import { Color } from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import styled from 'styled-components';

export const SuperPayRequestShipperGradientBox = styled(Box)`
  background: linear-gradient(${Color.White}, ${Color.White}) padding-box,
    linear-gradient(to right, #feac5e 0%, #c779d0 28.67%, #4bc0c8 100.59%)
      border-box;
  border: 1.5px solid transparent;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 52px;
  padding: 15px;
`;
