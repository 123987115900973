import { ListItem, ListItemText } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { Column, Columns, Stack } from '@superdispatch/ui';
import { Box, TextBox } from '@superdispatch/ui-lab';
import { LoadWaypoints } from 'shared/ui/LoadWaypoints';

export function LoadSuggestionsLoadingSkeleton() {
  return (
    <ListItem divider={true} component="div" ContainerComponent="div">
      <ListItemText
        primary={
          <Box
            paddingTop="medium"
            paddingBottom="medium"
            paddingLeft="large"
            paddingRight="large"
          >
            <Stack space="xsmall">
              <Stack space="xxsmall">
                <Columns align="center" space="large">
                  <Column width="1/2">
                    <TextBox variant="heading-3">
                      <Skeleton />
                    </TextBox>
                  </Column>
                  <Column width="1/2">
                    <TextBox variant="heading-3">
                      <Skeleton />
                    </TextBox>
                  </Column>
                </Columns>
              </Stack>
              <Stack space="medium">
                <Stack>
                  <LoadWaypoints
                    dense={true}
                    space="small"
                    inline={true}
                    pickupAddress={<Skeleton width={208} />}
                    deliveryAddress={<Skeleton width={208} />}
                  />

                  <TextBox>
                    <Skeleton width={136} />
                  </TextBox>
                </Stack>

                <Stack space="xxsmall">
                  <Skeleton width={208} />
                  <Skeleton width={128} />
                </Stack>
              </Stack>
              <Columns space="medium">
                <Column width="1/3">
                  <Skeleton height={56} />
                </Column>
                <Column width="2/3">
                  <Skeleton height={56} />{' '}
                </Column>
              </Columns>
            </Stack>
          </Box>
        }
      />
    </ListItem>
  );
}
