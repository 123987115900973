import { StandardTextFieldProps, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import {
  FieldValidator,
  FormikValues,
  getIn,
  setIn,
  useField,
  useFormikContext,
} from 'formik';
import { identity, merge } from 'lodash-es';
import { useMemo, useRef } from 'react';
import { QUARTER_OF_A_SECOND } from 'shared/constants/NumberConstants';
import { useDebouncedValue } from 'shared/helpers/ReactHelpers';
import { toSearchQueryText } from 'shared/utils/TextUtils';
import { Schema } from 'yup';
import { ContactDTO } from '../../contacts/data/ContactDTO';
import { useContactsList } from '../../contacts/data/ContactsAPI';

interface PartialVenue {
  name: string;
}

export interface FormikVenueAutofillProps
  extends Pick<
    StandardTextFieldProps,
    'label' | 'fullWidth' | 'inputProps' | 'disabled'
  > {
  name: string;
  validateName?: FieldValidator;
  schema: Schema<PartialVenue>;
  onSelect?: (contact: ContactDTO) => void;
}

export function FormikVenueAutofill({
  name,
  schema,
  validateName,
  inputProps,
  onSelect,
  ...textFieldProps
}: FormikVenueAutofillProps) {
  const { current: validate } = useRef(validateName);
  const { isSubmitting, setValues } = useFormikContext<FormikValues>();
  const [, { value, error, touched }, { setValue, setTouched }] = useField<
    string | undefined
  >({ validate, name: `${name}.name` });
  const errorText = touched && error;

  const query = useDebouncedValue(
    toSearchQueryText(value),
    QUARTER_OF_A_SECOND,
  );
  const { data: contacts } = useContactsList(
    { q: query, page_size: 20 },
    { enabled: !!query },
  );
  const options = useMemo(() => {
    const nextOptions: ContactDTO[] = [];
    if (contacts) {
      for (const page of contacts.pages) {
        for (const contact of page.data) {
          nextOptions.push(contact);
        }
      }
    }
    return nextOptions;
  }, [contacts]);

  return (
    <Autocomplete
      freeSolo={true}
      autoSelect={true}
      openOnFocus={false}
      disableClearable={true}
      options={options}
      disabled={isSubmitting || textFieldProps.disabled}
      filterOptions={identity}
      loading={!!query && !contacts}
      value={typeof value == 'string' ? value : ''}
      getOptionLabel={(option) =>
        typeof option == 'string' ? option : option.name || ''
      }
      onInputChange={(_, nextValue) => {
        void setValue(nextValue, false);
      }}
      onChange={(_, nextValue) => {
        if (typeof nextValue == 'string') {
          void setValue(nextValue);
        } else if (
          // Fixes https://sentry.io/organizations/superdispatch/issues/2354230874/
          typeof nextValue === 'object'
        ) {
          const { contact, phone, email, ...venue } = nextValue;

          void setValues((values) => {
            const current = getIn(values, name) as PartialVenue;

            return setIn(
              values,
              name,
              schema.cast(
                merge({}, current, venue, {
                  contacts: [{ name: contact, phone, email }],
                }),
              ),
            ) as FormikValues;
          }, true);

          if (onSelect) {
            onSelect(nextValue);
          }
        }
      }}
      onClose={() => {
        void setTouched(true, true);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          {...textFieldProps}
          error={!!errorText}
          helperText={errorText}
          inputProps={{
            ...params.inputProps,
            ...inputProps,
            autoComplete: 'new-password',
          }}
        />
      )}
    />
  );
}
