import { IconButton, Typography } from '@material-ui/core';
import { Delete, Edit } from '@material-ui/icons';
import { formatDate, NullableDateString } from '@superdispatch/dates';
import {
  Column,
  Columns,
  InfoTooltip,
  Inline,
  Stack,
  Tag,
  useResponsiveValue,
  useUID,
} from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import { ReactNode } from 'react';
import { Rating } from './Rating';

interface ReviewProps {
  variant: 'positive' | 'negative';
  author: ReactNode;
  reviewDate: NullableDateString;
  status: 'published' | 'pending';
  tags?: string[];
  comment?: string;
  response?: ReactNode;
  dispute?: ReactNode;
  onClickEdit?: () => void;
  onClickDelete?: () => void;
}

export function Review({
  comment,
  author,
  reviewDate,
  tags,
  variant,
  status,
  response,
  dispute,
  onClickEdit,
  onClickDelete,
}: ReviewProps) {
  const uid = useUID();
  const isPositiveRating = variant === 'positive';
  const editIconSize = useResponsiveValue('small', 'small', 'medium');
  const isMobile = useResponsiveValue(true, false);
  const shouldShowDispute =
    !isPositiveRating && status === 'published' && dispute;

  return (
    <Columns aria-labelledby={uid} space="small">
      <Column width="content">
        <Rating
          aria-label={`${isPositiveRating ? 'positive' : 'negative'} rating`}
          type={variant}
        />
      </Column>

      <Column>
        <Stack space="small">
          <Stack>
            <Columns align="center">
              <Column>
                <Inline verticalAlign="center">
                  <Typography id={uid}>{author}</Typography>

                  {!isMobile && status === 'pending' && <RatingPendingTag />}
                </Inline>

                <Typography
                  aria-label="rating date"
                  variant="caption"
                  color="textSecondary"
                >
                  {formatDate(reviewDate, { variant: 'Date' })}
                </Typography>
              </Column>

              {onClickEdit && (
                <Column width="content">
                  <IconButton aria-label="edit review" onClick={onClickEdit}>
                    <Edit fontSize={editIconSize} color="action" />
                  </IconButton>

                  {!isPositiveRating && (
                    <IconButton
                      aria-label="delete review"
                      onClick={onClickDelete}
                    >
                      <Delete fontSize={editIconSize} color="action" />
                    </IconButton>
                  )}
                </Column>
              )}
            </Columns>

            {isMobile && status === 'pending' && <RatingPendingTag />}
            {shouldShowDispute ? dispute : null}
          </Stack>

          {(tags?.length || comment) && (
            <Stack>
              {tags?.length ? (
                <Inline>
                  {tags.map((tag) => (
                    <Tag key={tag} color="grey" variant="subtle">
                      {tag}
                    </Tag>
                  ))}
                </Inline>
              ) : null}

              {comment && (
                <Box maxWidth="680px">
                  <Typography aria-label="rating comment" color="textSecondary">
                    {comment}
                  </Typography>
                </Box>
              )}
            </Stack>
          )}

          {response}
        </Stack>
      </Column>
    </Columns>
  );
}

function RatingPendingTag() {
  return (
    <Inline space="none" verticalAlign="center">
      <Tag color="purple" variant="subtle">
        Pending
      </Tag>

      <InfoTooltip
        interactive={true}
        title={
          <>
            <Typography>
              Pending ratings are visible to everyone but with hidden details.
            </Typography>
            <br />
            <Typography>
              Details are published after 10 days to allow both sides to resolve
              the issue.
            </Typography>
          </>
        }
        iconButtonProps={{
          'aria-label': 'pending rating info',
        }}
      />
    </Inline>
  );
}
