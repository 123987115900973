import { FormikErrors } from 'formik';
import { useMemo } from 'react';
import { APIError, isAPIError } from 'shared/api/APIError';
import { VerificationCodeMethod } from '../data/PhoneVerificationAPI';
import { VerificationCodeSubmittingDTO } from '../data/PhoneVerificationDTO';

export function getVerificationFormikErrors(error: unknown) {
  const errors: FormikErrors<VerificationCodeSubmittingDTO> = {};

  if (isAPIError(error)) {
    if (error.type === 'VerificationCodeExpired') {
      errors.code =
        'This code has expired, please request a new code to continue.';
    }

    if (error.type === 'VerificationCodeDoesNotMatch') {
      errors.code =
        'This code is incorrect. Please check your text again, or request a new one.';
    }
  }

  return errors;
}

const HIDDEN_BLOCK_ERROR_TYPES = [
  'VerificationCodeDoesNotMatch',
  'VerificationCodeExpired',
  'VerificationAttemptTooEarly', // do not show error if it's too early, let user enter previous code
];

const CODE_NOT_SENT_ERROR_TYPES = [
  'FailedToGenerateVerificationCode',
  'VerificationCodeWasNotSent',
  'FailedToSendMessage', // do not show error if it's too early, let user enter previous code
];

function getErrorMessage(error: APIError, method: VerificationCodeMethod) {
  if (error.type && HIDDEN_BLOCK_ERROR_TYPES.includes(error.type)) {
    return '';
  }

  if (error.type && CODE_NOT_SENT_ERROR_TYPES.includes(error.type)) {
    return method === 'SMS'
      ? 'The verification code could not be sent, please try again.'
      : 'There was a problem placing the verification call.';
  }

  return 'There was an error while attempting to fetch the code, please try again.';
}

export interface CodeValidationFormStatus {
  type: string;
  payload?: { message: string };
}
export function useErrorMessage(
  status: CodeValidationFormStatus,
  requestError: Error | null,
  method: VerificationCodeMethod,
) {
  return useMemo(() => {
    if (isAPIError(status.payload)) {
      return getErrorMessage(status.payload, method);
    }

    if (isAPIError(requestError)) {
      return getErrorMessage(requestError, method);
    }

    return '';
  }, [method, status, requestError]);
}
