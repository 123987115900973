import { Card, CardContent, IconButton } from '@material-ui/core';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import { Color, useResponsiveValue } from '@superdispatch/ui';
import styled from 'styled-components';

export const LOADS_PAGE_SIZE = 10;

const PageIconButton = styled(IconButton)`
  width: 44px;
  font-size: 14px;

  &[aria-current='true'] {
    color: ${Color.Blue300};
    background-color: ${Color.Blue50};
  }
`;

export interface PageButtonProps {
  page?: number;
  currentPage?: number;
  onClick?: (page: number) => void;
}

function PageButton({ page, currentPage, onClick }: PageButtonProps) {
  return (
    <PageIconButton
      color="primary"
      disabled={page == null}
      onClick={() => page != null && onClick?.(page)}
      aria-label={page == null ? undefined : `Goto Next ${page}`}
      aria-current={
        page == null ? undefined : page === currentPage ? 'true' : 'false'
      }
    >
      {page == null ? '…' : page}
    </PageIconButton>
  );
}

export interface LoadsPagePaginationProps {
  count: number;
  currentPage: number;
  onPageChange: (nextPage: number) => void;
}

export function LoadsPagePagination({
  count,
  currentPage,
  onPageChange,
}: LoadsPagePaginationProps) {
  const maxPage = Math.ceil(count / LOADS_PAGE_SIZE);
  const skipPages = useResponsiveValue(0, 2);

  const fromPage = currentPage > skipPages ? currentPage - skipPages : 1;
  const toPage =
    maxPage - currentPage > skipPages ? currentPage + skipPages + 1 : maxPage;

  return (
    <Card role="navigation" aria-label="Pagination Navigation">
      <CardContent>
        <IconButton
          color="primary"
          aria-label="Goto Previous Page"
          disabled={currentPage === 1}
          onClick={() => {
            onPageChange(currentPage - 1);
          }}
        >
          <ChevronLeft />
        </IconButton>

        {fromPage !== 1 && (
          <PageButton
            page={1}
            onClick={onPageChange}
            currentPage={currentPage}
          />
        )}

        {fromPage > 2 && <PageButton />}

        {Array.from({ length: toPage - fromPage }, (_, idx) => (
          <PageButton
            key={fromPage + idx}
            page={fromPage + idx}
            onClick={onPageChange}
            currentPage={currentPage}
          />
        ))}

        {toPage < maxPage && <PageButton />}
        {toPage - 1 < maxPage && (
          <PageButton
            page={maxPage}
            onClick={onPageChange}
            currentPage={currentPage}
          />
        )}

        <IconButton
          color="primary"
          aria-label="Goto Next Page"
          disabled={currentPage === maxPage}
          onClick={() => {
            onPageChange(currentPage + 1);
          }}
        >
          <ChevronRight />
        </IconButton>
      </CardContent>
    </Card>
  );
}
