import {
  CircularProgress,
  DialogActions,
  DialogProps,
  FormControlLabel,
  Radio,
  Typography,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import {
  FormikRadioGroupField,
  FormikTextField,
  useFormikEnhanced,
} from '@superdispatch/forms';
import {
  Column,
  Columns,
  Stack,
  Tag,
  useSnackbarStack,
} from '@superdispatch/ui';
import { Box, Button } from '@superdispatch/ui-lab';
import { FormikProvider } from 'formik';
import { snakeCase } from 'lodash-es';
import React, { useMemo } from 'react';
import {
  MutationDialog,
  MutationDialogContent,
  MutationDialogContentProps,
} from 'shared/form/MutationDialog';
import { LinkAnchor } from 'shared/routing/Links';
import { useLocationChangeEffect } from 'shared/routing/LocationChangeEffect';
import styled from 'styled-components';
import { cancelLoadSchema, formatLoadStatus, LoadDTO } from '../data/LoadDTO';
import { useLoadsAPI, useRelatedLoads } from '../data/LoadsAPI';

const StyledProgressBarWrapper = styled.div`
  display: flex;
  height: 200px;
  justify-content: center;
  align-items: center;
`;
const StyledLinkAnchor = styled(LinkAnchor)`
  word-break: break-all;
`;

const loadCancelReasons = [
  'Unit Not Ready',
  'Unknown Inoperable Vehicle',
  'Cannot Meet Dates',
  'Low Price',
  'Changed Route',
];

interface Props {
  load: LoadDTO | undefined;
  disableAutoclose?: boolean;
  onClose: () => void;
}

interface LoadCancelDialogWrapperProps {
  children: React.ReactNode;
  dialogProps: DialogProps;
  dialogContentProps: MutationDialogContentProps;
  actions: React.ReactNode;
}

function LoadCancelDialogWrapper({
  actions,
  children,
  dialogProps,
  dialogContentProps,
}: LoadCancelDialogWrapperProps) {
  return (
    <MutationDialog {...dialogProps}>
      <MutationDialogContent {...dialogContentProps}>
        {children}
      </MutationDialogContent>
      <DialogActions>{actions}</DialogActions>
    </MutationDialog>
  );
}

function LoadingDialog({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) {
  return (
    <LoadCancelDialogWrapper
      dialogProps={{ maxWidth: 'xs', open, onClose, fullWidth: true }}
      dialogContentProps={{
        title: <Skeleton width={250} height={50} />,
        onClose,
      }}
      actions={<Skeleton width={100} height={50} />}
    >
      <StyledProgressBarWrapper>
        <CircularProgress />
      </StyledProgressBarWrapper>
    </LoadCancelDialogWrapper>
  );
}

function LoadCannotBeCanceledDialog({
  onClose,
  load,
}: {
  onClose: () => void;
  load: LoadDTO | undefined;
}) {
  const relatedLoads = useRelatedLoads(load?.guid || '');

  return (
    <LoadCancelDialogWrapper
      dialogProps={{ maxWidth: 'xs', open: !!load, onClose }}
      dialogContentProps={{
        title: `Contact ${load?.customer.venue.name}`,
        onClose,
      }}
      actions={<Button onClick={onClose}>Got It</Button>}
      key={load?.guid}
    >
      <Stack space="small">
        <Typography>
          You cannot cancel the order. At least one of the splitted loads is
          already picked up.{' '}
        </Typography>
        <Stack space="small">
          {relatedLoads.data?.map(
            ({ guid, number, status, is_in_terminal }) => (
              <Columns key={guid} space="small">
                <Column width="2/3">
                  <StyledLinkAnchor color="textSecondary" to={`/loads/${guid}`}>
                    {number}
                  </StyledLinkAnchor>
                </Column>
                <Column width="1/3">
                  {is_in_terminal ? (
                    <Tag color="purple" variant="bold">
                      In Terminal
                    </Tag>
                  ) : (
                    <Tag
                      variant="subtle"
                      color={
                        status === 'new'
                          ? 'teal'
                          : status === 'picked_up'
                          ? 'yellow'
                          : 'green'
                      }
                    >
                      {formatLoadStatus(status)}
                    </Tag>
                  )}
                </Column>
              </Columns>
            ),
          )}

          <Typography>
            Contact the shipper at {load?.customer.venue.name} or call{' '}
            {load?.customer.venue.contacts.map((contact) =>
              !contact.phone ? null : contact.phone,
            )}{' '}
            to cancel.
          </Typography>
        </Stack>
      </Stack>
    </LoadCancelDialogWrapper>
  );
}

function LoadCanbeCanceledDialog({
  onClose,
  load,
}: {
  onClose: () => void;
  load: LoadDTO | undefined;
}) {
  const { cancelLoad } = useLoadsAPI();
  const relatedLoads = useRelatedLoads(load?.guid || '');
  const { addSnackbar } = useSnackbarStack();
  const formik = useFormikEnhanced({
    key: load?.guid,
    validationSchema: cancelLoadSchema,
    initialValues: {
      cancel_reason_type: '',
      cancel_reason: '',
    },
    onSubmit: (values) => cancelLoad(load?.guid || '', values),
    onSubmitSuccess: () => {
      addSnackbar(`Load ${load?.number} canceled`);
      onClose();
    },
    onSubmitFailure: (error) => {
      addSnackbar(
        <Stack>
          <Typography>Failed to cancel load</Typography>
          <Typography>Details:{error.message}</Typography>
        </Stack>,
        { variant: 'error' },
      );
    },
  });

  return (
    <LoadCancelDialogWrapper
      dialogProps={{ maxWidth: 'sm', fullWidth: true, open: !!load, onClose }}
      dialogContentProps={{ title: `Cancel Load ${load?.number}?`, onClose }}
      actions={
        <>
          <Button variant="neutral" onClick={onClose}>
            No
          </Button>
          <Button
            variant="critical"
            disabled={!formik.values.cancel_reason_type}
            pending={formik.isSubmitting}
            onClick={() => {
              void formik.submitForm();
            }}
          >
            Yes, Cancel
          </Button>
        </>
      }
    >
      <FormikProvider value={formik}>
        <Stack>
          {!!relatedLoads.data?.length && (
            <Box backgroundColor="Yellow50" padding="xsmall">
              <Typography>
                Canceling this load will cancel other splitted loads:
              </Typography>
              {relatedLoads.data.map(({ guid, number }, idx) => (
                <React.Fragment key={guid}>
                  <LinkAnchor
                    color="textSecondary"
                    to={`/loads/${guid}`}
                    onClick={() => {
                      onClose();
                    }}
                  >
                    {number}
                  </LinkAnchor>
                  {idx !== relatedLoads.data.length - 1 ? ',  ' : ''}
                </React.Fragment>
              ))}
            </Box>
          )}

          <FormikRadioGroupField
            name="cancel_reason_type"
            label={
              <Typography variant="h6" color="textSecondary">
                WHAT IS THE REASON?
              </Typography>
            }
          >
            {loadCancelReasons.map((reason) => (
              <FormControlLabel
                key={reason}
                value={snakeCase(reason)}
                control={<Radio />}
                label={reason}
              />
            ))}
          </FormikRadioGroupField>
          <FormikTextField
            name="cancel_reason"
            label="Details"
            placeholder="Add details if the reason is different or you want to tell more"
            helperText="Shipper will be notified with the reason you mention above"
            multiline={true}
            fullWidth={true}
          />
        </Stack>
      </FormikProvider>
    </LoadCancelDialogWrapper>
  );
}

export function LoadCancelDialog({ load, disableAutoclose, onClose }: Props) {
  const relatedLoads = useRelatedLoads(load?.guid || '');

  const canBeCanceled = useMemo(() => {
    if (!relatedLoads.data) {
      return true;
    }

    return relatedLoads.data.every(
      (l) => l.status === 'new' && !l.is_in_terminal,
    );
  }, [relatedLoads.data]);

  useLocationChangeEffect(() => {
    if (!disableAutoclose) {
      onClose();
    }
  });

  return relatedLoads.isFetching ? (
    <LoadingDialog open={!!load} onClose={onClose} />
  ) : !canBeCanceled ? (
    <LoadCannotBeCanceledDialog onClose={onClose} load={load} />
  ) : (
    <LoadCanbeCanceledDialog onClose={onClose} load={load} />
  );
}
