import { useAPISocketMessages } from 'shared/api/APISocketMessages';
import { usePushNotification } from 'shared/helpers/notifications/PushNotification';
import {
  RatingNotificationMessageDTO,
  ratingNotificationMessageSchema,
} from './RatingNotificationDTO';

type RatingNotificationType =
  | 'rating_received_positive'
  | 'rating_received_negative'
  | 'rating_updated'
  | 'rating_deleted'
  | 'shipper_rating_response_added'
  | 'shipper_rating_response_modified'
  | 'shipper_rating_response_deleted';
const ratingNotificationTypes = new Set<RatingNotificationType>([
  'rating_received_positive',
  'rating_received_negative',
  'rating_updated',
  'rating_deleted',
  'shipper_rating_response_added',
  'shipper_rating_response_deleted',
  'shipper_rating_response_modified',
]);

export function useRatingNotifications() {
  const notify = usePushNotification();

  useAPISocketMessages<RatingNotificationMessageDTO, RatingNotificationType>(
    ({ data, meta: { type, message_guid } }) => {
      if (ratingNotificationTypes.has(type)) {
        const message = ratingNotificationMessageSchema.cast(data);

        // Temporarily adding utm params manually. This will be done on backend properly later.
        const utmMedium = chooseUTMMedium(type);
        const ratingsURL = new URL(message.ratings_url);
        ratingsURL.searchParams.set('utm_source', 'Web CTMS Push');
        ratingsURL.searchParams.set('utm_medium', utmMedium);

        notify(
          message_guid,
          message.subject,
          message.body,
          ratingsURL.toString(),
          'View Ratings',
        );
      }
    },
  );
}

function chooseUTMMedium(type: RatingNotificationType) {
  switch (type) {
    case 'rating_deleted':
      return 'Deleted Rating';

    case 'rating_updated':
      return 'Modified Rating';

    case 'rating_received_positive':
      return 'Positive Rating';

    case 'rating_received_negative':
      return 'Negative Rating';

    default:
      return '';
  }
}
