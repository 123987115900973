import { Tooltip } from '@material-ui/core';
import { Box } from '@superdispatch/ui-lab';
import { ReactNode } from 'react';

export interface LoadVehicleDisabledTooltipProps {
  children: ReactNode;
  isEnabledEditLoad: boolean;
}

export function LoadVehicleDisabledTooltip({
  isEnabledEditLoad,
  children,
}: LoadVehicleDisabledTooltipProps) {
  return (
    <Tooltip
      title={
        isEnabledEditLoad
          ? ''
          : 'You cannot add vehicles for the loads coming from Shipper'
      }
    >
      <Box>{children}</Box>
    </Tooltip>
  );
}
