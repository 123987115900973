import { Inline, Stack, useUID } from '@superdispatch/ui';
import { TextBox } from '@superdispatch/ui-lab';
import { ReactElement, ReactNode } from 'react';

interface OfferDescriptionProps {
  inline?: boolean;
  fallback?: ReactNode;
  label: string | ReactElement;
  value: string | ReactElement;
}

export function OfferDescription({
  label,
  value,
  inline,
  fallback,
}: OfferDescriptionProps) {
  const uid = useUID();
  const Spacer = inline ? Inline : Stack;

  if (!value && !!fallback) {
    return <TextBox color="secondary">{fallback}</TextBox>;
  }

  return (
    <Spacer space="xxsmall">
      <div id={uid}>
        {typeof label != 'string' ? (
          label
        ) : (
          <TextBox color="secondary">{label}</TextBox>
        )}
      </div>

      <div aria-labelledby={uid}>{value}</div>
    </Spacer>
  );
}
