import { useSnackbarStack } from '@superdispatch/ui';
import { Box, Button } from '@superdispatch/ui-lab';
import { useCallback } from 'react';

export function useSnackbarNotification() {
  const { addSnackbar } = useSnackbarStack();

  return useCallback(
    (
      title: string,
      body: string,
      actionText: string,
      onClick: () => void,
      onClose: () => void,
    ) => {
      addSnackbar(
        <Box paddingRight="small">
          {title}
          <br />
          {body}
        </Box>,
        {
          onClose,
          autoHideDuration: 1000000,
          ContentProps: { style: { maxWidth: 660, width: 'auto' } },
          action: (
            <Button variant="inverted" size="small" onClick={onClick}>
              {actionText}
            </Button>
          ),
        },
      );
    },
    [addSnackbar],
  );
}
