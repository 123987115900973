import type {
  Account,
  BankAccount,
  BusinessType,
  NewBankAccount,
} from '@moovio/moov-js';
import {
  createHTTP,
  HTTPEndpointInput,
  HTTPError,
  HTTPRequestJSONOptions,
} from '@superdispatch/http';
import { URITemplateParams } from '@superdispatch/uri';
import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { capitalize } from 'shared/utils/StringUtils';
import { usePaymentLog } from '../helpers/PaymentLogger';
import { MoovAccountInfo } from './MoovAccountInfo';
import {
  AchParticipant,
  NewCarrierBankAccount,
  NewCarrierMoovAccount,
} from './MoovDTO';
import { useSyncSuperPayOnboardingStep } from './SuperPayAPI';
import { useMoovAccountInfo } from './useMoovAccountInfo';

const MOOV_HOST = 'https://api.moov.io';
const httpClient = createHTTP({
  baseURL: MOOV_HOST,
  headers(headers) {
    if (!headers?.authorization) {
      const moovAccountInfo = MoovAccountInfo.getInstance();
      const token = moovAccountInfo.getCurrentToken(); // token scope is set before the request

      if (token) headers = { ...headers, authorization: `Bearer ${token}` };
    }

    return headers;
  },
});

function requestJSONMoov<
  TData,
  TParams extends URITemplateParams = URITemplateParams,
>(
  input: HTTPEndpointInput<TParams>,
  options?: HTTPRequestJSONOptions<TData>,
): Promise<TData> {
  return httpClient.requestJSON(input, options).catch(handleError);
}

export type MoovActionError = Error & { statusCode?: number };

async function handleError(error: unknown) {
  if (!(error instanceof HTTPError)) return Promise.reject(error);

  const reason = (await error.response.json()) as { error: string };
  const statusCode = error.response.status;

  let errorMessage = `${reason.error || error.message}`;

  if (statusCode === 403) {
    errorMessage = 'Not authorized';
  } else if (!reason.error && statusCode === 422) {
    errorMessage = 'Values are invalid';
  }

  errorMessage = `${capitalize(errorMessage)}.`;

  return Promise.reject(
    Object.assign(new Error(errorMessage), {
      statusCode,
    }),
  );
}

export function useMoovAPI() {
  const moovAccountInfo = useMoovAccountInfo();
  const { logPaymentError } = usePaymentLog();

  return useMemo(
    () => ({
      createCarrierMoovAccount: async (
        newAccountData: NewCarrierMoovAccount,
      ) => {
        const { accountID } = await moovAccountInfo.setScope('account');
        const account: Partial<Account> = {
          accountType: 'business',
          profile: {
            business: {
              businessType: newAccountData.businessType as BusinessType,
              description: newAccountData.description,
              legalBusinessName: newAccountData.legalBusinessName,
              taxID: { ein: { number: newAccountData.einNumber } },
            },
          },
          metadata: {
            guid: newAccountData.carrier_guid,
            type: 'carrier',
          },
          foreignID: newAccountData.carrier_guid,
        };

        if (accountID) {
          throw new Error('Moov account already exists');
        }

        return requestJSONMoov<Account>('POST /accounts', {
          json: account,
        })
          .then((data) => moovAccountInfo.setAccount(data))
          .catch((error) => {
            logPaymentError(error, 'MoovAPI.createCarrierMoovAccount');

            throw error;
          });
      },

      linkBankAccount: async ({
        accountNumber,
        holderName,
        routingNumber,
      }: NewCarrierBankAccount) => {
        const { accountID } = await moovAccountInfo.setScope('bank_account');
        const account: NewBankAccount = {
          routingNumber,
          accountNumber,
          holderName,
          holderType: 'business',
          bankAccountType: 'checking',
        };

        return requestJSONMoov<BankAccount>(
          `POST /accounts/${accountID}/bank-accounts`,
          {
            json: { account },
          },
        )
          .then((data) => moovAccountInfo.setBankAccount(data))
          .catch((error) => {
            logPaymentError(error, 'MoovAPI.linkBankAccount');

            throw error;
          });
      },

      completeMicroDepositVerification: async (amounts: number[]) => {
        const { accountID, bankAccountID } = await moovAccountInfo.setScope(
          'bank_account',
        );

        return requestJSONMoov(
          `PUT /accounts/${accountID}/bank-accounts/${bankAccountID}/micro-deposits`,
          {
            json: { amounts },
            parseJSON: (json) => json, // Empty response, don't parse
          },
        ).then((result) => {
          moovAccountInfo.setBankAccount({
            ...moovAccountInfo.getBankAccount(),
            status: 'validated',
          });

          return result;
        });
      },

      getBankNameByRoutingNumber: async (routingNumber: string) => {
        await moovAccountInfo.setScope('bank_account');

        return requestJSONMoov<{ achParticipants?: AchParticipant[] }>(
          `GET /institutions/ach/search?routingNumber=${routingNumber}`,
        )
          .then((data) => data.achParticipants?.[0]?.customerName)
          .catch((error) => {
            logPaymentError(error, 'MoovAPI.getBankNameByRoutingNumber');

            throw error;
          });
      },

      // This data should be stored inside MoovAccountInfo. It should be done correctly later.
      getAccountLegalName: async () => {
        const { accountID } = await moovAccountInfo.setScope('micro_deposit');

        return requestJSONMoov<Account>(`GET /accounts/${accountID}`)
          .then((data) => {
            const accountInfo = moovAccountInfo.setAccount(data);

            return accountInfo.profile?.business?.legalBusinessName;
          })
          .catch((error) => {
            logPaymentError(error, 'MoovAPI.getAccountLegalName');

            throw error;
          });
      },

      // Pings '/institutions' endpoint to check availability
      isAvailable: async () => {
        await moovAccountInfo.setScope('bank_account');

        return requestJSONMoov(
          'GET /institutions/ach/search?routingNumber=000000000',
        )
          .then(() => true)
          .catch(() => false);
      },
    }),
    [moovAccountInfo, logPaymentError],
  );
}

export function useMoovSyncAPI() {
  const moovAccountInfo = useMoovAccountInfo();
  const { mutateAsync: syncSuperPay } = useSyncSuperPayOnboardingStep();
  const { createCarrierMoovAccount, linkBankAccount } = useMoovAPI();
  return useMemo(
    () => ({
      createCarrierSyncMoovAccount: (newAccountData: NewCarrierMoovAccount) => {
        return createCarrierMoovAccount(newAccountData).then((account) => {
          // We can skip errors, because there will be synchronization between moov and our backend,
          // creating account in moov, this is the most important for us.
          return syncSuperPay({
            scope: 'account',
            moov_bank_account_id: null,
            moov_account_id: account.accountID,
          }).finally(() => account);
        });
      },
      linkSyncMoovBankAccount: (newBankAccountData: NewCarrierBankAccount) => {
        return linkBankAccount(newBankAccountData).then((bankAccount) => {
          const account = moovAccountInfo.getAccount();
          // We can skip errors, because there will be synchronization between moov and our backend,
          // creating account in moov, this is the most important for us.
          return syncSuperPay({
            scope: 'bank_account',
            moov_bank_account_id: bankAccount.bankAccountID,
            moov_account_id: account?.accountID,
          }).finally(() => bankAccount);
        });
      },
    }),
    [createCarrierMoovAccount, linkBankAccount, moovAccountInfo, syncSuperPay],
  );
}

export function useAccountLegalBusinessName() {
  const { getAccountLegalName } = useMoovAPI();

  return useQuery('moovAccountLegalName', getAccountLegalName, {
    cacheTime: Infinity,
  });
}
