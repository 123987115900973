import { useSnackbarStack } from '@superdispatch/ui';
import { MutationDialog } from 'shared/form/MutationDialog';
import { ConfirmDialogContent } from 'shared/ui/ConfirmDialog';
import { useBatchDeleteContacts, useContact } from './data/ContactsAPI';

interface ContactsPageMutationDialogContentProps {
  guid?: string;
  onDismiss: () => void;
  onSuccess?: (nextContactGUID?: string) => void;
}

function ContactDeleteDialogContent({
  guid,
  onDismiss,
  onSuccess = onDismiss,
}: ContactsPageMutationDialogContentProps) {
  const { data } = useContact(guid);

  const { addSnackbar } = useSnackbarStack();
  const batchDeleteContacts = useBatchDeleteContacts({
    onSuccess(response, _, context) {
      onSuccess(context);
      addSnackbar(
        response.user_message || `${data?.name || 'Contact'} deleted`,
      );
    },
    onError(error) {
      addSnackbar(error.message, { variant: 'error' });
    },
  });

  return (
    <ConfirmDialogContent
      onClose={onDismiss}
      title={`Delete ${data?.name || 'contact'}?`}
      cancelButtonProps={{ disabled: batchDeleteContacts.isLoading }}
      confirmButtonProps={{
        children: 'Delete',
        variant: 'critical',
        pending: batchDeleteContacts.isLoading,
        onClick() {
          if (guid) batchDeleteContacts.mutate(new Set([guid]));
        },
      }}
    >
      Do you really want to delete this contact? This action can not be undone.
    </ConfirmDialogContent>
  );
}

export interface ContactsPageMutationDialogProps
  extends ContactsPageMutationDialogContentProps {
  open: boolean;
}

export function ContactDeleteDialog({
  open,
  ...props
}: ContactsPageMutationDialogProps) {
  return (
    <MutationDialog
      open={open}
      maxWidth="xs"
      fullWidth={true}
      onClose={props.onDismiss}
    >
      <ContactDeleteDialogContent {...props} />
    </MutationDialog>
  );
}
