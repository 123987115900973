import { Divider, IconButton, Typography } from '@material-ui/core';
import { DeleteOutline } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import { Color, Stack, VisibilityObserver } from '@superdispatch/ui';
import { Box, Sidebar, TextBox } from '@superdispatch/ui-lab';
import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useMatch, useNavigate } from 'shared/routing/react-router-6';
import { SidebarMenuItemLink } from 'shared/routing/SidebarMenuItemLink';
import { useFlag } from 'shared/settings/FeatureToggles';
import { useSavedReportsList } from '../data/ReportsAPI';
import { ReportsCustomDTO } from '../data/ReportsDTO';
import { ReportDeleteMenu } from './ReportDeleteMenu';

export function ReportsSidebar() {
  const navigate = useNavigate();
  const { reportGuid } = useParams<{ reportGuid: string }>();
  const [deleteAnchorRef, setDeleteAnchorRef] = useState<HTMLElement | null>(
    null,
  );
  const [savedReportToDelete, setSavedReportToDelete] =
    useState<ReportsCustomDTO>();
  const canDeleteSavedReport = useFlag('delete_saved_report');
  const match = useMatch('/reports/custom-report/');
  const { data, fetchNextPage, isLoading, hasNextPage } = useSavedReportsList();
  const savedReports = useMemo(
    () => data?.pages.flatMap((page) => page.data),
    [data],
  );

  return (
    <Sidebar title="Reports">
      <Stack space="small">
        <div>
          <SidebarMenuItemLink to="/reports/accounts-receivable/">
            Accounts Receivable
          </SidebarMenuItemLink>
          <SidebarMenuItemLink to="/reports/company-revenue/">
            Company Revenue
          </SidebarMenuItemLink>
          <SidebarMenuItemLink to="/reports/accounts-payable/">
            Accounts Payable
          </SidebarMenuItemLink>
          <SidebarMenuItemLink to="/reports/driver-pay/">
            Driver Pay Report
          </SidebarMenuItemLink>
          <SidebarMenuItemLink
            to="/reports/custom-report/"
            selected={match?.isExact}
          >
            Custom Report
          </SidebarMenuItemLink>
        </div>

        {!!match && (
          <>
            <Divider variant="middle" />

            <Stack space="xsmall">
              <Box marginLeft="medium" marginTop="xsmall">
                <TextBox color="secondary" variant="body-semibold">
                  SAVED REPORTS
                </TextBox>
              </Box>

              {savedReports?.map((report) => {
                return (
                  <SidebarMenuItemLink
                    key={report.guid}
                    to={`/reports/custom-report/${report.guid}`}
                    action={
                      canDeleteSavedReport && (
                        <IconButton
                          aria-label={`saved-report-${report.guid}`}
                          onClick={(e) => {
                            setSavedReportToDelete(report);
                            setDeleteAnchorRef(e.currentTarget);
                          }}
                        >
                          <DeleteOutline
                            htmlColor={Color.Dark100}
                            fontSize="small"
                          />
                        </IconButton>
                      )
                    }
                  >
                    {report.name}
                  </SidebarMenuItemLink>
                );
              })}

              {savedReportToDelete && (
                <ReportDeleteMenu
                  deleteAnchorRef={deleteAnchorRef}
                  open={!!savedReportToDelete}
                  savedReport={savedReportToDelete}
                  onCancel={() => {
                    setSavedReportToDelete(undefined);
                    setDeleteAnchorRef(null);
                  }}
                  onDeleteSuccess={() => {
                    setSavedReportToDelete(undefined);
                    setDeleteAnchorRef(null);
                    if (reportGuid === savedReportToDelete.guid) {
                      navigate('/reports/custom-report/', { replace: true });
                    }
                  }}
                  onClose={() => {
                    setDeleteAnchorRef(null);
                    setSavedReportToDelete(undefined);
                  }}
                />
              )}

              {!savedReports?.length && !isLoading && (
                <Box marginLeft="medium">
                  <Typography color="textSecondary">
                    Click Save on the top right to save your first report
                  </Typography>
                </Box>
              )}

              {!isLoading && hasNextPage && !!savedReports?.length && (
                <VisibilityObserver
                  onChange={(visibility) => {
                    if (visibility === 'visible') {
                      void fetchNextPage();
                    }
                  }}
                  render={({ ref }) => (
                    <div ref={ref}>
                      <Stack>
                        <Skeleton animation="wave" />
                        <Skeleton animation="wave" />
                      </Stack>
                    </div>
                  )}
                />
              )}
            </Stack>
          </>
        )}
      </Stack>
    </Sidebar>
  );
}
