import { useFormikEnhanced } from '@superdispatch/forms';
import { Column, Columns, useSnackbarStack } from '@superdispatch/ui';
import { Box, Button } from '@superdispatch/ui-lab';
import { APIResponse } from 'shared/api/APIClient';
import { APIError } from 'shared/api/APIError';
import { isWebView } from 'shared/constants/AppTypeConstants';
import { useInitialValues } from 'shared/form/AppFormik';
import { DocumentTitle } from 'shared/layout/DocumentTitle';
import { ProfileW9Form } from './core/ProfileW9Form';
import {
  useSubmitVerificationDocument,
  VerificationDialogs,
} from './core/VerificationDialogs';
import { W9Preview } from './core/W9Preview';
import { trackCarrierProfileEvent } from './data/CarrierProfileAnalytics';
import { useCarrierProfileAPI, useW9 } from './data/CarrierProfileAPI';
import { W9DTO, w9Schema } from './data/CarrierProfileDTO';
import { ProfilePageLayout } from './ProfilePageLayout';

export function ProfileW9Page() {
  const { addSnackbar } = useSnackbarStack();
  const { data, isLoading } = useW9({
    onError: () => {
      addSnackbar('Failed to fetch W-9 Form', {
        variant: 'error',
      });
    },
  });
  const { updateW9 } = useCarrierProfileAPI();
  const initialValues = useInitialValues(w9Schema, data);

  const formik = useFormikEnhanced<W9DTO, APIResponse>({
    initialValues,
    validationSchema: w9Schema,
    onSubmit: updateW9,
    onSubmitSuccess: () => {
      addSnackbar('W-9 Form was successfully updated', {
        variant: 'success',
      });

      trackCarrierProfileEvent({
        name: 'Carrier Updated Carrier Info',
        utm_medium: 'Carrier Profile',
        utm_content: 'W-9 Form',
      });
    },
    onSubmitFailure: ({ message }: APIError) => {
      addSnackbar(`Failed to update W-9 form: ${message}`, {
        variant: 'error',
      });
    },
  });

  const submitVerificationForm = useSubmitVerificationDocument(formik);

  return (
    <>
      <DocumentTitle title="W-9 Form - Profile" />

      <VerificationDialogs formik={formik} />

      <ProfilePageLayout
        formik={formik}
        title="W-9 Form"
        size={isWebView ? 'small' : 'medium'}
        isLoading={isLoading}
        headerEndActions={
          <Button
            type="button"
            onClick={submitVerificationForm}
            pending={formik.isSubmitting}
            disabled={!formik.dirty}
            fullWidth={isWebView}
          >
            Save
          </Button>
        }
      >
        <Columns space="xxlarge" collapseBelow="desktop">
          <Column>
            <Box maxWidth="496px" paddingTop={isWebView ? 'small' : 'xxlarge'}>
              <ProfileW9Form />
            </Box>
          </Column>

          {!isWebView && (
            <Column>
              <W9Preview />
            </Column>
          )}
        </Columns>
      </ProfilePageLayout>
    </>
  );
}
