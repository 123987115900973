import { useResponsiveValue } from '@superdispatch/ui';
import { AnchorButton } from '@superdispatch/ui-lab';
import { useLocation } from 'react-router-dom';
import { getSignupPageUrl } from 'shared/auth/SignupPageUtils';
import { LoginLayout } from './core/LoginLayout';
import { ResetPasswordSuccessDesktop } from './core/ResetPasswordSuccessDesktop';
import { ResetPasswordSuccessFooter } from './core/ResetPasswordSuccessFooter';
import { ResetPasswordSuccessMobile } from './core/ResetPasswordSuccessMobile';
import { resetPasswordSuccessSchema } from './data/AuthDTO';
import { trackLoginEvent } from './data/LoginAnalytics';

export function ResetPasswordSuccessPage() {
  const isMobile = useResponsiveValue(true, false);
  const location = useLocation();
  const state = resetPasswordSuccessSchema.cast(location.state);
  const { token } = state;

  return (
    <LoginLayout
      footerAction={<ResetPasswordSuccessFooter token={token} />}
      header={
        !isMobile && (
          <AnchorButton
            variant="neutral"
            size="medium"
            href={getSignupPageUrl()}
            onClick={() => {
              trackLoginEvent({ name: 'CTMS: Clicked Sign Up' });
            }}
          >
            Sign Up
          </AnchorButton>
        )
      }
      showDot={false}
    >
      {isMobile ? (
        <ResetPasswordSuccessMobile />
      ) : (
        <ResetPasswordSuccessDesktop />
      )}
    </LoginLayout>
  );
}
