import { createSvgIcon } from 'shared/icons/IconUtils';

export const CarRentalIcon = createSvgIcon(
  'CarRentalIcon',
  <g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.7345 6.56692C13.4346 6.92492 13.0733 7.22965 12.6667 7.46486V8.99999V12H12V12.3333C12 12.88 12.4467 13.3333 13 13.3333C13.5467 13.3333 14 12.8867 14 12.3333V7.55999C14 7.41332 13.9733 7.25999 13.9267 7.11999L13.7345 6.56692ZM9.66667 12L8.33333 11L9 9.99999L8.33333 8.99999L9 8.33332L8.66667 7.66666V7.46486C8.29836 7.25181 7.96717 6.98171 7.68519 6.66666H3.33333L4.18 4.11999C4.27333 3.85332 4.52667 3.66666 4.81333 3.66666H6.68036C6.70906 3.3188 6.78226 2.98356 6.89427 2.66666H4.33333C3.89333 2.66666 3.52667 2.94666 3.38667 3.33999L2.07333 7.11999C2.02667 7.25999 2 7.40666 2 7.55999V12.3333C2 12.8867 2.44667 13.3333 3 13.3333C3.55333 13.3333 4 12.8867 4 12.3333V12H9.66667ZM3.33333 8.99999C3.33333 9.55332 3.78 9.99999 4.33333 9.99999C4.88667 9.99999 5.33333 9.55332 5.33333 8.99999C5.33333 8.44666 4.88667 7.99999 4.33333 7.99999C3.78 7.99999 3.33333 8.44666 3.33333 8.99999Z"
      fill="currentColor"
    />
    <path
      d="M9.64103 3.99999C9.64103 4.2849 9.74076 4.52707 9.9402 4.72652C10.1396 4.92596 10.3818 5.02569 10.6667 5.02569C10.9516 5.02569 11.1938 4.92596 11.3933 4.72652C11.5927 4.52707 11.6924 4.2849 11.6924 3.99999C11.6924 3.71508 11.5927 3.47291 11.3933 3.27346C11.1938 3.07402 10.9516 2.9743 10.6667 2.9743C10.3818 2.9743 10.1396 3.07402 9.9402 3.27346C9.74076 3.47291 9.64103 3.71508 9.64103 3.99999ZM8.03853 3.99999C8.03853 3.25925 8.29136 2.63601 8.79701 2.13027C9.30275 1.62462 9.92599 1.3718 10.6667 1.3718C11.4075 1.3718 12.0307 1.62462 12.5364 2.13027C13.0421 2.63601 13.2949 3.25925 13.2949 3.99999C13.2949 4.6011 13.1152 5.14115 12.7559 5.62013C12.3966 6.09911 11.941 6.40805 11.3889 6.54694L11.3889 11L10.656 11.6667L9.39325 10.8868L10.0834 10L9.39325 9.19013L9.9445 8.43582L9.9445 6.54694C9.42099 6.42444 8.97247 6.12707 8.59895 5.65485C8.22534 5.18263 8.03853 4.63101 8.03853 3.99999Z"
      fill="currentColor"
    />
  </g>,
  { viewBox: '0 0 14 16' },
);
