import { Inline, InlineProps } from '@superdispatch/ui';
import { Children, Fragment } from 'react';

export function InlineBulletItems({ children, ...props }: InlineProps) {
  const items = Children.toArray(children).filter(Boolean);
  return (
    <Inline space="xxsmall" {...props}>
      {items.map((item, index) => (
        <Fragment key={index}>
          {item}
          {index !== items.length - 1 && '·'}
        </Fragment>
      ))}
    </Inline>
  );
}
