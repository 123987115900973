import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { DispatcherDTO } from 'shared/modules/dispatcher/DispatcherDTO';

export function shouldShowUserChangePassword(
  date: string,
  password_changed_at?: string | null,
) {
  const created_date = DateTime.fromISO(date);
  //Check if user created before May 8th 2023
  const before = DateTime.fromISO('2023-05-08');
  return created_date < before && !password_changed_at;
}

export function useDispatcherPasswordEnhancement(data?: DispatcherDTO) {
  const shouldShowPasswordReminder = useMemo(() => {
    if (data?.created_at) {
      return shouldShowUserChangePassword(data.created_at, data.p_changed_at);
    }
    return false;
  }, [data?.created_at, data?.p_changed_at]);

  return shouldShowPasswordReminder;
}
