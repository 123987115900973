import { AppBar, Toolbar } from '@material-ui/core';
import { Color, Inline } from '@superdispatch/ui';
import React from 'react';
import { LogoWithBrand } from 'shared/icons/LogoWithBrand';
import styled from 'styled-components';

const HeaderContainer = styled.div`
  margin: auto;
  max-width: 1024px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LogoLink = styled.a`
  color: ${Color.Dark500};
  text-decoration: none;
`;

interface Props {
  actions?: React.ReactNode;
}

export function HeaderWithLogo({ actions }: Props) {
  return (
    <AppBar position="sticky">
      <Toolbar>
        <HeaderContainer>
          <LogoLink href="https://superdispatch.com/">
            <Inline verticalAlign="center" space="xsmall">
              <LogoWithBrand />
            </Inline>
          </LogoLink>

          {actions}
        </HeaderContainer>
      </Toolbar>
    </AppBar>
  );
}
