import { EMPTY_ERROR_MESSAGE } from '@superdispatch/forms';
import { passwordCheckTypes } from 'shared/utils/PasswordUtils';
import {
  yupBoolean,
  yupEnum,
  yupNumber,
  yupObject,
  yupOmit,
  yupPhone,
  yupString,
} from 'shared/utils/YupUtils';
import { InferType } from 'yup';

export type DriverDTO = InferType<typeof driverSchema>;
export const driverSchema = yupObject({
  guid: yupString(),
  inbox: yupString(),
  connection_type: yupEnum(['invite', 'join_request']),
  connection_status: yupEnum([
    'pending',
    'activated',
    'declined',
    'deactivated',
  ]),
  is_activated: yupBoolean(),
  is_suspended: yupBoolean(),
  has_dispatcher: yupBoolean(),

  notes: yupString(),
  name: yupString().required('Enter name').max(100),
  email: yupString().email().required('Enter email'),
  phone: yupPhone().required('Enter phone'),

  trailer: yupObject({
    capacity: yupNumber()
      .integer()
      .min(1, 'No less than 1')
      .max(99, 'No more than 99'),
  }),

  drivers_licence: yupObject({
    state: yupString(),
    number: yupString(),
  }),
});

export type DriverEditLegacyDTO = InferType<typeof driverEditLegacySchema>;
export const driverEditLegacySchema = driverSchema.shape({
  guid: yupOmit,
  inbox: yupOmit,
  is_activated: yupOmit,
  is_suspended: yupOmit,
  drivers_licence: yupOmit,
});

export type DriverEditDTO = InferType<typeof driverEditLegacySchema>;
export const driverEditSchema = driverSchema.shape({
  guid: yupOmit,
  inbox: yupOmit,
  is_activated: yupOmit,
  is_suspended: yupOmit,
  drivers_licence: yupOmit,
  name: yupString(),
});

export type DriverActivationDetailsDTO = InferType<
  typeof driverActivationDetailsSchema
>;
export const driverActivationDetailsSchema = yupObject({
  carrier_name: yupString(),
  email: yupString(),
  status: yupEnum(['inactive', 'activated', 'deactivated']),
});

export type DeactivationDriverDTO = InferType<typeof deactivateDriverDTO>;
export const deactivateDriverDTO = yupObject({
  guid: yupString().required(),
  reason: yupString().required(),
});

export type DriverInvitationDTO = InferType<typeof driverInvitationSchema>;
export const driverInvitationSchema = yupObject({
  carrier_name: yupString(),
  contact_name: yupString(),
  email: yupString().email().required(),
  full_name: yupString(),
  phone_number: yupPhone().required(),
  status: yupEnum(['pending', 'activated', 'declined', 'deactivated']),
});

export type DriverActivateDTO = InferType<typeof driverActivationSchema>;
export const driverActivationSchema = yupObject({
  email: yupString(),
  full_name: yupString().required(),
  phone_number: yupPhone().required(),
  is_subscribed: yupBoolean(),
  password: yupString()
    .required(EMPTY_ERROR_MESSAGE)
    .min(8, EMPTY_ERROR_MESSAGE)
    .matches(passwordCheckTypes.toLowerCaseAndUpperCase, EMPTY_ERROR_MESSAGE)
    .matches(passwordCheckTypes.toSpecialCharacter, EMPTY_ERROR_MESSAGE)
    .matches(passwordCheckTypes.toNumber, EMPTY_ERROR_MESSAGE),
});

export type InviteDriverDTO = InferType<typeof inviteDriverDTO>;
export const inviteDriverDTO = yupObject({
  email: yupString().email().required(),
  phone_number: yupPhone().required(),
  name: yupString(),
});

export type DriverNoteDTO = InferType<typeof driverNoteSchema>;
export const driverNoteSchema = yupObject({
  notes: yupString().max(300),
});
