import { useCallback } from 'react';
import { useTabNotification } from 'shared/helpers/notifications/TabNotification';
import { openExternalURL } from 'shared/helpers/URLHelpers';
import { showBrowserNotification } from './BrowserNotification';
import { useSnackbarNotification } from './SnackbarNotification';

export function usePushNotification() {
  const showSnackbarNotification = useSnackbarNotification();
  const { addMessage, deleteMessage } = useTabNotification();

  return useCallback(
    (
      tag: string,
      title: string,
      body: string,
      actionUrl: string,
      fallbackActionText: string,
    ) => {
      showBrowserNotification(tag, title, body, actionUrl).catch(() => {
        addMessage(tag);
        showSnackbarNotification(
          title,
          body,
          fallbackActionText,
          () => {
            openExternalURL(actionUrl);
          },
          () => {
            deleteMessage(tag);
          },
        );
      });
    },
    [addMessage, deleteMessage, showSnackbarNotification],
  );
}
