import { Typography } from '@material-ui/core';
import { Stack, useUID } from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import { ReactNode, useState } from 'react';
import styled from 'styled-components';
import { MarkerContainer } from './ImageMarker';

const StyledImg = styled.img`
  width: 100%;
`;

interface DocumentPreviewProps {
  title: string;
  imgSrc: string;
  header?: ReactNode;
  footer?: ReactNode;
  children?: ReactNode;
}

export function DocumentPreview({
  title,
  imgSrc,
  header,
  footer,
  children,
}: DocumentPreviewProps) {
  const uid = useUID();
  const [isImageReady, setIsImageReady] = useState(false);

  return (
    <Box
      aria-labelledby={uid}
      maxWidth={['496px', '496px', '448px']}
      backgroundColor="Silver200"
      paddingTop="medium"
      paddingBottom="medium"
      paddingLeft="xlarge"
      paddingRight="xlarge"
      fontSize="small"
      position="relative"
    >
      <Stack space="small">
        <Typography id={uid} variant="h4">
          {title}
        </Typography>

        {header}

        <Box
          position="relative"
          padding="xsmall"
          borderRadius="small"
          backgroundColor="White"
          borderWidth="small"
          borderColor="Silver400"
        >
          <StyledImg
            src={imgSrc}
            onLoad={() => {
              setIsImageReady(true);
            }}
          />

          {isImageReady && <MarkerContainer>{children}</MarkerContainer>}
        </Box>

        {footer}
      </Stack>
    </Box>
  );
}
