import { Typography } from '@material-ui/core';
import { useResponsiveValue } from '@superdispatch/ui';
import { AnchorButton } from '@superdispatch/ui-lab';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { LoginLayout } from 'shared/auth/core/LoginLayout';
import { ForgotPasswordPage } from 'shared/auth/ForgotPasswordPage';
import { LoginPage } from 'shared/auth/LoginPage';
import { ResetPasswordPage } from 'shared/auth/ResetPasswordPage';
import { identifyAnalytics } from 'shared/helpers/Analytics';
import { showIntercomLauncher } from 'shared/helpers/Intercom';
import { useNavigate } from 'shared/routing/react-router-6';
import styled from 'styled-components';
import { trackLoginEvent } from './data/LoginAnalytics';
import { getSignupPageUrl } from './SignupPageUtils';

const Copyright = styled.div`
  margin: 16px auto;
`;

export function LoginContainerPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const isMobile = useResponsiveValue(true, false);
  const [email, setEmail] = useState('');

  const [currentPage, setCurrentPage] = useState<
    'login' | 'reset-password' | 'forgot-password'
  >('login');

  useEffect(() => {
    identifyAnalytics();
    return showIntercomLauncher;
  }, []);

  useEffect(() => {
    for (const page of ['reset-password', 'forgot-password'] as const) {
      if (location.pathname.startsWith(`/login/${page}`)) {
        setCurrentPage(page);
        navigate({ ...location, pathname: '/login' });
      }
    }
  }, [navigate, location]);

  return (
    <LoginLayout
      header={
        <AnchorButton
          variant="neutral"
          size={isMobile ? 'small' : 'medium'}
          href={getSignupPageUrl()}
          onClick={() => {
            trackLoginEvent({ name: 'CTMS: Clicked Sign Up' });
          }}
        >
          Sign Up
        </AnchorButton>
      }
      footerAction={
        <Copyright>
          <Typography align="center" color="textSecondary">
            &copy; {new Date().getFullYear()} Super Dispatch. All rights
            reserved.
          </Typography>
        </Copyright>
      }
    >
      {currentPage === 'login' && (
        <LoginPage
          initialEmail={email}
          onEmailChange={setEmail}
          onOpenForgotPassword={() => {
            setCurrentPage('forgot-password');
          }}
        />
      )}

      {currentPage === 'forgot-password' && (
        <ForgotPasswordPage
          initialEmail={email}
          onEmailChange={setEmail}
          onOpenLogin={() => {
            setCurrentPage('login');
          }}
        />
      )}

      {currentPage === 'reset-password' && (
        <ResetPasswordPage
          onOpenLogin={() => {
            setCurrentPage('login');
          }}
        />
      )}
    </LoginLayout>
  );
}
