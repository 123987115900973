import { OffersDeclineDialogBase } from '../../core/OffersDeclineDialogBase';
import { trackPublicOfferEvent } from '../data/PublicOfferAnalytics';
import {
  publicOfferDeclinePayloadSchema,
  PublicOfferDTO,
} from '../data/PublicOfferDTO';
import { usePublicOfferAPI } from '../data/PublicOffersAPI';

interface PublicOfferDeclineDialogProps {
  offer: PublicOfferDTO;
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
}

export function PublicOfferDeclineDialog({
  offer,
  onSuccess,
  ...props
}: PublicOfferDeclineDialogProps) {
  const { declinePublicOffer } = usePublicOfferAPI();

  return (
    <OffersDeclineDialogBase
      {...props}
      validationSchema={publicOfferDeclinePayloadSchema}
      onSubmit={(values) =>
        declinePublicOffer(offer.guid, !!offer.is_first_offer, values)
      }
      onSubmitSuccess={(_response, values) => {
        trackPublicOfferEvent({
          name: 'Declined Load Offer',
          offer,
          payload: values,
        });
        onSuccess();
      }}
    />
  );
}
