import { ONE_SECOND } from '@datadog/browser-core';
import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useUID } from '@superdispatch/ui';
import { useEffect, useMemo, useState } from 'react';
import { useDebouncedValue } from 'shared/helpers/ReactHelpers';
import { useNavigate } from 'shared/routing/react-router-6';
import { useDriverList } from '../../drivers/data/DriversAPI';
import { TripDriverDTO, TripDTO } from '../data/TripsDTO';
import { useMapDrivers } from './TripHelpers';

interface TripAssignDriverProps {
  trip: TripDTO;
  path: string;
}

export function TripAssignDriver({ trip, path: url }: TripAssignDriverProps) {
  const driverFieldID = useUID();
  const navigate = useNavigate();
  const [selected, setSelected] = useState<TripDriverDTO>({
    ...trip.driver,
    name: trip.driver?.name || trip.driver?.email,
  } as TripDriverDTO);
  const [search, setSearch] = useState<string>('');
  const unassignedValue = '-1';
  const debouncedText = useDebouncedValue(search, ONE_SECOND);

  const queryParams = {
    exclude_suspended: true,
    page_size: 100,
    q: debouncedText,
  };

  const driverList = useDriverList({
    ...queryParams,
    exclude_join_requests: true,
  });

  const { drivers } = useMapDrivers(driverList, trip);

  const driverListWithDefaultDriver = useMemo(() => {
    if (drivers) {
      return [
        { name: '*Unassigned', guid: '-1' },
        ...drivers.map((driver) => ({
          name: driver.name || driver.email,
          guid: driver.guid,
        })),
      ];
    }
    return [];
  }, [drivers]);

  useEffect(() => {
    if (trip.driver) {
      setSelected({
        ...trip.driver,
        name: trip.driver.name || trip.driver.email,
      });
    }
  }, [trip]);

  return (
    <Autocomplete
      options={driverListWithDefaultDriver}
      disableClearable={true}
      disabled={!!trip.archived}
      value={selected}
      aria-label="driver-list"
      getOptionSelected={(option, value) => option.guid === value.guid}
      getOptionLabel={(option) => option.name}
      onChange={(_, selectedValue) => {
        const nextDriverGUID = selectedValue.guid;
        const currentDriverGUID = trip.driver?.guid;
        if (
          nextDriverGUID !== unassignedValue &&
          currentDriverGUID !== nextDriverGUID
        ) {
          navigate(`${url}/assign/${nextDriverGUID}`);
        } else if (currentDriverGUID && nextDriverGUID === unassignedValue) {
          navigate(`${url}/unassign`);
        }
      }}
      loading={driverList.isFetching}
      renderInput={(params) => (
        <TextField
          {...params}
          id={driverFieldID}
          label="Assigned to"
          aria-label="driver-list-input"
          placeholder="Search for drivers..."
          onChange={(event) => {
            setSearch(event.target.value);
          }}
          variant="outlined"
          fullWidth={true}
        />
      )}
    />
  );
}
