import { NullableDateString } from '@superdispatch/dates';
import userflow from 'userflow.js';

const USERFLOW_TOKEN =
  import.meta.env.MODE !== 'production'
    ? 'ct_dyg4e3v4frdirlbg4afly7hp4e'
    : 'ct_zurw7eczhnb4jg3axxuvacgmne';

interface UserflowProps {
  guid: string;
  name: string;
  email: string;
  created_at: string | NullableDateString;
}

export function setupUserflow(props: UserflowProps) {
  userflow.init(USERFLOW_TOKEN);
  return userflow.identify(props.guid, {
    name: props.name,
    email: props.email,
    signed_up_at: props.created_at,
  });
}
