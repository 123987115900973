import { Box } from '@superdispatch/ui-lab';
import { PhoneVerification } from '../phone-verification/PhoneVerification';
import { PhoneVerificationComplete } from '../phone-verification/PhoneVerificationComplete';

interface StepPhoneVerificationPageProps {
  onComplete: () => void;
  onBack: () => void;
  isPhoneVerificationComplete: boolean;
}

export function StepPhoneVerificationPage({
  onComplete,
  onBack,
  isPhoneVerificationComplete,
}: StepPhoneVerificationPageProps) {
  return (
    <Box padding={['small', 'none']}>
      {isPhoneVerificationComplete ? (
        <PhoneVerificationComplete onComplete={onComplete} />
      ) : (
        <PhoneVerification onBack={onBack} onComplete={onComplete} />
      )}
    </Box>
  );
}
