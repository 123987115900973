import {
  Dialog,
  DialogActions,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import { useSnackbarStack } from '@superdispatch/ui';
import { Box, Button } from '@superdispatch/ui-lab';
import { getDriverLabel } from '../drivers/data/DriverPageUtils';
import { useDeleteTrackingDriver } from './data/TrackingAPI';
import { TrackingDriverDTO } from './data/TrackingDTO';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  driver: TrackingDriverDTO;
}

interface FormInternalProps {
  isSubmitting: boolean;
  handleSubmit: () => void;
}

function FormInternal({
  isOpen,
  onClose,
  driver,
  isSubmitting,
  handleSubmit,
}: Props & FormInternalProps) {
  const driverLabel = getDriverLabel({
    name: driver.name || '',
    email: driver.email || '',
  });

  return (
    <Dialog
      maxWidth="xs"
      open={isOpen}
      onClose={!isSubmitting ? onClose : undefined}
    >
      <Box margin="xsmall">
        <DialogTitle>
          <Typography variant="body2">
            Are you sure you want to remove {driverLabel} from GPS tracking?
          </Typography>
        </DialogTitle>
        <DialogActions>
          <Button variant="neutral" disabled={isSubmitting} onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="critical"
            pending={isSubmitting}
            autoFocus={true}
            onClick={() => {
              handleSubmit();
            }}
          >
            Remove
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
}

export default function TrackingRemoveDriverConfirm({
  isOpen,
  onClose,
  driver,
}: Props) {
  const { addSnackbar } = useSnackbarStack();
  const driverLabel = getDriverLabel({
    name: driver.name || '',
    email: driver.email || '',
  });

  const deleteDriver = useDeleteTrackingDriver({
    onSuccess() {
      onClose();
      addSnackbar(`${driverLabel} has been removed from tracking`, {
        variant: 'success',
      });
    },
    onError() {
      addSnackbar('Error occurred while deleting driver', {
        variant: 'error',
      });
    },
  });

  return (
    <form>
      <FormInternal
        isOpen={isOpen}
        onClose={onClose}
        driver={driver}
        isSubmitting={deleteDriver.isLoading}
        handleSubmit={() => {
          deleteDriver.mutate(driver.id);
        }}
      />
    </form>
  );
}
