import { Color } from '@superdispatch/ui';
import { createSvgIcon } from 'shared/icons/IconUtils';

export const VerificationIcon = createSvgIcon(
  'VerificationIcon',
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M14.2 3.78963L12 2L9.80004 3.78963L7 3.33975L5.98959 5.98959L3.33975 7L3.78963 9.80004L2 12L3.78963 14.2L3.33975 17L5.98959 18.0104L7 20.6603L9.80004 20.2104L12 22L14.2 20.2104L17 20.6603L18.0104 18.0104L20.6603 17L20.2104 14.2L22 12L20.2104 9.80004L20.6603 7L18.0104 5.98959L17 3.33975L14.2 3.78963ZM15.7321 8.08228L10.3805 13.342L8.27417 11.2698L6.96036 12.5532L10.3804 15.9178L17.0386 9.36761L15.7321 8.08228Z"
    fill={Color.Dark100}
  />,
  {
    viewBox: '0 0 24 24',
    style: { height: '24px', width: '24px' },
  },
);
