import {
  Checkbox,
  IconButton,
  Link,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
} from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import { useSnackbarStack } from '@superdispatch/ui';
import React, { useState } from 'react';
import { FormattedRelativeTimeToNow } from 'shared/ui/FormattedRelativeTimeToNow';
import styled from 'styled-components';
import { getDriverLabel } from '../drivers/data/DriverPageUtils';
import { useNotifyTrackingDriver } from './data/TrackingAPI';
import { getTrackingDriverStatus, TrackingDriverDTO } from './data/TrackingDTO';
import TrackingRemoveDriverConfirm from './TrackingRemoveDriverConfirm';

const StyledListItemText = styled(ListItemText)`
  word-break: break-all;
`;

interface TrackingSidebarItem
  extends React.ButtonHTMLAttributes<HTMLDivElement> {
  checked: boolean;
  driver: TrackingDriverDTO;
  onCheck: () => void;
}

export function TrackingSidebarItem({
  checked,
  driver,
  onCheck,
  onMouseEnter,
  onMouseLeave,
  ...props
}: TrackingSidebarItem) {
  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null);
  const { addSnackbar } = useSnackbarStack();
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [isNotified, setIsNotified] = useState(false);

  const trackingStatus = getTrackingDriverStatus(driver);
  const notificationType =
    trackingStatus === 'geolocation_inactive'
      ? 'activate_geolocation'
      : trackingStatus === 'tracking_inactive'
      ? 'activate_tracking'
      : null;

  const canBeChecked =
    trackingStatus === 'tracking_active' && !!driver.last_location;

  const driverLabel = getDriverLabel({
    name: driver.name || '',
    email: driver.email || '',
  });

  const notifyDriver = useNotifyTrackingDriver(notificationType, {
    onMutate() {
      addSnackbar(`Sending notification to ${driverLabel}…`);
    },
    onSuccess() {
      setIsNotified(true);
      addSnackbar(`Driver ${driverLabel} has notified.`, {
        variant: 'success',
      });
    },
    onError() {
      addSnackbar('Error occurred while notifying the driver', {
        variant: 'error',
      });
    },
  });

  return (
    <>
      {isConfirmDialogOpen && (
        <TrackingRemoveDriverConfirm
          driver={driver}
          isOpen={isConfirmDialogOpen}
          onClose={() => {
            setIsConfirmDialogOpen(false);
          }}
        />
      )}

      <ListItem
        {...props}
        button={
          canBeChecked as true /* @see https://github.com/mui-org/material-ui/pull/15049  */
        }
        onClick={canBeChecked ? onCheck : undefined}
        onMouseEnter={canBeChecked ? onMouseEnter : undefined}
        onMouseLeave={canBeChecked ? onMouseLeave : undefined}
      >
        <ListItemIcon>
          <Checkbox
            inputProps={{ 'aria-labelledby': `driver-${driver.id}` }}
            disabled={!canBeChecked}
            checked={canBeChecked && checked}
            tabIndex={-1}
            disableRipple={true}
            color="primary"
          />
        </ListItemIcon>
        <StyledListItemText
          primary={
            <span id={`driver-${driver.id}`}>
              {driverLabel || 'No Driver Name'}
            </span>
          }
          secondary={
            <>
              {!driver.disabled && driver.last_location?.time ? (
                <>
                  Last location received{' '}
                  <FormattedRelativeTimeToNow
                    date={driver.last_location.time}
                  />
                  .
                </>
              ) : isNotified ? (
                `OK, we’ve notified ${driverLabel}.`
              ) : driver.notification?.delivered &&
                driver.notification.result === 'ignored' ? (
                `${driverLabel} did not accept your request to activate GPS tracking.`
              ) : trackingStatus === 'geolocation_inactive' ? (
                `${driverLabel} disabled location services.`
              ) : trackingStatus === 'tracking_inactive' ? (
                `${driverLabel} has turned off tracking from the app.`
              ) : (
                'Waiting for confirmation from mobile device.'
              )}

              {!isNotified && notificationType && (
                <>
                  {' '}
                  <Link
                    component="button"
                    disabled={notifyDriver.isLoading}
                    onClick={() => {
                      notifyDriver.mutate(driver.id);
                    }}
                  >
                    NOTIFY DRIVER
                  </Link>
                </>
              )}
            </>
          }
        />
        <ListItemSecondaryAction>
          <IconButton
            edge="end"
            aria-label="Driver Options Menu"
            onClick={(event) => {
              setAnchorElement(event.currentTarget);
            }}
          >
            <MoreVert />
          </IconButton>

          <Menu
            anchorEl={anchorElement}
            open={Boolean(anchorElement)}
            onClose={() => {
              setAnchorElement(null);
            }}
          >
            <MenuItem
              onClick={() => {
                setAnchorElement(null);
                setIsConfirmDialogOpen(true);
              }}
            >
              Remove Driver
            </MenuItem>
          </Menu>
        </ListItemSecondaryAction>
      </ListItem>
    </>
  );
}
