import { Typography } from '@material-ui/core';
import { useResponsiveValue } from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useCarrierInfo } from 'shared/modules/carrier-profile/CarrierInfo';
import { CarrierInfoDTO } from 'shared/modules/carrier-profile/CarrierInfoDTO';
import { useVerifiedCarrierApplicationStatus } from 'shared/modules/carrier-profile/VerifiedCarrierApplicationStatus';
import { VerifiedCarrierApplicationStatusDTO } from 'shared/modules/carrier-profile/VerifiedCarrierApplicationStatusDTO';
import { ProgressStep, ProgressStepper } from 'shared/ui/ProgressStepper';
import { useIsCarrierWithoutUSDOT } from '../data/CarrierPerformanceAPI';

const STEP_COUNT = 4;

function getCurrentStep(routeMatchPath?: string) {
  switch (routeMatchPath) {
    case 'step-4':
      return 4;
    case 'step-3':
      return 3;
    case 'step-2':
      return 2;
    default:
      return 1;
  }
}

export function isIdentificationStepComplete(
  progress: VerifiedCarrierApplicationStatusDTO['verified_carrier_application']['progress'],
  carrierInfo: CarrierInfoDTO,
  isNonUSDOTCarrier: boolean,
) {
  const isAutoTowingComplete =
    (carrierInfo.logistics_type === 'auto_hauler' ||
      carrierInfo.logistics_type === 'towing') &&
    !isNonUSDOTCarrier &&
    !!progress.w9_step_is_complete &&
    !!progress.usdot_step_is_complete;

  const isDriveawayComplete =
    carrierInfo.logistics_type === 'driveaway' &&
    !isNonUSDOTCarrier &&
    !!progress.w9_step_is_complete &&
    !!progress.usdot_step_is_complete &&
    !!progress.transporter_plate_registration_is_complete;

  const isAutoTowingNonUSDOTComplete =
    (carrierInfo.logistics_type === 'auto_hauler' ||
      carrierInfo.logistics_type === 'towing') &&
    isNonUSDOTCarrier &&
    !!progress.w9_step_is_complete &&
    !!progress.state_motor_carrier_permit_is_complete;

  const isDriveawayNonUSDOTComplete =
    carrierInfo.logistics_type === 'driveaway' &&
    isNonUSDOTCarrier &&
    !!progress.w9_step_is_complete &&
    !!progress.business_license_is_complete &&
    !!progress.transporter_plate_registration_is_complete;

  return (
    isAutoTowingComplete ||
    isDriveawayComplete ||
    isAutoTowingNonUSDOTComplete ||
    isDriveawayNonUSDOTComplete
  );
}

interface StepTitleProps {
  step: number;
}

function StepTitle({ step }: StepTitleProps) {
  const isMobile = useResponsiveValue(true, false);
  return (
    <Typography component="span" variant="inherit" color="inherit">
      {isMobile ? `STEP ${step} OF ${STEP_COUNT}` : `STEP ${step}`}
    </Typography>
  );
}

interface ProfileVerifiedCarrierApplicationTabsProps {
  type?: 'dialog' | 'page';
  activeStep?: number;
}

export function ProfileVerifiedCarrierApplicationTabs({
  type = 'page',
  activeStep,
}: ProfileVerifiedCarrierApplicationTabsProps) {
  const params = useParams<{ step?: string }>();
  const currentStep = getCurrentStep(params.step);
  const dialogStep = Number(activeStep);

  const { data } = useVerifiedCarrierApplicationStatus();
  const { data: carrierInfo } = useCarrierInfo();
  const isNonUSDOTCarrier = useIsCarrierWithoutUSDOT();
  const progress = data?.verified_carrier_application.progress;
  const isStep1Complete = !!progress?.fmcsa_phone_verification_is_complete;
  const isStep2Complete = !!progress?.carrier_info_step_is_complete;
  const isStep3Complete = !!progress?.cargo_insurance_step_is_complete;

  const isStep4Complete =
    !!progress &&
    !!carrierInfo &&
    isIdentificationStepComplete(progress, carrierInfo, isNonUSDOTCarrier);

  const progressPercent = useMemo(() => {
    return Math.floor((currentStep / STEP_COUNT) * 100);
  }, [currentStep]);

  return (
    <Box maxWidth="830px" marginLeft="auto" marginRight="auto">
      <ProgressStepper progress={progressPercent}>
        <ProgressStep
          subtitle="Phone Verification"
          title={<StepTitle step={1} />}
          isCompleted={isStep1Complete}
          isHighlighted={type === 'dialog' && dialogStep > 1}
          isActive={type === 'page' ? currentStep === 1 : dialogStep === 1}
        />
        <ProgressStep
          subtitle="Carrier Info"
          isCompleted={isStep2Complete}
          title={<StepTitle step={2} />}
          isHighlighted={type === 'dialog' && dialogStep > 2}
          isActive={type === 'page' ? currentStep === 2 : dialogStep === 2}
        />
        <ProgressStep
          subtitle="Insurance"
          isCompleted={isStep3Complete}
          title={<StepTitle step={3} />}
          isHighlighted={type === 'dialog' && dialogStep > 3}
          isActive={type === 'page' ? currentStep === 3 : dialogStep === 3}
        />
        <ProgressStep
          isCompleted={isStep4Complete}
          subtitle="Identification"
          title={<StepTitle step={4} />}
          isActive={type === 'page' ? currentStep === 4 : dialogStep === 4}
        />
      </ProgressStepper>
    </Box>
  );
}
