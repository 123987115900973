import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import { Inline } from '@superdispatch/ui';
import { Button } from '@superdispatch/ui-lab';

interface BillingUpgradeDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onClick: () => void;
  isLoading: boolean;
}

export function BillingUpgradeDialog({
  isOpen,
  onClick,
  onClose,
  isLoading,
}: BillingUpgradeDialogProps) {
  return (
    <Dialog fullWidth={true} maxWidth="sm" open={isOpen} onClose={onClose}>
      <DialogTitle>Plan Update</DialogTitle>
      <DialogContent>
        <Typography>
          After adding a new driver your plan will be updated
        </Typography>
      </DialogContent>
      <DialogActions>
        <Inline space="small">
          <Button disabled={isLoading} variant="neutral" onClick={onClose}>
            Cancel
          </Button>
          <Button pending={isLoading} onClick={onClick}>
            Confirm
          </Button>
        </Inline>
      </DialogActions>
    </Dialog>
  );
}
