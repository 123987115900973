import { FormikProvider } from 'formik';
import { useMemo } from 'react';
import { APIResponse } from 'shared/api/APIClient';
import { useAppFormik } from 'shared/form/AppFormik';
import { useFormikComposerForm } from 'shared/form/FormikComposer';
import { isPossibleUUID } from 'shared/utils/StringUtils';
import { yupArray, yupObject } from 'shared/utils/YupUtils';
import { LoadAttachmentsCard } from '../core/LoadAttachmentsCard';
import { useAttachmentAPI } from '../data/AttachmentsAPI';
import { loadAttachmentEditSchema, LoadDTO } from '../data/LoadDTO';

const editLoadAttachmentsSchema = yupObject({
  attachments: yupArray(loadAttachmentEditSchema).required(),
});

export interface EditLoadPageAttachmentsProps {
  load: LoadDTO;
}

export function EditLoadPageAttachments({
  load,
}: EditLoadPageAttachmentsProps) {
  const { createAttachment, deleteAttachment } = useAttachmentAPI();
  const initialValues = useMemo(
    () => ({ attachments: load.attachments || [] }),
    [load.attachments],
  );
  const formik = useAppFormik({
    initialValues,
    validationSchema: editLoadAttachmentsSchema,
    onSubmit(values) {
      const currentGUIDs = new Set<string>();
      const requests: Array<Promise<APIResponse>> = [];

      for (const { guid, ...attachment } of values.attachments) {
        if (isPossibleUUID(guid)) {
          currentGUIDs.add(guid);
        } else {
          requests.push(
            createAttachment({ ...attachment, load_guid: load.guid }),
          );
        }
      }

      for (const { guid } of initialValues.attachments) {
        if (!currentGUIDs.has(guid)) {
          requests.push(deleteAttachment(guid));
        }
      }

      return Promise.all(requests).then(
        ([firstResponse]) => firstResponse as APIResponse,
      );
    },
    getErrorMessage({ message }) {
      return message || 'Something went wrong, contact support.';
    },
  });

  const nodeRef = useFormikComposerForm('attachments', formik);

  return (
    <FormikProvider value={formik}>
      <LoadAttachmentsCard nodeRef={nodeRef} />
    </FormikProvider>
  );
}
