import { useCallback, useMemo } from 'react';
import { LocalStore, useLocalStore } from 'shared/helpers/Store';
import { tryParseJSON } from 'shared/utils/DataUtils';

interface SelectedDriver {
  guid: string;
  name: string;
}

export function useSelectedDrivers() {
  const key = 'load_suggestions_selected_drivers';
  const storageValue = useLocalStore(key);

  const drivers = useMemo(() => {
    if (storageValue) {
      return tryParseJSON<SelectedDriver[]>(storageValue) || [];
    }
    return [];
  }, [storageValue]);

  const setDrivers = useCallback((value: SelectedDriver[]) => {
    LocalStore.set(key, JSON.stringify(value));
  }, []);

  return [drivers, setDrivers] as const;
}
