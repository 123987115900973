import { useConstant } from '@superdispatch/hooks';
import { createBrowserHistory } from 'history';
import { ReactNode } from 'react';
import { Router } from 'react-router-dom';

export const basePath: string = import.meta.env.BASE_URL;

export interface AppRouterProviderProps {
  children?: ReactNode;
}

export function AppRouterProvider({ children }: AppRouterProviderProps) {
  const history = useConstant(() =>
    createBrowserHistory({ basename: basePath }),
  );

  return <Router history={history}>{children}</Router>;
}
