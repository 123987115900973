import { useMemo } from 'react';
import { DRIVER_ACCOUNT_TOKEN } from 'shared/auth/AuthToken';
import { LocalStore, useLocalStore } from 'shared/helpers/Store';

export function useDriverAccount() {
  const token = useLocalStore(DRIVER_ACCOUNT_TOKEN, '');

  return useMemo(() => {
    function saveDriverToken(driverToken: string) {
      LocalStore.set(DRIVER_ACCOUNT_TOKEN, driverToken);
    }

    function removeDriverToken() {
      LocalStore.delete(DRIVER_ACCOUNT_TOKEN);
    }

    return {
      saveDriverToken,
      removeDriverToken,
      token,
    };
  }, [token]);
}
