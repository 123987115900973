import { Color, Inline, Tag } from '@superdispatch/ui';
import { isWebView } from 'shared/constants/AppTypeConstants';
import { SuperCarrierBadgeIcon } from 'shared/icons/SuperCarrierBadgeIcon';
import { useVerifiedCarrierApplicationStatus } from '../VerifiedCarrierApplicationStatus';

export function VerificationBadge() {
  const { data: verificationStatus } = useVerifiedCarrierApplicationStatus();
  const status = verificationStatus?.verified_carrier_application.status;

  switch (status) {
    case 'approved':
      return <Verified />;
    case 'pending':
      return <PendingVerification />;
    case 'reviewed':
      return <Reviewed />;
    case 'revoked':
      return <Revoked />;
    case 'declined':
      return <Declined />;
    default:
      return null;
  }
}

export function Verified() {
  return !isWebView ? (
    <Tag color="green" variant="subtle" align="center">
      <Inline space="none" verticalAlign="center">
        <SuperCarrierBadgeIcon fontSize="small" />
        Verified
      </Inline>
    </Tag>
  ) : (
    <SuperCarrierBadgeIcon htmlColor={Color.Green300} fontSize="small" />
  );
}

export function PendingVerification() {
  return (
    <Tag variant="subtle" color="yellow">
      Pending Verification
    </Tag>
  );
}

export function Reviewed() {
  return (
    <Tag variant="subtle" color="yellow" noWrap={false}>
      Insurance Verification Required
    </Tag>
  );
}

export function Revoked() {
  return (
    <Tag variant="subtle" color="red">
      Verification Revoked
    </Tag>
  );
}

export function Declined() {
  return (
    <Tag variant="subtle" color="red">
      Verification Declined
    </Tag>
  );
}
