import { FormikValues, getIn, setIn, useFormikContext } from 'formik';
import {
  FormikAddressAutofill,
  FormikAddressAutofillProps,
} from 'shared/form/FormikAddressAutofill';
import { joinStrings } from 'shared/utils/StringUtils';

export function FormikVenueAddressAutofill({
  name,
  ...props
}: Omit<FormikAddressAutofillProps, 'onGeocode'>) {
  const { setValues } = useFormikContext<FormikValues>();

  return (
    <FormikAddressAutofill
      {...props}
      name={`${name}.address`}
      onGeocode={(nextValue) => {
        void setValues(
          (values) =>
            setIn(values, name, {
              ...getIn(values, name),
              city: nextValue.place || '',
              zip: nextValue.postcode || '',
              state: nextValue.region_short || nextValue.region || '',
              address: joinStrings(
                ' ',
                nextValue.house_number,
                nextValue.address,
              ),
            }) as FormikValues,
        );
      }}
    />
  );
}
