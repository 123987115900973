import { useField, useFormikContext } from 'formik';
import {
  AddressAutofill,
  AddressAutofillProps,
} from 'shared/form/AddressAutofill';

export interface FormikAddressAutofillProps
  extends Omit<
    AddressAutofillProps,
    'value' | 'error' | 'onBlur' | 'onChange'
  > {
  name: string;
}

export function FormikAddressAutofill({
  name,
  disabled,
  helperText,
  ...props
}: FormikAddressAutofillProps) {
  const { isSubmitting } = useFormikContext();
  const [, { value, error, touched }, { setValue, setTouched }] =
    useField<string>(name);
  const errorText = touched && error;

  return (
    <AddressAutofill
      {...props}
      value={value || ''}
      error={!!errorText}
      helperText={errorText || helperText}
      disabled={disabled || isSubmitting}
      onBlur={(nextValue) => {
        void setTouched(true, false);
        void setValue(nextValue, true);
      }}
      onChange={(nextValue) => {
        void setValue(nextValue);
      }}
    />
  );
}
