import { requestCarrierAPI } from 'shared/api/APIClient';
import {
  APIQueryResult,
  useAPIQuery,
  UseAPIQueryOptions,
} from 'shared/api/APIQuery';
import {
  CarrierPerformanceDTO,
  carrierPerformanceSchema,
} from 'shared/modules/carrier-profile/data/CarrierPerformanceDTO';
import { hasOnlyDigits } from 'shared/utils/StringUtils';
import { useCarrierInfo } from '../CarrierInfo';

export function useCarrierPerformanceIndicators(
  options?: UseAPIQueryOptions<CarrierPerformanceDTO>,
): APIQueryResult<CarrierPerformanceDTO> {
  return useAPIQuery(
    ['carrier', 'performance'],
    () => requestCarrierAPI('GET /internal/web/carrier/performance/'),
    { ...options, schema: carrierPerformanceSchema },
  );
}

export function useIsCarrierWithoutUSDOT() {
  const { data } = useCarrierInfo();
  return !data?.usdot_number || !hasOnlyDigits(data.usdot_number);
}
