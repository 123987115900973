import { createContext, ReactNode, useMemo, useState } from 'react';
import { useNullableContext } from 'shared/hooks/useNullableContext';
import { LoadsAPI } from './LoadsAPI';

export interface LoadsContext {
  loadsAPI: LoadsAPI;
  loadPriceCalculationStatus: {
    isCalculating: boolean;
    setIsCalculating: (isCalculating: boolean) => void;
  };
}

const Context = createContext<null | LoadsContext>(null);

export function useLoadsContext() {
  return useNullableContext('LoadsContext', Context);
}

export interface LoadsContextProviderProps {
  children?: ReactNode;
}

export function LoadsContextProvider({ children }: LoadsContextProviderProps) {
  const ctx = useMemo(() => ({ loadsAPI: new LoadsAPI() }), []);
  const [isCalculating, setIsCalculating] = useState(false);

  return (
    <Context.Provider
      value={{
        ...ctx,
        loadPriceCalculationStatus: { isCalculating, setIsCalculating },
      }}
    >
      {children}
    </Context.Provider>
  );
}
