import { Card, Divider } from '@material-ui/core';
import { Column, Columns, Stack } from '@superdispatch/ui';
import { Box, TextBox } from '@superdispatch/ui-lab';
import styled from 'styled-components';
import DriverAppQR from '../assets/driver-app-qr.svg';
import PasswordUpdatedIcon from '../assets/password-updated-icon.svg';

const Container = styled.div`
  position: relative;
  margin: 0 auto 16px;
  max-width: 600px;
  display: flex;
  flex-direction: column;
`;

export function ResetPasswordSuccessDesktop() {
  return (
    <Container>
      <Box marginTop="large">
        <Card>
          <Box padding="large">
            <Stack space="medium">
              <Stack align="center" space="small">
                <img src={PasswordUpdatedIcon} />
                <TextBox variant="heading-2">Password Updated</TextBox>
              </Stack>
              <Box>
                <Divider variant="fullWidth" />
              </Box>
              <Stack align="center" space="medium">
                <TextBox variant="heading-6">WHAT ARE THE NEXT STEPS?</TextBox>
                <Columns align="center" space="medium">
                  <Column width="content">
                    <img src={DriverAppQR} />
                  </Column>
                  <Column width="fluid">
                    <Stack>
                      <TextBox variant="heading-2">Open the App</TextBox>
                      <TextBox color="secondary" variant="body">
                        Scan the QR code to download the Super Dispatch App and
                        access your loads using the password set earlier.
                      </TextBox>
                    </Stack>
                  </Column>
                </Columns>
              </Stack>
            </Stack>
          </Box>
        </Card>
      </Box>
    </Container>
  );
}
