import { Color } from '@superdispatch/ui';
import { createSvgIcon } from 'shared/icons/IconUtils';

export const LoadDetailsIcon = createSvgIcon(
  'LoadDetails',
  <path
    fill="currentColor"
    fillRule="evenodd"
    clipRule="evenodd"
    d="M11 14H4v-2h7v2zm3-4H4V8h10v2zm0-4H4V4h10v2zm2-6H2C.89 0 0 .89 0 2v14a2 2 0 002 2h14a2 2 0 002-2V2a2 2 0 00-2-2z"
  />,
  { htmlColor: Color.Dark400, viewBox: '0 0 18 18' },
);
