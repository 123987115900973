import {
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { Add, Clear, Search } from '@material-ui/icons';
import { Stack } from '@superdispatch/ui';
import { Box, Button } from '@superdispatch/ui-lab';
import { useState } from 'react';
import { useFlag } from 'shared/settings/FeatureToggles';
import { trackDispatchersEvent } from '../data/DispatchersAnalytics';
import { useDispatcherPageContext } from '../data/DispatchersProvider';
import { DispatcherInviteDialog } from './DispatcherInviteDialog';

export function DispatchersSidebarHeader() {
  const [isOpen, setOpen] = useState(false);
  const { searchQueryDrivers } = useDispatcherPageContext();
  const shouldShowDispatcherInviteButton = useFlag(
    'dispatcher_management_invite_form',
  );
  const [searchText, _, setSearchText] = searchQueryDrivers;

  function handleOpen() {
    trackDispatchersEvent({ name: 'Carrier Clicked Invite Dispatcher' });
    setOpen(true);
  }

  return (
    <>
      <DispatcherInviteDialog
        open={isOpen}
        onClose={() => {
          setOpen(false);
        }}
        onOpen={() => {
          setOpen(true);
        }}
      />
      <Stack space="small">
        <Tooltip
          placement="right"
          title={
            !shouldShowDispatcherInviteButton ? (
              <Stack space="none">
                <Typography>Will be available next week.</Typography>
                <Typography>Stay tuned!</Typography>
              </Stack>
            ) : (
              ''
            )
          }
        >
          <Box>
            <Button
              onClick={handleOpen}
              disabled={!shouldShowDispatcherInviteButton}
              fullWidth={true}
              startIcon={<Add />}
            >
              Invite Dispatcher
            </Button>
          </Box>
        </Tooltip>
        <TextField
          fullWidth={true}
          value={searchText}
          onChange={(event) => {
            setSearchText(event.target.value);
          }}
          placeholder="Search..."
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search color="action" />
              </InputAdornment>
            ),
            endAdornment: !!searchText && (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => {
                    setSearchText('');
                  }}
                >
                  <Clear color="action" />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>
    </>
  );
}
