import { useFlag } from 'shared/settings/FeatureToggles';
import { FormattedRelativeTimeToNow } from 'shared/ui/FormattedRelativeTimeToNow';
import styled from 'styled-components';
import { getDriverLabel } from '../drivers/data/DriverPageUtils';
import { useAddressPrediction } from './core/AddressPredictions';
import { TrackingDriverDTO } from './data/TrackingDTO';

const Title = styled.h4`
  font-size: 16px;
  font-weight: 400;
  margin: 0;
`;

const Caption = styled.p`
  font-size: 13px;
  font-weight: 400;
  color: #8c8c8c;
  margin-top: 5px;
`;

const Address = styled.p`
  width: 270px;
  font-size: 16px;
  font-weight: 400;
  min-height: 30px;
  margin-bottom: 0;
`;

interface Props {
  driver: TrackingDriverDTO;
  latLng?: google.maps.LatLngLiteral;
  isOpen: boolean;
}

export const TrackingInfoWindow = ({ driver, latLng, isOpen }: Props) => {
  const isPopupAddressDisabled = useFlag(
    'ctms_tracking_popup_address_disabled',
  );
  const address = useAddressPrediction(
    latLng ? [latLng.lng, latLng.lat] : undefined,
    isOpen && !isPopupAddressDisabled,
  );

  const driverLabel = getDriverLabel({
    name: driver.name || '',
    email: driver.email || '',
  });

  return (
    <div>
      <Title>{driverLabel}</Title>
      <Caption>
        {driver.device_name}
        {driver.last_location?.time && (
          <>
            <br />
            <FormattedRelativeTimeToNow date={driver.last_location.time} />
          </>
        )}
      </Caption>

      {!isPopupAddressDisabled && (
        <Address>{address ? address : 'Loading...'}</Address>
      )}
    </div>
  );
};
