import { FormControlLabel, Radio, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import {
  FormikNumberField,
  FormikRadioGroupField,
  FormikTextField,
  useFormikEnhanced,
} from '@superdispatch/forms';
import { Column, Columns, Stack, useSnackbarStack } from '@superdispatch/ui';
import { TextBox } from '@superdispatch/ui-lab';
import { FormikProvider } from 'formik';
import { useEffect } from 'react';
import { useInitialValues } from 'shared/form/AppFormik';
import { useCarrierInfo } from 'shared/modules/carrier-profile/CarrierInfo';
import {
  carrierInfoSchema,
  CARRIER_LOGISTICS_LABELS,
} from 'shared/modules/carrier-profile/CarrierInfoDTO';
import { useVerifiedCarrierApplicationStatus } from 'shared/modules/carrier-profile/VerifiedCarrierApplicationStatus';
import { useNavigationBlock } from 'shared/routing/NavigationBlock';
import { ProfileAddressForm } from '../core/ProfileAddressForm';
import { StepForm } from '../core/StepForm';
import { trackCarrierProfileEvent } from '../data/CarrierProfileAnalytics';
import { useCarrierProfileAPI } from '../data/CarrierProfileAPI';

interface StepCarrierInfoPageProps {
  onComplete: () => void;
  onBack?: () => void;
  shouldShowBottomButton?: boolean;
}

export function StepCarrierInfoPage({
  onComplete,
  onBack,
  shouldShowBottomButton,
}: StepCarrierInfoPageProps) {
  const carrierInfo = useCarrierInfo();
  const { data: verificationStatus } = useVerifiedCarrierApplicationStatus();
  const { updateCarrierInfo } = useCarrierProfileAPI();
  const { addSnackbar } = useSnackbarStack();

  const initialValues = useInitialValues(carrierInfoSchema, carrierInfo.data);

  const formik = useFormikEnhanced({
    initialValues,
    validationSchema: carrierInfoSchema,
    onSubmit: updateCarrierInfo,
    onSubmitSuccess() {
      trackCarrierProfileEvent({
        name: 'Carrier Updated Carrier Info',
        utm_medium: 'Verified Carrier Application',
        utm_content: 'Carrier Info',
      });

      onComplete();
    },
    onSubmitFailure() {
      addSnackbar('Failed to update carrier information. Please try again.', {
        variant: 'error',
      });
    },
  });

  useEffect(() => {
    if (carrierInfo.error) {
      addSnackbar('Failed to fetch carrier information', { variant: 'error' });
    }
  }, [addSnackbar, carrierInfo.error]);

  useNavigationBlock(
    formik.status.type !== 'submitted' &&
      (formik.dirty || formik.isSubmitting) &&
      'Changes have not been saved. Leaving will result in unsaved changes being lost.',
  );

  if (carrierInfo.isLoading) {
    return (
      <div aria-label="Loading Step 1 Carrier Info">
        <Skeleton width={170} height={24} />
        <Skeleton width={270} height={24} />
      </div>
    );
  }

  return (
    <FormikProvider value={formik}>
      <StepForm
        step={2}
        onBack={onBack}
        shouldShowBottomButton={shouldShowBottomButton}
        formContent={
          <Stack space="large">
            <ProfileAddressForm />

            <Stack space="small">
              <Typography variant="h4">Contact</Typography>

              <FormikTextField
                label="Name"
                name="contact_name"
                fullWidth={true}
              />

              <FormikTextField
                label="Phone Numbers"
                name="phone_numbers"
                fullWidth={true}
              />

              <FormikTextField label="Email" name="email" fullWidth={true} />
            </Stack>

            <Stack space="small">
              <Typography variant="h4">Company Info</Typography>

              <FormikTextField
                label="In Business Since Year"
                name="in_business_since"
                type="number"
                fullWidth={true}
              />

              <FormikTextField
                label={
                  <>
                    MC Number <TextBox color="secondary">(Optional)</TextBox>
                  </>
                }
                name="mc_number"
                fullWidth={true}
              />
            </Stack>

            <Stack space="small">
              <Typography variant="h4">Equipment</Typography>

              <Columns>
                <Column width={['fluid', '3/5']}>
                  <FormikNumberField
                    fullWidth={true}
                    label={
                      <>
                        Largest trailer capacity{' '}
                        <TextBox color="secondary">(Optional)</TextBox>
                      </>
                    }
                    helperText="Max. number of sedans your largest trailer can fit. (e.g. Honda Accord)"
                    name="largest_trailer_capacity"
                    inputProps={{
                      decimalScale: 0,
                      maxLength: 2,
                    }}
                  />
                </Column>
              </Columns>

              <FormikRadioGroupField
                name="logistics_type"
                label="Carrier Logistics"
                disabled={
                  !!carrierInfo.data?.logistics_type &&
                  verificationStatus?.verified_carrier_application.status !==
                    'not_submitted'
                }
                helperText={
                  <Typography variant="body2">
                    If you are an Auto Haulers, you must have motor vehicles
                    registered as cargo carried with FMCSA. <br />
                    <br />
                    If you are Towing or Driveaway, you must have Drive/Towaway.
                  </Typography>
                }
              >
                {Array.from(CARRIER_LOGISTICS_LABELS, ([key, label]) => (
                  <FormControlLabel
                    key={key}
                    value={key}
                    control={<Radio />}
                    label={label}
                  />
                ))}
              </FormikRadioGroupField>
            </Stack>
          </Stack>
        }
        submitButtonText="Continue to Cargo Insurance"
      />
    </FormikProvider>
  );
}
