import {
  yupArray,
  yupBoolean,
  yupNumber,
  yupObject,
  yupString,
} from 'shared/utils/YupUtils';
import { InferType } from 'yup';

const baseLoadIndex = 0;

export type SaveSplitLoadDTO = InferType<typeof saveSplitLoadDTO>;
export const saveSplitLoadDTO = yupObject({
  base_order_number: yupString(),
  new_orders: yupArray(
    yupObject({
      order_number: yupString(),
      vehicle_guids: yupArray(yupString()),
    }),
  ),
});

export type SplitLoadVehicleDTO = InferType<typeof splitLoadVehicleDTO>;
export const splitLoadVehicleDTO = yupObject({
  guid: yupString(),
  make: yupString(),
  model: yupString(),
  price: yupString(),
  vin: yupString(),
  year: yupNumber(),
});

export type SplitLoadDTO = InferType<typeof splitLoadDTO>;
export const splitLoadDTO = yupObject({
  guid: yupString(),
  number: yupString(),
  vehicles: yupArray(splitLoadVehicleDTO),
});

export type SplitDTO = InferType<typeof splitDTO>;
export const splitDTO = yupObject({
  isBase: yupBoolean(),
  uniqueId: yupString().default(() => Date.now().toString()),
  number: yupString(),
  vehicles: yupArray(splitLoadVehicleDTO),
});

export const splitPageParamsSchema = yupObject({
  utm_medium: yupString(),
  is_created_by_broker: yupBoolean(),
  back_url: yupString().nullable(),
});

export function transformBaseLoad(order: SplitLoadDTO): SplitDTO {
  return splitDTO.cast({
    isBase: true,
    number: order.number,
    vehicles: order.vehicles,
  });
}

export function createSplitLoad(
  vehicle: SplitLoadVehicleDTO,
  loads: SplitDTO[],
): SplitDTO {
  const loadSuffixNumber = loads.length + 1;
  return splitDTO.cast({
    isBase: false,
    number: loads[baseLoadIndex]
      ? `${loads[baseLoadIndex]?.number}-${loadSuffixNumber}`
      : loadSuffixNumber,
    vehicles: [vehicle],
  });
}
