import { useMemo } from 'react';
import { useQueryClient } from 'react-query';
import { APIResponse, requestCarrierAPI } from 'shared/api/APIClient';
import {
  APIQueryDataUpdater,
  APIQueryOptions,
  APIQueryResult,
  setAPIQueryData,
  useAPIQuery,
  UseAPIQueryOptions,
} from 'shared/api/APIQuery';
import { THIRTY_SECONDS } from './../../constants/NumberConstants';
import {
  DriverPreferencesBusinessTypeDTO,
  DriverPreferencesDTO,
  driverPreferencesSchema,
  DriverPreferencesTransportTypeDTO,
  DriverPreferencesVehicleDetailsDTO,
  DriverPreferencesVehicleDetailsHistoricalDataDTO,
  driverPreferencesVehicleDetailsHistoricalDataSchema,
} from './DriverPreferencesDTO';

export function useDriverPreferencesCacheUpdater() {
  const queryClient = useQueryClient();
  return (updater: APIQueryDataUpdater<DriverPreferencesDTO>) => {
    setAPIQueryData(queryClient, ['driver', 'preferences'], updater);
  };
}

export function useDriverPreferences(
  options?: APIQueryOptions<DriverPreferencesDTO>,
): APIQueryResult<DriverPreferencesDTO> {
  return useAPIQuery(
    ['driver', 'preferences'],
    () => requestCarrierAPI('GET /internal/mobile/v1/suggestion-preferences/'),
    {
      ...options,
      schema: driverPreferencesSchema,
    },
  );
}

export function useDriverPreferencesHistoricalData(
  options?: APIQueryOptions<DriverPreferencesVehicleDetailsHistoricalDataDTO>,
): APIQueryResult<DriverPreferencesVehicleDetailsHistoricalDataDTO> {
  return useAPIQuery(
    ['driver', 'historical_preferences'],
    () =>
      requestCarrierAPI(
        'GET /internal/mobile/v1/suggestion-preferences/historical/',
      ),
    {
      ...options,
      schema: driverPreferencesVehicleDetailsHistoricalDataSchema,
    },
  );
}

export function setDriverPreferencesBusinessType(
  json: DriverPreferencesBusinessTypeDTO,
) {
  return requestCarrierAPI(
    'PUT /internal/mobile/v1/suggestion-preferences/business-type/',
    { json },
  );
}

export function setDriverPreferencesTransportType(
  json: DriverPreferencesTransportTypeDTO,
) {
  return requestCarrierAPI(
    'PUT /internal/mobile/v1/suggestion-preferences/transport-details/',
    { json },
  );
}

export function setDriverPreferencesVehicleDetails(
  json: DriverPreferencesVehicleDetailsDTO,
) {
  return requestCarrierAPI<APIResponse<DriverPreferencesVehicleDetailsDTO>>(
    'PUT /internal/mobile/v1/suggestion-preferences/vehicle-details/',
    { json },
  );
}

export function useRequestedDriversCount(
  options?: UseAPIQueryOptions<{ join_requests: number }>,
): APIQueryResult<{ join_requests: number }> {
  return useAPIQuery(
    ['drivers', 'join-request-count'],
    () => requestCarrierAPI('GET /internal/web/drivers/count/'),
    { ...options, refetchInterval: THIRTY_SECONDS },
  );
}

export function useDriverCountCache() {
  const queryClient = useQueryClient();

  return useMemo(() => {
    const queryKey = ['drivers', 'join-request-count'];
    const invitaionDetails = queryClient.getQueryData<{
      join_requests: number;
    }>(queryKey);
    function setDriverCount(count: number) {
      void queryClient.setQueryData(queryKey, {
        ...invitaionDetails,
        join_requests: count,
      });
    }

    return {
      setDriverCount,
    };
  }, [queryClient]);
}
