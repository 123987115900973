import { startCase } from 'lodash-es';
import { joinStrings } from 'shared/utils/StringUtils';
import { stringifySearchQuery } from 'shared/utils/URLUtils';

export interface GeoLocationParam {
  state?: string | null;
  city?: string | null;
  zip?: string | null;
}

export function stringifyLocation({
  city,
  state,
  zip,
}: GeoLocationParam): string {
  const cityAndState = joinStrings(
    ', ',
    startCase(city?.toLowerCase()),
    state?.toUpperCase(),
  );

  // remove zip+4
  zip = zip?.split('-')?.[0];

  return joinStrings(' ', cityAndState, zip);
}

export function generateGoogleMapDirectionLink(
  origin: GeoLocationParam,
  destination: GeoLocationParam,
) {
  const search = stringifySearchQuery({
    api: 1,
    origin: stringifyLocation(origin),
    destination: stringifyLocation(destination),
    travelmode: 'driving',
  });

  return `https://www.google.com/maps/dir/?${search}`;
}
