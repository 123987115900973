import { trackEvent } from 'shared/helpers/Analytics';

export type LoginAnalyticsEvent =
  | { name: 'CTMS: Clicked Forgot Password' }
  | { name: 'CTMS: Clicked Reset Password' }
  | { name: 'CTMS: Opened New Login Page' }
  | { name: 'CTMS: Clicked Contact Support' }
  | { name: 'CTMS: Clicked "I didn\'t receive the email" link' }
  | { name: 'CTMS: Clicked Back to Forgot Password' }
  | {
      name: 'CTMS: Attempted to Login with Driver Email';
      isInvalidRole?: boolean;
    }
  | { name: 'CTMS: Clicked Sign Up' }
  | { name: 'CTMS: Reset Password Success via Email' };

export function trackLoginEvent(event: LoginAnalyticsEvent) {
  const { name, ...props } = event;

  trackEvent(name, { ...props });
}
