import { FormikTextField } from '@superdispatch/forms';
import { Stack } from '@superdispatch/ui';
import { TextBox } from '@superdispatch/ui-lab';
import { startCase } from 'lodash-es';
import { AppFormikProps, useAppFormik } from 'shared/form/AppFormik';
import {
  FormikCheckboxArrayField,
  FormikCheckboxArrayGroup,
} from 'shared/form/FormikCheckboxArrayGroup';
import {
  FormikDialog,
  FormikDialogActions,
  FormikDialogContent,
} from 'shared/form/FormikDialog';

const offerDeclineReasons = [
  'low_price',
  'lack_of_capacity',
  'inconvenient_dates',
];

type OfferDeclineDialogContentProps<TValues, TData> = Omit<
  AppFormikProps<TValues, TData>,
  'key'
>;

function OfferDeclineDialogContent<TValues, TData>(
  props: OfferDeclineDialogContentProps<TValues, TData>,
) {
  const formik = useAppFormik(props);

  return (
    <FormikDialogContent
      formik={formik}
      title="Decline the Load Offer?"
      actions={<FormikDialogActions variant="critical" text="Yes, Decline" />}
    >
      <Stack space="medium">
        <Stack space="xsmall">
          <TextBox color="secondary" variant="heading-6">
            What is the reason?
          </TextBox>

          <FormikCheckboxArrayGroup name="decline_reasons">
            <Stack>
              {offerDeclineReasons.map((reason) => (
                <FormikCheckboxArrayField
                  key={reason}
                  name={reason}
                  label={startCase(reason)}
                />
              ))}
            </Stack>
          </FormikCheckboxArrayGroup>
        </Stack>

        <FormikTextField
          multiline={true}
          fullWidth={true}
          name="decline_comment"
          label="Write a comment"
        />
      </Stack>
    </FormikDialogContent>
  );
}

export interface BaseDeclineOfferDialogProps<TValues, TData>
  extends OfferDeclineDialogContentProps<TValues, TData> {
  open: boolean;
  onClose: () => void;
}

export function OffersDeclineDialogBase<TValues, TData>({
  open,
  onClose,
  ...props
}: BaseDeclineOfferDialogProps<TValues, TData>) {
  return (
    <FormikDialog open={open} maxWidth="xs" fullWidth={true} onClose={onClose}>
      <OfferDeclineDialogContent {...props} />
    </FormikDialog>
  );
}
