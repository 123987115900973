// Time

export const QUARTER_OF_A_SECOND = 1000 / 4;
export const HALF_OF_A_SECOND = 1000 / 2;
export const ONE_SECOND = 1000;
export const ONE_AND_HALF_SECOND = 1.5 * 1000;
export const THREE_SECONDS = 3 * 1000;
export const FIVE_SECONDS = 5 * 1000;
export const SIX_SECONDS = 6 * 1000;
export const TEN_SECONDS = 10 * 1000;
export const THIRTY_SECONDS = 30 * 1000;
export const ONE_MINUTE = 60 * 1000;
export const FIVE_MINUTES = 5 * 60 * 1000;
export const TEN_MINUTES = 10 * 60 * 1000;
export const ONE_HOUR = 60 * 60 * 1000;

// Bytes
export const TWENTY_MEGABYTES = 20 * 1000 * 1000;
