import { Typography } from '@material-ui/core';
import { Stack } from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import PasswordUpdatedIcon from '../assets/password-updated-icon.svg';
import { LoginLayoutContent } from './LoginLayoutContent';

export function ResetPasswordSuccessMobile() {
  return (
    <LoginLayoutContent>
      <Box height="100%" width="100%">
        <Stack align="center" space="small">
          <img src={PasswordUpdatedIcon} />
          <Typography variant="h1">Password Updated</Typography>
        </Stack>
      </Box>
    </LoginLayoutContent>
  );
}
