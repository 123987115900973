import { Typography } from '@material-ui/core';
import { useResponsiveValue, useUID } from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import { useMemo } from 'react';
import { formatNumber } from 'shared/helpers/IntlHelpers';
import styled from 'styled-components';

const MobileSummaryItemWrapper = styled(Box)`
  display: flex;
  justify-content: space-between;
`;

export function RatingSummaryItem({
  label,
  count,
}: {
  label: string;
  count: number;
}) {
  const isMobile = useResponsiveValue(true, false);
  const uid = useUID();
  const content = useMemo(
    () => (
      <>
        <Typography id={uid} color="textSecondary" align="center">
          {label}
        </Typography>

        <Typography
          aria-labelledby={uid}
          variant="h4"
          color={count > 0 ? 'textPrimary' : 'inherit'}
          align="center"
        >
          {count ? formatNumber(count) : 'None'}
        </Typography>
      </>
    ),
    [count, label, uid],
  );

  if (isMobile) {
    return (
      <MobileSummaryItemWrapper color="Dark100">
        {content}
      </MobileSummaryItemWrapper>
    );
  }

  return (
    <Box width="212px" color="Dark100">
      {content}
    </Box>
  );
}
