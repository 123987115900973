import mitt from 'mitt';
import { useEffect, useState } from 'react';
import { logError, logWarning } from 'shared/helpers/ErrorTracker';

const emitter = mitt<{ load: typeof google.maps }>();

const CALLBACK_NAME = '__google_maps_api_load_callback__';
const API_URL = `https://maps.googleapis.com/maps/api/js?language=en&libraries=places&key=AIzaSyCIcGxVSXhA8PuNNm7oJziUACtGWoOWZEY&callback=${CALLBACK_NAME}`;

function getGoogleMaps(): undefined | typeof google.maps {
  return typeof google == 'object' && typeof google.maps == 'object'
    ? google.maps
    : undefined;
}

function injectGoogleMapsScript() {
  if (CALLBACK_NAME in window) return;

  Object.defineProperty(window, CALLBACK_NAME, {
    value: () => {
      const maps = getGoogleMaps();

      if (maps) {
        emitter.emit('load', maps);
      } else {
        logError(
          '[GoogleMaps] `google.maps` not found in global scope after loading script.',
          'injectGoogleMapsScript',
        );
      }
    },
  });

  const script = document.createElement('script');

  script.async = true;
  script.defer = true;

  script.onerror = (event, source, lineno, colno, error) => {
    logWarning('[GoogleMaps] Failed to load `google.maps`.', {
      event,
      source,
      lineno,
      colno,
      error,
    });
  };

  script.src = API_URL;

  document.body.appendChild(script);
}

function loadGoogleMaps(
  onLoad: (maps: typeof google.maps) => void,
): void | (() => void) {
  const maps = getGoogleMaps();

  if (maps) {
    onLoad(maps);
    return;
  }

  emitter.on('load', onLoad);

  injectGoogleMapsScript();

  return () => {
    emitter.off('load', onLoad);
  };
}

export function useGoogleMaps(): undefined | typeof google.maps {
  const [maps, setMaps] = useState(getGoogleMaps);
  useEffect(() => loadGoogleMaps(setMaps), []);
  return maps;
}
