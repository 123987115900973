import { Typography } from '@material-ui/core';
import { useSnackbarStack } from '@superdispatch/ui';
import { MutationDialog } from 'shared/form/MutationDialog';
import { ConfirmDialogContent } from 'shared/ui/ConfirmDialog';
import { useUnarchiveTrip } from '../data/TripsAPI';

interface UnarchiveTripDialogProps {
  onClose: () => void;
  onSubmitSuccess: () => void;
  tripGUID?: string;
}

export function UnarchiveTripDialog({
  onClose,
  onSubmitSuccess,
  tripGUID = '',
}: UnarchiveTripDialogProps) {
  const { addSnackbar } = useSnackbarStack();

  const unarchive = useUnarchiveTrip({
    onSuccess(response) {
      addSnackbar(response.user_message || 'Trip has been unarchived', {
        variant: 'success',
      });
      onSubmitSuccess();
    },
    onError() {
      addSnackbar('Error occurred while deleting the trip', {
        variant: 'error',
      });
    },
  });

  return (
    <MutationDialog
      open={!!tripGUID}
      onClose={onClose}
      fullWidth={true}
      maxWidth="xs"
    >
      <ConfirmDialogContent
        onClose={onClose}
        title="Are you sure you want to unarchive this trip?"
        cancelButtonProps={{ disabled: unarchive.isLoading }}
        confirmButtonProps={{
          children: 'Unarchive',
          pending: unarchive.isLoading,
          onClick() {
            unarchive.mutate(tripGUID);
          },
        }}
      >
        <Typography>Trip will be moved to unarchived trips.</Typography>
      </ConfirmDialogContent>
    </MutationDialog>
  );
}
