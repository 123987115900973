import { useEffect } from 'react';

export function usePageRedirect(to: string) {
  useEffect(() => {
    window.location.replace(to);
  }, [to]);
}

interface PageRedirectProps {
  to: string;
}

export function PageRedirect({ to }: PageRedirectProps) {
  usePageRedirect(to);

  return null;
}
