import { Card, CardContent, Typography } from '@material-ui/core';
import { FormikCheckboxField } from '@superdispatch/forms';
import { Inline, Stack, useUID } from '@superdispatch/ui';
import { Box, Button } from '@superdispatch/ui-lab';
import { FormikProvider } from 'formik';
import { useMemo } from 'react';
import { useAppFormik } from 'shared/form/AppFormik';
import { useFormikComposerForm } from 'shared/form/FormikComposer';
import { DeliveryAltIcon } from 'shared/icons/DeliveryAltIcon';
import { useFlag } from 'shared/settings/FeatureToggles';
import { yupBoolean } from 'shared/utils/YupUtils';
import { useContactsAPI } from '../../contacts/data/ContactsAPI';
import { LoadDeliveryForm } from '../core/LoadDeliveryForm';
import { loadDeliveryEditSchema } from '../data/LoadActionsDTO';
import { isEmptyLoadLegVenue, LoadDTO } from '../data/LoadDTO';
import { useLoadsAPI } from '../data/LoadsAPI';

const formSchema = loadDeliveryEditSchema.shape({
  $saveAsNewContact: yupBoolean(),
});

export interface EditLoadPageDeliveryProps {
  load: LoadDTO;
  onCopyToCustomer: () => void;
}

export function EditLoadPageDelivery({
  load,
  onCopyToCustomer,
}: EditLoadPageDeliveryProps) {
  const uid = useUID();
  const shouldDisableEditing = useFlag('ctms_disabled_fields');
  const { createContact } = useContactsAPI();
  const { editLoadDelivery } = useLoadsAPI();

  const formik = useAppFormik({
    initialValues: load.delivery,
    validationSchema: formSchema,
    async onSubmit({ $saveAsNewContact, ...values }) {
      const response = await editLoadDelivery(load.guid, values);

      if ($saveAsNewContact) {
        await createContact({
          name: values.venue.name,
          address: values.venue.address,
          city: values.venue.city,
          state: values.venue.state,
          zip: values.venue.zip,

          phone: values.venue.contacts[0]?.phone,
          email: values.venue.contacts[0]?.email,
          contact: values.venue.contacts[0]?.name,
        });
      }

      return response;
    },
  });

  const isDeliveryVenueEmpty = useMemo(
    () => isEmptyLoadLegVenue(formik.values.venue),
    [formik.values.venue],
  );

  const nodeRef = useFormikComposerForm('delivery', formik);

  return (
    <FormikProvider value={formik}>
      <Card ref={nodeRef} aria-label="Delivery Information Card">
        <CardContent>
          <Stack space="small">
            <Inline verticalAlign="center" space="xsmall" noWrap={true}>
              <DeliveryAltIcon />
              <Typography id={uid} variant="h3">
                Delivery Information
              </Typography>
            </Inline>

            <LoadDeliveryForm
              disabled={load.is_created_by_broker && shouldDisableEditing}
            />
          </Stack>
        </CardContent>

        <Box padding="small" backgroundColor="Silver100">
          <Inline space="small">
            <FormikCheckboxField
              name="$saveAsNewContact"
              label="Save as New Contact"
              disabled={isDeliveryVenueEmpty}
            />
            <Button
              variant="neutral"
              onClick={onCopyToCustomer}
              disabled={isDeliveryVenueEmpty}
            >
              Copy to Customer Information
            </Button>
          </Inline>
        </Box>
      </Card>
    </FormikProvider>
  );
}
