import { createSvgIcon } from 'shared/icons/IconUtils';

export const LoadOfferSidebarIcon = createSvgIcon(
  'LoadOfferSidebar',
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M4 22H20C18.9 22 18 21.1 18 20V4C18 2.9 17.1 2 16 2H4C2.9 2 2 2.9 2 4V20C2 21.1 2.9 22 4 22ZM5 8C5 8.6 5.4 9 6 9C6.6 9 7 8.6 7 8C7 7.4 6.6 7 6 7C5.4 7 5 7.4 5 8ZM6 13C5.4 13 5 12.6 5 12C5 11.4 5.4 11 6 11C6.6 11 7 11.4 7 12C7 12.6 6.6 13 6 13ZM5 16C5 16.6 5.4 17 6 17C6.6 17 7 16.6 7 16C7 15.4 6.6 15 6 15C5.4 15 5 15.4 5 16ZM14 9H10C9.4 9 9 8.6 9 8C9 7.4 9.4 7 10 7H14C14.6 7 15 7.4 15 8C15 8.6 14.6 9 14 9ZM10 13H14C14.6 13 15 12.6 15 12C15 11.4 14.6 11 14 11H10C9.4 11 9 11.4 9 12C9 12.6 9.4 13 10 13ZM14 17H10C9.4 17 9 16.6 9 16C9 15.4 9.4 15 10 15H14C14.6 15 15 15.4 15 16C15 16.6 14.6 17 14 17ZM20 19C20 19.6 20.4 20 21 20C21.6 20 22 19.6 22 19V14C22 12.9 21.1 12 20 12V19Z"
    fill="currentColor"
  />,
);
