export const PRICING_INSIGHTS_URL =
  import.meta.env.VITE_APP_TARGET === 'production'
    ? 'https://pricing-insights.superdispatch.com'
    : 'https://staging.pricing-insights.superdispatch.org';

export const WEBSITE_SIGNUP_URL =
  import.meta.env.VITE_APP_TARGET === 'production'
    ? 'https://signup.superdispatch.com'
    : 'https://website-signup-staging.web.app';

export const LOADBOARD_MOBILE_LOADS_PAGE_URL =
  import.meta.env.VITE_APP_TARGET === 'production'
    ? 'mysuperdispatch://carrier.superdispatch.com/loadboard-mobile/loads'
    : 'mysuperdispatch://staging.carrier.superdispatch.org/loadboard-mobile/loads';
