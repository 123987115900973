import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import { Stack } from '@superdispatch/ui';
import { Button } from '@superdispatch/ui-lab';
import styled from 'styled-components';

interface SettingsDisconnectQuickbooksDialogProps {
  open: boolean;
  type: 'Desktop' | 'Online';
  isLoading: boolean;
  onClose: () => void;
  onDisconnect: () => void;
}

const List = styled.ul`
  padding-inline-start: 20px;
  margin: 0;
`;

export function SettingsDisconnectQuickbooksDialog({
  open,
  type,
  isLoading,
  onClose,
  onDisconnect,
}: SettingsDisconnectQuickbooksDialogProps) {
  return (
    <Dialog fullWidth={true} maxWidth="sm" open={open} onClose={onClose}>
      <DialogTitle>Disconnect from QuickBooks {type}?</DialogTitle>
      <DialogContent>
        <Stack space="xxsmall">
          <Typography>After disconnecting you will not be able to:</Typography>
          <List>
            <Stack space="xxsmall">
              <li>Import your contacts from QuickBooks {type}</li>
              <li>Send invoices from Carrier TMS to QuickBooks {type}</li>
              <li>Update received payments in QuickBooks {type}</li>
            </Stack>
          </List>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="neutral">
          Cancel
        </Button>
        <Button pending={isLoading} onClick={onDisconnect} variant="critical">
          Disconnect
        </Button>
      </DialogActions>
    </Dialog>
  );
}
