import { Card } from '@material-ui/core';
import {
  ResponsiveProp,
  SpaceProp,
  useResponsiveValue,
} from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import { ReactNode } from 'react';
import styled from 'styled-components';

const Container = styled.div<{ maxWidth: string }>`
  position: relative;
  margin: 0 auto 16px;
  max-width: ${({ maxWidth }) => maxWidth};
  display: flex;
  flex-direction: column;
`;

interface LoginLayoutContentProps {
  children?: ReactNode;
  padding?: ResponsiveProp<SpaceProp> | undefined;
  maxWidth?: string;
  hasSlider?: boolean;
  sliderWidth?: string;
}

export function LoginLayoutContent({
  children,
  padding = ['small', 'medium'],
  maxWidth = '432px',
  hasSlider = false,
  sliderWidth = '0',
}: LoginLayoutContentProps) {
  const isMobile = useResponsiveValue(true, false);

  return (
    <Container maxWidth={maxWidth}>
      {hasSlider && (
        <>
          <Box
            width="100%"
            height="4px"
            backgroundColor="Silver400"
            position="absolute"
            top="0"
          />
          <Box
            width={sliderWidth}
            height="4px"
            backgroundColor="Blue300"
            position="absolute"
            top="0"
          />
        </>
      )}
      <Card square={isMobile}>
        <Box padding={padding}>{children}</Box>
      </Card>
    </Container>
  );
}
