import { trim } from 'shared/helpers/CommonHelpers';

function formatError(error?: string): string {
  const message = trim(error);

  switch (message) {
    case '':
      return 'Unknown error';

    case '404':
    case 'ObjectDoesNotExist':
      return 'Resource not found';

    case '413':
    case 'Payload Too Large':
      return 'Attachment size is too large';

    case '415':
    case 'Unsupported Media Type':
      return 'Unsupported file format';

    default:
      return message;
  }
}

export interface APIError<TContext = unknown> extends Error {
  type?: string;
  status?: number;
  context?: TContext;
  requestParams: unknown;
}

export function createAPIError<TContext>({
  message,
  ...options
}: Partial<Omit<APIError<TContext>, 'name'>>): APIError<TContext> {
  const error = new Error(formatError(message));

  Object.assign(error, options);

  error.name = 'APIError';

  // Pop invocation of the `createAPIError`.
  if (typeof error.stack == 'string') {
    const lines = error.stack.split('\n');
    lines.splice(1, 1);
    error.stack = lines.join('\n');
  }

  return error as APIError<TContext>;
}

export function isAPIError(value: unknown): value is APIError {
  return value != null && value instanceof Error && value.name === 'APIError';
}
