import { FormikTextField, useFormikEnhanced } from '@superdispatch/forms';
import { useSnackbarStack } from '@superdispatch/ui';
import { useInitialValues } from 'shared/form/AppFormik';
import { FormikDrawerActions } from 'shared/form/FormikDrawer';
import { SlowFormikDrawer } from 'shared/form/SlowFormikDrawer';
import { loadDetailsEditSchema } from '../data/LoadActionsDTO';
import { formatLoadStatus } from '../data/LoadDTO';
import { trackLoadsEvent } from '../data/LoadsAnalytics';
import { useLoadDetails, useLoadsAPI, useLoadsCache } from '../data/LoadsAPI';
import { useLoadStageFromBackURL } from '../view/ViewLoadHeaderActions';

interface Props {
  loadGUID: string;
  open: boolean;
  onClose: () => void;
}

export function EditLoadInstructionsDrawer({ loadGUID, open, onClose }: Props) {
  const { addSnackbar } = useSnackbarStack();
  const { data } = useLoadDetails(loadGUID);
  const { patchLoadDetails } = useLoadsAPI();
  const { invalidateLoads } = useLoadsCache();
  const loadStage = useLoadStageFromBackURL();
  const initialValues = useInitialValues(loadDetailsEditSchema, data);
  const formik = useFormikEnhanced({
    initialValues,
    onSubmit: (values) => patchLoadDetails(loadGUID, values, data),
    onSubmitSuccess: () => {
      onClose();
      invalidateLoads();
      trackLoadsEvent({
        name: 'Carrier Edited Load',
        utm_medium: loadStage,
        utm_content: 'View Page',
        load_status: formatLoadStatus(data?.status),
        is_created_by_broker: !!data?.is_created_by_broker,
        instructions: !data?.instructions ? 'Added' : 'Edited',
      });
      addSnackbar('Successfully changed load instructions.');
    },
    onSubmitFailure: ({ message }) => {
      addSnackbar(
        message ||
          'Something went wrong while updating load instructions, contact support.',
        { variant: 'error' },
      );
    },
  });

  return (
    <SlowFormikDrawer
      formik={formik}
      title="Driver Instructions"
      open={open}
      onClose={onClose}
      actions={<FormikDrawerActions />}
    >
      <FormikTextField
        name="instructions"
        fullWidth={true}
        multiline={true}
        autoFocus={true}
        rows={16}
        rowsMax={20}
        helperText="Driver will see these instructions in the mobile app"
      />
    </SlowFormikDrawer>
  );
}
