import {
  yupBoolean,
  yupDateString,
  yupEnum,
  yupObject,
  yupPhone,
  yupString,
} from 'shared/utils/YupUtils';
import { InferType } from 'yup';

export type DispatcherDTO = InferType<typeof dispatcherSchema>;
export const dispatcherSchema = yupObject({
  guid: yupString(),
  name: yupString().required().max(200),
  email: yupString().required().email().max(200),
  phone: yupPhone(),
  created_at: yupDateString('DateTimeISO'),
  driver: yupObject({
    name: yupString().required().max(200),
    email: yupString().required().email().max(200),
    phone: yupPhone(),
    guid: yupString(),
  })
    .nullable()
    .optional(),
  p_changed_at: yupDateString('DateTimeISO').nullable(),

  is_activated: yupBoolean(),
  connection_status: yupEnum(
    ['activated', 'pending', 'deactivated', 'declined'],
    'activated',
  ),
  connection_type: yupEnum(['invite', 'join_request']),
});
