import { MenuProps } from '@material-ui/core';
import { useUID } from '@superdispatch/ui';
import { Button, ButtonProps } from '@superdispatch/ui-lab';
import { ReactNode, useCallback, useState } from 'react';

export interface MenuButtonMenuProps
  extends Pick<MenuProps, 'id' | 'open' | 'anchorEl' | 'MenuListProps'> {
  onClose: () => void;
}

export interface MenuButtonProps extends ButtonProps {
  renderMenu: (props: MenuButtonMenuProps) => ReactNode;
}

export function MenuButton({
  onClick,
  renderMenu,
  id: idProp,
  ...props
}: MenuButtonProps) {
  const id = useUID(idProp);
  const menuID = `${id}-menu`;

  const [anchorEl, setAnchorEl] = useState<HTMLElement>();
  const isExpanded = anchorEl != null;
  const onClose = useCallback(() => {
    setAnchorEl(undefined);
  }, []);

  return (
    <>
      <Button
        variant="neutral"
        {...props}
        id={id}
        aria-haspopup={true}
        aria-controls={menuID}
        aria-expanded={isExpanded}
        onClick={(event) => {
          onClick?.(event);

          if (!event.defaultPrevented) {
            setAnchorEl(event.currentTarget);
          }
        }}
      />

      {renderMenu({
        id: menuID,
        anchorEl,
        onClose,
        open: isExpanded,
        MenuListProps: { 'aria-labelledby': id },
      })}
    </>
  );
}
