export const LOADBOARD_TOUR_ID =
  import.meta.env.VITE_APP_TARGET === 'production' ? '544786' : '553668';
export const BECAME_VERIFIED_TOUR_ID =
  import.meta.env.VITE_APP_TARGET === 'production' ? '554647' : '555222';
export const CREATE_OR_IMPORT_LOAD_TOUR_ID =
  import.meta.env.VITE_APP_TARGET === 'production' ? '548596' : '557763';
export const GET_TO_KNOW_CARRIER_TMS_TOUR_ID =
  import.meta.env.VITE_APP_TARGET === 'production' ? '555174' : '557775';
export const LOAD_DELIVERED_TOUR_ID =
  import.meta.env.VITE_APP_TARGET === 'production' ? '548601' : '557766';
export const FIRST_LOAD_TOUR_ID =
  import.meta.env.VITE_APP_TARGET === 'production' ? '548598' : '557765';
export const OFFER_ACCEPTED_TOUR_ID =
  import.meta.env.VITE_APP_TARGET === 'production' ? '548595' : '557761';
export const OFFER_RECEIVED_TOUR_ID =
  import.meta.env.VITE_APP_TARGET === 'production' ? '548593' : '554178';
export const ONBOARDING_MENU_ITEM_TOUR_ID =
  import.meta.env.VITE_APP_TARGET === 'production' ? '558220' : '558224';

export const ONBOARDING_TOURS = {
  tour_slb_search: LOADBOARD_TOUR_ID,
  tour_assign_driver: FIRST_LOAD_TOUR_ID,
  tour_create_or_import_load: CREATE_OR_IMPORT_LOAD_TOUR_ID,
  tour_getting_started: ONBOARDING_MENU_ITEM_TOUR_ID,
  tour_load_delivered: LOAD_DELIVERED_TOUR_ID,
  tour_loads_page: FIRST_LOAD_TOUR_ID,
  tour_offer_accepted: OFFER_ACCEPTED_TOUR_ID,
  tour_offer_received: OFFER_RECEIVED_TOUR_ID,
  tour_verification_approval: BECAME_VERIFIED_TOUR_ID,
  get_to_know_ctms: GET_TO_KNOW_CARRIER_TMS_TOUR_ID,
};

export const ONBOARDING_FIRST_LOAD_TOUR_STORAGE_KEY =
  'onboarding-first-load-tour';
