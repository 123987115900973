import { FormikCheckboxField } from '@superdispatch/forms';
import { Stack } from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import { useAppFormik } from 'shared/form/AppFormik';
import {
  FormikDrawer,
  FormikDrawerActions,
  FormikDrawerContent,
} from 'shared/form/FormikDrawer';
import { yupBoolean } from 'shared/utils/YupUtils';
import { loadPickupEditSchema } from '../data/LoadActionsDTO';
import {
  formatLoadStatus,
  isEmptyLoadLeg,
  loadVenueSchema,
} from '../data/LoadDTO';
import { trackLoadsEvent } from '../data/LoadsAnalytics';
import { useLoadDetails, useLoadsAPI, useLoadsCache } from '../data/LoadsAPI';
import { useLoadStageFromBackURL } from '../view/ViewLoadHeaderActions';
import { LoadPickupForm } from './LoadPickupForm';

const formSchema = loadPickupEditSchema.shape({
  $copyToCustomer: yupBoolean(),
});

interface EditLoadPickupDrawerContentProps {
  loadGUID: string;
  onClose: () => void;
}

function EditLoadPickupDrawerContent({
  onClose,
  loadGUID,
}: EditLoadPickupDrawerContentProps) {
  const { data } = useLoadDetails(loadGUID);
  const { invalidateLoads } = useLoadsCache();
  const { editLoadPickup, editLoadCustomer } = useLoadsAPI();
  const loadStage = useLoadStageFromBackURL();
  const formik = useAppFormik({
    initialValues: data?.pickup,
    validationSchema: formSchema,
    async onSubmit({ $copyToCustomer, ...values }) {
      const response = await editLoadPickup(loadGUID, values);

      if ($copyToCustomer) {
        await editLoadCustomer(loadGUID, {
          save_customer_contact: false,
          venue: loadVenueSchema.cast(values.venue),
        });
      }

      return response;
    },
    onSubmitSuccess() {
      onClose();
      invalidateLoads();

      trackLoadsEvent({
        name: 'Carrier Edited Load',
        utm_medium: loadStage,
        utm_content: 'View Page',
        load_status: formatLoadStatus(data?.status),
        is_created_by_broker: !!data?.is_created_by_broker,
        pickup_information:
          !data?.pickup || isEmptyLoadLeg(data.pickup) ? 'Added' : 'Edited',
      });
    },
    getSuccessMessage(_, { $copyToCustomer }) {
      return $copyToCustomer
        ? 'Successfully changed pickup and customer information'
        : 'Successfully changed pickup information';
    },
    getErrorMessage({ message }) {
      return message || 'Something went wrong, contact support.';
    },
  });

  return (
    <FormikDrawerContent
      formik={formik}
      title="Pickup Information"
      onClose={onClose}
      actions={<FormikDrawerActions />}
    >
      <Box maxWidth="424px">
        <Stack space="large">
          <FormikCheckboxField
            name="$copyToCustomer"
            label="Copy to Customer"
            helperText="Customer will have the same information as below"
          />

          <LoadPickupForm />
        </Stack>
      </Box>
    </FormikDrawerContent>
  );
}

interface EditLoadPickupDrawerProps extends EditLoadPickupDrawerContentProps {
  open: boolean;
}

export function EditLoadPickupDrawer({
  open,
  onClose,
  loadGUID,
}: EditLoadPickupDrawerProps) {
  return (
    <FormikDrawer open={open} onClose={onClose}>
      <EditLoadPickupDrawerContent loadGUID={loadGUID} onClose={onClose} />
    </FormikDrawer>
  );
}
