import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import { FormikTextField, useFormikEnhanced } from '@superdispatch/forms';
import { useSnackbarStack } from '@superdispatch/ui';
import { Button } from '@superdispatch/ui-lab';
import { FormikProvider } from 'formik';
import { APIResponse } from 'shared/api/APIClient';
import { validateString } from 'shared/helpers/ValidationHelpers';
import { formatLoadStatus, LoadDTO } from '../data/LoadDTO';
import { trackLoadsEvent } from '../data/LoadsAnalytics';
import { useLoadsAPI, useLoadsCache } from '../data/LoadsAPI';
import { useLoadStageFromBackURL } from '../view/ViewLoadHeaderActions';

interface LoadNoteAddDialogPropsValues {
  note: string;
  loadGUID: string;
}

export interface LoadNoteAddDialogProps {
  load?: LoadDTO;
  onClose: () => void;
  onSubmitSuccess: (values: LoadNoteAddDialogPropsValues) => void;
}

export function LoadNoteAddDialog({
  load,
  onClose,
  onSubmitSuccess,
}: LoadNoteAddDialogProps) {
  const { addLoadNote } = useLoadsAPI();
  const { addSnackbar } = useSnackbarStack();
  const { invalidateLoads } = useLoadsCache();
  const loadStage = useLoadStageFromBackURL();

  const formik = useFormikEnhanced<LoadNoteAddDialogPropsValues, APIResponse>({
    initialValues: { note: '', loadGUID: load?.guid || '' },
    onSubmit: ({ note, loadGUID }) => addLoadNote(loadGUID, note),
    onSubmitSuccess: ({ user_message }, values) => {
      invalidateLoads();
      onSubmitSuccess(values);
      addSnackbar(user_message || 'Internal note added.', {
        variant: 'success',
      });
      trackLoadsEvent({
        name: 'Carrier Internal Note Added',
        page: 'loads_list',
        is_created_by_broker: !!load?.is_created_by_broker,
        load_status: formatLoadStatus(load?.status),
        utm_medium: loadStage,
      });
    },
    onSubmitFailure: ({ message }) => {
      addSnackbar(message, { variant: 'error' });
    },
  });

  const { isSubmitting, handleSubmit } = formik;

  return (
    <Dialog
      maxWidth="xs"
      fullWidth={true}
      open={load != null}
      onClose={!isSubmitting ? onClose : undefined}
    >
      <form onSubmit={handleSubmit}>
        <DialogTitle disableTypography={true}>
          <Typography variant="h3">
            Add a new internal note to the load
          </Typography>
        </DialogTitle>

        <DialogContent>
          <FormikProvider value={formik}>
            <FormikTextField
              rows={5}
              name="note"
              fullWidth={true}
              multiline={true}
              autoFocus={true}
              validate={(value) =>
                validateString(value, { required: true, maxLength: 500 })
              }
            />
          </FormikProvider>
        </DialogContent>

        <DialogActions>
          <Button variant="neutral" onClick={onClose} disabled={isSubmitting}>
            Cancel
          </Button>

          <Button type="submit" pending={isSubmitting}>
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
