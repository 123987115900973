import { useState } from 'react';
import { useCheckChromeExtension } from 'shared/helpers/ExtensionHelper';
import { BecomeVerifiedDialog } from 'shared/modules/onboarding/core/BecomeVerifiedDialog';
import { OnboardingDialog } from './core/OnboardingDialog';
import { useOnboardingDialogState } from './core/OnboardingWrapper';
import { useSaveOnboardingWithErrorHandler } from './data/useSaveOnboardingWithErrorHandler';
import { OnboardingSDConnectionDialog } from './OnboardingSDConnectionDialog';

export function OnboardingDialogs() {
  const {
    isOnboardingDialogOpen,
    closeOnboardingDialog,
    isBecomeVerifiedDialogOpen,
    closeBecomeVerifiedDialog,
    openOnboardingDialog,
  } = useOnboardingDialogState();
  const { mutateAsync: saveStep } = useSaveOnboardingWithErrorHandler();
  const [isSDConnectionDialogOpen, setIsSDConnectionDialogOpen] =
    useState(false);

  const isInstalled = useCheckChromeExtension();

  return (
    <>
      <OnboardingDialog
        isOpen={isOnboardingDialogOpen}
        onClose={() => {
          closeOnboardingDialog();
        }}
      />

      <BecomeVerifiedDialog
        open={isBecomeVerifiedDialogOpen}
        onClose={closeBecomeVerifiedDialog}
        onCompleted={() => {
          if (!isInstalled) {
            setIsSDConnectionDialogOpen(true);
          } else {
            void saveStep({
              stepName: 'install_chrome_extension',
              status: 'completed',
            });

            openOnboardingDialog();
          }
        }}
      />

      <OnboardingSDConnectionDialog
        open={isSDConnectionDialogOpen}
        onClose={() => {
          void saveStep({
            stepName: 'install_chrome_extension',
            status: 'skipped',
          });

          openOnboardingDialog();
          setIsSDConnectionDialogOpen(false);
        }}
        onComplete={() => {
          setIsSDConnectionDialogOpen(false);
          void saveStep({
            stepName: 'install_chrome_extension',
            status: 'completed',
          });

          openOnboardingDialog();
        }}
      />
    </>
  );
}
