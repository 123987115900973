import {
  LinearProgress,
  StandardTextFieldProps,
  TextField,
} from '@material-ui/core';
import { Autocomplete, AutocompleteProps } from '@material-ui/lab';
import { useDeepEqualMemo } from '@superdispatch/hooks';
import { identity } from 'lodash-es';
import {
  forwardRef,
  HTMLAttributes,
  UIEvent,
  useCallback,
  useRef,
} from 'react';
import { APIListQueryData } from 'shared/api/APIListQuery';
import { useSearchQueryText } from 'shared/helpers/ReactHelpers';

const ListboxComponent = forwardRef<
  HTMLUListElement,
  HTMLAttributes<HTMLUListElement>
>(({ children, ...props }, ref) => (
  <ul {...props} ref={ref}>
    {children}

    {props['aria-busy'] && (
      <li>
        <LinearProgress />
      </li>
    )}
  </ul>
));

ListboxComponent.displayName = 'ListboxComponent';

export type UseResourceFieldOptionsHook<TOption> = (
  query: string | undefined,
) => {
  hasNextPage?: boolean;
  fetchNextPage: () => void;
  isFetchingNextPage: boolean;
  data?: null | APIListQueryData<TOption>;
};

export type UseResourceFieldSelectedOptionHook<TOption> = (
  key: null | string | undefined,
) => { data?: null | TOption };

export interface BaseResourceFieldProps<TOption>
  extends Pick<
    AutocompleteProps<TOption, false, boolean, false>,
    'id' | 'disabled' | 'noOptionsText' | 'forcePopupIcon' | 'disableClearable'
  > {
  value?: null | string;
  TextFieldProps?: StandardTextFieldProps;
  onChange?: (value: string | undefined) => void;
}

export interface ResourceFieldProps<TOption>
  extends BaseResourceFieldProps<TOption> {
  groupBy?: (option: TOption) => string;
  getOptionKey: (option: TOption) => string;
  getOptionLabel: (option: TOption) => string;
  useOptions: UseResourceFieldOptionsHook<TOption>;
  useSelectedOption: UseResourceFieldSelectedOptionHook<TOption>;
}

export function ResourceField<TOption>({
  disabled: disabledProp,

  value,
  onChange,

  useOptions,
  useSelectedOption,
  groupBy: groupByProp,
  getOptionKey: getOptionKeyProp,
  getOptionLabel: getOptionLabelProp,

  TextFieldProps = {},

  ...props
}: ResourceFieldProps<TOption>) {
  const {
    current: { groupBy, getOptionKey, getOptionLabel },
  } = useRef({
    groupBy: groupByProp,
    getOptionKey: getOptionKeyProp,
    getOptionLabel: getOptionLabelProp,
  });

  const [searchText, searchQuery, setSearchText] = useSearchQueryText('');

  const {
    data: options,
    hasNextPage: hasNextOptionsPage,
    fetchNextPage: fetchNextOptionsPage,
    isFetchingNextPage: isFetchingNextOptionsPage,
  } = useOptions(searchQuery);
  const { data: selectedOption } = useSelectedOption(value);

  const [keys, optionsMap] = useDeepEqualMemo(() => {
    const nextKeys: string[] = [];
    const nextOptionsMap = new Map<string, undefined | TOption>();

    if (options) {
      for (const page of options.pages) {
        for (const option of page.data) {
          const optionKey = getOptionKey(option);

          nextKeys.push(optionKey);
          nextOptionsMap.set(optionKey, option);
        }
      }
    }

    if (selectedOption) {
      const selectedOptionKey = getOptionKey(selectedOption);

      // Add `selectedOption` as first if it's not included in the `options`.
      if (!nextOptionsMap.has(selectedOptionKey)) {
        nextKeys.unshift(selectedOptionKey);
        nextOptionsMap.set(selectedOptionKey, selectedOption);
      }
    }

    return [nextKeys, nextOptionsMap];
  }, [options, getOptionKey, selectedOption]);

  const getOptionKeyLabel = useCallback(
    (key: string) => {
      const option = optionsMap.get(key);

      if (option) {
        const label = getOptionLabel(option);

        if (label) {
          return label;
        }
      }

      return '';
    },
    [getOptionLabel, optionsMap],
  );

  const renderOptionKey = useCallback(
    (key: string) => getOptionKeyLabel(key) || '…',
    [getOptionKeyLabel],
  );

  const handleScroll = useCallback(
    ({
      currentTarget: { scrollTop, scrollHeight, clientHeight },
    }: UIEvent<HTMLUListElement>) => {
      if (hasNextOptionsPage) {
        const threshold = 32 /* minimal height of menu item */ * 5;
        const delta = scrollHeight - scrollTop - threshold;

        if (delta <= clientHeight) {
          fetchNextOptionsPage();
        }
      }
    },
    [fetchNextOptionsPage, hasNextOptionsPage],
  );

  const disabled = disabledProp || (!!value && !selectedOption);

  return (
    <Autocomplete
      {...props}
      options={keys}
      multiple={false}
      autoComplete={true}
      autoHighlight={true}
      filterOptions={identity}
      disabled={disabled}
      loading={disabled || !options}
      value={selectedOption ? getOptionKey(selectedOption) : null}
      onChange={(_, nextValue) => {
        onChange?.(nextValue ?? undefined);
      }}
      inputValue={searchText}
      onInputChange={(_, nextSearchText) => {
        setSearchText(nextSearchText);
      }}
      getOptionLabel={getOptionKeyLabel}
      renderOption={renderOptionKey}
      renderInput={(params) => (
        <TextField
          {...params}
          {...TextFieldProps}
          variant="outlined"
          InputProps={{ ...params.InputProps, ...TextFieldProps.InputProps }}
        />
      )}
      groupBy={
        !groupBy ? undefined : (key) => groupBy(optionsMap.get(key) as TOption)
      }
      ListboxComponent={ListboxComponent}
      ListboxProps={{
        onScroll: handleScroll,
        'aria-busy': isFetchingNextOptionsPage,
      }}
    />
  );
}
