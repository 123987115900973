import { useMemo } from 'react';
import { APIListQueryResult } from 'shared/api/APIListQuery';
import { isEmptyRecord } from 'shared/helpers/CommonHelpers';
import { DriverDTO } from '../../drivers/data/DriverDTO';
import { TripDTO } from '../data/TripsDTO';

export function useMapDrivers(
  driverList: APIListQueryResult<DriverDTO>,
  trip: TripDTO,
) {
  return useMemo(() => {
    const drivers = driverList.data?.pages.flatMap((page) => page.data);

    //render driver returned from api only if driver not presented in driver list
    let shouldShowDefaultDriver = false;
    if (drivers && !isEmptyRecord(trip.driver)) {
      shouldShowDefaultDriver = drivers.every(
        (driver) => driver.guid !== trip.driver?.guid,
      );
    }

    return { drivers, shouldShowDefaultDriver };
  }, [driverList, trip]);
}
