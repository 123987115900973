import { useConstant, useEventHandler } from '@superdispatch/hooks';
import { useEffect, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import { useGoogleMaps } from 'shared/geo/GoogleMaps';
import { TrackingDriverDTO } from './data/TrackingDTO';
import { TrackingInfoWindow } from './TrackingInfoWindow';

interface TrackingDriversMarkerProps {
  map?: google.maps.Map;
  driver: TrackingDriverDTO;
  isFocused: boolean;
  onFocusChange: (isFocused: boolean) => void;
}

export function TrackingDriversMarker({
  map,
  driver,
  isFocused,
  onFocusChange,
}: TrackingDriversMarkerProps) {
  const maps = useGoogleMaps();
  const [marker, setMarker] = useState<google.maps.Marker>();
  const [infoWindow, setInfoWindow] = useState<google.maps.InfoWindow>();
  const { latitude, longitude } = driver.last_location || {};
  const latLng = useMemo(
    (): undefined | google.maps.LatLngLiteral =>
      latitude == null || longitude == null
        ? undefined
        : { lat: latitude, lng: longitude },
    [latitude, longitude],
  );
  const infoWindowNode = useConstant(() => document.createElement('div'));
  const handleFocusChange = useEventHandler(onFocusChange);

  useEffect(() => {
    if (!map || !maps || !latLng) {
      setMarker(undefined);

      return;
    }

    const nextMarker = new maps.Marker({ map, position: latLng });

    setMarker(nextMarker);

    nextMarker.addListener('mouseover', () => {
      handleFocusChange(true);
    });

    return () => {
      nextMarker.setMap(null);
      maps.event.clearInstanceListeners(nextMarker);
    };
  }, [map, maps, latLng, handleFocusChange]);

  useEffect(() => {
    if (!map || !maps || !marker) {
      setInfoWindow(undefined);

      return;
    }

    const nextInfoWindow = new maps.InfoWindow({
      content: infoWindowNode,
      maxWidth: 360,
    });

    const infoWindowCloseEventListener = google.maps.event.addListener(
      nextInfoWindow,
      'closeclick',
      () => {
        handleFocusChange(false);
      },
    );

    setInfoWindow(nextInfoWindow);

    return () => {
      nextInfoWindow.close();
      google.maps.event.removeListener(infoWindowCloseEventListener);
    };
  }, [map, marker, infoWindowNode, maps, handleFocusChange]);

  useEffect(() => {
    if (!map || !marker || !isFocused) {
      return;
    }

    const position = marker.getPosition();

    if (position) {
      map.panTo(position);
    }
  }, [map, marker, isFocused]);

  useEffect(() => {
    if (!map || !marker || !infoWindow || !isFocused) {
      return;
    }

    infoWindow.open(map, marker);

    return () => {
      infoWindow.close();
    };
  }, [map, marker, infoWindow, isFocused]);

  return createPortal(
    <TrackingInfoWindow driver={driver} latLng={latLng} isOpen={isFocused} />,
    infoWindowNode,
  );
}
