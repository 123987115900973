import {
  Dialog,
  DialogContent,
  DialogTitle as MuiDialogTitle,
  IconButton,
  Typography,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { Color, Column, Columns, Stack } from '@superdispatch/ui';
import { Box, Button } from '@superdispatch/ui-lab';
import { useEffect, useState } from 'react';
import { ArrowRightRoundedIcon } from 'shared/icons/ArrowRightRounded';
import { CustomerVerificationIcon } from 'shared/icons/CustomerVerificationIcon';
import { useCarrierInfo } from 'shared/modules/carrier-profile/CarrierInfo';
import {
  isIdentificationStepComplete,
  ProfileVerifiedCarrierApplicationTabs,
} from 'shared/modules/carrier-profile/core/ProfileVerifiedCarrierApplicationTabs';
import { useIsCarrierWithoutUSDOT } from 'shared/modules/carrier-profile/data/CarrierPerformanceAPI';
import { useVerifiedCarrierApplicationStatus } from 'shared/modules/carrier-profile/VerifiedCarrierApplicationStatus';
import styled from 'styled-components';
import { StepCargoInsurancePage } from '../../../../carrier/profile/verified-carrier-application/StepCargoInsurancePage';
import { StepCarrierInfoPage } from '../../../../carrier/profile/verified-carrier-application/StepCarrierInfoPage';
import { StepIdentificationPage } from '../../../../carrier/profile/verified-carrier-application/StepIdentificationPage';
import { StepPhoneVerificationPage } from '../../../../carrier/profile/verified-carrier-application/StepPhoneVerificationPage';

interface BecomeVerifiedDialogProps {
  open: boolean;
  onClose: () => void;
  onCompleted: () => void;
}

const DialogTitle = styled(MuiDialogTitle)`
  border-bottom: 1px solid ${Color.Silver400};
`;

export function BecomeVerifiedDialog({
  open,
  onClose,
  onCompleted,
}: BecomeVerifiedDialogProps) {
  const [shouldShowBottomButton, setShowBottomButton] = useState(true);
  const [isShowVerification, setShowVerification] = useState(false);

  function handleScroll(event: React.UIEvent<HTMLDivElement>) {
    const target = event.target as HTMLDivElement;
    const { scrollTop, clientHeight, scrollHeight } = target;
    setShowBottomButton(scrollTop + clientHeight < scrollHeight - 200);
  }

  return (
    <Dialog fullWidth={true} maxWidth="lg" open={open} onClose={onClose}>
      <DialogTitle>
        <Columns align="top">
          <Column width="fluid">
            <Typography variant="h3">
              {isShowVerification
                ? 'Verification Application'
                : 'Become Verified Carrier'}
            </Typography>
          </Column>

          <Column width="content">
            <IconButton size="small" aria-label="close" onClick={onClose}>
              <Close />
            </IconButton>
          </Column>
        </Columns>
      </DialogTitle>
      <DialogContent onScroll={handleScroll}>
        {isShowVerification ? (
          <VerificationSteps
            onClose={onClose}
            onCompleted={onCompleted}
            onBack={() => {
              setShowVerification(false);
            }}
            shouldShowBottomButton={shouldShowBottomButton}
          />
        ) : (
          <BecomeVerificationDialogContent
            onClick={() => {
              setShowVerification(true);
            }}
          />
        )}
      </DialogContent>
    </Dialog>
  );
}

const DialogContainer = styled(Box)`
  width: 100%;
  min-height: 700px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Wrapper = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 140px 100px 100px;
  align-items: center;
`;

interface BecomeVerificationDialogContentProps {
  onClick: () => void;
}

function BecomeVerificationDialogContent({
  onClick,
}: BecomeVerificationDialogContentProps) {
  return (
    <DialogContainer>
      <Wrapper>
        <Columns space="small" align="top">
          <Column width="content">
            <ArrowRightRoundedIcon />
          </Column>
          <Column width="content">
            <Stack space="large">
              <Stack space="xsmall">
                <Typography variant="h3">4 Steps to Get Started</Typography>
                <Box width="500px">
                  <Typography color="textSecondary">
                    Super Loadboard is a trusted platform where vetted carriers
                    and shippers build meaningful business relationships. We
                    need to verify your company first to ensure a safe and
                    secure environment for everyone.
                  </Typography>
                </Box>
              </Stack>
              <Button onClick={onClick}>Continue</Button>
            </Stack>
          </Column>
        </Columns>

        <CustomerVerificationIcon />
      </Wrapper>
    </DialogContainer>
  );
}

const VerificationContainer = styled(Box)`
  height: 700px;
  width: 100%;
  padding: 24px 0;
`;

interface VerificationStepsProps {
  onBack: () => void;
  onClose: () => void;
  onCompleted: () => void;
  shouldShowBottomButton: boolean;
}

function VerificationSteps({
  onBack,
  onClose,
  onCompleted,
  shouldShowBottomButton,
}: VerificationStepsProps) {
  const { currentStep, progress, setCurrentStep } = useVerificationSteps();

  return (
    <VerificationContainer>
      <Stack space="medium">
        <ProfileVerifiedCarrierApplicationTabs
          type="dialog"
          activeStep={currentStep}
        />
        {currentStep === 3 ? (
          <Box
            maxWidth="90%"
            marginLeft="auto"
            marginRight="auto"
            paddingBottom="xxlarge"
          >
            <StepCargoInsurancePage
              shouldShowBottomButton={shouldShowBottomButton}
              onBack={() => {
                setCurrentStep(2);
              }}
              onComplete={() => {
                setCurrentStep(4);
              }}
            />
          </Box>
        ) : (
          <Box
            maxWidth="700px"
            marginLeft="auto"
            marginRight="auto"
            paddingBottom="xxlarge"
          >
            {currentStep === 1 && (
              <StepPhoneVerificationPage
                onBack={onBack}
                isPhoneVerificationComplete={
                  !!progress?.fmcsa_phone_verification_is_complete
                }
                onComplete={() => {
                  setCurrentStep(2);
                }}
              />
            )}
            {currentStep === 2 && (
              <StepCarrierInfoPage
                shouldShowBottomButton={shouldShowBottomButton}
                onBack={() => {
                  setCurrentStep(1);
                }}
                onComplete={() => {
                  setCurrentStep(3);
                }}
              />
            )}

            {currentStep === 4 && (
              <StepIdentificationPage
                shouldShowBottomButton={shouldShowBottomButton}
                onBack={() => {
                  setCurrentStep(3);
                }}
                onComplete={() => {
                  onCompleted();
                  onClose();
                }}
              />
            )}
          </Box>
        )}
      </Stack>
    </VerificationContainer>
  );
}

function useVerificationSteps() {
  const { data } = useVerifiedCarrierApplicationStatus();
  const { data: carrierInfo } = useCarrierInfo();
  const isNonUSDOTCarrier = useIsCarrierWithoutUSDOT();

  const progress = data?.verified_carrier_application.progress;
  const isStep1Complete = !!progress?.fmcsa_phone_verification_is_complete;
  const isStep2Complete = !!progress?.carrier_info_step_is_complete;
  const isStep3Complete = !!progress?.cargo_insurance_step_is_complete;
  const isStep4Complete =
    !!progress &&
    !!carrierInfo &&
    isIdentificationStepComplete(progress, carrierInfo, isNonUSDOTCarrier);
  const [currentStep, setCurrentStep] = useState<1 | 2 | 3 | 4>(1);

  useEffect(() => {
    if (!isStep1Complete) {
      setCurrentStep(1);
    } else if (!isStep2Complete) {
      setCurrentStep(2);
    } else if (!isStep3Complete) {
      setCurrentStep(3);
    } else if (!isStep4Complete) {
      setCurrentStep(4);
    }
  }, [isStep1Complete, isStep2Complete, isStep3Complete, isStep4Complete]);

  return {
    currentStep,
    setCurrentStep,
    progress,
  };
}
