import { useCallback } from 'react';
import { logError, logInfo, logWarning } from 'shared/helpers/ErrorTracker';
import { useMoovAccountInfo } from '../data/useMoovAccountInfo';
interface LogExtraInfoProps extends Record<string, unknown> {
  moovAccountId?: string;
}
export function logPaymentInfo(
  action: string,
  source: string,
  { moovAccountId, ...extraInfo }: LogExtraInfoProps = {},
) {
  logInfo(action, {
    namespace: `carrier.payments.onboarding.${source}`,
    moov_account_id: moovAccountId,
    ...extraInfo,
  });
}

export function logPaymentError(
  error: Error,
  source: string,
  { moovAccountId, ...extraInfo }: LogExtraInfoProps = {},
) {
  logError(error, `carrier.payments.onboarding.${source}`, {
    extraInfo: {
      moov_account_id: moovAccountId,
      ...extraInfo,
    },
  });
}

export function usePaymentLog() {
  const moovAccountInfo = useMoovAccountInfo();
  const moovAccountId = moovAccountInfo.getAccount()?.accountID;
  const infoLog = useCallback(
    (action: string, source: string, extraInfo?: Record<string, unknown>) => {
      logPaymentInfo(action, source, {
        moovAccountId,
        ...extraInfo,
      });
    },
    [moovAccountId],
  );
  const warningLog = useCallback(
    (action: string, source: string, extraInfo?: Record<string, unknown>) => {
      logWarning(action, {
        namespace: `carrier.payments.onboarding.${source}`,
        moov_account_id: moovAccountId,
        ...extraInfo,
      });
    },
    [moovAccountId],
  );

  const errorLog = useCallback(
    (error: Error, source: string, extraInfo?: Record<string, unknown>) => {
      logPaymentError(error, source, {
        moovAccountId,
        ...extraInfo,
      });
    },
    [moovAccountId],
  );
  return {
    logPaymentInfo: infoLog,
    logPaymentWarning: warningLog,
    logPaymentError: errorLog,
  };
}
