import {
  FileDropZone as UIFileDropZone,
  FileDropZoneProps,
} from '@superdispatch/ui-lab';
import { RefAttributes } from 'react';
import { FileRejection } from 'react-dropzone';
import { ACCEPTED_FILE_TYPES, MAX_FILE_SIZE } from 'shared/form/FileField';
import { logWarning } from 'shared/helpers/ErrorTracker';

/**
 * Component was added as secure alternative to FileDropZone from @superdispatch/ui-lab.
 * Component requires a list of accepted file types and max file size
 */
export function FileDropZone({
  children,
  accept = ACCEPTED_FILE_TYPES,
  maxSize = MAX_FILE_SIZE,
  onDropRejected,
  ...rest
}: FileDropZoneProps & RefAttributes<HTMLButtonElement>) {
  return (
    <UIFileDropZone
      maxSize={maxSize}
      accept={accept}
      hintText="or Drag & Drop files less than 20 MB"
      onDropRejected={(fileRejections) => {
        captureFileTypeError(fileRejections);
        onDropRejected?.(fileRejections);
      }}
      {...rest}
    >
      {children}
    </UIFileDropZone>
  );
}

function captureFileTypeError(fileRejectionList: FileRejection[]) {
  //filter only 'file-invalid-type' errors and extract type of files.
  const types = fileRejectionList
    .filter((fileRejection) =>
      fileRejection.errors.some((error) => error.code === 'file-invalid-type'),
    )
    .map((fileRejection) => fileRejection.file.type);

  if (types.length > 0) {
    logWarning('Unsupported Media Type in FileDropZone', { fileType: types });
  }
}
