import { Column, Columns, Tag } from '@superdispatch/ui';
import {
  Sidebar,
  SidebarContainer,
  SidebarMenuItem,
} from '@superdispatch/ui-lab';
import { ReactNode } from 'react';
import { Route, Switch } from 'react-router-dom';
import { getAuthToken } from 'shared/auth/AuthToken';
import { openExternalURL } from 'shared/helpers/URLHelpers';
import { useShowAddBillingAddressDialog } from 'shared/modules/subscription/core/useShowAddBillingAddressDialog';
import { Navigate } from 'shared/routing/react-router-6';
import { SidebarMenuItemLink } from 'shared/routing/SidebarMenuItemLink';
import { useFlag } from 'shared/settings/FeatureToggles';
import { SettingsBillingPage } from './SettingsBillingPage';
import { SettingsDriverAppPage } from './SettingsDriverAppPage';
import { SettingsFactoringPage } from './SettingsFactoringPage';
import { SettingsGeneralPage } from './SettingsGeneralPage';
import { SettingsNotificationsPage } from './SettingsNotificationsPage';
import { SettingsQuickbooksIntegrationPage } from './SettingsQuickbooksIntegrationPage';
import { SettingsSubscriptionLegacyPage } from './SettingsSubscriptionDetailsLegacyPage';
import { SettingsSubscriptionPage } from './SettingsSubscriptionDetailsPage';
import { SettingsTerminalPage } from './SettingsTerminalsPage';

interface SettingsSidebarProps {
  href: string;
  children?: ReactNode;
}

function SettingsSidebarExternalItem({ href, children }: SettingsSidebarProps) {
  return (
    <SidebarMenuItem
      external={true}
      onClick={() => {
        openExternalURL('{+href}{?token}', {
          href,
          target: '_blank',
          token: getAuthToken(),
        });
      }}
    >
      {children}
    </SidebarMenuItem>
  );
}

export function SettingsContainerPage() {
  const shouldShowQuickbooksIntegration = useFlag('quickbooks_integration');
  const { doesBillingAddressNotExist } = useShowAddBillingAddressDialog();
  const isSeatBasedPriceEnabled = useFlag('seats_based_pricing');

  return (
    <SidebarContainer
      sidebar={
        <Sidebar title="Settings">
          <SidebarMenuItemLink to="/settings/general/">
            General
          </SidebarMenuItemLink>
          <SidebarMenuItemLink to="/settings/terminals/">
            Terminals
          </SidebarMenuItemLink>
          <SidebarMenuItemLink to="/settings/driver-app/">
            Driver App
          </SidebarMenuItemLink>
          <SidebarMenuItemLink to="/settings/billing/">
            Billing
          </SidebarMenuItemLink>
          <SidebarMenuItemLink to="/settings/factoring/">
            Factoring
          </SidebarMenuItemLink>
          {shouldShowQuickbooksIntegration ? (
            <SidebarMenuItemLink to="/settings/quickbooks/">
              Quickbooks Integration
            </SidebarMenuItemLink>
          ) : (
            <SettingsSidebarExternalItem href="/apps/integrations/">
              Quickbooks Integration
            </SettingsSidebarExternalItem>
          )}
          <SidebarMenuItemLink to="/settings/subscription/">
            <Columns space="xsmall">
              <Column width="fluid">Subscription Details</Column>
              <Column width="content">
                {doesBillingAddressNotExist && (
                  <Tag variant="subtle" color="yellow">
                    To Do
                  </Tag>
                )}
              </Column>
            </Columns>
          </SidebarMenuItemLink>
          <SidebarMenuItemLink to="/settings/notifications/">
            Notifications
          </SidebarMenuItemLink>
          <SettingsSidebarExternalItem href="/loadboard/settings/notifications/">
            Loadboard Notifications
          </SettingsSidebarExternalItem>
        </Sidebar>
      }
    >
      <Switch>
        <Route path="/settings/general/">
          <SettingsGeneralPage />
        </Route>

        <Route path="/settings/terminals/">
          <SettingsTerminalPage />
        </Route>

        <Route path="/settings/driver-app/">
          <SettingsDriverAppPage />
        </Route>

        <Route path="/settings/billing/">
          <SettingsBillingPage />
        </Route>

        <Route path="/settings/factoring/">
          <SettingsFactoringPage />
        </Route>

        <Route path="/settings/quickbooks/">
          <SettingsQuickbooksIntegrationPage />
        </Route>

        <Route path="/settings/subscription/">
          {isSeatBasedPriceEnabled ? (
            <SettingsSubscriptionPage />
          ) : (
            <SettingsSubscriptionLegacyPage />
          )}
        </Route>

        <Route path="/settings/notifications/">
          <SettingsNotificationsPage />
        </Route>

        <Route path="/settings/notification-emails/">
          <Navigate to="/settings/notifications/" />
        </Route>

        <Route path="/">
          <Navigate to="/settings/general/" />
        </Route>
      </Switch>
    </SidebarContainer>
  );
}
