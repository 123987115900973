import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import {
  FormikMaxLengthTextField,
  useFormikEnhanced,
} from '@superdispatch/forms';
import { Stack, useSnackbarStack } from '@superdispatch/ui';
import { Button, TextBox } from '@superdispatch/ui-lab';
import { Form, FormikProvider } from 'formik';
import styled from 'styled-components';
import { trackDispatchersEvent } from '../data/DispatchersAnalytics';
import { useDispatchersAPI } from '../data/DispatchersAPI';
import { useDispatcherPageContext } from '../data/DispatchersProvider';

interface DispatcherDeactivationDialogProps {
  open: boolean;
  onClose: () => void;
}

const List = styled.ul`
  padding-inline-start: 20px;
  margin: 0;
`;

export function DispatcherDeactivationDialog({
  open,
  onClose,
}: DispatcherDeactivationDialogProps) {
  return (
    <Dialog open={open} onClose={onClose}>
      <DeactivationContent onClose={onClose} />
    </Dialog>
  );
}

interface DeactivationContentProps {
  onClose: () => void;
}

function DeactivationContent({ onClose }: DeactivationContentProps) {
  const { addSnackbar } = useSnackbarStack();
  const { deactivateDispatcher } = useDispatchersAPI();
  const { dispatcher, currentDispatcherGUID } = useDispatcherPageContext();
  const { data } = dispatcher;
  const formik = useFormikEnhanced({
    initialValues: {
      deactivationReason: '',
    },
    onSubmit: (values) => {
      if (currentDispatcherGUID) {
        return deactivateDispatcher(
          currentDispatcherGUID,
          values.deactivationReason,
        );
      }
      return Promise.reject(new Error('Please select dispatcher!'));
    },
    onSubmitSuccess: () => {
      trackDispatchersEvent({ name: 'Carrier Deactivated Dispatcher' });
      addSnackbar(`${data?.name || 'Dispatcher'} deactivated`);
      onClose();
    },
    onSubmitFailure: (error) => {
      addSnackbar(error.message, { variant: 'error' });
    },
  });

  return (
    <FormikProvider value={formik}>
      <Form aria-label="deactivate driver">
        <DialogTitle>Deactivate {data?.name}?</DialogTitle>
        <DialogContent>
          <Stack space="small">
            <List>
              <li>
                All of the orders related to the dispatcher will remain in your
                account.
              </li>

              <li>The dispatcher will not be able to login to Carrier TMS.</li>
              <li>
                You can reactivate this dispatcher at any time in the future.
              </li>
            </List>
            <FormikMaxLengthTextField
              name="deactivationReason"
              autoFocus={true}
              fullWidth={true}
              multiline={true}
              maxLength={512}
              label="Reason for Deactivation"
              placeholder="Tell us why you are deactivating this dispatcher"
              helperText={
                <TextBox color="secondary" variant="caption">
                  This will help us improve user experience
                </TextBox>
              }
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            variant="neutral"
            onClick={onClose}
            disabled={formik.isSubmitting}
          >
            Cancel
          </Button>
          <Button
            pending={formik.isSubmitting}
            variant="critical"
            type="submit"
          >
            Deactivate
          </Button>
        </DialogActions>
      </Form>
    </FormikProvider>
  );
}
