import { Route, Switch } from 'react-router-dom';
import { OnboardingInviteTeamStepPage } from './OnboardingInviteTeamStepPage';
import { OnboardingQuestionaireStep1Page } from './OnboardingQuestionnaireStep1Page';
import { OnboardingQuestionaireStep2Page } from './OnboardingQuestionnaireStep2Page';
import { OnboardingQuestionaireStep3Page } from './OnboardingQuestionnaireStep3Page';
import { OnboardingWelcomeStepPage } from './OnboardingWelcomeStepPage';

export function OnboardingContainer() {
  return (
    <Switch>
      <Route path="/onboarding/invite-team">
        <OnboardingInviteTeamStepPage />
      </Route>
      <Route path="/onboarding/questions-step-3">
        <OnboardingQuestionaireStep3Page />
      </Route>
      <Route path="/onboarding/questions-step-2">
        <OnboardingQuestionaireStep2Page />
      </Route>
      <Route path="/onboarding/questions-step-1">
        <OnboardingQuestionaireStep1Page />
      </Route>
      <Route path="/onboarding">
        <OnboardingWelcomeStepPage />
      </Route>
    </Switch>
  );
}
