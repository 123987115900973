import { AvatarButton } from '@superdispatch/ui';
import { useMemo } from 'react';
import { useDriverAccountDetails } from '../data/DriverAccountAPI';

export function DriverName() {
  const { data } = useDriverAccountDetails();
  const driverNameInitials = useMemo(
    () => getInitials(data?.name || ''),
    [data?.name],
  );

  return <AvatarButton size="large">{driverNameInitials}</AvatarButton>;
}

function getInitials(name: string) {
  return (name.match(/\b\w/g) || []).join('').toUpperCase();
}
