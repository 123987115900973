import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { Color, useSnackbarStack } from '@superdispatch/ui';
import React, { lazy, useEffect, useState } from 'react';
import type { DropResult } from 'react-beautiful-dnd';
import styled from 'styled-components';
import { useReorderTripLoads } from '../data/TripsAPI';
import { TripLoadDTO } from '../data/TripsDTO';
import { StyledCell, TripLoadsTableRow } from './TripLoadsTableRow';

const DragDropContext = lazy(() =>
  import('react-beautiful-dnd').then((module) => ({
    default: module.DragDropContext,
  })),
);

const Draggable = lazy(() =>
  import('react-beautiful-dnd').then((module) => ({
    default: module.Draggable,
  })),
);

const Droppable = lazy(() =>
  import('react-beautiful-dnd').then((module) => ({
    default: module.Droppable,
  })),
);

const StickyTableRow = styled(TableRow)`
  display: flex;
  position: sticky;
  top: 0;
  z-index: 1;
`;

const getItemStyle = (
  isDragging: boolean,
  draggableStyle?: React.CSSProperties,
) => {
  if (draggableStyle?.transform) {
    const [, y] = draggableStyle.transform.match(/-?\d{1,}/g) || [];

    return {
      ...draggableStyle,
      transform: `translateY(${y}px)`,
      boxShadow: isDragging ? `0 2px 10px 0 ${Color.Black20}` : undefined,
    };
  }

  return draggableStyle;
};

function arrayMove<T>(array: T[], from: number, to: number) {
  const newArray = Array.from(array);
  newArray.splice(to, 0, ...newArray.splice(from, 1));

  return newArray;
}

interface TripLoadsTableProps {
  tripGUID: string;
  loads: TripLoadDTO[];
  isTripArchived: boolean;
  path: string;
}

export function TripLoadsTable({
  tripGUID,
  loads,
  isTripArchived,
  path,
}: TripLoadsTableProps) {
  const { addSnackbar } = useSnackbarStack();
  const [sortedLoads, setSortedLoads] = useState(loads);
  const reorderLoads = useReorderTripLoads(tripGUID);

  const handleSortEnd = ({ destination, source }: DropResult) => {
    if (destination && destination.index !== source.index) {
      const nextSortedLoads = arrayMove(
        sortedLoads,
        source.index,
        destination.index,
      ).map((load, i) => ({
        ...load,
        load_order: i,
      }));

      setSortedLoads(nextSortedLoads);

      reorderLoads.mutate(
        nextSortedLoads.map(({ guid, load_order }) => ({
          load_guid: guid,
          load_order,
        })),
        {
          onSuccess() {
            addSnackbar("Load's order was successfully updated", {
              variant: 'success',
            });
          },
          onError() {
            setSortedLoads(loads);
            addSnackbar("Error occurred while updating the load's order", {
              variant: 'error',
            });
          },
        },
      );
    }
  };

  useEffect(() => {
    setSortedLoads(loads);
  }, [loads]);

  return (
    <TableContainer>
      <Table size="small" stickyHeader={true}>
        <TableHead>
          <StickyTableRow>
            <StyledCell />
            <StyledCell>
              <Typography variant="h5">Load ID</Typography>
            </StyledCell>
            <StyledCell>
              <Typography variant="h5">Status</Typography>
            </StyledCell>
            <StyledCell>
              <Typography variant="h5">Vehicle</Typography>
            </StyledCell>
            <StyledCell>
              <Typography variant="h5">Origin</Typography>
            </StyledCell>
            <StyledCell>
              <Typography variant="h5">Destination</Typography>
            </StyledCell>
            <StyledCell>
              <Typography variant="h5">Pickup Date</Typography>
            </StyledCell>
            <StyledCell>
              <Typography variant="h5">Delivery Date</Typography>
            </StyledCell>
            <StyledCell align="right">
              <Typography variant="h5">Customer & Payment</Typography>
            </StyledCell>
            {!isTripArchived && <StyledCell />}
          </StickyTableRow>
        </TableHead>
        <DragDropContext onDragEnd={handleSortEnd}>
          <Droppable droppableId="TripLoadsTable">
            {({ droppableProps, innerRef: droppableRef, placeholder }) => (
              <TableBody {...droppableProps} ref={droppableRef}>
                {sortedLoads.map((load) => (
                  <Draggable
                    isDragDisabled={isTripArchived}
                    draggableId={`TripLoadsTable-${load.guid}`}
                    index={load.load_order ?? 0}
                    key={load.guid}
                  >
                    {(
                      { dragHandleProps, draggableProps, innerRef },
                      snapshot,
                    ) => (
                      <TripLoadsTableRow
                        {...draggableProps}
                        {...dragHandleProps}
                        ref={innerRef}
                        load={load}
                        path={path}
                        isTripArchived={isTripArchived}
                        style={getItemStyle(
                          snapshot.isDragging,
                          draggableProps.style,
                        )}
                      />
                    )}
                  </Draggable>
                ))}
                {placeholder}
              </TableBody>
            )}
          </Droppable>
        </DragDropContext>
      </Table>
    </TableContainer>
  );
}
