import {
  Dialog,
  DialogActions,
  DialogTitle,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@material-ui/core';
import { Close, Search } from '@material-ui/icons';
import { Button } from '@superdispatch/ui-lab';
import { useEffect } from 'react';
import { useSearchQueryText } from 'shared/helpers/ReactHelpers';
import styled from 'styled-components';
import { AddTripLoadsTable } from './AddTripLoadsTable';

interface AddLoadsToTripDialogProps {
  isOpen: boolean;
  currentTripGUID: string;
  currentTripName?: string;
  onClose: () => void;
}

const Styled1 = styled.div`
  margin: 0 auto;
  max-width: 480px;
`;

const Styled2 = styled.div`
  height: 480px;
  overflow-y: auto;
`;

export function AddLoadsToTripDialog({
  isOpen,
  onClose,
  currentTripGUID,
  currentTripName,
}: AddLoadsToTripDialogProps) {
  const [searchText, searchQuery, setSearchText] = useSearchQueryText();

  useEffect(() => {
    if (!isOpen) {
      setSearchText('');
    }
  }, [isOpen, setSearchText]);

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth={true} maxWidth="lg">
      <DialogTitle disableTypography={true}>
        <Typography variant="h4" align="center">
          {currentTripName}
        </Typography>

        <Styled1>
          <TextField
            autoFocus={true}
            fullWidth={true}
            value={searchText}
            placeholder="Search loads..."
            onChange={(event) => {
              setSearchText(event.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search color="action" />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => {
                      setSearchText('');
                    }}
                  >
                    <Close />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Styled1>
      </DialogTitle>

      <Styled2>
        <AddTripLoadsTable
          searchQuery={searchQuery}
          tripGUID={currentTripGUID}
        />
      </Styled2>

      <DialogActions>
        <Button variant="neutral" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
