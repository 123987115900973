import { startCase } from 'lodash-es';
import {
  yupArray,
  yupDateString,
  yupEnum,
  yupNumber,
  yupObject,
  yupString,
} from 'shared/utils/YupUtils';
import { InferType } from 'yup';

export type CarrierRatingsSummaryDTO = InferType<
  typeof carrierRatingsSummarySchema
>;
export const carrierRatingsSummarySchema = yupObject({
  carrier_name: yupString(),
  carrier_logo_url: yupString(),
  rating: yupObject({
    percentage: yupNumber().default(0),
    count: yupNumber().default(0),
  }),
  summary: yupObject({
    on_time_transport: yupNumber().default(0).nullable(false),
    good_communication: yupNumber().default(0).nullable(false),
    good_customer_service: yupNumber().default(0).nullable(false),
  }),
});

export const CARRIER_RATING_TAGS = [
  'ON_TIME_TRANSPORT',
  'GOOD_COMMUNICATION',
  'ACCURATE_INFORMATION',
  'GOOD_CUSTOMER_SERVICE',
  'LATE_TRANSPORT',
  'DAMAGE',
  'BAD_COMMUNICATION',
  'BAD_CUSTOMER_SERVICE',
  'LATE_CANCELLATION_OR_NO_SHOW',
  'DOUBLE_BROKERING',
] as const;
export type CarrierRatingTags = (typeof CARRIER_RATING_TAGS)[number];

export function getCarrierRatingTagDisplayName(tag: CarrierRatingTags) {
  switch (tag) {
    case 'ON_TIME_TRANSPORT':
      return 'On-Time Transport';

    default:
      return startCase(tag.toLowerCase());
  }
}

export type CarrierRatingDTO = InferType<typeof carrierRatingSchema>;
export const carrierRatingSchema = yupObject({
  guid: yupString(),
  shipper_guid: yupString(),
  shipper_name: yupString(),
  rating: yupEnum(['POSITIVE', 'NEGATIVE']),
  comment: yupString(),
  tags: yupArray(yupString().oneOf(CARRIER_RATING_TAGS)).default([]),
  created_at: yupDateString('DateTimeISO'),
  changed_at: yupDateString('DateTimeISO'),
  status: yupEnum(['PUBLISHED', 'PENDING']),
  response: yupString(),
  response_date: yupDateString('DateTimeISO'),
});

export const CARRIER_RATINGS_PAGE_SIZE = 10;
export type CarrierRatingsPageParams = InferType<
  typeof carrierRatingsPageParamsSchema
>;
export const carrierRatingsPageParamsSchema = yupObject({
  ratings_page: yupNumber().default(1).nullable(false),
  ratings_page_size: yupNumber().default(CARRIER_RATINGS_PAGE_SIZE),
  tab: yupEnum(['ratings_received', 'ratings_to_give']).default(
    'ratings_received',
  ),
});

export const carrierReviewResponseSchema = yupObject({
  response: yupString().max(280),
});

export type UnratedShipperDTO = InferType<typeof unratedShipperSchema>;
export const unratedShipperSchema = yupObject({
  guid: yupString(),
  name: yupString(),
  logo: yupString().nullable(),
  accepted_loads_count: yupNumber(),
  total_rating_count: yupNumber(),
  overall_rating: yupNumber(),
});

export type UnratedShippersCountDTO = InferType<
  typeof unratedShippersCountSchema
>;
export const unratedShippersCountSchema = yupObject({
  unrated_count: yupNumber().nullable(false),
});
