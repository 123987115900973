import { SidebarContainer } from '@superdispatch/ui-lab';
import { useEffect, useState } from 'react';
import { ContactsDeleteSnackbar } from './ContactsDeleteSnackbar';
import { ContactsPageDetails } from './ContactsPageDetails';
import { ContactsPageList } from './ContactsPageList';
import { ContactsPageRouterProvider } from './ContactsPageRouter';
import { trackContactsEvent } from './data/ContactsAnalytics';

export function ContactsPage() {
  const [selectedContacts, setSelectedContacts] = useState(
    () => new Set<string>(),
  );

  useEffect(() => {
    trackContactsEvent({ name: 'CTMS: Opened React Contacts Page' });
  }, []);

  return (
    <ContactsPageRouterProvider>
      <SidebarContainer
        sidebar={
          <ContactsPageList
            selectedContacts={selectedContacts}
            onSelectedContactsChange={setSelectedContacts}
          />
        }
      >
        <ContactsDeleteSnackbar
          contacts={selectedContacts}
          onClose={() => {
            setSelectedContacts(new Set());
          }}
        />

        {!selectedContacts.size && <ContactsPageDetails />}
      </SidebarContainer>
    </ContactsPageRouterProvider>
  );
}
