import { Typography } from '@material-ui/core';
import { ReactElement } from 'react';
import { LoginLayout } from 'shared/auth/core/LoginLayout';
import styled from 'styled-components';

const Copyright = styled.div`
  margin: 16px auto;
`;

interface VerifyAccountContainerProps {
  children: ReactElement;
}

export function VerifyAccountContainer({
  children,
}: VerifyAccountContainerProps) {
  return (
    <LoginLayout
      footerAction={
        <Copyright>
          <Typography align="center" color="textSecondary">
            &copy; {new Date().getFullYear()} Super Dispatch. All rights
            reserved.
          </Typography>
        </Copyright>
      }
    >
      {children}
    </LoginLayout>
  );
}
