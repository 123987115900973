import { useDeepEqualValue, useIsMounted } from '@superdispatch/hooks';
import { dequal } from 'dequal';
import {
  DependencyList,
  Dispatch,
  ReducerWithoutAction,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

export interface ResourceState<T> {
  data?: T;
  error?: Error;
  isLoading?: true;
}

export interface Resource<T> extends ResourceState<T> {
  refetch: () => void;
  setData: Dispatch<SetStateAction<undefined | T>>;
}

export interface UseResourceOptions {
  key?: unknown;
  skip?: boolean;
}

export function useResource<T>(
  factory: () => T | PromiseLike<T>,
  deps: DependencyList,
  { key = null, skip = false }: UseResourceOptions = {},
): Resource<T> {
  const isMounted = useIsMounted();
  const [randomKey, setRandomKey] = useState(Math.random);
  const [state, setState] = useState<ResourceState<T>>({ isLoading: true });

  const factoryRef = useRef(factory);
  const pureDeps = useDeepEqualValue(deps);

  const refetch = useCallback(() => {
    setRandomKey(Math.random);
  }, []);
  const updateState = useCallback(
    (updater: (prevState: ResourceState<T>) => ResourceState<T>) => {
      setState((prevState) => {
        const nextState = updater(prevState);

        if (dequal(nextState, prevState)) {
          return prevState;
        }

        return nextState;
      });
    },
    [],
  );
  const setData = useCallback<Dispatch<SetStateAction<undefined | T>>>(
    (action) => {
      if (isMounted()) {
        updateState((prevState) => ({
          data:
            typeof action !== 'function'
              ? action
              : (action as ReducerWithoutAction<undefined | T>)(prevState.data),
        }));
      }
    },
    [isMounted, updateState],
  );

  useEffect(() => {
    factoryRef.current = factory;
  });

  useEffect(() => {
    setState((prevState) => ({ isLoading: true, data: prevState.data }));

    if (skip) {
      return;
    }

    let isCurrent = true;

    Promise.resolve(factoryRef.current()).then(
      (data) => {
        if (isCurrent) {
          setState({ data });
        }
      },
      (error: Error) => {
        if (isCurrent) {
          setState({ error });
        }
      },
    );

    return () => {
      isCurrent = false;
    };
  }, [key, skip, randomKey, pureDeps]);

  return useMemo<Resource<T>>(
    () => ({ ...state, refetch, setData }),
    [state, refetch, setData],
  );
}
