import { Typography } from '@material-ui/core';
import { Stack } from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import { ReactNode } from 'react';
import { EmptyRatingIllustration } from './EmptyRatingIllustration';

interface Props {
  message?: ReactNode;
}

export function EmptyRatings({ message }: Props) {
  return (
    <Stack align="center">
      <Box
        width="90px"
        height="80px"
        marginTop={['small']}
        marginBottom="xsmall"
      >
        <EmptyRatingIllustration />
      </Box>

      <Typography variant="h3" color="textSecondary" align="center">
        No ratings yet!
      </Typography>

      {message}
    </Stack>
  );
}
