import {
  CanadaProvince,
  CanadaProvinceOptionsSet,
} from 'shared/form/CanadaProvince';
import {
  CountryFieldOption,
  countryFieldOptions,
} from 'shared/form/CountryFieldOptions';
import { USAState, USAStateOptions } from 'shared/form/USAState';
import {
  yupArray,
  yupBoolean,
  yupDateString,
  yupEnum,
  yupNumber,
  yupObject,
  yupOmit,
  yupPhone,
  yupString,
} from 'shared/utils/YupUtils';
import { InferType, StringSchema } from 'yup';

export type GeneralSettingsDTO = InferType<typeof generalSettingsSchema>;
export const generalSettingsSchema = yupObject({
  inbox: yupString(),
  timezone: yupString().required(),
  initial_load_id: yupNumber(),
  multiple_units_bol: yupBoolean(),
});
export const generalSettingsEditSchema = generalSettingsSchema.shape({
  inbox: yupOmit,
});

export type DriverAppSettingsDTO = InferType<typeof driverAppSettingsSchema>;
export const driverAppSettingsSchema = yupObject({
  fleet_mode: yupBoolean(),
  hide_pricing: yupBoolean(),
  is_mobile_loadboard_enabled: yupBoolean(),
  is_customer_signature_required: yupBoolean().default(true),
  is_subject_to_inspection_disabled: yupBoolean(),
  enabled_driver_pay: yupBoolean().default(true),
  drivers_can_edit_carrier_info: yupBoolean().default(true),
  drivers_can_rate: yupBoolean(),
  drivers_can_mark_vehicles_as_cannot_be_picked_up: yupBoolean(),
});

export type BillingSettingsDTO = InferType<typeof billingSettingsSchema>;
export const billingSettingsSchema = yupObject({
  attach_bol: yupBoolean(),
  include_dates_on_bol: yupBoolean(),
  bcc_email: yupString().email('Enter a single valid email').max(200),
});

export type EmailNotificationSettingsDTO = InferType<
  typeof emailNotificationSettingsSchema
>;
export const emailNotificationSettingsSchema = yupObject({
  broker_notifications_enabled: yupBoolean(),
  notification_emails: yupArray(
    yupString().email('Please make sure all emails are correct.').required(),
  ),
  delivery_confirmation_emails: yupArray(
    yupString().email('Please make sure all emails are correct.').required(),
  ),
});

export type LoadOfferNotificationSettingsDTO = InferType<
  typeof loadOfferNotificationSettingsSchema
>;
export const loadOfferNotificationSettingsSchema = yupObject({
  is_load_offer_email_notifications_enabled: yupBoolean(),
  is_load_offer_sms_notifications_enabled: yupBoolean(),
  is_superpay_payment_onhold_email_notifications_enabled: yupBoolean(),

  is_valid_load_offer_phone_numbers: yupBoolean(),
  is_unsubscribed_from_load_offer_sms: yupBoolean(),
  is_unsubscribed_from_load_offer_emails: yupBoolean(),

  user_email: yupString().email(),
  load_offer_email: yupString().email(),
  load_offer_phone_numbers: yupArray(yupPhone()),
});

export type NotificationSettingsPayloadDTO = InferType<
  typeof loadOfferNotificationSettingsPayloadSchema
>;
export const loadOfferNotificationSettingsPayloadSchema = yupObject({
  is_load_offer_email_notifications_enabled: yupBoolean(),
  is_load_offer_sms_notifications_enabled: yupBoolean(),
  is_superpay_payment_onhold_email_notifications_enabled: yupBoolean(),
});

export type FactoringSettingsDTO = InferType<typeof factoringSettingsSchema>;
export const factoringSettingsSchema = yupObject({
  name: yupString(),
  address: yupString(),
  city: yupString(),
  state: yupString(),
  zip: yupString(),
  email: yupString(),
  phone_number: yupString(),
  fee_in_percent: yupNumber(),
});

export type DowngradeRequestDTO = InferType<typeof downgradeRequestSchema>;
export const downgradeRequestSchema = yupObject({
  reasons: yupArray(
    yupObject({
      reason: yupString(),
      comment: yupString(),
    }),
  ),
  feedback: yupString(),
});

export type TaxPriceDTO = InferType<typeof taxPriceSchema>;
export const taxPriceSchema = yupObject({
  sub_total: yupNumber(),
  total: yupString(),
  taxes: yupArray(
    yupObject({
      name: yupString(),
      description: yupString(),
      amount: yupNumber(),
    }),
  ),
});

export type SubscriptionDTO = InferType<typeof subscriptionSchema>;
export const subscriptionSchema = yupObject({
  country: yupEnum([null, ...countryFieldOptions.keys()]).required(),
  state: yupString().when(
    'country',
    (country: CountryFieldOption, schema: StringSchema) => {
      if (country === 'US') {
        return schema.test('us-state', 'Select valid state', (state) =>
          USAStateOptions.has(state as USAState),
        );
      }

      if (country === 'CA') {
        return schema.test(
          'canada-province',
          'Select valid province',
          (state) => CanadaProvinceOptionsSet.has(state as CanadaProvince),
        );
      }

      return schema;
    },
  ),
  zip: yupString().when(
    'country',
    (country: CountryFieldOption, schema: StringSchema) => {
      if (country === 'US' || country === 'CA') {
        return schema.required();
      }

      return schema;
    },
  ),
  address: yupString().when(
    'country',
    (country: CountryFieldOption, schema: StringSchema) => {
      if (country === 'US' || country === 'CA') {
        return schema.required();
      }

      return schema;
    },
  ),
  city: yupString().when(
    'country',
    (country: CountryFieldOption, schema: StringSchema) => {
      if (country === 'US' || country === 'CA') {
        return schema.required();
      }

      return schema;
    },
  ),
});

export type QuickbookOnlineDTO = InferType<typeof quickbookOnlineSchema>;
export const quickbookOnlineSchema = yupObject({
  connected: yupBoolean(),
  grant_url: yupString(),
  connected_at: yupDateString('DateTimeISO'),
  invoice_item: yupString(),
  is_us_company: yupBoolean(),
  tax: yupString(),
  is_allowed_to_create_customer: yupBoolean(),
  customers_updated_at: yupDateString('DateTimeISO'),
});

export type QuickbookOnlineServiceDTO = InferType<
  typeof quickbookOnlineServiceSchema
>;
export const quickbookOnlineServiceSchema = yupObject({
  id: yupNumber(),
  name: yupString(),
});

export type QuickbookDesktopDTO = InferType<typeof quickbookDesktopSchema>;
export const quickbookDesktopSchema = yupObject({
  connected: yupBoolean(),
  connected_at: yupDateString('DateTimeISO'),
  customers_last_updated_at: yupDateString('DateTimeISO'),
  is_allowed_to_create_customer: yupBoolean(),
  service_items_last_updated_at: yupDateString('DateTimeISO'),
  qwc_url: yupString(),
  password: yupString(),
  service_items: yupArray(
    yupObject({
      id: yupNumber(),
      name: yupString(),
    }),
  ),
});

export type QBOTaxDTO = InferType<typeof qboTaxSchema>;
export const qboTaxSchema = yupObject({
  id: yupString(),
  name: yupString(),
  rate: yupNumber(),
});
