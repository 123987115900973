//eslint-disable-next-line import/no-internal-modules
import ChargebeeComponents from '@chargebee/chargebee-js-react-wrapper/dist/components/ComponentGroup';
import {
  Address,
  ChargebeeInstance,
  InitOptions,
} from '@chargebee/chargebee-js-types';
import { ChangeEvent, useMemo, useRef } from 'react';
import { ensureError } from 'shared/utils/ErrorUtils';
import { logError } from './ErrorTracker';

declare global {
  interface Window {
    Chargebee?: {
      init: (options: InitOptions) => void;
      getInstance: () => ChargebeeInstance;
    };
  }
}

export interface TokenizeProps {
  firstName?: string;
  lastName?: string;
}

export interface ChargebeeError {
  message: string;
  code: 'cardCvvInvalid' | 'invalidCard' | 'cardExpiryInvalid';
  name: string;
  type: string;
}

interface ChargebeeAddittionalInformationProps {
  braintree: ChargebeeCreditCard | undefined;
}

export interface ChargebeeCreditCard {
  creditCard: {
    details: {
      bin: string;
      cardType: string;
      cardholderName: string;
      expirationMonth: string;
      expirationYear: string;
      lastFour: string;
      lastTwo: string;
    };
  };
}

export interface ChargebeeResponse {
  additional_information: ChargebeeAddittionalInformationProps;
  token: string;
  vaultToken: string;
}

export interface FieldError {
  errorCode:
    | 'card_number_incomplete'
    | 'card_number_invalid'
    | 'card_cvv_incomplete'
    | 'card_expiry_past';
  message: string;
}

export interface ChargebeeEvent extends ChangeEvent {
  error?: FieldError;
  field: 'number' | 'expiry' | 'cvv';
  complete?: boolean;
  empty: boolean;
  cardType: 'amex' | 'visa' | 'mastercard';
}

export interface ChargebeeErrorField {
  expiry: FieldError | null;
  cvv: FieldError | null;
  number: FieldError | null;
}

export const fonts = ['https://fonts.googleapis.com/css2?family=Inter'];

export function useChargebee(token?: string) {
  const cardRef = useRef<ChargebeeComponents | null>(null);
  const chargebeeInstance = useMemo(() => getChargebeeInstance(token), [token]);

  const tokenize = async ({
    firstName,
    lastName,
  }: TokenizeProps): Promise<ChargebeeResponse> => {
    if (cardRef.current && Boolean(chargebeeInstance)) {
      try {
        const data = (await cardRef.current.tokenize({
          billingAddress: {
            firstName,
            lastName,
          },
        })) as ChargebeeResponse;

        return data;
      } catch (err: unknown) {
        return Promise.reject(ensureError(err, 'Chargebee is not available'));
      }
    }

    return Promise.reject(new Error('Chargebee is not available'));
  };

  const addChargebeeCreditCard = async ({ firstName, lastName }: Address) => {
    return tokenize({ firstName, lastName });
  };

  return {
    cardRef,
    tokenize,
    isChargebeeAvailable: Boolean(chargebeeInstance),
    addChargebeeCreditCard,
    chargebeeInstance,
  };
}

function getChargebeeInstance(token?: string) {
  const chargebee = window.Chargebee;

  try {
    if (!chargebee || !token) {
      return null;
    }

    //eslint-disable-next-line @typescript-eslint/no-confusing-void-expression
    const instance = chargebee.init({
      site:
        import.meta.env.VITE_APP_TARGET === 'production'
          ? 'superdispatch'
          : 'superdispatch-test',
      publishableKey: token,
    });
    return instance;
  } catch (err: unknown) {
    const isError = err instanceof Error;
    logError(isError, 'ChargebeeWeb');

    return null;
  }
}
