import { Color } from '@superdispatch/ui';
import { createSvgIcon } from 'shared/icons/IconUtils';

export const ErrorIcon = createSvgIcon(
  'ErrorIcon',
  <>
    <rect x="5" y="5" width="14" height="14" fill="white" />
    <path
      d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM13 17H11V15H13V17ZM13 13H11L11 7H13L13 13Z"
      fill="currentColor"
    />
  </>,
  { style: { color: Color.Red300 } },
);
