import { Dialog, DialogContent, Divider } from '@material-ui/core';
import { Color, Column, Columns, Inline, Stack } from '@superdispatch/ui';
import { Box, Button, TextBox } from '@superdispatch/ui-lab';
import { LoadsSidebarIcon } from 'shared/icons/LoadsSidebarIcon';
import { OrganizationIcon } from 'shared/icons/OrganizationIcon';
import { UploadCloudIcon } from 'shared/icons/UploadCloudIcon';
import { CustomerComments } from 'shared/modules/subscription/core/CustomerComments';
import { LinkButton } from 'shared/routing/Links';
import { trackLoadsEvent } from '../data/LoadsAnalytics';
interface UpgradePlanDialogProps {
  open: boolean;
  onClose: () => void;
}

const items = [
  {
    icon: <LoadsSidebarIcon fontSize="large" htmlColor={Color.Dark300} />,
    title: 'Efficient Load Management',
    description:
      'Streamline your operations with easy load creation and import.',
  },
  {
    icon: <OrganizationIcon htmlColor={Color.Dark300} />,
    title: 'Enhanced Organization',
    description: 'Keep track of all your loads in one convenient place.',
  },
  {
    icon: <UploadCloudIcon htmlColor={Color.Dark300} />,
    title: 'Time-Saving',
    description:
      'Reduce manual entry and speed up your workflow by importing loads from a PDF or CSV file.',
  },
];

export function UpgradePlanDialog({ open, onClose }: UpgradePlanDialogProps) {
  return (
    <Dialog onClose={onClose} open={open}>
      <DialogContent>
        <Box padding="medium" paddingBottom="xlarge">
          <Stack space="medium">
            <Stack space="small">
              <TextBox variant="heading-2">
                Upgrade to Unlock Additional Features
              </TextBox>
              <TextBox variant="body">
                The Super Dispatch limited access version doesn&apos;t support
                the Create and Import Load features. Upgrade your plan to access
                these essential tools:
              </TextBox>
            </Stack>

            {items.map((item) => (
              <Columns align="top" key={item.title} space="medium">
                <Column width="content">
                  <Inline horizontalAlign="right">{item.icon}</Inline>
                </Column>
                <Column>
                  <Stack space="xxsmall">
                    <TextBox variant="heading-4">{item.title}</TextBox>
                    <TextBox variant="body" color="secondary">
                      {item.description}
                    </TextBox>
                  </Stack>
                </Column>
              </Columns>
            ))}

            <Divider />

            <CustomerComments />

            <Box paddingTop="xsmall">
              <Columns space="small">
                <Column width="1/2">
                  <Button
                    onClick={() => {
                      onClose();
                      trackLoadsEvent({
                        name: 'Carrier Closed Upgrade Plan Dialog',
                      });
                    }}
                    fullWidth={true}
                    size="large"
                    variant="neutral"
                  >
                    Maybe Later
                  </Button>
                </Column>
                <Column width="1/2">
                  <LinkButton
                    to="/settings/subscription"
                    fullWidth={true}
                    size="large"
                    onClick={() => {
                      trackLoadsEvent({ name: 'Carrier Clicked Upgrade Plan' });
                    }}
                  >
                    Upgrade
                  </LinkButton>
                </Column>
              </Columns>
            </Box>
          </Stack>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
