import { TextField } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import { Stack } from '@superdispatch/ui';
import { useFormikContext } from 'formik';
import { MobileAppBridge } from 'shared/data/MobileAppBridge';
import {
  EMPTY_ERROR_MESSAGE,
  FormikMediaField,
} from 'shared/form/FormikMediaField';
import { useCarrierInfo } from 'shared/modules/carrier-profile/CarrierInfo';
import { FieldAdditionalMessages } from 'shared/modules/carrier-profile/core/AdditionalMessages';
import { useVerifiedCarrierApplicationStatus } from 'shared/modules/carrier-profile/VerifiedCarrierApplicationStatus';
import { useCarrierSettings } from 'shared/settings/CarrierSettingsAPI';
import { USDOTDTO } from '../data/CarrierProfileDTO';

export function ProfileUSDOTForm() {
  const carrierInfo = useCarrierInfo();
  const { data } = useVerifiedCarrierApplicationStatus();
  const formik = useFormikContext<USDOTDTO>();
  const { data: settings } = useCarrierSettings();

  const isEditingDisabled =
    !settings?.drivers_can_edit_carrier_info && MobileAppBridge.isInjected();
  const decliningReasons = data?.verified_carrier_application.declining_reasons;
  const USDOTDecliningReasons = decliningReasons?.usdot_certificate_url;
  const USDOTRevokingReasons =
    data?.verified_carrier_application.revoking_reasons?.usdot_certificate_url;
  const externalNotes =
    data?.verified_carrier_application.external_notes?.usdot_certificate;

  const isInitialUSDOTFile =
    formik.initialValues.usdot_certificate_url ===
    formik.values.usdot_certificate_url;

  const shouldShowUSDOTError =
    isInitialUSDOTFile &&
    !!(USDOTDecliningReasons?.length || USDOTRevokingReasons?.length);

  return (
    <Stack space="large">
      <Stack space="xsmall">
        <FormikMediaField
          label="USDOT Certificate"
          name="usdot_certificate_url"
          fullWidth={true}
          disabled={isEditingDisabled}
          error={shouldShowUSDOTError}
          validate={() =>
            shouldShowUSDOTError ? EMPTY_ERROR_MESSAGE : undefined
          }
        />

        {shouldShowUSDOTError && USDOTDecliningReasons && (
          <FieldAdditionalMessages
            variant="error"
            title="USDOT Certificate declined due to:"
            messages={USDOTDecliningReasons}
            externalNotes={externalNotes}
          />
        )}

        {shouldShowUSDOTError && USDOTRevokingReasons && (
          <FieldAdditionalMessages
            variant="error"
            title="USDOT Certificate revoked due to:"
            messages={USDOTRevokingReasons}
            externalNotes={externalNotes}
          />
        )}
      </Stack>

      <TextField
        id="USDOT Number"
        aria-readonly={true}
        label="USDOT Number"
        value={carrierInfo.data?.usdot_number || ''}
        disabled={true}
        fullWidth={true}
        InputProps={{
          endAdornment: !!carrierInfo.data?.usdot_number && (
            <Check fontSize="small" color="primary" fontWeight="bolder" />
          ),
        }}
      />
    </Stack>
  );
}
