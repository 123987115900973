import { Column, Columns } from '@superdispatch/ui';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { HALF_OF_A_SECOND } from 'shared/constants/NumberConstants';
import { useDocumentTitle } from 'shared/layout/DocumentTitle';
import { PageLayout } from 'shared/layout/PageLayout';
import { trackLoadsEvent } from '../data/LoadsAnalytics';
import { useLoadDetails } from '../data/LoadsAPI';
import { ViewLoadCustomer } from './ViewLoadCustomer';
import { ViewLoadDelivery } from './ViewLoadDelivery';
import { ViewLoadPageHeader } from './ViewLoadPageHeader';
import { ViewLoadPayment } from './ViewLoadPayment';
import { ViewLoadPickup } from './ViewLoadPickup';
import { ViewLoadVehicles } from './ViewLoadVehicles';

export function ViewLoadPagePrintPage() {
  const { loadGUID } = useParams<{ loadGUID: string }>();
  const { data } = useLoadDetails(loadGUID);

  useDocumentTitle(`${data?.number || 'No Load ID'} - Loads`);

  useEffect(() => {
    if (data?.guid) {
      trackLoadsEvent({ name: 'CTMS: Opened Print Load Page' });

      try {
        window.print();
      } catch {
        // eslint-disable-next-line no-alert
        window.alert(
          'Browser blocked automatic printing, please print this page manually and close it after.',
        );
      }
    }
  }, [data?.guid]);

  useEffect(() => {
    const handleAfterPrint = () => {
      setTimeout(() => {
        try {
          window.close();
        } catch {
          // eslint-disable-next-line no-alert
          window.alert(
            'Browser blocked automatic printing, please print this page manually and close it after.',
          );
        }
      }, HALF_OF_A_SECOND);
    };

    window.addEventListener('afterprint', handleAfterPrint);

    return () => {
      window.removeEventListener('afterprint', handleAfterPrint);
    };
  }, []);

  return (
    <PageLayout
      background="White"
      header={<ViewLoadPageHeader load={data} layout="print" />}
    >
      {data && (
        <>
          <Columns space="small">
            <Column>
              <ViewLoadPickup load={data} layout="print" />
            </Column>

            <Column>
              <ViewLoadDelivery load={data} layout="print" />
            </Column>
          </Columns>

          <ViewLoadVehicles load={data} layout="print" />

          <Columns space="small">
            <Column>
              <ViewLoadPayment load={data} layout="print" />
            </Column>

            <Column>
              <ViewLoadCustomer load={data} layout="print" />
            </Column>
          </Columns>
        </>
      )}
    </PageLayout>
  );
}
