import { Card, CardContent, Grid, Typography } from '@material-ui/core';
import { Stack } from '@superdispatch/ui';
import { TextBox } from '@superdispatch/ui-lab';
import React from 'react';
import { formatCurrency } from 'shared/helpers/IntlHelpers';

interface ReportsCardItemProps {
  label: string;
  value?: number | null;
}

export function ReportsCardItem({ label, value }: ReportsCardItemProps) {
  return (
    <Grid
      item={true}
      container={true}
      alignItems="center"
      spacing={1}
      key={label}
    >
      <Grid item={true}>{label}</Grid>
      <Grid item={true}>
        {value && (
          <Typography
            aria-label="Reports Card Label"
            display="inline"
            variant="body1"
          >
            {formatCurrency(value)}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
}

interface ReportsCardProps {
  title: React.ReactNode;
  children: React.ReactNode;
}

export function ReportsCard({ title, children }: ReportsCardProps) {
  return (
    <Card>
      <CardContent>
        <Stack>
          <TextBox variant="heading-3">{title}</TextBox>

          {children}
        </Stack>
      </CardContent>
    </Card>
  );
}
