import {
  formatPaymentTerm,
  formatVehicleType,
  isValidPaymentTerm,
  PaymentTerm,
} from '@superdispatch/sdk';
import { isEmpty, omitBy, startCase } from 'lodash-es';
import { Geocoding } from 'shared/geo/GeoHelpers';
import {
  deepClone,
  tryParseJSON,
  tryStringifyJSON,
} from 'shared/utils/DataUtils';
import { joinStrings } from 'shared/utils/StringUtils';
import {
  transformQueryArray,
  transformStringToArray,
  yupArray,
  yupObject,
} from 'shared/utils/YupUtils';
import {
  array,
  boolean,
  InferType,
  lazy,
  mixed,
  number,
  object,
  string,
} from 'yup';
import {
  convertPricePerKmToPricePerMile,
  formatPostingAddress,
  mileToKm,
} from './LoadboardUtils';

export type VenueDTO = InferType<typeof venueSchema>;
const venueSchema = yupObject({
  zip: string().nullable(),
  city: string().nullable(),
  state: string().nullable(),
  latitude: number().nullable().optional(),
  longitude: number().nullable().optional(),
});

export type PostingSearchCriteriaVenueDTO = InferType<
  typeof postingSearchCriteriaVenueSchema
>;
export const postingSearchCriteriaVenueSchema = venueSchema.clone();

export function mapGeocodeToVenue(
  geocode: Geocoding,
): PostingSearchCriteriaVenueDTO {
  return postingSearchCriteriaVenueSchema.cast({
    longitude: geocode.longitude,
    latitude: geocode.latitude,
    zip: geocode.postcode,
    city: geocode.place,
    state: geocode.region_short?.toLocaleLowerCase(),
  });
}

export function mapVenueToGeocode(
  venue: PostingSearchCriteriaVenueDTO,
): Geocoding {
  return omitBy(
    {
      longitude: venue.longitude,
      latitude: venue.latitude,
      postcode: venue.zip,
      place: venue.city,
      region_short: venue.state?.toUpperCase(),
    },
    (item) => item == null,
  );
}

export function canHaveRadius(venue: PostingSearchCriteriaVenueDTO): boolean {
  return Boolean(venue.city || venue.zip);
}

export function isValidLATRSearchCriteria({
  pickup_venues,
  delivery_venues,
}: PostingSearchCriteriaDTO) {
  const [pickupVenue] = pickup_venues;
  const [deliveryVenue] = delivery_venues;

  if (pickup_venues.length !== 1 || delivery_venues.length !== 1) {
    return false;
  }

  return (
    !!pickupVenue &&
    !!deliveryVenue &&
    canHaveRadius(pickupVenue) &&
    canHaveRadius(deliveryVenue)
  );
}

function transformVenue(raw?: unknown[]) {
  const value = Array.isArray(raw) ? raw : [];
  return value.length === 0 ? undefined : value;
}

function transformNanToNull(value: number) {
  return Number.isNaN(value) ? null : value;
}

export const paymentGroups = [
  ['cash_on_delivery', 'cash_on_pickup'],
  ['cash_on_delivery', 'cash_on_pickup', 'quick_pay', 'comchek'],
  [
    'other',
    'ach',
    '5_days',
    '7_days',
    '10_days',
    '15_days',
    '20_days',
    '30_days',
    '45_days',
    '60_days',
  ],
  ['superpay'],
] as const;

export function formatPaymentTermsLabel(terms: unknown): string {
  if (Array.isArray(terms)) {
    if (terms.includes('other')) {
      return 'Billing';
    }

    return Array.from(new Set(terms))
      .filter((term) => isValidPaymentTerm(term))
      .map((term) => formatPaymentTerm(term, { short: true }))
      .join(' / ');
  }

  return 'All';
}

export function formatPaymentTermsValue(terms: unknown): string | undefined {
  return Array.isArray(terms)
    ? Array.from(new Set(terms)).join(',')
    : undefined;
}

export function parsePaymentTerms(terms: unknown) {
  const parsedTerms =
    typeof terms === 'string'
      ? terms.split(',').filter((value) => isValidPaymentTerm(value))
      : [];
  return paymentGroups.find(
    (groups) =>
      groups.length === parsedTerms.length &&
      !groups.some((group: PaymentTerm) => !parsedTerms.includes(group)),
  );
}

export const radiusOptions = new Map(
  [5, 10, 25, 50, 100, 250, 500].map((value) => [
    mileToKm(value),
    `${value} mi`,
  ]),
);

type PostingSearchCriteriaPaymentDTO = InferType<
  typeof postingSearchCriteriaPaymentSchema
>;
export const postingSearchCriteriaPaymentSchema = yupObject({
  terms: string()
    .transform((value) => formatPaymentTermsValue(parsePaymentTerms(value)))
    .optional(),
  method: string().optional(),
}).transform((values?: PostingSearchCriteriaPaymentDTO) =>
  !values ? { terms: undefined, method: undefined } : values,
);

type PostingSearchCriteriaShipperDTO = InferType<
  typeof postingSearchCriteriaShipperSchema
>;
export const postingSearchCriteriaShipperSchema = yupObject({
  guid: string().defined(),
}).transform((value?: PostingSearchCriteriaShipperDTO) =>
  !value ? { guid: undefined } : value,
);

function isPaymentEmpty({ terms, method }: PostingSearchCriteriaPaymentDTO) {
  return !terms && !method;
}

function isShipperEmpty({ guid }: PostingSearchCriteriaShipperDTO) {
  return !guid;
}

export type PostingSearchCriteriaDTO = InferType<
  typeof postingSearchCriteriaSchema
>;
export const postingSearchCriteriaSchema = yupObject({
  price: number().nullable().defined(),
  price_per_km: number().nullable().defined(),
  vehicle_count: number().nullable().defined(),
  vehicle_is_inoperable: boolean().nullable().defined(),
  transport_type: mixed<'OPEN' | 'ENCLOSED' | 'DRIVEAWAY'>()
    .optional()
    .defined(),
  pickup_radius: number().nullable().transform(transformNanToNull).defined(),
  delivery_radius: number().nullable().transform(transformNanToNull).defined(),
  latr_waypoints: yupArray(postingSearchCriteriaVenueSchema).defined(),
  pickup_venues: yupArray(postingSearchCriteriaVenueSchema).required(),
  delivery_venues: yupArray(postingSearchCriteriaVenueSchema).required(),
  vehicle_types: array(lazy(() => mixed())) // Yup ver(0.31.1) have problems with InferType (empty array), we should update yup, but it will be big migration all code, array(string().oneOf(VEHICLE_TYPES))
    .transform(transformStringToArray)
    .required()
    .ensure(),
  show_bookable: boolean().nullable().defined(),
  posted_to_private_loadboard: boolean().nullable().defined(),
  search_along_route: boolean().optional(),
  distance_off_route: number()
    .nullable()
    .transform(transformNanToNull)
    .defined(),
  ready_within_days: number().nullable().defined(),
  payment: postingSearchCriteriaPaymentSchema,
  shipper: postingSearchCriteriaShipperSchema,
});

type PostingSearchCriteriaPayload = InferType<
  typeof postingSearchCriteriaPayload
>;
export const postingSearchCriteriaPayload = postingSearchCriteriaSchema
  .shape({
    vehicle_types: string().optional(),
    pickup_venues: array().optional(),
    delivery_venues: array().optional(),
    payment: object().optional(),
    shipper: object().optional(),
  })
  .transform((values: PostingSearchCriteriaPayload) => {
    const { pickup_venues, delivery_venues, vehicle_types, payment, shipper } =
      values;
    values.pickup_venues = transformVenue(pickup_venues);
    values.delivery_venues = transformVenue(delivery_venues);
    values.vehicle_types = transformQueryArray(vehicle_types);
    values.payment = isPaymentEmpty(payment as PostingSearchCriteriaPaymentDTO)
      ? undefined
      : (payment as PostingSearchCriteriaPaymentDTO);
    values.shipper = isShipperEmpty(shipper as PostingSearchCriteriaShipperDTO)
      ? undefined
      : shipper;
    return values;
  });

export function createSearchCriteria(
  value?: string | Partial<PostingSearchCriteriaDTO>,
) {
  const raw = !value
    ? {}
    : typeof value !== 'string'
    ? deepClone(value)
    : tryParseJSON<Partial<PostingSearchCriteriaDTO>>(value);

  return postingSearchCriteriaSchema.cast(raw);
}

function canHaveSimilarSearchForVenues(
  venues: PostingSearchCriteriaVenueDTO[],
  radius?: number | null,
) {
  return (
    venues.length > 0 &&
    venues.some((x) => canHaveRadius(x)) &&
    (radius == null ? true : radius < mileToKm(250))
  );
}

export function canHaveSimilarSearch(
  criteria: PostingSearchCriteriaDTO,
): boolean {
  const { delivery_venues, delivery_radius, pickup_venues, pickup_radius } =
    criteria;
  return (
    canHaveSimilarSearchForVenues(delivery_venues, delivery_radius) ||
    canHaveSimilarSearchForVenues(pickup_venues, pickup_radius)
  );
}

export function createSimilarSearch(
  criteria: PostingSearchCriteriaDTO,
  includeLATR = false,
): PostingSearchCriteriaDTO {
  const newCriteria = createSearchCriteria(criteria);

  if (includeLATR && isValidLATRSearchCriteria(newCriteria)) {
    newCriteria.search_along_route = true;
  }

  if (
    canHaveSimilarSearchForVenues(
      newCriteria.pickup_venues,
      newCriteria.pickup_radius,
    )
  ) {
    newCriteria.pickup_radius = mileToKm(250);
  }

  if (
    canHaveSimilarSearchForVenues(
      newCriteria.delivery_venues,
      newCriteria.delivery_radius,
    )
  ) {
    newCriteria.delivery_radius = mileToKm(250);
  }

  return newCriteria;
}

export function searchCriteriaToJSON(
  value: PostingSearchCriteriaDTO | undefined,
): string | undefined {
  return tryStringifyJSON(postingSearchCriteriaSchema.cast(value || {}));
}

export function isEmptySearchCriteria(raw?: PostingSearchCriteriaDTO): boolean {
  const {
    payment,
    shipper,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    show_bookable,
    ...rest
  } = postingSearchCriteriaSchema.cast(raw || {});
  const omitted = omitBy(rest, (item) =>
    Array.isArray(item) ? isEmpty(item) : item == null,
  );

  return (
    Object.keys(omitted).length === 0 &&
    !show_bookable &&
    isPaymentEmpty(payment) &&
    isShipperEmpty(shipper)
  );
}

export function getActiveSearchCount({
  shipper,
  payment,
  delivery_radius,
  pickup_radius,
  show_bookable,
  search_along_route,
  ...rest
}: PostingSearchCriteriaDTO): number {
  let count = Object.values(rest).filter((value) =>
    Array.isArray(value) ? !isEmpty(value) : value != null,
  ).length;

  if (!isPaymentEmpty(payment)) {
    count++;
  }

  if (show_bookable) {
    count++;
  }

  if (search_along_route) {
    count++;
  }

  return count;
}

function formatSavedSearchTitleFull({
  price,
  payment,
  ready_within_days,
  vehicle_types = [],
  pickup_venues = [],
  transport_type,
  delivery_venues = [],
  price_per_km: pricePerKm,
  vehicle_count: vehicleCount,
  vehicle_is_inoperable: vehicleIsInoperable,
}: PostingSearchCriteriaDTO) {
  return joinStrings(
    ' - ',
    ...pickup_venues.map(formatPostingAddress),
    ...delivery_venues.map(formatPostingAddress),
    typeof vehicleIsInoperable === 'boolean' &&
      (vehicleIsInoperable ? 'Inoperable' : 'Operable'),
    transport_type && startCase(transport_type.toLowerCase()),
    !!vehicle_types.length &&
      vehicle_types.map((type) => formatVehicleType(type)).join(', '),
    payment.terms && formatPaymentTermsLabel(parsePaymentTerms(payment.terms)),
    pricePerKm && `Min $${convertPricePerKmToPricePerMile(pricePerKm, 2)}/mi`,
    typeof price === 'number' && `Min. Price: $${price}`,
    typeof vehicleCount === 'number' && `Min. Vehicles: ${vehicleCount}`,
    ready_within_days != null &&
      (ready_within_days === 0
        ? `Ready Today`
        : ready_within_days === 1
        ? `Ready Tomorrow`
        : `Ready in ${ready_within_days} days`),
  );
}

export function composeSavedSearchTitle(
  searchCriteria: PostingSearchCriteriaDTO,
): string {
  const {
    price,
    payment,
    vehicle_types,
    pickup_venues = [],
    transport_type,
    delivery_venues = [],
    price_per_km: pricePerKm,
    vehicle_count: vehicleCount,
    vehicle_is_inoperable: isVehicleIsInoperable,
  } = searchCriteria;

  if (pickup_venues.length > 1 || delivery_venues.length > 1) {
    return joinStrings(
      ', ',
      !!pickup_venues.length && `Origin ${pickup_venues.length}`,
      !!delivery_venues.length && `Destination ${delivery_venues.length}`,
      `Filters ${
        Object.keys(
          omitBy(
            {
              price,
              payment,
              pricePerKm,
              vehicleCount,
              vehicle_types,
              transport_type,
              isVehicleIsInoperable,
            },
            (item) => (Array.isArray(item) ? isEmpty(item) : !item),
          ),
        ).length
      }`,
    );
  }

  return formatSavedSearchTitleFull(searchCriteria);
}
