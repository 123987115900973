import { useEffect } from 'react';
import { usePageTrack } from 'shared/helpers/Analytics';
import { trackTrackingEvent } from './data/TrackingAnalytics';
import { TrackingPage } from './TrackingPage';

export function TrackingContainerPage() {
  usePageTrack('GPS Tracking');

  useEffect(() => {
    trackTrackingEvent({
      name: 'CTMS: Opened React GPS Tracking Page',
    });
  }, []);

  return <TrackingPage />;
}
