import { trackEvent } from 'shared/helpers/Analytics';
import { parseSearchQuery } from 'shared/utils/URLUtils';

const source = new Set([
  'Order View',
  'Shipper Profile',
  'Delivery Update',
  'Positive Rating Given',
  'Ratings To Give',
]);

export function trackRatingSubmittedAnalytics(
  name: 'Carrier Submitted Rating',
  data?: Record<string, unknown>,
) {
  const { utm_medium = 'Shipper Profile' } = parseSearchQuery(
    window.location.search,
  );

  trackEvent(name, {
    utm_medium,
    submitted_from: source.has(utm_medium) ? utm_medium : 'Shipper Profile',
    ...data,
  });
}

export type RatingAnalyticsEvent = 'Carrier Clicked How To Dispute Link';

export function trackRatingAnalytics(name: RatingAnalyticsEvent) {
  trackEvent(name);
}
