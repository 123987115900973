import { createSvgIcon } from 'shared/icons/IconUtils';

export const TrailerIcon = createSvgIcon(
  'Trailer',
  <g fill="currentColor" fillRule="evenodd">
    <path d="M6 6a1 1 0 1 1 0-2 1 1 0 0 1 0 2m0-3.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5" />
    <path d="M9.352 4H16V0H0v4h2.65a3.5 3.5 0 0 1 6.705 0h-.003z" />
  </g>,
  {
    // TODO: Use 24x24 view-box.
    viewBox: '0 0 16 6',
  },
);
