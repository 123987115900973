import { createSvgIcon } from 'shared/icons/IconUtils';

export const DealerIcon = createSvgIcon(
  'BusinessTypeDealer',
  <path
    fill="currentColor"
    d="M6 10H2V7.333h4m6 0V6l-.667-3.333H.667L0 6v1.333h.667v4h6.666v-4H10v4h1.333v-4m0-6.666H.667V2h10.666V.667Z"
  />,
  { viewBox: '0 -1 15 15' },
);
