import { Tooltip, Typography } from '@material-ui/core';
import { formatVehicleType } from '@superdispatch/sdk';
import { Inline, Stack, useResponsiveValue } from '@superdispatch/ui';
import { Box, TextBox } from '@superdispatch/ui-lab';
import { useMemo } from 'react';
import { formatNumber } from 'shared/helpers/IntlHelpers';
import {
  formatVehicleDimensions,
  hasVehicleDimension,
} from 'shared/helpers/VehicleHelpers';
import { InlineBulletItems } from 'shared/ui/InlineBulletItems';
import { joinStrings } from 'shared/utils/StringUtils';
import { LoadSuggestionDTO } from '../data/LoadSuggestionDTO';
import { LoadSuggestionsVehicleConditionIcon } from './LoadSuggestionsVehicleContitionIcon';

export function LoadSuggestionsVehicles({
  vehicles,
  transportType,
}: {
  vehicles: LoadSuggestionDTO['vehicles'];
  transportType: LoadSuggestionDTO['transport_type'];
}) {
  const isMobile = useResponsiveValue(true, false);
  const [totalCurbWeight, vehiclesToRender, hasHiddenInoperableVehicles] =
    useMemo(() => {
      const nextTotalCurbWeight = vehicles.reduce(
        (total, { curb_weight }) => total + (Number(curb_weight) || 0),
        0,
      );
      const toRender = vehicles.slice(0, 2);

      return [
        nextTotalCurbWeight,
        toRender,
        vehicles.slice(2).some((v) => v.is_inoperable) &&
          toRender.every((v) => !v.is_inoperable),
      ];
    }, [vehicles]);

  const weightText = `~ ${formatNumber(totalCurbWeight)} ${
    vehicles[0]?.curb_weight_unit || 'lbs'
  }`;

  return (
    <Stack space={isMobile ? 'xxsmall' : 'xsmall'}>
      {vehiclesToRender.map((vehicle) => (
        <Inline space="xxsmall" verticalAlign="center" key={vehicle.guid}>
          <Tooltip
            title={
              !hasVehicleDimension(vehicle) ? (
                <Box color="Dark100">Dimensions not available</Box>
              ) : (
                <TextBox color="white">
                  {formatVehicleDimensions(vehicle)}
                </TextBox>
              )
            }
            placement="right"
          >
            <TextBox variant="body-semibold">
              <Inline space="xxsmall">
                {joinStrings(' ', vehicle.year, vehicle.make, vehicle.model)}
                <TextBox color="secondary">
                  {formatVehicleType(vehicle.type)}
                </TextBox>
              </Inline>
            </TextBox>
          </Tooltip>

          <LoadSuggestionsVehicleConditionIcon
            isInoperable={vehicle.is_inoperable}
          />
        </Inline>
      ))}
      <InlineBulletItems verticalAlign="center" space="xsmall">
        <Inline verticalAlign="center">
          {vehicles.length > 2 && `+ ${vehicles.length - 2} more`}
          <LoadSuggestionsVehicleConditionIcon
            transportType={transportType}
            isInoperable={hasHiddenInoperableVehicles}
          />
        </Inline>
        {!!totalCurbWeight && (
          <Typography color="textSecondary">total {weightText}</Typography>
        )}
      </InlineBulletItems>
    </Stack>
  );
}
