import { StandardTextFieldProps, TextField } from '@material-ui/core';
import { useUID } from '@superdispatch/ui';
import { useField, useFormikContext } from 'formik';
import { useMemo } from 'react';

function normalizeEmails(emails: string[]) {
  return emails.map((e) => e.trim()).filter((i) => i !== '');
}

interface FormikSettingsEmailsFieldProps
  extends Pick<StandardTextFieldProps, 'fullWidth' | 'label' | 'helperText'> {
  name: string;
}

export function FormikSettingsEmailsField({
  name,
  helperText,
  ...props
}: FormikSettingsEmailsFieldProps) {
  const uid = useUID();
  const { isSubmitting } = useFormikContext();
  const [field, { error, touched }, { setValue, setTouched }] = useField<
    string[]
  >({ name });
  const errorText = useMemo(() => {
    if (!error || !touched) return null;
    if (Array.isArray(error)) {
      return (error as Array<string | undefined>).find(
        (message) => typeof message == 'string' && message,
      );
    }

    return error;
  }, [touched, error]);

  return (
    <TextField
      {...props}
      {...field}
      id={uid}
      name={name}
      error={!!errorText}
      disabled={isSubmitting}
      helperText={errorText || helperText}
      value={field.value.join(',')}
      onChange={(event) => {
        void setValue(event.target.value.split(','), false);
      }}
      onBlur={(event) => {
        void setTouched(true, false);
        void setValue(normalizeEmails(event.target.value.split(',')), true);
      }}
    />
  );
}
