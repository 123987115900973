import {
  FormattedDate,
  parseDate,
  toPrimitiveDateInput,
} from '@superdispatch/dates';
import { TextBox } from '@superdispatch/ui-lab';
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { SuggestedLoadDTO } from './data/SuggestedLoadDTO';

export function SuggestedLoadAvailableDate({
  load: {
    pickup: { scheduled_at: dateProp },
  },
}: {
  load: SuggestedLoadDTO;
}) {
  const primitiveInput = toPrimitiveDateInput(dateProp);
  const date = useMemo(() => parseDate(primitiveInput), [primitiveInput]);
  const endOfDay = useMemo(() => DateTime.local().endOf('day'), []);

  if (!date.isValid) {
    return <TextBox>No Date</TextBox>;
  }

  if (date <= endOfDay) {
    return <TextBox>Now</TextBox>;
  }

  return <FormattedDate date={date} variant="Date" />;
}
