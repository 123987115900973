import {
  IconButton,
  IconButtonProps,
  Link,
  LinkProps,
} from '@material-ui/core';
import { AnchorButton, AnchorButtonProps } from '@superdispatch/ui-lab';
import { createLocation, LocationDescriptorObject } from 'history';
import React, { forwardRef, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

interface BaseRouterLinkProps extends Pick<AnchorButtonProps, 'target'> {
  replace?: boolean;
  to: string | LocationDescriptorObject;
}

export interface RouterLinkProps
  extends BaseRouterLinkProps,
    Omit<AnchorButtonProps, 'href'> {}

function useAnchorProps({
  to,
  target,
  replace,
  onClick,
}: RouterLinkProps): Pick<AnchorButtonProps, 'href' | 'target' | 'onClick'> {
  const history = useHistory();
  const toLocation = useMemo(
    () => (typeof to === 'string' ? createLocation(to) : to),
    [to],
  );
  const href = useMemo(
    () => history.createHref(toLocation),
    [history, toLocation],
  );

  /**
   * Implementation from react-router-dom/Link#onClick
   */
  function handleClick(event: React.MouseEvent<HTMLAnchorElement>): void {
    if (onClick) {
      try {
        onClick(event);
      } catch (error: unknown) {
        event.preventDefault();
        throw error;
      }
    }

    if (
      // onClick prevented default
      !event.defaultPrevented &&
      // ignore everything but left clicks
      event.button === 0 &&
      // let browser handle "target=_blank" etc.
      (!target || target === '_self') &&
      // ignore clicks with modifier keys
      !event.metaKey &&
      !event.altKey &&
      !event.ctrlKey &&
      !event.shiftKey
    ) {
      event.preventDefault();

      if (replace) {
        history.replace(toLocation);
      } else {
        history.push(toLocation);
      }
    }
  }

  return {
    href,
    target,
    onClick: handleClick,
  };
}

export interface LinkAnchorProps
  extends BaseRouterLinkProps,
    Omit<LinkProps, 'href' | 'target'> {}

export const LinkAnchor = forwardRef<HTMLAnchorElement, LinkAnchorProps>(
  ({ to, onClick, target, replace, ...props }, ref) => {
    const linkProps = useAnchorProps({ to, onClick, target, replace });

    return <Link {...props} {...linkProps} ref={ref} component="a" />;
  },
);

LinkAnchor.displayName = 'LinkAnchor';

export interface LinkButtonProps
  extends BaseRouterLinkProps,
    Omit<AnchorButtonProps, 'href'> {}

export const LinkButton = forwardRef<HTMLAnchorElement, LinkButtonProps>(
  ({ to, onClick, target, replace, ...props }, ref) => {
    const linkProps = useAnchorProps({ to, onClick, target, replace });

    return <AnchorButton {...props} {...linkProps} ref={ref} />;
  },
);

LinkButton.displayName = 'LinkButton';

export interface LinkIconButtonProps
  extends BaseRouterLinkProps,
    Omit<IconButtonProps<'a'>, 'href' | 'target'> {}

export const LinkIconButton = forwardRef<
  HTMLAnchorElement,
  LinkIconButtonProps
>(({ to, onClick, target, replace, ...props }, ref) => {
  const linkProps = useAnchorProps({ to, onClick, target, replace });
  return <IconButton {...props} {...linkProps} ref={ref} />;
});

LinkIconButton.displayName = 'LinkIconButton';
