import { camelCase, upperFirst } from 'lodash-es';
import { trim } from 'shared/helpers/CommonHelpers';

export function getInitials(
  name: string,
  { delimiter = ' ', maxLength = 2 } = {},
) {
  return trim(name)
    .split(delimiter)
    .slice(0, maxLength)
    .map((x) => x.charAt(0))
    .join('')
    .toUpperCase();
}

export function toSearchQueryText(value: unknown): string | undefined {
  return trim(value).toLowerCase() || undefined;
}

export function pascalCase(text: string): string {
  return upperFirst(camelCase(text));
}
