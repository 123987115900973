import { Typography } from '@material-ui/core';
import { Business, Phone } from '@material-ui/icons';
import { Stack } from '@superdispatch/ui';
import { Alert, DescriptionItem } from '@superdispatch/ui-lab';
import { MobileAppBridge } from 'shared/data/MobileAppBridge';
import { PoundIcon } from 'shared/icons';
import { useCarrierInfo } from 'shared/modules/carrier-profile/CarrierInfo';
import { useVerifiedCarrierApplicationStatus } from 'shared/modules/carrier-profile/VerifiedCarrierApplicationStatus';
import { LinkButton } from 'shared/routing/Links';
import { useCarrierSettings } from 'shared/settings/CarrierSettingsAPI';
import { trackPhoneVerificationEvent } from '../data/PhoneVerificationAnalytics';

export function ProfileCarrierInfoDetails() {
  const { data: carrierInfo } = useCarrierInfo();
  const { data: verificationStatus } = useVerifiedCarrierApplicationStatus();
  const { data: settings } = useCarrierSettings();
  const isVerificationDisabled =
    !settings?.drivers_can_edit_carrier_info && MobileAppBridge.isInjected();

  const hasFmcsaPhoneRevokingReasons =
    !!verificationStatus?.verified_carrier_application.revoking_reasons
      ?.fmcsa_phone?.length;

  return (
    <Stack space="xsmall">
      <Typography variant="h4">Safer FMCSA Record</Typography>
      <DescriptionItem icon={<Business />}>{carrierInfo?.name}</DescriptionItem>
      <DescriptionItem icon={<PoundIcon />}>
        {carrierInfo?.usdot_number}{' '}
        <Typography variant="caption" color="textSecondary">
          · USDOT
        </Typography>
      </DescriptionItem>
      {carrierInfo?.is_fmcsa_phone_verified && !hasFmcsaPhoneRevokingReasons ? (
        <DescriptionItem icon={<Phone />}>
          {carrierInfo.fmcsa_phone}
        </DescriptionItem>
      ) : (
        <Alert severity={hasFmcsaPhoneRevokingReasons ? 'critical' : 'caution'}>
          <Stack space="xsmall">
            Verify your phone number and help us keep our platform secure and
            your identity safe.
            <LinkButton
              variant="neutral"
              size="small"
              to="/profile/phone-verification"
              onClick={() => {
                trackPhoneVerificationEvent({
                  name: 'Carrier Clicked Verify Phone Number Now',
                  carrier_verification_status:
                    verificationStatus?.verified_carrier_application.status,
                });
              }}
              disabled={isVerificationDisabled}
            >
              Verify now
            </LinkButton>
          </Stack>
        </Alert>
      )}
    </Stack>
  );
}
