import { Color } from '@superdispatch/ui';
import { createSvgIcon } from './IconUtils';

export const WeakPasswordIcon = createSvgIcon(
  'WeakPassword',
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M12 21.975C9.66667 21.3916 7.75 20.0375 6.25 17.9125C4.75 15.7875 4 13.4583 4 10.925V4.97498L12 1.97498L20 4.97498V10.925C20 13.4583 19.25 15.7875 17.75 17.9125C16.25 20.0375 14.3333 21.3916 12 21.975ZM11 12.7612V6.76123H13V12.7612H11ZM11 14.7612V16.7612H13V14.7612H11Z"
    fill={Color.Blue500}
  />,
);
