import { CircularProgress, Typography } from '@material-ui/core';
import { Stack, useSnackbarStack } from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import { useEffect, useState } from 'react';
import { MutationDialog } from 'shared/form/MutationDialog';
import { useLocationParams } from 'shared/routing/LocationParams';
import { ConfirmDialogContent } from 'shared/ui/ConfirmDialog';
import { CarrierRatingsList } from './CarrierRatingsList';
import { RatingsSummary } from './CarrierRatingsSummary';
import { CarrierReviewResponseDialog } from './CarrierReviewResponseDialog';
import {
  useCarrierRatings,
  useDeleteRatingResponse,
} from './data/CarrierRatingsAPI';
import {
  CarrierRatingDTO,
  carrierRatingsPageParamsSchema,
  CarrierRatingsSummaryDTO,
  CARRIER_RATINGS_PAGE_SIZE,
} from './data/CarrierRatingsDTO';
import { EmptyRatings } from './EmptyRatings';
import { RatingsPagination } from './RatingsPagination';

interface Props {
  carrierRatingsSummary?: CarrierRatingsSummaryDTO;
}

export function CarrierReceivedRatings({ carrierRatingsSummary }: Props) {
  const { addSnackbar } = useSnackbarStack();

  const [params, updateParams] = useLocationParams({
    yupSchema: carrierRatingsPageParamsSchema,
  });
  const { data: carrierRatings, error } = useCarrierRatings(params);
  const maxPage = carrierRatings?.pagination.count
    ? Math.ceil(carrierRatings.pagination.count / CARRIER_RATINGS_PAGE_SIZE)
    : 0;
  const [currentResponseRating, setCurrentResponseRating] =
    useState<CarrierRatingDTO>();
  const [currentDeleteResponseRating, setCurrentDeleteResponseRating] =
    useState<CarrierRatingDTO>();

  const onCloseResponseDialog = () => {
    setCurrentResponseRating(undefined);
  };

  const onCloseDeleteResponseDialog = () => {
    setCurrentDeleteResponseRating(undefined);
  };

  const deleteRatingResponse = useDeleteRatingResponse({
    onSuccess: () => {
      addSnackbar('Response deleted');
      onCloseDeleteResponseDialog();
    },
    onError: ({ message }) => {
      addSnackbar(message || 'Failed to delete response', { variant: 'error' });
    },
  });

  useEffect(() => {
    if (error) {
      addSnackbar('Failed to fetch carrier ratings', { variant: 'error' });
    }
  }, [addSnackbar, error]);

  return (
    <>
      <CarrierReviewResponseDialog
        rating={currentResponseRating}
        onClose={onCloseResponseDialog}
      />

      <MutationDialog
        open={!!currentDeleteResponseRating?.guid}
        onClose={onCloseDeleteResponseDialog}
        maxWidth="xs"
      >
        <ConfirmDialogContent
          onClose={onCloseDeleteResponseDialog}
          title="Delete response?"
          cancelButtonProps={{ disabled: deleteRatingResponse.isLoading }}
          confirmButtonProps={{
            children: 'Delete',
            pending: deleteRatingResponse.isLoading,
            disabled: !currentDeleteResponseRating?.guid,
            onClick() {
              if (currentDeleteResponseRating?.guid) {
                deleteRatingResponse.mutate(currentDeleteResponseRating.guid);
              }
            },
          }}
        >
          <Box width="312px" />
        </ConfirmDialogContent>
      </MutationDialog>

      <Stack space="small" align="center">
        {carrierRatingsSummary && carrierRatings ? (
          carrierRatingsSummary.rating.count ? (
            <>
              <RatingsSummary summary={carrierRatingsSummary.summary} />

              <Box width="100%" paddingTop="xsmall">
                <Stack space="large" align="center">
                  <CarrierRatingsList
                    ratings={carrierRatings.data}
                    onClickAddResponse={setCurrentResponseRating}
                    onClickDeleteResponse={setCurrentDeleteResponseRating}
                  />

                  {maxPage > 1 && (
                    <RatingsPagination
                      page={params.ratings_page}
                      count={maxPage}
                      onChange={(_, pageNumber) => {
                        updateParams({ ratings_page: pageNumber });
                      }}
                    />
                  )}
                </Stack>
              </Box>
            </>
          ) : (
            <EmptyRatings
              message={
                <Typography color="textSecondary" align="center">
                  Ask your Shippers to give <br />
                  you a positive rating!
                </Typography>
              }
            />
          )
        ) : (
          <Box paddingTop="xxlarge" height="172px">
            <CircularProgress />
          </Box>
        )}
      </Stack>
    </>
  );
}
