import { FormControlLabel, Radio, Typography } from '@material-ui/core';
import { RadioGroupField, Stack } from '@superdispatch/ui';
import { Box, Button } from '@superdispatch/ui-lab';
import { useState } from 'react';
import { showNewIntercomMessage } from 'shared/helpers/Intercom';
import { useNavigate } from 'shared/routing/react-router-6';
import { useDriverAccountDeletionContext } from '../DriverAccountDeletionPage';

export function QuestionnaireStepPage() {
  const [reason, setReason] = useState<'transition' | 'quit'>();
  const navigate = useNavigate();
  const { token } = useDriverAccountDeletionContext();

  return (
    <Box padding="medium">
      <Stack space="medium">
        <Typography variant="h2">We&rsquo;re sorry to see you go!</Typography>
        <Typography>What&apos;s your reason for leaving?</Typography>
        <RadioGroupField name="reason" fullWidth={true}>
          <Stack space="medium">
            <FormControlLabel
              value="transition"
              control={<Radio />}
              checked={reason === 'transition'}
              onChange={() => {
                setReason('transition');
              }}
              label={
                <Stack space="none">
                  <Typography>
                    I&apos;m transitioning to a different carrier
                  </Typography>
                  <Typography variant="caption" color="textSecondary">
                    You will be directed to our support team who will help you
                    with a transition.
                  </Typography>
                </Stack>
              }
            />
            <FormControlLabel
              value="quit"
              control={<Radio />}
              checked={reason === 'quit'}
              onChange={() => {
                setReason('quit');
              }}
              label={<Typography>I don&apos;t use the App anymore</Typography>}
            />
          </Stack>
        </RadioGroupField>

        <Button
          fullWidth={true}
          disabled={!reason}
          onClick={() => {
            if (reason === 'quit') {
              navigate(`/driver-account-deletion/${token}/confirmation/`);
            } else {
              showNewIntercomMessage(
                "I'm transitioning to a different carrier",
              );
            }
          }}
        >
          Continue
        </Button>
      </Stack>
    </Box>
  );
}
