import { MenuItem, Typography } from '@material-ui/core';
import { FormikTextField } from '@superdispatch/forms';
import { Column, Columns, Stack } from '@superdispatch/ui';
import { TextBox } from '@superdispatch/ui-lab';
import { useField } from 'formik';
import React from 'react';
import { CanadaProvinceOptions } from 'shared/form/CanadaProvince';
import { FormikCountryField } from 'shared/form/FormikCountryField';
import { USAStateOptions } from 'shared/form/USAState';

interface ProfileAddressFormProps {
  label?: React.ReactNode;
  isFormDisabled?: boolean;
}

export function ProfileAddressForm({
  label = <Typography variant="h4">Address</Typography>,
  isFormDisabled = false,
}: ProfileAddressFormProps) {
  const [field] = useField('country');
  const isUSASelected = field.value === 'US';
  const isCanadaSelected = field.value === 'CA';

  return (
    <Stack space="small">
      {label}

      <FormikTextField
        label="Address"
        name="address"
        fullWidth={true}
        disabled={isFormDisabled}
      />

      <Columns space="small" collapseBelow="tablet">
        <Column width={['fluid', '2/5']}>
          <FormikTextField
            label="City"
            name="city"
            fullWidth={true}
            disabled={isFormDisabled}
          />
        </Column>

        <Column width={['fluid', '2/5']}>
          <FormikTextField
            label={
              isCanadaSelected ? (
                'Province'
              ) : isUSASelected ? (
                'State'
              ) : (
                <>
                  State <TextBox color="secondary">(Optional)</TextBox>
                </>
              )
            }
            name="state"
            fullWidth={true}
            select={isUSASelected || isCanadaSelected}
            disabled={isFormDisabled}
          >
            {isUSASelected &&
              Array.from(USAStateOptions, (state) => (
                <MenuItem key={state} value={state}>
                  {state}
                </MenuItem>
              ))}
            {isCanadaSelected &&
              Array.from(CanadaProvinceOptions, (province) => (
                <MenuItem key={province} value={province}>
                  {province}
                </MenuItem>
              ))}
          </FormikTextField>
        </Column>

        <Column width={['fluid', '1/5']}>
          <FormikTextField
            label={isCanadaSelected ? 'Postal Code' : 'Zip'}
            name="zip"
            fullWidth={true}
            disabled={isFormDisabled}
          />
        </Column>
      </Columns>

      <FormikCountryField
        name="country"
        fullWidth={true}
        TextFieldProps={{
          fullWidth: true,
          label: 'Country',
        }}
        disabled={isFormDisabled}
      />
    </Stack>
  );
}
