import { Column, Columns, Inline, Stack } from '@superdispatch/ui';
import { Box, TextBox } from '@superdispatch/ui-lab';
import { AmericanExpressIcon } from 'shared/icons/AmericanExpressIcon';
import { VisaCardIcon } from 'shared/icons/VisaCardIcon';

export interface CVVTitleProps {
  isEmpty?: boolean;
  cardType?: 'amex' | 'visa' | 'mastercard';
}

export function CVVTitle({ isEmpty, cardType }: CVVTitleProps) {
  if (isEmpty) {
    return (
      <Box maxWidth="250px">
        <Stack space="small">
          <Columns>
            <Column width="1/2">
              <Inline horizontalAlign="left">
                <VisaCardIcon />
              </Inline>
            </Column>
            <Column width="1/2">
              <Inline horizontalAlign="right">
                <AmericanExpressIcon />
              </Inline>
            </Column>
          </Columns>
          <TextBox color="white">
            For Visa, MasterCard and Discover the 3 digits on the back of the
            card.
          </TextBox>
          <TextBox color="white">
            For American Express, the 4 digits on the front of the card.
          </TextBox>
        </Stack>
      </Box>
    );
  }

  if (cardType === 'amex') {
    return (
      <Stack space="small">
        <Inline horizontalAlign="center">
          <AmericanExpressIcon width="100%" />
        </Inline>

        <TextBox color="white">4 digits on the front of the card.</TextBox>
      </Stack>
    );
  }

  return (
    <Stack space="small">
      <Inline horizontalAlign="center">
        <VisaCardIcon width="100%" />
      </Inline>
      <TextBox color="white">3 digits on the back of the card.</TextBox>
    </Stack>
  );
}
