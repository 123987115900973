import {
  Card,
  CardContent,
  CircularProgress,
  Divider,
  Link,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { Mail, Phone } from '@material-ui/icons';
import { FormikCheckboxField } from '@superdispatch/forms';
import { Column, Columns, Stack, useSnackbarStack } from '@superdispatch/ui';
import { DescriptionItem } from '@superdispatch/ui-lab';
import { MobileAppBridge } from 'shared/data/MobileAppBridge';
import { LinkAnchor } from 'shared/routing/Links';
import { useResubscribeLoadOfferEmailsMutation } from './data/SettingsAPI';
import { LoadOfferNotificationSettingsDTO } from './data/SettingsDTO';

export function SettingsSMSAndEmailNotifications({
  loadOfferNotification,
}: {
  loadOfferNotification?: LoadOfferNotificationSettingsDTO;
}) {
  return (
    <Card aria-label="Load Offer Settings Card">
      <CardContent>
        <Stack space="xsmall">
          <Typography variant="h3">SMS and Email Notifications</Typography>
          <Stack space="medium">
            <SettingsSMSAndEmailNotificationsCarrier
              loadOfferNotification={loadOfferNotification}
            />
            <Divider />
            <SettingsSMSAndEmailNotificationsDispatcher
              loadOfferNotification={loadOfferNotification}
            />
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
}

export function SettingsSMSAndEmailNotificationsCarrier({
  loadOfferNotification,
}: {
  loadOfferNotification?: LoadOfferNotificationSettingsDTO;
}) {
  const { addSnackbar } = useSnackbarStack();
  const { mutate: resubscribeEmails, isLoading: isResubscribing } =
    useResubscribeLoadOfferEmailsMutation({
      onSuccess: () => {
        addSnackbar('Resubscribed to Load Offers Emails.');
      },
      onError: (error) => {
        addSnackbar(error.message, { variant: 'error' });
      },
    });

  const phoneNumbers =
    loadOfferNotification?.load_offer_phone_numbers?.join(', ');
  const isPhoneNumbersValid =
    !!loadOfferNotification?.is_valid_load_offer_phone_numbers;

  return (
    <Stack space="large" aria-label="Load Offer Settings Carrier Card">
      <Stack space="small">
        <Stack space="xsmall">
          <Typography variant="h4">Carrier</Typography>
          <Typography color="textSecondary">Updates send to:</Typography>

          <Columns collapseBelow="tablet" space="small">
            <Column width="adaptive">
              <DescriptionItem
                icon={<Mail />}
                fallback="No Email"
                aria-label="load offer email"
              >
                {loadOfferNotification?.load_offer_email}
              </DescriptionItem>
            </Column>

            <Column width="adaptive">
              <Stack space="none">
                <DescriptionItem
                  icon={<Phone />}
                  fallback="No Phone"
                  aria-label="load offer phones"
                >
                  {phoneNumbers}
                </DescriptionItem>

                {phoneNumbers && !isPhoneNumbersValid && (
                  <Typography color="error">Enter valid number</Typography>
                )}
              </Stack>
            </Column>
          </Columns>
        </Stack>

        <Typography color="textSecondary">
          Update at{' '}
          {MobileAppBridge.isInjected() ? (
            <Link
              color="primary"
              onClick={() => {
                MobileAppBridge.openCarrierInformation();
              }}
            >
              Carrier Info
            </Link>
          ) : (
            <LinkAnchor color="primary" to="/profile/contact_info">
              Carrier profile
            </LinkAnchor>
          )}
        </Typography>
      </Stack>

      <Stack space="xsmall">
        <Columns space="small" collapseBelow="tablet" align={['top', 'center']}>
          <Column>
            <Stack space="none">
              <Typography variant="h4">Load Offers</Typography>
              <Typography color="textSecondary">
                Notifications about Load Offers from shippers
              </Typography>
            </Stack>
          </Column>

          <Column width="content">
            <Columns collapseBelow="tablet">
              <Column width="content">
                <FormikCheckboxField
                  label="SMS"
                  name="is_load_offer_sms_notifications_enabled"
                  disabled={
                    !isPhoneNumbersValid ||
                    !!loadOfferNotification.is_unsubscribed_from_load_offer_sms
                  }
                />
              </Column>

              <Column width="content">
                <FormikCheckboxField
                  label="Email"
                  name="is_load_offer_email_notifications_enabled"
                  disabled={
                    !!loadOfferNotification?.is_unsubscribed_from_load_offer_emails
                  }
                />
              </Column>
            </Columns>
          </Column>
        </Columns>

        {loadOfferNotification?.is_unsubscribed_from_load_offer_sms && (
          <Typography>
            You Blacklisted our number{' '}
            <Tooltip
              placement="right"
              title={`Reply START to +1 (844) 989-1444 from ${phoneNumbers} or to last Load Offer SMS`}
            >
              <Link href="#">How to Remove from Blacklist</Link>
            </Tooltip>
          </Typography>
        )}

        {loadOfferNotification?.is_unsubscribed_from_load_offer_emails && (
          <Typography>
            You unsubscribed from our mailing list{' '}
            <Link
              href="#"
              onClick={() => {
                if (!isResubscribing) {
                  resubscribeEmails(undefined);
                }
              }}
            >
              Resubscribe {isResubscribing && <CircularProgress size="1em" />}
            </Link>
          </Typography>
        )}
      </Stack>
    </Stack>
  );
}

function SettingsSMSAndEmailNotificationsDispatcher({
  loadOfferNotification,
}: {
  loadOfferNotification?: LoadOfferNotificationSettingsDTO;
}) {
  return (
    <Stack space="large" aria-label="Load Offer Settings Dispatcher Card">
      <Stack space="small">
        <Stack space="xsmall">
          <Typography variant="h4">Dispatcher</Typography>
          <Typography color="textSecondary">Updates send to:</Typography>

          <DescriptionItem
            icon={<Mail />}
            fallback="No Email"
            aria-label="dispatcher email"
          >
            {loadOfferNotification?.user_email}
          </DescriptionItem>
        </Stack>

        <Typography color="textSecondary">
          Update at{' '}
          <LinkAnchor color="primary" to="/dispatchers/profile">
            Dispatcher Profile
          </LinkAnchor>
        </Typography>
      </Stack>

      <Stack space="xsmall">
        <Columns space="small" collapseBelow="tablet" align={['top', 'center']}>
          <Column>
            <Stack space="none">
              <Typography variant="h4">SuperPay Payment on Hold</Typography>
              <Typography color="textSecondary">
                Notifications about Payment on Hold
              </Typography>
            </Stack>
          </Column>

          <Column width="content">
            <FormikCheckboxField
              label="Email"
              name="is_superpay_payment_onhold_email_notifications_enabled"
            />
          </Column>
        </Columns>
      </Stack>
    </Stack>
  );
}
