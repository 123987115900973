import { FormsProvider } from '@superdispatch/forms';
import { dequal } from 'dequal';
import { noop } from 'lodash-es';
import { ReactNode, Suspense } from 'react';
import { QueryClient, QueryClientProvider, setLogger } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { AppRouterProvider } from 'shared/app/AppRouter';
import { TEN_MINUTES, TEN_SECONDS } from 'shared/constants/NumberConstants';
import { AppErrorBoundary } from 'shared/errors/AppErrorBoundary';
import { defaultFormErrorsGetter } from 'shared/helpers/FormHelpers';
import { AppLayoutManagerProvider } from 'shared/layout/AppLayoutManager';
import { SplashScreen } from 'shared/layout/SplashScreen';
import { AppThemeProvider } from 'shared/theme/AppThemeProvider';
import { AppVersionChecker } from './AppVersionChecker';

setLogger({ log: noop, warn: noop, error: noop });

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      isDataEqual: dequal,
      staleTime: TEN_SECONDS,
      cacheTime: TEN_MINUTES,
    },
  },
});

interface RootContextProviderProps {
  children?: ReactNode;
}

export function AppContainer({ children }: RootContextProviderProps) {
  return (
    <Suspense fallback={<SplashScreen />}>
      <AppErrorBoundary>
        <AppRouterProvider>
          <AppThemeProvider>
            <FormsProvider getFormErrors={defaultFormErrorsGetter}>
              <AppLayoutManagerProvider>
                <QueryClientProvider client={queryClient}>
                  {!('Cypress' in window) &&
                    import.meta.env.MODE === 'development' && (
                      <ReactQueryDevtools
                        initialIsOpen={false}
                        position="bottom-right"
                      />
                    )}

                  {import.meta.env.MODE !== 'development' && (
                    <AppVersionChecker />
                  )}

                  {children}
                </QueryClientProvider>
              </AppLayoutManagerProvider>
            </FormsProvider>
          </AppThemeProvider>
        </AppRouterProvider>
      </AppErrorBoundary>
    </Suspense>
  );
}
