import {
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Link,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { ExpandLess, ExpandMore, Info, Lock } from '@material-ui/icons';
import { useFormikEnhanced } from '@superdispatch/forms';
import { Inline, Snackbar, Stack, useSnackbarStack } from '@superdispatch/ui';
import { AnchorButton, Button, TextBox } from '@superdispatch/ui-lab';
import { parseURITemplate } from '@superdispatch/uri';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'shared/routing/react-router-6';
import { useCarrierSettings } from 'shared/settings/CarrierSettingsAPI';
import { useFlag } from 'shared/settings/FeatureToggles';
import { emitLoadEvent } from '../data/LoadEvents';
import { LoadsImportResult } from '../data/LoadsAPI';
import { useLoadsContext } from '../data/LoadsContext';

export interface LoadsImportButtonProps {
  onSubmitSuccess: () => void;
  onClick?: () => void;
}

export function LoadsImportButton({
  onSubmitSuccess,
  onClick,
}: LoadsImportButtonProps) {
  const [errorMessage, setErrorMessage] = useState<string>();
  const [isErrorDetailsVisible, setIsErrorDetailsVisible] = useState<boolean>();
  const navigate = useNavigate();
  const inputRef = useRef<HTMLInputElement>(null);
  const { addSnackbar } = useSnackbarStack();
  const { data: settings } = useCarrierSettings();
  const shouldHaveAccessBillingLevel = useFlag('billing_access_level');

  const { loadsAPI } = useLoadsContext();
  const { values, handleSubmit, isSubmitting, setFieldValue } =
    useFormikEnhanced<{ files: File[] }, LoadsImportResult>({
      initialValues: { files: [] },
      onSubmit: ({ files }) => loadsAPI.uploadLoads(files[0] as File),
      onSubmitSuccess: (data) => {
        if (data.length === 1) {
          navigate(
            parseURITemplate('/loads/{guid}{?next}', {
              guid: data[0]?.guid,
              next: window.location.pathname + window.location.search,
            }),
          );
        } else {
          addSnackbar(`${data.length} loads are successfully imported.`);
        }

        emitLoadEvent('imported');
        onSubmitSuccess();
      },
      onSubmitFailure: ({ message }) => {
        setErrorMessage(message);
      },
    });

  const shouldRestrictImport = shouldHaveAccessBillingLevel
    ? !settings?.can_import_load
    : settings?.lol_experiment?.should_be_restricted_in_load_offer_loophole;

  useEffect(() => {
    if (values.files.length) {
      handleSubmit();
    }
  }, [handleSubmit, values, values.files.length]);

  return (
    <>
      {!!values.files.length && (
        <Dialog
          open={!!errorMessage}
          onClose={() => {
            setErrorMessage(undefined);
          }}
        >
          <DialogTitle>
            {values.files[0]?.type === 'application/pdf'
              ? 'We couldn’t import this dispatch sheet'
              : 'We couldn’t import this load'}
          </DialogTitle>
          <DialogContent>
            <Stack space="small">
              <Typography>
                {values.files[0]?.type === 'application/pdf' ? (
                  <>
                    The dispatch sheet you are importing is not supported yet.
                    You can find the list of supported brokers in the help
                    article.
                  </>
                ) : (
                  <>
                    The CSV file you are trying to import differs from our
                    template.{' '}
                    <Link
                      download={true}
                      href="https://storage.googleapis.com/superdispatch-carrier-tms-production/templates/CSV-import-template.csv"
                      color="primary"
                    >
                      Download a sample CSV template
                    </Link>{' '}
                    to see an example of the format required. Also, check the
                    help article to see CSV import requirements.
                  </>
                )}
              </Typography>
              <Stack space="xsmall">
                <Button
                  variant="text"
                  endIcon={
                    isErrorDetailsVisible ? <ExpandLess /> : <ExpandMore />
                  }
                  onClick={() => {
                    setIsErrorDetailsVisible(!isErrorDetailsVisible);
                  }}
                >
                  More Details
                </Button>
                <Collapse in={isErrorDetailsVisible}>{errorMessage}</Collapse>
              </Stack>
            </Stack>
          </DialogContent>

          <DialogActions>
            <AnchorButton
              variant="neutral"
              target="_blank"
              href="https://support.superdispatch.com/en/articles/4358989-how-to-import-pdf-dispatch-sheets-and-csv-loads-inside-carrier-dashboard"
            >
              Open Help Article
            </AnchorButton>
            <Button
              onClick={() => {
                setErrorMessage(undefined);
              }}
            >
              Got It
            </Button>
          </DialogActions>
        </Dialog>
      )}

      <input
        value=""
        type="file"
        hidden={true}
        ref={inputRef}
        accept=".pdf,.csv"
        onChange={(event) => {
          if (event.target.files) {
            void setFieldValue('files', Array.from(event.target.files));
          }
        }}
      />

      <Tooltip
        placement="bottom"
        title={
          shouldRestrictImport ? (
            <Inline verticalAlign="center">
              <Lock />
              <TextBox variant="body" color="white">
                Upgrade to unlock this feature.
              </TextBox>
            </Inline>
          ) : (
            ''
          )
        }
      >
        <Button
          startIcon={shouldRestrictImport && <Lock color="action" />}
          variant="neutral"
          disabled={isSubmitting}
          onClick={() => {
            if (!shouldRestrictImport) {
              inputRef.current?.click();
            } else {
              onClick?.();
            }
          }}
        >
          Import Load
        </Button>
      </Tooltip>

      <Tooltip
        enterTouchDelay={0}
        title="Open help article about PDF and CSV importing."
      >
        <IconButton
          size="small"
          target="_blank"
          rel="noopener noreferrer"
          href="https://support.superdispatch.com/en/articles/4358989-how-to-import-pdf-dispatch-sheets-and-csv-loads-inside-carrier-dashboard"
        >
          <Info color="primary" />
        </IconButton>
      </Tooltip>

      <Snackbar open={isSubmitting}>Importing dispatch sheet…</Snackbar>
    </>
  );
}
