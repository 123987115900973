import { IconButton, Typography } from '@material-ui/core';
import { Column, Columns, Inline } from '@superdispatch/ui';
import { Box, Button } from '@superdispatch/ui-lab';
import { useState } from 'react';
import { Close } from 'shared/icons/Close';
import { WarningIcon } from 'shared/icons/WarningIcon';
import { useNavigate } from 'shared/routing/react-router-6';
import { useCarrierSettings } from 'shared/settings/CarrierSettingsAPI';

export function NavigationHeaderBanner() {
  const [shouldShowBanner, setShouldShowBanner] = useState(true);
  const { data: settings } = useCarrierSettings();
  const navigate = useNavigate();

  if (!shouldShowBanner) {
    return null;
  }

  return (
    <Box
      paddingTop="small"
      paddingBottom="small"
      paddingRight="large"
      backgroundColor="Yellow50"
    >
      <Columns align="center">
        <Column width="fluid">
          <Inline verticalAlign="center" horizontalAlign="center" space="small">
            <Inline
              space="xxsmall"
              verticalAlign="center"
              horizontalAlign="center"
            >
              <WarningIcon />
              <Typography>
                You are currently viewing the Carrier TMS as{' '}
                <b>{settings?.carrier.name}</b>
              </Typography>
            </Inline>

            <Button
              size="small"
              variant="neutral"
              onClick={() => {
                navigate('/logout');
              }}
            >
              Log Out
            </Button>
          </Inline>
        </Column>

        <Column width="content">
          <IconButton
            onClick={() => {
              setShouldShowBanner(false);
            }}
            size="small"
          >
            <Close />
          </IconButton>
        </Column>
      </Columns>
    </Box>
  );
}
