import { Typography } from '@material-ui/core';
import { LinkButton } from 'shared/routing/Links';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px;
`;

export function EmptyTripLoadsTable({ addLoadLink }: { addLoadLink?: string }) {
  return (
    <Wrapper>
      <Typography variant="h2">No loads in this trip</Typography>
      {addLoadLink && (
        <>
          <Typography
            id="empty-table-subtitle"
            variant="subtitle1"
            gutterBottom={true}
          >
            To add loads to this trip, tap button below.
          </Typography>
          <LinkButton
            to={addLoadLink}
            variant="default"
            id="empty-table-add-loads-link"
          >
            Add Load
          </LinkButton>
        </>
      )}
    </Wrapper>
  );
}
