import { trackEvent } from 'shared/helpers/Analytics';

export type DispatchersAnalyticsEvent =
  | {
      name: 'Carrier Clicked Invite Dispatcher';
    }
  | { name: 'Carrier Sent Invite To Dispatcher' }
  | { name: 'Carrier Accepted Join Request Of Dispatcher' }
  | { name: 'Carrier Declined Join Request Of Dispatcher' }
  | { name: 'Carrier Deleted Dispatcher' }
  | { name: 'Carrier Reactivated Dispatcher' }
  | { name: 'Carrier Deactivated Dispatcher' };

export function trackDispatchersEvent(event: DispatchersAnalyticsEvent) {
  trackEvent(event.name);
}
