import { useMemo } from 'react';
import { useQueryClient } from 'react-query';
import { requestCarrierAPI } from 'shared/api/APIClient';
import {
  APIMutationOptions,
  APIMutationResult,
  useAPIMutation,
} from 'shared/api/APIMutation';
import {
  APIQueryResult,
  useAPIQuery,
  UseAPIQueryOptions,
} from 'shared/api/APIQuery';
import { useVerificationNotificationsReset } from 'shared/layout/notification-stack/core/HideVerificationNotifications';
import { CarrierInfoDTO } from 'shared/modules/carrier-profile/CarrierInfoDTO';
import {
  ACHPaymentInfoDTO,
  ACHPaymentInfoSchema,
  BillingInfoDTO,
  billingInfoSchema,
  BusinessLicenseDTO,
  businessLicenseSchema,
  CargoInsuranceDTO,
  cargoInsuranceSchema,
  StateMotorCarrierPermitDTO,
  stateMotorCarrierPermitSchema,
  TermsAndConditionsDTO,
  TransporterPlateRegistrationDTO,
  transporterPlateRegistrationSchema,
  USDOTDTO,
  USDOTSchema,
  W9DTO,
  w9Schema,
} from './CarrierProfileDTO';

export function useCarrierProfileAPI() {
  const queryClient = useQueryClient();
  const resetVerificationVisibility = useVerificationNotificationsReset();

  return useMemo(
    () => ({
      updateCarrierInfo: (values: Partial<CarrierInfoDTO>) =>
        requestCarrierAPI('POST /internal/web/carrier/profile/carrier-info/', {
          json: values,
        }).then((response) => {
          void queryClient.invalidateQueries(['profile', 'carrier-info']);
          void queryClient.invalidateQueries([
            'verified-carrier-application',
            'status',
          ]);
          return response;
        }),

      updateCargoInsurance: (values: Partial<CargoInsuranceDTO>) =>
        requestCarrierAPI(
          'POST /internal/web/carrier/profile/cargo-insurance/',
          { json: values },
        ).then((response) => {
          resetVerificationVisibility();
          void queryClient.invalidateQueries(['profile', 'cargo-insurance']);
          void queryClient.invalidateQueries([
            'verified-carrier-application',
            'status',
          ]);
          return response;
        }),

      updateTermsAndConditions: (values: Partial<TermsAndConditionsDTO>) =>
        requestCarrierAPI(
          'POST /internal/web/carrier/profile/terms-and-conditions/',
          { json: values },
        ).then((response) => {
          void queryClient.invalidateQueries([
            'profile',
            'terms-and-conditions',
          ]);
          void queryClient.invalidateQueries([
            'verified-carrier-application',
            'status',
          ]);
          return response;
        }),

      updateBillingInfo: (values: Partial<BillingInfoDTO>) =>
        requestCarrierAPI('POST /internal/web/carrier/profile/billing-info/', {
          json: values,
        }).then((response) => {
          void queryClient.invalidateQueries(['profile', 'billing-info']);
          void queryClient.invalidateQueries([
            'verified-carrier-application',
            'status',
          ]);
          return response;
        }),

      updateW9: (values: Partial<W9DTO>) =>
        requestCarrierAPI('POST /internal/web/carrier/profile/w9/', {
          json: values,
        }).then((response) => {
          resetVerificationVisibility();
          void queryClient.invalidateQueries(['profile', 'w9']);
          void queryClient.invalidateQueries([
            'verified-carrier-application',
            'status',
          ]);
          return response;
        }),

      updateUSDOT: (values: Partial<USDOTDTO>) =>
        requestCarrierAPI('POST /internal/web/carrier/profile/usdot/', {
          json: values,
        }).then((response) => {
          resetVerificationVisibility();
          void queryClient.invalidateQueries(['profile', 'usdot']);
          void queryClient.invalidateQueries([
            'verified-carrier-application',
            'status',
          ]);
          return response;
        }),

      updateTransporterPlateRegistration: (
        values: Partial<TransporterPlateRegistrationDTO>,
      ) =>
        requestCarrierAPI(
          'PATCH /internal/web/carrier/profile/transporter-plate-registration/',
          {
            json: values,
          },
        ).then((response) => {
          resetVerificationVisibility();
          void queryClient.invalidateQueries([
            'profile',
            'transporter-plate-registration',
          ]);
          void queryClient.invalidateQueries([
            'verified-carrier-application',
            'status',
          ]);
          return response;
        }),

      updateStateMotorCarrierPermit: (
        values: Partial<StateMotorCarrierPermitDTO>,
      ) =>
        requestCarrierAPI(
          'PATCH /internal/web/carrier/profile/state-motor-carrier-permit/',
          {
            json: values,
          },
        ).then((response) => {
          resetVerificationVisibility();
          void queryClient.invalidateQueries([
            'profile',
            'state-motor-carrier-permit',
          ]);
          void queryClient.invalidateQueries([
            'verified-carrier-application',
            'status',
          ]);
          return response;
        }),

      updateBusinessLicense: (values: Partial<BusinessLicenseDTO>) =>
        requestCarrierAPI(
          'PATCH /internal/web/carrier/profile/business-license/',
          {
            json: values,
          },
        ).then((response) => {
          resetVerificationVisibility();
          void queryClient.invalidateQueries(['profile', 'business-license']);
          void queryClient.invalidateQueries([
            'verified-carrier-application',
            'status',
          ]);
          return response;
        }),

      updateACHPaymentInfo: (values: Partial<ACHPaymentInfoDTO>) =>
        requestCarrierAPI(
          'POST /internal/web/carrier/profile/ach-payment-info/',
          { json: values },
        ).then((response) => {
          void queryClient.invalidateQueries(['profile', 'ach-payment-info']);
          void queryClient.invalidateQueries([
            'verified-carrier-application',
            'status',
          ]);
          return response;
        }),
    }),
    [queryClient, resetVerificationVisibility],
  );
}

export function useCargoInsurance(
  options?: UseAPIQueryOptions<CargoInsuranceDTO>,
): APIQueryResult<CargoInsuranceDTO> {
  return useAPIQuery(
    ['profile', 'cargo-insurance'],
    () =>
      requestCarrierAPI('GET /internal/web/carrier/profile/cargo-insurance/'),
    { schema: cargoInsuranceSchema, ...options },
  );
}

export function useTermsAndConditions(): APIQueryResult<TermsAndConditionsDTO> {
  return useAPIQuery(['profile', 'terms-and-conditions'], () =>
    requestCarrierAPI(
      'GET /internal/web/carrier/profile/terms-and-conditions/',
    ),
  );
}

export function useBillingInfo(): APIQueryResult<BillingInfoDTO> {
  return useAPIQuery(
    ['profile', 'billing-info'],
    () => requestCarrierAPI('GET /internal/web/carrier/profile/billing-info/'),
    { schema: billingInfoSchema },
  );
}

export function useW9(
  options?: UseAPIQueryOptions<W9DTO>,
): APIQueryResult<W9DTO> {
  return useAPIQuery(
    ['profile', 'w9'],
    () => requestCarrierAPI('GET /internal/web/carrier/profile/w9/'),
    { schema: w9Schema, ...options },
  );
}

export function useUSDOT(
  options?: UseAPIQueryOptions<USDOTDTO>,
): APIQueryResult<USDOTDTO> {
  return useAPIQuery(
    ['profile', 'usdot'],
    () => requestCarrierAPI('GET /internal/web/carrier/profile/usdot/'),
    { schema: USDOTSchema, ...options },
  );
}

export function useTransporterPlateRegistration(
  options?: UseAPIQueryOptions<TransporterPlateRegistrationDTO>,
): APIQueryResult<TransporterPlateRegistrationDTO> {
  return useAPIQuery(
    ['profile', 'transporter-plate-registration'],
    () =>
      requestCarrierAPI(
        'GET /internal/web/carrier/profile/transporter-plate-registration/',
      ),
    { schema: transporterPlateRegistrationSchema, ...options },
  );
}

export function useStateMotorCarrierPermit(
  options?: UseAPIQueryOptions<StateMotorCarrierPermitDTO>,
): APIQueryResult<StateMotorCarrierPermitDTO> {
  return useAPIQuery(
    ['profile', 'state-motor-carrier-permit'],
    () =>
      requestCarrierAPI(
        'GET /internal/web/carrier/profile/state-motor-carrier-permit/',
      ),
    { schema: stateMotorCarrierPermitSchema, ...options },
  );
}

export function useBusinessLicense(
  options?: UseAPIQueryOptions<BusinessLicenseDTO>,
): APIQueryResult<BusinessLicenseDTO> {
  return useAPIQuery(
    ['profile', 'business-license'],
    () =>
      requestCarrierAPI('GET /internal/web/carrier/profile/business-license/'),
    { schema: businessLicenseSchema, ...options },
  );
}

export function useACHPaymentInfo(): APIQueryResult<ACHPaymentInfoDTO> {
  return useAPIQuery(
    ['profile', 'ach-payment-info'],
    () =>
      requestCarrierAPI('GET /internal/web/carrier/profile/ach-payment-info/'),
    { schema: ACHPaymentInfoSchema },
  );
}

export function useDeleteACHPaymentInfo(
  options?: APIMutationOptions<string>,
): APIMutationResult<string> {
  const queryClient = useQueryClient();

  return useAPIMutation(
    () =>
      requestCarrierAPI(
        'DELETE /internal/web/carrier/profile/ach-payment-info/',
      ).then((response) => {
        void queryClient.invalidateQueries(['profile', 'ach-payment-info']);
        return response;
      }),
    options,
  );
}

export function useRegisterVerificationError() {
  const queryClient = useQueryClient();

  return useAPIMutation((bankAccountGuid: string) =>
    requestCarrierAPI(
      `POST /internal/web/payments/superpay-settings/${bankAccountGuid}/register-verification-error/`,
    ).then((response) => {
      void queryClient.invalidateQueries(['superpay-settings']);
      return response;
    }),
  );
}
