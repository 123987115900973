import { yupObject, yupString } from 'shared/utils/YupUtils';
import { InferType } from 'yup';

export type LoadboardNotificationMessageDTO = InferType<
  typeof loadboardNotificationMessageSchema
>;
export const loadboardNotificationMessageSchema = yupObject({
  body: yupString(),
  subject: yupString(),
  load_guid: yupString(),
});
