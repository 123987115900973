import { QueryBuilder } from '@material-ui/icons';
import { Color } from '@superdispatch/ui';
import { Box, SidebarMenuItemAvatar } from '@superdispatch/ui-lab';
import { PersonAddAlt } from 'shared/icons/PersonAddAlt';
import { PersonDeactivatedIcon } from 'shared/icons/PersonDeactivatedIcon';
import { DispatcherDTO } from 'shared/modules/dispatcher/DispatcherDTO';
import styled from 'styled-components';

interface DispatcherAvatarProps {
  dispatcher: DispatcherDTO;
}

const StyledBox = styled(Box)`
  border-radius: 50%;
`;

export function DispatcherAvatar({ dispatcher }: DispatcherAvatarProps) {
  const inviteDirection = dispatcher.connection_type;
  const inviteStatus = dispatcher.connection_status;
  const dispatcherLabel = dispatcher.name || dispatcher.email;

  if (inviteDirection === 'join_request' && inviteStatus === 'pending') {
    return (
      <StyledBox backgroundColor="Blue300" padding="xsmall">
        <PersonAddAlt htmlColor={Color.White} fontSize="small" />
      </StyledBox>
    );
  }

  if (inviteDirection === 'invite' && inviteStatus === 'pending') {
    return (
      <StyledBox
        borderWidth="small"
        borderColor="Yellow300"
        backgroundColor="Yellow50"
        padding="xsmall"
      >
        <QueryBuilder fontSize="small" htmlColor={Color.Yellow500} />
      </StyledBox>
    );
  }

  if (inviteStatus === 'declined' || inviteStatus === 'deactivated') {
    return (
      <StyledBox
        borderWidth="small"
        borderColor="Silver400"
        backgroundColor="Silver200"
        padding="xsmall"
      >
        <PersonDeactivatedIcon />
      </StyledBox>
    );
  }

  return <SidebarMenuItemAvatar>{dispatcherLabel}</SidebarMenuItemAvatar>;
}
