import { Color } from '@superdispatch/ui';
import { createSvgIcon } from 'shared/icons/IconUtils';

export const WarningIcon = createSvgIcon(
  'WarningDashed',
  <>
    <g clipPath="url(#clip0_3080_12116)">
      <path
        d="M1.5 21H23.5L12.5 2L1.5 21ZM13.5 18H11.5V16H13.5V18ZM13.5 14H11.5V10H13.5V14Z"
        fill={Color.Yellow300}
      />
    </g>
    <defs>
      <clipPath id="clip0_3080_12116">
        <rect width="24" height="24" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </>,
  { viewBox: '0 0 25 24' },
);
