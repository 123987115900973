import { FieldValidator, useField, useFormikContext } from 'formik';
import { MediaField, MediaFieldProps } from 'shared/form/MediaField';

interface FormikMediaFieldProps extends MediaFieldProps {
  name: string;
  validate?: FieldValidator;
}

export const EMPTY_ERROR_MESSAGE = 'EMPTY_ERROR_MESSAGE';

export function FormikMediaField({
  name,
  helperText,
  error: errorProp,
  validate,
  onChange,
  ...props
}: FormikMediaFieldProps) {
  const { isSubmitting } = useFormikContext();
  const [field, { error, touched }, { setValue }] = useField<string | null>({
    name,
    validate,
  });

  const errorMessage = touched && error !== EMPTY_ERROR_MESSAGE && error;

  return (
    <MediaField
      {...field}
      {...props}
      disabled={isSubmitting || props.disabled}
      error={!!errorMessage || errorProp}
      onChange={(url) => {
        onChange?.(url);
        void setValue(url);
      }}
      helperText={errorMessage || helperText}
    />
  );
}
