import { IconButton, Typography } from '@material-ui/core';
import { Delete, Edit } from '@material-ui/icons';
import { formatDate, NullableDateString } from '@superdispatch/dates';
import {
  Column,
  Columns,
  Stack,
  useResponsiveValue,
  useUID,
} from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import { ReactNode } from 'react';

export interface ReviewResponseProps {
  author: ReactNode;
  avatar: ReactNode;
  responseDate: NullableDateString;
  response?: string;
  onClickEdit?: () => void;
  onClickDelete?: () => void;
}

export function ReviewResponse({
  response,
  author,
  avatar,
  responseDate,
  onClickEdit,
  onClickDelete,
}: ReviewResponseProps) {
  const uid = useUID();
  const editIconSize = useResponsiveValue('small', 'small', 'medium');

  return (
    <Columns aria-labelledby={uid} space="xsmall">
      <Column width="content">
        <Box marginTop="xxsmall">{avatar}</Box>
      </Column>

      <Column>
        <Stack space="xsmall">
          <Columns align="center">
            <Column>
              <Typography id={uid}>{author}</Typography>

              <Typography
                aria-label="review response date"
                variant="caption"
                color="textSecondary"
              >
                {formatDate(responseDate, { variant: 'Date' })}
              </Typography>
            </Column>

            {onClickEdit && (
              <Column width="content">
                <IconButton
                  aria-label="edit review response"
                  onClick={onClickEdit}
                >
                  <Edit fontSize={editIconSize} color="action" />
                </IconButton>

                <IconButton
                  aria-label="delete review response"
                  onClick={onClickDelete}
                >
                  <Delete fontSize={editIconSize} color="action" />
                </IconButton>
              </Column>
            )}
          </Columns>

          {response && (
            <Box maxWidth="680px">
              <Typography aria-label="review response" color="textSecondary">
                {response}
              </Typography>
            </Box>
          )}
        </Stack>
      </Column>
    </Columns>
  );
}
