import { Tab, Tabs } from '@material-ui/core';
import { Inline, Tag } from '@superdispatch/ui';
import { useErrorSnackbar } from 'shared/hooks/useErrorSnackbar';
import { useOnboardingTourHandler } from 'shared/modules/onboarding/OnboardingIntercomTours';
import { useCarrierSettings } from 'shared/settings/CarrierSettingsAPI';
import { formatLoadStage, LoadStage } from '../data/LoadDTO';
import { trackLoadsEvent } from '../data/LoadsAnalytics';
import { useLoadCounts } from '../data/LoadsAPI';
import { useLoadsPageContext } from './data/LoadsPageParamsContext';
import { LOADS_PAGE_SIZE } from './LoadsPagePagination';

const tabs = new Set<LoadStage>([
  'new',
  'in_terminal',
  'assigned',
  'picked_up',
  'delivered',
  'billed',
  'paid',
  'archived',
  'deleted',
]);

export function LoadsPageTabs() {
  const { data: settings } = useCarrierSettings();
  const { params, updateParams, isEmptyFilterParams } = useLoadsPageContext();
  const { data: counts, error } = useLoadCounts(params);

  useErrorSnackbar(error);

  useOnboardingTourHandler({
    tourName: 'tour_load_delivered',
    enabled: !!counts?.get('delivered')?.count,
  });

  return (
    <Tabs
      value={params.stage}
      variant="scrollable"
      scrollButtons="on"
      onChange={(_, nextStage: LoadStage) => {
        const nextStageCount = counts?.get(nextStage)?.count;

        updateParams(
          (prev) => ({
            ...prev,
            page: getNextPage(nextStageCount, prev.page),
            stage: nextStage,
          }),
          { strategy: 'reset' },
        );

        trackLoadsEvent({ name: 'CTMS: Clicked Loads Tab', tab: nextStage });
      }}
    >
      {Array.from(tabs, (stage) => {
        if (stage === 'in_terminal' && !settings?.has_terminal) {
          return null;
        }

        const isActive = params.stage === stage;
        const count = counts?.get(stage)?.count;
        const label = formatLoadStage(stage);

        return (
          <Tab
            key={stage}
            value={stage}
            data-intercom-target={`loads-tab-${stage}`}
            label={
              <Inline space="xsmall">
                <span>{label}</span>

                {!!count && (
                  <Tag
                    variant="subtle"
                    color={isActive || !isEmptyFilterParams ? 'blue' : 'grey'}
                  >
                    {count}
                  </Tag>
                )}
              </Inline>
            }
          />
        );
      })}
    </Tabs>
  );
}

const INITIAL_PAGE = 1;

function getNextPage(
  nextStageCount: number | undefined | null,
  previousPage: number,
) {
  if (!nextStageCount) {
    return INITIAL_PAGE;
  }

  const nextStageLastPage = Math.ceil(nextStageCount / LOADS_PAGE_SIZE);
  if (previousPage < nextStageLastPage) {
    return INITIAL_PAGE;
  }

  return nextStageLastPage;
}
