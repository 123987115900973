import { Color } from '@superdispatch/ui';
import { createSvgIcon } from 'shared/icons/IconUtils';

export const CompletedIcon = createSvgIcon(
  'CompletedIcon',
  <>
    <rect width="32" height="32" rx="16" fill={Color.Silver200} />
    <path
      d="M13.0847 19.4676L9.29164 15.736L8 16.9978L13.0847 22L24 11.2617L22.7175 10L13.0847 19.4676Z"
      fill={Color.Dark500}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.29104 15.7361L13.0841 19.4676L22.7169 10.0001L23.9994 11.2618L13.0841 22L7.9994 16.9978L9.29104 15.7361ZM13.0843 18.7664L22.717 9.29883L24.7124 11.2618L13.0841 22.7015L7.28516 16.9966L9.29228 15.0359L13.0843 18.7664Z"
      fill={Color.Dark100}
    />
  </>,
  {
    viewBox: '0 0 32 32',
    style: { height: '32px', width: '32px' },
  },
);
