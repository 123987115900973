import { useEffect } from 'react';
import { useLocalStore } from 'shared/helpers/Store';
import {
  IS_ENTERED_DISPATCHER_PROFILE,
  setEnteredDispatcherProfile,
} from '../notification-stack/data/PasswordBannerStorage';

export function useDispatcherProfileVisitedValue() {
  return useLocalStore(IS_ENTERED_DISPATCHER_PROFILE);
}

export function useIsDispatcherProfileVisited() {
  const isVisitedDispatcherProfile = useDispatcherProfileVisitedValue();

  useEffect(() => {
    if (!isVisitedDispatcherProfile) {
      setEnteredDispatcherProfile('true');
    }
  }, [isVisitedDispatcherProfile]);
}
