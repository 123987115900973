import { createSvgIcon } from './IconUtils';

export const WeightIcon = createSvgIcon(
  'Weight',
  <path
    fill="currentColor"
    d="M8 2a2.667 2.667 0 012.307 4H12c.633 0 1.167.447 1.3 1.04 1.34 5.34 1.367 5.48 1.367 5.627A1.334 1.334 0 0113.333 14H2.667a1.334 1.334 0 01-1.334-1.333c0-.147.027-.287 1.367-5.627A1.336 1.336 0 014 6h1.693A2.667 2.667 0 018 2zm0 1.333A1.333 1.333 0 108 6a1.333 1.333 0 000-2.667z"
  />,
  { viewBox: '0 0 16 16' },
);
