import { useSnackbarStack } from '@superdispatch/ui';
import { useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { useLogout } from 'shared/auth/data/AuthAPI';
import { useShowSubscriptionCancelledDialog } from 'shared/errors/data/useShowSubscriptionCancelledDialog';
import { useShowTrialExpiredDialog } from 'shared/errors/data/useShowTrialExpiredDialog';
import { clearErrorTrackerUser } from 'shared/helpers/ErrorTracker';
import { SplashScreen } from 'shared/layout/SplashScreen';
import { useSubscriptionCelebrationState } from 'shared/modules/subscription/core/useSubscriptionCelebrationState';
import { useNavigate } from 'shared/routing/react-router-6';
import { MoovAccountInfo } from '../../carrier/profile/data/MoovAccountInfo';
import { refreshAuthToken, saveAuthRole } from './AuthToken';
import { useVerification } from './core/VerificationStorage';
import { useDriverAccount } from './data/useDriverAccount';

export function LogoutContainerPage() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { removeVerification } = useVerification();
  const { removeDriverToken } = useDriverAccount();
  const { removeTrialExpiredDialog } = useShowTrialExpiredDialog();
  const { removeSubscriptionCancelledDialog } =
    useShowSubscriptionCancelledDialog();
  const { removeStatuses } = useSubscriptionCelebrationState();

  const { addSnackbar } = useSnackbarStack();
  const { mutate } = useLogout({
    onSuccess() {
      clearErrorTrackerUser();
      queryClient.clear();
      MoovAccountInfo.flushStorageData(); // to avoid onboarding status collisions among other accounts
      refreshAuthToken(null);
      saveAuthRole(null);
      removeVerification();
      removeTrialExpiredDialog();
      removeSubscriptionCancelledDialog();
      removeDriverToken();
      removeStatuses();
      navigate('/login', { replace: true });
    },
    onError() {
      addSnackbar('Failed to logout, please contact support', {
        variant: 'error',
      });
    },
  });

  useEffect(() => {
    mutate();
  }, [mutate]);

  return <SplashScreen />;
}
