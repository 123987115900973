import { Link } from '@material-ui/core';
import { parseURITemplate } from '@superdispatch/uri';
import { ReactNode } from 'react';
import { LinkAnchor } from 'shared/routing/Links';
import { useFlag } from 'shared/settings/FeatureToggles';
import { stringifySearchQuery } from 'shared/utils/URLUtils';
import {
  ReportsAccountsReceivableParamsDTO,
  ReportsCompanyRevenueParamsDTO,
  ReportsPeriodDTO,
} from './data/ReportsDTO';

export type Parameters = Partial<
  ReportsAccountsReceivableParamsDTO &
    ReportsCompanyRevenueParamsDTO &
    ReportsPeriodDTO
>;

export interface ReportsLoadsLinkProps {
  stage: 'revenue' | 'payable' | 'receivable' | 'receivable/past-due';
  params?: Parameters;
  companyName: string;
  children: ReactNode;
}

export function ReportsLoadsLink({
  stage,
  params,
  companyName,
  children,
}: ReportsLoadsLinkProps) {
  const shouldUseNewReportLoads = useFlag('reports_loads_page');

  if (shouldUseNewReportLoads) {
    return (
      <LinkAnchor
        to={parseURITemplate('/reports/loads{?params*}', {
          params: {
            stage,
            broker_name: companyName,
            ...getDates(params),
          },
        })}
      >
        {children}
      </LinkAnchor>
    );
  }

  return (
    <Link
      href={`/accounting/orders/?${stringifySearchQuery({
        ...params,
        past_due: params?.past_due ? 'True' : undefined,
        missing_info: params?.missing ? 'True' : undefined,
        use_case: stage,
        broker_name: companyName,
      })}`}
    >
      {children}
    </Link>
  );
}

function getDates(params?: Parameters) {
  if (params?.accounting_method === 'cash') {
    return {
      receipt_date_after: params.start_date,
      receipt_date_before: params.end_date,
    };
  }

  return {
    invoice_date_after: params?.start_date,
    invoice_date_before: params?.end_date,
  };
}
