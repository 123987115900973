import { useParams } from 'react-router-dom';
import { openExternalURL } from 'shared/helpers/URLHelpers';
import { HeaderWithLogo } from 'shared/layout/HeaderWithLogo';
import { SplashScreen } from 'shared/layout/SplashScreen';
import { useDispatcherInvitationDetails } from '../data/DispatchersAPI';
import { DispatcherInvitationDeclinedPage } from './DispatcherInvitationDeclinedPage';
import { DispatcherInvitationForm } from './DispatcherInvitationForm';

export function DispatcherInvitationPage() {
  const { invitationCode } = useParams<{ invitationCode: string }>();
  const { data, isLoading } = useDispatcherInvitationDetails(
    {
      invitationCode,
    },
    {
      onSuccess: (response) => {
        if (response.connection_status === 'activated') {
          openExternalURL('/tms/loads', { target: '_self' });
        }
      },
    },
  );

  if (isLoading) {
    return <SplashScreen />;
  }

  return (
    <>
      <HeaderWithLogo />
      {data?.connection_status === 'pending' ? (
        <DispatcherInvitationForm
          dispatcher={data}
          invitationCode={invitationCode}
        />
      ) : (
        <DispatcherInvitationDeclinedPage />
      )}
    </>
  );
}
