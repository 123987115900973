import { ButtonBase, Divider, Typography } from '@material-ui/core';
import { Color } from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import { useMemo } from 'react';
import { formatAddressLine } from 'shared/helpers/AddressHelpers';
import { trackEvent } from 'shared/helpers/Analytics';
import {
  composeSavedSearchTitle,
  PostingSearchCriteriaDTO,
  PostingSearchCriteriaVenueDTO,
} from 'shared/modules/loadboard/PostingSearchCriteriaDTO';
import styled from 'styled-components';
import {
  generateHash,
  useRecentSearches,
} from './PostingRecentSearchesStorage';

const Button = styled(ButtonBase)`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  padding: 0 16px;
  color: ${Color.Dark500};

  &:hover {
    background-color: ${Color.Silver30};
  }
`;

function formatVenues(venues: PostingSearchCriteriaVenueDTO[]) {
  if (venues.length === 0) {
    return 'Anywhere';
  }

  return venues
    .map((item) =>
      formatAddressLine(item.zip, item.city, item.state?.toUpperCase()),
    )
    .join('; ');
}

interface Props {
  searchCriteria: PostingSearchCriteriaDTO;
  onClick: (value: PostingSearchCriteriaDTO) => void;
}

export function PostingRecentSearches({ onClick, searchCriteria }: Props) {
  const recentSearches = useRecentSearches();
  const currentSearchHash = useMemo(
    () => generateHash(searchCriteria),
    [searchCriteria],
  );
  const filteredRecentSearches = useMemo(
    () => recentSearches.filter(([key]) => key !== currentSearchHash),
    [recentSearches, currentSearchHash],
  );

  if (filteredRecentSearches.length === 0) {
    return null;
  }

  return (
    <div>
      <Box paddingBottom="xsmall" paddingLeft="small" paddingRight="small">
        <Typography variant="h5" color="textSecondary">
          Recent Searches
        </Typography>
      </Box>

      {filteredRecentSearches.map(([key, search]) => {
        const title = composeSavedSearchTitle({
          ...search,
          pickup_venues: [],
          delivery_venues: [],
        });
        return (
          <Button
            key={key}
            onMouseDown={() => {
              onClick(search);
              trackEvent('SLB: Clicked Recent Search', {
                search: title,
                origin: formatVenues(search.pickup_venues),
                destination: formatVenues(search.delivery_venues),
              });
            }}
          >
            <Box paddingTop="xsmall" paddingBottom="xsmall" maxWidth="100%">
              <Typography variant="body1" align="left" noWrap={true}>
                {formatVenues(search.pickup_venues)}

                {' -> '}

                {formatVenues(search.delivery_venues)}
              </Typography>

              <Typography noWrap={true} align="left" color="textSecondary">
                {title}
              </Typography>
            </Box>
          </Button>
        );
      })}

      <Box paddingBottom="xsmall" paddingTop="xsmall">
        <Divider />
      </Box>
    </div>
  );
}
