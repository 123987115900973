import {
  Card,
  CardContent,
  Grid,
  Link,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { Error } from '@material-ui/icons';
import { FormattedDate } from '@superdispatch/dates';
import { formatVehicleType } from '@superdispatch/sdk';
import { Inline, Stack, Tag } from '@superdispatch/ui';
import { Box, TextBox } from '@superdispatch/ui-lab';
import { parseURITemplate } from '@superdispatch/uri';
import { Fragment, useMemo } from 'react';
import { BankErrorSimpleBanner } from 'shared/errors/SuperPayBankErrors';
import { formatAddressLine } from 'shared/helpers/AddressHelpers';
import { formatCurrency, formatPlural } from 'shared/helpers/IntlHelpers';
import {
  formatLoadPayment,
  SuperPayLabel,
} from 'shared/helpers/PaymentHelpers';
import { NewMessageIcon } from 'shared/icons/NewMessageIcon';
import {
  splitTextByLine,
  StyledNotesIcon,
} from 'shared/modules/driver/DriverUtils';
import { SuperPayExpeditedPayNudging } from 'shared/modules/superpay/SuperPayExpeditedPayNudging';
import { SuperPayNudgingBanner } from 'shared/modules/superpay/SuperPayNudgingBanner';
import { SuperPayRequestShipperNudging } from 'shared/modules/superpay/SuperPayRequestShipperNudging';
import { useCarrierSettings } from 'shared/settings/CarrierSettingsAPI';
import { useFlag } from 'shared/settings/FeatureToggles';
import { BusinessTypeTag } from 'shared/ui/BusinessTypeTag';
import { InlineBulletItems } from 'shared/ui/InlineBulletItems';
import { joinStrings } from 'shared/utils/StringUtils';
import { getDriverLabel } from '../../drivers/data/DriverPageUtils';
import { DriverDisconnectedTag } from '../core/DriverDisconnectedTag';
import { LoadLegsDiagram } from '../core/LoadLegsDiagram';
import { LoadPaymentStatus } from '../core/LoadPaymentStatus';
import { LoadTags } from '../core/LoadTags';
import { LoadVehicleStatusTag } from '../core/LoadVehicleStatusTag';
import {
  LoadDTO,
  LoadLegDTO,
  LoadPaymentDTO,
  LoadVehicleDTO,
  sortVehiclesByStatus,
} from '../data/LoadDTO';
import { trackLoadsEvent } from '../data/LoadsAnalytics';
import {
  LoadsPageLoadAction,
  LoadsPageLoadActions,
} from './LoadsPageLoadActions';
import { LoadsPageLoadId } from './LoadsPageLoadId';

function LoadsPageLoadVehicle(vehicle: LoadVehicleDTO) {
  const yearMakeModel = joinStrings(
    ' ',
    vehicle.year,
    vehicle.make,
    vehicle.model,
  );

  return (
    <Inline space="xsmall" aria-label="Vehicle">
      <LoadVehicleStatusTag status={vehicle.status} />

      {!!yearMakeModel && (
        <Typography variant="body1" component="strong">
          {yearMakeModel}
        </Typography>
      )}

      <div>
        <Typography variant="body1" color="textSecondary" component="strong">
          Type:&nbsp;
        </Typography>
        {formatVehicleType(vehicle.type)}
      </div>

      {!!vehicle.vin && (
        <div>
          <Typography variant="body1" color="textSecondary" component="strong">
            VIN #:&nbsp;
          </Typography>
          {vehicle.vin}
        </div>
      )}

      {!!vehicle.price && (
        <Typography component="strong">
          {formatCurrency(vehicle.price)}
        </Typography>
      )}

      {vehicle.is_inoperable && (
        <Tooltip
          title="Inoperable vehicle"
          enterTouchDelay={0}
          aria-label="INOP"
        >
          <Tag color="yellow" variant="bold">
            INOP
          </Tag>
        </Tooltip>
      )}

      {vehicle.requires_enclosed_trailer && (
        <Tooltip
          title="The vehicle requires enclosed transport type"
          enterTouchDelay={0}
        >
          <Tag color="grey" variant="bold" aria-label="Enclosed">
            ENCLOSED
          </Tag>
        </Tooltip>
      )}
    </Inline>
  );
}

interface LoadsPageLoadLegProps {
  leg: LoadLegDTO;
}

function LoadsPageLoadLeg({ leg }: LoadsPageLoadLegProps) {
  const relevantDate = leg.actual_date || leg.scheduled_date;

  const addressLine = formatAddressLine(
    leg.venue.zip,
    leg.venue.city,
    leg.venue.state,
  );
  return (
    <>
      <Inline space="xxsmall">
        <Typography noWrap={true} display="block" variant="body1">
          {leg.venue.name || 'N/A'}
        </Typography>
        {!!leg.venue.business_type && (
          <BusinessTypeTag businessType={leg.venue.business_type} />
        )}
      </Inline>

      {!!leg.venue.address && (
        <Typography display="block">{leg.venue.address}</Typography>
      )}

      {!!addressLine && (
        <Typography noWrap={true} display="block">
          {addressLine}
        </Typography>
      )}

      {!!relevantDate && (
        <Typography>
          <em>
            <FormattedDate
              date={relevantDate}
              variant={leg.actual_date ? 'DateTime' : 'Date'}
            />
          </em>
        </Typography>
      )}

      {leg.venue.contacts.map((contact, idx) => (
        <Fragment key={idx}>
          {!!contact.name && (
            <Typography noWrap={true} display="block">
              {contact.name}
            </Typography>
          )}

          {!!contact.phone && (
            <Typography display="block">Phone: {contact.phone}</Typography>
          )}

          {!!contact.phone2 && (
            <Typography display="block">Mobile: {contact.phone2}</Typography>
          )}
        </Fragment>
      ))}
    </>
  );
}

export interface LoadsPageLoadProps {
  load: LoadDTO;
  onAction: (action: LoadsPageLoadAction) => void;
}

export function LoadsPageLoad({ load, onAction }: LoadsPageLoadProps) {
  const isSuperPayErrorNotificationsEnabled = useFlag(
    'superpay_bank_error_notifications',
  );
  const isInspectionPhotoIssuesEnabled = useFlag(
    'ctms_photo_inspection_issues',
  );
  const isCarrierInvitesToSuperPayEnabled = useFlag(
    'payments_carrier_invites_to_superpay',
  );
  const isExpeditedPayEnabled = useFlag('payments_expedited_pay');
  const isMessagingProjectEnabled = useFlag('messaging_project');
  const [currentPayment] = load.payments as Array<undefined | LoadPaymentDTO>;
  const { data: settings } = useCarrierSettings();
  const sortedVehicles = useMemo(
    () => sortVehiclesByStatus(load.vehicles),
    [load.vehicles],
  );

  const hasDisconnectedDriver =
    load.driver?.connection_status === 'deactivated';
  const driverLabel = getDriverLabel(load.driver || {});
  const isSuperPayRequestShipperNudging =
    isCarrierInvitesToSuperPayEnabled &&
    settings?.superpay.is_invite_available &&
    (load.stage === 'paid' || load.stage === 'active') &&
    currentPayment?.paid_at &&
    load.is_created_by_broker;

  const paymentMethod = useMemo(() => {
    if (currentPayment) {
      if (currentPayment.method) {
        return currentPayment.method === 'superpay' ? (
          <TextBox color="secondary">
            <InlineBulletItems>
              <SuperPayLabel source="Load List" />
              {currentPayment.terms}
            </InlineBulletItems>
          </TextBox>
        ) : (
          formatLoadPayment(currentPayment.method)
        );
      }

      if (Number(currentPayment.price) === 0) {
        return 'No payment info';
      }
    }

    return 'No payment terms';
  }, [currentPayment]);

  return (
    <Card>
      <CardContent>
        <Grid container={true} spacing={2}>
          <Grid item={true} md={8} xs={12}>
            <Stack space="small">
              <Inline
                space="xsmall"
                verticalAlign="center"
                aria-label="Load Header"
              >
                <LoadsPageLoadId load={load} />

                <LoadTags load={load} />

                {!!currentPayment?.price && (
                  <Typography>
                    <Inline>
                      {formatCurrency(currentPayment.price)}
                      {paymentMethod &&
                        (typeof paymentMethod === 'string'
                          ? `(${paymentMethod})`
                          : paymentMethod)}
                    </Inline>
                  </Typography>
                )}

                <LoadLegsDiagram terminalsDiagram={load.terminals_diagram} />

                {isMessagingProjectEnabled && <NewMessageIcon />}
              </Inline>

              {isInspectionPhotoIssuesEnabled &&
                load.stage !== 'new' &&
                load.stage !== 'assigned' &&
                !!load.undetected_vehicle_count && (
                  <Box
                    backgroundColor="Red50"
                    display="inline-block"
                    paddingLeft="xxsmall"
                    paddingRight="xxsmall"
                    borderRadius="small"
                  >
                    <Inline verticalAlign="center" space="xxsmall">
                      <Error fontSize="small" color="error" />
                      <Link
                        href={parseURITemplate(
                          `${load.online_bol_url}{?utm_source,utm_medium,utm_content}`,
                          {
                            utm_source: 'CTMS Web',
                            utm_medium: 'View Load Page',
                            utm_content: 'Non-vehicle Photo Issues Link',
                          },
                        )}
                        onClick={() => {
                          trackLoadsEvent({
                            name: 'Carrier Clicked Non-vehicle Photo Issues',
                            page: 'loads_list',
                          });
                        }}
                      >
                        {load.undetected_vehicle_count} Inspection photo{' '}
                        {formatPlural(
                          load.undetected_vehicle_count,
                          'issue',
                          'issues',
                        )}
                      </Link>
                    </Inline>
                  </Box>
                )}

              {load.vehicles.length > 0 && (
                <Stack space="xsmall" aria-label="Load Vehicles">
                  {sortedVehicles.map((vehicle, index) => (
                    <LoadsPageLoadVehicle
                      key={
                        index /* `vehicle.guid` is not unique somehow ¯\_(ツ)_/¯‍ */
                      }
                      {...vehicle}
                    />
                  ))}
                </Stack>
              )}

              <Grid container={true} spacing={1} aria-label="Load Details">
                <Grid item={true} sm={4} xs={12} aria-label="Origin Block">
                  <Typography variant="h6" color="textSecondary">
                    Origin
                  </Typography>

                  <LoadsPageLoadLeg leg={load.pickup} />
                </Grid>

                <Grid item={true} sm={4} xs={12} aria-label="Destination Block">
                  <Typography variant="h6" color="textSecondary">
                    Destination
                  </Typography>

                  <LoadsPageLoadLeg leg={load.delivery} />
                </Grid>

                <Grid item={true} sm={4} xs={12} aria-label="Customer Block">
                  <Typography variant="h6" color="textSecondary">
                    Shipper/Customer
                  </Typography>

                  <Typography variant="body1" noWrap={true}>
                    {load.customer.venue.name &&
                    load.customer.shipper_profile_url ? (
                      <Link
                        href={load.customer.shipper_profile_url}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={() => {
                          trackLoadsEvent({
                            name: 'CTMS: Clicked Shipper Name',
                            page: 'loads_list',
                          });
                        }}
                      >
                        {load.customer.venue.name}
                      </Link>
                    ) : (
                      load.customer.venue.name || 'N/A'
                    )}
                  </Typography>

                  {load.customer.venue.contacts.map((contact, idx) =>
                    !contact.phone ? null : (
                      <Typography key={idx}>Phone: {contact.phone}</Typography>
                    ),
                  )}
                </Grid>
              </Grid>
            </Stack>
          </Grid>

          <Grid item={true} md={4} xs={12}>
            <Stack space="xsmall">
              <LoadsPageLoadActions load={load} onAction={onAction} />
              {!!load.driver && (
                <Typography color="textSecondary">
                  {load.driver.notes ? (
                    <Inline space="xsmall" verticalAlign="center">
                      <Typography>
                        Assigned to <strong>{driverLabel}</strong>{' '}
                      </Typography>

                      <Tooltip
                        title={splitTextByLine(load.driver.notes)}
                        placement="top"
                        interactive={true}
                      >
                        <StyledNotesIcon />
                      </Tooltip>

                      <DriverDisconnectedTag
                        disconnected={hasDisconnectedDriver}
                      />

                      <Typography>{load.driver.phone_number}</Typography>
                    </Inline>
                  ) : (
                    <Inline space="xsmall" verticalAlign="center">
                      <Typography>
                        Assigned to <strong>{driverLabel}</strong>{' '}
                      </Typography>

                      <DriverDisconnectedTag
                        disconnected={hasDisconnectedDriver}
                      />

                      <Typography>{load.driver.phone_number}</Typography>
                    </Inline>
                  )}
                </Typography>
              )}
              {currentPayment?.method === 'superpay' ? (
                settings?.superpay.payment_error &&
                isSuperPayErrorNotificationsEnabled ? (
                  <BankErrorSimpleBanner
                    source="Load List"
                    paymentError={settings.superpay.payment_error}
                  />
                ) : (
                  <SuperPayNudgingBanner source="Load List" />
                )
              ) : null}

              {isExpeditedPayEnabled &&
              currentPayment?.is_expedited_pay_available ? (
                <SuperPayExpeditedPayNudging
                  onClick={() => {
                    onAction('expedite_payment');
                  }}
                />
              ) : (
                isSuperPayRequestShipperNudging && (
                  <SuperPayRequestShipperNudging
                    isSuperPay={currentPayment.method === 'superpay'}
                  />
                )
              )}
              {currentPayment && <LoadPaymentStatus payment={currentPayment} />}

              {!!currentPayment?.invoice.sent_at && (
                <div>
                  {currentPayment.invoice.errors.length ? (
                    currentPayment.invoice.errors.map((error) => (
                      <Typography color="error" key={error.channel}>
                        {error.message}
                      </Typography>
                    ))
                  ) : (
                    <>
                      Invoiced on{' '}
                      <em>
                        <FormattedDate
                          variant="DateTime"
                          date={currentPayment.invoice.sent_at}
                        />
                      </em>
                    </>
                  )}
                </div>
              )}
              {!!load.latest_internal_note && (
                <div>
                  <Typography color="textSecondary" variant="h6">
                    Last Note
                  </Typography>

                  <Typography>{load.latest_internal_note}</Typography>
                </div>
              )}
            </Stack>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
