import { Color } from '@superdispatch/ui';
import styled from 'styled-components';
import { LoadStatus } from '../../loads/data/LoadDTO';
import { formatLoadStatusCount, TripShortDTO } from '../data/TripsDTO';

export const TripLoadStatusDot = styled.span<{ status: string }>`
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 8px;
  background-color: ${({ status }) =>
    status === 'new'
      ? Color.Blue200
      : status === 'picked_up'
      ? Color.Yellow300
      : Color.Green300};
`;

interface TripStatusProps {
  title: LoadStatus;
  count: number;
  total: number;
}

export function TripStatus({ title, count, total }: TripStatusProps) {
  return (
    <div key={title}>
      <TripLoadStatusDot status={title} />
      {formatLoadStatusCount(title, count, total)}
    </div>
  );
}

interface TripStatusesProps {
  loads: TripShortDTO['loads'];
}

export function TripStatuses({ loads }: TripStatusesProps) {
  if (loads.total === null) {
    return null;
  }

  return (
    <>
      {loads.new && loads.new > 0 ? (
        <TripStatus title="new" count={loads.new} total={loads.total} />
      ) : null}

      {loads.picked_up && loads.picked_up > 0 ? (
        <TripStatus
          title="picked_up"
          count={loads.picked_up}
          total={loads.total}
        />
      ) : null}

      {loads.delivered && loads.delivered > 0 ? (
        <TripStatus
          title="delivered"
          count={loads.delivered}
          total={loads.total}
        />
      ) : null}
    </>
  );
}
