import { THIRTY_SECONDS } from 'shared/constants/NumberConstants';
import {
  BaseResourceFieldProps,
  ResourceField,
} from 'shared/form/ResourceField';
import { TerminalDTO } from './data/TerminalDTO';
import { useTerminal, useTerminalsList } from './data/TerminalsAPI';

export function TerminalField(props: BaseResourceFieldProps<TerminalDTO>) {
  const useOptions = (q: string) =>
    useTerminalsList(
      { q, page_size: 20 },
      { keepPreviousData: true, staleTime: THIRTY_SECONDS },
    );

  const useSelectedOption = (guid: string) =>
    useTerminal(guid, {
      staleTime: THIRTY_SECONDS,
      refetchOnWindowFocus: false,
    });

  return (
    <ResourceField
      {...props}
      useOptions={useOptions}
      noOptionsText="No terminals found"
      useSelectedOption={useSelectedOption}
      getOptionKey={(option) => option.guid}
      getOptionLabel={(option) => option.name}
    />
  );
}
