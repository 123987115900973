import { useMemo } from 'react';
import { LocalStore, useLocalStore } from 'shared/helpers/Store';

export const LOGIN_VERIFICATION_EMAIL = 'login_verification_email';
export const LOGIN_VERIFICATION_PHONE_NUMBER =
  'login_verification_phone_number';
export const LOGIN_VERIFICATION_CODE_CHANNEL =
  'login_verification_code_channel';

export function useVerification() {
  const verificationChannel = useLocalStore(
    LOGIN_VERIFICATION_CODE_CHANNEL,
    'email',
  );
  const email = useLocalStore(LOGIN_VERIFICATION_EMAIL);
  const phoneNumber = useLocalStore(LOGIN_VERIFICATION_PHONE_NUMBER);

  return useMemo(() => {
    //email
    function saveVerificationEmail(mail: string) {
      LocalStore.set(LOGIN_VERIFICATION_EMAIL, mail);
    }
    function removeVerification() {
      LocalStore.delete(LOGIN_VERIFICATION_EMAIL);
      LocalStore.delete(LOGIN_VERIFICATION_CODE_CHANNEL);
      LocalStore.delete(LOGIN_VERIFICATION_PHONE_NUMBER);
    }

    //phone
    function saveVerificationPhoneNumber(phone: string) {
      LocalStore.set(LOGIN_VERIFICATION_PHONE_NUMBER, phone);
    }

    //channel
    function saveVerificationCodeChannel(channel: 'sms' | 'email') {
      LocalStore.set(LOGIN_VERIFICATION_CODE_CHANNEL, channel);
    }

    return {
      email,
      phoneNumber,
      verificationChannel,
      saveVerificationEmail,
      saveVerificationPhoneNumber,
      saveVerificationCodeChannel,
      removeVerification,
    };
  }, [email, phoneNumber, verificationChannel]);
}
