import { AvatarButton, AvatarButtonProps } from '@superdispatch/ui';
import { useMemo } from 'react';
import { getInitials } from 'shared/utils/TextUtils';

export function ShipperAvatar({
  name,
  logo,
  ...props
}: Omit<AvatarButtonProps, 'src'> & { name: string; logo: string | null }) {
  const shipperNameInitials = useMemo(() => name && getInitials(name), [name]);

  return (
    <AvatarButton {...props} src={logo || undefined}>
      {shipperNameInitials}
    </AvatarButton>
  );
}
