import { Color } from '@superdispatch/ui';
import { createSvgIcon } from 'shared/icons/IconUtils';

export const NewMessageIcon = createSvgIcon(
  'New Message',
  <>
    <path
      d="M4.479 13.7503H17.0832V6.47949C17.3164 6.41005 17.5345 6.3163 17.7373 6.19824C17.9401 6.08019 18.1387 5.94477 18.3332 5.79199V13.7503C18.3332 14.0698 18.2047 14.358 17.9478 14.6149C17.6908 14.8719 17.4026 15.0003 17.0832 15.0003H4.99984L1.6665 18.3337V2.91699C1.6665 2.59755 1.7915 2.30935 2.0415 2.05241C2.2915 1.79546 2.58317 1.66699 2.9165 1.66699H12.0207C11.9651 1.86144 11.9234 2.0663 11.8957 2.28158C11.8679 2.49685 11.8609 2.70866 11.8748 2.91699H2.9165V15.3128L4.479 13.7503ZM4.99984 11.6878H11.5207V10.4378H4.99984V11.6878ZM4.99984 8.97949H14.9998V7.72949H4.99984V8.97949ZM4.99984 6.27116H14.0623C13.7707 6.11838 13.5033 5.93783 13.2603 5.72949C13.0172 5.52116 12.7984 5.28505 12.604 5.02116H4.99984V6.27116Z"
      fill={Color.Dark200}
    />
    <path
      d="M14.2082 4.35171C14.6526 4.79779 15.1935 5.02083 15.8307 5.02083C16.468 5.02083 17.0096 4.79861 17.4557 4.35417C17.9018 3.90972 18.1248 3.36888 18.1248 2.73163C18.1248 2.09436 17.9026 1.55269 17.4582 1.10662C17.0137 0.660542 16.4729 0.4375 15.8356 0.4375C15.1984 0.4375 14.6567 0.659722 14.2106 1.10417C13.7645 1.54861 13.5415 2.08946 13.5415 2.72671C13.5415 3.36397 13.7637 3.90564 14.2082 4.35171Z"
      fill={Color.Red300}
    />
  </>,
  {
    viewBox: '0 0 20 20',
  },
);
