import {
  yupArray,
  yupBoolean,
  yupDateString,
  yupEnum,
  yupNumber,
  yupObject,
  yupString,
} from 'shared/utils/YupUtils';
import { InferType } from 'yup';

export type TrackingAppStatusDTO = InferType<typeof trackingAppStatusSchema>;
export const trackingAppStatusSchema = yupObject({
  status: yupBoolean(),
  app_status: yupBoolean(),
  system_status: yupBoolean(),
});

export type TrackingLocationDTO = InferType<typeof trackingLocationSchema>;
export const trackingLocationSchema = yupObject({
  latitude: yupNumber().nullable(false),
  longitude: yupNumber().nullable(false),
  time: yupDateString('DateTimeISO'),
});

export type TrackingNotificationDTO = InferType<
  typeof trackingNotificationSchema
>;
export const trackingNotificationSchema = yupObject({
  delivered: yupBoolean(),
  id: yupNumber(),
  result: yupEnum(['unknown', 'accepted', 'ignored'], 'unknown'),
  type: yupEnum([
    'activate_geolocation',
    'activate_tracking',
    'enabled',
    'disabled',
  ]),
});

export function getTrackingDriverStatus(driver: TrackingDriverDTO) {
  return getTrackingAppStatus(driver.app_status);
}

export function getTrackingAppStatus(
  appStatus: TrackingDriverDTO['app_status'],
):
  | 'no_status'
  | 'geolocation_inactive'
  | 'tracking_inactive'
  | 'tracking_active' {
  if (!appStatus) {
    return 'no_status';
  }

  if (!appStatus.app_status || !appStatus.system_status) {
    return 'geolocation_inactive';
  }

  if (!appStatus.status) {
    return 'tracking_inactive';
  }

  return 'tracking_active';
}

export type TrackingDriverDTO = InferType<typeof trackingDriverSchema>;
export const trackingDriverSchema = yupObject({
  id: yupNumber().nullable(false),
  name: yupString().nullable(),
  email: yupString().nullable(),
  disabled: yupBoolean(),
  device_name: yupString(),
  last_activity: yupDateString('DateTimeISO'),

  app_status: trackingAppStatusSchema.nullable(),
  last_location: trackingLocationSchema.nullable(),
  notification: trackingNotificationSchema.nullable(),
});

export type TrackingDTO = InferType<typeof trackingSchema>;
export const trackingSchema = yupObject({
  count: yupNumber(),
  next: yupString(),
  previous: yupString(),

  results: yupArray(trackingDriverSchema),
});

export type TrackingWebSocketMessageDTO = InferType<
  typeof trackingWebSocketMessageSchema
>;
export const trackingWebSocketMessageSchema = yupObject({
  device_name: yupString(),
  driver: yupNumber(),
  location: trackingLocationSchema.nullable().default(null),
  app_status: trackingAppStatusSchema.nullable().default(null),
});
