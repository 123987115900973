import { FormikContextTypeEnhanced } from '@superdispatch/forms';
import { useUID } from '@superdispatch/ui';
import { Box, Button } from '@superdispatch/ui-lab';
import { Form, FormikProvider, FormikValues } from 'formik';
import { ReactNode } from 'react';
import { PageHeader, PageLayout } from 'shared/layout/PageLayout';
import styled from 'styled-components';

const CenterBox = styled(Box)`
  justify-content: center;
`;

export interface SettingsPageLayoutProps {
  loading?: boolean;
  title?: ReactNode;
  titleID?: string;
  actions?: ReactNode;
  children?: ReactNode;
}

export function SettingsPageLayout({
  title,
  titleID,
  actions,
  loading,
  children,
}: SettingsPageLayoutProps) {
  return (
    <PageLayout
      loading={loading}
      header={
        <PageHeader title={title} titleID={titleID} endActions={actions} />
      }
    >
      <CenterBox display="flex">{children}</CenterBox>
    </PageLayout>
  );
}

export interface SettingsPageFormProps {
  loading?: boolean;
  formik: FormikContextTypeEnhanced<FormikValues, unknown>;
  title?: ReactNode;
  children?: ReactNode;
}

export function SettingsPageForm({
  title,
  formik,
  loading,
  children,
}: SettingsPageFormProps) {
  const titleID = useUID();
  const isLoading = loading || formik.isSubmitting;

  return (
    <FormikProvider value={formik}>
      <Form aria-labelledby={titleID}>
        <SettingsPageLayout
          title={title}
          titleID={titleID}
          loading={isLoading}
          actions={
            <Button type="submit" pending={isLoading} disabled={!formik.dirty}>
              Save
            </Button>
          }
        >
          <Box maxWidth="512px" width="100%">
            {children}
          </Box>
        </SettingsPageLayout>
      </Form>
    </FormikProvider>
  );
}
