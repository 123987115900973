import { trackEvent } from 'shared/helpers/Analytics';

export interface DashboardAnalyticsEvent {
  name:
    | 'CTMS: Opened Dashboard Page'
    | 'CTMS: Viewed Aging Loads'
    | 'CTMS: Viewed Delayed Pickup Loads'
    | 'CTMS: Viewed Delayed Delivery Loads'
    | 'CTMS: Viewed To Pick Up Today'
    | 'CTMS: Viewed To Deliver Today';
}

export function trackDashboardEvent(event: DashboardAnalyticsEvent) {
  trackEvent(event.name);
}
