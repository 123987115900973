import { Typography } from '@material-ui/core';
import { Inline, Stack } from '@superdispatch/ui';
import { Box, Button } from '@superdispatch/ui-lab';
import { useState } from 'react';
import { DispatcherDTO } from 'shared/modules/dispatcher/DispatcherDTO';
import styled from 'styled-components';
import invitationIcon from '../assets/invitation-icon.svg';
import { DispatcherInviteCancelDialog } from '../dispatcher-invitation/DispatcherInvitationCancelDialog';

interface InvitedDispatcherProps {
  dispatcher: DispatcherDTO;
}

const CenterBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export function InvitedDispatcher({ dispatcher }: InvitedDispatcherProps) {
  const [isOpen, setIsOpen] = useState(false);

  function handleClose() {
    setIsOpen(false);
  }

  return (
    <>
      <DispatcherInviteCancelDialog
        dispatcher={dispatcher}
        open={isOpen}
        onClose={handleClose}
      />
      <CenterBox height="80vh" width="100%">
        <Inline verticalAlign="center" horizontalAlign="center">
          <Box maxWidth="400px">
            <Stack align="center" space="medium">
              <Box>
                <img
                  width="270px"
                  height="100px"
                  alt="invitationIcon"
                  src={invitationIcon}
                />
              </Box>
              <Stack align="center" space="xsmall">
                <Typography align="center" variant="h3">
                  Waiting for Confirmation
                </Typography>
                <Typography align="center" color="textSecondary">
                  An email notification has been sent to{' '}
                  <b>{dispatcher.email}</b> to review your invite.
                </Typography>
                <Stack>
                  <Typography align="center" color="textSecondary">
                    This invitation expires in 3 days.
                  </Typography>
                </Stack>
              </Stack>

              <Button
                onClick={() => {
                  setIsOpen(true);
                }}
                variant="text"
              >
                Cancel Invite
              </Button>
            </Stack>
          </Box>
        </Inline>
      </CenterBox>
    </>
  );
}
