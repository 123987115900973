import { createSvgIcon } from 'shared/icons/IconUtils';

export const NotesIcon = createSvgIcon(
  'NotesIcon',
  <path
    d="M15.5 3.5H12.1037C11.7625 2.5575 10.8687 1.875 9.8125 1.875C8.75625 1.875 7.8625 2.5575 7.52125 3.5H4.125C3.23125 3.5 2.5 4.23125 2.5 5.125V16.5C2.5 17.3938 3.23125 18.125 4.125 18.125H15.5C16.3938 18.125 17.125 17.3938 17.125 16.5V5.125C17.125 4.23125 16.3938 3.5 15.5 3.5ZM9.8125 3.5C10.2594 3.5 10.625 3.86563 10.625 4.3125C10.625 4.75938 10.2594 5.125 9.8125 5.125C9.36562 5.125 9 4.75938 9 4.3125C9 3.86563 9.36562 3.5 9.8125 3.5ZM11.4375 14.875H5.75V13.25H11.4375V14.875ZM13.875 11.625H5.75V10H13.875V11.625ZM13.875 8.375H5.75V6.75H13.875V8.375Z"
    fill="currentColor"
  />,
  { viewBox: '0 0 20 20', style: { height: '20px', width: '20px' } },
);
