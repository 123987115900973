import { Drawer } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import {
  DrawerActions,
  DrawerContent,
  DrawerTitle,
  Stack,
} from '@superdispatch/ui';
import { Box, TextBox } from '@superdispatch/ui-lab';

export function DriverInviteDrawerLoading({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) {
  return (
    <Drawer open={open} onClose={onClose}>
      <DrawerTitle title={<Skeleton />} />
      <DrawerContent title="Invite Driver">
        <Stack space="large">
          <Stack space="small">
            <Box width="368px">
              <TextBox color="secondary">
                <Skeleton width="100%" />
                <Skeleton width="80%" />
                <Skeleton width="65%" />
              </TextBox>
            </Box>

            <Skeleton height="60px" />
            <Skeleton height="60px" />
            <Skeleton height="60px" />
          </Stack>
        </Stack>
      </DrawerContent>
      <DrawerActions>
        <Skeleton width="150px" height="60px" />
      </DrawerActions>
    </Drawer>
  );
}
