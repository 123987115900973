import { Tooltip } from '@material-ui/core';
import { Color, Inline } from '@superdispatch/ui';
import { DriveawayIcon } from 'shared/icons/DriveawayIcon';
import { EnclosedTrailerIcon } from 'shared/icons/EnclosedTrailerIcon';
import { InoperableIcon } from 'shared/icons/InoperableIcon';
import { LoadSuggestionDTO } from '../data/LoadSuggestionDTO';

export function LoadSuggestionsVehicleConditionIcon({
  transportType,
  isInoperable,
}: {
  transportType?: LoadSuggestionDTO['transport_type'];
  isInoperable: boolean;
}) {
  return (
    <Inline>
      {transportType === 'ENCLOSED' && (
        <Tooltip title="Enclosed Trailer Required" enterDelay={800}>
          <EnclosedTrailerIcon
            htmlColor={Color.Yellow300}
            aria-label="Enclosed Trailer Required icon"
          />
        </Tooltip>
      )}

      {isInoperable && (
        <Tooltip title="Inoperable Vehicle" enterDelay={800}>
          <InoperableIcon
            htmlColor={Color.Red300}
            aria-label="Inoperable Vehicle icon"
          />
        </Tooltip>
      )}

      {transportType === 'DRIVEAWAY' && (
        <Tooltip title="Driveaway" enterDelay={800}>
          <DriveawayIcon
            htmlColor={Color.Purple300}
            aria-label="Driveaway icon"
          />
        </Tooltip>
      )}
    </Inline>
  );
}
