import { GeocodeQueryType } from '@mapbox/mapbox-sdk/services/geocoding';
import { useMemo } from 'react';
import { useMapboxReversePredictions } from 'shared/geo/Mapbox';
import { parseGeocodeFeature } from 'shared/geo/MapboxHelpers';

const types = ['place', 'region', 'postcode', 'address'] as GeocodeQueryType[];

export function useAddressPrediction(
  latLng: [number, number] | undefined,
  enabled = true,
) {
  const { data: predictions } = useMapboxReversePredictions(latLng, types, {
    enabled: latLng && enabled,
  });

  return useMemo(() => {
    const feature = predictions?.features[0];
    if (!feature) {
      return undefined;
    }

    const geocodedFeature = parseGeocodeFeature(feature);
    return geocodedFeature.place_name;
  }, [predictions]);
}
