import { Inline, useResponsiveValue } from '@superdispatch/ui';
import { TextBox } from '@superdispatch/ui-lab';
import React from 'react';
import { DotGridPattern } from 'shared/icons/DotGridPattern';
import { HeaderWithLogo } from 'shared/layout/HeaderWithLogo';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Content = styled.div`
  flex: 1;
`;

const Pattern = styled.div`
  position: relative;
  top: 48px;
  height: 88px;
  max-width: 560px;
  margin: auto;

  svg {
    width: 100%;
    height: 4px;
    margin-bottom: 14px;
    display: block;
  }
`;

interface Props {
  children: React.ReactNode;
  name?: string;
  footerAction?: React.ReactNode;
  header?: React.ReactNode;
  showDot?: boolean;
}

export function LoginLayout({
  children,
  name,
  footerAction,
  header,
  showDot = true,
}: Props) {
  const isMobile = useResponsiveValue(true, false);

  return (
    <Wrapper>
      <HeaderWithLogo
        actions={
          <Inline space="medium" verticalAlign="center">
            {name && (
              <TextBox variant="heading-5" color="secondary">
                {name}
              </TextBox>
            )}
            {header}
          </Inline>
        }
      />
      <Content>
        {!isMobile && showDot && (
          <Pattern>
            <DotGridPattern />
            <DotGridPattern />
            <DotGridPattern />
            <DotGridPattern />
            <DotGridPattern />
            <DotGridPattern />
            <DotGridPattern />
            <DotGridPattern />
          </Pattern>
        )}

        {children}
      </Content>
      {footerAction && footerAction}
    </Wrapper>
  );
}
