import { Card, CardContent, Typography } from '@material-ui/core';
import { formatDate } from '@superdispatch/dates';
import { useFormikEnhanced } from '@superdispatch/forms';
import {
  Column,
  Columns,
  Inline,
  Stack,
  Tag,
  useSnackbarStack,
} from '@superdispatch/ui';
import { Button } from '@superdispatch/ui-lab';
import { Form, FormikProvider } from 'formik';
import { useState } from 'react';
import { connectToQBO, loadIntuitScript } from 'shared/helpers/QuickbooksWeb';
import { QuickbooksOnlineIcon } from 'shared/icons/QuickbooksOnlineIcon';
import { WebIcon } from 'shared/icons/WebIcon';
import {
  useDisconnectQboMutation,
  useImportQBOContacts,
  useQBOGrantURL,
  useQuickbooksOnlineConnectionInfo,
  useQuickbooksOnlineServiceList,
  useQuickbooksOnlineTaxes,
} from '../data/SettingsAPI';
import { QBOTaxDTO } from '../data/SettingsDTO';
import { findServiceId, updateDateToString } from '../data/SettingsUtils';
import { SettingsDisconnectQuickbooksDialog } from './SettingsDisconnectQuickbooksDialog';
import { SettingsQuickbookOnlineContactForm } from './SettingsQuickbookOnlineContactForm';

export function SettingsQuickbookOnline() {
  const [isOpen, setOpen] = useState(false);
  const { addSnackbar } = useSnackbarStack();

  useQBOGrantURL({
    onSuccess: (res) => {
      loadIntuitScript(res.grant_url);
    },
  });
  const { mutate: importContacts, isLoading: isImportLoading } =
    useImportQBOContacts({
      onSuccess: (data) => {
        addSnackbar(data.user_message);
      },
    });
  const { mutate: disconnectQbo, isLoading } = useDisconnectQboMutation({
    onSuccess: () => {
      onClose();
    },
  });
  const { data: services } = useQuickbooksOnlineServiceList();
  const { data } = useQuickbooksOnlineConnectionInfo();
  const { data: taxes } = useQuickbooksOnlineTaxes();

  const lastImport = updateDateToString(data?.customers_updated_at);

  const formik = useFormikEnhanced({
    enableReinitialize: true,
    initialValues: {
      service: findServiceId(services, data?.invoice_item),
      create_customers_in_qbo: data?.is_allowed_to_create_customer,
      tax: getTaxId(taxes, data?.tax),
    },
    onSubmit: () => {
      importContacts();
    },
  });

  function handleOpen() {
    setOpen(true);
  }

  function onClose() {
    setOpen(false);
  }

  return (
    <>
      <FormikProvider value={formik}>
        <Form>
          <Card>
            <CardContent>
              <Stack space="small">
                <Columns space="small">
                  <Column width="fluid">
                    <Stack space={data?.connected ? 'xxsmall' : 'xsmall'}>
                      <Inline space="xsmall">
                        <WebIcon />
                        <Typography variant="h4">QuickBooks Online</Typography>
                      </Inline>
                      {data?.connected ? (
                        <Inline space="xxsmall" verticalAlign="center">
                          <Tag color="green" variant="subtle">
                            Connected
                          </Tag>
                          <Typography color="textSecondary">
                            on{' '}
                            {formatDate(data.connected_at, {
                              variant: 'Date',
                            })}
                          </Typography>
                        </Inline>
                      ) : (
                        <Typography>
                          Connect to any QuickBooks Online plan. You will be
                          redirected to log in to your QuickBooks and connect to
                          Super Dispatch.
                        </Typography>
                      )}
                    </Stack>
                  </Column>
                  <Column width="content">
                    {data?.connected ? (
                      <Button onClick={handleOpen} variant="critical">
                        Disconnect
                      </Button>
                    ) : (
                      <Button
                        onClick={connectToQBO}
                        startIcon={<QuickbooksOnlineIcon />}
                        variant="success"
                      >
                        Connect to QuickBooks
                      </Button>
                    )}
                  </Column>
                </Columns>

                {data?.connected && (
                  <SettingsQuickbookOnlineContactForm
                    isLoading={isImportLoading}
                    lastImport={lastImport}
                    isUSCompany={Boolean(data.is_us_company)}
                  />
                )}
              </Stack>
            </CardContent>
          </Card>
        </Form>
      </FormikProvider>
      <SettingsDisconnectQuickbooksDialog
        isLoading={isLoading}
        open={isOpen}
        type="Online"
        onClose={onClose}
        onDisconnect={disconnectQbo}
      />
    </>
  );
}

function getTaxId(taxes?: QBOTaxDTO[], tax?: string) {
  if (taxes?.length) {
    const taxId = taxes.find((t) => t.name === tax)?.id;
    return taxId;
  }
  return '';
}
