import { TextBox } from '@superdispatch/ui-lab';
import { formatAddressLine } from 'shared/helpers/AddressHelpers';
import { StepDTO } from 'shared/modules/load-suggestions/data/LoadSuggestionDTO';
import { LoadWaypoints } from 'shared/ui/LoadWaypoints';
import { LoadSuggestionsAvailableDate } from './LoadSuggestionsAvailableDate';

interface LoadSuggestionsVenuesProps {
  pickup: StepDTO;
  delivery: StepDTO;
}

export function LoadSuggestionsVenues({
  pickup,
  delivery,
}: LoadSuggestionsVenuesProps) {
  return (
    <LoadWaypoints
      dense={true}
      space="small"
      inline={true}
      pickupAddress={
        <TextBox>
          {formatAddressLine(
            pickup.venue.zip,
            pickup.venue.city,
            pickup.venue.state,
          )}
        </TextBox>
      }
      pickupBusinessType={pickup.business_type}
      pickupDate={
        <LoadSuggestionsAvailableDate scheduledAt={pickup.scheduled_at} />
      }
      deliveryAddress={
        <TextBox>
          {formatAddressLine(
            delivery.venue.zip,
            delivery.venue.city,
            delivery.venue.state,
          )}
        </TextBox>
      }
      deliveryBusinessType={delivery.business_type}
    />
  );
}
