import { MenuItem, Typography } from '@material-ui/core';
import { FormikCheckboxField, FormikTextField } from '@superdispatch/forms';
import { Column, Columns, Stack } from '@superdispatch/ui';
import { Box, Button } from '@superdispatch/ui-lab';
import {
  useQBCompanyMutation,
  useQBUpdateTaxMutation,
  useQuickbooksOnlineServiceList,
  useQuickbooksOnlineTaxes,
  useUpdateServiceItem,
} from '../data/SettingsAPI';

interface SettingsQuickbookOnlineContactFormProps {
  isLoading: boolean;
  isUSCompany: boolean;
  lastImport: string | null;
}

export function SettingsQuickbookOnlineContactForm({
  isLoading,
  lastImport,
  isUSCompany,
}: SettingsQuickbookOnlineContactFormProps) {
  const { data: services } = useQuickbooksOnlineServiceList();
  const { data: taxes } = useQuickbooksOnlineTaxes();
  const { mutate: updateServiceItem } = useUpdateServiceItem();
  const { mutate: updateCompany } = useQBCompanyMutation();
  const { mutate: updateTax } = useQBUpdateTaxMutation();

  return (
    <Stack space="large">
      <Stack space="small">
        <Stack space="xsmall">
          <Typography variant="h5">
            How to send invoice to QuickBooks?
          </Typography>
          <Typography>
            Chose the service item you want below. Go to the Loads page, click
            Options → Send Invoice. Select the QuickBooks Online option. After
            filling all required information click Send Invoice.
          </Typography>
        </Stack>

        <Columns>
          <Column width="2/5">
            <FormikTextField
              label="Service Item"
              aria-label="service-list-input"
              name="service"
              helperText={
                <Typography color="textSecondary" variant="caption">
                  For invoice items and syncing them to QuickBooks
                </Typography>
              }
              select={true}
              onChange={(event) => {
                updateServiceItem(Number(event.target.value));
              }}
            >
              {services?.map((service) => (
                <MenuItem key={service.id} value={Number(service.id)}>
                  {service.name}
                </MenuItem>
              ))}
            </FormikTextField>
          </Column>
        </Columns>

        {!isUSCompany && !!taxes?.length && (
          <Columns>
            <Column width="2/5">
              <FormikTextField
                label="Tax"
                aria-label="tax-list-input"
                name="tax"
                fullWidth={true}
                select={true}
                onChange={(event) => {
                  updateTax(event.target.value);
                }}
              >
                {taxes.map((service) => (
                  <MenuItem key={service.id} value={Number(service.id)}>
                    {service.name}
                  </MenuItem>
                ))}
              </FormikTextField>
            </Column>
          </Columns>
        )}
      </Stack>

      <Stack space="small">
        <Stack space="xxsmall">
          <Button pending={isLoading} type="submit" variant="neutral">
            Import Contacts from QuickBooks
          </Button>
          <Typography color="textSecondary" variant="caption">
            Last import was {lastImport}
          </Typography>
        </Stack>
        <Stack space="none">
          <FormikCheckboxField
            name="create_customers_in_qbo"
            label="Automatically create customers inside QuickBooks Online"
            onChange={(event) => {
              updateCompany(event.target.checked);
            }}
          />
          <Box paddingLeft="large">
            <Typography variant="caption" color="textSecondary">
              A new contact will be created if there is no the same customer
              name in QuickBooks Online
            </Typography>
          </Box>
        </Stack>
      </Stack>
    </Stack>
  );
}
