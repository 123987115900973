import { InputAdornment } from '@material-ui/core';
import {
  DateRangeField,
  DateRangeFieldProps,
  NullableDateRangeInput,
  toPrimitiveDateRangeInput,
} from '@superdispatch/dates';
import { forwardRef, ReactNode, useEffect, useState } from 'react';

interface Props extends DateRangeFieldProps {
  label: ReactNode;
}

export const InlineDateRangeField = forwardRef<HTMLDivElement, Props>(
  ({ label, value: input, onChange, ...other }, ref) => {
    const [start, finish] = toPrimitiveDateRangeInput(input);
    const [value, setValue] = useState<NullableDateRangeInput>([start, finish]);

    useEffect(() => {
      setValue([start, finish]);
    }, [start, finish]);

    return (
      <DateRangeField
        {...other}
        ref={ref}
        value={value}
        onChange={(payload) => {
          const {
            stringValue: [nextStart, nextFinish],
          } = payload;

          if (!nextStart || !nextFinish) {
            setValue([nextStart, nextFinish]);
          } else {
            onChange?.(payload);
          }
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">{label}</InputAdornment>
          ),
        }}
      />
    );
  },
);

InlineDateRangeField.displayName = 'InlineDateRangeField';
