import { Card, CardContent, Typography } from '@material-ui/core';
import { Business } from '@material-ui/icons';
import { Inline, Stack, useUID } from '@superdispatch/ui';
import { FormikProvider } from 'formik';
import { useAppFormik } from 'shared/form/AppFormik';
import { useFormikComposerForm } from 'shared/form/FormikComposer';
import { LoadCustomerForm } from '../core/LoadCustomerForm';
import { loadCustomerEditSchema } from '../data/LoadActionsDTO';
import { LoadDTO } from '../data/LoadDTO';
import { useLoadsAPI } from '../data/LoadsAPI';

export interface EditLoadPageCustomerProps {
  load: LoadDTO;
}

export function EditLoadPageCustomer({ load }: EditLoadPageCustomerProps) {
  const { editLoadCustomer } = useLoadsAPI();
  const uid = useUID();
  const formik = useAppFormik({
    initialValues: load.customer,
    validationSchema: loadCustomerEditSchema,
    onSubmit(values) {
      return editLoadCustomer(load.guid, values);
    },
  });

  const nodeRef = useFormikComposerForm('customer', formik);

  return (
    <FormikProvider value={formik}>
      <Card ref={nodeRef} aria-labelledby={uid}>
        <CardContent aria-label="Customer Card">
          <Stack space="small">
            <Inline verticalAlign="center" space="xsmall">
              <Business />
              <Typography variant="h3" id={uid}>
                Customer
              </Typography>
            </Inline>

            <LoadCustomerForm disabled={!load.can_edit_customer_information} />
          </Stack>
        </CardContent>
      </Card>
    </FormikProvider>
  );
}
