import { Tag } from '@superdispatch/ui';
import { LoadVehicleDTO } from '../data/LoadDTO';

export function LoadVehicleStatusTag({
  status,
}: {
  status: LoadVehicleDTO['status'];
}) {
  switch (status) {
    case 'not_ready':
      return (
        <Tag variant="subtle" color="yellow">
          Not Ready
        </Tag>
      );
    case 'canceled':
      return (
        <Tag variant="subtle" color="red">
          Canceled
        </Tag>
      );
    default:
      return null;
  }
}
