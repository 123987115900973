import { Link, Tooltip } from '@material-ui/core';
import { TextBox } from '@superdispatch/ui-lab';
import { ReactElement } from 'react';
import styled from 'styled-components';

const List = styled.ul`
  margin: 0;
  padding-left: 12px;
`;

function joinDrivers(...drivers: string[]): ReactElement {
  if (drivers.length === 1) {
    return <TextBox variant="heading-5">{drivers[0]}</TextBox>;
  }

  if (drivers.length <= 4) {
    const last = drivers.pop();
    return (
      <>
        <TextBox variant="heading-5">{drivers.join(', ')}</TextBox>{' '}
        <TextBox variant="heading-5" color="secondary">
          and
        </TextBox>{' '}
        <TextBox variant="heading-5">{last}</TextBox>
      </>
    );
  }

  return (
    <>
      <TextBox variant="heading-5">{drivers.slice(0, 3).join(', ')}</TextBox>{' '}
      <TextBox variant="heading-5" color="secondary">
        and
      </TextBox>{' '}
      <Tooltip
        title={
          <List>
            {drivers.slice(3, 5).map((name) => (
              <li key={name}>{name}</li>
            ))}

            {drivers.length > 5 && <span>and more</span>}
          </List>
        }
      >
        <Link href="#" variant="h5" color="textPrimary">
          {drivers.length - 3} others
        </Link>
      </Tooltip>
    </>
  );
}

export function LoadSuggestionsActivity({
  driverNames,
}: {
  driverNames: string[];
}) {
  if (driverNames.length === 0) {
    return (
      <TextBox
        variant="heading-5"
        color="secondary"
        data-intercom-target="load-suggestions-activity"
      >
        Based on your recent activity
      </TextBox>
    );
  }

  return (
    <TextBox data-intercom-target="load-suggestions-activity">
      For {joinDrivers(...driverNames)}
    </TextBox>
  );
}
