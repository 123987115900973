import { useMemo } from 'react';
import { APIResponse, requestCarrierAPI } from 'shared/api/APIClient';
import {
  APIMutationOptions,
  APIMutationResult,
  useAPIMutation,
} from 'shared/api/APIMutation';
import { APIQueryOptions, useAPIQuery } from 'shared/api/APIQuery';
import { VerificationResponse } from 'shared/auth/data/AuthAPI';
import {
  LoginDTO,
  ResendVerificationCodeDTO,
  VerifyAccountDTO,
} from 'shared/auth/data/AuthDTO';
import { DriverAccountDTO, DriverAuthDTO } from './DriverAccountDTO';

export function useDriverAccountAuthAPI() {
  return useMemo(() => {
    function driverLogin(json: LoginDTO) {
      return requestCarrierAPI<APIResponse<DriverAuthDTO>>(
        'POST /internal/mobile/v1/web/login/',
        {
          json,
        },
      );
    }

    function resendDriverVerificationCode(json: ResendVerificationCodeDTO) {
      return requestCarrierAPI(
        'POST /internal/mobile/v1/web/verification/resend-code/',
        {
          json,
        },
      );
    }

    function checkDriverVerificationCode(json: VerifyAccountDTO) {
      return requestCarrierAPI<VerificationResponse>(
        'POST /internal/mobile/v1/web/verification/check-code/',
        {
          json,
        },
      );
    }

    return {
      driverLogin,
      checkDriverVerificationCode,
      resendDriverVerificationCode,
    };
  }, []);
}

export function useDriverResendVerificationCode(
  options?: APIMutationOptions<ResendVerificationCodeDTO>,
): APIMutationResult<ResendVerificationCodeDTO> {
  const { resendDriverVerificationCode } = useDriverAccountAuthAPI();
  return useAPIMutation((json) => resendDriverVerificationCode(json), options);
}

export function useDriverAccountDetails(
  options?: APIQueryOptions<DriverAccountDTO>,
) {
  return useAPIQuery(
    ['driver-account', 'detail'],
    () =>
      requestCarrierAPI<APIResponse<DriverAccountDTO>>(
        'GET /internal/mobile/v1/web/driver/',
      ),
    options,
  );
}
