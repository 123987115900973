import { ReactElement } from 'react';
import { getDriverAccountToken } from 'shared/auth/AuthToken';
import { Navigate } from 'shared/routing/react-router-6';

interface DriverAccountWrapperProps {
  children: ReactElement;
}

export function DriverAccountWrapper({ children }: DriverAccountWrapperProps) {
  const token = getDriverAccountToken();

  if (!token) {
    return <Navigate to="/driver-login" />;
  }

  return children;
}
