import { Card, CardContent, Typography } from '@material-ui/core';
import { AttachMoney } from '@material-ui/icons';
import { Inline, Stack } from '@superdispatch/ui';
import { useFormikContext } from 'formik';
import { useMemo } from 'react';
import { computePrice } from 'shared/utils/NumberUtils';
import { useGeneralSettings } from '../../settings/data/SettingsAPI';
import { LoadPaymentForm } from '../core/LoadPaymentForm';
import { AddLoadDTO } from '../data/LoadDTO';

export function AddLoadPayment() {
  const { data: settings } = useGeneralSettings();
  const { values } = useFormikContext<AddLoadDTO>();

  const vehiclesTotalPrice = useMemo(
    () => computePrice(values.vehicles),
    [values.vehicles],
  );

  return (
    <Card aria-label="Payment Card">
      <CardContent>
        <Stack space="small">
          <Inline verticalAlign="center" space="xsmall" noWrap={true}>
            <AttachMoney />
            <Typography variant="h3">Payment</Typography>
          </Inline>

          <LoadPaymentForm
            name="payments[0]"
            vehiclesTotalPrice={vehiclesTotalPrice}
            hasInitialLoadID={!!settings?.initial_load_id}
          />
        </Stack>
      </CardContent>
    </Card>
  );
}
