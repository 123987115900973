import { Menu, MenuItem } from '@material-ui/core';
import { Cancel, MoreHoriz, PowerSettingsNew } from '@material-ui/icons';
import { Inline } from '@superdispatch/ui';
import { TextBox } from '@superdispatch/ui-lab';
import { MenuButton } from 'shared/ui/MenuButton';
import { DispatcherPageMutationDialogType } from './DispatchersPageDetailsHeader';

interface DispatcherDetailsMenuButtonProps {
  connectionStatus: 'deactivated' | 'activated';
  onSelect: (modal: DispatcherPageMutationDialogType) => void;
}

export function DispatcherDetailsMenuButton({
  connectionStatus,
  onSelect,
}: DispatcherDetailsMenuButtonProps) {
  return (
    <MenuButton
      aria-label="dispatcher options"
      renderMenu={({ onClose, ...menuProps }) => (
        <Menu {...menuProps} onClose={onClose}>
          {connectionStatus === 'deactivated' ? (
            <MenuItem
              key="delete"
              onClick={() => {
                onClose();
                onSelect('delete');
              }}
            >
              <TextBox color="red">
                <Inline noWrap={true} space="xxsmall" verticalAlign="center">
                  <Cancel fontSize="small" />
                  Delete
                </Inline>
              </TextBox>
            </MenuItem>
          ) : (
            <MenuItem
              onClick={() => {
                onClose();
                onSelect('deactivate');
              }}
            >
              <Inline noWrap={true} space="xxsmall" verticalAlign="center">
                <PowerSettingsNew fontSize="small" />
                Deactivate
              </Inline>
            </MenuItem>
          )}
        </Menu>
      )}
    >
      <MoreHoriz />
    </MenuButton>
  );
}
