import { createSvgIcon } from 'shared/icons/IconUtils';

export const DriverSidebarIcon = createSvgIcon(
  'DriverSideBar',
  <g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.5833 6.58333C16.5833 9.11563 14.5323 11.1667 12 11.1667C9.46771 11.1667 7.41667 9.11563 7.41667 6.58333C7.41667 4.05104 9.46771 2 12 2C14.5323 2 16.5833 4.05104 16.5833 6.58333ZM8.1682 13.942C5.16899 14.6132 2 15.9822 2 18.0417V19.3333C2 19.8856 2.44772 20.3333 3 20.3333H3.80035C4.10782 17.552 5.79797 15.1873 8.1682 13.942ZM21 20.3333H20.1996C19.8922 17.552 18.202 15.1873 15.8318 13.942C18.831 14.6132 22 15.9822 22 18.0417V19.3333C22 19.8856 21.5523 20.3333 21 20.3333ZM18.4167 21.25C18.4167 17.7062 15.5438 14.8333 12 14.8333C8.45618 14.8333 5.58334 17.7062 5.58334 21.25C5.58334 21.6148 5.89294 21.8917 6.25774 21.8917H17.7423C18.1071 21.8917 18.4167 21.6148 18.4167 21.25ZM14.473 19.6295C15.3217 20.1189 16.1704 20.6083 17.0936 20.6083C16.7779 18.0761 14.6178 16.1167 12 16.1167C9.38226 16.1167 7.22215 18.0761 6.90639 20.6083C7.82959 20.6083 8.67829 20.1189 9.52699 19.6295C10.0894 19.3052 10.6517 18.9809 11.2358 18.799C11.4772 18.7238 11.7339 18.6833 12 18.6833C12.2661 18.6833 12.5228 18.7238 12.7642 18.799C13.3483 18.9809 13.9107 19.3052 14.473 19.6295Z"
      fill="currentColor"
    />
  </g>,
);
