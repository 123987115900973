import { Card, CardContent } from '@material-ui/core';
import {
  FormikNumberField,
  FormikTextField,
  SuspendedFormikPhoneField,
} from '@superdispatch/forms';
import { Stack } from '@superdispatch/ui';
import { useAppFormik } from 'shared/form/AppFormik';
import {
  EMAIL_VALIDATION_PATTERN,
  validateString,
} from 'shared/helpers/ValidationHelpers';
import { DocumentTitle } from 'shared/layout/DocumentTitle';
import { SettingsPageForm } from './core/SettingsPageLayout';
import { useFactoringSettings, useSettingsAPI } from './data/SettingsAPI';
import { factoringSettingsSchema } from './data/SettingsDTO';

export function SettingsFactoringPage() {
  const { data, isLoading } = useFactoringSettings();
  const { createFactoringSettings, updateFactoringSettings } = useSettingsAPI();

  const formik = useAppFormik({
    initialValues: data,
    validationSchema: factoringSettingsSchema,
    onSubmit(values) {
      return data
        ? updateFactoringSettings(values)
        : createFactoringSettings(values);
    },
    getSuccessMessage() {
      return data
        ? 'Factoring Settings successfully updated'
        : 'Factoring Settings successfully created';
    },
  });

  return (
    <>
      <DocumentTitle title="Factoring Company - Settings" />

      <SettingsPageForm
        formik={formik}
        loading={isLoading}
        title="Factoring Company"
      >
        <Card aria-label="Factoring Settings Card">
          <CardContent>
            <Stack space="small">
              <div>
                Enter the details of your factoring company below to enable the
                option to factor your invoices
              </div>

              <FormikTextField
                autoFocus={true}
                validate={(value) =>
                  validateString(value, { required: true, maxLength: 200 })
                }
                name="name"
                fullWidth={true}
                label="Name*"
              />
              <FormikTextField
                validate={(value) => validateString(value, { required: true })}
                name="address"
                fullWidth={true}
                label="Address*"
                multiline={true}
                rows={3}
              />
              <FormikTextField
                validate={(value) =>
                  validateString(value, { required: true, maxLength: 100 })
                }
                name="city"
                fullWidth={true}
                label="City*"
                autoComplete="dsdfg"
              />
              <FormikTextField
                validate={(value) =>
                  validateString(value, { required: true, maxLength: 100 })
                }
                name="state"
                fullWidth={true}
                label="State*"
              />
              <FormikTextField
                validate={(value) =>
                  validateString(value, { required: true, maxLength: 10 })
                }
                name="zip"
                fullWidth={true}
                label="Zip*"
              />
              <FormikTextField
                validate={(value) =>
                  validateString(value, {
                    required: true,
                    maxLength: 200,
                    pattern: EMAIL_VALIDATION_PATTERN,
                  })
                }
                name="email"
                fullWidth={true}
                label="Email*"
              />
              <SuspendedFormikPhoneField
                validate={(value, phoneService) =>
                  phoneService.validate(value, { required: true })
                }
                name="phone_number"
                fullWidth={true}
                label="Phone Number*"
              />
              <FormikNumberField
                name="fee_in_percent"
                fullWidth={true}
                label="Factoring Fee (%)"
                inputProps={{
                  thousandSeparator: '',
                }}
              />
            </Stack>
          </CardContent>
        </Card>
      </SettingsPageForm>
    </>
  );
}
