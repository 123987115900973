import { Card, CardContent, Typography } from '@material-ui/core';
import { FormikCheckboxField } from '@superdispatch/forms';
import { Inline, Stack, useUID } from '@superdispatch/ui';
import { Box, Button } from '@superdispatch/ui-lab';
import { FormikProvider } from 'formik';
import { useMemo } from 'react';
import { useAppFormik } from 'shared/form/AppFormik';
import { useFormikComposerForm } from 'shared/form/FormikComposer';
import { PickupAltIcon } from 'shared/icons/PickupAltIcon';
import { useFlag } from 'shared/settings/FeatureToggles';
import { yupBoolean } from 'shared/utils/YupUtils';
import { useContactsAPI } from '../../contacts/data/ContactsAPI';
import { LoadPickupForm } from '../core/LoadPickupForm';
import { loadPickupEditSchema } from '../data/LoadActionsDTO';
import { isEmptyLoadLegVenue, LoadDTO } from '../data/LoadDTO';
import { useLoadsAPI } from '../data/LoadsAPI';

const formSchema = loadPickupEditSchema.shape({
  $saveAsNewContact: yupBoolean(),
});

export interface EditLoadPagePickupProps {
  load: LoadDTO;
  onCopyToCustomer: () => void;
}

export function EditLoadPagePickup({
  load,
  onCopyToCustomer,
}: EditLoadPagePickupProps) {
  const uid = useUID();
  const shouldDisableEditing = useFlag('ctms_disabled_fields');
  const { editLoadPickup } = useLoadsAPI();
  const { createContact } = useContactsAPI();

  const formik = useAppFormik({
    initialValues: load.pickup,
    validationSchema: formSchema,
    async onSubmit({ $saveAsNewContact, ...values }) {
      const response = await editLoadPickup(load.guid, values);

      if ($saveAsNewContact) {
        await createContact({
          name: values.venue.name,
          address: values.venue.address,
          city: values.venue.city,
          state: values.venue.state,
          zip: values.venue.zip,

          phone: values.venue.contacts[0]?.phone,
          email: values.venue.contacts[0]?.email,
          contact: values.venue.contacts[0]?.name,
        });
      }

      return response;
    },
  });

  const isPickupVenueEmpty = useMemo(
    () => isEmptyLoadLegVenue(formik.values.venue),
    [formik.values.venue],
  );

  const nodeRef = useFormikComposerForm('pickup', formik);

  return (
    <FormikProvider value={formik}>
      <Card ref={nodeRef} aria-label="Pickup Information Card">
        <CardContent>
          <Stack space="small">
            <Inline verticalAlign="center" space="xsmall" noWrap={true}>
              <PickupAltIcon />
              <Typography id={uid} variant="h3">
                Pickup Information
              </Typography>
            </Inline>

            <LoadPickupForm
              disabled={load.is_created_by_broker && shouldDisableEditing}
            />
          </Stack>
        </CardContent>

        <Box padding="small" backgroundColor="Silver100">
          <Inline space="small">
            <FormikCheckboxField
              name="$saveAsNewContact"
              label="Save as New Contact"
              disabled={isPickupVenueEmpty}
            />
            <Button
              variant="neutral"
              onClick={onCopyToCustomer}
              disabled={isPickupVenueEmpty}
            >
              Copy to Customer Information
            </Button>
          </Inline>
        </Box>
      </Card>
    </FormikProvider>
  );
}
