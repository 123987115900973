import { StandardTextFieldProps, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useUID } from '@superdispatch/ui';
import { identity } from 'lodash-es';
import { HALF_OF_A_SECOND } from 'shared/constants/NumberConstants';
import { useDebouncedValue } from 'shared/helpers/ReactHelpers';
import { toSearchQueryText } from 'shared/utils/TextUtils';

export interface TextAutocompleteProps
  extends Pick<
    StandardTextFieldProps,
    | 'disabled'
    | 'error'
    | 'fullWidth'
    | 'helperText'
    | 'inputProps'
    | 'label'
    | 'name'
    | 'onBlur'
  > {
  value?: null | string;
  onChange?: (value: string) => void;
  useOptions: (query: string | undefined) => undefined | string[];
}

export function TextAutocomplete({
  value,
  onChange,
  disabled,
  useOptions,
  ...textFieldProps
}: TextAutocompleteProps) {
  const uid = useUID();
  const query = useDebouncedValue(toSearchQueryText(value), HALF_OF_A_SECOND);
  const options = useOptions(query);

  return (
    <Autocomplete
      id={uid}
      freeSolo={true}
      value={value || ''}
      disabled={disabled}
      openOnFocus={false}
      disableClearable={true}
      loading={!!value && !options}
      options={options || []}
      filterOptions={identity}
      onInputChange={(_, nextValue) => {
        onChange?.(nextValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          {...textFieldProps}
          inputProps={{
            ...params.inputProps,
            ...textFieldProps.inputProps,
            autoComplete: 'new-password',
          }}
        />
      )}
    />
  );
}
