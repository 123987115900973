import { Stack } from '@superdispatch/ui';
import { useFormikContext } from 'formik';
import { MobileAppBridge } from 'shared/data/MobileAppBridge';
import { FormikMediaField } from 'shared/form/FormikMediaField';
import { FieldAdditionalMessages } from 'shared/modules/carrier-profile/core/AdditionalMessages';
import { useVerifiedCarrierApplicationStatus } from 'shared/modules/carrier-profile/VerifiedCarrierApplicationStatus';
import { useCarrierSettings } from 'shared/settings/CarrierSettingsAPI';
import { StateMotorCarrierPermitDTO } from '../data/CarrierProfileDTO';

export function ProfileStateMotorCarrierPermitForm() {
  const { data } = useVerifiedCarrierApplicationStatus();
  const formik = useFormikContext<StateMotorCarrierPermitDTO>();
  const { data: settings } = useCarrierSettings();

  const isEditingDisabled =
    !settings?.drivers_can_edit_carrier_info && MobileAppBridge.isInjected();
  const application = data?.verified_carrier_application;
  const decliningReasons =
    application?.declining_reasons?.state_motor_carrier_permit;
  const externalNotes = application?.external_notes?.state_motor_carrier_permit;

  const isInitialFile =
    formik.initialValues.file_url === formik.values.file_url;

  const shouldShowError = isInitialFile && !!decliningReasons?.length;

  return (
    <Stack space="small">
      <FormikMediaField
        label="State Motor Carrier Permit"
        name="file_url"
        fullWidth={true}
        disabled={isEditingDisabled}
      />
      {shouldShowError && (
        <FieldAdditionalMessages
          title="State Motor Carrier Permit declined due to:"
          variant="error"
          messages={decliningReasons}
          externalNotes={externalNotes}
        />
      )}
    </Stack>
  );
}
