import { InputAdornment, TableCell } from '@material-ui/core';
import { FormikTextField, useFormikEnhanced } from '@superdispatch/forms';
import { Box } from '@superdispatch/ui-lab';
import { FormikProvider } from 'formik';
import { floor, toFinite } from 'lodash-es';
import { ONE_SECOND } from 'shared/constants/NumberConstants';
import { useTimedOutEffect } from 'shared/helpers/ReactHelpers';
import { validateNumber } from 'shared/helpers/ValidationHelpers';
import { useReportsContext } from './data/ReportsContext';

interface Props {
  guid: string;
  payRate: number;
  onSubmitSuccess: () => void;
}

export function ReportsDriverPayRateCell({
  guid,
  payRate,
  onSubmitSuccess,
}: Props) {
  const { reportsAPI } = useReportsContext();
  const formik = useFormikEnhanced<{ pay_rate: number }, unknown>({
    initialValues: { pay_rate: payRate },
    onSubmit: (values) =>
      reportsAPI.updateDriversPayRate(guid, values.pay_rate),
    onSubmitSuccess,
  });
  const { dirty, handleSubmit, values, isValid, isSubmitting } = formik;

  useTimedOutEffect(
    () => {
      if (dirty && isValid && !isSubmitting) {
        handleSubmit();
      }
    },
    [dirty, handleSubmit, isSubmitting, isValid, values.pay_rate],
    ONE_SECOND,
  );

  return (
    <TableCell>
      <Box minWidth="100px">
        <form
          onSubmit={
            dirty
              ? handleSubmit
              : (event) => {
                  event.preventDefault();
                  event.stopPropagation();
                }
          }
        >
          <FormikProvider value={formik}>
            <FormikTextField
              type="number"
              name="pay_rate"
              fullWidth={true}
              InputProps={{
                readOnly: isSubmitting,
                endAdornment: (
                  <InputAdornment position="end"> %</InputAdornment>
                ),
              }}
              inputProps={{ 'aria-labelledby': guid }}
              parse={({ target }) =>
                target.value === '' ? '' : floor(toFinite(target.value), 1)
              }
              validate={(value) =>
                validateNumber(value, { min: 0, max: 100, required: true })
              }
            />
          </FormikProvider>
        </form>
      </Box>
    </TableCell>
  );
}
