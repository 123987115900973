import { useEffect, useState } from 'react';
import { useErrorSnackbar } from 'shared/hooks/useErrorSnackbar';
import { MoovAccountInfo } from './MoovAccountInfo';
import { useGenerateMoovToken } from './MoovAccountInfoAPI';

export function useMoovAccountInfo(): MoovAccountInfo & { isLoading: boolean } {
  const [, update] = useState(false);
  const { error, isLoading, mutateAsync } = useGenerateMoovToken();
  const moovAccountInfo = MoovAccountInfo.initialize('account', (scope) =>
    mutateAsync(scope).then(({ data }) => data),
  );

  useErrorSnackbar(error, {
    formatError: (e: Error) => e.message || 'Failed to authorize',
  });

  useEffect(
    () =>
      moovAccountInfo.subscribeToAccountDataChange(() => {
        update((state) => !state);
      }),
    [moovAccountInfo],
  );

  return Object.assign(moovAccountInfo, { isLoading });
}
