import { createSvgIcon } from 'shared/icons/IconUtils';

export const PortIcon = createSvgIcon(
  'BusinessTypePort',
  <path
    fill="currentColor"
    d="M3 4h8v2.64L7 5.333 3 6.64m-1.373 6.027h.04c1.066 0 2-.587 2.666-1.334.667.747 1.6 1.334 2.667 1.334 1.067 0 2-.587 2.667-1.334.666.747 1.6 1.334 2.666 1.334h.034l1.266-4.46a.72.72 0 0 0-.04-.514.683.683 0 0 0-.4-.333l-.86-.28V4c0-.74-.6-1.333-1.333-1.333H9v-2H5v2H3A1.333 1.333 0 0 0 1.667 4v3.08l-.86.28a.683.683 0 0 0-.4.333.72.72 0 0 0-.04.514M12.333 14c-.926 0-1.853-.313-2.666-.887a4.586 4.586 0 0 1-5.334 0c-.813.574-1.74.887-2.666.887H.333v1.333h1.334c.913 0 1.826-.233 2.666-.666a5.795 5.795 0 0 0 5.334 0 5.81 5.81 0 0 0 2.666.666h1.334V14h-1.334Z"
  />,
  { viewBox: '0 0 14 16' },
);
