import { useEffect } from 'react';
import { startIntercomTour } from 'shared/helpers/Intercom';
import { useLoadsPageContext } from './LoadsPageParamsContext';
import { useSendInvoiceTourAction } from './useSendInvoiceTourAction';

const SEND_INVOICE_DRAWER_INTERCOM_TOUR_ID = {
  staging: '469133',
  production: '469128',
};

export function useSendInvoiceDrawerTour() {
  const { params } = useLoadsPageContext();
  const { isTourOpened, setTourOpened } = useSendInvoiceTourAction();

  useEffect(() => {
    if (params.stage === 'delivered' && !isTourOpened) {
      // Start New Send Invoice Drawer Intercom Tour here
      setTourOpened();
      if (import.meta.env.VITE_APP_TARGET !== 'production') {
        startIntercomTour(SEND_INVOICE_DRAWER_INTERCOM_TOUR_ID.staging);
      } else {
        startIntercomTour(SEND_INVOICE_DRAWER_INTERCOM_TOUR_ID.production);
      }
    }
  }, [params.stage, isTourOpened, setTourOpened]);
}
