import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import { Box, Button } from '@superdispatch/ui-lab';
import { Form, FormikProvider } from 'formik';
import { useEffect, useState } from 'react';
import { useShipperProfile } from '../shipper/LoadboardShipperAPI';
import { ShipperProfileDTO } from '../shipper/ShipperProfileDTO';
import { RatingShipperForm, useRatingShipperForm } from './RatingShipperForm';

interface ShipperRatingDialogContentProps {
  onClose: () => void;
  profile: ShipperProfileDTO;
  onStateChange: (state: { isSubmitting: boolean }) => void;
}

export function RateShipperDialogContent({
  onClose,
  profile,
  onStateChange,
}: ShipperRatingDialogContentProps) {
  const { formik, isEditingRating } = useRatingShipperForm({
    guid: profile.guid,
    onSuccess() {
      onClose();
    },
  });

  useEffect(() => {
    onStateChange({ isSubmitting: formik.isSubmitting });
  }, [formik.isSubmitting, onStateChange]);

  return (
    <FormikProvider value={formik}>
      <Form>
        <DialogTitle>{profile.name}</DialogTitle>

        <DialogContent>
          <Box width="312px" paddingBottom="small">
            <RatingShipperForm />
          </Box>
        </DialogContent>

        <DialogActions>
          <Button
            variant="neutral"
            disabled={formik.isSubmitting}
            onClick={onClose}
          >
            Cancel
          </Button>

          <Button
            type="submit"
            pending={formik.isSubmitting}
            disabled={!formik.values.rating || !formik.dirty}
          >
            {isEditingRating ? 'Update' : 'Submit'}
          </Button>
        </DialogActions>
      </Form>
    </FormikProvider>
  );
}

interface ShipperRatingDialogProps
  extends Omit<ShipperRatingDialogContentProps, 'profile' | 'onStateChange'> {
  isOpen: boolean;
  shipperGUID: string;
  onClose: () => void;
}

export function RateShipperDialog({
  isOpen,
  onClose,
  shipperGUID,
  ...props
}: ShipperRatingDialogProps) {
  const [state, setState] = useState({ isSubmitting: false });
  const { data: profile } = useShipperProfile(shipperGUID);

  if (!profile) {
    return null;
  }

  return (
    <Dialog
      open={isOpen}
      maxWidth="xs"
      onClose={state.isSubmitting ? undefined : onClose}
    >
      <RateShipperDialogContent
        {...props}
        onClose={onClose}
        profile={profile}
        onStateChange={setState}
      />
    </Dialog>
  );
}
