import { Color } from '@superdispatch/ui';
import { createSvgIcon } from 'shared/icons/IconUtils';

export const QuickbooksOnlineIcon = createSvgIcon(
  'QuickbooksOnlineIcon',
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.4864 10.1366C16.4864 8.0544 14.789 6.36035 12.7026 6.36035H12.3562V7.68695H12.7026C14.0561 7.68695 15.1571 8.78594 15.1571 10.1366C15.1571 11.4876 14.0561 12.5865 12.7026 12.5865H11.5136V4.53387H10.1843V13.9131H12.7026C14.789 13.9131 16.4864 12.219 16.4864 10.1366ZM9.339 6.36035H6.82086C4.7344 6.36035 3.03693 8.0544 3.03693 10.1366C3.03693 12.219 4.7344 13.9131 6.82086 13.9131H7.16709V12.5865H6.82086C5.46737 12.5865 4.36624 11.4876 4.36624 10.1366C4.36624 8.78594 5.46737 7.68695 6.82086 7.68695H8.00969V15.7393H9.339V6.36035ZM19.2688 10.1435C19.2688 15.3801 15.0155 19.625 9.7688 19.625C4.52209 19.625 0.268799 15.3801 0.268799 10.1435C0.268799 4.90716 4.52209 0.662037 9.7688 0.662037C15.0155 0.662037 19.2688 4.90716 19.2688 10.1435Z"
      fill={Color.White}
    />
  </svg>,
  { viewBox: '0 0 20 20', style: { height: '20px', width: '20px' } },
);
