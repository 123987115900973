import {
  CanadaProvince,
  CanadaProvinceOptionsSet,
} from 'shared/form/CanadaProvince';
import {
  CountryFieldOption,
  countryFieldOptions,
} from 'shared/form/CountryFieldOptions';
import { USAState, USAStateOptions } from 'shared/form/USAState';
import { InferType, StringSchema } from 'yup';
import {
  yupArray,
  yupBoolean,
  yupDateString,
  yupEnum,
  yupNumber,
  yupObject,
  yupString,
} from '../../utils/YupUtils';

export type TaxPriceDTO = InferType<typeof taxPriceSchema>;
export const taxPriceSchema = yupObject({
  sub_total: yupNumber(),
  total: yupString(),
  taxes: yupArray(
    yupObject({
      name: yupString(),
      description: yupString(),
      amount: yupNumber(),
    }),
  ),
});

export type SubscriptionSettingsDTO = InferType<
  typeof subscriptionSettingsSchema
>;
export const subscriptionSettingsSchema = yupObject({
  client_token: yupString(),
  plan: yupObject({
    type: yupString(),
    drivers_count: yupNumber(),
    price: yupNumber(),
    discounted_price: yupNumber(),
    discount_amount: yupNumber(),
    seats_count: yupNumber(),
    seats_pricing_tiers: yupArray(
      yupObject({
        starting_seat: yupNumber().nullable(false),
        ending_seat: yupNumber()
          .transform((value: number, originalValue: number | string) =>
            originalValue === 'infinity' ? Infinity : value,
          )
          .nullable(false),
        price: yupNumber(),
      }),
    ),
  }).nullable(true),

  card_information: yupObject({
    card_number: yupString(),
    cardholder_name: yupString(),
    // this field contains `MM/YYYY` format date string
    expiry: yupString(),
  }).nullable(true),
  status: yupEnum(
    ['Active', 'Canceled', 'Past Due', 'Pending', 'In Trial'],
    null,
  ),
  billing_period_end_date: yupDateString('DateISO'),
});

export type BillingAddressDTO = InferType<typeof billingAddressSchema>;
export const billingAddressSchema = yupObject({
  billing_zip: yupString(),
  billing_state: yupString(),
  billing_country: yupString(),
  billing_address: yupString(),
  billing_city: yupString(),
});

export type SubscriptionDTO = InferType<typeof subscriptionSchema>;
export const subscriptionSchema = yupObject({
  country: yupEnum([null, ...countryFieldOptions.keys()]).required(),
  state: yupString().when(
    ['country', 'can_use_company_billing_address'],
    (
      country: CountryFieldOption,
      can_use_company_billing_address: boolean,
      schema: StringSchema,
    ) => {
      if (country === 'US' && !can_use_company_billing_address) {
        return schema.test('us-state', 'Select valid state', (state) =>
          USAStateOptions.has(state as USAState),
        );
      }

      if (country === 'CA' && !can_use_company_billing_address) {
        return schema.test(
          'canada-province',
          'Select valid province',
          (state) => CanadaProvinceOptionsSet.has(state as CanadaProvince),
        );
      }

      return schema;
    },
  ),
  zip: yupString().when(
    ['country', 'can_use_company_billing_address'],
    (
      country: CountryFieldOption,
      can_use_company_billing_address: boolean,
      schema: StringSchema,
    ) => {
      if (
        (country === 'US' || country === 'CA') &&
        !can_use_company_billing_address
      ) {
        return schema.required();
      }

      return schema;
    },
  ),
  address: yupString().when(
    ['country', 'can_use_company_billing_address'],
    (
      country: CountryFieldOption,
      can_use_company_billing_address: boolean,
      schema: StringSchema,
    ) => {
      if (
        (country === 'US' || country === 'CA') &&
        !can_use_company_billing_address
      ) {
        return schema.required();
      }

      return schema;
    },
  ),
  city: yupString().when(
    ['country', 'can_use_company_billing_address'],
    (
      country: CountryFieldOption,
      can_use_company_billing_address: boolean,
      schema: StringSchema,
    ) => {
      if (
        (country === 'US' || country === 'CA') &&
        !can_use_company_billing_address
      ) {
        return schema.required();
      }

      return schema;
    },
  ),

  can_use_company_billing_address: yupBoolean().notRequired(),
  seats_count: yupNumber().notRequired().min(1),
});

export type UpdateSubscriptionDTO = InferType<typeof updateSubscriptionSchema>;
export const updateSubscriptionSchema = yupObject({
  id: yupString(),
  id_at_vault: yupString(),
  payment_method_nonce: yupString(),
  seats_count: yupNumber().notRequired().min(1),
});
