import { Typography } from '@material-ui/core';
import { useFormikEnhanced } from '@superdispatch/forms';
import { Color, Stack } from '@superdispatch/ui';
import { Alert, Box, Button } from '@superdispatch/ui-lab';
import { useState } from 'react';
import { APIError, createAPIError, isAPIError } from 'shared/api/APIError';
import { FormikDrawerContent } from 'shared/form/FormikDrawer';
import { LinkAnchor } from 'shared/routing/Links';
import { useCarrierSettings } from 'shared/settings/CarrierSettingsAPI';
import { useFlag } from 'shared/settings/FeatureToggles';
import styled from 'styled-components';
import {
  DriverDTO,
  driverEditLegacySchema,
  driverEditSchema,
  inviteDriverDTO,
} from '../../drivers/data/DriverDTO';
import { JOIN_REQUEST_ALREADY_SENT_ERROR } from '../../drivers/data/DriverPageUtils';
import { trackDriversEvent } from '../../drivers/data/DriversAnalytics';
import {
  useAcceptDriverRequest,
  useDriversAPI,
} from '../../drivers/data/DriversAPI';
import { DriverFormFields } from '../../drivers/DriverFormDrawer';
import { PlanUpdateDialog } from '../../drivers/PlanUpdateDialog';
import { LoadDTO } from '../data/LoadDTO';
import { useLoadsContext } from '../data/LoadsContext';

const Link = styled(LinkAnchor)`
  text-decoration: underline !important;
`;

const StyledBox = styled(Box)`
  padding-top: 6px;
  padding-bottom: 6px;
`;

const StyledTypography = styled(Typography)`
  font-size: 13px;
  color: ${Color.Dark500};
`;

interface LoadsInviteDriverFormProps {
  onClose: () => void;
  onSuccess: (driver: DriverDTO | null) => void;
  load?: LoadDTO;
}

export function LoadsInviteDriverForm({
  onClose,
  load,
  onSuccess,
}: LoadsInviteDriverFormProps) {
  const loadGUID = load?.guid || '';
  const [requestedDriverGUID, setRequestedDriverGUID] = useState<
    string | undefined
  >();
  const [shouldShowDriversLinkAlert, setShowDriversLinkAlert] = useState(true);
  const [shouldConfirm, setShouldConfirm] = useState(false);

  const isInternalNameEnabled = useFlag(
    'multi_carrier_edit_internal_driver_name',
  );

  const { inviteDriver } = useDriversAPI();
  const { data: settings } = useCarrierSettings();
  const { loadsAPI } = useLoadsContext();
  const { mutateAsync: acceptDriverRequest, isLoading } =
    useAcceptDriverRequest({
      onSuccess: async () => {
        await loadsAPI.assignDriver(loadGUID, requestedDriverGUID || '');
        onClose();
        handleClose();
      },
    });

  const formik = useFormikEnhanced({
    validationSchema: inviteDriverDTO,
    initialValues: {
      email: '',
      phone_number: '',
      name: '',
    },
    async onSubmit(values) {
      const currentDriver = await inviteDriver(values);
      await loadsAPI.assignDriver(loadGUID, currentDriver.guid);

      return currentDriver;
    },
    onSubmitSuccess(response: DriverDTO) {
      onSuccess(response);

      trackDriversEvent({
        name: 'CTMS: Driver Invited',
        properties: {
          guid: response.guid,
          source: 'Assign Loads Page',
          userGuid: settings?.user.guid,
        },
      });

      onClose();
      handleClose();
    },
    onSubmitFailure: (e) => {
      if (isAPIError(e) && e.type === JOIN_REQUEST_ALREADY_SENT_ERROR) {
        const error = createAPIError(e) as APIError<{ guid: string }>;
        setRequestedDriverGUID(error.context?.guid);
      }
    },
  });

  const { isSubmitting, values } = formik;

  function handleClose() {
    setRequestedDriverGUID(undefined);
  }

  return (
    <>
      <PlanUpdateDialog
        open={shouldConfirm}
        onClose={() => {
          setShouldConfirm(false);
        }}
        onClick={() => {
          setShouldConfirm(false);
          void formik.submitForm();
        }}
      />

      <FormikDrawerContent
        onClose={() => {
          onClose();
          handleClose();
        }}
        formik={formik}
      >
        <Box maxWidth="368px">
          <Stack space="small">
            {shouldShowDriversLinkAlert && (
              <Alert
                onClose={() => {
                  setShowDriversLinkAlert(false);
                }}
                severity="info"
              >
                You can manage drivers from{' '}
                <Link underline="none" color="primary" to="/drivers">
                  Drivers page
                </Link>{' '}
                on navigation bar.
              </Alert>
            )}

            <DriverFormFields type="invite" hasWarning={false} />

            {requestedDriverGUID && (
              <StyledBox
                paddingLeft="xsmall"
                paddingRight="xsmall"
                backgroundColor="Blue50"
                borderRadius="small"
              >
                <StyledTypography>
                  This driver has already requested to join your carrier. Would
                  you like to accept the driver&apos;s request and assign the
                  load?
                </StyledTypography>
              </StyledBox>
            )}

            <Box paddingTop="small">
              {requestedDriverGUID ? (
                <Button
                  disabled={isLoading}
                  onClick={() =>
                    acceptDriverRequest([requestedDriverGUID, values.name])
                  }
                >
                  Accept & Assign
                </Button>
              ) : (
                <Button
                  disabled={isSubmitting}
                  onClick={() => {
                    const schema = isInternalNameEnabled
                      ? driverEditSchema
                      : driverEditLegacySchema;
                    if (
                      settings?.is_paying &&
                      schema.isValidSync(formik.values)
                    ) {
                      setShouldConfirm(true);
                    } else {
                      void formik.submitForm();
                    }
                  }}
                >
                  Invite & Assign
                </Button>
              )}
            </Box>
          </Stack>
        </Box>
      </FormikDrawerContent>
    </>
  );
}
