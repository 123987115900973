import { useUID } from '@superdispatch/ui';
import { Ref } from 'react';
import { DispatcherField } from '../../dispatchers/core/DispatcherField';
import { DriverField } from '../../drivers/core/DriverField';
import { TerminalField } from '../../terminals/TerminalField';
import {
  LoadsPageFilterFieldActions,
  LoadsPageFilterFieldLabel,
} from './LoadsPageFilterField';

export interface LoadsPageFilterResourceFieldProps {
  name: 'drivers' | 'dispatchers' | 'terminals';
  inputRef: Ref<HTMLInputElement>;
  value: null | undefined | string;
  onChange: (value: undefined | string) => void;
}

export function LoadsPageFilterResourceField({
  name,
  value,
  onChange,
  inputRef,
}: LoadsPageFilterResourceFieldProps) {
  const uid = useUID();
  const FilterAutocomplete =
    name === 'drivers'
      ? DriverField
      : name === 'dispatchers'
      ? DispatcherField
      : // name === 'terminals'
        TerminalField;

  return (
    <FilterAutocomplete
      id={uid}
      value={value}
      forcePopupIcon={false}
      disableClearable={true}
      onChange={onChange}
      TextFieldProps={{
        inputRef,
        placeholder: 'Search and select',
        InputProps: {
          startAdornment: (
            <LoadsPageFilterFieldLabel name={name} htmlFor={uid} />
          ),
          endAdornment: (
            <LoadsPageFilterFieldActions
              hasValue={!!value}
              onClear={() => {
                onChange(undefined);
              }}
            />
          ),
        },
      }}
    />
  );
}
