import { Check } from '@material-ui/icons';
import { Color, Inline, Stack } from '@superdispatch/ui';
import { Box, Button, TextBox } from '@superdispatch/ui-lab';
import { ReactNode } from 'react';
import { SuperPayLabel } from 'shared/helpers/PaymentHelpers';
import { openExternalURL } from 'shared/helpers/URLHelpers';
import styled from 'styled-components';
import OfferDeclinedLogo from '../../assets/offer-declined-logo.svg';
import SuperLoadboard from '../../assets/super-loadboard.png';
import { OffersPageLayout } from '../../core/OffersPageLayout';
import { PublicOfferDTO } from '../data/PublicOfferDTO';
import { PublicOfferPageHeader } from '../PublicOfferPageHeader';

const StyledButton = styled(Button)`
  background-color: ${Color.Green300};
`;

function Item({ children }: { children: ReactNode }) {
  return (
    <Inline verticalAlign="center" noWrap={true}>
      <Check color="primary" />
      <TextBox variant="body-semibold">{children}</TextBox>
    </Inline>
  );
}

export function PublicOfferDeclinedPage({ offer }: { offer: PublicOfferDTO }) {
  return (
    <>
      <PublicOfferPageHeader offer={offer} />

      <OffersPageLayout>
        <Stack space="large">
          <Box
            paddingBottom="xlarge"
            borderColor="Silver400"
            borderBottomWidth="small"
          >
            <Stack align="center">
              <img
                width={152}
                height={56}
                alt="Offer Declined"
                src={OfferDeclinedLogo}
              />

              <TextBox variant="heading-2">Offer Declined</TextBox>
            </Stack>
          </Box>

          <Stack space="medium">
            <Stack space="none">
              <TextBox variant="heading-2" align="center">
                Want to Find More Cars from <br />
                Other Shippers?
              </TextBox>
              <TextBox variant="heading-2" color="red" align="center">
                Look at our Free Load Board
              </TextBox>
            </Stack>

            <Box maxWidth="400px" margin="auto">
              <Stack space="medium">
                <img src={SuperLoadboard} alt="SuperLoadboard" />

                <Stack>
                  <Item>Instantly Book Loads 24/7</Item>
                  <Item>
                    Get Paid Fast with{' '}
                    <SuperPayLabel source="Public Load Offer Declined" />
                  </Item>
                  <Item>Create Free Load Alerts</Item>
                  <Item>Get Intelligent Load Suggestions</Item>
                </Stack>

                <StyledButton
                  onClick={() => {
                    openExternalURL(
                      '/loadboard?utm_medium=Load Offer&utm_source=Web+CTMS',
                      { target: '_self' },
                    );
                  }}
                  size="large"
                  fullWidth={true}
                >
                  View Load Board
                </StyledButton>
              </Stack>
            </Box>
          </Stack>
        </Stack>
      </OffersPageLayout>
    </>
  );
}
