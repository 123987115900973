import { MenuItem, Slide, Tooltip } from '@material-ui/core';
import { Info } from '@material-ui/icons';
import { FormikTextField } from '@superdispatch/forms';
import { Color, Column, Columns, Inline, Stack } from '@superdispatch/ui';
import { useField, useFormikContext } from 'formik';
import { PaywallBillingAddressDTO } from 'shared/errors/data/PaywallDTO';
import { CanadaProvinceOptionsSet } from 'shared/form/CanadaProvince';
import { FormikCountryField } from 'shared/form/FormikCountryField';
import { USAStateOptions } from 'shared/form/USAState';

interface SubscriptionBillingFormProps {
  onFocus?: () => void;
}

export function SubscriptionBillingForm({
  onFocus,
}: SubscriptionBillingFormProps) {
  const { setValues } = useFormikContext<PaywallBillingAddressDTO>();
  const [field] = useField('country');

  const isUSASelected = field.value === 'US';
  const isCanadaSelected = field.value === 'CA';

  return (
    <Stack space="small">
      <FormikCountryField
        name="country"
        fullWidth={true}
        onFocus={onFocus}
        onChange={(value) => {
          if (value !== 'US') {
            void setValues((item) => ({
              ...item,
              zip: '',
              state: '',
            }));
          }
        }}
        TextFieldProps={{
          fullWidth: true,
          label: 'Country',
        }}
      />

      {(isUSASelected || isCanadaSelected) && (
        <Slide
          direction="up"
          in={isUSASelected || isCanadaSelected}
          mountOnEnter={true}
          unmountOnExit={false}
          timeout={100}
        >
          <Stack space="small">
            <FormikTextField
              name="address"
              label="Address"
              fullWidth={true}
              onFocus={onFocus}
            />

            <FormikTextField
              onFocus={onFocus}
              name="city"
              label="City"
              fullWidth={true}
            />
            <Columns space="small">
              <Column width="1/2">
                <FormikTextField
                  label={isCanadaSelected ? 'Province' : 'State'}
                  name="state"
                  fullWidth={true}
                  onFocus={onFocus}
                  select={isUSASelected || isCanadaSelected}
                >
                  {Array.from(
                    isUSASelected ? USAStateOptions : CanadaProvinceOptionsSet,
                    (state) => (
                      <MenuItem key={state} value={state}>
                        {state}
                      </MenuItem>
                    ),
                  )}
                </FormikTextField>
              </Column>

              <Column width="1/2">
                <FormikTextField
                  name="zip"
                  onFocus={onFocus}
                  label={
                    <Inline verticalAlign="center">
                      {isCanadaSelected ? 'Postal Code' : 'ZIP Code'}
                      <Tooltip
                        interactive={true}
                        placement="right"
                        title={
                          isCanadaSelected
                            ? "Postal code of your card's billing address."
                            : "Zip code of your card's billing address."
                        }
                      >
                        <Info fontSize="small" htmlColor={Color.Dark100} />
                      </Tooltip>
                    </Inline>
                  }
                  fullWidth={true}
                />
              </Column>
            </Columns>
          </Stack>
        </Slide>
      )}
    </Stack>
  );
}
