import { offerDeclinePayloadSchema } from '../../loads/data/LoadDTO';
import { OffersDeclineDialogBase } from '../core/OffersDeclineDialogBase';
import { trackOffersEvent } from './data/OffersAnalytics';
import { useOffersAPI } from './data/OffersAPI';
import { OfferDetailsDTO } from './data/OffersDTO';

interface OffersDeclineDialogProps {
  offer: OfferDetailsDTO;
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
}

export function OffersDeclineDialog({
  offer,
  onSuccess,
  ...props
}: OffersDeclineDialogProps) {
  const { declineOffer } = useOffersAPI();

  return (
    <OffersDeclineDialogBase
      {...props}
      validationSchema={offerDeclinePayloadSchema}
      onSubmit={(values) => declineOffer(offer.guid, values)}
      onSubmitSuccess={(_response, values) => {
        trackOffersEvent({
          name: 'Declined Load Offer',
          offer,
          payload: values,
        });
        onSuccess();
      }}
    />
  );
}
