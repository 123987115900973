import { useField, useFormikContext } from 'formik';
import { VINDecoder, VINDecoderProps } from './VINDecoder';

export interface FormikVINDecoderProps extends VINDecoderProps {
  name: string;
}

export function FormikVINDecoder({
  name,
  onBlur,
  onChange,
  disabled,
  helperText,
  ...props
}: FormikVINDecoderProps) {
  const { isSubmitting } = useFormikContext();
  const [field, { error, touched }, { setValue, setTouched }] =
    useField<string>(name);

  return (
    <VINDecoder
      {...props}
      {...field}
      name={name}
      error={!!error}
      helperText={touched && error}
      disabled={disabled ?? isSubmitting}
      onBlur={() => {
        onBlur?.();
        void setTouched(true);
      }}
      onChange={(nextValue) => {
        onChange?.(nextValue);
        void setValue(nextValue);
      }}
    />
  );
}
