import { useAppFormik } from 'shared/form/AppFormik';
import {
  FormikDrawer,
  FormikDrawerActions,
  FormikDrawerContent,
} from 'shared/form/FormikDrawer';
import { useFlag } from 'shared/settings/FeatureToggles';
import { loadDetailsEditSchema } from '../data/LoadActionsDTO';
import { formatLoadStatus, isDisabledLoadId } from '../data/LoadDTO';
import { trackLoadsEvent } from '../data/LoadsAnalytics';
import { useLoadDetails, useLoadsAPI, useLoadsCache } from '../data/LoadsAPI';
import { useLoadStageFromBackURL } from '../view/ViewLoadHeaderActions';
import { LoadDetailsForm } from './LoadDetailsForm';

interface EditLoadDetailsDrawerContentProps {
  loadGUID: string;
  onClose: () => void;
  isEnabledEditLoad: boolean;
}

function EditLoadDetailsDrawerContent({
  onClose,
  loadGUID,
  isEnabledEditLoad,
}: EditLoadDetailsDrawerContentProps) {
  const isCarrierRestrictLoadIdEnabled = useFlag(
    'carrier_restrict_changing_load_id',
  );
  const { data } = useLoadDetails(loadGUID);
  const { patchLoadDetails } = useLoadsAPI();
  const { invalidateLoads } = useLoadsCache();
  const loadStage = useLoadStageFromBackURL();
  const formik = useAppFormik({
    initialValues: data,
    validationSchema: loadDetailsEditSchema,
    onSubmit(values) {
      return patchLoadDetails(loadGUID, values, data);
    },
    onSubmitSuccess() {
      onClose();
      invalidateLoads();

      if (data) {
        const isInitialValuesEmpty =
          !data.number &&
          !data.internal_load_id &&
          data.inspection_type === 'standard';

        trackLoadsEvent({
          name: 'Carrier Edited Load',
          utm_medium: loadStage,
          utm_content: 'View Page',
          load_status: formatLoadStatus(data.status),
          is_created_by_broker: !!data.is_created_by_broker,
          load_details: isInitialValuesEmpty ? 'Added' : 'Edited',
        });
      }
    },
    getSuccessMessage() {
      return 'Successfully changed load details.';
    },
    getErrorMessage(error) {
      return (
        error.message ||
        'Something went wrong while updating load details, contact support.'
      );
    },
  });

  const disabled =
    data?.is_created_by_broker &&
    ((data && isDisabledLoadId(data)) || isCarrierRestrictLoadIdEnabled);

  return (
    <FormikDrawerContent
      formik={formik}
      onClose={onClose}
      title="Load Details"
      actions={<FormikDrawerActions />}
    >
      <LoadDetailsForm
        isEnabledEditLoad={isEnabledEditLoad}
        disableInternalLoadIDHint={true}
        isDisableLoadId={disabled}
      />{' '}
    </FormikDrawerContent>
  );
}

interface EditLoadDetailsDrawerProps extends EditLoadDetailsDrawerContentProps {
  open: boolean;
  isEnabledEditLoad: boolean;
}

export function EditLoadDetailsDrawer({
  open,
  onClose,
  loadGUID,
  isEnabledEditLoad,
}: EditLoadDetailsDrawerProps) {
  return (
    <FormikDrawer open={open} onClose={onClose}>
      <EditLoadDetailsDrawerContent
        onClose={onClose}
        loadGUID={loadGUID}
        isEnabledEditLoad={isEnabledEditLoad}
      />
    </FormikDrawer>
  );
}
