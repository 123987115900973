import { Typography } from '@material-ui/core';
import { FormikTextField, useFormikEnhanced } from '@superdispatch/forms';
import { Column, Columns, Stack, useSnackbarStack } from '@superdispatch/ui';
import { Button } from '@superdispatch/ui-lab';
import { useEffect, useState } from 'react';
import { APIResponse } from 'shared/api/APIClient';
import { isWebView } from 'shared/constants/AppTypeConstants';
import { MobileAppBridge } from 'shared/data/MobileAppBridge';
import { useInitialValues } from 'shared/form/AppFormik';
import { FormikMediaField } from 'shared/form/FormikMediaField';
import { trackEvent } from 'shared/helpers/Analytics';
import { useQuery } from 'shared/helpers/RoutingHelpers';
import { openExternalURL } from 'shared/helpers/URLHelpers';
import { DocumentTitle } from 'shared/layout/DocumentTitle';
import { useCarrierSettings } from 'shared/settings/CarrierSettingsAPI';
import { ConfirmDialog } from 'shared/ui/ConfirmDialog';
import { trackCarrierProfileEvent } from './data/CarrierProfileAnalytics';
import {
  useACHPaymentInfo,
  useCarrierProfileAPI,
  useDeleteACHPaymentInfo,
} from './data/CarrierProfileAPI';
import {
  ACHPaymentInfoDTO,
  ACHPaymentInfoSchema,
} from './data/CarrierProfileDTO';
import { ProfilePageLayout } from './ProfilePageLayout';

export function ProfileACHPaymentInfoPage() {
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const { data, error, isLoading } = useACHPaymentInfo();
  const { updateACHPaymentInfo } = useCarrierProfileAPI();
  const { data: settings } = useCarrierSettings();
  const { addSnackbar } = useSnackbarStack();
  const [searchParams] = useQuery();
  const initialValues = useInitialValues(ACHPaymentInfoSchema, data);

  const isEditingDisabled =
    !settings?.drivers_can_edit_carrier_info && MobileAppBridge.isInjected();

  const isACHPaymentInfoEmpty = !(
    data &&
    (data.bank_account_number ||
      data.bank_name ||
      data.bank_routing_number ||
      data.voided_check_url)
  );

  const formik = useFormikEnhanced<ACHPaymentInfoDTO, APIResponse>({
    initialValues,
    validationSchema: ACHPaymentInfoSchema,
    onSubmit: updateACHPaymentInfo,
    onSubmitSuccess: () => {
      trackEvent('CTMS: Saved ACH Payment Info');
      addSnackbar('ACH payment info was successfully updated', {
        variant: 'success',
      });

      trackCarrierProfileEvent({
        name: 'Carrier Updated Carrier Info',
        utm_medium: 'Carrier Profile',
        utm_content: 'ACH Payment Info',
      });

      if (searchParams.back_url) {
        openExternalURL(decodeURIComponent(searchParams.back_url));
      }
    },
    onSubmitFailure: ({ message }) =>
      addSnackbar(`Failed to update ACH payment info: ${message}`, {
        variant: 'error',
      }),
  });

  const { mutate: deleteACHPayment, isLoading: isLoadingDeleteACHPayment } =
    useDeleteACHPaymentInfo({
      onSuccess() {
        addSnackbar('ACH payment info was deleted', {
          variant: 'success',
        });
      },
      onError({ message }) {
        addSnackbar(`Failed to delete ACH payment info: ${message}`, {
          variant: 'error',
        });
      },
    });

  useEffect(() => {
    if (error) {
      addSnackbar('Failed to fetch ACH payment info', { variant: 'error' });
    }
  }, [addSnackbar, error]);

  useEffect(() => {
    trackEvent('CTMS: Started Add ACH Payment Info', {
      utm_medium: searchParams.utm_medium || 'carrier_profile',
    });
  }, [searchParams]);

  return (
    <>
      <DocumentTitle title="ACH Payment Info - Profile" />

      <ConfirmDialog
        open={isDeleteDialogOpen}
        title="Delete ACH Payment Info?"
        onClose={() => {
          setIsDeleteDialogOpen(false);
        }}
        confirmButtonProps={{
          children: 'Delete',
          variant: 'critical',
          onClick: () => {
            setIsDeleteDialogOpen(false);
            deleteACHPayment('');
          },
        }}
      >
        Shippers and drivers will not be able to see ACH Payment Info.
      </ConfirmDialog>

      <ProfilePageLayout
        formik={formik}
        title="ACH Payment Info"
        isLoading={isLoading}
        headerEndActions={
          <Columns space="small">
            <Column>
              <Button
                disabled={isACHPaymentInfoEmpty}
                pending={isLoadingDeleteACHPayment}
                variant="neutral"
                fullWidth={isWebView}
                onClick={() => {
                  setIsDeleteDialogOpen(true);
                }}
              >
                Delete
              </Button>
            </Column>
            <Column>
              <Button
                type="button"
                onClick={formik.submitForm}
                pending={formik.isSubmitting}
                disabled={!formik.dirty}
                fullWidth={isWebView}
              >
                Save
              </Button>
            </Column>
          </Columns>
        }
      >
        <Stack space="small">
          <Typography color="textSecondary" gutterBottom={true}>
            ACH Payment Info helps Shippers to process payments faster.
            <br />
            Shippers can see this information. Drivers can see and edit this
            information
          </Typography>

          <FormikTextField
            label="Bank Name"
            name="bank_name"
            fullWidth={true}
            disabled={isEditingDisabled}
          />

          <Columns space="small" collapseBelow="tablet">
            <Column>
              <FormikTextField
                label="Routing Number"
                name="bank_routing_number"
                fullWidth={true}
                disabled={isEditingDisabled}
              />
            </Column>
            <Column>
              <FormikTextField
                label="Bank Account Number"
                name="bank_account_number"
                fullWidth={true}
                disabled={isEditingDisabled}
              />
            </Column>
          </Columns>

          <FormikMediaField
            label="Voided Check Upload"
            name="voided_check_url"
            disabled={isEditingDisabled}
          />
        </Stack>
      </ProfilePageLayout>
    </>
  );
}
