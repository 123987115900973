import { Card, Divider, Typography } from '@material-ui/core';
import { Stack, useResponsiveValue } from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import SuccessIcon from '../assets/activation-success-icon.svg';
import DeliverToCustomerIcon from '../assets/deliver-to-customer-icon.svg';
import DownloadAppIcon from '../assets/download-app-icon.svg';
import InspectVehiclesIcon from '../assets/inspect-vehicles-icon.svg';
import QRCodeIcon from '../assets/qr-code-icon.svg';
import SendInvoiceIcon from '../assets/send-invoice-icon.svg';
import { DriverActivationDownloadAppSection } from '../driver-activation/core/DriverActivationDownloadAppSection';
import { DriverActivationStepItem } from './DriverActivationStepItem';

export function DriverActivationCompletedPage({
  carrierName,
}: {
  carrierName?: string;
}) {
  const isMobile = useResponsiveValue(true, true, false);

  return (
    <Box
      maxWidth="600px"
      marginLeft="auto"
      marginRight="auto"
      marginTop={['none', 'large']}
      marginBottom={['none', 'large']}
    >
      <Card>
        <Box padding={['small', 'medium']}>
          <Stack space="large">
            <Stack space="xxsmall" align="center">
              <img src={SuccessIcon} />
              <Typography variant="h2">You’ve successfully joined</Typography>
              <Typography variant="h2">{carrierName}</Typography>
            </Stack>
            <Divider />
            <Typography variant="h6" align="center">
              WHAT ARE THE NEXT STEPS?
            </Typography>
            <Stack space={['xlarge', 'small']}>
              {isMobile ? (
                <DriverActivationStepItem
                  iconSrc={DownloadAppIcon}
                  title="1. Download the App"
                  description="Download the Super Dispatch App to access your loads."
                />
              ) : (
                <DriverActivationStepItem
                  iconSrc={QRCodeIcon}
                  title="1. Download the App"
                  description="Scan the QR code to download the Super Dispatch App and access your loads."
                />
              )}
              <DriverActivationStepItem
                iconSrc={InspectVehiclesIcon}
                title="2. Inspect Vehicles"
                description="Take photos of each vehicle and mark damages if needed."
              />
              <DriverActivationStepItem
                iconSrc={DeliverToCustomerIcon}
                title="3. Deliver to the Customer"
                description="Safely deliver all vehicles."
              />
              <DriverActivationStepItem
                iconSrc={SendInvoiceIcon}
                title="4. Send Invoice to Get Paid"
                description="Use Super Dispatch to send invoices to your customers or your dispatcher can send them from their Carrier TMS account."
              />
            </Stack>
          </Stack>
        </Box>
      </Card>
      {isMobile && <DriverActivationDownloadAppSection />}
    </Box>
  );
}
