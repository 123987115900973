import { THIRTY_SECONDS } from 'shared/constants/NumberConstants';
import {
  BaseResourceFieldProps,
  ResourceField,
} from 'shared/form/ResourceField';
import { DriverDTO } from '../data/DriverDTO';
import { useDriver, useDriverList } from '../data/DriversAPI';

export function DriverField(props: BaseResourceFieldProps<DriverDTO>) {
  const useSelectedOption = (guid: string) =>
    useDriver(guid, {
      staleTime: THIRTY_SECONDS,
      refetchOnWindowFocus: false,
    });

  return (
    <ResourceField
      {...props}
      useOptions={useOptions}
      noOptionsText="No drivers found"
      useSelectedOption={useSelectedOption}
      groupBy={(option) => (option.is_suspended ? 'Deactivated' : 'Active')}
      getOptionKey={(option) => option.guid}
      getOptionLabel={(option) =>
        option.name || option.email || option.phone || option.inbox
      }
    />
  );
}

function useOptions(query: string) {
  return useDriverList(
    { q: query, page_size: 20, exclude_join_requests: true },

    {
      staleTime: THIRTY_SECONDS,
      refetchOnWindowFocus: false,
    },
  );
}
