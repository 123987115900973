import { yupNumber, yupObject } from 'shared/utils/YupUtils';
import { InferType } from 'yup';

export type CarrierPerformanceDTO = InferType<typeof carrierPerformanceSchema>;

/*
  general - count of loads that was taken for calculation
  final - count of loads that are satisfies condition for metric
 */
export const carrierPerformanceSchema = yupObject({
  eta_provided_general_count: yupNumber(),
  eta_provided_final_pickup_count: yupNumber(),
  eta_provided_final_delivery_count: yupNumber(),

  photos_on_site_general_count: yupNumber(),
  photos_on_site_final_pickup_count: yupNumber(),
  photos_on_site_final_delivery_count: yupNumber(),

  late_cancellation_general_count: yupNumber(),
  late_cancellation_final_count: yupNumber(),
});
