import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useMemo, useState } from 'react';
import { useDebouncedValue } from 'shared/helpers/ReactHelpers';
import { useDriverList } from '../../../carrier/drivers/data/DriversAPI';

interface LoadSuggestionDriverDTO {
  guid: string;
  name: string;
}

export interface LoadSuggestionDriverFieldProps {
  value: LoadSuggestionDriverDTO[];
  onChange: (value: LoadSuggestionDriverDTO[]) => void;
}

export function LoadSuggestionDriverField({
  value,
  onChange,
}: LoadSuggestionDriverFieldProps) {
  const [search, setSearch] = useState('');
  const query = useDebouncedValue(search, 500);

  const { data: drivers, isFetching } = useDriverList({
    q: query,
    page_size: 20,
    exclude_suspended: true,
  });
  const options = useMemo(() => {
    const nextOptions: LoadSuggestionDriverDTO[] = [];
    if (drivers) {
      for (const page of drivers.pages) {
        for (const driver of page.data) {
          nextOptions.push({
            guid: driver.guid,
            name: driver.name,
          });
        }
      }
    }
    return nextOptions;
  }, [drivers]);

  return (
    <Autocomplete
      value={value}
      multiple={true}
      options={options}
      loading={isFetching}
      data-intercom-target="load-suggestions-driver-filter"
      getOptionLabel={(option) => option.name}
      getOptionSelected={(option, _value) => option.name === _value.name}
      filterOptions={(_options) =>
        _options.filter((option) => option.name !== '')
      }
      onInputChange={(_, inputValue) => {
        setSearch(inputValue);
      }}
      onChange={(_, changedValue) => {
        onChange(changedValue);
      }}
      renderInput={(params) => (
        <TextField {...params} placeholder="Filter by drivers" />
      )}
    />
  );
}
