import { CardComponent } from '@chargebee/chargebee-js-react-wrapper';
//eslint-disable-next-line import/no-internal-modules
import ChargebeeComponents from '@chargebee/chargebee-js-react-wrapper/dist/components/ComponentGroup';
import { Typography } from '@material-ui/core';
import { Column, Columns, Stack } from '@superdispatch/ui';
import { forwardRef, useState } from 'react';
import {
  ChargebeeErrorField,
  ChargebeeEvent,
  fonts,
} from 'shared/helpers/ChargebeeWeb';
import { SubscriptionBillingForm } from 'shared/modules/subscription/core/SubscriptionBillingForm';
import { CVVTitleProps } from 'shared/ui/ChargebeeCvvTitle';
import {
  FormCardCVV,
  FormCardExpiry,
  FormCardNumber,
} from 'shared/ui/ChargebeeFormFields';

export const SubscriptionForm = forwardRef<ChargebeeComponents | null>(
  (_, cardRef) => {
    const [focus, setFocus] = useState<'number' | 'expiry' | 'cvv'>();
    const [errors, setErrors] = useState<ChargebeeErrorField>({
      expiry: null,
      cvv: null,
      number: null,
    });
    const [card, setCard] = useState<CVVTitleProps>({
      isEmpty: true,
      cardType: undefined,
    });

    const handleError = (event: ChargebeeEvent) => {
      const { field: chargebeeField, error } = event;
      setErrors((prevErr) => ({ ...prevErr, [chargebeeField]: error ?? null }));
      if (chargebeeField === 'number') {
        setCard({ cardType: event.cardType, isEmpty: event.empty });
      }
    };

    return (
      <CardComponent
        placeholder={{
          cvv: 'CVV2, CVC2, etc.',
          number: ' ',
          expiry: 'MM / YY',
        }}
        onChange={handleError}
        fonts={fonts}
        ref={cardRef}
      >
        <Stack space="medium">
          <Stack space="small">
            <FormCardNumber
              label="Card Number"
              focused={focus === 'number'}
              onFocus={() => {
                setFocus('number');
              }}
              error={errors.number ? errors.number.message : ''}
            />
            <Columns space="small">
              <Column width="1/2">
                <FormCardExpiry
                  label="Expiration"
                  focused={focus === 'expiry'}
                  onFocus={() => {
                    setFocus('expiry');
                  }}
                  error={errors.expiry ? errors.expiry.message : ''}
                />
              </Column>
              <Column width="1/2">
                <FormCardCVV
                  {...card}
                  label="Security Code"
                  focused={focus === 'cvv'}
                  onFocus={() => {
                    setFocus('cvv');
                  }}
                  error={errors.cvv ? errors.cvv.message : ''}
                />
              </Column>
            </Columns>
          </Stack>
          <Stack space="small">
            <Typography variant="h4">Billing Address</Typography>
            <SubscriptionBillingForm
              onFocus={() => {
                setFocus(undefined);
              }}
            />
          </Stack>
        </Stack>
      </CardComponent>
    );
  },
);

SubscriptionForm.displayName = 'SubscriptionForm';
