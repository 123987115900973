import styled from 'styled-components';

export const PrintPage = styled.div`
  @media print {
    zoom: 0.85;
  }
`;

export const HiddenForPrint = styled.div`
  @media print {
    display: none;
  }
`;
