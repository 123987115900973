import { Typography } from '@material-ui/core';
import { Stack } from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';

export function BusinessDetailsInfo({
  isMoovAvailable,
}: {
  isMoovAvailable: boolean;
}) {
  return (
    <Stack space="small">
      {!isMoovAvailable && (
        <Box backgroundColor="Yellow50" padding="small" borderRadius="small">
          <Stack space="small">
            <Typography>SuperPay is unavailable in your location.</Typography>
            <Typography>
              Due to security liabilities use of SuperPay is restricted in
              certain jurisdictions.
            </Typography>
          </Stack>
        </Box>
      )}

      <Typography color="textSecondary">
        To enable payments, we are legally required to collect information
        below. This information is not stored by Super Dispatch and only used to
        verify the business.
      </Typography>
    </Stack>
  );
}
