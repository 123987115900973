import { createRef, RefObject, useCallback, useRef } from 'react';

export function useLazyRef<TShape, TKey extends string = string>(): (
  key: TKey,
) => RefObject<TShape> {
  const refs = useRef(new Map<string, RefObject<TShape>>());
  return useCallback((key: string) => {
    const { current } = refs;
    let ref = current.get(key);

    if (!ref) {
      ref = createRef();
      current.set(key, ref);
    }

    return ref;
  }, []);
}
