import { Box } from '@superdispatch/ui-lab';
import { ReactNode } from 'react';

export interface PublicOfferPageFooterProps {
  children: ReactNode;
}

export function PublicOfferPageFooter({
  children,
}: PublicOfferPageFooterProps) {
  return (
    <Box
      marginLeft={['small', 'auto', 'auto']}
      marginRight={['small', 'auto', 'auto']}
      paddingTop="medium"
      paddingBottom="medium"
      minWidth={`${/* two buttons + gap */ 172 * 2 + 16}px`}
    >
      {children}
    </Box>
  );
}
