import { SidebarMenuItem, SidebarMenuItemProps } from '@superdispatch/ui-lab';
import { forwardRef } from 'react';
import { useMatch, useNavigate } from './react-router-6';

export interface SidebarMenuItemLinkProps extends SidebarMenuItemProps {
  to: string;
}

export const SidebarMenuItemLink = forwardRef<
  HTMLDivElement,
  SidebarMenuItemLinkProps
>(({ to, onClick, selected, ...props }, ref) => {
  const navigate = useNavigate();
  const match = useMatch(to);

  return (
    <SidebarMenuItem
      {...props}
      ref={ref}
      selected={typeof selected === 'boolean' ? selected : !!match}
      onClick={(event) => {
        onClick?.(event);

        if (!event.defaultPrevented) {
          navigate(to);
        }
      }}
    />
  );
});

SidebarMenuItemLink.displayName = 'SidebarMenuItemLink';
