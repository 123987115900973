import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import { Button } from '@superdispatch/ui-lab';

interface PlanUpdateDialogProps {
  open: boolean;
  onClose: () => void;
  onClick: () => void;
}

export function PlanUpdateDialog({
  open,
  onClick,
  onClose,
}: PlanUpdateDialogProps) {
  return (
    <Dialog maxWidth="xs" fullWidth={true} open={open} onClose={onClose}>
      <DialogTitle>Plan Update</DialogTitle>

      <DialogContent>
        After adding a new driver your plan will be updated
      </DialogContent>

      <DialogActions>
        <Button variant="neutral" onClick={onClose}>
          Cancel
        </Button>

        <Button autoFocus={true} onClick={onClick}>
          Add Driver
        </Button>
      </DialogActions>
    </Dialog>
  );
}
