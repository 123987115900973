import { Inline } from '@superdispatch/ui';
import { Box, Button, TextBox } from '@superdispatch/ui-lab';
import { ErrorIcon } from 'shared/icons/ErrorIcon';
import { useNavigate } from 'shared/routing/react-router-6';

export function DriverTrialExpiredBanner() {
  const navigate = useNavigate();
  return (
    <Box
      width="100%"
      borderTopWidth="small"
      borderTopColor="Silver400"
      paddingLeft="large"
      paddingRight="large"
      paddingTop="small"
      paddingBottom="small"
    >
      <Inline space="small" verticalAlign="center" horizontalAlign="center">
        <Inline space="xsmall" verticalAlign="center" horizontalAlign="center">
          <ErrorIcon />

          <TextBox color="primary" variant="body">
            <b>Trial has Expired.</b> Upgrade your plan to access advanced load
            management features.
          </TextBox>
        </Inline>
        <Button
          variant="neutral"
          onClick={() => {
            navigate('/settings/subscription');
          }}
        >
          Upgrade
        </Button>
      </Inline>
    </Box>
  );
}
