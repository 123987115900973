import * as Cookies from 'js-cookie';
import { useMemo } from 'react';

export function getCookie(name: string): string | undefined {
  // Avoid private storage errors
  try {
    return Cookies.get(name);
  } catch {
    return undefined;
  }
}

export function useCookie(name: string): string | undefined {
  return useMemo(() => getCookie(name), [name]);
}
