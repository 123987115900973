import { Inline, Snackbar, Stack } from '@superdispatch/ui';
import { Alert, Button } from '@superdispatch/ui-lab';
import { useEffect, useState } from 'react';
import { trackEvent } from 'shared/helpers/Analytics';
import { isChromium } from 'shared/helpers/BrowserHelpers';
import {
  CHROME_EXTENSION_URL,
  useCheckChromeExtension,
} from 'shared/helpers/ExtensionHelper';
import { LocalStore } from 'shared/helpers/Store';
import { openExternalURL } from 'shared/helpers/URLHelpers';
import { useFlag } from 'shared/settings/FeatureToggles';
import styled from 'styled-components';
import { listenToLoadEvents } from '../../../carrier/loads/data/LoadEvents';

const AlertSnackbar = styled(Snackbar)`
  left: 112px;
  bottom: 32px;
  & .SD-SnackbarContent-root {
    max-width: 512px;
    width: 100%;
    padding: 0;
    & .SD-SnackbarContent-message {
      padding: 0;
    }
  }
  @media print {
    display: none;
  }
`;

interface ExtensionReminderSnackBarStore {
  time: number;
  count: number;
}

const EXTENSION_REMINDER_SNACKBAR_KEY = 'extensionReminderSnackBarShowDate';

const getLocalStorageData = () => {
  const oldData = LocalStore.get(EXTENSION_REMINDER_SNACKBAR_KEY);
  if (!oldData) {
    return null;
  }
  return JSON.parse(oldData) as ExtensionReminderSnackBarStore;
};

const getShowedCount = () => {
  const data = getLocalStorageData();
  if (!data) {
    return 0;
  }
  return data.count;
};

const setShowDate = () => {
  const currentDate = new Date();
  const currentTime = currentDate.getTime();
  const data = {
    time: currentTime,
    count: 1,
  };
  const oldData = getLocalStorageData();
  if (oldData) {
    const { count } = oldData;
    data.count = count + 1;
  }

  LocalStore.set(EXTENSION_REMINDER_SNACKBAR_KEY, JSON.stringify(data));
};

const hasAccessShow = () => {
  const data = getLocalStorageData();
  if (!data) {
    return true;
  }
  const { count, time } = data;
  if (count > 3) {
    return false;
  }
  const currentDate = new Date();
  const currentTime = currentDate.getTime();
  const diffMinutes = (currentTime - time) / 60000;
  const THREE_DAYS_MINUTES = 4320;
  return diffMinutes > THREE_DAYS_MINUTES;
};

const useIsExtensionReminderSnackBar = (): boolean => {
  const isEnabled = useFlag('extension_alert_experiment');
  const hasExtensionInstalled = useCheckChromeExtension();

  return isEnabled && !hasExtensionInstalled && isChromium() && hasAccessShow();
};

export function ChromeExtensionReminderSnackBar() {
  const showedCount = getShowedCount();
  const isAccessShow = useIsExtensionReminderSnackBar();
  const [isShow, setIsShow] = useState<boolean>(false);

  useEffect(
    () =>
      listenToLoadEvents((eventType) => {
        if (eventType === 'imported' && isAccessShow) {
          setIsShow(true);
        }
      }),
    [isAccessShow],
  );

  const close = () => {
    trackEvent('Carrier Clicked No, Thanks', {
      utm_medium: 'alert_banner',
    });
    setShowDate();
    setIsShow(false);
  };

  const addToChrome = () => {
    trackEvent('Carrier Clicked Install for Chrome', {
      utm_medium: 'alert_banner',
    });
    openExternalURL(CHROME_EXTENSION_URL, { target: '_blank' });
    setShowDate();
    setIsShow(false);
  };

  if (!isShow) {
    return null;
  }

  return (
    <AlertSnackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      open={isShow}
    >
      <Alert severity="info" onClose={close}>
        <Stack space="small">
          <span>
            Want to import loads faster? Try our chrome extension. Lightning
            fast imports with a click of a button.
          </span>
          <Inline>
            <Button size="small" onClick={addToChrome}>
              Add to Chrome
            </Button>
            <Button variant="neutral" size="small" onClick={close}>
              {showedCount === 3 ? 'Don’t Show Again' : 'Not Now'}
            </Button>
          </Inline>
        </Stack>
      </Alert>
    </AlertSnackbar>
  );
}
