import { Color } from '@superdispatch/ui';
import { createSvgIcon } from 'shared/icons/IconUtils';

export const EncryptIcon = createSvgIcon(
  'EncryptIcon',
  <path
    d="M6.99999 10H8.99999L8.61666 7.85004C8.83888 7.73893 9.01388 7.57782 9.14166 7.36671C9.26943 7.1556 9.33332 6.92226 9.33332 6.66671C9.33332 6.30004 9.20277 5.98615 8.94166 5.72504C8.68054 5.46393 8.36666 5.33337 7.99999 5.33337C7.63332 5.33337 7.31943 5.46393 7.05832 5.72504C6.79721 5.98615 6.66666 6.30004 6.66666 6.66671C6.66666 6.92226 6.73055 7.1556 6.85832 7.36671C6.9861 7.57782 7.1611 7.73893 7.38332 7.85004L6.99999 10ZM7.99999 14.6667C6.45555 14.2778 5.18055 13.3917 4.17499 12.0084C3.16943 10.625 2.66666 9.08893 2.66666 7.40004V3.33337L7.99999 1.33337L13.3333 3.33337V7.40004C13.3333 9.08893 12.8305 10.625 11.825 12.0084C10.8194 13.3917 9.54443 14.2778 7.99999 14.6667ZM7.99999 13.2667C9.15555 12.9 10.1111 12.1667 10.8667 11.0667C11.6222 9.96671 12 8.74449 12 7.40004V4.25004L7.99999 2.75004L3.99999 4.25004V7.40004C3.99999 8.74449 4.37777 9.96671 5.13332 11.0667C5.88888 12.1667 6.84443 12.9 7.99999 13.2667Z"
    fill={Color.Dark100}
  />,
  {
    viewBox: '0 0 16 16',
    style: { height: '16px', width: '16px', fill: 'none' },
  },
);
