import {
  IconButton,
  InputAdornment,
  StandardTextFieldProps,
  TextField,
} from '@material-ui/core';
import { Launch } from '@material-ui/icons';
import { Inline, useUID } from '@superdispatch/ui';
import { Button, formatBytes, toBytes } from '@superdispatch/ui-lab';
import accepts from 'attr-accept';
import React, { MouseEvent, useEffect, useMemo, useRef, useState } from 'react';
import { MobileAppBridge } from 'shared/data/MobileAppBridge';
import { logWarning } from 'shared/helpers/ErrorTracker';

//text/plain added as mime type, because .txt extension allow to choose all text file formats in webkit browsers.
export const ACCEPTED_FILE_TYPES =
  '.jpeg, .jpg, .png, .heic, .heif, .webp, .pdf, .csv, text/plain, .doc, .docx, .xls, .xlsx';
export const MAX_FILE_SIZE = toBytes(20, 'mb');

export interface FileFieldProps
  extends Omit<StandardTextFieldProps, 'onChange'> {
  name: string;
  disabled?: boolean;
  label?: string;
  value?: string | File | null;
  onChange?: (value: File | null) => void;
  accept?: HTMLInputElement['accept'];
  maxSize?: number;
  pending?: boolean;
}

export function FileField({
  id,
  name,
  value = null,
  onChange,
  disabled: disabledProp,
  accept = ACCEPTED_FILE_TYPES,
  maxSize = MAX_FILE_SIZE,
  label,
  pending,
  error: errorProp,
  helperText,
  ...rest
}: FileFieldProps) {
  const uid = useUID();
  const [previewURL, setPreviewURL] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const fileName = useMemo(
    () => (typeof value === 'string' ? value.split('/').pop() : value?.name),
    [value],
  );
  const disabled = disabledProp || pending;

  function openInput() {
    if (inputRef.current) {
      inputRef.current.focus();
      inputRef.current.click();
    }
  }

  function handleFile({ target }: React.ChangeEvent<HTMLInputElement>) {
    const file = target.files?.[0];
    if (!file) {
      return;
    }

    if (!accepts(file, accept)) {
      setError(`File type must be: ${accept}`);
      logWarning('Unsupported Media Type in FileField', {
        fileType: file.type,
      });
      return;
    }

    if (maxSize && file.size > maxSize) {
      setError(`Attachment size should be less than ${formatBytes(maxSize)}`);
      return;
    }

    onChange?.(file);
    setError(null);
  }

  useEffect(() => {
    if (!value) setError(null);
  }, [value]);

  useEffect(() => {
    if (!(value instanceof File)) {
      setPreviewURL(value);

      return;
    }

    const nextPreviewURL = URL.createObjectURL(value);

    setPreviewURL(nextPreviewURL);

    return () => {
      URL.revokeObjectURL(nextPreviewURL);
    };
  }, [value]);

  return (
    <div>
      <input
        value=""
        ref={inputRef}
        type="file"
        accept={accept}
        hidden={true}
        disabled={disabled}
        onChange={handleFile}
      />

      <TextField
        {...rest}
        error={Boolean(error) || errorProp}
        helperText={
          error ||
          helperText ||
          (!errorProp && `Upload file less than ${formatBytes(MAX_FILE_SIZE)}`)
        }
        id={id || uid}
        name={name}
        fullWidth={true}
        disabled={disabled}
        label={label}
        value={fileName || ''}
        inputProps={{ onClick: openInput }}
        InputProps={{
          readOnly: true,
          startAdornment: (
            <InputAdornment position="start">
              <Button
                variant="neutral"
                size="small"
                disabled={disabled}
                pending={pending}
                onClick={openInput}
              >
                Choose file
              </Button>
            </InputAdornment>
          ),
          endAdornment: previewURL && (
            <InputAdornment position="end">
              <Inline space="xxsmall" verticalAlign="center" noWrap={true}>
                <IconButton
                  aria-label={`${label} preview link`}
                  href={previewURL}
                  target="_blank"
                  size="small"
                  disabled={disabled}
                  onClick={(event: MouseEvent<HTMLAnchorElement>) => {
                    MobileAppBridge.interceptLinkClick(event);
                  }}
                >
                  <Launch color="action" fontSize="small" />
                </IconButton>

                <Button
                  variant="neutral"
                  size="small"
                  disabled={disabled}
                  onClick={() => {
                    onChange?.(null);
                  }}
                >
                  Remove
                </Button>
              </Inline>
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
}
