import { FormikCheckboxField } from '@superdispatch/forms';
import { Stack } from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import { useAppFormik } from 'shared/form/AppFormik';
import {
  FormikDrawer,
  FormikDrawerActions,
  FormikDrawerContent,
} from 'shared/form/FormikDrawer';
import { yupBoolean } from 'shared/utils/YupUtils';
import { loadDeliveryEditSchema } from '../data/LoadActionsDTO';
import {
  formatLoadStatus,
  isEmptyLoadLeg,
  loadVenueSchema,
} from '../data/LoadDTO';
import { trackLoadsEvent } from '../data/LoadsAnalytics';
import { useLoadDetails, useLoadsAPI, useLoadsCache } from '../data/LoadsAPI';
import { useLoadStageFromBackURL } from '../view/ViewLoadHeaderActions';
import { LoadDeliveryForm } from './LoadDeliveryForm';

const editLoadDeliverySchema = loadDeliveryEditSchema.shape({
  $copyToCustomer: yupBoolean(),
});

interface EditLoadDeliveryDrawerContentProps {
  loadGUID: string;
  onClose: () => void;
}

function EditLoadDeliveryDrawerContent({
  onClose,
  loadGUID,
}: EditLoadDeliveryDrawerContentProps) {
  const { data } = useLoadDetails(loadGUID);
  const { invalidateLoads } = useLoadsCache();
  const { editLoadDelivery, editLoadCustomer } = useLoadsAPI();
  const loadStage = useLoadStageFromBackURL();
  const formik = useAppFormik({
    initialValues: data?.delivery,
    validationSchema: editLoadDeliverySchema,
    async onSubmit({ $copyToCustomer, ...values }) {
      const response = await editLoadDelivery(loadGUID, values);

      if ($copyToCustomer) {
        await editLoadCustomer(loadGUID, {
          save_customer_contact: false,
          venue: loadVenueSchema.cast(values.venue),
        });
      }

      return response;
    },
    onSubmitSuccess() {
      onClose();
      invalidateLoads();

      trackLoadsEvent({
        name: 'Carrier Edited Load',
        utm_medium: loadStage,
        utm_content: 'View Page',
        load_status: formatLoadStatus(data?.status),
        is_created_by_broker: !!data?.is_created_by_broker,
        delivery_information:
          !data?.delivery || isEmptyLoadLeg(data.delivery) ? 'Added' : 'Edited',
      });
    },
    getSuccessMessage(_, { $copyToCustomer }) {
      return $copyToCustomer
        ? 'Successfully changed delivery and customer information'
        : 'Successfully changed delivery information.';
    },
    getErrorMessage({ message }) {
      return message || 'Something went wrong, contact support.';
    },
  });

  return (
    <FormikDrawerContent
      formik={formik}
      title="Delivery Information"
      onClose={onClose}
      actions={<FormikDrawerActions />}
    >
      <Box maxWidth="424px">
        <Stack space="large">
          <FormikCheckboxField
            name="$copyToCustomer"
            label="Copy to Customer"
            helperText="Customer will have the same information as below"
          />

          <LoadDeliveryForm />
        </Stack>
      </Box>
    </FormikDrawerContent>
  );
}

interface EditLoadDeliveryDrawerProps
  extends EditLoadDeliveryDrawerContentProps {
  open: boolean;
}

export function EditLoadDeliveryDrawer({
  open,
  onClose,
  loadGUID,
}: EditLoadDeliveryDrawerProps) {
  return (
    <FormikDrawer open={open} onClose={onClose}>
      <EditLoadDeliveryDrawerContent loadGUID={loadGUID} onClose={onClose} />
    </FormikDrawer>
  );
}
