import { useSnackbarStack } from '@superdispatch/ui';
import { Box, Button } from '@superdispatch/ui-lab';
import { parseURITemplate } from '@superdispatch/uri';
import { getOS } from 'shared/helpers/BrowserHelpers';
import { trackDriversEvent } from '../../data/DriversAnalytics';

const ANDROID_HOST = 'carrier.superdispatch.com';
const ANDROID_SCHEME = 'superdispatch';
const APP_PACKAGE = 'com.mysuperdispatch.android';

function launchAppOnAndroidDevice() {
  const url = parseURITemplate(
    'intent://{ANDROID_HOST}/#Intent;scheme={ANDROID_SCHEME};package={APP_PACKAGE};end',
    {
      ANDROID_HOST,
      ANDROID_SCHEME,
      APP_PACKAGE,
    },
  );

  window.location.replace(url);
}

function launchAppOnAppleDevice() {
  // if the redirect fails we need to navigate users to app store to get the app
  setTimeout(() => {
    window.location.replace(
      'https://itunes.apple.com/us/app/super-dispatch-bol-app-epod/id921598152?mt=8',
    );
  }, 500);

  window.location.replace('mysuperdispatch://');
}

function launchSDApp(fallbackFn?: () => void) {
  const os = getOS();
  if (os === 'android') {
    launchAppOnAndroidDevice();
  } else if (os === 'ios') {
    launchAppOnAppleDevice();
  } else {
    fallbackFn?.();
  }
}

export function DriverActivationDownloadAppSection() {
  const { addSnackbar } = useSnackbarStack();

  const handleDownloadApp = () => {
    trackDriversEvent({ name: 'Carrier clicked Download the App' });

    launchSDApp(() => {
      addSnackbar('Unsupported OS, contact support');
    });
  };

  return (
    <Box
      padding="medium"
      position="sticky"
      bottom={0}
      borderTopColor="Black20"
      borderTopWidth="small"
      backgroundColor="White"
    >
      <Button
        fullWidth={true}
        variant="primary"
        onClick={() => {
          handleDownloadApp();
        }}
      >
        Download the App
      </Button>
    </Box>
  );
}
