import {
  Dialog,
  DialogTitle as MuiDialogTitle,
  IconButton,
  Typography,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { Color, Column, Columns } from '@superdispatch/ui';
import styled from 'styled-components';
import { useSaveOnboardingWithErrorHandler } from '../data/useSaveOnboardingWithErrorHandler';
import { OnboardingContent } from './OnboardingContent';

interface OnboardingDialogProps {
  isOpen: boolean;
  onClose?: () => void;
}

export function OnboardingDialog({ isOpen, onClose }: OnboardingDialogProps) {
  const { mutateAsync: saveStep } = useSaveOnboardingWithErrorHandler();
  const handleClose = () => {
    onClose?.();
  };
  return (
    <Dialog
      fullWidth={true}
      maxWidth="lg"
      open={isOpen}
      onClose={() => {
        void saveStep({
          stepName: 'getting_started_guide',
          status: 'completed',
        }).then(handleClose);
      }}
      keepMounted={true}
    >
      {isOpen && <OnboardingDialogContent onClose={handleClose} />}
    </Dialog>
  );
}

interface OnboardingDialogContentProps {
  onClose: () => void;
}

const DialogTitle = styled(MuiDialogTitle)`
  border-bottom: 1px solid ${Color.Silver400};
`;

function OnboardingDialogContent({ onClose }: OnboardingDialogContentProps) {
  return (
    <>
      <DialogTitle>
        <Columns align="top">
          <Column width="fluid">
            <Typography variant="h3">Getting Started</Typography>
          </Column>

          <Column width="content">
            <IconButton size="small" aria-label="close" onClick={onClose}>
              <Close />
            </IconButton>
          </Column>
        </Columns>
      </DialogTitle>
      <OnboardingContent onClose={onClose} />
    </>
  );
}
