import { Typography } from '@material-ui/core';
import {
  FormikCurrencyField,
  FormikDateField,
  FormikNumberField,
  FormikTextField,
  SuspendedFormikPhoneField,
} from '@superdispatch/forms';
import { Column, Columns, Stack } from '@superdispatch/ui';
import { TextBox } from '@superdispatch/ui-lab';
import { useFormikContext } from 'formik';
import { MobileAppBridge } from 'shared/data/MobileAppBridge';
import {
  EMPTY_ERROR_MESSAGE,
  FormikMediaField,
} from 'shared/form/FormikMediaField';
import { FieldAdditionalMessages } from 'shared/modules/carrier-profile/core/AdditionalMessages';
import { useVerifiedCarrierApplicationStatus } from 'shared/modules/carrier-profile/VerifiedCarrierApplicationStatus';
import { useCarrierSettings } from 'shared/settings/CarrierSettingsAPI';
import styled from 'styled-components';
import { CargoInsuranceDTO } from '../data/CarrierProfileDTO';

// Customize styles of calendar icon to make date value fully visible
const StyledFormikDateField = styled(FormikDateField)`
  .MuiInputAdornment-positionEnd {
    margin-left: 0;
    width: 25px;
  }
`;

export function ProfileCargoInsuranceForm() {
  const { data } = useVerifiedCarrierApplicationStatus();
  const { data: settings } = useCarrierSettings();
  const formik = useFormikContext<CargoInsuranceDTO>();

  const isEditingDisabled =
    !settings?.drivers_can_edit_carrier_info && MobileAppBridge.isInjected();
  const cargoInsuranceRevokingReasons =
    data?.verified_carrier_application.revoking_reasons
      ?.cargo_insurance_file_url;

  const cargoInsuranceDecliningReasons =
    data?.verified_carrier_application.declining_reasons
      ?.cargo_insurance_file_url;

  const externalNotes =
    data?.verified_carrier_application.external_notes?.cargo_insurance;

  const isInitialCargoInsuranceFile =
    formik.initialValues.file_url === formik.values.file_url;

  const shouldShowError =
    isInitialCargoInsuranceFile &&
    !!(
      cargoInsuranceDecliningReasons?.length ||
      cargoInsuranceRevokingReasons?.length
    );

  return (
    <Stack space="large">
      <Stack space="small">
        <Stack space="xsmall">
          <FormikMediaField
            label="Certificate of Insurance"
            name="file_url"
            fullWidth={true}
            error={shouldShowError}
            disabled={isEditingDisabled}
            validate={() => (shouldShowError ? EMPTY_ERROR_MESSAGE : undefined)}
          />

          {shouldShowError && cargoInsuranceDecliningReasons && (
            <FieldAdditionalMessages
              variant="error"
              title="Certificate of Insurance declined due to:"
              messages={cargoInsuranceDecliningReasons}
              externalNotes={externalNotes}
            />
          )}

          {shouldShowError && cargoInsuranceRevokingReasons && (
            <FieldAdditionalMessages
              variant="error"
              title="Certificate of Insurance revoked due to:"
              messages={cargoInsuranceRevokingReasons}
              externalNotes={externalNotes}
            />
          )}
        </Stack>

        <Columns space="small" collapseBelow="desktop">
          <Column width={['fluid', '1/3']}>
            <StyledFormikDateField
              format="DateISO"
              label="Expiration Date"
              name="expiration_date"
              fullWidth={true}
              disabled={isEditingDisabled}
            />
          </Column>

          <Column width={['fluid', '1/3']}>
            <FormikNumberField
              label="Cargo Limit"
              name="cargo_limit"
              fullWidth={true}
              disabled={isEditingDisabled}
            />
          </Column>

          <Column width={['fluid', '1/3']}>
            <FormikCurrencyField
              label="Deductible"
              name="deductible_amount"
              fullWidth={true}
              disabled={isEditingDisabled}
            />
          </Column>
        </Columns>

        <Columns space="small" collapseBelow="tablet">
          <Column width={['fluid', '1/2']}>
            <FormikTextField
              label={
                <>
                  Auto Policy Number{' '}
                  <TextBox color="secondary">(Optional)</TextBox>
                </>
              }
              name="auto_policy_number"
              fullWidth={true}
              disabled={isEditingDisabled}
            />
          </Column>

          <Column width={['fluid', '1/2']}>
            <FormikTextField
              label={
                <>
                  Cargo Policy Number{' '}
                  <TextBox color="secondary">(Optional)</TextBox>
                </>
              }
              name="cargo_policy_number"
              disabled={isEditingDisabled}
              fullWidth={true}
            />
          </Column>
        </Columns>
      </Stack>

      <Stack space="small">
        <Typography variant="h4">Agent</Typography>

        <FormikTextField
          label="Name"
          name="agent_name"
          disabled={isEditingDisabled}
          fullWidth={true}
        />

        <Columns space="small" collapseBelow="tablet">
          <Column width={['fluid', '1/2']}>
            <SuspendedFormikPhoneField
              label="Phone"
              name="agent_phone"
              disabled={isEditingDisabled}
              fullWidth={true}
            />
          </Column>

          <Column width={['fluid', '1/2']}>
            <FormikTextField
              label="Email"
              name="agent_email"
              disabled={isEditingDisabled}
              fullWidth={true}
            />
          </Column>
        </Columns>
      </Stack>
    </Stack>
  );
}
