import { trackEvent } from 'shared/helpers/Analytics';

export type SplitLoadsAnalyticsEvent =
  | { name: 'CTMS: Opened Split Order Page' }
  | {
      name: 'Carrier Splited Order';
      utm_medium: string;
      is_created_by_broker: boolean;
      number_of_vehicles?: number;
      number_of_splited_loads: number;
    };

export function trackSplitLoadsEvent(event: SplitLoadsAnalyticsEvent) {
  const { name, ...props } = event;
  trackEvent(name, { ...props });
}
