import { Tooltip } from '@material-ui/core';
import { Color, Inline } from '@superdispatch/ui';
import { DriveawayIcon } from 'shared/icons/DriveawayIcon';
import { EnclosedTrailerIcon } from 'shared/icons/EnclosedTrailerIcon';
import { InoperableIcon } from 'shared/icons/InoperableIcon';

interface Props {
  isInoperable: boolean;
  transportType?: 'OPEN' | 'ENCLOSED' | 'DRIVEAWAY';
}

export function VehicleConditionIcon({ isInoperable, transportType }: Props) {
  return (
    <Inline space="xxsmall">
      {isInoperable && (
        <Tooltip title="Inoperable Vehicle" enterDelay={800}>
          <InoperableIcon
            fontSize="small"
            htmlColor={Color.Red500}
            aria-label="Inoperable Vehicle icon"
          />
        </Tooltip>
      )}

      {transportType === 'ENCLOSED' && (
        <Tooltip title="Enclosed Trailer Required" enterDelay={800}>
          <EnclosedTrailerIcon
            fontSize="small"
            htmlColor={Color.Yellow500}
            aria-label="Enclosed Trailer Required icon"
          />
        </Tooltip>
      )}

      {transportType === 'DRIVEAWAY' && (
        <Tooltip title="Driveaway" enterDelay={800}>
          <DriveawayIcon
            fontSize="small"
            htmlColor={Color.Purple500}
            aria-label="Driveaway icon"
          />
        </Tooltip>
      )}
    </Inline>
  );
}
