import { Color } from '@superdispatch/ui';
import { createSvgIcon } from 'shared/icons/IconUtils';

export const BookNowIcon = createSvgIcon(
  'BoltIcon',
  <path
    d="M11.2757 1L3 8.67984H8.12864L3.76337 15L13.5 6.66039H8.25L11.2757 1Z"
    fill="currentColor"
  />,
  {
    viewBox: '0 0 16 16',
    style: { height: '16px', width: '16px' },
    htmlColor: Color.White,
  },
);
