import { Card, CardContent, Typography } from '@material-ui/core';
import { formatDate } from '@superdispatch/dates';
import {
  Column,
  Columns,
  Inline,
  Stack,
  Tag,
  useSnackbarStack,
} from '@superdispatch/ui';
import { Box, Button } from '@superdispatch/ui-lab';
import { useState } from 'react';
import { DesktopIcon } from 'shared/icons/DesktopIcon';
import {
  useCreateQBDConnectionMutation,
  useDisconnectQbdMutation,
  useQuickbookDesktopConnectionDetails,
} from '../data/SettingsAPI';
import { SettingsDisconnectQuickbooksDialog } from './SettingsDisconnectQuickbooksDialog';
import { SettingsQuickbookDestopGuide } from './SettingsQuickbookDestopGuide';
import { SettingsQuickbooksDesktopForm } from './SettingsQuickbooksDesktopForm';

export function SettingsQuickbookDesktop() {
  const { addSnackbar } = useSnackbarStack();
  const [isShowDesktopConnection, setShowDesktopConnection] = useState(false);
  const [isOpen, setOpen] = useState(false);

  const { mutate: create, isLoading: isCreating } =
    useCreateQBDConnectionMutation({
      onSuccess: () => {
        handleShow();
      },
      onError: (e) => {
        addSnackbar(e.message, { variant: 'error' });
        handleShow();
      },
    });

  const { data: desktop } = useQuickbookDesktopConnectionDetails();
  const { mutate: disconnectQbd, isLoading } = useDisconnectQbdMutation({
    onSuccess: () => {
      onClose();
    },
  });

  function handleShow() {
    setShowDesktopConnection(!isShowDesktopConnection);
  }

  function handleDisconnect() {
    setOpen(true);
  }

  function onClose() {
    setOpen(false);
  }

  return (
    <>
      <Card>
        <CardContent>
          <Stack space="small">
            <Columns space="small">
              <Column width="fluid">
                <Stack>
                  <Inline space="xsmall">
                    <DesktopIcon />
                    <Typography variant="h4">QuickBooks Desktop</Typography>
                  </Inline>
                  {desktop?.connected && (
                    <Inline space="xxsmall" verticalAlign="center">
                      <Tag color="green" variant="subtle">
                        Connected
                      </Tag>
                      <Typography color="textSecondary">
                        on{' '}
                        {formatDate(desktop.connected_at, {
                          variant: 'Date',
                        })}
                      </Typography>
                    </Inline>
                  )}
                  {!isShowDesktopConnection && !desktop?.connected && (
                    <Typography>
                      Connect to QuickBooks Desktop Pro, Premier, or Enterprise
                      Editions. This includes the US and Canadian Editions.
                    </Typography>
                  )}
                </Stack>
              </Column>
              <Column width="content">
                {desktop?.connected ? (
                  <Button onClick={handleDisconnect} variant="critical">
                    Disconnect
                  </Button>
                ) : (
                  <Button
                    pending={isCreating}
                    onClick={() => {
                      if (!isShowDesktopConnection) {
                        create();
                      } else {
                        setShowDesktopConnection(false);
                      }
                    }}
                    variant={isShowDesktopConnection ? 'critical' : 'success'}
                  >
                    {isShowDesktopConnection
                      ? 'Cancel Connecting'
                      : 'Show How to Connect'}
                  </Button>
                )}
              </Column>
            </Columns>
            {isShowDesktopConnection && !desktop?.connected && (
              <Box paddingTop="small">
                <SettingsQuickbookDestopGuide />
              </Box>
            )}
            {desktop?.connected && <SettingsQuickbooksDesktopForm />}
          </Stack>
        </CardContent>
      </Card>

      <SettingsDisconnectQuickbooksDialog
        isLoading={isLoading}
        open={isOpen}
        type="Desktop"
        onClose={onClose}
        onDisconnect={disconnectQbd}
      />
    </>
  );
}
