import { useDeepEqualValue, useEventHandler } from '@superdispatch/hooks';
import { useEffect, useState } from 'react';
import WebSocket from 'reconnecting-websocket';

interface WebSocketConnection {
  ws: WebSocket;
  refs: Set<number>;
}

export type WebSocketAction =
  | { type: 'open'; ws: WebSocket }
  | { type: 'close'; ws: WebSocket }
  | { type: 'error'; ws: WebSocket }
  | { type: 'message'; ws: WebSocket; event: WebSocketEventMap['message'] };

export type WebSocketDispatch = (action: WebSocketAction) => void;

const pool = new Map<string, WebSocketConnection>();

export function connect(id: number, url: string): WebSocket {
  let connection = pool.get(url);

  if (!connection) {
    connection = {
      refs: new Set(),
      ws: new WebSocket(url, [], {
        maxRetries: 20,
        minReconnectionDelay: 5000,
      }),
    };
    pool.set(url, connection);
  }

  connection.refs.add(id);

  return connection.ws;
}

export function disconnect(id: number, url: string): void {
  const connection = pool.get(url);

  if (connection) {
    connection.refs.delete(id);

    if (connection.refs.size === 0) {
      pool.delete(url);
      connection.ws.close();
    }
  }
}

export type WebSocketStatus = 'unknown' | 'open';

export interface WebSocketState {
  status: WebSocketStatus;
}

export function useWebSocket(
  url: null | string,
  fn: WebSocketDispatch,
): WebSocketState {
  const [status, setStatus] = useState<WebSocketStatus>('unknown');
  const dispatch = useEventHandler(fn);

  useEffect(() => {
    if (!url || 'Cypress' in window || !('WebSocket' in window)) {
      setStatus('unknown');

      return;
    }

    const id = Math.random();
    const ws = connect(id, url);

    const onOpen = () => {
      setStatus('open');
      dispatch({ ws, type: 'open' });
    };

    const onClose = () => {
      setStatus('unknown');
      dispatch({ ws, type: 'close' });
    };

    const onError = () => {
      setStatus('unknown');
      dispatch({ ws, type: 'error' });
    };

    const onMessage = (event: WebSocketEventMap['message']) => {
      dispatch({ ws, type: 'message', event });
    };

    // Manually trigger open event when we get WebSocket from the pool.
    if (ws.readyState === ws.OPEN) onOpen();

    ws.addEventListener('open', onOpen);
    ws.addEventListener('close', onClose);
    ws.addEventListener('error', onError);
    ws.addEventListener('message', onMessage);

    return () => {
      disconnect(id, url);

      ws.removeEventListener('open', onOpen);
      ws.removeEventListener('close', onClose);
      ws.removeEventListener('error', onError);
      ws.removeEventListener('message', onMessage);
    };
  }, [url, dispatch]);

  return useDeepEqualValue({ status });
}
