import { yupBoolean, yupObject, yupString } from 'shared/utils/YupUtils';
import { InferType } from 'yup';

export type QuickBooksSettingsDTO = InferType<typeof quickBooksSettingsSchema>;
export const quickBooksSettingsSchema = yupObject({
  connected: yupBoolean(),
  connected_at: yupString(),
  invoice_item: yupString(),
  qwc_url: yupString(),
  password: yupString(),
});
