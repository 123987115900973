import { useAPISocketMessages } from 'shared/api/APISocketMessages';
import { usePushNotification } from 'shared/helpers/notifications/PushNotification';
import { loadboardNotificationMessageSchema } from './LoadboardNotificationMessageDTO';

export function useLoadboardNotifications() {
  const notify = usePushNotification();

  useAPISocketMessages(({ data, meta: { type, message_guid } }) => {
    if (type !== 'load_alert' && type !== 'declined_request') return;

    const message = loadboardNotificationMessageSchema.cast(data);
    const actionURL = new URL('/loadboard/loads', window.location.origin);

    actionURL.searchParams.set('current_load_guid', message.load_guid);
    actionURL.searchParams.set('utm_source', 'Web SLB Push');
    actionURL.searchParams.set(
      'utm_medium',
      type === 'load_alert' ? 'Load Alert' : 'Declined Load',
    );

    notify(
      message_guid,
      message.subject,
      message.body,
      actionURL.href,
      type === 'load_alert' ? 'View Details' : 'Request Load Again',
    );
  });
}
