import { useLayoutEffect } from 'react';

interface ViewportMetaOptions {
  width: number;
  initialScale?: number;
  shrinkToFit?: boolean;
  userScalable?: boolean;
  maximumScale?: number;
  minimumScale?: number;
  disabled?: boolean;
}

export function useViewportMeta({
  width,
  initialScale = 0,
  maximumScale = 1,
  minimumScale = 0.25,
  shrinkToFit = false,
  userScalable = true,
  disabled,
}: ViewportMetaOptions) {
  useLayoutEffect(() => {
    if (disabled) {
      return;
    }
    const meta = document.querySelector('meta[name="viewport"]');

    if (!meta) {
      return;
    }

    const prevContent = meta.getAttribute('content');

    meta.setAttribute(
      'content',
      [
        `width=${width}`,
        `initial-scale=${initialScale}`,
        `maximum-scale=${maximumScale}`,
        `minimum-scale=${minimumScale}`,
        `shrink-to-fit=${shrinkToFit ? 'yes' : 'no'}`,
        `user-scalable=${userScalable ? 'yes' : 'no'}`,
      ].join(', '),
    );

    return () => {
      if (prevContent == null) {
        meta.removeAttribute('content');
      } else {
        meta.setAttribute('content', prevContent);
      }
    };
  }, [
    width,
    shrinkToFit,
    initialScale,
    userScalable,
    maximumScale,
    minimumScale,
    disabled,
  ]);
}
