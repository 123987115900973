import {
  Checkbox,
  CircularProgress,
  Dialog,
  DialogTitle,
  Divider,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  TextField,
  Typography,
} from '@material-ui/core';
import { Close, Search as SearchIcon } from '@material-ui/icons';
import { Column, Columns, useSnackbarStack } from '@superdispatch/ui';
import { Button } from '@superdispatch/ui-lab';
import { useMemo, useState } from 'react';
import { formatPlural } from 'shared/helpers/IntlHelpers';
import { FormattedRelativeTimeToNow } from 'shared/ui/FormattedRelativeTimeToNow';
import styled from 'styled-components';
import { getDriverLabel } from '../drivers/data/DriverPageUtils';
import {
  useAddNonTrackingDrivers,
  useNonTrackingDriverPage,
} from './data/TrackingAPI';
import { TrackingDriverDTO } from './data/TrackingDTO';

const StyledListItemText = styled(ListItemText)`
  word-break: break-all;

  & .MuiListItemText-primary {
    width: 80%;
  }
`;

const StyledTextField = styled(TextField)`
  & .MuiOutlinedInput-input {
    padding: 12px 6px 12px 0;
    outline: none;
  }
`;
const CenteredWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledListItem = styled(ListItem)`
  padding-left: 24px;
  padding-right: 24px;
`;

interface Props {
  onClose: () => void;
}

const Styled1 = styled.div`
  padding: 8px 24px;
`;
const Styled2 = styled.div`
  height: 360px;
  overflow: auto;
  padding: 8px 0;
`;
const Styled3 = styled.div`
  padding: 16px 24px;
  text-align: right;
`;

export function TrackingAddDialog({ onClose }: Props) {
  const { addSnackbar } = useSnackbarStack();
  const [searchValue, setSearchValue] = useState<string>('');
  const [selectedIds, setSelectedIds] = useState<Set<number>>(
    () => new Set<number>(),
  );

  const {
    data: nonTrackingDrivers,
    isError,
    isLoading,
  } = useNonTrackingDriverPage();

  const addDriver = useAddNonTrackingDrivers({
    onSuccess() {
      onClose();
      addSnackbar(
        `${formatPlural(
          selectedIds.size,
          'Driver',
          'Drivers',
        )} successfully added to tracking`,
        { variant: 'success' },
      );
    },
    onError() {
      addSnackbar('Error occurred while adding drivers', {
        variant: 'error',
      });
    },
  });

  const toggleDriver = (id: number) => {
    const nextDriverIds = new Set(selectedIds);

    if (nextDriverIds.has(id)) {
      nextDriverIds.delete(id);
    } else {
      nextDriverIds.add(id);
    }

    setSelectedIds(nextDriverIds);
  };

  const filteredDrivers = useMemo((): undefined | TrackingDriverDTO[] => {
    if (!nonTrackingDrivers) {
      return undefined;
    }

    const query = searchValue.toLocaleLowerCase().trim();

    if (!query) {
      return nonTrackingDrivers;
    }

    return nonTrackingDrivers.filter((d) => {
      if (d.name) {
        return d.name.toLocaleLowerCase().includes(query);
      }
      return d.email?.toLocaleLowerCase().includes(query);
    });
  }, [nonTrackingDrivers, searchValue]);

  return (
    <Dialog open={true} onClose={onClose} maxWidth="xs" fullWidth={true}>
      <DialogTitle disableTypography={true}>
        <Columns align="center">
          <Column width="fluid">
            <Typography variant="h3">Add drivers</Typography>
          </Column>
          <Column width="content" aria-label="close">
            <IconButton size="small" onClick={onClose}>
              <Close />
            </IconButton>
          </Column>
        </Columns>
      </DialogTitle>

      <Styled1>
        <StyledTextField
          fullWidth={true}
          placeholder="Search drivers"
          variant="outlined"
          value={searchValue}
          onChange={({ target: { value } }) => {
            setSearchValue(value);
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Styled1>

      <Styled2>
        {isLoading ? (
          <CenteredWrapper>
            <CircularProgress size={24} />
          </CenteredWrapper>
        ) : isError ? (
          <CenteredWrapper>
            <div>Error occurred while loading drivers</div>
          </CenteredWrapper>
        ) : !filteredDrivers ? null : filteredDrivers.length === 0 ? (
          <CenteredWrapper>
            <div>No drivers found</div>
          </CenteredWrapper>
        ) : (
          <List>
            {filteredDrivers.map((driver) => {
              const driverLabel = getDriverLabel({
                name: driver.name || '',
                email: driver.email || '',
              });

              return (
                <StyledListItem
                  key={driver.id}
                  button={true}
                  dense={true}
                  onClick={() => {
                    toggleDriver(driver.id);
                  }}
                >
                  <StyledListItemText
                    primary={driverLabel}
                    primaryTypographyProps={{ id: `driver-${driver.id}` }}
                    secondary={
                      !driver.last_activity ? (
                        'No activity'
                      ) : (
                        <>
                          Last activity:{' '}
                          <FormattedRelativeTimeToNow
                            date={driver.last_activity}
                          />
                        </>
                      )
                    }
                  />
                  <ListItemSecondaryAction>
                    <Checkbox
                      inputProps={{
                        'aria-labelledby': `driver-${driver.id}`,
                      }}
                      color="primary"
                      checked={selectedIds.has(driver.id)}
                      onChange={() => {
                        toggleDriver(driver.id);
                      }}
                    />
                  </ListItemSecondaryAction>
                </StyledListItem>
              );
            })}
          </List>
        )}
      </Styled2>

      <Divider />

      <Styled3>
        {!searchValue &&
          nonTrackingDrivers &&
          nonTrackingDrivers.length > 0 && (
            <Button
              variant="neutral"
              disabled={addDriver.isLoading}
              onClick={() => {
                if (nonTrackingDrivers.length === selectedIds.size) {
                  setSelectedIds(new Set());
                } else {
                  setSelectedIds(new Set(nonTrackingDrivers.map((x) => x.id)));
                }
              }}
            >
              {nonTrackingDrivers.length === selectedIds.size
                ? 'Unselect All'
                : 'Select All'}
            </Button>
          )}
        {'  '}
        <Button
          pending={addDriver.isLoading}
          onClick={() => {
            if (selectedIds.size > 0) {
              addDriver.mutate(Array.from(selectedIds));
            } else {
              addSnackbar('Please select driver', { variant: 'error' });
            }
          }}
        >
          Add
        </Button>
      </Styled3>
    </Dialog>
  );
}
