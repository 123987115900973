import { yupObject, yupString } from 'shared/utils/YupUtils';
import { InferType } from 'yup';

export type RatingNotificationMessageDTO = InferType<
  typeof ratingNotificationMessageSchema
>;

export const ratingNotificationMessageSchema = yupObject({
  body: yupString(),
  ratings_url: yupString(),
  subject: yupString(),
});
