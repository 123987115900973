import {
  ButtonBase,
  CircularProgress,
  InputAdornment,
  List,
  TextField,
  Typography,
} from '@material-ui/core';
import {
  ArrowLeft,
  ArrowRight,
  Search as SearchIcon,
} from '@material-ui/icons';
import { Color, Stack, useUID } from '@superdispatch/ui';
import { Box, Button } from '@superdispatch/ui-lab';
import { useMemo, useState } from 'react';
import styled from 'styled-components';
import { TrackingDriverDTO } from './data/TrackingDTO';
import { TrackingAddDialog } from './TrackingAddDialog';
import { TrackingSidebarItem } from './TrackingSidebarItem';

const SIDEBAR_WIDTH = '368px';

const Wrapper = styled.section`
  height: 100vh;
  width: ${SIDEBAR_WIDTH};
  position: relative;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  background-color: ${Color.White};
  border-right: 1px solid ${Color.Silver400};

  margin-left: 0;
  transition: margin-left 225ms ease-out;

  &[aria-hidden='true'] {
    margin-left: -${SIDEBAR_WIDTH};
  }
`;

const Content = styled.div`
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
`;

const CenteredWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ToggleButton = styled(ButtonBase)`
  position: absolute;
  right: -26px;
  background: ${Color.White};
  z-index: 9;
  border: 1px solid ${Color.Silver400};
  border-left: none;
  top: 54px;
  height: 44px;
  display: flex;
  align-items: center;
  box-shadow: 2px 0 5px 0 #e6e6e6;
  cursor: pointer;
  transition: 0.1s ease-in-out;

  &:hover {
    background-color: #fafafa;
  }
`;

interface TrackingSidebarProps {
  drivers?: TrackingDriverDTO[];
  checkedDriversIds: Set<number>;
  setCheckedDriversIds: (driversIds: Set<number>) => void;
  onDriverHover: (driverId?: number) => void;
}

export function TrackingSidebar({
  drivers,
  checkedDriversIds,
  setCheckedDriversIds,
  onDriverHover,
}: TrackingSidebarProps) {
  const headingID = useUID();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [searchValue, setSearchValue] = useState('');

  const filteredDrivers = useMemo(() => {
    if (drivers) {
      const query = searchValue.toLocaleLowerCase().trim();

      if (query) {
        return drivers.filter(({ name, email }) => {
          if (name) {
            return name.toLocaleLowerCase().includes(query);
          }

          return email?.toLocaleLowerCase().includes(query);
        });
      }
    }

    return drivers;
  }, [drivers, searchValue]);

  return (
    <Wrapper aria-hidden={!isVisible} aria-labelledby={headingID}>
      {isDialogOpen && (
        <TrackingAddDialog
          onClose={() => {
            setIsDialogOpen(false);
          }}
        />
      )}

      <ToggleButton
        aria-expanded={isVisible}
        aria-label="Toggle Drivers Sidebar Visibility"
        onClick={() => {
          setIsVisible(!isVisible);
        }}
      >
        {isVisible ? <ArrowLeft /> : <ArrowRight />}
      </ToggleButton>
      <Box padding="small">
        <Stack space="small">
          <Typography variant="h3" id={headingID}>
            Drivers
          </Typography>

          <TextField
            fullWidth={true}
            variant="outlined"
            placeholder="Search drivers"
            value={searchValue}
            onChange={(event) => {
              setSearchValue(event.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Stack>
      </Box>

      <Content>
        <List>
          {!filteredDrivers ? (
            <CenteredWrapper>
              <CircularProgress size={24} />
            </CenteredWrapper>
          ) : filteredDrivers.length === 0 ? (
            <CenteredWrapper>No drivers found</CenteredWrapper>
          ) : (
            filteredDrivers.map((driver) => (
              <TrackingSidebarItem
                key={driver.id}
                checked={checkedDriversIds.has(driver.id)}
                driver={driver}
                onCheck={() => {
                  const nextCheckedDriversIds = new Set(checkedDriversIds);

                  if (checkedDriversIds.has(driver.id)) {
                    nextCheckedDriversIds.delete(driver.id);
                  } else {
                    nextCheckedDriversIds.add(driver.id);
                  }

                  setCheckedDriversIds(nextCheckedDriversIds);
                }}
                onMouseEnter={() => {
                  onDriverHover(driver.id);
                }}
              />
            ))
          )}
        </List>
      </Content>

      <Box padding="small" borderTopWidth="small" borderTopColor="Black20">
        <Button
          fullWidth={true}
          onClick={() => {
            setIsDialogOpen(true);
          }}
        >
          Add Driver
        </Button>
      </Box>
    </Wrapper>
  );
}
