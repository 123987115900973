import { Card, CardContent, Link, Typography } from '@material-ui/core';
import { Check, Info } from '@material-ui/icons';
import { useFormikEnhanced } from '@superdispatch/forms';
import { Color, Column, Columns, Inline, Stack } from '@superdispatch/ui';
import { Alert, Button, TextBox } from '@superdispatch/ui-lab';
import { Form, FormikProvider } from 'formik';
import { useState } from 'react';
import { useCreditCard } from 'shared/errors/PaymentService';
import { ChargebeeResponse } from 'shared/helpers/ChargebeeWeb';
import { EncryptIcon } from 'shared/icons/EncryptIcon';
import { EncryptOutlinedIcon } from 'shared/icons/EncryptOutlinedIcon';
import { billingAddressFormErrorsGetter } from 'shared/modules/subscription/core/SubscriptionUtils';
import { trackSubscriptionEvent } from 'shared/modules/subscription/data/SubscriptionAnalytics';
import {
  useBillingAddress,
  useSubscriptionAPI,
  useSubscriptionSettings,
} from 'shared/modules/subscription/SubscriptionAPI';
import { LocationState } from 'shared/modules/subscription/SubscriptionSalesTaxDrawer';
import { useNavigate } from 'shared/routing/react-router-6';
import { ChargebeeEmptyPlaceholder } from 'shared/ui/ChargebeeEmptyPlaceholder';
import { subscriptionSchema } from '../data/SettingsDTO';
import { SubscriptionForm } from './SubscriptionForm';

export function SubscriptionPaymentCard() {
  const navigate = useNavigate();
  const { data: subscriptionBillingAddress } = useBillingAddress();
  const { data: subscriptionDetails } = useSubscriptionSettings();
  const { cardRef, isChargebeeAvailable, addCreditCard } = useCreditCard(
    subscriptionDetails?.client_token,
  );
  const [billingAddress, setBillingAddress] = useState<{
    zip: string;
    country: string;
    state: string;
  }>({ zip: '', country: '', state: '' });
  const { saveBillingAddress } = useSubscriptionAPI();

  const formik = useFormikEnhanced({
    validateOnBlur: false,
    validationSchema: subscriptionSchema,
    initialValues: {
      zip: subscriptionBillingAddress?.billing_zip || '',
      country: subscriptionBillingAddress?.billing_country || 'US',
      state: subscriptionBillingAddress?.billing_state || '',
      city: subscriptionBillingAddress?.billing_city || '',
      address: subscriptionBillingAddress?.billing_address || '',
    },
    getFormErrors: (error) => {
      return billingAddressFormErrorsGetter(error);
    },
    onSubmit: async (values) => {
      setBillingAddress(values);
      if (formik.dirty) {
        await saveBillingAddress({
          billing_country: values.country,
          billing_zip: values.zip,
          billing_state: values.state,
          billing_address: values.address,
          billing_city: values.city,
        });
      }
      return addCreditCard({});
    },
    onSubmitSuccess: (values: ChargebeeResponse) => {
      const {
        token,
        vaultToken,
        additional_information: { braintree },
      } = values;

      const locationState: LocationState = {
        token,
        vaultToken,
        billingAddress,
        cardDetails: braintree,
      };

      if (formik.dirty) {
        trackSubscriptionEvent({
          name: 'Carrier Edited Subscription Billing Address',
        });
      }

      navigate('/settings/subscription/upgrade_plan', { state: locationState });
    },
  });

  return (
    <Card aria-label="Add Payment Information">
      <CardContent>
        <Stack space="medium">
          <Typography variant="h3">Add Payment Information</Typography>
          <Stack space="xsmall">
            <Columns space="xxsmall" align="top">
              <Column width="content">
                <EncryptIcon />
              </Column>
              <Column width="fluid">
                <TextBox color="secondary" variant="body">
                  We use industry-standard encryption and security protocols to
                  safeguard your information. Your card details are not stored
                  on our internal servers for your protection.
                </TextBox>
              </Column>
            </Columns>
            <Columns space="xxsmall" align="top">
              <Column width="content">
                <Check fontSize="small" htmlColor={Color.Dark100} />
              </Column>
              <Column width="fluid">
                <TextBox color="secondary" variant="body">
                  We accept all major credit cards
                </TextBox>
              </Column>
            </Columns>
          </Stack>
          <FormikProvider value={formik}>
            <Form>
              <Stack space="large">
                {isChargebeeAvailable ? (
                  <SubscriptionForm ref={cardRef} />
                ) : (
                  <ChargebeeEmptyPlaceholder />
                )}
                <Stack space="small">
                  <Button
                    pending={formik.isSubmitting}
                    fullWidth={true}
                    startIcon={<EncryptOutlinedIcon />}
                    type="submit"
                    size="large"
                  >
                    Continue to Upgrade
                  </Button>
                  <Inline horizontalAlign="center">
                    <Typography
                      align="center"
                      color="textSecondary"
                      variant="caption"
                    >
                      By continuing, you agree to our{' '}
                      <Link
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.superdispatch.com/terms-of-service"
                      >
                        Terms of Service
                      </Link>{' '}
                      and{' '}
                      <Link
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://support.superdispatch.com/en/articles/6975505-carrier-tms-how-do-i-cancel-my-subscription"
                      >
                        Refund Policy
                      </Link>
                    </Typography>
                  </Inline>

                  {formik.status.type === 'rejected' && (
                    <Alert
                      icon={<Info fontSize="small" htmlColor={Color.Red500} />}
                      severity="critical"
                    >
                      {formik.status.payload.message}
                    </Alert>
                  )}
                </Stack>
              </Stack>
            </Form>
          </FormikProvider>
        </Stack>
      </CardContent>
    </Card>
  );
}
