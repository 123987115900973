import { dequal } from 'dequal';
// eslint-disable-next-line you-dont-need-lodash-underscore/clone-deep
import { cloneDeep } from 'lodash-es';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function shallowDiff<TUpdated extends Record<string, any>>(
  updates: Partial<TUpdated>,
  original: TUpdated | undefined,
): Partial<TUpdated> {
  if (original == null) return updates;
  let diff: Partial<TUpdated> = {};
  for (const [key, value] of Object.entries(updates) as Array<
    [keyof TUpdated, TUpdated[keyof TUpdated]]
  >) {
    if (!dequal(value, original[key])) {
      // Clone only when it's required
      if (diff === updates) diff = { ...updates };
      diff[key] = value;
    }
  }
  return diff;
}

export function tryParseJSON<T>(json: string): T | undefined {
  try {
    return JSON.parse(json) as T;
  } catch {
    return undefined;
  }
}

export function tryStringifyJSON<T>(data: T): string | undefined {
  try {
    return JSON.stringify(data);
  } catch {
    return undefined;
  }
}

export const isEmptyObject = (obj: unknown): obj is Record<never, never> =>
  typeof obj === 'object' && obj !== null && Object.keys(obj).length === 0;

export function deepClone<T>(value: T): T {
  return cloneDeep(value);
}
