import { useFormikEnhanced } from '@superdispatch/forms';
import { Column, Columns, useSnackbarStack } from '@superdispatch/ui';
import { Button } from '@superdispatch/ui-lab';
import { APIResponse } from 'shared/api/APIClient';
import { APIError } from 'shared/api/APIError';
import { isWebView } from 'shared/constants/AppTypeConstants';
import { useInitialValues } from 'shared/form/AppFormik';
import { DocumentTitle } from 'shared/layout/DocumentTitle';
import { ProfileTransporterPlateRegistrationForm } from './core/ProfileTransporterPlateRegistrationForm';
import {
  useSubmitVerificationDocument,
  VerificationDialogs,
} from './core/VerificationDialogs';
import { trackCarrierProfileEvent } from './data/CarrierProfileAnalytics';
import {
  useCarrierProfileAPI,
  useTransporterPlateRegistration,
} from './data/CarrierProfileAPI';
import {
  TransporterPlateRegistrationDTO,
  transporterPlateRegistrationSchema,
} from './data/CarrierProfileDTO';
import { ProfilePageLayout } from './ProfilePageLayout';

export function ProfileTransporterPlateRegistrationPage() {
  const { addSnackbar } = useSnackbarStack();
  const { updateTransporterPlateRegistration } = useCarrierProfileAPI();
  const { data, isLoading } = useTransporterPlateRegistration({
    onError: () => {
      addSnackbar('Failed to fetch Transporter Plate Registration', {
        variant: 'error',
      });
    },
  });

  const initialValues = useInitialValues(
    transporterPlateRegistrationSchema,
    data,
  );

  const formik = useFormikEnhanced<
    TransporterPlateRegistrationDTO,
    APIResponse
  >({
    initialValues,
    validationSchema: transporterPlateRegistrationSchema,
    onSubmit: updateTransporterPlateRegistration,
    onSubmitSuccess: () => {
      addSnackbar('Transporter Plate Registration was successfully updated', {
        variant: 'success',
      });

      trackCarrierProfileEvent({
        name: 'Carrier Updated Carrier Info',
        utm_medium: 'Carrier Profile',
        utm_content: 'Transporter Plate Registration',
      });
    },
    onSubmitFailure: ({ message }: APIError) => {
      addSnackbar(
        `Failed to update Transporter Plate Registration: ${message}`,
        {
          variant: 'error',
        },
      );
    },
  });

  const submitVerificationForm = useSubmitVerificationDocument(formik);

  return (
    <>
      <DocumentTitle title="Transporter Plate Registration - Profile" />

      <VerificationDialogs formik={formik} />

      <ProfilePageLayout
        formik={formik}
        title="Transporter Plate Registration"
        isLoading={isLoading}
        headerEndActions={
          <Button
            type="button"
            onClick={submitVerificationForm}
            pending={formik.isSubmitting}
            disabled={!formik.dirty}
            fullWidth={isWebView}
          >
            Save
          </Button>
        }
      >
        <Columns space="xxlarge" collapseBelow="desktop">
          <Column>
            <ProfileTransporterPlateRegistrationForm />
          </Column>
        </Columns>
      </ProfilePageLayout>
    </>
  );
}
