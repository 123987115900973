import { Color } from '@superdispatch/ui';
import { createSvgIcon } from 'shared/icons/IconUtils';

export const ClockIcon = createSvgIcon(
  'ClockIcon',
  <>
    <rect width="32" height="32" rx="16" fill={Color.Silver200} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 16C6 10.48 10.47 6 15.99 6C21.52 6 26 10.48 26 16C26 21.52 21.52 26 15.99 26C10.47 26 6 21.52 6 16ZM8 16C8 20.42 11.58 24 16 24C20.42 24 24 20.42 24 16C24 11.58 20.42 8 16 8C11.58 8 8 11.58 8 16ZM15 11H16.5V16.25L21 18.92L20.25 20.15L15 17V11Z"
      fill={Color.Dark100}
    />
  </>,
  { viewBox: '0 0 32 32', style: { height: '32px', width: '32px' } },
);
