import { stringifyDate } from '@superdispatch/dates';
import { toFinite } from 'lodash-es';
import { DateTime } from 'luxon';
import {
  yupArray,
  yupBoolean,
  yupDateString,
  yupEnum,
  yupNumber,
  yupObject,
  yupString,
} from 'shared/utils/YupUtils';
import { InferType } from 'yup';

export type ReportsCompanyRevenueShipperDTO = InferType<
  typeof reportsCompanyRevenueShipperSchema
>;
export const reportsCompanyRevenueShipperSchema = yupObject({
  company_name: yupString(),
  order_count: yupNumber().transform((value) => toFinite(value)),
  revenue: yupNumber().transform((value) => toFinite(value)),
});

export type ReportsCompanyRevenueDTO = InferType<
  typeof reportsCompanyRevenueSchema
>;
export const reportsCompanyRevenueSchema = yupObject({
  total: yupNumber().transform((value) => toFinite(value)),
  shippers: yupArray(reportsCompanyRevenueShipperSchema),
});

export type ReportsCompanyRevenueParamsDTO = InferType<
  typeof reportsCompanyRevenueParamsSchema
>;
export const ACCOUNTING_METHOD = ['cash', 'accrual'];
export const reportsCompanyRevenueParamsSchema = yupObject({
  start_date: yupDateString('DateTimeISO').default(() =>
    stringifyDate(DateTime.local().startOf('month')),
  ),
  end_date: yupDateString('DateTimeISO').default(() =>
    stringifyDate(DateTime.local().endOf('day')),
  ),
  accounting_method: yupEnum(ACCOUNTING_METHOD).transform((value) =>
    ACCOUNTING_METHOD.includes(value) ? (value as string) : 'cash',
  ),
});

export type ReportsPeriodDTO = InferType<typeof reportsPeriodSchema>;
export const reportsPeriodSchema = yupObject({
  start_date: yupDateString('DateTimeISO'),
  end_date: yupDateString('DateTimeISO'),
});

export type ReportsDriverPayDTO = InferType<typeof driverPaySchema>;
export const driverPaySchema = yupObject({
  guid: yupString(),
  name: yupString(),
  email: yupString(),
  pay_rate: yupNumber().min(0).max(100).nullable(false),
  revenue: yupNumber().default(0).nullable(false),
});

export type ReportsAccountsPayableShipperDTO = InferType<
  typeof accountsPayableShipperSchema
>;
const accountsPayableShipperSchema = yupObject({
  company_name: yupString(),
  order_count: yupNumber(),
  amount: yupNumber(),
});

export type ReportsAccountsPayableDTO = InferType<typeof accountsPayableSchema>;
export const accountsPayableSchema = reportsPeriodSchema.shape({
  total: yupNumber().default(0),
  shippers: yupArray(accountsPayableShipperSchema),
});

export type ReportsAccountsReceivableParamsDTO = InferType<
  typeof accountsReceivableParamsSchema
>;
export const accountsReceivableParamsSchema = reportsPeriodSchema.shape({
  missing: yupBoolean(),
  past_due: yupBoolean(),
  sort_by: yupString().default('-invoice_date'),
});

export type ReportsDriverPayQueryParamsDTO = InferType<
  typeof driverPayQueryParamsSchema
>;
export const driverPayQueryParamsSchema = reportsPeriodSchema.shape({
  only_active: yupBoolean().default(false),
});

export type ReportsDriverPayParamsDTO = InferType<typeof driverPayParamsSchema>;
export const driverPayParamsSchema = driverPayQueryParamsSchema.shape({
  page: yupNumber().nullable(false).default(1),
  page_size: yupNumber().nullable(false).default(20),
  start_date: yupDateString('DateTimeISO').default(() =>
    stringifyDate(DateTime.local().startOf('month')),
  ),
  end_date: yupDateString('DateTimeISO').default(() =>
    stringifyDate(DateTime.local().endOf('day')),
  ),
});

export type DriverPayTotalReportDTO = InferType<
  typeof driverPayTotalReportSchema
>;
export const driverPayTotalReportSchema = yupObject({
  total_revenue: yupNumber(),
  total_fee: yupNumber(),
});

export const customReportFilters = {
  loadTypeFilters: new Map([
    ['all', 'All Loads'],
    ['active', 'Active Loads'],
    ['archived', 'Archived Loads'],
  ]),
  loadStatusFilters: new Map([
    ['all', 'All'],
    ['new', 'New'],
    ['picked_up', 'Picked Up'],
    ['delivered', 'Delivered'],
  ]),
  orderByFilters: new Map([
    ['created_at', 'Creation Date'],
    ['delivery_date', 'Actual Delivery Date'],
  ]),
};

export const customReportFields = {
  generalInfo: new Map([
    ['number', 'Load ID'],
    ['internal_load_id', 'Internal Load ID'],
    ['created_at', 'Creation Date'],
    ['dispatcher', 'Dispatcher'],
    ['trip', 'Trip'],
    ['has_terminal', 'Has Terminal'],
    ['is_dispatched_to_carrier', 'Dispatched to Carrier'],
  ]),
  vehicleInfo: new Map([
    ['vehicle_info', 'Vehicle Year, Make, Model'],
    ['vin', 'VIN'],
    ['lot_number', 'Lot Number'],
  ]),
  pickupInfo: new Map([
    ['pickup_name', 'Pickup Name'],
    ['pickup_address', 'Pickup Address'],
    ['pickup_date', 'Scheduled Pickup Date'],
    ['pickedup_date', 'Actual Pickup Date'],
    ['buyer_number', 'Buyer Number'],
    ['pickup_notes', 'Pickup Notes'],
    ['pickup_phone', 'Pickup Phone'],
    ['pickup_phone2', 'Pickup Mobile'],
  ]),
  deliveryInfo: new Map([
    ['delivery_name', 'Delivery Name'],
    ['delivery_address', 'Delivery Address'],
    ['delivery_date', 'Scheduled Delivery Date'],
    ['delivered_date', 'Actual Delivery Date'],
    ['delivery_notes', 'Delivery Notes'],
    ['delivery_phone', 'Delivery Phone'],
    ['delivery_phone2', 'Delivery Mobile'],
  ]),
  brokerInfo: new Map([
    ['broker_name', 'Shipper Name'],
    ['broker_phone', 'Shipper Phone'],
  ]),
  driverInfo: new Map([['driver', 'Driver Name']]),
  payment: new Map([
    ['price', 'Price'],
    ['expenses', 'Expenses'],
    ['broker_fee', 'Broker Fee'],
    ['driver_pay', 'Driver Pay'],
    ['payment_method', 'Payment Method'],
    ['paid_amount', 'Paid Amount'],
    ['paid_method', 'Paid Method'],
    ['payment_reference_number', 'Reference Number'],
    ['receipt_date', 'Receipt Date'],
    ['payment_notes', 'Payment Notes'],
    ['payment_terms', 'Payment Terms'],
    ['payment_status', 'Payment Status'],
    ['invoice_id', 'Invoice Number'],
    ['invoice_date', 'Invoice Date'],
    ['invoice_notes', 'Invoice Notes'],
  ]),
};

export type ReportsCustomDTO = InferType<typeof customReportSchema>;
export const customReportSchema = yupObject({
  creation_period: reportsPeriodSchema.nullable(true).default(null),
  delivery_period: reportsPeriodSchema.nullable(true).default(null),
  drivers: yupArray(yupString()),
  dispatchers: yupArray(yupString()),
  fields: yupArray(yupString()),
  guid: yupString(),
  include_not_assigned: yupBoolean(),
  include_removed_drivers: yupBoolean(),
  load_filter: yupEnum(['all', 'active', 'archived']),
  load_status: yupEnum(['all', 'new', 'picked_up', 'delivered']),
  name: yupString(),
  order_by: yupEnum(['created_at', 'delivery_date']),
  report_email: yupString().required(),
});

export type SendDriverPayReportRequestDTO = InferType<
  typeof sendDriverPayReportRequestSchema
>;
export const sendDriverPayReportRequestSchema =
  driverPayQueryParamsSchema.shape({
    email: yupString().email().required(),
  });

export type ReportsAccountsReceivableShipperDTO = InferType<
  typeof reportsAccountsReceivableShipperSchema
>;
const reportsAccountsReceivableShipperSchema = yupObject({
  company_name: yupString(),
  total_due_order_count: yupNumber(),
  total_due_amount: yupNumber(),
  past_due_order_count: yupNumber(),
  past_due_amount: yupNumber(),
});

export type ReportsAccountsReceivableDTO = InferType<
  typeof reportsAccountsReceivableSchema
>;
export const reportsAccountsReceivableSchema = reportsPeriodSchema.shape({
  current_due: yupNumber(),
  past_due: yupNumber(),
  total_due: yupNumber(),
  shippers: yupArray(reportsAccountsReceivableShipperSchema).defined(),
  shippers_with_missing_info: yupArray(
    reportsAccountsReceivableShipperSchema,
  ).defined(),
});
