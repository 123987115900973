import { createSvgIcon } from 'shared/icons/IconUtils';

export const SuperCarrierBadgeIcon = createSvgIcon(
  'SuperCarrierBadge',
  <>
    <path
      d="M8.96483 15.0512L6.82123 16.9647L5.92158 14.2358L3.10842 14.8211L3.69377 12.008L0.964827 11.1083L2.87833 8.9647L0.964827 6.82111L3.69377 5.92146L3.10842 3.1083L5.92158 3.69364L6.82123 0.964705L8.96483 2.87821L11.1084 0.964705L12.0081 3.69364L14.8212 3.1083L14.2359 5.92146L16.9648 6.82111L15.0513 8.9647L16.9648 11.1083L14.2359 12.008L14.8212 14.8211L12.0081 14.2358L11.1084 16.9647L8.96483 15.0512Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.4823 6.29803L7.64145 10.036L6.16944 8.63006L5.40918 9.40408L7.61878 11.563H7.62827L12.2536 7.05626L11.4823 6.29803Z"
      fill="white"
      stroke="white"
      strokeWidth="0.2"
    />
  </>,
  { viewBox: '0 0 18 18' },
);
