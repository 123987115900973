import { ArrowForward } from '@material-ui/icons';
import { Inline, Stack, useSnackbarStack } from '@superdispatch/ui';
import { Button, TextBox } from '@superdispatch/ui-lab';
import { useAPISocketMessages } from 'shared/api/APISocketMessages';
import { ONE_MINUTE, THIRTY_SECONDS } from 'shared/constants/NumberConstants';
import { formatCurrency } from 'shared/helpers/IntlHelpers';
import { openExternalURL } from 'shared/helpers/URLHelpers';
import {
  useOffersCache,
  useOffersCount,
} from '../../../carrier/offers/offers/data/OffersAPI';
import {
  counterOfferNotificationSchema,
  offerCountDataSchema,
} from '../../../carrier/offers/offers/data/OffersDTO';

export function useLoadOffer() {
  const { setOffersCount } = useOffersCache();
  const { addSnackbar, clearStack } = useSnackbarStack();

  const { status: webSocketStatus } = useAPISocketMessages((payload) => {
    if (payload.meta.type === 'offers-count') {
      const data = offerCountDataSchema.cast(payload.data);
      if (data.count != null) setOffersCount(data.count);
    }

    if (payload.meta.type === 'counter-offer') {
      const offer = counterOfferNotificationSchema.cast(payload.data);
      addSnackbar(
        <Stack space="xxsmall">
          <Inline verticalAlign="center">
            New counter offer from {offer.counter_offer.load.pickup_state}
            <ArrowForward fontSize="small" />
            {offer.counter_offer.load.delivery_state}
          </Inline>
          <TextBox color="white">
            for {formatCurrency(offer.counter_offer.load.price)}
          </TextBox>
        </Stack>,
        {
          autoHideDuration: 300000,
          action: (
            <Button
              variant="inverted"
              onClick={() => {
                clearStack();

                openExternalURL('/tms/offers/{guid}/{?source}', {
                  guid: offer.counter_offer.guid,
                  source: 'ctms',
                  target: '_blank',
                });
              }}
            >
              View Details
            </Button>
          ),
        },
      );
    }
  });

  const shouldSkipPolling = 'Cypress' in window || webSocketStatus === 'open';
  const { data } = useOffersCount({
    staleTime: shouldSkipPolling ? Infinity : THIRTY_SECONDS,
    refetchInterval: shouldSkipPolling ? false : ONE_MINUTE,
  });

  return data;
}
