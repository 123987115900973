import { formatPaymentTerm } from '@superdispatch/sdk';
import { Color, Inline } from '@superdispatch/ui';
import { SuperPayLabel } from 'shared/helpers/PaymentHelpers';
import styled from 'styled-components';

const GradientBorderTerm = styled.div`
  background: linear-gradient(${Color.White} 0 0) padding-box,
    linear-gradient(90deg, ${Color.Blue500} 0%, #fe4a49 100%) border-box;
  padding: 1px 2px 1px 2px;
  border: 1px solid transparent;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
`;

export const GradientBorderBox = styled.div<{ isActive: boolean }>`
  background: linear-gradient(${Color.White} 0 0) padding-box,
    linear-gradient(90deg, ${Color.Blue500} 0%, #fe4a49 100%) border-box;
  padding: ${({ isActive }) => (isActive ? '0 5px 0 5px' : 0)};
  border: ${({ isActive }) => (isActive ? '1px solid transparent' : 0)};
  border-radius: 4px;
`;

interface PaymentGradientBorderProps {
  method?: string;
  terms?: string;
  source?: 'Load Details' | 'Load List' | 'Suggested Load';
  superPayLabelTarget?: '_blank' | '_self';
}

export function PaymentGradientBorder({
  method,
  terms,
  source,
  superPayLabelTarget,
}: PaymentGradientBorderProps) {
  return method === 'superpay' ? (
    <Inline noWrap={false} verticalAlign="center" space="xxsmall">
      <SuperPayLabel source={source} target={superPayLabelTarget} />
      {terms && (
        <GradientBorderTerm>
          {formatPaymentTerm(terms, { fallback: terms })}
        </GradientBorderTerm>
      )}
    </Inline>
  ) : (
    <span>{formatPaymentTerm(terms, { fallback: terms, short: true })}</span>
  );
}
