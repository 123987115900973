import { useEffect, useMemo } from 'react';
import { useIntercomTourManager } from 'shared/helpers/IntercomTourManager';
import { useFlag } from 'shared/settings/FeatureToggles';
import { useExperimentState } from '../experiments/ExperimentsAPI';
import { useOnboardingDialogState } from './core/OnboardingWrapper';
import { useOnboardingDetails } from './data/OnboardingAPI';
import { ONBOARDING_TOURS } from './data/OnboardingConstants';
import { OnboardingStep } from './data/OnboardingDTO';

// steps by priority
const ONBOARDING_STEPS = [
  'welcome_to_ctms',
  'question_how_can_ctms_make_your_job_easier',
  'question_what_brings_you_to_ctms',
  'question_experience_with_ctms',
  'team_invitation',
  'install_chrome_extension',
  'getting_started_guide',
] as const;

function getLastPendingStepName(steps: OnboardingStep['steps']) {
  for (const step of ONBOARDING_STEPS) {
    if (steps?.[step]?.status === 'pending') {
      return step;
    }
  }
  return null;
}

interface OnboardingTourHandlerProps {
  tourName: keyof typeof ONBOARDING_TOURS;
  enabled?: boolean;
  onSettled?: () => void;
}

export function useOnboardingTourHandler({
  tourName,
  enabled,
  onSettled,
}: OnboardingTourHandlerProps) {
  const isOnboardingEnabled = useFlag('ctms_onboarding');
  const { data: onboarding } = useOnboardingDetails({
    enabled: isOnboardingEnabled,
  });
  const { isInExperiment } = useExperimentState('Onboarding', {
    options: { enabled: isOnboardingEnabled },
  });
  const { openOnboardingDialog } = useOnboardingDialogState();
  const tourStatus = onboarding?.steps?.[tourName]?.status;
  const isInitial = onboarding?.steps?.team_invitation?.status === 'pending';
  const lastPendingStepName = useMemo(
    () => (onboarding?.steps ? getLastPendingStepName(onboarding.steps) : null),
    [onboarding?.steps],
  );

  const { startTour } = useIntercomTourManager({
    onClose: (id) => {
      if (id === ONBOARDING_TOURS[tourName]) {
        onSettled?.();
      }
    },
    onCompleted: (id) => {
      if (id === ONBOARDING_TOURS[tourName]) {
        onSettled?.();
      }
    },
  });

  useEffect(() => {
    if (lastPendingStepName === 'getting_started_guide') {
      openOnboardingDialog();
      return;
    }

    const isEnabled = typeof enabled === 'boolean' ? enabled : true;

    if (
      isOnboardingEnabled &&
      tourStatus === 'pending' &&
      isInExperiment &&
      isEnabled &&
      !isInitial
    ) {
      startTour(ONBOARDING_TOURS[tourName]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isOnboardingEnabled,
    startTour,
    tourName,
    isInExperiment,
    enabled,
    lastPendingStepName,
    tourStatus,
    isInitial,
  ]);
}
