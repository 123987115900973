import { useEventHandler } from '@superdispatch/hooks';
import { dequal } from 'dequal';
import { FormikProvider } from 'formik';
import { useEffect, useMemo } from 'react';
import { APIResponse } from 'shared/api/APIClient';
import { useAppFormik } from 'shared/form/AppFormik';
import { useFormikComposerForm } from 'shared/form/FormikComposer';
import { computePrice } from 'shared/utils/NumberUtils';
import { yupArray, yupObject } from 'shared/utils/YupUtils';
import { LoadVehiclesCard } from '../core/LoadVehiclesCard';
import {
  isEmptyLoadVehicleDTO,
  LoadVehicleCreateDTO,
  loadVehicleCreateSchema,
  LoadVehicleEditDTO,
  loadVehicleEditSchema,
} from '../data/LoadActionsDTO';
import { LoadDTO } from '../data/LoadDTO';
import { useVehiclesAPI } from '../data/VehiclesAPI';

const editLoadPageVehiclesSchema = yupObject({
  vehicles: yupArray(loadVehicleEditSchema).required(),
});

export interface EditLoadPageVehiclesProps {
  load: LoadDTO;
  onTotalPriceChange: (totalPrice: number) => void;
  isEnabledEditLoad: boolean;
}

export function EditLoadPageVehicles({
  load,
  onTotalPriceChange,
  isEnabledEditLoad,
}: EditLoadPageVehiclesProps) {
  const [initialValues, initialVehiclesMap] = useMemo(() => {
    const nextInitialValues = { vehicles: [] as unknown[] };
    const nextInitialVehiclesMap = new Map<string, LoadVehicleEditDTO>();

    if (!load.vehicles.length) {
      nextInitialValues.vehicles.push({});
    } else {
      for (const vehicle of load.vehicles) {
        nextInitialValues.vehicles.push(vehicle);
        nextInitialVehiclesMap.set(
          vehicle.guid,
          loadVehicleEditSchema.cast(vehicle),
        );
      }
    }

    return [nextInitialValues, nextInitialVehiclesMap];
  }, [load.vehicles]);

  const { editVehicle, deleteVehicle, bulkCreateVehicles } = useVehiclesAPI();
  const formik = useAppFormik({
    initialValues,
    validationSchema: editLoadPageVehiclesSchema,
    onSubmit(values) {
      const requests: Array<Promise<APIResponse>> = [];
      const toRemove = new Set(initialVehiclesMap.keys());
      const toCreate: LoadVehicleCreateDTO[] = [];

      for (const vehicle of values.vehicles) {
        const initialVehicle = initialVehiclesMap.get(vehicle.guid);

        if (initialVehicle) {
          toRemove.delete(vehicle.guid);
          if (!dequal(vehicle, initialVehicle)) {
            requests.push(
              editVehicle(vehicle.guid, { ...vehicle, load_guid: load.guid }),
            );
          }
        } else if (!isEmptyLoadVehicleDTO(vehicle)) {
          toCreate.push(
            loadVehicleCreateSchema.cast({
              ...vehicle,
              load_guid: load.guid,
            }),
          );
        }
      }

      requests.push(bulkCreateVehicles(toCreate));

      for (const guid of toRemove) {
        requests.push(deleteVehicle(guid));
      }

      return Promise.all(requests).then(
        ([firstResponse]) => firstResponse as APIResponse,
      );
    },
  });

  const nodeRef = useFormikComposerForm('vehicles', formik);

  const handleTotalPriceChange = useEventHandler(onTotalPriceChange);

  useEffect(() => {
    handleTotalPriceChange(computePrice(formik.values.vehicles));
  }, [formik.values.vehicles, handleTotalPriceChange]);

  return (
    <FormikProvider value={formik}>
      <LoadVehiclesCard
        nodeRef={nodeRef}
        isEnabledEditLoad={isEnabledEditLoad}
        hasInspections={load.status !== 'new'}
      />
    </FormikProvider>
  );
}
