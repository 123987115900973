import {
  CardContent,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { Add, Delete, DirectionsCar, Edit } from '@material-ui/icons';
import { formatVehicleType } from '@superdispatch/sdk';
import { CardButton, Color, Inline } from '@superdispatch/ui';
import { Button } from '@superdispatch/ui-lab';
import { useMemo, useState } from 'react';
import { formatCurrency, formatPlural } from 'shared/helpers/IntlHelpers';
import {
  formatCurbWeight,
  formatVehicleDimensions,
  hasVehicleDimension,
} from 'shared/helpers/VehicleHelpers';
import { EnclosedTrailerIcon } from 'shared/icons/EnclosedTrailerIcon';
import { InoperableIcon } from 'shared/icons/InoperableIcon';
import { HiddenForPrint } from 'shared/ui/Print';
import { joinStrings } from 'shared/utils/StringUtils';
import { LoadVehicleDeleteDialog } from '../core/LoadVehicleDeleteDialog';
import { LoadVehicleDisabledTooltip } from '../core/LoadVehicleDisabledTooltip';
import { LoadVehicleDrawer } from '../core/LoadVehicleDrawer';
import { LoadVehicleStatusTag } from '../core/LoadVehicleStatusTag';
import {
  formatLoadStatus,
  LoadDTO,
  LoadVehicleDTO,
  sortVehiclesByStatus,
} from '../data/LoadDTO';
import { trackLoadsEvent } from '../data/LoadsAnalytics';
import { useLoadStageFromBackURL } from './ViewLoadHeaderActions';

interface VehiclesTableRowProps {
  load: LoadDTO;
  layout?: 'print';
  vehicle: LoadVehicleDTO;
  onEditClick: () => void;
  onDeleteClick: () => void;
  isEnabledEditLoad: boolean;
}

function VehiclesTableRow({
  load,
  layout,
  vehicle,
  onEditClick,
  onDeleteClick,
  isEnabledEditLoad,
}: VehiclesTableRowProps) {
  return (
    <TableRow
      aria-label={vehicle.make + ' ' + vehicle.model + ' ' + vehicle.type}
      hover={!!load.can_be_edited}
    >
      <TableCell>
        <Inline space="xsmall">
          <LoadVehicleStatusTag status={vehicle.status} />
          <Typography variant="body1">
            {joinStrings(' ', vehicle.year, vehicle.make, vehicle.model)}
          </Typography>
          <Typography color="textSecondary">
            {vehicle.color}{' '}
            {formatVehicleType(vehicle.type, { fallback: vehicle.type })}
          </Typography>
        </Inline>
        <Inline verticalAlign="center">
          <Typography color="textSecondary">{vehicle.vin}</Typography>
          <Inline>
            {vehicle.is_inoperable && (
              <Tooltip title="Inoperable vehicle" enterTouchDelay={0}>
                <InoperableIcon htmlColor={Color.Red500} />
              </Tooltip>
            )}
            {vehicle.requires_enclosed_trailer && (
              <Tooltip title="Requires enclosed trailer" enterTouchDelay={0}>
                <EnclosedTrailerIcon htmlColor={Color.Yellow500} />
              </Tooltip>
            )}
          </Inline>
        </Inline>
      </TableCell>
      <TableCell>
        {vehicle.curb_weight && (
          <Typography>{formatCurbWeight(vehicle.curb_weight)}</Typography>
        )}
        {hasVehicleDimension(vehicle) && (
          <Typography noWrap={true} color="textSecondary">
            {formatVehicleDimensions(vehicle)}
          </Typography>
        )}
      </TableCell>
      <TableCell>{vehicle.lot_number}</TableCell>
      <TableCell>{!!vehicle.price && formatCurrency(vehicle.price)}</TableCell>
      {layout !== 'print' && isEnabledEditLoad && load.can_be_edited && (
        <TableCell align="right" width={150}>
          <Inline horizontalAlign="right">
            <IconButton onClick={onEditClick} aria-label="edit vehicle">
              <Edit />
            </IconButton>
            <IconButton onClick={onDeleteClick} aria-label="delete vehicle">
              <Delete />
            </IconButton>
          </Inline>
        </TableCell>
      )}
    </TableRow>
  );
}

interface ViewLoadVehiclesProps {
  load: LoadDTO;
  layout?: 'print';
  isEnabledEditLoad?: boolean;
}

type ModalState =
  | { type: 'add'; payload?: never }
  | { type: 'edit'; payload: string }
  | { type: 'delete'; payload: string };

export function ViewLoadVehicles({
  load,
  layout,
  isEnabledEditLoad = true,
}: ViewLoadVehiclesProps) {
  const [modalState, setModalState] = useState<ModalState>();
  const loadStage = useLoadStageFromBackURL();
  const sortedVehicles = useMemo(
    () => sortVehiclesByStatus(load.vehicles),
    [load.vehicles],
  );

  const closeModal = () => {
    setModalState(undefined);
  };

  const getModalStatePayload = <T extends ModalState>(
    type: T['type'],
  ): undefined | T['payload'] =>
    modalState?.type === type ? modalState.payload : undefined;

  return (
    <>
      <LoadVehicleDrawer
        type="add"
        loadGUID={load.guid}
        onClose={closeModal}
        open={modalState?.type === 'add'}
      />

      <LoadVehicleDrawer
        type="edit"
        loadGUID={load.guid}
        onClose={closeModal}
        vehicleGUID={getModalStatePayload('edit')}
      />

      <LoadVehicleDeleteDialog
        loadGUID={load.guid}
        onDismiss={closeModal}
        vehicleGUID={getModalStatePayload('delete')}
        onSubmitSuccess={() => {
          closeModal();
          trackLoadsEvent({
            name: 'Carrier Edited Load',
            utm_medium: loadStage,
            utm_content: 'View Page',
            load_status: formatLoadStatus(load.status),
            is_created_by_broker: !!load.is_created_by_broker,
            vehicles: 'Edited',
          });
        }}
      />

      {!load.vehicles.length ? (
        layout !== 'print' && (
          <HiddenForPrint>
            <CardContent>
              <CardButton
                startIcon={<Add />}
                disabled={!load.can_be_edited || !isEnabledEditLoad}
                onClick={() => {
                  setModalState({ type: 'add' });
                }}
              >
                Add Vehicles
              </CardButton>
            </CardContent>
          </HiddenForPrint>
        )
      ) : (
        <>
          <CardContent aria-label="Vehicles Title">
            <Inline verticalAlign="center" space="xsmall">
              <DirectionsCar />
              <Typography variant="h3">
                {load.vehicles.length}{' '}
                {formatPlural(load.vehicles.length, 'Vehicle', 'Vehicles')}
              </Typography>

              {layout !== 'print' && (
                <HiddenForPrint>
                  <LoadVehicleDisabledTooltip
                    isEnabledEditLoad={isEnabledEditLoad}
                  >
                    <Button
                      aria-label="Add Vehicle"
                      variant="text"
                      disabled={!isEnabledEditLoad}
                      startIcon={<Add />}
                      onClick={() => {
                        setModalState({ type: 'add' });
                      }}
                    >
                      Add
                    </Button>
                  </LoadVehicleDisabledTooltip>
                </HiddenForPrint>
              )}
            </Inline>
          </CardContent>

          <TableContainer>
            <Table aria-label="Vehicles Table" size="small">
              <TableHead>
                <TableRow>
                  <TableCell scope="row">Vehicle</TableCell>
                  <TableCell scope="row">Vehicle Details</TableCell>
                  <TableCell>
                    <Typography noWrap={true} variant="inherit">
                      Lot Number
                    </Typography>
                  </TableCell>
                  <TableCell>Price</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody aria-label="Vehicles List">
                {sortedVehicles.map((vehicle) => (
                  <VehiclesTableRow
                    load={load}
                    layout={layout}
                    vehicle={vehicle}
                    key={vehicle.guid}
                    isEnabledEditLoad={isEnabledEditLoad}
                    onEditClick={() => {
                      setModalState({ type: 'edit', payload: vehicle.guid });
                    }}
                    onDeleteClick={() => {
                      setModalState({ type: 'delete', payload: vehicle.guid });

                      if (load.status !== 'new') {
                        trackLoadsEvent({
                          page: 'view_load',
                          name: 'CTMS: Clicked Delete Vehicle',
                        });
                      }
                    }}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </>
  );
}
