import { IconButton, MenuItem, Typography } from '@material-ui/core';
import { Add, DeleteOutline } from '@material-ui/icons';
import {
  FormikTextField,
  SuspendedFormikPhoneField,
  useFormikEnhanced,
} from '@superdispatch/forms';
import {
  Column,
  Columns,
  Inline,
  Stack,
  useSnackbarStack,
} from '@superdispatch/ui';
import { Box, Button } from '@superdispatch/ui-lab';
import { Form, FormikProvider } from 'formik';
import { openExternalURL } from 'shared/helpers/URLHelpers';
import styled from 'styled-components';
import { useDispatchersAPI } from '../../../carrier/dispatchers/data/DispatchersAPI';
import { useDriversAPI } from '../../../carrier/drivers/data/DriversAPI';
import { OnboardingLayout } from './core/OnboardingLayout';
import { inviteTeamSchema } from './data/OnboardingDTO';
import { useSaveOnboardingWithErrorHandler } from './data/useSaveOnboardingWithErrorHandler';

const TEAM_TYPES = [
  {
    label: 'Driver',
    name: 'driver',
  },
  {
    label: 'Dispatcher',
    name: 'dispatcher',
  },
];

const AddButton = styled(Button)`
  padding-left: 0;
  padding-right: 0;
`;

export function OnboardingInviteTeamStepPage() {
  const { inviteDispatcher } = useDispatchersAPI();
  const { inviteDriver } = useDriversAPI();
  const { addSnackbar } = useSnackbarStack();
  const { mutate: saveStep, isLoading: isSavingStep } =
    useSaveOnboardingWithErrorHandler({
      onSuccess: () => {
        openExternalURL('/loadboard');
      },
    });

  const formik = useFormikEnhanced({
    initialValues: {
      teammates: [
        {
          type: 'driver',
          email: '',
          phone: '',
        },
        {
          type: 'driver',
          email: '',
          phone: '',
        },
        {
          type: 'driver',
          email: '',
          phone: '',
        },
      ],
    },
    validationSchema: inviteTeamSchema,
    onSubmit: (values) => {
      const dispatchers = values.teammates.filter(
        (teammate) => teammate.type === 'dispatcher',
      );
      const drivers = values.teammates.filter(
        (teammate) => teammate.type === 'driver',
      );

      return Promise.all([
        ...dispatchers.map((dispatcher) =>
          inviteDispatcher({
            email: dispatcher.email,
            phone_number: dispatcher.phone,
          }),
        ),
        ...drivers.map((driver) =>
          inviteDriver({
            email: driver.email,
            phone_number: driver.phone,
            name: '',
          }),
        ),
      ]);
    },
    onSubmitSuccess() {
      saveStep({
        stepName: 'team_invitation',
        status: 'completed',
      });
    },
    onSubmitFailure(e) {
      addSnackbar(e.message, { variant: 'error' });
    },
  });

  const { values, setValues } = formik;

  const { teammates } = values;

  function onRemove(index: number) {
    void setValues({
      ...values,
      teammates: teammates.filter((_, i) => i !== index),
    });
  }

  function onAdd() {
    void setValues({
      ...values,
      teammates: [
        ...teammates,
        {
          type: 'driver',
          email: '',
          phone: '',
        },
      ],
    });
  }

  function onSkip() {
    formik.setSubmitting(false);
    saveStep({
      stepName: 'team_invitation',
      status: 'skipped',
    });
  }

  return (
    <OnboardingLayout progress={80}>
      <FormikProvider value={formik}>
        <Form>
          <Stack space="large">
            <Stack space="xsmall">
              <Typography variant="h2">
                Invite Your Team{' '}
                <span role="img" aria-label="teammate">
                  👥
                </span>
              </Typography>
              <Typography color="textSecondary">
                Add your co-workers to collaborate on the loads you will move.
              </Typography>
            </Stack>
            <Stack space="small">
              <Typography variant="h4">Send invite to</Typography>
              <Stack space={['xlarge', 'small']}>
                {teammates.map((_, index) => (
                  <Columns key={index} align="center">
                    <Column width="fluid">
                      <Columns
                        space="small"
                        align="top"
                        aria-label={`vehicle ${index}`}
                        collapseBelow="tablet"
                      >
                        <Column width={['adaptive', 'content']}>
                          <Box width={['100%', '120px']}>
                            <FormikTextField
                              select={true}
                              fullWidth={true}
                              name={`teammates[${index}].type`}
                            >
                              {TEAM_TYPES.map((key) => (
                                <MenuItem key={key.name} value={key.name}>
                                  {key.label}
                                </MenuItem>
                              ))}
                            </FormikTextField>
                          </Box>
                        </Column>

                        <Column width={['adaptive', 'content']}>
                          <Box width={['100%', '264px']}>
                            <FormikTextField
                              name={`teammates[${index}].email`}
                              fullWidth={true}
                              placeholder="Team member’s email"
                            />
                          </Box>
                        </Column>

                        <Column width={['adaptive', '1/3']}>
                          <SuspendedFormikPhoneField
                            fullWidth={true}
                            name={`teammates[${index}].phone`}
                          />
                        </Column>
                      </Columns>
                    </Column>
                    <Column width="content">
                      {index !== 0 ? (
                        <IconButton
                          edge="end"
                          onClick={() => {
                            onRemove(index);
                          }}
                        >
                          <DeleteOutline />
                        </IconButton>
                      ) : (
                        <Box
                          width={['48px', '40px']}
                          height={['56px', '48px']}
                        />
                      )}
                    </Column>
                  </Columns>
                ))}
              </Stack>
              <AddButton startIcon={<Add />} variant="text" onClick={onAdd}>
                Add Team Member
              </AddButton>
            </Stack>
            <Inline space="small">
              <Button
                type="submit"
                disabled={!formik.dirty || !formik.isValid || isSavingStep}
                pending={
                  formik.isSubmitting ||
                  (formik.status.type === 'submitted' && isSavingStep)
                }
              >
                Complete
              </Button>
              <Button
                type="button"
                onClick={onSkip}
                variant="text"
                pending={formik.status.type === 'initial' && isSavingStep}
                disabled={formik.isSubmitting || isSavingStep}
              >
                Skip
              </Button>
            </Inline>
          </Stack>
        </Form>
      </FormikProvider>
    </OnboardingLayout>
  );
}
