import { Link } from '@material-ui/core';
import { CloudUpload } from '@material-ui/icons';
import { Stack, useSnackbarStack } from '@superdispatch/ui';
import { FileDropZone } from 'shared/form/FileDropZone';
import {
  MutationDrawer,
  MutationDrawerContent,
} from 'shared/form/MutationDrawer';
import { formatPlural } from 'shared/helpers/IntlHelpers';
import { useUploadContacts } from './data/ContactsAPI';

interface ContactsUploadDrawerContentProps {
  onClose: () => void;
}

function ContactsUploadDrawerContent({
  onClose,
}: ContactsUploadDrawerContentProps) {
  const { addSnackbar } = useSnackbarStack();
  const uploadContacts = useUploadContacts({
    onSuccess(response) {
      onClose();
      addSnackbar(
        `Successfully imported ${response.data.count} ${formatPlural(
          response.data.count,
          'contact',
          'contacts',
        )}`,
      );
    },
    onError(error) {
      addSnackbar(error.message || 'Failed to import contacts', {
        variant: 'error',
      });
    },
  });

  return (
    <MutationDrawerContent title="Import contacts" onClose={onClose}>
      <Stack align="right" space="small">
        <FileDropZone
          maxFiles={1}
          startIcon={<CloudUpload />}
          accept=".csv"
          hintText="Drag & Drop or upload CSV file here"
          onDropAccepted={(files) => {
            if (files[0]) uploadContacts.mutate(files[0]);
          }}
        >
          Browse
        </FileDropZone>

        <Link
          download={true}
          target="_blank"
          rel="noopener noreferrer"
          href="https://superdispatch-attachments.s3.amazonaws.com/static/dashboard/contacts-sample.csv"
        >
          Download sample
        </Link>
      </Stack>
    </MutationDrawerContent>
  );
}

export interface ContactsImportDrawerProps {
  open: boolean;
  onClose: () => void;
}

export function ContactsImportDrawer({
  open,
  onClose,
}: ContactsImportDrawerProps) {
  return (
    <MutationDrawer open={open} onClose={onClose}>
      <ContactsUploadDrawerContent onClose={onClose} />
    </MutationDrawer>
  );
}
