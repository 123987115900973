import { Color } from '@superdispatch/ui';
import { createSvgIcon } from 'shared/icons/IconUtils';

export const ArrowRightRoundedIcon = createSvgIcon(
  'ArrowRightRoundedIcon',
  <>
    <rect width="32" height="32" rx="16" fill={Color.Silver200} />
    <path
      d="M16 8L14.59 9.41L20.17 15H8V17H20.17L14.59 22.59L16 24L24 16L16 8Z"
      fill={Color.Dark500}
    />
  </>,
  {
    viewBox: '0 0 32 32',
    style: {
      width: '32px',
      height: '32px',
    },
  },
);
