import { Card, CardContent } from '@material-ui/core';
import { Inline } from '@superdispatch/ui';
import { AnchorButton, Box } from '@superdispatch/ui-lab';
import { ReactNode } from 'react';
import { LogoWithBrand } from 'shared/icons/LogoWithBrand';
import { PageLayout } from 'shared/layout/PageLayout';
import styled from 'styled-components';

const HeaderContainer = styled.div<{ height?: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: ${({ height }) => height ?? 'auto'};
  padding: 24px 32px;
`;
const HeaderContent = styled.div<{ maxWidth?: string }>`
  max-width: ${({ maxWidth }) => maxWidth ?? '1136px'};
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const PAGE_CONTENT_WIDTH = {
  large: '1136px',
  middle: '930px',
  small: '456px',
};

interface ExperimentPageLayoutProps {
  actions?: ReactNode;
  size?: 'large' | 'small' | 'middle';
  children: ReactNode;
  hideLogout?: boolean;
  withoutCardBg?: boolean;
  maxWidthHeader?: string;
  heightHeader?: string;
}

export function ExperimentPageLayout({
  size = 'large',
  actions,
  children,
  hideLogout,
  withoutCardBg,
  maxWidthHeader,
  heightHeader,
}: ExperimentPageLayoutProps) {
  return (
    <PageLayout
      header={
        <HeaderContainer height={heightHeader}>
          <HeaderContent maxWidth={maxWidthHeader}>
            <LogoWithBrand />
            {hideLogout ? (
              actions
            ) : (
              <Inline space="small" verticalAlign="center">
                {actions}
                <AnchorButton variant="neutral" href="/tms/logout/">
                  Log Out
                </AnchorButton>
              </Inline>
            )}
          </HeaderContent>
        </HeaderContainer>
      }
    >
      <Box margin="auto" maxWidth={PAGE_CONTENT_WIDTH[size]}>
        {withoutCardBg ? (
          children
        ) : (
          <Card>
            <CardContent>
              <Box padding="medium">{children}</Box>
            </CardContent>
          </Card>
        )}
      </Box>
    </PageLayout>
  );
}
