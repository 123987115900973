import { TextField, Typography } from '@material-ui/core';
import { Stack, useUID } from '@superdispatch/ui';
import { Draggable } from 'react-beautiful-dnd';
import { formatPlural } from 'shared/helpers/IntlHelpers';
import { SplitDTO } from './data/SplitLoadsDTO';
import { SplitLoadsVehicle } from './SplitLoadsVehicle';

export interface SplitLoadsCardProps {
  split: SplitDTO;
  onSplitChanged?: (split: SplitDTO) => void;
}

export function SplitLoadsCard({ split, onSplitChanged }: SplitLoadsCardProps) {
  const loadFieldID = useUID();

  const onLoadIdChanged = (loadId: string) => {
    onSplitChanged?.({ ...split, number: loadId });
  };

  return (
    <Stack space="medium">
      <TextField
        id={loadFieldID}
        fullWidth={true}
        variant="outlined"
        label="Load ID"
        onBlur={(evt) => {
          onLoadIdChanged(evt.target.value);
        }}
        defaultValue={split.number}
        helperText={!split.number ? 'Enter Load ID' : undefined}
      />
      <Stack>
        {split.vehicles && (
          <Typography variant="body1" color="textSecondary">
            {split.vehicles.length}{' '}
            {formatPlural(split.vehicles.length, 'vehicle', 'vehicles')}
          </Typography>
        )}
        {split.vehicles?.map((vehicle, index) => (
          <Draggable
            draggableId={vehicle.guid}
            index={index}
            key={vehicle.guid}
          >
            {(
              { dragHandleProps, draggableProps, innerRef },
              { isDragging },
            ) => (
              <SplitLoadsVehicle
                {...draggableProps}
                {...dragHandleProps}
                isDragging={isDragging}
                ref={innerRef}
                key={vehicle.guid}
                vehicle={vehicle}
              />
            )}
          </Draggable>
        ))}
      </Stack>
    </Stack>
  );
}
