import { Tiles } from '@superdispatch/ui';
import { parseURITemplate } from '@superdispatch/uri';
import { useEffect } from 'react';
import { DocumentTitle } from 'shared/layout/DocumentTitle';
import { PageHeader, PageLayout } from 'shared/layout/PageLayout';
import { trackDashboardEvent } from './DashboardAnalytics';
import { DashboardCard } from './DashboardCard';

export function DashboardPage() {
  useEffect(() => {
    trackDashboardEvent({ name: 'CTMS: Opened Dashboard Page' });
  }, []);

  return (
    <PageLayout header={<PageHeader title="Dashboard" />}>
      <DocumentTitle title="Dashboard" />

      <Tiles columns={{ xs: 1, sm: 2, md: 3 }} space={{ xs: 2 }}>
        <DashboardCard
          title="Aging Loads"
          loadCountType="aging"
          linkTo={parseURITemplate('/dashboard/loads{?stage}', {
            stage: 'aging',
          })}
          tooltipText="Loads created more than 5 days ago and have not been assigned to any driver."
        />

        <DashboardCard
          title="Delayed Pickups"
          loadCountType="delayed-pickup"
          linkTo={parseURITemplate('/dashboard/loads{?stage}', {
            stage: 'delayed-pickups',
          })}
          tooltipText="Loads assigned to a driver and scheduled pickup date has already passed."
        />

        <DashboardCard
          title="Delayed Deliveries"
          loadCountType="delayed-delivery"
          linkTo={parseURITemplate('/dashboard/loads{?stage}', {
            stage: 'delayed-deliveries',
          })}
          tooltipText="Loads assigned to a driver and scheduled delivery date has already passed."
        />

        <DashboardCard
          title="To Pick Up Today"
          loadCountType="to-pickup-today"
          linkTo={parseURITemplate('/dashboard/loads{?stage}', {
            stage: 'to-pickup-today',
          })}
          tooltipText="Loads scheduled to be picked up today."
        />

        <DashboardCard
          title="To Deliver Today"
          loadCountType="to-deliver-today"
          linkTo={parseURITemplate('/dashboard/loads{?stage}', {
            stage: 'to-deliver-today',
          })}
          tooltipText="Loads scheduled to be delivered today."
        />
      </Tiles>
    </PageLayout>
  );
}
