import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { Inline, Stack } from '@superdispatch/ui';
import { useMemo } from 'react';
import { formatPlural } from 'shared/helpers/IntlHelpers';
import { InlinePhotoGallery } from 'shared/ui/InlinePhotoGallery';
import { joinStrings } from 'shared/utils/StringUtils';
import {
  AIAGInspectionDamageDTO,
  LoadVehicleInspectionDTO,
} from '../data/LoadDTO';

interface AIAGInspectionPhotosProps {
  damage: AIAGInspectionDamageDTO;
  type: 'Pickup' | 'Delivery';
}

function AIAGInspectionPhotos({ type, damage }: AIAGInspectionPhotosProps) {
  return (
    <Stack space="small">
      <Typography color="textSecondary" variant="body1">
        {type} Damage: {damage.area.code} {damage.type.code}{' '}
        {damage.severity.code}{' '}
        <Typography display="inline">
          {joinStrings(
            ' - ',
            damage.area.description,
            damage.type.description,
            damage.severity.description,
          )}
        </Typography>
      </Typography>
      <InlinePhotoGallery
        photos={damage.photos.map((photo) => ({
          id: photo.original_photo_url,
          url: photo.original_photo_url,
          thumbnail_url: photo.photo_thumbnail_url,
          type,
          is_vehicle_detected: null,
        }))}
      />
    </Stack>
  );
}

interface VehicleInspectionProps {
  expanded?: boolean;
  year: number | null;
  make: string;
  model: string;
  vin: string;
  aiag_inspections: LoadVehicleInspectionDTO['aiag_inspections'];
}

export function ViewLoadAIAGVehicleInspection({
  expanded,
  year,
  make,
  model,
  vin,
  aiag_inspections,
}: VehicleInspectionProps) {
  const aiagPickupInspection = aiag_inspections.find(
    (i) => i.step === 'pickup',
  );
  const aiagDeliveryInspection = aiag_inspections.find(
    (i) => i.step === 'delivery',
  );

  const damagedPhotosCount = useMemo(() => {
    return aiag_inspections.reduce((acc, inspection) => {
      if (inspection.damages?.length) {
        return acc + inspection.damages.length;
      }
      return acc;
    }, 0);
  }, [aiag_inspections]);

  return (
    <Accordion defaultExpanded={expanded}>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="inspection-content"
        id="inspection-header"
      >
        <Inline space="xsmall">
          <Typography variant="body1">
            {joinStrings(' ', year, make, model)}
          </Typography>
          <Typography color="textSecondary">{vin}</Typography>

          {damagedPhotosCount > 0 ? (
            <Typography color="textSecondary">
              · {damagedPhotosCount}{' '}
              {formatPlural(damagedPhotosCount, 'Damage', 'Damages')}
            </Typography>
          ) : (
            <Typography color="textSecondary">No Damages</Typography>
          )}
        </Inline>
      </AccordionSummary>
      <AccordionDetails>
        {!!aiagPickupInspection?.damages?.length ||
        !!aiagDeliveryInspection?.damages?.length ? (
          <Stack space="xxlarge">
            {aiagPickupInspection?.damages?.map((damage, index) => (
              <AIAGInspectionPhotos key={index} type="Pickup" damage={damage} />
            ))}

            {aiagDeliveryInspection?.damages?.map((damage, index) => (
              <AIAGInspectionPhotos
                key={index}
                type="Delivery"
                damage={damage}
              />
            ))}
          </Stack>
        ) : (
          <Typography color="textSecondary">No inspection photos</Typography>
        )}
      </AccordionDetails>
    </Accordion>
  );
}
