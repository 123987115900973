import { parseURITemplate } from '@superdispatch/uri';
import { WEBSITE_SIGNUP_URL } from 'shared/constants/Constants';
import { chooseUTMSource } from 'shared/helpers/Analytics';
import { getBackURL } from 'shared/ui/BackButton';

function getUTMParamFromBackUrl(key: string) {
  const url = new URL(window.location.href);
  const next = url.searchParams.get('next');

  if (!next) {
    return url.searchParams.get(key);
  }

  try {
    const nextURL = new URL(next, window.location.origin);
    return nextURL.searchParams.get(key);
  } catch (error: unknown) {
    // ignore error
  }

  return null;
}

export function getSignupPageUrl() {
  return parseURITemplate(
    `${WEBSITE_SIGNUP_URL}/carrier/{?utm_source,utm_medium,next_url}`,
    {
      utm_medium: getUTMParamFromBackUrl('utm_medium'),
      utm_source: getUTMParamFromBackUrl('utm_source') || chooseUTMSource(),
      next_url:
        window.location.origin +
        getBackURL(window.location.search, '/tms/loads'),
    },
  );
}
