import { IconButton, Link, Typography } from '@material-ui/core';
import { Edit, Email, Inbox, Phone } from '@material-ui/icons';
import { SuspendedPhoneLink } from '@superdispatch/phones';
import { Column, Columns, InfoCard, Inline, Stack } from '@superdispatch/ui';
import { Box, Button, DescriptionItem, TextBox } from '@superdispatch/ui-lab';
import { useEffect } from 'react';
import { showIntercomLauncher } from 'shared/helpers/Intercom';
import { openExternalURL } from 'shared/helpers/URLHelpers';
import { TowTruckIcon } from 'shared/icons';
import { WarningIcon } from 'shared/icons/WarningIcon';
import { useFlag } from 'shared/settings/FeatureToggles';
import { DriverDTO } from '../data/DriverDTO';

interface DriverInfoCardProps {
  currentDriver: DriverDTO;
  setCurrentModal: (currentModal: 'edit') => void;
  labelID: string;
  descriptionID: string;
  editIconID: string;
}

export function DriverInfoCard({
  currentDriver,
  setCurrentModal,
  labelID,
  descriptionID,
  editIconID,
}: DriverInfoCardProps) {
  const shouldRestrictDriverEdit = useFlag(
    'multi_carrier_restrict_driver_edit_carrier_tms_web',
  );
  const shouldShowRestrictedDriverEditWarning = useFlag(
    'multi_carrier_restrict_driver_edit_banner',
  );

  useEffect(() => {
    showIntercomLauncher();
  }, []);

  return (
    <Box
      minWidth="516px"
      maxWidth="516px"
      role="description"
      aria-labelledby={labelID}
      aria-describedby={descriptionID}
    >
      <InfoCard>
        <Stack space="small">
          <Stack space="small">
            <Columns align="top">
              <Column>
                <TextBox variant="heading-3">Driver Info</TextBox>
              </Column>

              {!shouldRestrictDriverEdit ? (
                <Column width="content">
                  <IconButton
                    size="small"
                    aria-labelledby={`${editIconID} ${labelID}`}
                    onClick={() => {
                      setCurrentModal('edit');
                    }}
                  >
                    <Edit id={editIconID} aria-label="edit" />
                  </IconButton>
                </Column>
              ) : null}
            </Columns>

            <DescriptionItem
              wrap={true}
              label="Email"
              icon={<Email />}
              fallback="Not Available"
            >
              {!!currentDriver.email && (
                <Link href={`mailto:${currentDriver.email}`}>
                  {currentDriver.email}
                </Link>
              )}
            </DescriptionItem>

            <DescriptionItem
              label="Phone"
              icon={<Phone />}
              fallback="Not Available"
            >
              {!!currentDriver.phone && (
                <SuspendedPhoneLink phone={currentDriver.phone} />
              )}
            </DescriptionItem>

            <DescriptionItem
              wrap={true}
              label="Inbox"
              icon={<Inbox />}
              fallback="Not Available"
            >
              {!!currentDriver.inbox && (
                <Link href={`mailto:${currentDriver.inbox}`}>
                  {currentDriver.inbox}
                </Link>
              )}
            </DescriptionItem>

            <DescriptionItem
              label="Trailer Capacity"
              icon={<TowTruckIcon />}
              fallback="Not Available"
            >
              {currentDriver.trailer.capacity}
            </DescriptionItem>
          </Stack>

          {shouldShowRestrictedDriverEditWarning && (
            <Box
              padding="xsmall"
              borderRadius="small"
              backgroundColor="Yellow50"
            >
              <Columns align="top" space="xsmall">
                <Column width="content">
                  <WarningIcon
                    // eslint-disable-next-line react/forbid-component-props
                    style={{
                      height: '16px',
                      width: '16px',
                    }}
                  />
                </Column>
                <Column width="fluid">
                  <Stack space="xsmall">
                    <Typography>
                      Soon dispatchers will no longer be able to edit driver
                      profile information. To update a driver’s information,
                      contact the driver.
                    </Typography>
                    <Inline verticalAlign="center" space="small">
                      <Button
                        onClick={() => {
                          openExternalURL(
                            'https://support.superdispatch.com/en/articles/4105320-carrier-tms-how-to-invite-remove-edit-reactivate-drivers#h_b74cd64623',
                            {
                              target: '_blank',
                            },
                          );
                        }}
                        size="small"
                        variant="neutral"
                      >
                        Learn More
                      </Button>
                      <Link
                        href="#"
                        data-intercom-target="driver-edit-leave-feedback"
                        onClick={(e) => {
                          e.preventDefault();
                        }}
                      >
                        Leave Feedback
                      </Link>
                    </Inline>
                  </Stack>
                </Column>
              </Columns>
            </Box>
          )}
        </Stack>
      </InfoCard>
    </Box>
  );
}
