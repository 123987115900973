import { parseDate } from '@superdispatch/dates';
import { set, unset } from 'lodash-es';
import {
  PostingSearchCriteriaDTO,
  postingSearchCriteriaPayload,
  postingSearchCriteriaSchema,
} from 'shared/modules/loadboard/PostingSearchCriteriaDTO';
import { deepClone } from 'shared/utils/DataUtils';
import { yupDateString, yupObject } from 'shared/utils/YupUtils';
import { boolean, InferType, mixed, number, string } from 'yup';

export type AlertDuration = 'today' | 'week' | 'permanent';

export function formatActivatedMessage(duration: AlertDuration) {
  return duration === 'permanent'
    ? 'Load Alert now permanently active.'
    : duration === 'week'
    ? 'Load Alert now active for 7 days.'
    : 'Load Alert now active for today in CST.';
}

export function formatAlertCreateMessage(duration: AlertDuration) {
  return duration === 'permanent'
    ? 'Permanent Load Alert created.'
    : duration === 'today'
    ? 'Load Alert created for Today in CST.'
    : 'Load Alert created for 7 Days.';
}

export function isSavedSearchMuted(savedSearch: PostingSavedSearchDTO) {
  return savedSearch.is_alert_expired ? true : !savedSearch.alert_enabled;
}

export function compareSavedSearch(
  a: PostingSavedSearchDTO,
  b: PostingSavedSearchDTO,
) {
  const isMutedA = isSavedSearchMuted(a);
  const isMutedB = isSavedSearchMuted(b);

  if (isMutedA === isMutedB && b.updated_at && a.updated_at) {
    return parseDate(b.updated_at, {
      format: 'JodaISO',
    }).diff(parseDate(a.updated_at, { format: 'JodaISO' })).milliseconds;
  }

  return isMutedB ? -1 : 1;
}

export const alertDurationOptions: Record<AlertDuration, string> = {
  today: 'Today Only',
  week: '7 Days',
  permanent: 'Permanent',
};

export type PostingSavedSearchAlertDTO = InferType<
  typeof postingSavedSearchAlertSchema
>;
export const postingSavedSearchAlertSchema = yupObject({
  alert_enabled: boolean(),
  alert_duration: mixed<AlertDuration>().optional(),
});

export type PostingSavedSearchPayloadDTO = InferType<
  typeof postingSavedSearchPayloadSchema
>;
export const postingSavedSearchPayloadSchema = yupObject({
  description: string(),
  alert_enabled: boolean(),
  alert_duration: mixed<AlertDuration>(),
  search_criteria: postingSearchCriteriaSchema,
});

export const postingSavedSearchPayloadSchemaPayload =
  postingSavedSearchPayloadSchema.shape({
    search_criteria: postingSearchCriteriaPayload,
  });

export type PostingSavedSearchDTO = InferType<typeof postingSavedSearchSchema>;
export const postingSavedSearchSchema = yupObject({
  id: number().required(),
  guid: string().required(),
  contact_email: string(),
  description: string().nullable(),
  contact_phone_number: string(),
  notification_enabled: boolean(),
  alert_enabled: boolean().required(),
  alert_duration: mixed<AlertDuration>().optional(),
  is_alert_expired: boolean(),
  created_at: yupDateString('JodaISO'),
  updated_at: yupDateString('JodaISO'),
  alert_expires_at: yupDateString('JodaISO'),
  search_criteria: postingSearchCriteriaSchema,
});

export function normalizeSavedSearchPayment<T extends PostingSavedSearchDTO>(
  value: T,
): T {
  const payload = deepClone(value);
  if (payload.search_criteria.payment.method === 'superpay') {
    unset(payload, 'search_criteria.payment.method');
    set(payload, 'search_criteria.payment.terms', 'superpay');
  }
  return payload;
}

export function normalizeSavedSearchCriteriaPayment(
  value: PostingSearchCriteriaDTO,
): PostingSearchCriteriaDTO {
  const payload = deepClone(value);
  if (payload.payment.terms === 'superpay') {
    unset(payload, 'payment.terms');
    set(payload, 'payment.method', 'superpay');
  }
  return payload;
}
