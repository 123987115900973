import { useDeepEqualValue } from '@superdispatch/hooks';
import { createContext, ReactNode, useCallback } from 'react';
import { APIListQueryResult } from 'shared/api/APIListQuery';
import { APIQueryResult } from 'shared/api/APIQuery';
import {
  SearchQueryTextType,
  useSearchQueryText,
} from 'shared/helpers/ReactHelpers';
import { useNullableContext } from 'shared/hooks/useNullableContext';
import { useMatch, useNavigate } from 'shared/routing/react-router-6';
import { DriverDTO } from './data/DriverDTO';
import { useDriver, useDriverList } from './data/DriversAPI';

export interface DriversPageContext {
  rootURL: string;
  currentDriverGUID: string | undefined;
  openDriver: (guid: string, replace?: boolean) => void;
  searchQueryDrivers: SearchQueryTextType;
  driverList: APIListQueryResult<DriverDTO>;
  driver: APIQueryResult<DriverDTO>;
}

const context = createContext<null | DriversPageContext>(null);

export function useDriversPageContext(): DriversPageContext {
  return useNullableContext('DriversPageContext', context);
}

export interface DriversPageContextProviderProps {
  children?: ReactNode;
}

const rootURL = '/drivers';

export function DriversPageContextProvider({
  children,
}: DriversPageContextProviderProps) {
  const driverDetailsMatch = useMatch<{ driverGUID?: string }>(
    `${rootURL}/:driverGUID`,
  );
  const currentDriverGUID = driverDetailsMatch?.params.driverGUID || undefined;

  const searchQueryDrivers = useSearchQueryText();
  const [_, searchQuery] = searchQueryDrivers;
  const driverList = useDriverList({ q: searchQuery, page_size: 20 });
  const driver = useDriver(currentDriverGUID);

  const navigate = useNavigate();
  const openDriver = useCallback(
    (guid: string, replace?: boolean) => {
      const driverURL = `${rootURL}/${guid}`;
      navigate(driverURL, { replace });
    },
    [navigate],
  );

  const ctx = useDeepEqualValue({
    rootURL,
    openDriver,
    currentDriverGUID,
    searchQueryDrivers,
    driverList,
    driver,
  });

  return <context.Provider value={ctx}>{children}</context.Provider>;
}
