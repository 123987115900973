import { FormControlLabel, Radio, Typography } from '@material-ui/core';
import { RadioGroupField, Stack } from '@superdispatch/ui';
import { Box, Button } from '@superdispatch/ui-lab';
import { useEffect, useState } from 'react';
import { LoginLayout } from 'shared/auth/core/LoginLayout';
import { LoginLayoutContent } from 'shared/auth/core/LoginLayoutContent';
import {
  showIntercomLauncher,
  showNewIntercomMessage,
} from 'shared/helpers/Intercom';
import { useNavigate } from 'shared/routing/react-router-6';
import { DriverLogoutButton } from './core/DriverLogoutButton';

export function DriverDeleteAccountPage() {
  const navigate = useNavigate();
  const [reason, setReason] = useState<'transition' | 'quit'>();

  useEffect(() => {
    showIntercomLauncher();
  }, []);

  return (
    <LoginLayout showDot={false} header={<DriverLogoutButton />}>
      <Box marginTop="medium">
        <LoginLayoutContent>
          <Stack space="medium">
            <Typography variant="h2">
              We&rsquo;re sorry to see you go!
            </Typography>
            <Typography>What&rsquo;s your reason for leaving?</Typography>
            <RadioGroupField name="reason" fullWidth={true}>
              <Stack space="medium">
                <FormControlLabel
                  value="transition"
                  control={<Radio />}
                  checked={reason === 'transition'}
                  onChange={() => {
                    setReason('transition');
                  }}
                  label={
                    <Stack space="none">
                      <Typography>
                        I&apos;m transitioning to a different carrier
                      </Typography>
                      <Typography variant="caption" color="textSecondary">
                        You will be directed to our support team who will help
                        you with a transition.
                      </Typography>
                    </Stack>
                  }
                />
                <FormControlLabel
                  value="quit"
                  control={<Radio />}
                  checked={reason === 'quit'}
                  onChange={() => {
                    setReason('quit');
                  }}
                  label={
                    <Typography>I don&apos;t use the App anymore</Typography>
                  }
                />
              </Stack>
            </RadioGroupField>
            <Button
              onClick={() => {
                if (reason === 'transition') {
                  showNewIntercomMessage(
                    "I'm transitioning to a different carrier",
                  );
                }
                if (reason === 'quit') {
                  navigate('/driver-account/delete/confirmation/');
                }
              }}
              disabled={!reason}
              fullWidth={true}
            >
              Continue
            </Button>
          </Stack>
        </LoginLayoutContent>
      </Box>
    </LoginLayout>
  );
}
