import { SvgIcon, SvgIconProps } from '@material-ui/core';
import { startCase } from 'lodash-es';
import { forwardRef, FunctionComponent, memo, ReactNode } from 'react';

export function createSvgIcon(
  displayName: string,
  path: ReactNode,
  {
    'aria-label': ariaLabel = `${startCase(displayName)} icon`,
    ...defaultProps
  }: SvgIconProps = {},
): FunctionComponent<SvgIconProps> {
  const Icon = forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => (
    <SvgIcon {...defaultProps} {...props} ref={ref} aria-label={ariaLabel}>
      {typeof path == 'string' ? <path d={path} /> : path}
    </SvgIcon>
  ));

  const Component = memo(Icon);

  if (import.meta.env.MODE !== 'production') {
    Icon.displayName = `${displayName}Icon`;
    Component.displayName = `${displayName}Icon`;
  }

  return Component;
}
