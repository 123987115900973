import { createSvgIcon } from 'shared/icons/IconUtils';

export const WarningDashedIcon = createSvgIcon(
  'WarningDashed',
  <>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M6.5 51.8l.4-2 1.9.2h2v2h-2c-.8 0-1.6 0-2.3-.2zm38.8.2v-2h2c.6 0 1.2 0 1.8-.2l.4 2-2.3.2h-2zm8.3-2.2l-1.4-1.5a5.3 5.3 0 001.7-3l2 .4a7.6 7.6 0 01-2.3 4zm.9-11l1 2.1-2 .7a10.6 10.6 0 00-.8-2l-1-1.6 1.8-1 1 1.7zm-3-5.2l-1.7 1-2-3.3 1.8-1 2 3.3zM47.8 27L46 28l-1.9-3.4 1.7-1 2 3.4zm-4-7l-1.7 1-2-3.3 1.8-1 2 3.3zM40 13.4l-1.7 1-2-3.4 1.8-1 2 3.4zm-3.8-6.8l-1.8 1-1-1.7-1-1.5L33.8 3c.5.5.9 1.2 1.3 1.8l1 1.7zm-6-6.2l-.7 1.9a4.4 4.4 0 00-3 0L25.8.4a6.4 6.4 0 014.4 0zM20 6.6L21 5c.4-.6.8-1.3 1.3-1.8l1.5 1.3-1 1.5-1 1.7-1.8-1zM18 10l1.7 1-1.9 3.4-1.7-1 2-3.4zm-3.9 6.7l1.8 1-2 3.4-1.7-1 2-3.4zm-3.8 6.8l-2 3.4 1.8 1 1.9-3.4-1.7-1zm-4 6.7l1.8 1-2 3.4-1.7-1 2-3.4zM2.6 37l1.7 1-1 1.7-.8 1.8-2-.5 1-2.2 1-1.7zm0 12.8l1.3-1.5a5.3 5.3 0 01-1.7-3l-2 .4a7.7 7.7 0 002.3 4zm50.9-11l-1 .1v.1l1-.1zm0 0h1-1zM41.5 52v-2h-3.8v2h3.9zm-7.6 0v-2H30v2h3.9zM26 52v-2h-3.9v2H26zm-7.7 0v-2h-3.9v2h3.9z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      d="M28.2 35.7c1.7 0 3 1.4 3 3.2a3 3 0 01-3 3.1 3.1 3.1 0 01-3.2-3.1c0-1.8 1.4-3.2 3.2-3.2zm0-3c-1.2 0-2-1-2.1-2.2l-1-9.4c-.3-1.6 1.5-3.1 3-3.1 1.7 0 3.4 1.5 3.2 3.1l-1 9.4c-.2 1.1-1 2.1-2.1 2.1z"
    />
  </>,
  { viewBox: '0 0 56 52' },
);
