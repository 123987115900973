import {
  Card,
  CardContent,
  IconButton as MuiIconButton,
} from '@material-ui/core';
import { Add, Edit } from '@material-ui/icons';
import { CardButton, Column, Columns, Inline, Stack } from '@superdispatch/ui';
import { Alert, Button, TextBox } from '@superdispatch/ui-lab';
import { useShowAddBillingAddressDialog } from 'shared/modules/subscription/core/useShowAddBillingAddressDialog';
import {
  useBillingAddress,
  useSubscriptionSettings,
} from 'shared/modules/subscription/SubscriptionAPI';
import { useNavigate } from 'shared/routing/react-router-6';
import { joinStrings } from 'shared/utils/StringUtils';
import styled from 'styled-components';

const IconButton = styled(MuiIconButton)`
  padding: 4px;
`;

export function SubscriptionPaymentInformation() {
  const navigate = useNavigate();
  const { data: billingAddress } = useBillingAddress();
  const { data: subscriptionDetails } = useSubscriptionSettings();
  const { doesBillingAddressNotExist } = useShowAddBillingAddressDialog();

  const cardInformation = subscriptionDetails?.card_information;
  const canReactivate =
    (subscriptionDetails?.status === 'Canceled' ||
      subscriptionDetails?.status === 'In Trial') &&
    !!cardInformation;

  return (
    <Card>
      <CardContent>
        <Stack space="small">
          {cardInformation && (
            <Stack space="xsmall">
              <TextBox variant="heading-3">Payment Information</TextBox>
              <Stack space="xsmall">
                <Columns align="center">
                  <Column>
                    <TextBox variant="heading-4">Card Details</TextBox>
                  </Column>
                  <Column>
                    <Inline horizontalAlign="right">
                      <IconButton
                        aria-label="edit card details"
                        onClick={() => {
                          navigate('/settings/subscription/edit_card_details');
                        }}
                      >
                        <Edit fontSize="small" />
                      </IconButton>
                    </Inline>
                  </Column>
                </Columns>
                <Inline>
                  <TextBox color="secondary" variant="body">
                    Card Number:
                  </TextBox>
                  <TextBox variant="body">
                    {cardInformation.card_number}
                  </TextBox>
                </Inline>
                <Inline>
                  <TextBox color="secondary" variant="body">
                    Expiration Date:
                  </TextBox>
                  <TextBox variant="body">{cardInformation.expiry}</TextBox>
                </Inline>
              </Stack>
            </Stack>
          )}

          {!!billingAddress?.billing_country && !doesBillingAddressNotExist && (
            <Stack space="xsmall">
              <Columns align="center">
                <Column>
                  <TextBox variant="heading-4">Billing Address</TextBox>
                </Column>
                <Column>
                  <Inline horizontalAlign="right">
                    <IconButton
                      onClick={() => {
                        navigate('/settings/subscription/edit_billing_address');
                      }}
                    >
                      <Edit fontSize="small" />
                    </IconButton>
                  </Inline>
                </Column>
              </Columns>
              <Stack space="none">
                {billingAddress.billing_address && (
                  <TextBox color="secondary">
                    {joinStrings(
                      ', ',
                      billingAddress.billing_address +
                        ' ' +
                        billingAddress.billing_city +
                        ',',
                    )}
                  </TextBox>
                )}
                <TextBox color="secondary">
                  {joinStrings(
                    ', ',
                    billingAddress.billing_state +
                      ' ' +
                      billingAddress.billing_zip,
                    billingAddress.billing_country,
                  )}
                </TextBox>
              </Stack>
            </Stack>
          )}

          {!billingAddress?.billing_country && !doesBillingAddressNotExist ? (
            <Button
              onClick={() => {
                navigate('/settings/subscription/edit_billing_address', {
                  state: {
                    enableReactivation: canReactivate,
                    canAddBilling: true,
                  },
                });
              }}
              size="large"
              fullWidth={true}
            >
              Add Billing Address {canReactivate && '& Reactivate'}
            </Button>
          ) : canReactivate && !doesBillingAddressNotExist ? (
            <Button
              onClick={() => {
                navigate('/settings/subscription/reactivate_plan');
              }}
              fullWidth={true}
              size="large"
            >
              Reactivate
            </Button>
          ) : null}

          {doesBillingAddressNotExist && (
            <Stack space="small">
              <CardButton
                onClick={() => {
                  navigate('/settings/subscription/edit_billing_address', {
                    state: {
                      canAddBilling: true,
                    },
                  });
                }}
                startIcon={<Add />}
                size="small"
              >
                Billing Information
              </CardButton>
              <Alert severity="caution">
                Add your billing address so we can correctly calculate your tax.
              </Alert>
            </Stack>
          )}
        </Stack>
      </CardContent>
    </Card>
  );
}
