import { LinearProgress, Typography } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import { Color, Column, Columns, Inline, Stack } from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import styled from 'styled-components';
import { usePublicOfferPageContext } from './PublicOfferPageContext';

const BorderLinearProgress = styled(LinearProgress)`
  background-color: ${Color.Silver400};
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
`;

const StepItem = styled.div`
  padding: 16px;
  width: 100%;
  text-align: center;
`;

interface PublicOfferStepItemProps {
  completed?: boolean;
  active?: boolean;
  label: string;
  description: string;
}

function PublicOfferStepItem({
  label,
  description,
  completed,
  active,
}: PublicOfferStepItemProps) {
  return (
    <StepItem>
      <Stack space="none">
        <Inline horizontalAlign="center" verticalAlign="center">
          {completed && <Check color="primary" fontSize="small" />}
          <Typography
            color={active ? 'primary' : 'textSecondary'}
            variant="body1"
          >
            {label}
          </Typography>
        </Inline>
        <Typography
          color={active ? 'textPrimary' : 'textSecondary'}
          variant={active ? 'body1' : 'inherit'}
        >
          {description}
        </Typography>
      </Stack>
    </StepItem>
  );
}

export function PublicOfferStepper() {
  const {
    publicOffer: { data },
  } = usePublicOfferPageContext();
  const isAccepted = data?.status === 'accepted';

  return (
    <Box marginBottom="-xxsmall">
      <Columns align="center">
        <Column width="1/2">
          <PublicOfferStepItem
            label="STEP 1"
            description="View All Details"
            active={data?.status === 'pending'}
            completed={isAccepted}
          />
        </Column>
        <Column width="1/2">
          <PublicOfferStepItem
            label="STEP 2"
            description="Assign to Driver"
            active={isAccepted && !data.order.driver}
            completed={!!data?.order.driver}
          />
        </Column>
      </Columns>
      <BorderLinearProgress
        value={isAccepted ? 100 : 50}
        variant="determinate"
      />
    </Box>
  );
}
