import { DateRangeField, DateRangeFieldProps } from '@superdispatch/dates';
import { useField } from 'formik';
import { ReportsPeriodDTO } from '../data/ReportsDTO';

interface ReportsDateRangeProps extends DateRangeFieldProps {
  name: string;
  validate?: (range: undefined | ReportsPeriodDTO) => string | void;
}

export function ReportsDateRange({
  name,
  validate,
  helperText,
  ...rest
}: ReportsDateRangeProps) {
  const [{ value }, { error }, { setValue }] =
    useField<null | ReportsPeriodDTO>({ name, validate });

  return (
    <DateRangeField
      {...rest}
      value={!value ? undefined : [value.start_date, value.end_date]}
      error={!!error}
      helperText={typeof error == 'string' ? error || helperText : helperText}
      onChange={({ stringValue: [start, finish] }) => {
        if (start || finish) {
          void setValue({ start_date: start, end_date: finish });
        } else {
          void setValue(null);
        }
      }}
    />
  );
}
