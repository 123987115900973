import { Box } from '@superdispatch/ui-lab';
import { omit } from 'lodash-es';
import { useMemo } from 'react';
import { useAppFormik } from 'shared/form/AppFormik';
import {
  FormikDrawer,
  FormikDrawerActions,
  FormikDrawerContent,
} from 'shared/form/FormikDrawer';
import { computePrice } from 'shared/utils/NumberUtils';
import { isEmptyLoadPayment } from '../data/LoadActionsDTO';
import { formatLoadStatus, loadPaymentSchema } from '../data/LoadDTO';
import { trackLoadsEvent } from '../data/LoadsAnalytics';
import { useLoadDetails, useLoadsAPI, useLoadsCache } from '../data/LoadsAPI';
import { useLoadStageFromBackURL } from '../view/ViewLoadHeaderActions';
import { LoadPaymentForm } from './LoadPaymentForm';

interface EditPaymentDrawerContentProps {
  loadGUID: string;
  onClose: () => void;
  isEnabledEditLoad: boolean;
}

function EditPaymentDrawerContent({
  onClose,
  loadGUID,
  isEnabledEditLoad,
}: EditPaymentDrawerContentProps) {
  const { data } = useLoadDetails(loadGUID);
  const { editLoadPayment } = useLoadsAPI();
  const { invalidateLoads } = useLoadsCache();
  const loadStage = useLoadStageFromBackURL();

  const formik = useAppFormik({
    initialValues: data?.payments[0],
    validationSchema: loadPaymentSchema,
    onSubmit(values) {
      const formValues = omit(values, ['paid_at']);
      return editLoadPayment(loadGUID, {
        ...formValues,
        receipt_date: values.paid_at,
      });
    },
    onSubmitSuccess() {
      onClose();
      invalidateLoads();

      trackLoadsEvent({
        name: 'Carrier Edited Load',
        utm_medium: loadStage,
        utm_content: 'View Page',
        load_status: formatLoadStatus(data?.status),
        is_created_by_broker: !!data?.is_created_by_broker,
        payment_information: isEmptyLoadPayment(data?.payments[0])
          ? 'Added'
          : 'Edited',
      });
    },
    getSuccessMessage() {
      return 'Payment information successfully updated';
    },
    getErrorMessage({ message }) {
      return message || 'Something went wrong, contact support.';
    },
  });

  const vehiclesTotalPrice = useMemo(
    () => computePrice(data?.vehicles),
    [data],
  );

  return (
    <FormikDrawerContent
      title="Payment"
      formik={formik}
      onClose={onClose}
      actions={<FormikDrawerActions />}
    >
      <Box maxWidth="368px">
        {data && (
          <LoadPaymentForm
            vehiclesTotalPrice={vehiclesTotalPrice}
            canPaymentBeEdited={!!data.can_payment_be_edited}
            isEnabledEditLoad={isEnabledEditLoad}
          />
        )}
      </Box>
    </FormikDrawerContent>
  );
}

export interface EditPaymentDrawerProps extends EditPaymentDrawerContentProps {
  open: boolean;
  isEnabledEditLoad: boolean;
}

export function EditPaymentDrawer({
  open,
  onClose,
  loadGUID,
  isEnabledEditLoad,
}: EditPaymentDrawerProps) {
  return (
    <FormikDrawer open={open} onClose={onClose}>
      <EditPaymentDrawerContent
        onClose={onClose}
        loadGUID={loadGUID}
        isEnabledEditLoad={isEnabledEditLoad}
      />
    </FormikDrawer>
  );
}
