import { Card, CardContent, Typography } from '@material-ui/core';
import { DragIndicator } from '@material-ui/icons';
import { Color, Stack, useUID } from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import { forwardRef } from 'react';
import { formatCurrency } from 'shared/helpers/IntlHelpers';
import { joinStrings } from 'shared/utils/StringUtils';
import styled from 'styled-components';
import { SplitLoadVehicleDTO } from './data/SplitLoadsDTO';

const DragIcon = styled(DragIndicator)`
  color: ${Color.Silver500};
  transition: color 300ms;
`;

const VehicleCard = styled(Card)`
  cursor: grab;

  &:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 0 4px rgba(0, 0, 0, 0.1);

    ${DragIcon} {
      color: ${Color.Dark300};
    }
  }
`;

const ContentBox = styled(Box)`
  height: 100%;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  gap: 16px;
`;

export interface SplitLoadsVehicleProps {
  vehicle: SplitLoadVehicleDTO;
  isDragging?: boolean;
}

export const SplitLoadsVehicle = forwardRef<
  HTMLDivElement,
  SplitLoadsVehicleProps
>(
  (
    { vehicle: { year, make, model, vin, price }, isDragging, ...props },
    ref,
  ) => {
    const vehicleId = useUID();
    return (
      <VehicleCard {...props} ref={ref} aria-labelledby={vehicleId}>
        <CardContent>
          <ContentBox>
            <DragIcon color="action" />

            <Stack>
              <Stack space="none">
                <Typography id={vehicleId}>
                  {joinStrings(' ', year, make, model)}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {vin}
                </Typography>
              </Stack>
              {price && (
                <Typography>{formatCurrency(parseFloat(price))}</Typography>
              )}
            </Stack>
          </ContentBox>
        </CardContent>
      </VehicleCard>
    );
  },
);

SplitLoadsVehicle.displayName = 'SplitLoadsVehicle';
