import { FormikDrawer } from 'shared/form/FormikDrawer';
import { SuperPayBankAccountDTO } from 'shared/modules/superpay/SuperPayDTO';
import { useMatch } from 'shared/routing/react-router-6';
import { SUPERPAY_PROFILE_ROUTES } from './SuperPayRoute';
import { VerifyBankAccountDrawerContent } from './VerifyBankAccountDrawerContent';

interface VerifyBankAccountDrawerProps {
  bankAccount?: SuperPayBankAccountDTO;
  onClose: () => void;
  onComplete: () => void;
}

export function VerifyBankAccountDrawer({
  bankAccount,
  onClose,
  onComplete,
}: VerifyBankAccountDrawerProps) {
  const superPayProfileMatch = useMatch(
    SUPERPAY_PROFILE_ROUTES.bankVerification,
  );
  return (
    <FormikDrawer open={!!superPayProfileMatch} onClose={onClose}>
      <VerifyBankAccountDrawerContent
        bankAccountDetails={bankAccount}
        onClose={onClose}
        onComplete={() => {
          onClose();
          onComplete();
        }}
      />
    </FormikDrawer>
  );
}
