import { Typography } from '@material-ui/core';
import { Color, Stack } from '@superdispatch/ui';
import { Alert } from '@superdispatch/ui-lab';
import { CelebrationIcon } from 'shared/icons/CelebrationIcon';

interface CelebrationNotificationProps {
  onClose: () => void;
}
export function CelebrationNotification({
  onClose,
}: CelebrationNotificationProps) {
  return (
    <Alert
      icon={<CelebrationIcon htmlColor={Color.Green300} />}
      severity="positive"
      onClose={onClose}
    >
      <Stack space="xxsmall">
        <Typography variant="body1">Congratulations!</Typography>
        <Typography>
          You have successfully upgraded to Carrier TMS Pro.
        </Typography>
      </Stack>
    </Alert>
  );
}
