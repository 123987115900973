import { PageLayout } from 'shared/layout/PageLayout';
import { CarrierOverviewPage } from 'shared/modules/carrier-profile/overview/CarrierOverviewPage';

export function ProfileOverviewPage() {
  return (
    <PageLayout background="White">
      <CarrierOverviewPage />
    </PageLayout>
  );
}
