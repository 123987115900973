import { Color } from '@superdispatch/ui';
import { createSvgIcon } from 'shared/icons/IconUtils';

export const PersonDeactivatedIcon = createSvgIcon(
  'PersonDeactivatedIcon',
  <path
    d="M5.76676 3.87996C6.24009 3.14662 7.06676 2.66662 8.00009 2.66662C9.47342 2.66662 10.6668 3.85996 10.6668 5.33329C10.6668 6.26662 10.1868 7.09329 9.45342 7.56663L5.76676 3.87996ZM13.3334 11.4466C13.3201 10.7133 12.9134 10.04 12.2601 9.69996C11.9001 9.51329 11.5068 9.33996 11.0801 9.19329L13.3334 11.4466ZM14.1268 14.1266L1.87342 1.87329L0.926758 2.81329L6.85342 8.73996C5.64676 8.89329 4.59342 9.26662 3.74009 9.70662C3.07342 10.0466 2.66676 10.7333 2.66676 11.48V13.3333H11.4468L13.1868 15.0733L14.1268 14.1266Z"
    fill={Color.Dark100}
  />,
  { viewBox: '0 0 16 16', style: { height: '16px', width: '16px' } },
);
