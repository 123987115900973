import { TableCell, TableRow, Typography } from '@material-ui/core';
import { FormattedDate } from '@superdispatch/dates';
import { useSnackbarStack } from '@superdispatch/ui';
import { Button } from '@superdispatch/ui-lab';
import { useState } from 'react';
import { joinStrings } from 'shared/utils/StringUtils';
import styled from 'styled-components';
import { formatLoadStatus } from '../../../loads/data/LoadDTO';
import { useAddTripLoad, useRemoveTripLoad } from '../../data/TripsAPI';
import { LoadForTripDTO } from '../../data/TripsDTO';

interface LoadButtonProps {
  loadGUID?: string;
  tripGUID: string;
  onSubmitSuccess: () => void;
}

function AddLoadToTripButton({
  loadGUID,
  tripGUID,
  onSubmitSuccess,
}: LoadButtonProps) {
  const { addSnackbar } = useSnackbarStack();

  const addLoad = useAddTripLoad(tripGUID, {
    onSuccess() {
      onSubmitSuccess();
      addSnackbar('Load was successfully added', {
        variant: 'success',
      });
    },
    onError() {
      addSnackbar('Error occurred while adding the load', {
        variant: 'error',
      });
    },
  });

  return (
    <Button
      pending={addLoad.isLoading}
      onClick={() => {
        if (loadGUID) {
          addLoad.mutate(loadGUID);
        }
      }}
    >
      Add
    </Button>
  );
}

function RemoveTripLoadButton({
  loadGUID,
  tripGUID,
  onSubmitSuccess,
}: LoadButtonProps) {
  const { addSnackbar } = useSnackbarStack();

  const removeLoad = useRemoveTripLoad(tripGUID, {
    onSuccess() {
      onSubmitSuccess();
      addSnackbar('Load was successfully removed', {
        variant: 'success',
        key: tripGUID,
      });
    },
    onError() {
      addSnackbar('Error occurred while removing the load', {
        variant: 'error',
        key: tripGUID,
      });
    },
  });

  return (
    <Button
      variant="critical"
      pending={removeLoad.isLoading}
      onClick={() => {
        if (loadGUID) {
          removeLoad.mutate(loadGUID);
        }
      }}
    >
      Remove
    </Button>
  );
}

const Styled1 = styled.div`
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
`;

const WrappedTableCell = styled(TableCell)`
  word-break: break-all;
`;

export function AddTripLoadsTableRow({
  guid,
  load_id: loadId,
  status,
  vehicles,
  origin,
  origin_date,
  destination,
  destination_date,
  customer,
  tripGUID,
}: LoadForTripDTO & { tripGUID: string }) {
  const [state, setState] = useState<'add' | 'remove'>('add');

  return (
    <TableRow hover={true}>
      <WrappedTableCell>
        {loadId ? loadId : "Load doesn't have ID"}
      </WrappedTableCell>
      <TableCell>{formatLoadStatus(status)}</TableCell>
      <WrappedTableCell>
        {vehicles?.map(({ year, make, model }, i) => (
          <Typography key={i}>{joinStrings(' ', year, make, model)}</Typography>
        ))}
      </WrappedTableCell>
      <TableCell>
        <Typography>{origin}</Typography>
        {origin_date && (
          <small>
            <FormattedDate variant="Date" date={origin_date} />
          </small>
        )}
      </TableCell>
      <TableCell>
        <Typography>{destination}</Typography>
        {destination_date && (
          <small>
            <FormattedDate variant="Date" date={destination_date} />
          </small>
        )}
      </TableCell>
      <TableCell>{customer}</TableCell>
      <TableCell>
        <Styled1>
          {state === 'add' && (
            <AddLoadToTripButton
              loadGUID={guid}
              tripGUID={tripGUID}
              onSubmitSuccess={() => {
                setState('remove');
              }}
            />
          )}
          {state === 'remove' && (
            <RemoveTripLoadButton
              loadGUID={guid}
              tripGUID={tripGUID}
              onSubmitSuccess={() => {
                setState('add');
              }}
            />
          )}
        </Styled1>
      </TableCell>
    </TableRow>
  );
}
