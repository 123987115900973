import { useUID } from '@superdispatch/ui';
import { FieldValidator, useField, useFormikContext } from 'formik';
import { ContactEmailField, ContactEmailFieldProps } from './ContactEmailField';

interface FormikContactEmailFieldProps
  extends Omit<ContactEmailFieldProps, 'TextFiledProps'> {
  name: string;
  validate?: FieldValidator;

  TextFieldProps?: Omit<
    NonNullable<ContactEmailFieldProps['TextFiledProps']>,
    'name' | 'error'
  >;
}

export function FormikContactEmailField({
  name,
  validate,

  id,
  onBlur,
  onChange,
  disabled,
  TextFieldProps: { helperText, ...textFieldProps } = {},
  ...rest
}: FormikContactEmailFieldProps) {
  const uid = useUID();
  const { isSubmitting } = useFormikContext();
  const [field, { error, touched }, { setValue }] = useField({
    name,
    validate,
  });
  const errorMessage = touched && error;

  return (
    <ContactEmailField
      {...rest}
      {...field}
      id={id || uid}
      disabled={disabled ?? isSubmitting}
      onBlur={(event) => {
        onBlur?.(event);
        field.onBlur(event);
      }}
      onChange={(value) => {
        onChange?.(value);
        void setValue(value);
      }}
      TextFiledProps={{
        ...textFieldProps,
        error: !!errorMessage,
        helperText: errorMessage || helperText,
      }}
    />
  );
}
