import {
  ListItemText,
  StandardTextFieldProps,
  TextField,
  Typography,
} from '@material-ui/core';
import { Autocomplete, AutocompleteProps } from '@material-ui/lab';
import { identity } from 'lodash-es';
import { useMemo } from 'react';
import { useDebouncedValue } from 'shared/helpers/ReactHelpers';
import { toSearchQueryText } from 'shared/utils/TextUtils';
import { ContactDTO } from '../data/ContactDTO';
import { useContactsList } from '../data/ContactsAPI';

export interface ContactEmailFieldProps
  extends Omit<
    AutocompleteProps<string, false, false, true>,
    | 'multiple'
    | 'options'
    | 'renderInput'
    | 'filterOptions'
    | 'loading'
    | 'inputValue'
    | 'onInputChange'
    | 'value'
    | 'onChange'
  > {
  value?: string;
  onChange?: (value: string) => void;
  TextFiledProps?: StandardTextFieldProps;
}

export function ContactEmailField({
  value = '',
  onChange,
  freeSolo = true,
  openOnFocus = false,
  TextFiledProps: textFiledProps,
  ...props
}: ContactEmailFieldProps) {
  const query = useDebouncedValue(toSearchQueryText(value), 500);
  const shouldSkipQuery = !query && !value;
  const { data: contacts } = useContactsList(
    { q: query, page_size: 20 },
    { enabled: !!query },
  );

  const [optionsMap, options] = useMemo(() => {
    const nextOptionsMap = new Map<string, ContactDTO>();

    if (contacts) {
      for (const page of contacts.pages) {
        for (const contact of page.data) {
          if (contact.email) {
            nextOptionsMap.set(contact.email, contact);
          }
        }
      }
    }

    return [nextOptionsMap, Array.from(nextOptionsMap.keys())] as const;
  }, [contacts]);

  return (
    <Autocomplete
      {...props}
      multiple={false}
      loading={!shouldSkipQuery && !contacts}
      value={value}
      onInputChange={(_, nextValue) => {
        onChange?.(nextValue);
      }}
      options={options}
      freeSolo={freeSolo}
      filterOptions={identity}
      openOnFocus={openOnFocus}
      renderOption={(option) => {
        const contact = optionsMap.get(option) as ContactDTO;

        return (
          <ListItemText
            primary={
              contact.name || (
                <Typography display="inline" color="textSecondary">
                  No name
                </Typography>
              )
            }
            secondary={contact.email}
          />
        );
      }}
      renderInput={(params) => <TextField {...params} {...textFiledProps} />}
    />
  );
}
