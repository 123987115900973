import { IconButton, Typography } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import { Inline, OverflowText, Stack } from '@superdispatch/ui';
import { PageHeader } from 'shared/layout/PageLayout';
import { BackButton } from 'shared/ui/BackButton';
import { HiddenForPrint } from 'shared/ui/Print';
import { LoadLegsDiagram } from '../core/LoadLegsDiagram';
import { LoadTags } from '../core/LoadTags';
import { LoadDTO } from '../data/LoadDTO';
import { ViewLoadAction } from './ViewLoadActionGroups';
import { ViewLoadHeaderActions } from './ViewLoadHeaderActions';

interface ViewLoadPageHeaderProps {
  load?: LoadDTO;
  layout?: 'print';

  onBackClick?: () => void;
  onEditClick?: () => void;
  onAction?: (action: ViewLoadAction) => void;
}

export function ViewLoadPageHeader({
  load,
  layout,
  onAction,
  onBackClick,
  onEditClick,
}: ViewLoadPageHeaderProps) {
  return (
    <PageHeader
      title={
        !load ? (
          <Skeleton width={168} height={46} />
        ) : (
          <Inline verticalAlign="center">
            <Stack>
              <Inline space="xsmall" verticalAlign="center">
                <OverflowText aria-label="Load Number" variant="h2">
                  {load.number || 'No Load ID'}
                </OverflowText>

                <Inline space="small">
                  <LoadTags load={load} />

                  {layout !== 'print' && onEditClick && load.can_be_edited && (
                    <HiddenForPrint>
                      <IconButton
                        size="small"
                        onClick={onEditClick}
                        aria-label="Edit Load Details Button"
                      >
                        <Edit />
                      </IconButton>
                    </HiddenForPrint>
                  )}
                </Inline>
              </Inline>

              <Typography>Internal Load ID: {load.internal_load_id}</Typography>
            </Stack>

            <LoadLegsDiagram terminalsDiagram={load.terminals_diagram} />
          </Inline>
        )
      }
      startAction={
        layout !== 'print' &&
        onBackClick && (
          <HiddenForPrint>
            <BackButton onClick={onBackClick} />
          </HiddenForPrint>
        )
      }
      endActions={
        load && (
          <ViewLoadHeaderActions
            load={load}
            layout={layout}
            onAction={onAction}
          />
        )
      }
    />
  );
}
