import { useMemo } from 'react';
import { useQueryClient } from 'react-query';
import { APIResponse, requestCarrierAPI } from 'shared/api/APIClient';
import {
  APIListQueryOptions,
  APIListQueryResult,
  useAPIListQuery,
} from 'shared/api/APIListQuery';
import { useAPIQuery } from 'shared/api/APIQuery';
import {
  AcceptPublicOfferResponseDTO,
  acceptPublicOfferResponseSchema,
  PublicDriverAcceptDriverResponseDTO,
  publicDriverAcceptResponseSchema,
  publicDriverInviteResponseSchema,
  PublicOfferAddDriverDTO,
  PublicOfferAssignResponseDTO,
  publicOfferAssignResponseSchema,
  PublicOfferDeclinePayloadDTO,
  PublicOfferDriverDTO,
  publicOfferDriverSchema,
  PublicOfferInviteDriverDTO,
  PublicOfferInviteResponseDTO,
  publicOfferSchema,
} from './PublicOfferDTO';

export function usePublicOfferAPI() {
  const queryClient = useQueryClient();

  return useMemo(() => {
    const invalidatePublicOfferCache = () => {
      void queryClient.invalidateQueries('public-offer');
    };

    return {
      invalidatePublicOfferCache,

      acceptPublicOffer: (
        guid: string,
        isFirstOffer: boolean,
      ): Promise<AcceptPublicOfferResponseDTO> => {
        return requestCarrierAPI([
          'POST /internal/web/offers/aa/{guid}/accept/{?is_first_offer}',
          { guid, is_first_offer: isFirstOffer },
        ]).then(
          (response) => {
            invalidatePublicOfferCache();
            return acceptPublicOfferResponseSchema.cast(response.data);
          },
          (error) => {
            invalidatePublicOfferCache();
            return Promise.reject(error);
          },
        );
      },

      declinePublicOffer: (
        guid: string,
        isFirstOffer: boolean,
        payload: PublicOfferDeclinePayloadDTO,
      ): Promise<APIResponse> => {
        return requestCarrierAPI(
          [
            'POST /internal/web/offers/aa/{guid}/decline/{?is_first_offer}',
            { guid, is_first_offer: isFirstOffer },
          ],
          { json: payload },
        ).then(
          (response) => {
            invalidatePublicOfferCache();
            return response;
          },
          (error) => {
            invalidatePublicOfferCache();
            return Promise.reject(error);
          },
        );
      },

      assignDriver: (
        token: string,
        offerGUID: string,
        driverGUID: string,
        isNewDriver: boolean,
        isFirstOffer: boolean,
      ): Promise<PublicOfferAssignResponseDTO> => {
        return requestCarrierAPI(
          [
            'POST /internal/web/offers/{guid}/assign/{?new_driver,is_first_offer}',
            {
              guid: offerGUID,
              new_driver: isNewDriver,
              is_first_offer: isFirstOffer,
            },
          ],
          {
            json: { driver_guid: driverGUID },
            headers: { authorization: `Token ${token}` },
          },
        ).then(
          (response) => {
            invalidatePublicOfferCache();
            return publicOfferAssignResponseSchema.cast(response.data);
          },
          (error) => {
            invalidatePublicOfferCache();
            return Promise.reject(error);
          },
        );
      },

      acceptJoinRequest: (
        guid: string,
        name: string,
      ): Promise<PublicDriverAcceptDriverResponseDTO> => {
        return requestCarrierAPI(
          ['POST /internal/web/drivers/{guid}/accept/', { guid }],
          { json: { name } },
        ).then((res) => {
          invalidatePublicOfferCache();
          return publicDriverAcceptResponseSchema.cast(res.data);
        });
      },

      addDriver: (
        token: string,
        data: PublicOfferAddDriverDTO,
      ): Promise<PublicOfferDriverDTO> => {
        return requestCarrierAPI('POST /internal/web/offers/drivers/', {
          json: data,
          headers: { authorization: `Token ${token}` },
        }).then((response) => {
          invalidatePublicOfferCache();
          return publicOfferDriverSchema.cast(response.data);
        });
      },

      inviteDriver: (
        token: string,
        data: PublicOfferInviteDriverDTO,
      ): Promise<PublicOfferInviteResponseDTO> => {
        return requestCarrierAPI('POST /internal/web/offers/drivers/', {
          json: data,
          headers: { authorization: `Token ${token}` },
        }).then((response) => {
          return publicDriverInviteResponseSchema.cast(response.data);
        });
      },
    };
  }, [queryClient]);
}

export function usePublicOffer(guid: string) {
  return useAPIQuery(
    ['public-offer', guid],
    () => requestCarrierAPI(['GET /internal/web/offers/aa/{guid}/', { guid }]),
    { schema: publicOfferSchema },
  );
}

export interface DriverListParams {
  q?: string;
  page_size?: number;
  exclude_suspended?: boolean;
}

export function usePublicOfferDriversList(
  token: string,
  params?: DriverListParams,
  options?: APIListQueryOptions<PublicOfferDriverDTO>,
): APIListQueryResult<PublicOfferDriverDTO> {
  return useAPIListQuery(
    ['public-offers', 'driver-list', params],
    (page) =>
      requestCarrierAPI(
        ['GET /internal/web/offers/drivers/{?page,params*}', { page, params }],
        {
          headers: { authorization: `Token ${token}` },
        },
      ),
    { ...options, schema: publicOfferDriverSchema },
  );
}
