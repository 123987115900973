import { Cached, GetApp, Lock, ThumbUp } from '@material-ui/icons';
import {
  FormikMaxLengthTextField,
  useFormikEnhanced,
} from '@superdispatch/forms';
import { Inline, Stack, useSnackbarStack } from '@superdispatch/ui';
import { Button, TextBox } from '@superdispatch/ui-lab';
import { FormikProvider } from 'formik';
import { useState } from 'react';
import { useExperimentState } from 'shared/modules/experiments/ExperimentsAPI';
import { ExperimentPageLayout } from '../core/ExperimentPageLayout';
import {
  centralDispatchAccountCredentialsSchema,
  useCentralDispatchIntegrationAPI,
} from './useVerifyCentralDispatchAccount';
import { VerifyCentralDispatchAccountSkipDialog } from './VerifyCentralDispatchAccountSkipDialog';

export function VerifyCentralDispatchAccountPage() {
  const { verifyCentralAccount } = useCentralDispatchIntegrationAPI();
  const { addSnackbar } = useSnackbarStack();
  const [dialogState, setDialogState] = useState<'skip-reason-dialog'>();
  const { markAsViewed } = useExperimentState('Central Dispatch Integration');

  const formik = useFormikEnhanced({
    validationSchema: centralDispatchAccountCredentialsSchema,
    initialValues: {
      username: '',
      password: '',
    },
    onSubmit: verifyCentralAccount,
    onSubmitSuccess: () => {
      void markAsViewed();
      window.location.reload();
    },
    onSubmitFailure: (response) => {
      addSnackbar(response.message, { variant: 'error' });
    },
  });

  return (
    <ExperimentPageLayout size="small">
      <VerifyCentralDispatchAccountSkipDialog
        open={dialogState === 'skip-reason-dialog'}
        onClose={() => {
          setDialogState(undefined);
        }}
      />

      <Stack space="medium">
        <Stack>
          <TextBox variant="heading-2">Central Dispatch Integration</TextBox>
          <Inline verticalAlign="center">
            <GetApp fontSize="small" color="action" />
            <TextBox variant="caption">Import your Contacts and Orders</TextBox>
          </Inline>
          <Inline verticalAlign="center">
            <ThumbUp fontSize="small" color="action" />
            <TextBox variant="caption">
              Load recommendations from Super Loadboard
            </TextBox>
          </Inline>
          <Inline verticalAlign="center">
            <Cached fontSize="small" color="action" />
            <TextBox variant="caption">
              Sync load statuses from Super Dispatch to Central Dispatch
            </TextBox>
          </Inline>
        </Stack>

        <FormikProvider value={formik}>
          <Stack space="medium">
            <Stack space="small">
              <FormikMaxLengthTextField
                label="Central Dispatch’s Username"
                name="username"
                fullWidth={true}
              />
              <FormikMaxLengthTextField
                label="Password"
                name="password"
                fullWidth={true}
              />
            </Stack>

            <Stack>
              <Button
                variant="primary"
                fullWidth={true}
                size="large"
                onClick={() => {
                  void formik.submitForm();
                }}
              >
                Integrate with Central Dispatch
              </Button>
              <Inline verticalAlign="center" horizontalAlign="center">
                <Lock fontSize="small" color="action" />
                <TextBox variant="caption" align="center">
                  Your credentials are saved and stored securely
                </TextBox>
              </Inline>
            </Stack>
          </Stack>
        </FormikProvider>

        <Button
          variant="text"
          fullWidth={true}
          size="large"
          onClick={() => {
            setDialogState('skip-reason-dialog');
          }}
        >
          Skip Integration
        </Button>
      </Stack>
    </ExperimentPageLayout>
  );
}
