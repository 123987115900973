import {
  IconButton,
  InputAdornment,
  InputBaseComponentProps,
  Link,
  TextField,
  TextFieldProps,
} from '@material-ui/core';
import { FileCopy } from '@material-ui/icons';
import { useSnackbarStack } from '@superdispatch/ui';
import { useClipboard } from 'shared/helpers/DomHelpers';
import styled from 'styled-components';

interface InputLinkProps extends InputBaseComponentProps {
  value: string;
  href: string;
}

const Wrapper = styled.div`
  width: 100%;
  overflow-x: hidden;
  padding: 6px 8px;
`;

function InputLink({ value, href }: InputLinkProps) {
  return (
    <Wrapper>
      <Link color="primary" href={href}>
        {value}
      </Link>
    </Wrapper>
  );
}

interface CopyableLinkedInputProps
  extends Omit<
    TextFieldProps,
    'onChange' | 'InputProps' | 'inputProps' | 'select' | 'SelectProps'
  > {
  value: string;
  href: string;
  copyMessage?: string;
}

export function CopyableLinkedInput({
  value,
  href,
  copyMessage = 'Link copied!',
  ...props
}: CopyableLinkedInputProps) {
  const { copy } = useClipboard();
  const { addSnackbar } = useSnackbarStack();

  return (
    <TextField
      {...props}
      value={value}
      InputProps={{
        readOnly: true,
        inputComponent: InputLink,
        inputProps: { href },
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              onClick={() => {
                copy(value);
                addSnackbar(copyMessage, { variant: 'success' });
              }}
            >
              <FileCopy fontSize="small" color="action" />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
}
