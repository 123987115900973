import { Collapse, IconButton } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { Inline, Stack } from '@superdispatch/ui';
import { Box, Button, TextBox } from '@superdispatch/ui-lab';
import { useMemo } from 'react';
import { formatPlural } from 'shared/helpers/IntlHelpers';
import { LocalStore, useLocalStore } from 'shared/helpers/Store';
import { openExternalURL } from 'shared/helpers/URLHelpers';
import { useLoadboardLoadsCount } from 'shared/modules/loadboard/LoadboardLoadsCount';
import { LoadsPageSuggestedLoad } from './LoadsPageSuggestedLoad';
import { SuggestedLoadDTO } from './suggested-loads-components/data/SuggestedLoadDTO';

function useCollapseState() {
  const key = 'is_loads_page_suggested_loads_expanded';
  const value = useLocalStore(key, 'true');
  const isExpanded = value === 'true';

  const toggleExpanded = () => {
    LocalStore.set(key, String(!isExpanded));
  };

  return [isExpanded, toggleExpanded] as const;
}

interface Props {
  loads: SuggestedLoadDTO[];
}

export function LoadsPageSuggestedLoadsList({ loads }: Props) {
  const [isExpanded, toggleExpanded] = useCollapseState();
  const loadCounts = useLoadboardLoadsCount();
  const loadsToRender = useMemo(() => {
    return loads.slice(0, 2);
  }, [loads]);

  return (
    <Stack space="small">
      <Inline verticalAlign="center">
        <Inline verticalAlign="center">
          <TextBox variant="heading-6">SUGGESTED LOADS</TextBox>
          <IconButton
            aria-label="suggested loads toggler"
            size="small"
            onClick={() => {
              toggleExpanded();
            }}
          >
            {isExpanded ? (
              <ExpandLess fontSize="small" />
            ) : (
              <ExpandMore fontSize="small" />
            )}
          </IconButton>
        </Inline>
        {!!loadCounts.data?.suggested && loadCounts.data.suggested > 2 && (
          <Button
            variant="text"
            onClick={() => {
              openExternalURL(
                '/loadboard/loads{?current_page,current_page_size,current_tab,page_sort,search_criteria,utm_medium,utm_source}',
                {
                  current_page: 0,
                  current_page_size: 50,
                  current_tab: 'suggested',
                  page_sort: 'posted_to_loadboard_at,desc',
                  search_criteria: '{}',
                  target: '_blank',
                  utm_source: 'Web CTMS',
                  utm_medium: 'Suggested Loads',
                },
              );
            }}
          >
            View {loadCounts.data.suggested - 2} More{' '}
            {formatPlural(loadCounts.data.suggested - 2, 'Load', 'Loads')}
          </Button>
        )}
      </Inline>
      <Collapse in={isExpanded}>
        <Box marginBottom="large">
          <Stack>
            {loadsToRender.map((load) => (
              <LoadsPageSuggestedLoad key={load.guid} load={load} />
            ))}
          </Stack>
        </Box>
      </Collapse>
    </Stack>
  );
}
