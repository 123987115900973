import { IconButton, Link } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { FormikTextField } from '@superdispatch/forms';
import {
  DrawerActions,
  DrawerContent,
  DrawerTitle,
  Stack,
} from '@superdispatch/ui';
import { Button, TextBox } from '@superdispatch/ui-lab';
import { showNewIntercomMessage } from 'shared/helpers/Intercom';
import { StepProps } from './DowngradeConfirmationStep';

export function DowngradeFeedbackStep({ onNext, onClose, formik }: StepProps) {
  return (
    <>
      <DrawerTitle
        title="Downgrading Plan"
        endAction={
          <IconButton edge="end" onClick={onClose}>
            <Close aria-label="close" />
          </IconButton>
        }
      />

      <Stack space="small">
        <DrawerContent>
          <Stack space="small">
            <FormikTextField
              name="feedback"
              label="Please tell us how we can improve"
              placeholder="Explain us in few sentances how we can improve Carrier TMS"
              multiline={true}
              fullWidth={true}
            />
          </Stack>
        </DrawerContent>

        <DrawerActions>
          <Stack space="large">
            <Button onClick={onNext} pending={formik.isSubmitting}>
              Send Request for Downgrade
            </Button>

            <TextBox color="secondary">
              For any questions or feedback, please{' '}
              <Link
                component="button"
                type="button"
                align="center"
                onClick={() => {
                  showNewIntercomMessage();
                }}
              >
                contact support
              </Link>
            </TextBox>
          </Stack>
        </DrawerActions>
      </Stack>
    </>
  );
}
