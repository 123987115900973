import { FormikContextTypeEnhanced } from '@superdispatch/forms';
import { useDeepEqualMemo, useValueObserver } from '@superdispatch/hooks';
import { FormikValues } from 'formik';
import {
  createContext,
  ReactNode,
  RefObject,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useLazyRef } from 'shared/hooks/useLazyRef';

type Form = FormikContextTypeEnhanced<FormikValues, unknown>;
type FormStatus = 'initial' | 'rejected' | 'submitted';

interface FormState {
  isDirty: boolean;
  isValid: boolean;
  status: FormStatus;
  isSubmitting: boolean;
}

interface FormikComposerState {
  isDirty: boolean;
  isValid: boolean;
  status: FormStatus;
  isSubmitting: boolean;
  formStates: Map<string, FormState>;
}

export interface FormikComposer {
  state: FormikComposerState;
  getForm: (name: string) => Form;
  submitForms: () => void;
  syncFormState: (name: string, state: FormState) => void;
  registerForm: (name: string, form: Form) => RefObject<HTMLElement>;
}

export interface FormikComposerProviderProps {
  children?: ReactNode;
  value: FormikComposer;
}

const context = createContext<null | FormikComposer>(null);

export function FormikComposerProvider(props: FormikComposerProviderProps) {
  return <context.Provider {...props} />;
}

export interface FormikComposerOptions {
  onSubmitSuccess?: (state: FormikComposerState) => void;
  onSubmitFailure?: (state: FormikComposerState) => void;
}

export function useFormikComposer({
  onSubmitSuccess,
  onSubmitFailure,
}: FormikComposerOptions = {}): FormikComposer {
  const forms = useRef(new Set<string>());
  const getFormRef = useLazyRef<Form>();
  const getNodeRef = useLazyRef<HTMLElement>();
  const [formStates, setFormStates] = useState(
    () => new Map<string, FormState>(),
  );

  const state = useDeepEqualMemo<FormikComposerState>(() => {
    const nextState: FormikComposerState = {
      formStates,
      isDirty: false,
      isValid: false,
      isSubmitting: false,
      status: 'initial',
    };

    let initialFormsCount = 0;
    let rejectedFormsCount = 0;
    let submittedFormsCount = 0;

    for (const formState of formStates.values()) {
      nextState.isDirty = nextState.isDirty || formState.isDirty;
      nextState.isValid = nextState.isValid || formState.isValid;
      nextState.isSubmitting = nextState.isSubmitting || formState.isSubmitting;

      // Only compute state of the changed forms.
      if (formState.isDirty) {
        switch (formState.status) {
          case 'initial':
            initialFormsCount++;
            break;
          case 'submitted':
            submittedFormsCount++;
            break;
          case 'rejected':
            rejectedFormsCount++;
            break;
        }
      }
    }

    if (initialFormsCount === 0) {
      if (rejectedFormsCount > 0) {
        nextState.status = 'rejected';
      } else if (submittedFormsCount > 0) {
        nextState.status = 'submitted';
      }
    }

    return nextState;
  }, [formStates]);

  const submitForms = useCallback(() => {
    let isScrolledToError = false;
    for (const formName of forms.current) {
      const form = getFormRef(formName);
      const node = getNodeRef(formName);

      if (form.current?.dirty) {
        form.current
          .submitForm()
          .then(() => {
            // Handle local validation
            if (!isScrolledToError && !form.current?.isValid) {
              isScrolledToError = true;
              node.current?.scrollIntoView();
            }
          })
          .catch(() => {
            // Handle server error
            if (!isScrolledToError) {
              isScrolledToError = true;
              node.current?.scrollIntoView();
            }
          });
      }
    }
  }, [getFormRef, getNodeRef]);

  useValueObserver(state.status, () => {
    if (state.status === 'rejected') onSubmitFailure?.(state);
    if (state.status === 'submitted') onSubmitSuccess?.(state);
  });

  return useMemo(
    () => ({
      state,
      submitForms,
      getForm: (name) => {
        const form = getFormRef(name).current;
        if (!form) {
          throw new Error(`Attempted to access unregistered form: ${name}`);
        }
        return form;
      },
      registerForm: (name, form) => {
        forms.current.add(name);
        Object.defineProperty(getFormRef(name), 'current', { value: form });
        return getNodeRef(name);
      },
      syncFormState: (name, formState) => {
        setFormStates((prev) => {
          const next = new Map(prev);
          next.set(name, formState);
          return next;
        });
      },
    }),
    [state, submitForms, getFormRef, getNodeRef],
  );
}

export function useFormikComposerForm(
  name: string,
  form: Form,
): RefObject<HTMLElement> {
  const ctx = useContext(context);
  if (!ctx) throw new Error('Form composer not found');
  const { syncFormState, registerForm } = ctx;

  useEffect(() => {
    syncFormState(name, {
      isValid: form.isValid,
      isDirty: form.dirty,
      status: form.status.type,
      isSubmitting: form.isSubmitting,
    });
  }, [
    name,
    syncFormState,
    form.dirty,
    form.isValid,
    form.status.type,
    form.isSubmitting,
  ]);

  return registerForm(name, form);
}
