import { Stack } from '@superdispatch/ui';
import { useAppFormik } from 'shared/form/AppFormik';
import { DocumentTitle } from 'shared/layout/DocumentTitle';
import { SettingsPageForm } from './core/SettingsPageLayout';
import {
  useEmailNotificationSettings,
  useLoadOfferNotificationSettings,
  useSettingsAPI,
} from './data/SettingsAPI';
import {
  emailNotificationSettingsSchema,
  loadOfferNotificationSettingsPayloadSchema,
} from './data/SettingsDTO';
import { SettingsEmailNotifications } from './SettingsEmailNotifications';
import { SettingsSMSAndEmailNotifications } from './SettingsSMSAndEmailNotifications';

const settingsFormSchema = loadOfferNotificationSettingsPayloadSchema.concat(
  emailNotificationSettingsSchema,
);

export function SettingsNotificationsPage() {
  const { data: emailNotifications } = useEmailNotificationSettings();
  const { data: loadOfferNotification } = useLoadOfferNotificationSettings();
  const {
    patchEmailNotificationSettings,
    updateLoadOfferNotificationSettings,
  } = useSettingsAPI();

  const formik = useAppFormik({
    initialValues: {
      ...emailNotifications,
      ...loadOfferNotification,
    },
    validationSchema: settingsFormSchema,
    async onSubmit(values) {
      await patchEmailNotificationSettings(
        emailNotificationSettingsSchema.cast(values),
        emailNotifications,
      );
      return updateLoadOfferNotificationSettings(
        loadOfferNotificationSettingsPayloadSchema.cast(values),
      );
    },
    getSuccessMessage() {
      return 'Notification Settings successfully updated';
    },
  });

  return (
    <>
      <DocumentTitle title="Notifications - Settings" />

      <SettingsPageForm
        formik={formik}
        title="Notifications"
        loading={!emailNotifications || !loadOfferNotification}
      >
        <Stack space="small">
          <SettingsSMSAndEmailNotifications
            loadOfferNotification={loadOfferNotification}
          />

          <SettingsEmailNotifications />
        </Stack>
      </SettingsPageForm>
    </>
  );
}
