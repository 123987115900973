import { trackEvent } from 'shared/helpers/Analytics';

export type DriversAnalyticsEvent =
  | {
      name: 'CTMS: Driver Added';
      properties: {
        guid: string;
        userGuid?: string;
        source: 'New Drivers Page' | 'Assign Offer Page' | 'Assign Loads Page';
      };
    }
  | {
      name: 'CTMS: Driver Invited';
      properties: {
        guid: string;
        userGuid?: string;
        source: 'New Drivers Page' | 'Assign Offer Page' | 'Assign Loads Page';
      };
    }
  | {
      name: 'Carrier Assigned Driver';
    }
  | {
      name: 'CTMS: Opened React Drivers Page';
    }
  | {
      name: 'Carrier Clicked Add Driver';
    }
  | {
      name: 'Carrier Clicked Invite Driver';
    }
  | {
      name: 'Carrier clicked Download the App';
    }
  | {
      name: 'Carrier Declined Join Request';
    }
  | {
      name: 'Carrier Accepted Join Request';
    }
  | { name: 'Carrier Clicked Decline Button' }
  | { name: 'Carrier Clicked Accept Button' }
  | { name: 'Carrier Sent Invite To Driver' };

export function trackDriversEvent(event: DriversAnalyticsEvent) {
  switch (event.name) {
    case 'CTMS: Driver Added':
      trackEvent(event.name, {
        driver_guid: event.properties.guid,
        user_guid: event.properties.userGuid,
        source: event.properties.source,
      });

      break;
    case 'CTMS: Driver Invited':
      trackEvent(event.name, {
        driver_guid: event.properties.guid,
        user_guid: event.properties.userGuid,
        source: event.properties.source,
      });

      break;

    default:
      trackEvent(event.name);
  }
}
