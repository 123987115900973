import { createSvgIcon } from 'shared/icons/IconUtils';

export const PersonAddAlt = createSvgIcon(
  'PersonAddAlt',
  <path
    d="M11.9998 9.33329V7.33329H9.99984V5.99996H11.9998V3.99996H13.3332V5.99996H15.3332V7.33329H13.3332V9.33329H11.9998ZM5.99984 7.99996C5.2665 7.99996 4.63873 7.73885 4.1165 7.21663C3.59428 6.6944 3.33317 6.06663 3.33317 5.33329C3.33317 4.59996 3.59428 3.97218 4.1165 3.44996C4.63873 2.92774 5.2665 2.66663 5.99984 2.66663C6.73317 2.66663 7.36095 2.92774 7.88317 3.44996C8.40539 3.97218 8.6665 4.59996 8.6665 5.33329C8.6665 6.06663 8.40539 6.6944 7.88317 7.21663C7.36095 7.73885 6.73317 7.99996 5.99984 7.99996ZM0.666504 13.3333V11.4666C0.666504 11.0888 0.763726 10.7416 0.958171 10.425C1.15261 10.1083 1.41095 9.86663 1.73317 9.69996C2.42206 9.35551 3.12206 9.09718 3.83317 8.92496C4.54428 8.75274 5.2665 8.66663 5.99984 8.66663C6.73317 8.66663 7.45539 8.75274 8.1665 8.92496C8.87761 9.09718 9.57761 9.35551 10.2665 9.69996C10.5887 9.86663 10.8471 10.1083 11.0415 10.425C11.2359 10.7416 11.3332 11.0888 11.3332 11.4666V13.3333H0.666504Z"
    fill="currentColor"
  />,
  { viewBox: '0 0 16 16', style: { height: '16px', width: '16px' } },
);
