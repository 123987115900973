import { openExternalURL } from 'shared/helpers/URLHelpers';
import { getAuthToken } from './AuthToken';

interface RedirectToTMSProps {
  children: React.ReactElement;
}

export function RedirectToTMS({ children }: RedirectToTMSProps) {
  const authToken = getAuthToken();

  if (authToken) {
    openExternalURL('/tms/loads');
  }

  return children;
}
