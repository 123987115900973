import { ListItem, ListItemText } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { VisibilityObserver } from '@superdispatch/ui';
import { Box, Sidebar, SidebarMenuItem, TextBox } from '@superdispatch/ui-lab';
import { useEffect, useMemo } from 'react';
import { DispatcherAvatar } from '../core/DispatcherAvatar';
import { useDispatcherPageContext } from '../data/DispatchersProvider';
import { DispatchersSidebarHeader } from './DispatchersSidebarHeader';

export function DispatchersListSidebar() {
  const {
    openDispatcher,
    currentDispatcherGUID,
    searchQueryDrivers,
    dispatcherList,
  } = useDispatcherPageContext();
  const [searchText] = searchQueryDrivers;

  const { isFetching, data, isFetchingNextPage, fetchNextPage, hasNextPage } =
    dispatcherList;

  const list = useMemo(() => data?.pages.flatMap((item) => item.data), [data]);

  useEffect(() => {
    const fistDriverGUID = list?.[0]?.guid;
    if (!isFetching && fistDriverGUID && !currentDispatcherGUID) {
      openDispatcher(fistDriverGUID, true);
    }
  }, [openDispatcher, isFetching, list, currentDispatcherGUID]);

  return (
    <Sidebar title="Dispatchers" header={<DispatchersSidebarHeader />}>
      {!data?.pages.length ? (
        <ListItem>
          <ListItemText primary={<Skeleton />} secondary={<Skeleton />} />
        </ListItem>
      ) : !list?.length ? (
        <Box padding="small">
          <TextBox color="secondary" align="center">
            {searchText ? 'No search results' : 'No dispatchers'}
          </TextBox>
        </Box>
      ) : (
        list.map((dispatcher) => {
          const isCurrent = currentDispatcherGUID === dispatcher.guid;
          const isInvitedDispatcher =
            dispatcher.connection_status === 'pending' &&
            dispatcher.connection_type === 'invite';
          const dispatcherLabel = isInvitedDispatcher
            ? dispatcher.email
            : dispatcher.name;

          return (
            <SidebarMenuItem
              key={dispatcher.guid}
              selected={isCurrent}
              onClick={() => {
                openDispatcher(dispatcher.guid);
              }}
              avatar={<DispatcherAvatar dispatcher={dispatcher} />}
            >
              {dispatcherLabel}
            </SidebarMenuItem>
          );
        })
      )}

      {hasNextPage && (
        <VisibilityObserver
          onChange={(visibility) => {
            if (!isFetchingNextPage && visibility === 'visible') {
              void fetchNextPage();
            }
          }}
          render={({ ref }) => (
            <ListItem ref={ref}>
              <ListItemText primary={<Skeleton />} secondary={<Skeleton />} />
            </ListItem>
          )}
        />
      )}
    </Sidebar>
  );
}
