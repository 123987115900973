import useWebSocket, { Options, ReadyState } from 'react-use-websocket';

interface WebSocketHookProps {
  url: string | null;
  onOpen?: () => void;
  onClose?: () => void;
  onMessage?: (data: Record<string, unknown>) => void;
  options?: Options;
}

interface Payload {
  type: string;
  [key: string]: unknown;
}

/**
 * useWebSocketHook is a custom hook that wraps the useWebSocket hook from react-use-websocket
 * It is used to send and receive messages from websocket
 * @param url - The url to use for the websocket
 * @param onOpen - The function to run when the websocket opens
 * @param onClose - The function to run when the websocket closes
 * @param onMessage - The function to run when the websocket receives a message
 * @param options - The options to pass to the useWebSocket hook
 */
export function useWebSocketHook({
  url,
  onOpen,
  onClose,
  onMessage,
  options,
}: WebSocketHookProps) {
  const { sendMessage: sendWebSocketMessage, readyState } = useWebSocket(url, {
    ...options,
    onOpen,
    onClose,
    onMessage: (event) => {
      if (onMessage) {
        const payload = JSON.parse(event.data) as Payload;
        onMessage(payload);
      }
    },
  });

  const isReady = readyState === ReadyState.OPEN;

  // Define a method to send a payload
  const send = (payload: unknown) => {
    sendWebSocketMessage(JSON.stringify(payload));
  };

  return {
    send,
    isReady,
  };
}
