import { trim } from 'shared/helpers/CommonHelpers';

export const EMAIL_VALIDATION_PATTERN = /[\S]+@[\S]+\.[\S]+/i;
export interface FieldRules {
  type?: string;
  typeMessage?: string;
  required?: boolean;
  requiredMessage?: string;
  isEmpty?: (value: unknown) => boolean;
}

export function validateField(
  value: unknown,
  {
    type,
    typeMessage = 'Invalid Type',
    required,
    requiredMessage = 'This field is required',
    isEmpty = (input) => input == null,
  }: FieldRules,
): string | void {
  if (isEmpty(value)) {
    if (required) {
      return requiredMessage;
    }

    return;
  }

  if (type != null && typeof value !== type) {
    return typeMessage;
  }
}

export interface ValidateStringRules
  extends Omit<FieldRules, 'type' | 'isEmpty'> {
  blankMessage?: string;

  pattern?: RegExp;
  patternMessage?: string;
  maxLength?: number;
  maxLengthMessage?: string;
  minLength?: number;
  minLengthMessage?: string;
}

export function validateString(
  value: unknown,
  {
    pattern,
    patternMessage = 'Invalid Value',
    maxLength,
    maxLengthMessage = `Maximum length is ${maxLength}`,
    minLength,
    minLengthMessage = `This field should have at least ${minLength} characters`,
    ...fieldRules
  }: ValidateStringRules = {},
): string | void {
  const fieldError = validateField(value, {
    type: 'string',
    isEmpty: (input) =>
      input == null || (typeof input === 'string' && input.trim().length === 0),
    ...fieldRules,
  });

  if (fieldError) {
    return fieldError;
  }

  const string = trim(value);
  const stringLength = string.length;

  if (!string && !fieldRules.required) {
    return;
  }

  if (pattern != null && !pattern.test(string)) {
    return patternMessage;
  }

  if (maxLength != null && stringLength > maxLength) {
    return maxLengthMessage;
  }

  if (minLength != null && stringLength < minLength) {
    return minLengthMessage;
  }
}

export interface ValidateNumberRules {
  typeMessage?: string;
  required?: boolean;
  requiredMessage?: string;
  max?: number;
  maxMessage?: string;
  min?: number;
  minMessage?: string;
}

export function validateNumber(
  value: unknown,
  {
    typeMessage = 'Invalid Type',
    required,
    requiredMessage = 'This field is required',
    max,
    maxMessage = `Value cannot be greater than ${max}`,
    min,
    minMessage = `Value cannot be less than ${min}`,
  }: ValidateNumberRules = {},
): string | undefined {
  if (value == null) {
    if (required) {
      return requiredMessage;
    }

    return undefined;
  }

  if (typeof value !== 'number') {
    return typeMessage;
  }

  if (max != null && value > max) {
    return maxMessage;
  }

  if (min != null && value < min) {
    return minMessage;
  }

  return undefined;
}
