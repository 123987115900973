import { Typography } from '@material-ui/core';
import { useResponsiveValue } from '@superdispatch/ui';
import { AnchorButton, Button } from '@superdispatch/ui-lab';
import { MobileAppBridge } from 'shared/data/MobileAppBridge';
import { useQuery } from 'shared/helpers/RoutingHelpers';
import {
  DialogWithIllustration,
  DialogWithIllustrationProps,
} from 'shared/ui/DialogWithIllustration';
import { PendingVerificationIllustration } from './PendingVerificationIllustration';

const isCarrierTMSWebView = import.meta.env.VITE_APP_NAME === 'ctmsw';

function addUTMs(path: string) {
  const url = new URL(path, window.location.origin);

  url.searchParams.set('utm_source', 'Web CTMS');
  url.searchParams.set('utm_medium', 'Carrier Verification Page');

  return url.toString();
}

type PendingVerificationDialogProps = Omit<
  DialogWithIllustrationProps,
  'illustrationSrc' | 'title' | 'actions'
>;

export function PendingVerificationDialog({
  ...props
}: PendingVerificationDialogProps) {
  const isMobile = useResponsiveValue(true, false);
  const [{ back_url, utm_medium }] = useQuery();

  const loadboardURL = addUTMs(
    isCarrierTMSWebView ? '/loadboard-mobile/loads' : '/loadboard/loads',
  );
  const backURL = back_url && addUTMs(decodeURIComponent(back_url));
  const isFromLoadboard =
    utm_medium === 'slb_load_details' || utm_medium === 'Load Details';

  return (
    <DialogWithIllustration
      {...props}
      title="Verification Submitted Successfully"
      illustration={<PendingVerificationIllustration />}
      actions={
        isCarrierTMSWebView && !backURL ? (
          <Button
            onClick={() => MobileAppBridge.closeScreen()}
            fullWidth={true}
          >
            Got it
          </Button>
        ) : (
          <AnchorButton
            href={isFromLoadboard && backURL ? backURL : loadboardURL}
          >
            Continue to Super Loadboard
          </AnchorButton>
        )
      }
    >
      <Typography align={isMobile ? 'left' : 'center'}>
        Your application is under review. Expect a response within 24-48 hours.
        We will reach out if additional information is needed.
      </Typography>
    </DialogWithIllustration>
  );
}
