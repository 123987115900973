import { useParams } from 'react-router-dom';
import { HeaderWithLogo } from 'shared/layout/HeaderWithLogo';
import { SplashScreen } from 'shared/layout/SplashScreen';
import { DriverActivationCompletedPage } from '../core/DriverActivationCompletedPage';
import { useDriverInvitationDetails } from '../data/DriversAPI';
import { DriverInvitationDeclinedPage } from './DriverInvitationDeclinedPage';
import { DriverInvitationForm } from './DriverInvitationForm';

export function DriverInvitationPage() {
  const { invitationCode } = useParams<{ invitationCode: string }>();
  const { data, isLoading } = useDriverInvitationDetails(invitationCode);

  if (isLoading) {
    return <SplashScreen />;
  }

  return (
    <>
      <HeaderWithLogo />
      {data?.status === 'pending' ? (
        <DriverInvitationForm driver={data} invitationCode={invitationCode} />
      ) : data?.status === 'activated' ? (
        <DriverActivationCompletedPage carrierName={data.carrier_name} />
      ) : (
        <DriverInvitationDeclinedPage />
      )}
    </>
  );
}
