import { Card, CardContent, Typography } from '@material-ui/core';
import { FormikTextField, useFormikEnhanced } from '@superdispatch/forms';
import { Inline, Stack } from '@superdispatch/ui';
import { FormikProvider } from 'formik';
import { useInitialValues } from 'shared/form/AppFormik';
import { useFormikComposerForm } from 'shared/form/FormikComposer';
import { LoadDetailsIcon } from 'shared/icons/LoadDetailsIcon';
import { useFlag } from 'shared/settings/FeatureToggles';
import { LoadDetailsForm } from '../core/LoadDetailsForm';
import { loadDetailsEditSchema } from '../data/LoadActionsDTO';
import { isDisabledLoadId, LoadDTO } from '../data/LoadDTO';
import { useLoadsAPI } from '../data/LoadsAPI';

export interface EditLoadPageDetailsProps {
  load: LoadDTO;
  isEnabledEditLoad: boolean;
}

export function EditLoadPageDetails({
  load,
  isEnabledEditLoad,
}: EditLoadPageDetailsProps) {
  const isCarrierRestrictLoadIdEnabled = useFlag(
    'carrier_restrict_changing_load_id',
  );
  const { patchLoadDetails } = useLoadsAPI();
  const initialValues = useInitialValues(loadDetailsEditSchema, load);
  const formik = useFormikEnhanced({
    initialValues,
    validationSchema: loadDetailsEditSchema,
    onSubmit(values) {
      return patchLoadDetails(load.guid, values, load);
    },
  });
  const nodeRef = useFormikComposerForm('details', formik);

  const disabled =
    load.is_created_by_broker &&
    (isDisabledLoadId(load) || isCarrierRestrictLoadIdEnabled);

  return (
    <FormikProvider value={formik}>
      <Card ref={nodeRef}>
        <CardContent>
          <Stack space="small">
            <Inline verticalAlign="center" space="xsmall">
              <LoadDetailsIcon fontSize="small" />
              <Typography variant="h3">Load Details</Typography>
            </Inline>

            <Stack space="large">
              <LoadDetailsForm
                isEnabledEditLoad={isEnabledEditLoad}
                disableInternalLoadIDHint={true}
                isDisableLoadId={disabled}
              />

              <FormikTextField
                label="Driver Instructions"
                name="instructions"
                fullWidth={true}
                multiline={true}
              />
            </Stack>
          </Stack>
        </CardContent>
      </Card>
    </FormikProvider>
  );
}
