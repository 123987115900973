import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import {
  addVehicle,
  findDestinationLoad,
  findVehicleAndLoad,
  removeLoadIfEmpty,
  removeVehicle,
  replaceLoad,
  vehicleCanBeDropped,
} from './data/SplitLoadsDnDUtils';
import { createSplitLoad, SplitDTO } from './data/SplitLoadsDTO';
import { SplitLoadsCard } from './SplitLoadsCard';
import { SplitLoadsCardDropable } from './SplitLoadsCardContainer';
import { SplitLoadsEmptyCard } from './SplitLoadsEmptyCard';

const emptyListId = 'empty-list';

export interface SplitLoadsDnDContainerProps {
  loads: SplitDTO[];
  onLoadsChanged: (loads: SplitDTO[]) => void;
  isLoading: boolean;
}

export function SplitLoadsList({
  loads,
  onLoadsChanged,
  isLoading,
}: SplitLoadsDnDContainerProps) {
  const onDragEnd = (dropResult: DropResult) => {
    const isDroppedToEmptylist =
      dropResult.destination?.droppableId === emptyListId;

    const [draggedVehicle, sourceLoad] = findVehicleAndLoad(loads, dropResult);

    if (
      !sourceLoad ||
      !draggedVehicle ||
      !dropResult.destination ||
      !vehicleCanBeDropped(sourceLoad, dropResult, isDroppedToEmptylist)
    ) {
      return;
    }

    if (isDroppedToEmptylist) {
      const updatedLoad = removeVehicle(sourceLoad, dropResult.source.index);
      const newLoads = replaceLoad(loads, updatedLoad);
      onLoadsChanged([...newLoads, createSplitLoad(draggedVehicle, newLoads)]);
    } else {
      const updatedSourceLoad = removeVehicle(
        sourceLoad,
        dropResult.source.index,
      );
      let newLoads = replaceLoad(loads, updatedSourceLoad);

      const desinationLoad = findDestinationLoad(
        newLoads,
        dropResult.destination,
      );

      if (desinationLoad) {
        newLoads = replaceLoad(
          newLoads,
          addVehicle(
            desinationLoad,
            draggedVehicle,
            dropResult.destination.index,
          ),
        );
      }

      newLoads = removeLoadIfEmpty(newLoads, updatedSourceLoad.uniqueId);
      onLoadsChanged(newLoads);
    }
  };

  const onSplitChanged = (split: SplitDTO) => {
    onLoadsChanged(replaceLoad(loads, split));
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      {loads.map((load) => (
        <SplitLoadsCardDropable
          key={load.uniqueId}
          label={load.number}
          uniqueId={load.uniqueId}
        >
          <SplitLoadsCard onSplitChanged={onSplitChanged} split={load} />
        </SplitLoadsCardDropable>
      ))}

      {!isLoading && (
        <SplitLoadsCardDropable
          isEmpty={true}
          label={emptyListId}
          uniqueId={emptyListId}
        >
          <SplitLoadsEmptyCard />
        </SplitLoadsCardDropable>
      )}
    </DragDropContext>
  );
}
