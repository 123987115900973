import { Route, Switch } from 'react-router-dom';
import { SplitLoadsPage } from './SplitLoadsPage';

export function SplitLoadsContainerPage() {
  return (
    <Switch>
      <Route path="/loads-split/:guid">
        <SplitLoadsPage />
      </Route>
    </Switch>
  );
}
