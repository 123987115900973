import { Color } from '@superdispatch/ui';
import { createSvgIcon } from 'shared/icons/IconUtils';

export const EncryptOutlinedIcon = createSvgIcon(
  'EncryptOutlinedIcon',
  <path
    d="M9.62496 13.7499H12.375L11.8479 10.7937C12.1534 10.6409 12.3941 10.4194 12.5698 10.1291C12.7454 9.83881 12.8333 9.51797 12.8333 9.16658C12.8333 8.66242 12.6538 8.23082 12.2948 7.87179C11.9357 7.51277 11.5041 7.33325 11 7.33325C10.4958 7.33325 10.0642 7.51277 9.70517 7.87179C9.34614 8.23082 9.16663 8.66242 9.16663 9.16658C9.16663 9.51797 9.25447 9.83881 9.43017 10.1291C9.60586 10.4194 9.84649 10.6409 10.152 10.7937L9.62496 13.7499ZM11 20.1666C8.87635 19.6319 7.12322 18.4135 5.74058 16.5114C4.35795 14.6093 3.66663 12.4971 3.66663 10.1749V4.58325L11 1.83325L18.3333 4.58325V10.1749C18.3333 12.4971 17.642 14.6093 16.2593 16.5114C14.8767 18.4135 13.1236 19.6319 11 20.1666Z"
    fill={Color.White}
  />,
  {
    viewBox: '0 0 22 22',
    style: { height: '22px', width: '22px', fill: 'none' },
  },
);
