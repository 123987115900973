import { useState } from 'react';
import { useWebSocketURL } from 'shared/api/APISocket';
import { useWebSocketHook } from 'shared/api/WebSocketHook';
import { CTMS_MESSAGING_WEBSOCKET } from 'shared/constants/ServerConstants';
import {
  getChatsSchema,
  getMessagesSchema,
  MessageDTO,
  messageSchema,
} from './MessagingDTO';

interface UseMessagingProps {
  loadGuid: string;
}

/**
 * useMessaging is a custom hook that wraps the useWebSocket hook from react-use-websocket
 * It is used to send and receive messages from the messaging websocket
 * @param loadGuid - The load guid to use for the messaging websocket
 */
export function useMessaging({ loadGuid }: UseMessagingProps) {
  const [chatGUID, setChatGUID] = useState<string>('');
  const [messages, setMessages] = useState<MessageDTO[]>([]);

  const tokenizedURL = useWebSocketURL(CTMS_MESSAGING_WEBSOCKET); // This is a custom hook that appends the token to the URL

  const { send, isReady } = useWebSocketHook({
    url: tokenizedURL,
    onOpen: () => {
      getChats();
    },
    onMessage: (msg) => {
      switch (msg.type) {
        case 'getChat': {
          const { guid } = getChatsSchema.cast(msg.data);
          setChatGUID(guid);
          getMessages(guid);
          break;
        }
        case 'getMessages': {
          const validatedMessages = getMessagesSchema.cast(msg.data);
          setMessages([...messages, ...validatedMessages]);
          break;
        }
        case 'newMessage':
          setMessages([...messages, messageSchema.cast(msg)]);
          break;
      }
    },
  });

  function sendMessage(message: string) {
    send({
      type: 'sendMessage',
      data: { content: message, chat_guid: chatGUID },
    });
  }

  function getChats() {
    send({ type: 'getChat', data: { load_guid: loadGuid } });
  }

  function getMessages(chat_guid: string) {
    send({
      type: 'getMessages',
      data: { chat_guid, page: '1', page_size: '30' },
    });
  }

  // Other specific chat methods can be added here, such as markAsRead, getLoadListUnread, etc.

  return {
    messages,
    sendMessage,
    getChats,
    getMessages,
    isReady,
  };
}
