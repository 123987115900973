import { FormControlLabel, Radio, Typography } from '@material-ui/core';
import {
  FormikNumberField,
  FormikRadioGroupField,
  FormikTextField,
  useFormikEnhanced,
} from '@superdispatch/forms';
import {
  Column,
  Columns,
  Inline,
  Stack,
  useSnackbarStack,
} from '@superdispatch/ui';
import { TextBox } from '@superdispatch/ui-lab';
import { useEffect } from 'react';
import { APIResponse } from 'shared/api/APIClient';
import { MobileAppBridge } from 'shared/data/MobileAppBridge';
import { useInitialValues } from 'shared/form/AppFormik';
import { FormikMediaField } from 'shared/form/FormikMediaField';
import { DocumentTitle } from 'shared/layout/DocumentTitle';
import { useCarrierInfo } from 'shared/modules/carrier-profile/CarrierInfo';
import {
  CarrierInfoDTO,
  carrierInfoSchema,
  CARRIER_LOGISTICS_LABELS,
} from 'shared/modules/carrier-profile/CarrierInfoDTO';
import { useVerifiedCarrierApplicationStatus } from 'shared/modules/carrier-profile/VerifiedCarrierApplicationStatus';
import { useCarrierSettings } from 'shared/settings/CarrierSettingsAPI';
import { useFlag } from 'shared/settings/FeatureToggles';
import { ProfileAddressForm } from './core/ProfileAddressForm';
import { ProfileCarrierInfoDetails } from './core/ProfileCarrierInfoDetails';
import { trackCarrierProfileEvent } from './data/CarrierProfileAnalytics';
import { useCarrierProfileAPI } from './data/CarrierProfileAPI';
import { ProfilePageLayout } from './ProfilePageLayout';

export function ProfileCarrierInfoPage() {
  const carrierInfo = useCarrierInfo();
  const { data: settings } = useCarrierSettings();
  const { data: verificationStatus } = useVerifiedCarrierApplicationStatus();
  const { updateCarrierInfo } = useCarrierProfileAPI();
  const { addSnackbar } = useSnackbarStack();
  const shouldShowWebviewHeader = useFlag('mobile_webview_carrier_profile');

  const initialValues = useInitialValues(carrierInfoSchema, carrierInfo.data);
  const isEditingDisabled =
    !settings?.drivers_can_edit_carrier_info && MobileAppBridge.isInjected();

  const formik = useFormikEnhanced<CarrierInfoDTO, APIResponse>({
    initialValues,
    validationSchema: carrierInfoSchema,
    onSubmit: updateCarrierInfo,
    onSubmitSuccess: () => {
      addSnackbar('Carrier info was successfully updated', {
        variant: 'success',
      });

      trackCarrierProfileEvent({
        name: 'Carrier Updated Carrier Info',
        utm_medium: 'Carrier Profile',
        utm_content: 'Carrier Info',
      });
    },
    onSubmitFailure: () =>
      addSnackbar('Failed to update carrier info', { variant: 'error' }),
  });

  useEffect(() => {
    if (carrierInfo.error) {
      addSnackbar('Failed to fetch carrier info', { variant: 'error' });
    }
  }, [addSnackbar, carrierInfo.error]);

  return (
    <>
      <DocumentTitle title="Carrier Info" />

      <ProfilePageLayout
        formik={formik}
        title="Carrier Info"
        isLoading={carrierInfo.isLoading}
        showHeaderOnWebview={shouldShowWebviewHeader}
      >
        <Stack space="large">
          <ProfileCarrierInfoDetails />
          <ProfileAddressForm
            label={
              <Inline space="xxsmall">
                <Typography variant="h4">Address</Typography>
                <Typography variant="h4" color="textSecondary">
                  (Required)
                </Typography>
              </Inline>
            }
            isFormDisabled={isEditingDisabled}
          />
          <Stack space="small">
            <Typography variant="h4">Contact</Typography>

            <FormikTextField
              label="Name"
              name="contact_name"
              fullWidth={true}
              disabled={isEditingDisabled}
            />

            <FormikTextField
              label="Phone Numbers"
              name="phone_numbers"
              fullWidth={true}
              disabled={isEditingDisabled}
            />

            <FormikTextField
              label="Email"
              name="email"
              fullWidth={true}
              disabled={isEditingDisabled}
            />
          </Stack>
          <Stack space="small">
            <Typography variant="h4">About</Typography>

            <FormikMediaField
              label="Logo"
              name="logo_url"
              accept=".jpeg, .jpg, .webp, .heic, .heif, .png"
              fullWidth={true}
              disabled={isEditingDisabled}
            />

            <FormikTextField
              label="About"
              name="about"
              fullWidth={true}
              multiline={true}
              disabled={isEditingDisabled}
            />

            <FormikTextField
              label="Website"
              name="website"
              fullWidth={true}
              disabled={isEditingDisabled}
            />

            <FormikTextField
              label="MC Number"
              name="mc_number"
              fullWidth={true}
              disabled={isEditingDisabled}
            />

            <FormikTextField
              label={
                <>
                  In Business Since Year{' '}
                  <TextBox color="secondary">(Required)</TextBox>
                </>
              }
              name="in_business_since"
              type="number"
              fullWidth={true}
              disabled={isEditingDisabled}
            />
          </Stack>
          <Stack space="small">
            <Typography variant="h4">Equipment</Typography>

            <Columns>
              <Column width={['fluid', '3/5']}>
                <FormikNumberField
                  fullWidth={true}
                  label="Largest trailer capacity"
                  helperText="Max. number of sedans your largest trailer can fit. (e.g. Honda Accord)"
                  name="largest_trailer_capacity"
                  inputProps={{
                    decimalScale: 0,
                    maxLength: 2,
                  }}
                  disabled={isEditingDisabled}
                />
              </Column>
            </Columns>

            <FormikRadioGroupField
              name="logistics_type"
              label="Carrier Logistics"
              disabled={
                !!carrierInfo.data?.logistics_type &&
                verificationStatus?.verified_carrier_application.status !==
                  'not_submitted'
              }
              helperText={
                <Typography variant="body2">
                  If you are an Auto Haulers, you must have motor vehicles
                  registered as cargo carried with FMCSA. <br />
                  <br />
                  If you are Towing or Driveaway, you must have Drive/Towaway.
                </Typography>
              }
            >
              {Array.from(CARRIER_LOGISTICS_LABELS, ([key, label]) => (
                <FormControlLabel
                  key={key}
                  value={key}
                  control={<Radio />}
                  label={label}
                />
              ))}
            </FormikRadioGroupField>
          </Stack>
        </Stack>
      </ProfilePageLayout>
    </>
  );
}
