import { useMemo } from 'react';
import {
  MutationFunction,
  MutationOptions,
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from 'react-query';
import { isAPIErrorResponse, request, requestJSON } from 'shared/api/APIClient';
import {
  APIQueryDataUpdater,
  APIQueryInput,
  setAPIQueryData,
  UseAPIQueryOptions,
} from 'shared/api/APIQuery';
import { PaymentPastDueContextDTO } from 'shared/errors/data/PaywallDTO';
import { emitServerError } from 'shared/errors/ServerErrorEvents';
import { Schema } from 'yup';
import { TrackingDriverDTO } from './TrackingDTO';

export function useTrackingCache() {
  const queryClient = useQueryClient();

  return useMemo(() => {
    function invalidateTrackingDrivers() {
      void queryClient.invalidateQueries('tracking');
    }

    function updateDriver(
      id: number | null,
      updater: APIQueryDataUpdater<TrackingDriverDTO>,
    ) {
      setAPIQueryData<TrackingDriverDTO[]>(
        queryClient,
        ['tracking', 'list'],
        (drivers) => {
          return drivers.map((driver) =>
            driver.id !== id ? driver : updater(driver),
          );
        },
      );
    }

    return { invalidateTrackingDrivers, updateDriver };
  }, [queryClient]);
}

export function useTrackingDriverPage(
  options: DashboardAPIQueryOptions<TrackingDriverDTO[]>,
): DashboardAPIQueryResult<TrackingDriverDTO[]> {
  return useDashboardAPIQuery(
    ['tracking', 'list'],
    () => requestJSON('GET /dashboard/api/tracking/drivers/'),
    options,
  );
}

export function useNonTrackingDriverPage(): DashboardAPIQueryResult<
  TrackingDriverDTO[]
> {
  return useDashboardAPIQuery(['tracking', 'non-tracking-list'], () =>
    requestJSON('GET /dashboard/api/tracking/drivers/nontracking/'),
  );
}

export function useAddNonTrackingDrivers({
  onSuccess,
  ...options
}: DashboardAPIMutationOptions<number[]>): DashboardAPIMutationResult<
  number[]
> {
  const { invalidateTrackingDrivers } = useTrackingCache();
  return useMutation(
    (ids: number[]) =>
      request('POST /dashboard/api/tracking/drivers/add/', {
        json: { drivers: ids },
      }),
    {
      ...options,
      onSuccess(...args) {
        invalidateTrackingDrivers();
        return onSuccess?.(...args);
      },
    },
  );
}

export function useDeleteTrackingDriver({
  onSuccess,
  ...options
}: DashboardAPIMutationOptions<number>): DashboardAPIMutationResult<number> {
  const { invalidateTrackingDrivers } = useTrackingCache();
  return useMutation(
    (id: number) =>
      request(['DELETE /dashboard/api/tracking/drivers/{id}/', { id }]).catch(
        () => {
          throw new Error('Driver not found');
        },
      ),
    {
      ...options,
      onSuccess(...args) {
        invalidateTrackingDrivers();
        return onSuccess?.(...args);
      },
    },
  );
}

export function useNotifyTrackingDriver(
  notificationType: string | null,
  options: DashboardAPIMutationOptions<number>,
): DashboardAPIMutationResult<number> {
  return useDashboardAPIMutation(
    (id) =>
      requestJSON<{ id: number }>(
        ['POST /dashboard/api/tracking/drivers/{id}/notify/', { id }],
        {
          json: { notification_type: notificationType },
        },
      ).then((response) => {
        if (!response.id) throw new Error(`Driver ${id} not found`);
        return response;
      }),
    options,
  );
}

export type DashboardAPIQueryResult<TData> = UseQueryResult<TData, Error>;
export type DashboardAPIQueryOptions<TData> = UseAPIQueryOptions<TData>;

export interface DashboardAPIResponseError {
  error: {
    context: PaymentPastDueContextDTO;
    dev_message: string;
    type: string;
    user_message: string;
  };
  meta: {
    code: number;
    request_id: string;
  };
}
export interface DashboardAPIResponse<TData>
  extends Partial<DashboardAPIResponseError> {
  results: TData;
}

//Parsing Tracking Drivers function which parses unexpected response type into array, however tracking drivers errors comes into status code 200 and handling its trial expired status here as well
function parseTrackingDriversList<TData>(
  schema: Schema<TData> | undefined,
  response: DashboardAPIResponse<TData>,
) {
  if (isAPIErrorResponse(response)) {
    emitServerError(response.error);
  }
  if (schema && Array.isArray(response.results)) {
    return response.results.map((item) => schema.cast(item));
  }
  return response.results;
}

export function useDashboardAPIQuery<TData extends unknown[]>(
  input: APIQueryInput,
  query: () => Promise<DashboardAPIResponse<TData>>,
  { schema, normalize, ...options }: DashboardAPIQueryOptions<TData> = {},
): DashboardAPIQueryResult<TData> {
  return useQuery(
    input,
    () =>
      query().then((response) => parseTrackingDriversList(schema, response)),
    options,
  );
}

export type DashboardAPIMutationResult<
  TInput = void,
  TData = unknown,
  TContext = unknown,
> = UseMutationResult<TData, Error, TInput, TContext>;

export type DashboardAPIMutationOptions<
  TInput = void,
  TData = unknown,
  TContext = unknown,
> = MutationOptions<TData, Error, TInput, TContext>;

export function useDashboardAPIMutation<
  TInput,
  TData = unknown,
  TContext = unknown,
>(
  mutation: MutationFunction<TData, TInput>,
  options?: DashboardAPIMutationOptions<TInput, TData, TContext>,
): DashboardAPIMutationResult<TInput, TData, TContext> {
  return useMutation(mutation, options);
}
