import { Color, Stack } from '@superdispatch/ui';
import { Button, TextBox } from '@superdispatch/ui-lab';
import { WarningDashedIcon } from 'shared/icons/WarningDashedIcon';
import styled from 'styled-components';

const OfferErrorRoot = styled.div`
  margin: auto;
`;

const OfferErrorIcon = styled(WarningDashedIcon)`
  font-size: 52px;
  color: ${Color.Silver500};
`;

export interface OfferErrorProps {
  title: string;
  description?: string;
  isLoading?: boolean;
  onRetry?: () => void;
}

export function OfferError({
  title,
  onRetry,
  isLoading,
  description,
}: OfferErrorProps) {
  return (
    <OfferErrorRoot>
      <Stack align="center" space="small">
        <Stack align="center">
          <OfferErrorIcon />

          <TextBox variant="heading-4">{title}</TextBox>

          {!!description && (
            <TextBox variant="body" color="secondary">
              {description}
            </TextBox>
          )}
        </Stack>

        {!!onRetry && (
          <Button onClick={onRetry} variant="text" disabled={isLoading}>
            Retry Again
          </Button>
        )}
      </Stack>
    </OfferErrorRoot>
  );
}
