import { IconButton, IconButtonProps } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { forwardRef } from 'react';
import styled from 'styled-components';

const ClearButton = styled(IconButton)`
  visibility: hidden;

  .MuiInputBase-root.Mui-focused & {
    visibility: visible;
  }

  @media (pointer: fine) {
    .MuiInputBase-root:hover & {
      visibility: visible;
    }
  }
`;

export const InputClearButton = forwardRef<
  HTMLButtonElement,
  Omit<IconButtonProps, 'children'>
>(({ size = 'small', ...props }: Omit<IconButtonProps, 'children'>, ref) => (
  <ClearButton {...props} ref={ref} size={size}>
    <Close fontSize="small" />
  </ClearButton>
));

InputClearButton.displayName = 'InputClearButton';
