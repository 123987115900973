import { Dialog } from '@material-ui/core';
import { useSnackbarStack } from '@superdispatch/ui';
import {
  Source,
  SuperPayRequestShipperContent,
} from 'shared/modules/superpay/SuperPayRequestShipperContent';

interface SuperPayRequestShipperPopupProps {
  isOpen: boolean;
  onClose: () => void;
  source: Source;
  isSuperPay?: boolean;
}

export function SuperPayRequestShipperDialog({
  isOpen,
  onClose,
  source,
  isSuperPay,
}: SuperPayRequestShipperPopupProps) {
  const { addSnackbar } = useSnackbarStack();
  return (
    <Dialog open={isOpen} onClose={onClose}>
      <SuperPayRequestShipperContent
        onClose={onClose}
        source={source}
        isSuperPay={isSuperPay}
        onSubmitSuccess={(message) => {
          addSnackbar(message, { variant: 'success' });
        }}
      />
    </Dialog>
  );
}
