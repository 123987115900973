import { Fade } from '@material-ui/core';
import { Color } from '@superdispatch/ui';
import styled from 'styled-components';

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  background-color: ${Color.White50};
`;

interface Props {
  in?: boolean;
}

export function LoadingOverlay(props: Props) {
  return (
    <Fade in={props.in}>
      <Overlay />
    </Fade>
  );
}
