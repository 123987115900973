import { useShowSubscriptionCancelledDialog } from 'shared/errors/data/useShowSubscriptionCancelledDialog';
import { openExternalURL } from 'shared/helpers/URLHelpers';
import {
  EXPERIMENT_TYPES,
  fetchExperiments,
} from 'shared/modules/experiments/ExperimentsAPI';
import { useSubscriptionSettingsMutation } from 'shared/modules/subscription/SubscriptionAPI';
import { getBackURL } from 'shared/ui/BackButton';

export function useSubscriptionFetchExpirement() {
  const subscription = useSubscriptionSettingsMutation();
  const { setOpenSubscriptionCancelledDialog } =
    useShowSubscriptionCancelledDialog();

  const handleFetchExperiment = () => {
    void subscription.mutateAsync(undefined).then((subscriptionData) => {
      const isCancelledSubscription =
        subscriptionData.data.status === 'Canceled';
      const isTrialExpiredSubscription = subscriptionData.data.status === null;

      if (isCancelledSubscription) {
        setOpenSubscriptionCancelledDialog('cancelled');
        openExternalURL(getBackURL(window.location.search, '/loadboard'));
      } else if (isTrialExpiredSubscription) {
        setOpenSubscriptionCancelledDialog('expired');
        openExternalURL(getBackURL(window.location.search, '/loadboard'));
      } else {
        void fetchExperiments().then((data) => {
          const redirectToSLBExperiment = data.data.groups.find(
            (e) => e.number === EXPERIMENT_TYPES['Redirect To SLB'],
          );

          if (redirectToSLBExperiment?.seen) {
            openExternalURL(getBackURL(window.location.search, '/loadboard'));
          } else {
            openExternalURL(getBackURL(window.location.search, '/tms/loads'));
          }
        });
      }
    });
  };
  return { handleFetchExperiment, isLoading: subscription.isLoading };
}
