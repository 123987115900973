import { Column, Columns } from '@superdispatch/ui';
import { Box, Button } from '@superdispatch/ui-lab';
import { useState } from 'react';
import { useErrorSnackbar } from 'shared/hooks/useErrorSnackbar';
import { LinkButton } from 'shared/routing/Links';
import { emitLoadEvent } from '../../loads/data/LoadEvents';
import { trackOffersEvent } from './data/OffersAnalytics';
import { useAcceptOffer } from './data/OffersAPI';
import { OfferDetailsDTO } from './data/OffersDTO';
import { OffersDeclineDialog } from './OffersDeclineDialog';

export interface AcceptedOfferPageFooterProps {
  loadGuid: string;
}

export function AcceptedOfferPageFooter({
  loadGuid,
}: AcceptedOfferPageFooterProps) {
  return (
    <Box
      marginLeft="auto"
      marginRight="auto"
      paddingTop="medium"
      paddingBottom="medium"
    >
      <LinkButton
        size="large"
        variant="neutral"
        to={`/loads/${loadGuid}?utm_medium=load_offer&utm_source=Web+CTMS`}
        data-intercom-target="offer-open-load-button"
      >
        Open Load
      </LinkButton>
    </Box>
  );
}

export interface PendingOfferPageFooterProps {
  offer: OfferDetailsDTO;
}

export function PendingOfferPageFooter({ offer }: PendingOfferPageFooterProps) {
  const [dialog, setDialog] = useState<'decline'>();

  const acceptOffer = useAcceptOffer({
    onSuccess: () => {
      trackOffersEvent({ name: 'Accepted Load Offer', offer });
      emitLoadEvent('accepted');
    },
  });

  useErrorSnackbar(acceptOffer.error, { onClose: acceptOffer.reset });

  return (
    <>
      <OffersDeclineDialog
        offer={offer}
        open={dialog === 'decline'}
        onSuccess={() => {
          setDialog(undefined);
        }}
        onClose={() => {
          setDialog(undefined);
        }}
      />

      <Box
        marginLeft="auto"
        marginRight="auto"
        paddingTop="medium"
        paddingBottom="medium"
        minWidth={`${/* two buttons + gap */ 176 * 2 + 16}px`}
      >
        <Columns space="small">
          <Column width="1/2">
            <Button
              size="large"
              fullWidth={true}
              variant="critical"
              disabled={acceptOffer.isLoading}
              onClick={() => {
                setDialog('decline');
              }}
            >
              Decline
            </Button>
          </Column>

          <Column width="1/2">
            <Button
              size="large"
              variant="primary"
              fullWidth={true}
              pending={acceptOffer.isLoading}
              onClick={() => {
                acceptOffer.mutate(offer.guid);
              }}
            >
              Accept
            </Button>
          </Column>
        </Columns>
      </Box>
    </>
  );
}
