import { Typography } from '@material-ui/core';
import { FormikTextField, useFormikEnhanced } from '@superdispatch/forms';
import { Stack, useSnackbarStack } from '@superdispatch/ui';
import { Box, Button } from '@superdispatch/ui-lab';
import { Form, FormikProvider } from 'formik';
import { useNavigate } from 'shared/routing/react-router-6';
import { deleteDriverAccount } from '../data/DriverAccountDeletionAPI';
import { useDriverAccountDeletionContext } from '../DriverAccountDeletionPage';

export function ConfirmationStepPage() {
  const navigate = useNavigate();
  const {
    token,
    authToken,
    driverAccount: { email } = {},
  } = useDriverAccountDeletionContext();
  const { addSnackbar } = useSnackbarStack();
  const formik = useFormikEnhanced({
    initialValues: {
      email: '',
    },
    validate: (values) => {
      if (values.email !== email || !values.email) {
        return { email: 'Please type your account email' };
      }
      return {};
    },
    onSubmit: (values) => {
      if (!authToken) {
        return Promise.reject(new Error('Token is not available'));
      }

      return deleteDriverAccount({
        email: values.email,
        reason: "I don't use the App anymore",
        token: authToken,
      });
    },
    onSubmitSuccess: () => {
      navigate(`/driver-account-deletion/${token}/completion/`, {
        replace: true,
      });
    },
    onSubmitFailure: (error) => {
      addSnackbar(error.message || 'Failed delete your account', {
        variant: 'error',
      });
    },
  });
  return (
    <Box padding="medium">
      <FormikProvider value={formik}>
        <Form>
          <Stack space="medium">
            <Typography variant="h2">
              Are you sure you want to delete your account?
            </Typography>
            <Typography>
              Read the important notes below, before deleting your Super
              Dispatch account.
            </Typography>

            <Stack>
              <Typography variant="body1">Important:</Typography>
              <ul>
                <Stack space="medium">
                  <li>
                    When you delete your account, all related data will be
                    deleted, including created loads any data associated with
                    those loads including photos, signatures, and inspections.
                  </li>
                  <li>
                    We may securely keep some information or event that we need
                    to establish, exercise or defend a legal claim. This
                    information will be retained securely and will be deleted
                    permanently obfuscated once it is no longer required.
                  </li>
                </Stack>
              </ul>
              <Typography>
                To continue, please type the email for the account below.
              </Typography>
            </Stack>

            <FormikTextField
              name="email"
              type="email"
              label="Type email to confirm"
              placeholder={email}
              fullWidth={true}
            />

            <Button
              fullWidth={true}
              disabled={!formik.dirty}
              pending={formik.isSubmitting}
              variant="critical"
              type="submit"
            >
              Yes, I want to delete my account
            </Button>
            <Button
              fullWidth={true}
              variant="neutral"
              disabled={formik.isSubmitting}
              onClick={() => {
                navigate(`/driver-account-deletion/${token}`, {
                  replace: true,
                });
              }}
            >
              Cancel
            </Button>
          </Stack>
        </Form>
      </FormikProvider>
    </Box>
  );
}
