import { isEmpty, pick } from 'lodash-es';
import { createContext, ReactNode, useEffect, useMemo } from 'react';
import { isEmptyRecord } from 'shared/helpers/CommonHelpers';
import { useNullableContext } from 'shared/hooks/useNullableContext';
import { UpdateParams, useLocationParams } from 'shared/routing/LocationParams';
import { useFlag } from 'shared/settings/FeatureToggles';
import { trackLoadsEvent } from '../../data/LoadsAnalytics';
import {
  LoadsPageParams,
  loadsPageParamsSchema,
  LOAD_FILTER_FIELDS,
  LOAD_SEARCH_FIELDS,
} from '../../data/LoadsPageParams';

export interface LoadsPageParamsContext {
  isEmptyFilterParams: boolean;
  isEmptySearchParams: boolean;

  params: LoadsPageParams;
  updateParams: UpdateParams<LoadsPageParams>;
}

const Context = createContext<null | LoadsPageParamsContext>(null);

export function useLoadsPageContext() {
  return useNullableContext('LoadsPageContext', Context);
}

interface LoadsPageContextProviderProps {
  children?: ReactNode;
}

export function LoadsPageContextProvider({
  children,
}: LoadsPageContextProviderProps) {
  const shouldPersistParams = useFlag('react_loads_page_filter_persist');
  const [params, updateParams] = useLocationParams({
    yupSchema: loadsPageParamsSchema,
    persistName: shouldPersistParams ? 'LoadsPage' : undefined,
  });

  const filterParams = useMemo(
    () => pick(params, LOAD_FILTER_FIELDS),
    [params],
  );

  useEffect(() => {
    if (!isEmptyRecord(filterParams)) {
      trackLoadsEvent({ name: 'CTMS: Filtered Loads', params: filterParams });
    }
  }, [filterParams]);

  const ctx = useMemo<LoadsPageParamsContext>(() => {
    const isEmptyFilterParams = !LOAD_FILTER_FIELDS.find(
      (key) => !isEmpty(params[key]),
    );
    const isEmptySearchParams = !LOAD_SEARCH_FIELDS.find(
      (key) => !isEmpty(params[key]),
    );

    return {
      params,
      updateParams,
      isEmptyFilterParams,
      isEmptySearchParams,
    };
  }, [params, updateParams]);

  return <Context.Provider value={ctx}>{children}</Context.Provider>;
}
