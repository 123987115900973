import { TextField, Tooltip, Typography } from '@material-ui/core';
import { Info } from '@material-ui/icons';
import { formatDate, NullableDateString } from '@superdispatch/dates';
import { FormikDateField, FormikTextField } from '@superdispatch/forms';
import { Column, Columns, Inline, Stack } from '@superdispatch/ui';
import { useField } from 'formik';
import { loadVenueSchema } from '../data/LoadDTO';
import { FormikVenueAddressAutofill } from './FormikVenueAddressAutofill';
import { FormikVenueAutofill } from './FormikVenueAutofill';

export interface LoadDeliveryFormProps {
  name?: string;
  disabled?: boolean;
}

export function LoadDeliveryForm({ name, disabled }: LoadDeliveryFormProps) {
  const prefix = !name ? '' : `${name}.`;
  const [{ value: actualDate }] = useField<NullableDateString>(
    `${prefix}actual_date`,
  );

  return (
    <Stack space="large">
      <FormikVenueAutofill
        fullWidth={true}
        label="Business Name"
        name={`${prefix}venue`}
        schema={loadVenueSchema}
        disabled={disabled}
      />

      <Stack space="small">
        <FormikVenueAddressAutofill
          fullWidth={true}
          label="Street Address"
          name={`${prefix}venue`}
          disabled={disabled}
        />

        <Columns space="small" collapseBelow="desktop">
          <Column width="1/2">
            <FormikTextField
              fullWidth={true}
              label="City"
              name={`${prefix}venue.city`}
              disabled={disabled}
            />
          </Column>

          <Column width="1/4">
            <FormikTextField
              fullWidth={true}
              label="State"
              name={`${prefix}venue.state`}
              disabled={disabled}
            />
          </Column>

          <Column width="1/4">
            <FormikTextField
              fullWidth={true}
              label="ZIP"
              name={`${prefix}venue.zip`}
              disabled={disabled}
            />
          </Column>
        </Columns>
      </Stack>

      <Columns space="small" collapseBelow="desktop">
        <Column width="1/2">
          <FormikDateField
            fullWidth={true}
            label="Scheduled Delivery Date"
            name={`${prefix}scheduled_date`}
            disabled={disabled}
          />
        </Column>

        {actualDate && (
          <Column width="1/2">
            <TextField
              fullWidth={true}
              InputProps={{ readOnly: true }}
              value={formatDate(actualDate, { variant: 'DateTime' })}
              disabled={disabled}
              label={
                <Inline verticalAlign="center">
                  <Typography>Delivered on</Typography>
                  <Tooltip
                    enterTouchDelay={0}
                    placement="top"
                    title={`The date when the load was 
                            delivered by the driver or marked
                            as delivered by a dispatcher. Cannot
                            be changed.`}
                  >
                    <Info color="action" fontSize="small" />
                  </Tooltip>
                </Inline>
              }
            />
          </Column>
        )}
      </Columns>

      <FormikTextField
        label="Notes"
        name={`${prefix}notes`}
        fullWidth={true}
        multiline={true}
        disabled={disabled}
      />

      <Stack space="small">
        <Typography variant="h4">Delivery Contact Details</Typography>

        <FormikTextField
          fullWidth={true}
          label="Full Name"
          name={`${prefix}venue.contacts[0].name`}
          disabled={disabled}
        />

        <Columns space="small" collapseBelow="desktop">
          <Column width="1/2">
            <FormikTextField
              fullWidth={true}
              label="Phone"
              name={`${prefix}venue.contacts[0].phone`}
              disabled={disabled}
            />
          </Column>
          <Column width="1/2">
            <FormikTextField
              fullWidth={true}
              label="Mobile"
              name={`${prefix}venue.contacts[0].phone2`}
              disabled={disabled}
            />
          </Column>
        </Columns>
        <FormikTextField
          fullWidth={true}
          label="Email"
          name={`${prefix}venue.contacts[0].email`}
          disabled={disabled}
        />
      </Stack>
    </Stack>
  );
}
