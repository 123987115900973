import {
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from '@material-ui/core';
import { Inline, useSnackbarStack } from '@superdispatch/ui';
import { Box, Button, TextBox } from '@superdispatch/ui-lab';
import { useMemo, useState } from 'react';
import {
  PlanUpdateDrawer,
  useNextPlanEstimate,
  useShowPlanUpdateDrawer,
} from 'shared/modules/subscription/core/PlanUpdateDrawer';
import { useSubscriptionSettings } from 'shared/modules/subscription/SubscriptionAPI';
import { useCarrierSettings } from 'shared/settings/CarrierSettingsAPI';
import { DriverDTO } from '../data/DriverDTO';
import { trackDriversEvent } from '../data/DriversAnalytics';
import {
  useAcceptDriverRequest,
  useDeclineDriverRequest,
} from '../data/DriversAPI';
import { BillingUpgradeDialog } from './BillingUpgradeDialog';

interface DriverJoinRequestBannerProps {
  currentDriver: DriverDTO;
}

export function DriverJoinRequestBanner({
  currentDriver,
}: DriverJoinRequestBannerProps) {
  const { addSnackbar } = useSnackbarStack();
  const [isOpen, setOpen] = useState<boolean>(false);
  const [modalState, setModalState] = useState<
    'plan_upgrade_drawer' | 'plan_upgrade_confirmation'
  >();
  const { shouldShowPlanUpdateDrawer } = useShowPlanUpdateDrawer();
  const { refetch: refetchSubscriptionSettings, data: subscription } =
    useSubscriptionSettings();

  const { mutate: acceptJoinRequest, isLoading: isAcceptLoading } =
    useAcceptDriverRequest({
      onSuccess: () => {
        addSnackbar('New driver added', { variant: 'success' });
        trackDriversEvent({ name: 'Carrier Accepted Join Request' });
        handleClose();
        setModalState(undefined);
        void refetchSubscriptionSettings();
      },
    });
  const { mutate: declineJoinRequest, isLoading: isDeclineLoading } =
    useDeclineDriverRequest({
      onSuccess: () => {
        trackDriversEvent({ name: 'Carrier Declined Join Request' });
        handleClose();
      },
    });

  const { data: settings } = useCarrierSettings();

  const nextPlan = useNextPlanEstimate();

  const canInviteDriver = useMemo(() => {
    if (subscription?.plan) {
      const { plan } = subscription;
      const seatsCount = Number(plan.seats_count);
      const driversCount = Number(plan.drivers_count);
      return (
        seatsCount > driversCount && driversCount === 0 && seatsCount === 1
      );
    }
    return false;
  }, [subscription]);

  function handleClose() {
    setOpen(false);
  }

  const isPayingCustomer = settings?.is_paying;

  return (
    <>
      <Dialog open={isOpen} onClose={handleClose}>
        <DialogContent>
          <Typography variant="h3">
            Do you want to Decline Request from {currentDriver.name}?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Inline space="small">
            <Button
              disabled={isDeclineLoading}
              variant="neutral"
              onClick={() => {
                setOpen(false);
              }}
            >
              No
            </Button>
            <Button
              pending={isDeclineLoading}
              variant="critical"
              onClick={() => {
                declineJoinRequest(currentDriver.guid);
              }}
            >
              Yes, Decline
            </Button>
          </Inline>
        </DialogActions>
      </Dialog>
      <Box
        width="100%"
        borderTopWidth="small"
        borderTopColor="Silver400"
        paddingLeft="large"
        paddingRight="large"
        paddingTop="small"
        paddingBottom="small"
      >
        <Inline space="medium" verticalAlign="center" horizontalAlign="center">
          <TextBox color="primary" variant="body">
            <b>{currentDriver.name}</b> is requesting to join your company
          </TextBox>
          <Inline space="small" verticalAlign="center" horizontalAlign="center">
            <Button
              disabled={isAcceptLoading}
              variant="critical"
              onClick={() => {
                setOpen(true);
                trackDriversEvent({ name: 'Carrier Clicked Decline Button' });
              }}
            >
              Decline
            </Button>
            <Button
              pending={isAcceptLoading || nextPlan.isFetching}
              disabled={isDeclineLoading}
              onClick={() => {
                trackDriversEvent({ name: 'Carrier Clicked Accept Button' });

                if (shouldShowPlanUpdateDrawer) {
                  void nextPlan.refetch().then(({ data }) => {
                    if (data && !canInviteDriver) {
                      setModalState('plan_upgrade_drawer');
                    } else {
                      acceptJoinRequest([currentDriver.guid]);
                    }
                  });

                  return;
                }

                if (isPayingCustomer) {
                  setModalState('plan_upgrade_confirmation');
                  return;
                }

                acceptJoinRequest([currentDriver.guid]);
              }}
            >
              Accept
            </Button>
          </Inline>
        </Inline>
      </Box>
      <BillingUpgradeDialog
        isOpen={modalState === 'plan_upgrade_confirmation'}
        isLoading={isAcceptLoading}
        onClose={() => {
          setModalState(undefined);
        }}
        onClick={() => {
          acceptJoinRequest([currentDriver.guid]);
        }}
      />
      <PlanUpdateDrawer
        open={modalState === 'plan_upgrade_drawer'}
        onClose={() => {
          setModalState(undefined);
        }}
        onSubmitSuccess={() => {
          acceptJoinRequest([currentDriver.guid]);
        }}
      />
    </>
  );
}
