import { createSvgIcon } from 'shared/icons/IconUtils';

export const AvailableDateIcon = createSvgIcon(
  'AvailableDate',
  <>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M20.333 20.125V6.375A1.867 1.867 0 0018.476 4.5H16.62V2.469A.468.468 0 0016.155 2h-1.548a.468.468 0 00-.464.469V4.5H9.19V2.469A.468.468 0 008.726 2H7.18a.468.468 0 00-.465.469V4.5H4.857C3.832 4.5 3 5.34 3 6.375v13.75C3 21.16 3.832 22 4.857 22h13.62c1.024 0 1.856-.84 1.856-1.875zM18.476 8.25v11.64a.234.234 0 01-.232.235H5.089a.234.234 0 01-.232-.234V8.25h13.62z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M15.745 16.93h-1.53l.01-3.806-5.489 5.488-1.072-1.073 5.488-5.488-3.807.01v-1.53l6.4.005v6.394z"
      clipRule="evenodd"
    />
  </>,
);
