import { Stack } from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import { useEffect, useState } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { isCarrierTMSWebView } from 'shared/constants/AppTypeConstants';
import { MobileAppBridge } from 'shared/data/MobileAppBridge';
import { trackEvent } from 'shared/helpers/Analytics';
import { useQuery } from 'shared/helpers/RoutingHelpers';
import { openExternalURL } from 'shared/helpers/URLHelpers';
import { DocumentTitle } from 'shared/layout/DocumentTitle';
import { ProfileVerifiedCarrierApplicationTabs } from 'shared/modules/carrier-profile/core/ProfileVerifiedCarrierApplicationTabs';
import { useVerifiedCarrierApplicationStatus } from 'shared/modules/carrier-profile/VerifiedCarrierApplicationStatus';
import { useSignupExperiment } from 'shared/modules/experiments/signup-experiment/useSignupExperiment';
import { Navigate, useNavigate } from 'shared/routing/react-router-6';
import { useFlag } from 'shared/settings/FeatureToggles';
import { PendingVerificationDialog } from '../core/PendingVerificationDialog';
import { ProfileVerifiedCarrierApplicationLayout } from './ProfileVerifiedCarrierApplicationLayout';
import { StepCargoInsurancePage } from './StepCargoInsurancePage';
import { StepCarrierInfoPage } from './StepCarrierInfoPage';
import { StepIdentificationPage } from './StepIdentificationPage';
import { StepPhoneVerificationPage } from './StepPhoneVerificationPage';

export function ProfileVerifiedCarrierApplicationPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useQuery();
  const [isPendingDialogOpen, setIsPendingDialogOpen] = useState(false);
  const { shouldRunExperiment } = useSignupExperiment();

  const isRedirectionEnabled = useFlag('verification_application_redirect');

  const { data } = useVerifiedCarrierApplicationStatus();
  const progress = data?.verified_carrier_application.progress;

  useEffect(() => {
    if (data?.verified_carrier_application.status === 'pending') {
      setIsPendingDialogOpen(true);
    }
  }, [data]);

  useEffect(() => {
    const status = data?.verified_carrier_application.status;
    if (
      isRedirectionEnabled &&
      status &&
      status !== 'pending' &&
      status !== 'not_submitted'
    ) {
      navigate('/profile');
    }
  }, [isRedirectionEnabled, data, navigate]);

  useEffect(() => {
    trackEvent('CTMS: Started Identity Verification Process', {
      utm_medium: searchParams.utm_medium || 'carrier_profile',
    });
  }, [searchParams.utm_medium]);

  return (
    <>
      <DocumentTitle title="Verify Carrier Application" />

      <ProfileVerifiedCarrierApplicationLayout>
        <Stack space={['none', 'large']}>
          <ProfileVerifiedCarrierApplicationTabs />
          <Box maxWidth="992px" marginLeft="auto" marginRight="auto">
            <Switch>
              <Route path="/profile/verified-carrier-application/step-1">
                <Box maxWidth="496px" marginLeft="auto" marginRight="auto">
                  <StepPhoneVerificationPage
                    onBack={() => {
                      if (isCarrierTMSWebView) {
                        MobileAppBridge.closeScreen();
                      } else {
                        navigate('/profile');
                      }
                    }}
                    isPhoneVerificationComplete={
                      !!progress?.fmcsa_phone_verification_is_complete
                    }
                    onComplete={() => {
                      navigate({
                        pathname:
                          '/profile/verified-carrier-application/step-2',
                        search: location.search,
                      });
                    }}
                  />
                </Box>
              </Route>
              <Route path="/profile/verified-carrier-application/step-2">
                <Box maxWidth="496px" marginLeft="auto" marginRight="auto">
                  <StepCarrierInfoPage
                    onComplete={() => {
                      navigate({
                        pathname:
                          '/profile/verified-carrier-application/step-3',
                        search: location.search,
                      });
                    }}
                  />
                </Box>
              </Route>

              <Route path="/profile/verified-carrier-application/step-3">
                <StepCargoInsurancePage
                  onComplete={() => {
                    navigate({
                      pathname: '/profile/verified-carrier-application/step-4',
                      search: location.search,
                    });
                  }}
                />
              </Route>

              <Route path="/profile/verified-carrier-application/step-4">
                <Box maxWidth="496px" marginLeft="auto" marginRight="auto">
                  <StepIdentificationPage
                    onComplete={() => {
                      if (shouldRunExperiment && !isCarrierTMSWebView) {
                        // in experiment we want user to hit paywall and see verification processing screen
                        openExternalURL('/tms/loads/', { target: '_self' });
                      } else {
                        setIsPendingDialogOpen(true);
                      }
                    }}
                  />
                </Box>
              </Route>

              <Route path="*">
                <Navigate to="/profile/verified-carrier-application/step-1" />
              </Route>
            </Switch>
          </Box>
        </Stack>
      </ProfileVerifiedCarrierApplicationLayout>

      <PendingVerificationDialog
        open={isPendingDialogOpen && !shouldRunExperiment}
      />
    </>
  );
}
