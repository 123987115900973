import { DialogActions } from '@material-ui/core';
import { useSnackbarStack } from '@superdispatch/ui';
import { Button } from '@superdispatch/ui-lab';
import { useMemo } from 'react';
import {
  MutationDialog,
  MutationDialogContent,
} from 'shared/form/MutationDialog';
import { formatCurrency } from 'shared/helpers/IntlHelpers';
import { computePrice } from 'shared/utils/NumberUtils';
import { joinStrings } from 'shared/utils/StringUtils';
import { useLoadDetails, useLoadsCache } from '../data/LoadsAPI';
import { useDeleteVehicle } from '../data/VehiclesAPI';

interface LoadVehicleDeleteDialogProps {
  loadGUID: string;
  vehicleGUID?: string;
  onDismiss: () => void;
  onSubmitSuccess: () => void;
}

function LoadVehicleDeleteDialogContent({
  loadGUID,
  onDismiss,
  vehicleGUID,
  onSubmitSuccess,
}: LoadVehicleDeleteDialogProps) {
  const { data } = useLoadDetails(loadGUID);
  const { addSnackbar } = useSnackbarStack();
  const { invalidateLoads } = useLoadsCache();

  const vehicle = useMemo(
    () => data?.vehicles.find((v) => v.guid === vehicleGUID),
    [data?.vehicles, vehicleGUID],
  );

  const vehicleName = useMemo(
    () =>
      vehicle && joinStrings(' ', vehicle.year, vehicle.make, vehicle.model),
    [vehicle],
  );

  const deleteVehicle = useDeleteVehicle(
    { adjust_load_price: true },
    {
      onSuccess() {
        invalidateLoads();
        onSubmitSuccess();

        const loadTotalPrice = computePrice(data?.payments);
        const vehiclesTotalPrice = computePrice(data?.vehicles);

        const vehiclePrice = vehicle?.price;

        if (
          !vehiclePrice ||
          !loadTotalPrice ||
          vehiclesTotalPrice !== loadTotalPrice
        ) {
          addSnackbar('Vehicle deleted');
        } else {
          addSnackbar(
            `Vehicle deleted and the load price is changed to ${formatCurrency(
              vehiclesTotalPrice - vehiclePrice,
            )}`,
            { autoHideDuration: 12000 },
          );
        }
      },
      onError({ message }) {
        addSnackbar(
          message ||
            'Something went wrong while deleting the vehicle, contact support',
        );
      },
    },
  );

  const isStatusNew = !!data && data.status === 'new';

  return (
    <MutationDialogContent
      onClose={onDismiss}
      title={`Delete ${vehicleName || 'vehicle'}?`}
    >
      {!isStatusNew &&
        'All vehicle information including inspection details will be deleted.'}

      <DialogActions>
        <Button
          variant="neutral"
          onClick={onDismiss}
          disabled={deleteVehicle.isLoading}
        >
          Cancel
        </Button>

        <Button
          type="submit"
          variant="critical"
          disabled={deleteVehicle.isLoading}
          onClick={() => {
            if (vehicleGUID) deleteVehicle.mutate(vehicleGUID);
          }}
        >
          Delete
        </Button>
      </DialogActions>
    </MutationDialogContent>
  );
}

export function LoadVehicleDeleteDialog(props: LoadVehicleDeleteDialogProps) {
  return (
    <MutationDialog
      maxWidth="xs"
      fullWidth={true}
      onClose={props.onDismiss}
      open={!!props.vehicleGUID}
    >
      <LoadVehicleDeleteDialogContent {...props} />
    </MutationDialog>
  );
}
