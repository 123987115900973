import {
  IconButton,
  StandardTextFieldProps,
  TextField,
  Typography,
} from '@material-ui/core';
import { OpenInNew } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import {
  Column,
  Columns,
  Inline,
  OverflowText,
  Stack,
  Tag,
} from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import { FormikValues, useField, useFormikContext } from 'formik';
import { forwardRef, ReactNode, useMemo, useRef, useState } from 'react';
import { formatPlural } from 'shared/helpers/IntlHelpers';
import { useDebouncedValue } from 'shared/helpers/ReactHelpers';
import { openExternalURL } from 'shared/helpers/URLHelpers';
import { CloseIcon } from 'shared/icons/CloseIcon';
import styled from 'styled-components';
import { ContactDTO } from '../../contacts/data/ContactDTO';
import { OrdersDTO } from '../data/LoadDTO';
import { useOrders } from '../data/LoadsAPI';
import { OrderWaypoints } from './LoadOrderWayPoints';

interface OrderFieldCommonProps {
  TextFieldProps?: StandardTextFieldProps;
}

const StartIcon = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 8px;
`;

const MultipleOrderFieldText = styled(TextField)`
  & .MuiAutocomplete-inputRoot {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    & .MuiAutocomplete-input {
      width: 100%;
    }
  }
`;

interface OrderFieldMultipleProps
  extends Omit<
    OrderFieldCommonProps,
    'options' | 'onChange' | 'getOptionLabel'
  > {
  startIcon?: ReactNode;
  name: string;
  guid?: string;
  loadNumber?: string;
}

export const LoadSelectionField = forwardRef(
  (
    {
      startIcon,
      TextFieldProps,
      loadNumber,
      guid,
      name,
      ...props
    }: OrderFieldMultipleProps,
    ref,
  ) => {
    const rendered = useRef<boolean>();
    const { setValues } = useFormikContext<FormikValues>();
    const [field, { touched, error }] = useField<OrdersDTO[]>({ name });
    const [broker] = useField<ContactDTO>({ name: 'customer' });
    const [search, setSearch] = useState(loadNumber);
    const query = useDebouncedValue(search, 500);

    const { data, isFetching } = useOrders(
      guid, //if guid will update, it will automatically refetch data while open close drawer multiple times.
      {
        q: query,
        page: 1,
        page_size: 50,
        archived: false,
        broker_name: broker.value.name || '',
      },
      {
        onSuccess: (res) => {
          if (res.length > 0) {
            const order = res.find((item) => item.guid === guid);

            //On first render initialize orders list, on other time it does not required anywhere. To check if it is first render, we use useRef.
            if (order && !rendered.current) {
              void setValues((value) => ({ ...value, orders: [order] }));
            }
          }
        },
        onSettled: () => {
          //On the First request to aware of the guid, it will find the order and set it to the values, which is for initialize on formik and represent default value on Autocomplete.
          rendered.current = true;
        },
      },
    );

    const errorMessage = touched && error;

    const options = useMemo(() => (data ? data : []), [data]);

    const handleTag = (option: OrdersDTO) => {
      openExternalURL(`/tms/loads/${option.guid}`, { target: '_blank' });
    };

    const handleDelete = (option: OrdersDTO) => {
      const filteredOrders = field.value.filter(
        (item) => item.guid !== option.guid,
      );
      void setValues((value) => ({ ...value, orders: filteredOrders }));
    };

    const getTagColor = (statusDisplay: string) => {
      switch (statusDisplay) {
        case 'New':
          return 'teal';
        case 'Picked up':
          return 'yellow';
        default:
          return 'green';
      }
    };

    return (
      <Autocomplete
        {...props}
        options={options}
        ref={ref}
        multiple={true}
        freeSolo={true}
        disableClearable={true}
        filterSelectedOptions={true}
        disabled={!rendered.current}
        loading={isFetching}
        aria-label="load-list"
        value={field.value}
        getOptionSelected={(option, value) => option.guid === value.guid}
        getOptionLabel={(option) => option.number}
        filterOptions={(filterOptions) => {
          return filterOptions.filter((option) => option.number !== '');
        }}
        onChange={(_, selectedValue) => {
          void setValues((value) => ({ ...value, orders: selectedValue }));
        }}
        renderTags={(value) => {
          return (
            <Inline space="xxsmall">
              {value.length > 0 &&
                value.map((option, index) => {
                  return (
                    <Tag
                      key={option.guid}
                      color="grey"
                      variant="subtle"
                      id={option.guid}
                    >
                      <Box display="flex">
                        <Typography color="textPrimary">
                          {option.number || 'No Load ID'}
                        </Typography>
                        <IconButton
                          size="small"
                          onClick={() => {
                            handleTag(option);
                          }}
                        >
                          <OpenInNew color="action" fontSize="small" />
                        </IconButton>
                        {index !== 0 && (
                          <IconButton
                            onClick={() => {
                              handleDelete(option);
                            }}
                            size="small"
                          >
                            <CloseIcon />
                          </IconButton>
                        )}
                      </Box>
                    </Tag>
                  );
                })}
            </Inline>
          );
        }}
        renderOption={(option) => {
          return (
            <Stack>
              <Columns>
                <Column width="fluid">
                  <Columns space="xxsmall" align="center">
                    <Column width="content">
                      <Typography color="textSecondary" variant="h6">
                        LOAD ID
                      </Typography>
                    </Column>
                    <Column width="adaptive">
                      <OverflowText
                        gutterBottom={false}
                        disableUnderline={true}
                        TooltipProps={{
                          placement: 'top',
                          interactive: true,
                        }}
                      >
                        {option.number}
                      </OverflowText>
                    </Column>
                  </Columns>
                </Column>

                <Column width="content">
                  <Inline horizontalAlign="right" space="xsmall">
                    {option.invoice_date && (
                      <Tag variant="subtle" color="grey">
                        Invoiced
                      </Tag>
                    )}
                    {option.is_paid && (
                      <Tag variant="subtle" color="grey">
                        Paid
                      </Tag>
                    )}
                    <Tag
                      variant="subtle"
                      color={getTagColor(option.status_display)}
                    >
                      {option.status_display}
                    </Tag>
                  </Inline>
                </Column>
              </Columns>

              {option.vehicles && option.vehicles.length > 0 && (
                <Typography variant="h5">
                  {option.vehicles.length}{' '}
                  {formatPlural(option.vehicles.length, 'vehicle', 'vehicles')}
                </Typography>
              )}
              <Stack>
                <OrderWaypoints load={option} />
              </Stack>
            </Stack>
          );
        }}
        renderInput={(params) => (
          <MultipleOrderFieldText
            {...params}
            {...TextFieldProps}
            data-start-icon={String(!!startIcon)}
            placeholder="Enter load ids to combine them into one invoice"
            onChange={(event) => {
              setSearch(event.target.value);
            }}
            multiline={true}
            minRows={2}
            InputProps={{
              ...TextFieldProps?.InputProps,
              ...params.InputProps,
              startAdornment: (
                <>
                  {startIcon && <StartIcon>{startIcon}</StartIcon>}
                  {params.InputProps.startAdornment}
                </>
              ),
            }}
            variant="outlined"
            fullWidth={true}
            error={!!errorMessage}
            helperText={errorMessage}
            onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
              if (event.key === 'Backspace' && field.value.length === 1) {
                event.stopPropagation();
              }
            }}
          />
        )}
      />
    );
  },
);
