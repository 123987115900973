import { IconButton, Typography } from '@material-ui/core';
import { Column, Columns, Inline } from '@superdispatch/ui';
import { Box, Button } from '@superdispatch/ui-lab';
import { useState } from 'react';
import { CircleCloseIcon } from 'shared/icons/CircleCloseIcon';
import { Close } from 'shared/icons/Close';
import { TimerIcon } from 'shared/icons/TimerIcon';
import { useSubscriptionSettings } from 'shared/modules/subscription/SubscriptionAPI';
import { useNavigate } from 'shared/routing/react-router-6';

export function DriverSubscriptionStatusBanner() {
  const [shouldShowBanner, setShouldShowBanner] = useState(true);
  const { data: subscription } = useSubscriptionSettings();
  const navigate = useNavigate();

  const isSubscriptionCanceled = subscription?.status === 'Canceled';

  if (!shouldShowBanner) {
    return null;
  }

  return (
    <Box
      paddingTop="small"
      paddingBottom="small"
      paddingRight="large"
      backgroundColor="Yellow50"
    >
      <Columns align="center">
        <Column width="fluid">
          <Inline verticalAlign="center" horizontalAlign="center" space="small">
            <Inline
              space="xxsmall"
              verticalAlign="center"
              horizontalAlign="center"
            >
              {isSubscriptionCanceled ? <CircleCloseIcon /> : <TimerIcon />}
              <Typography>
                <b>
                  {isSubscriptionCanceled
                    ? 'Subscription Canceled. '
                    : 'Trial expired. '}
                </b>
                {isSubscriptionCanceled
                  ? 'Reactivate to get unlimited access to advanced load management features.'
                  : 'Upgrade to get unlimited access to advanced load management features.'}
              </Typography>
            </Inline>

            <Button
              size="small"
              variant="neutral"
              onClick={() => {
                navigate('/settings/subscription');
              }}
            >
              {isSubscriptionCanceled
                ? 'Continue to Reactivate Plan'
                : 'Continue to Upgrade'}
            </Button>
          </Inline>
        </Column>

        <Column width="content">
          {!isSubscriptionCanceled && (
            <IconButton
              onClick={() => {
                setShouldShowBanner(false);
              }}
              size="small"
            >
              <Close />
            </IconButton>
          )}
        </Column>
      </Columns>
    </Box>
  );
}
