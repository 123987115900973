import { Color } from '@superdispatch/ui';
import { createSvgIcon } from 'shared/icons/IconUtils';

export const DotGridPattern = createSvgIcon(
  'DotGridPattern',
  <>
    <circle cx="1" cy="0" r="1" fill="currentColor" />
    <circle cx="19" cy="0" r="1" fill="currentColor" />
    <circle cx="37" cy="0" r="1" fill="currentColor" />
    <circle cx="55" cy="0" r="1" fill="currentColor" />
    <circle cx="73" cy="0" r="1" fill="currentColor" />
    <circle cx="91" cy="0" r="1" fill="currentColor" />
    <circle cx="109" cy="0" r="1" fill="currentColor" />
    <circle cx="127" cy="0" r="1" fill="currentColor" />
    <circle cx="145" cy="0" r="1" fill="currentColor" />
    <circle cx="163" cy="0" r="1" fill="currentColor" />
    <circle cx="181" cy="0" r="1" fill="currentColor" />
    <circle cx="199" cy="0" r="1" fill="currentColor" />
    <circle cx="217" cy="0" r="1" fill="currentColor" />
    <circle cx="235" cy="0" r="1" fill="currentColor" />
    <circle cx="253" cy="0" r="1" fill="currentColor" />
    <circle cx="271" cy="0" r="1" fill="currentColor" />
    <circle cx="289" cy="0" r="1" fill="currentColor" />
    <circle cx="307" cy="0" r="1" fill="currentColor" />
    <circle cx="325" cy="0" r="1" fill="currentColor" />
    <circle cx="343" cy="0" r="1" fill="currentColor" />
    <circle cx="361" cy="0" r="1" fill="currentColor" />
    <circle cx="379" cy="0" r="1" fill="currentColor" />
    <circle cx="397" cy="0" r="1" fill="currentColor" />
    <circle cx="415" cy="0" r="1" fill="currentColor" />
    <circle cx="433" cy="0" r="1" fill="currentColor" />
    <circle cx="451" cy="0" r="1" fill="currentColor" />
    <circle cx="469" cy="0" r="1" fill="currentColor" />
    <circle cx="487" cy="0" r="1" fill="currentColor" />
    <circle cx="505" cy="0" r="1" fill="currentColor" />
    <circle cx="523" cy="0" r="1" fill="currentColor" />
    <circle cx="541" cy="0" r="1" fill="currentColor" />
    <circle cx="559" cy="0" r="1" fill="currentColor" />
  </>,
  { viewBox: '0 0 560 1', htmlColor: Color.Silver500 },
);
