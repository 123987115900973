import { Tag, TagProps } from '@superdispatch/ui';
import { TextBox } from '@superdispatch/ui-lab';
import { ReactNode } from 'react';
import styled from 'styled-components';

const StyledTag = styled(Tag)`
  align-items: center;
  column-gap: 4px;
  display: flex;
`;

const StyledTagIcon = styled.div`
  display: flex;
`;

interface IconTagProps extends Omit<TagProps, 'variant'> {
  icon: ReactNode;
  variant?: TagProps['variant'];
}

export function IconTag({ children, icon, ...props }: IconTagProps) {
  return (
    <StyledTag variant="subtle" {...props}>
      <StyledTagIcon>
        <TextBox variant="body-semibold" color="inherit">
          {icon}
        </TextBox>
      </StyledTagIcon>

      <TextBox variant="body-semibold" color="inherit">
        {children}
      </TextBox>
    </StyledTag>
  );
}
