import { useEffect } from 'react';

export function useScrollToTop(anchorElement?: HTMLElement | null) {
  useEffect(() => {
    if (anchorElement) {
      anchorElement.scrollTop = 0;
    } else {
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    }
  }, [anchorElement]);
}

export function ScrollToTop({
  anchorElement,
}: {
  anchorElement?: HTMLElement | null;
}) {
  useScrollToTop(anchorElement);

  return null;
}
