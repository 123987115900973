import { requestAPIGateway } from 'shared/api/APIClient';
import { APIQueryResult, useAPIQuery } from 'shared/api/APIQuery';
import { ShipperProfileDTO, shipperProfileSchema } from './ShipperProfileDTO';

export function useShipperProfile(
  guid: string | undefined,
): APIQueryResult<ShipperProfileDTO> {
  return useAPIQuery(
    ['shippers', 'profile', { guid }],
    () => requestAPIGateway(['GET /carrier/shippers/{guid}/profile', { guid }]),
    {
      enabled: !!guid,
      normalize(data: { object: unknown }) {
        return shipperProfileSchema.cast(data.object);
      },
    },
  );
}
