import { TokenizeProps, useChargebee } from '../helpers/ChargebeeWeb';

export function useCreditCard(client_token?: string) {
  const { addChargebeeCreditCard, isChargebeeAvailable, cardRef } =
    useChargebee(client_token);

  async function addCreditCard({ firstName, lastName }: TokenizeProps) {
    if (!client_token) {
      return Promise.reject(new Error('Unauthorized request'));
    }
    return addChargebeeCreditCard({ firstName, lastName });
  }

  return {
    addCreditCard,
    isChargebeeAvailable,
    cardRef,
  };
}
