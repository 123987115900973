import {
  LoadPaymentMethod,
  LOAD_PAYMENT_METHODS,
  LOAD_PAYMENT_TERMS,
} from '@superdispatch/sdk';

export const LOAD_PAYMENT_TYPES = [
  ...LOAD_PAYMENT_METHODS.filter(
    (method): method is Exclude<LoadPaymentMethod, 'other'> =>
      method !== 'other',
  ),
  ...LOAD_PAYMENT_TERMS,
] as const;
