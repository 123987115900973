import { trackEvent } from 'shared/helpers/Analytics';
import { VerifiedCarrierApplicationStatusDTO } from 'shared/modules/carrier-profile/VerifiedCarrierApplicationStatusDTO';
import { VerificationCodeMethod } from './PhoneVerificationAPI';

type VerificationStatus =
  | VerifiedCarrierApplicationStatusDTO['verified_carrier_application']['status']
  | undefined;

type PhoneVerificationAnalyticsEvent =
  | {
      name: 'Carrier Clicked Verify Phone Number Now';
      carrier_verification_status: VerificationStatus;
    }
  | {
      name: 'Carrier Requested Phone Verification Code';
      attempt_count: number;
      method: VerificationCodeMethod;
      is_method_changed: boolean;
      carrier_verification_status: VerificationStatus;
    }
  | {
      name: 'Carrier Submitted Phone Verification Code';
      method: VerificationCodeMethod | undefined;
      is_successful: boolean;
      carrier_verification_status: VerificationStatus;
    }
  | {
      name: 'Carrier Clicked Instruction Fmcsa Usdot Pin';
      utm_content:
        | 'Code Request Screen Help Drawer'
        | 'Phone Number Retrieve Error Screen';
    }
  | {
      name: 'Carrier Clicked Request Fmcsa Usdot Pin';
      utm_content:
        | 'Code Request Screen Help Drawer'
        | 'Phone Number Retrieve Error Screen';
    };

export function trackPhoneVerificationEvent(
  event: PhoneVerificationAnalyticsEvent,
) {
  const { name, ...props } = event;
  trackEvent(name, { ...props });
}
