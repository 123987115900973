import { Card, CardContent, Typography } from '@material-ui/core';
import { FormattedDate } from '@superdispatch/dates';
import { OverflowText, Stack } from '@superdispatch/ui';
import { Box, TextBox } from '@superdispatch/ui-lab';
import { useEffect, useRef, useState } from 'react';
import { useFlag } from 'shared/settings/FeatureToggles';
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from 'shared/ui/Timeline';
import { LoadActivityDTO, LoadDTO } from '../data/LoadDTO';

interface ViewLoadActivityProps {
  load: LoadDTO;
  changedByBrokerActivities: LoadActivityDTO;
}

export function ViewLoadActivity({
  load,
  changedByBrokerActivities,
}: ViewLoadActivityProps) {
  const isChangedByBrokerActivityLogsEnabled = useFlag('latest_activity_logs');
  const [activeLogs, setActiveLogs] = useState<LoadActivityDTO>(
    changedByBrokerActivities,
  );
  const ref = useRef<HTMLUListElement>(null);

  const isUpdated =
    load.activities?.some((item) => item.is_changed_by_broker) ||
    load.is_changed_by_broker;

  useEffect(() => {
    if (isUpdated && !load.archived && load.is_active && activeLogs) {
      const timeout = activeLogs.length <= 3 ? 5000 : 8000;
      setTimeout(() => {
        setActiveLogs([]);
      }, timeout);
    }
  }, [isUpdated, load.archived, load.is_active, activeLogs]);

  useEffect(() => {
    if (
      isUpdated &&
      isChangedByBrokerActivityLogsEnabled &&
      !load.archived &&
      load.is_active
    ) {
      ref.current?.scrollIntoView({
        behavior: 'smooth',
      });
    } else {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [
    isUpdated,
    isChangedByBrokerActivityLogsEnabled,
    load.archived,
    load.is_active,
  ]);

  return (
    <Card aria-label="Activity Timeline">
      <CardContent>
        <Stack space="small">
          <Typography variant="h3" color="textPrimary" id="activity-timeline">
            Activity
          </Typography>

          <Timeline ref={ref}>
            {load.activities?.map(
              ({ timestamp, user, text, level }, idx, activities) => {
                const isLastElement = idx === activities.length - 1;

                return (
                  <TimelineItem aria-label={`activity-item-${idx}`} key={idx}>
                    <TimelineSeparator>
                      <TimelineDot
                        aria-label={`activity-timeline-dot-${idx}`}
                        color={level}
                      />
                      {!isLastElement && <TimelineConnector />}
                    </TimelineSeparator>

                    <TimelineContent
                      isUnRead={
                        activeLogs?.find(
                          (item) => item.timestamp === timestamp,
                        ) &&
                        isChangedByBrokerActivityLogsEnabled &&
                        !load.archived &&
                        load.is_active
                      }
                    >
                      <Box paddingBottom={!isLastElement ? 'small' : 'none'}>
                        <Stack space="xxsmall">
                          <TextBox wrapOverflow={true} variant="body-semibold">
                            {text}
                          </TextBox>

                          <div>
                            {timestamp && (
                              <Typography
                                component="p"
                                variant="caption"
                                color="textSecondary"
                              >
                                <FormattedDate
                                  date={timestamp}
                                  variant="DateTime"
                                />
                              </Typography>
                            )}

                            {user?.email && (
                              <OverflowText
                                component="p"
                                variant="caption"
                                color="textSecondary"
                              >
                                {user.email}
                              </OverflowText>
                            )}
                          </div>
                        </Stack>
                      </Box>
                    </TimelineContent>
                  </TimelineItem>
                );
              },
            )}
          </Timeline>
        </Stack>
      </CardContent>
    </Card>
  );
}
