import { Stack } from '@superdispatch/ui';
import { useFormikContext } from 'formik';
import { MobileAppBridge } from 'shared/data/MobileAppBridge';
import { FormikMediaField } from 'shared/form/FormikMediaField';
import { FieldAdditionalMessages } from 'shared/modules/carrier-profile/core/AdditionalMessages';
import { useVerifiedCarrierApplicationStatus } from 'shared/modules/carrier-profile/VerifiedCarrierApplicationStatus';
import { useCarrierSettings } from 'shared/settings/CarrierSettingsAPI';
import { TransporterPlateRegistrationDTO } from '../data/CarrierProfileDTO';

export function ProfileTransporterPlateRegistrationForm() {
  const { data } = useVerifiedCarrierApplicationStatus();
  const { data: settings } = useCarrierSettings();
  const formik = useFormikContext<TransporterPlateRegistrationDTO>();

  const isEditingDisabled =
    !settings?.drivers_can_edit_carrier_info && MobileAppBridge.isInjected();
  const application = data?.verified_carrier_application;
  const plateDecliningReasons =
    application?.declining_reasons?.transporter_plate_registration;
  const externalNotes =
    application?.external_notes?.transporter_plate_registration;

  const isInitialFile =
    formik.initialValues.file_url === formik.values.file_url;

  const shouldShowW9Error = isInitialFile && !!plateDecliningReasons?.length;

  return (
    <Stack space="small">
      <FormikMediaField
        label="Transporter Plate Registration"
        name="file_url"
        fullWidth={true}
        disabled={isEditingDisabled}
      />
      {shouldShowW9Error && (
        <FieldAdditionalMessages
          title="Transporter Plate Registration declined due to:"
          variant="error"
          messages={plateDecliningReasons}
          externalNotes={externalNotes}
        />
      )}
    </Stack>
  );
}
