import { useResponsiveValue } from '@superdispatch/ui';
import { ReactNode, useState } from 'react';
import { useNavbarContext } from 'shared/layout/sidebar/NavbarContext';
import { useOnboardingTourHandler } from '../onboarding/OnboardingIntercomTours';
import { useLoadOffer } from './loadOfferCount';
import { LoadOffersDrawer } from './LoadOffersDrawer';

interface LoadOffersButtonProps {
  render: (props: {
    loadOffersCount?: number;
    onClick: () => void;
  }) => ReactNode;
}

export function LoadOffersButton({ render }: LoadOffersButtonProps) {
  const [isOpen, setIsOpen] = useState(false);
  const { setDrawerOpen } = useNavbarContext();
  const screen = useResponsiveValue('mobile', 'tablet', 'desktop');
  const isMobile = screen === 'mobile' || screen === 'tablet';
  const data = useLoadOffer();

  useOnboardingTourHandler({
    tourName: 'tour_offer_received',
    enabled: !!data && data > 0,
  });

  return (
    <>
      <LoadOffersDrawer
        open={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
      />

      {render({
        loadOffersCount: data,
        onClick: () => {
          if (isMobile) {
            setDrawerOpen('load-offers');
          } else {
            setIsOpen(true);
          }
        },
      })}
    </>
  );
}
