import { CircularProgress, Tooltip, Typography } from '@material-ui/core';
import { Help } from '@material-ui/icons';
import {
  Color,
  Column,
  Columns,
  ExitTransitionPlaceholder,
  Inline,
  Stack,
} from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import { lazy, Suspense, useState } from 'react';
import { formatPlural } from 'shared/helpers/IntlHelpers';
import { ContentProgressIndicator } from 'shared/layout/ContentProgressIndicator';
import { trackPerformanceAnalytics } from 'shared/modules/carrier-profile/core/PerformanceAnalytics';
import { PerformanceDrawer } from 'shared/modules/carrier-profile/core/PerformanceDrawer';
import { WebViewLink } from 'shared/routing/WebViewLinks';
import styled from 'styled-components';
import {
  etaThresholds,
  lateCancellationThresholds,
  photosTakenThresholds,
} from '../core/PerformanceHelpers';
import { Indicator, NotVerifiedInfo } from '../core/PerformanceIndicators';
import { useCarrierPerformanceIndicators } from '../data/CarrierPerformanceAPI';
import { CarrierPerformanceDTO } from '../data/CarrierPerformanceDTO';
import { useVerifiedCarrierApplicationStatus } from '../VerifiedCarrierApplicationStatus';

const EtaDrawerLazy = lazy(() => import('../core/EtaDrawer'));
const LateCancellationDrawerLazy = lazy(
  () => import('../core/LateCancellationDrawer'),
);
const PhotosTakenOnSiteDrawerLazy = lazy(
  () => import('../core/PhotosTakenOnSiteDrawer'),
);

const PerformanceEstimateText = styled(Typography)`
  color: ${Color.Dark100};
`;

export interface IndicatorsProps {
  indicators?: CarrierPerformanceDTO;
}

export function Indicators({ indicators }: IndicatorsProps) {
  const [currentDrawer, setCurrentDrawer] = useState<
    'eta' | 'lateCancellation' | 'photosTaken' | undefined
  >();

  return (
    <>
      <PerformanceDrawer
        onClose={() => {
          setCurrentDrawer(undefined);
        }}
        open={!!currentDrawer}
        title={
          currentDrawer === 'eta'
            ? 'ETA Provided'
            : currentDrawer === 'lateCancellation'
            ? 'Late Cancellation'
            : 'Photos Taken On-Site'
        }
      >
        <ExitTransitionPlaceholder in={!!currentDrawer}>
          <Suspense fallback={<ContentProgressIndicator />}>
            {currentDrawer === 'eta' && <EtaDrawerLazy />}
            {currentDrawer === 'lateCancellation' && (
              <LateCancellationDrawerLazy />
            )}
            {currentDrawer === 'photosTaken' && <PhotosTakenOnSiteDrawerLazy />}
          </Suspense>
        </ExitTransitionPlaceholder>
      </PerformanceDrawer>
      <Columns space={['small', 'xsmall']} collapseBelow="tablet">
        <Column width="1/3">
          <Indicator
            label="Late Cancellation"
            finalCount={indicators?.late_cancellation_final_count}
            generalCount={indicators?.late_cancellation_general_count}
            generalCountCaption={formatPlural(
              indicators?.late_cancellation_general_count,
              `${indicators?.late_cancellation_general_count} acceptance`,
              `${indicators?.late_cancellation_general_count} acceptances`,
            )}
            notEnoughDataTitle="Not enough data"
            thresholds={lateCancellationThresholds}
            onClick={() => {
              trackPerformanceAnalytics({
                name: 'Carrier Clicked on Performance Indicator',
                indicator_type: 'Late cancellation',
              });
              setCurrentDrawer('lateCancellation');
            }}
          />
        </Column>
        <Column width="1/3">
          <Indicator
            label="ETA provided"
            finalPickupCount={indicators?.eta_provided_final_pickup_count}
            finalDeliveryCount={indicators?.eta_provided_final_delivery_count}
            generalCount={indicators?.eta_provided_general_count}
            generalCountCaption={formatPlural(
              indicators?.eta_provided_general_count,
              `${indicators?.eta_provided_general_count} delivery`,
              `${indicators?.eta_provided_general_count} deliveries`,
            )}
            notEnoughDataTitle="Not enough data"
            thresholds={etaThresholds}
            onClick={() => {
              trackPerformanceAnalytics({
                name: 'Carrier Clicked on Performance Indicator',
                indicator_type: 'ETA provided',
              });
              setCurrentDrawer('eta');
            }}
          />
        </Column>
        <Column width="1/3">
          <Indicator
            label="Photos taken on-site"
            finalPickupCount={indicators?.photos_on_site_final_pickup_count}
            finalDeliveryCount={indicators?.photos_on_site_final_delivery_count}
            generalCount={indicators?.photos_on_site_general_count}
            generalCountCaption={formatPlural(
              indicators?.photos_on_site_general_count,
              `${indicators?.photos_on_site_general_count} delivery`,
              `${indicators?.photos_on_site_general_count} deliveries`,
            )}
            notEnoughDataTitle="Not enough data"
            thresholds={photosTakenThresholds}
            onClick={() => {
              trackPerformanceAnalytics({
                name: 'Carrier Clicked on Performance Indicator',
                indicator_type: 'Photos taken on-site',
              });
              setCurrentDrawer('photosTaken');
            }}
          />
        </Column>
      </Columns>
    </>
  );
}

export function CarrierPerformanceIndicators() {
  const { data, isFetched } = useCarrierPerformanceIndicators();
  const { data: verificationStatus } = useVerifiedCarrierApplicationStatus();

  const isVerified =
    verificationStatus?.verified_carrier_application.status === 'approved';

  return (
    <Stack aria-label="Performance" space="small">
      <Inline space="xxsmall" verticalAlign="center">
        <Typography variant="h3">Performance</Typography>
        <PerformanceEstimateText variant="h3">
          for last 60 days
        </PerformanceEstimateText>
        <Tooltip
          interactive={true}
          placement="right"
          title={
            <>
              Only loads accepted through Super Dispatch are included in the
              performance metrics. Loads that are imported or manually created
              are excluded.{' '}
              <WebViewLink
                color="inherit"
                target="_blank"
                rel="noopener noreferrer"
                href="https://support.superdispatch.com/en/articles/7913694-carrier-tms-carrier-performance-metrics"
              >
                Learn more
              </WebViewLink>
            </>
          }
        >
          <Help fontSize="small" color="action" />
        </Tooltip>
      </Inline>

      {!isFetched ? (
        <Box padding="small">
          <Stack align="center">
            <CircularProgress size={24} />
          </Stack>
        </Box>
      ) : !isVerified ? (
        <NotVerifiedInfo />
      ) : (
        <Indicators indicators={data} />
      )}
    </Stack>
  );
}
