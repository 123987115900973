import { useMemo } from 'react';
import { Query, QueryClient, useQueryClient } from 'react-query';
import { requestAPIGateway } from 'shared/api/APIClient';
import {
  APIMutationOptions,
  APIMutationResult,
  useAPIMutation,
} from 'shared/api/APIMutation';
import {
  APIQueryDataUpdater,
  APIQueryOptions,
  APIQueryResult,
  setAPIQueryData,
  useAPIQuery,
} from 'shared/api/APIQuery';
import { useAPISocketMessages } from 'shared/api/APISocketMessages';
import { LoadSuggestionDTO } from './LoadSuggestionDTO';

export function setLoadSuggestionsQueryData(
  queryClient: QueryClient,
  updater: APIQueryDataUpdater<LoadSuggestionDTO[]>,
): void {
  const queries = queryClient
    .getQueryCache()
    .findAll(['suggested-loads', 'items'], { exact: false }) as Array<
    Query<LoadSuggestionDTO[]>
  >;

  for (let query of queries) {
    if (query.state.data) {
      query.setData(updater(query.state.data));
    }
  }
}

export function useLoadSuggestions(
  drivers: string[],
  options?: APIQueryOptions<LoadSuggestionDTO[]>,
): APIQueryResult<LoadSuggestionDTO[]> {
  return useAPIQuery(
    ['suggested-loads', 'items', { drivers }],
    () => {
      return requestAPIGateway([
        'GET /loadboard/loads/suggestions{?driver_guids}',
        { driver_guids: drivers },
      ]);
    },
    options,
  );
}

interface LoadSuggestionsUnseenCount {
  number_of_unseen_suggestions: number;
}

export function useLoadSuggestionsUnseenCount(): APIQueryResult<LoadSuggestionsUnseenCount> {
  return useAPIQuery(
    ['suggested-loads', 'unseen'],
    () => requestAPIGateway('GET /loadboard/loads/suggestions/unseen'),
    { staleTime: Infinity },
  );
}

export function useLoadSuggestionsCache() {
  const client = useQueryClient();

  return useMemo(() => {
    function setLoadSuggestionsUnseenCount(
      updater: APIQueryDataUpdater<LoadSuggestionsUnseenCount>,
    ): void {
      setAPIQueryData(client, ['suggested-loads', 'unseen'], updater);
    }

    function invalidateLoadSuggestions() {
      void client.invalidateQueries(['suggested-loads']);
    }

    function invalidateLoadSuggestionsList() {
      void client.invalidateQueries(['suggested-loads', 'items']);
    }

    return {
      invalidateLoadSuggestions,
      setLoadSuggestionsUnseenCount,
      invalidateLoadSuggestionsList,
    };
  }, [client]);
}

export function useDismissLoadSuggestion(
  options?: APIMutationOptions<number>,
): APIMutationResult<number> {
  const queryClient = useQueryClient();
  const previousLoads = queryClient.getQueryData<
    APIQueryDataUpdater<LoadSuggestionDTO[]>
  >(['suggested-loads', 'items']);

  return useAPIMutation(
    (suggestionId) => {
      return requestAPIGateway([
        'POST /loadboard/loads/suggestions/{suggestionId}/dismiss',
        { suggestionId },
      ]);
    },
    {
      ...options,
      onMutate: (suggestionId) => {
        setLoadSuggestionsQueryData(queryClient, (loads) =>
          loads.filter((load) => load.suggestion_id !== suggestionId),
        );
        void options?.onMutate?.(suggestionId);
      },
      onError: (...args) => {
        queryClient.setQueryData(['suggested-loads', 'items'], previousLoads);
        void options?.onError?.(...args);
      },
    },
  );
}

export function useMarkAsSeenLoadSuggestion({
  invalidateCache = true,
  ...options
}: APIMutationOptions<Set<number>> & {
  invalidateCache?: boolean;
} = {}): APIMutationResult<Set<number>> {
  const queryClient = useQueryClient();
  const { invalidateLoadSuggestions } = useLoadSuggestionsCache();

  return useAPIMutation(
    (ids) =>
      requestAPIGateway('POST /loadboard/loads/suggestions/mark_as_seen', {
        json: { suggestion_ids: Array.from(ids) },
      }).then((response) => {
        if (!invalidateCache) {
          return response;
        }

        setLoadSuggestionsQueryData(queryClient, (loads) =>
          loads.map((load) => {
            if (ids.has(load.suggestion_id)) {
              return {
                ...load,
                is_seen: true,
              };
            }
            return load;
          }),
        );
        invalidateLoadSuggestions();

        return response;
      }),
    options,
  );
}

interface LoadSuggestionsUpdatedData {
  subject: 'Load Suggestion Deactivated' | 'New Load Suggestion Added';
}

/*
 * This hook should be called once because it updates global cache.
 */
export function useLoadSuggestionCountSubscription() {
  const { data } = useLoadSuggestionsUnseenCount();

  const { invalidateLoadSuggestionsList, setLoadSuggestionsUnseenCount } =
    useLoadSuggestionsCache();

  useAPISocketMessages<LoadSuggestionsUpdatedData>((payload) => {
    if (payload.meta.type === 'load_suggestions_updated') {
      setLoadSuggestionsUnseenCount(
        ({ number_of_unseen_suggestions: count }) => {
          count +=
            payload.data.subject === 'New Load Suggestion Added' ? 1 : -1;
          return { number_of_unseen_suggestions: Math.max(count, 0) };
        },
      );

      invalidateLoadSuggestionsList();
    }
  });

  return data;
}
