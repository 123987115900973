import { createContext, ReactNode, useContext, useMemo, useState } from 'react';

export interface NavbarContextType {
  isDrawerOpen: 'menu' | 'suggested-loads' | 'load-offers' | null;

  setDrawerOpen: (
    value: 'menu' | 'suggested-loads' | 'load-offers' | null,
  ) => void;
}

export const NavbarContext = createContext<NavbarContextType>({
  isDrawerOpen: null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setDrawerOpen: () => {},
});

export function useNavbarContext(): NavbarContextType {
  return useContext(NavbarContext);
}

interface NavbarContextProviderProps {
  children: ReactNode;
}

export function NavbarContextProvider({
  children,
}: NavbarContextProviderProps) {
  const [isDrawerOpen, setDrawerOpen] = useState<
    'menu' | 'suggested-loads' | 'load-offers' | null
  >(null);

  const ctx = useMemo(
    (): NavbarContextType => ({ isDrawerOpen, setDrawerOpen }),
    [isDrawerOpen, setDrawerOpen],
  );

  return (
    <NavbarContext.Provider value={ctx}>{children}</NavbarContext.Provider>
  );
}
