import { Inline } from '@superdispatch/ui';
import { Button } from '@superdispatch/ui-lab';
import { useEffect, useMemo, useState } from 'react';
import { useLazyRef } from 'shared/hooks/useLazyRef';
import { trackLoadsEvent } from '../data/LoadsAnalytics';
import {
  findLoadsPageParamsSearchEntry,
  LoadFilterField,
  LOAD_FILTER_FIELDS,
} from '../data/LoadsPageParams';
import { useLoadsPageContext } from './data/LoadsPageParamsContext';
import { LoadsPageFilterOrderBy } from './LoadsPageFilterOrderBy';
import { LoadsPageFilterResourceField } from './LoadsPageFilterResourceField';
import { LoadsPageFilterSearch } from './LoadsPageFilterSearch';

export function LoadsPageFilter() {
  const getLazyRef = useLazyRef<HTMLInputElement, LoadFilterField>();
  const { params, updateParams, isEmptyFilterParams } = useLoadsPageContext();
  const [searchName, searchValue] = useMemo(
    () => findLoadsPageParamsSearchEntry(params),
    [params],
  );

  const [lastUsedFilter, setLastUsedFilter] = useState<LoadFilterField>();

  useEffect(() => {
    if (lastUsedFilter) {
      const { current: input } = getLazyRef(lastUsedFilter);

      if (input) {
        input.focus();
        input.click();
      }

      setLastUsedFilter(undefined);
    }
  }, [getLazyRef, lastUsedFilter]);

  return (
    <Inline space="medium">
      <LoadsPageFilterSearch
        name={searchName}
        value={searchValue}
        onSearch={(field, value) => {
          updateParams(
            { stage: 'active', [field]: value },
            { strategy: 'reset' },
          );
          trackLoadsEvent({ name: 'CTMS: Searched Loads', field, value });
        }}
      />

      {params.stage !== 'active' && (
        <Inline space="medium">
          <Inline space="xsmall">
            {LOAD_FILTER_FIELDS.map((filter) => {
              const ref = getLazyRef(filter);

              if (params.stage !== 'in_terminal' && filter === 'terminals') {
                return null;
              }

              return (
                <LoadsPageFilterResourceField
                  key={filter}
                  name={filter}
                  inputRef={ref}
                  value={params[filter]}
                  onChange={(guid) => {
                    updateParams({ [filter]: guid, page: undefined });
                  }}
                />
              );
            })}
          </Inline>

          <LoadsPageFilterOrderBy
            stage={params.stage}
            orderBy={params.order_by}
            onChange={(orderBy) => {
              updateParams(
                (prev) => ({
                  order_by: orderBy,
                  stage: prev.stage,
                  drivers: prev.drivers,
                  terminals: prev.terminals,
                  dispatchers: prev.dispatchers,
                }),
                { strategy: 'reset' },
              );

              if (orderBy) {
                trackLoadsEvent({
                  name: 'CTMS: Sorted Loads',
                  field: orderBy,
                });
              }
            }}
          />

          {(!isEmptyFilterParams || params.order_by) && (
            <Button
              variant="text"
              onClick={() => {
                updateParams((prev) => ({ stage: prev.stage }), {
                  strategy: 'reset',
                });
              }}
            >
              Clear Filters
            </Button>
          )}
        </Inline>
      )}
    </Inline>
  );
}
