import { Typography } from '@material-ui/core';
import { FormattedDate } from '@superdispatch/dates';
import { Column, Columns, Stack } from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import { LogoWithBrand } from 'shared/icons/LogoWithBrand';
import { PublicOfferDTO } from './data/PublicOfferDTO';

export interface OfferViewHeaderProps {
  offer: PublicOfferDTO;
}

export function PublicOfferPageHeader({
  offer: { created_at: createdAt },
}: OfferViewHeaderProps) {
  return (
    <Box
      maxWidth="600px"
      marginLeft={['xsmall', 'auto', 'auto']}
      marginRight={['xsmall', 'auto', 'auto']}
      marginBottom="small"
      marginTop="medium"
    >
      <Columns>
        <Column width="fluid">
          <LogoWithBrand />
        </Column>

        <Column width="content">
          <Stack space="none">
            <Typography variant="body1" align="right">
              LOAD OFFER
            </Typography>

            {createdAt && (
              <Typography color="textSecondary" align="right">
                <FormattedDate variant="DateTime" date={createdAt} />
              </Typography>
            )}
          </Stack>
        </Column>
      </Columns>
    </Box>
  );
}
