import { Box } from '@superdispatch/ui-lab';
import { ProfilePageLayout } from '../ProfilePageLayout';
import { PromoPageContent } from './PromoPageContent';
import { SUPERPAY_PROFILE_ROUTES } from './SuperPayRoute';

export function PromoPage() {
  return (
    <ProfilePageLayout title="SuperPay" headerEndActions={false} size="medium">
      <Box maxWidth="672px" margin="auto">
        <PromoPageContent
          setupLink={`${SUPERPAY_PROFILE_ROUTES.businessDetails}?utm_medium=Promo Page`}
        />
      </Box>
    </ProfilePageLayout>
  );
}
