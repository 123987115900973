import { Link, Typography } from '@material-ui/core';
import { Info, Warning } from '@material-ui/icons';
import { Color, Column, Columns } from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import { chooseUTMSource } from 'shared/helpers/Analytics';
import { showNewIntercomMessage } from 'shared/helpers/Intercom';
import { useCarrierSettings } from 'shared/settings/CarrierSettingsAPI';
import { GradientBackground } from 'shared/ui/GradientBackground';

const isSLB = import.meta.env.VITE_APP_NAME === 'slbm';

export interface SuperPayNudgingBannerProps {
  source:
    | 'Load Details'
    | 'Load List'
    | 'Load Offer Details'
    | 'Public Load Offer Details';
}

function NoUserBanner() {
  return (
    <GradientBackground
      paddingTop="xxsmall"
      paddingBottom="xxsmall"
      paddingLeft="xsmall"
      paddingRight="xsmall"
      borderRadius="small"
      color="Dark500"
    >
      SuperPay needs to be set up to receive payment.{' '}
      <Link
        href="https://support.superdispatch.com/en/articles/6502819-superpay-how-to-begin-and-what-to-expect"
        target="_blank"
        rel="noreferrer"
        color="primary"
        variant="body1"
      >
        Learn more
      </Link>
    </GradientBackground>
  );
}

export function BankAccountVerificationBanner({ link }: { link: string }) {
  return (
    <Box
      padding="xsmall"
      borderRadius="small"
      backgroundColor="Yellow50"
      aria-label="superpay banner"
    >
      <Columns space="xsmall" align="top">
        <Column width="content">
          <Box top="3px" position="relative">
            <Warning fontSize="small" htmlColor={Color.Yellow300} />
          </Box>
        </Column>
        <Column>
          <Typography>
            To receive payment on time,{' '}
            <Link color="primary" href={link}>
              verify your bank account.
            </Link>
          </Typography>
        </Column>
      </Columns>
    </Box>
  );
}

function BankAccountNotStartedBanner({ link }: { link: string }) {
  return (
    <Link href={link}>
      <GradientBackground
        paddingTop="xxsmall"
        paddingBottom="xxsmall"
        paddingLeft="xsmall"
        paddingRight="xsmall"
        borderRadius="small"
        color="Dark500"
      >
        Click here to set up SuperPay and receive payment.
      </GradientBackground>
    </Link>
  );
}

export function getOnboardingLink() {
  return isSLB
    ? '/tms-webview/superpay/onboarding/'
    : '/tms/profile/superpay/onboarding/';
}

export function BankAccountFailedBanner() {
  return (
    <Box padding="xsmall" borderRadius="small" backgroundColor="Red50">
      <Columns space="xsmall" align="top">
        <Column width="content">
          <Info fontSize="small" htmlColor={Color.Red300} />
        </Column>
        <Column>
          <Typography>
            Bank verification failed. To resolve and receive payments,{' '}
            <Link
              data-intercom-target="SuperPay Bank Verification Status Failed"
              color="primary"
              underline="none"
              component="button"
              onClick={() => {
                showNewIntercomMessage();
              }}
            >
              contact support
            </Link>
          </Typography>
        </Column>
      </Columns>
    </Box>
  );
}

export function SuperPayNudgingBanner({ source }: SuperPayNudgingBannerProps) {
  const mainLink = getOnboardingLink();
  const { data: settings } = useCarrierSettings();
  const verificationStatus = settings?.superpay.verification;
  const isStatusNotStarted = verificationStatus === 'not_started';
  const isStatusBankAccount = verificationStatus === 'bank_account';
  const isStatusBankAccountVerification =
    verificationStatus === 'bank_account_verification' &&
    settings?.superpay.is_verification_available;
  const isBankAccountFailed =
    verificationStatus === 'bank_account_failed' ||
    verificationStatus === 'bank_account_errored';

  // in case we cannot identify user, display informative message
  if (!settings) {
    return <NoUserBanner />;
  }

  if (isStatusBankAccountVerification) {
    const link = `${mainLink}account-details/verify-bank-account?utm_medium=${source}&utm_source=${chooseUTMSource()}`;
    return <BankAccountVerificationBanner link={link} />;
  }

  if (isStatusNotStarted || isStatusBankAccount) {
    const link = `${mainLink}${
      isStatusNotStarted ? 'create-account' : 'bank-account'
    }?utm_medium=${source}&utm_source=${chooseUTMSource()}`;

    return <BankAccountNotStartedBanner link={link} />;
  }

  if (isBankAccountFailed) {
    return <BankAccountFailedBanner />;
  }

  return null;
}
