import { StandardTextFieldProps, TextField } from '@material-ui/core';
import { useUID } from '@superdispatch/ui';
import {
  VirtualizedAutocomplete,
  VirtualizedAutocompleteProps,
} from 'shared/form/VirtualizedAutocomplete';
import { timezones } from './SettingsTimezones';

export interface SettingsTimezoneFieldProps
  extends Omit<
    VirtualizedAutocompleteProps<string, false, false, false>,
    | 'value'
    | 'onChange'
    | 'options'
    | 'multiple'
    | 'autoHighlight'
    | 'renderInput'
    | 'filterOptions'
    | 'getOptionLabel'
  > {
  value?: null | string;
  onChange?: (value: null | string) => void;
  TextFieldProps?: StandardTextFieldProps;
}

export function SettingsTimezoneField({
  id,
  value,
  onChange,
  TextFieldProps: { autoComplete = 'off', ...textFieldProps } = {},
  ...props
}: SettingsTimezoneFieldProps) {
  const uid = useUID();

  return (
    <VirtualizedAutocomplete
      {...props}
      multiple={false}
      autoHighlight={true}
      id={id || uid}
      value={value || null}
      options={timezones}
      onChange={(_, nextValue) => {
        onChange?.(nextValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          {...textFieldProps}
          autoComplete={autoComplete}
        />
      )}
    />
  );
}
