import { Link, Typography } from '@material-ui/core';
import { Color, Column, Columns, Inline, Stack } from '@superdispatch/ui';
import { Button } from '@superdispatch/ui-lab';
import { useFormikContext } from 'formik';
import { formatCurrency, formatPlural } from 'shared/helpers/IntlHelpers';
import { EncryptOutlinedIcon } from 'shared/icons/EncryptOutlinedIcon';
import { getPerDriverPrice } from 'shared/modules/subscription/core/SeatBasedPricingBox';
import { SubscriptionSettingsDTO } from 'shared/modules/subscription/SubscriptionDTO';
import styled from 'styled-components';

const PlanInformationDivider = styled.div`
  border-bottom: 1px dotted ${Color.Silver400};
  height: 18px;
`;

interface SubscriptionSummaryProps {
  subscriptionDetails: SubscriptionSettingsDTO;
  disabled: boolean;
  seatsCount: number;
  onContinue?: () => void;
}

export function SubscriptionSummary({
  subscriptionDetails,
  seatsCount,
  disabled,
  onContinue,
}: SubscriptionSummaryProps) {
  const { isSubmitting } = useFormikContext();

  if (!subscriptionDetails.plan) {
    return null;
  }

  const perDriverPrice = getPerDriverPrice(
    seatsCount,
    subscriptionDetails.plan,
  );
  const totalPrice = seatsCount * perDriverPrice;

  return (
    <Stack space="small">
      <Stack space="small">
        <Typography display="inline" variant="h3">
          Order Summary
        </Typography>

        <Stack space="xxsmall">
          <Columns space="xsmall">
            <Column width="content">
              <Typography>{subscriptionDetails.plan.type}</Typography>
            </Column>
            <Column width="fluid">
              <PlanInformationDivider />
            </Column>
            <Column width="content">
              <Typography>{formatCurrency(totalPrice)}</Typography>
            </Column>
          </Columns>
          <Typography color="textSecondary">
            {formatCurrency(perDriverPrice, { maximumFractionDigits: 0 })} /
            driver / month x {seatsCount}{' '}
            {formatPlural(seatsCount, 'driver', 'drivers')}
          </Typography>
          <Typography color="textSecondary">Billed monthly</Typography>
        </Stack>

        <Stack space="xsmall">
          <Columns space="xsmall">
            <Column width="content">
              <Typography>Total</Typography>
            </Column>
            <Column width="fluid">
              <PlanInformationDivider />
            </Column>
            <Column width="content">
              <Typography variant="h4">{formatCurrency(totalPrice)}</Typography>
            </Column>
          </Columns>
          <Typography align="right">+ tax if applicable</Typography>
        </Stack>

        <Button
          startIcon={<EncryptOutlinedIcon />}
          fullWidth={true}
          size="large"
          onClick={onContinue}
          disabled={disabled}
          pending={isSubmitting}
        >
          Continue to Upgrade
        </Button>

        <Inline horizontalAlign="center">
          <Typography align="center" color="textSecondary" variant="caption">
            By continuing, you agree to our{' '}
            <Link
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.superdispatch.com/terms-of-service"
            >
              Terms of Service
            </Link>{' '}
            and{' '}
            <Link
              target="_blank"
              rel="noopener noreferrer"
              href="https://support.superdispatch.com/en/articles/6975505-carrier-tms-how-do-i-cancel-my-subscription"
            >
              Refund Policy
            </Link>
          </Typography>
        </Inline>
      </Stack>
    </Stack>
  );
}
