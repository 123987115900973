import { Stack } from '@superdispatch/ui';
import { AnchorButton, Box } from '@superdispatch/ui-lab';
import { DocumentTitle } from 'shared/layout/DocumentTitle';
import { SettingsQuickbookDesktop } from './core/SettingQuickbookDesktop';
import { SettingsPageLayout } from './core/SettingsPageLayout';
import { SettingsQuickbookOnline } from './core/SettingsQuickbookOnline';

export function SettingsQuickbooksIntegrationPage() {
  return (
    <>
      <DocumentTitle title="Quickbooks Integration - Settings" />

      <SettingsPageLayout
        actions={
          <Box>
            <AnchorButton
              variant="text"
              target="_blank"
              href="https://support.superdispatch.com/en/articles/1316429-quickbooks-integrating-with-super-dispatch-carrier-tms"
            >
              Open Help Center
            </AnchorButton>
          </Box>
        }
        title="Quickbooks Integration"
      >
        <Box width="768px">
          <Stack space="small">
            <SettingsQuickbookOnline />
            <SettingsQuickbookDesktop />
          </Stack>
        </Box>
      </SettingsPageLayout>
    </>
  );
}
