import { trackEvent } from 'shared/helpers/Analytics';

interface SubscriptionAnalyticsProps {
  name:
    | 'Carrier Reactivated Subscription'
    | 'Carrier Edited Subscription Payment Information'
    | 'Carrier Edited Subscription Billing Address'
    | 'Carrier Opened Subscription Checkout'
    | 'Carrier Updated Seats'
    | 'Carrier Upgraded Plan';
}

export function trackSubscriptionEvent(event: SubscriptionAnalyticsProps) {
  const { name } = event;

  trackEvent(name);
}
