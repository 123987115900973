import { useResponsiveValue } from '@superdispatch/ui';
import { Button, TextBox } from '@superdispatch/ui-lab';
import {
  DialogWithIllustration,
  DialogWithIllustrationProps,
} from 'shared/ui/DialogWithIllustration';
import styled from 'styled-components';
import { PendingVerificationIllustration } from './PendingVerificationIllustration';

const InlineActions = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
`;

interface SaveAndResubmitVerificationDialogProps
  extends Omit<
    DialogWithIllustrationProps,
    'actions' | 'illustrationSrc' | 'title'
  > {
  onResubmit: () => void;
}

export function ResubmitNonVerifiableApplicationDialog({
  onClose,
  onResubmit,
  ...props
}: SaveAndResubmitVerificationDialogProps) {
  const isMobile = useResponsiveValue(true, false);
  return (
    <DialogWithIllustration
      {...props}
      onClose={onClose}
      illustration={<PendingVerificationIllustration />}
      title={
        <>
          Do you want to resubmit
          <br /> for Verified Carrier?
        </>
      }
      alignActions="right"
      actions={
        <InlineActions>
          <Button variant="neutral" onClick={onClose} fullWidth={isMobile}>
            Cancel
          </Button>
          <Button onClick={onResubmit} fullWidth={isMobile}>
            Save and Resubmit
          </Button>
        </InlineActions>
      }
    >
      <TextBox variant="body-block">
        Due to your USDOT number not qualifying for Verified Carrier, your
        request was initially declined.
        <br />
        To reapply for Verified Carrier, you can Save and Resubmit your
        documents for the Compliance team to review.
      </TextBox>
    </DialogWithIllustration>
  );
}
