import { IconButton } from '@material-ui/core';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import {
  PaginationItem,
  usePagination,
  UsePaginationProps,
} from '@material-ui/lab';
import { Inline, useResponsiveValue } from '@superdispatch/ui';

export function RatingsPagination(
  props: Omit<UsePaginationProps, 'siblingCount' | 'boundaryCount'>,
) {
  const siblingCount = useResponsiveValue(0, 2, 3);
  const { items } = usePagination({ ...props, siblingCount });

  return (
    <nav aria-label="pagination navigation">
      <Inline verticalAlign="center" horizontalAlign="center" space="xxsmall">
        {items.map((item, i) => {
          if (item.type === 'previous' || item.type === 'next') {
            return (
              <IconButton
                key={i}
                size="small"
                onClick={item.onClick}
                disabled={item.disabled}
                aria-label={`${item.type} page`}
              >
                {item.type === 'previous' ? <ChevronLeft /> : <ChevronRight />}
              </IconButton>
            );
          }

          return <PaginationItem key={i} {...item} />;
        })}
      </Inline>
    </nav>
  );
}
