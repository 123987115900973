import { FormControlLabel, Radio } from '@material-ui/core';
import { FormikRadioGroupField, FormikTextField } from '@superdispatch/forms';
import { Column, Columns, Stack } from '@superdispatch/ui';
import { TextBox } from '@superdispatch/ui-lab';
import { Fragment } from 'react';
import { useGeneralSettings } from '../../settings/data/SettingsAPI';
import {
  formatLoadInspectionType,
  LoadInspectionType,
  LOAD_INSPECTION_TYPES,
} from '../data/LoadDTO';

export function getLoadInspectionTypeDescription(type: LoadInspectionType) {
  switch (type) {
    case 'standard':
      return 'Doesn’t have any required steps for drivers';
    case 'advanced':
      return 'Requires the driver to take at least 6 photos at pickup and delivery';
    case 'aiag':
      return 'Used for OEM customers and requires 2 photos per damage';
  }
}

export interface LoadDetailsFormProps {
  disableInternalLoadIDHint?: boolean;
  isEnabledEditLoad?: boolean;
  isDisableLoadId?: boolean;
}

export function LoadDetailsForm({
  disableInternalLoadIDHint,
  isEnabledEditLoad = true,
  isDisableLoadId,
}: LoadDetailsFormProps) {
  const { data: generalSettings } = useGeneralSettings({
    enabled: !disableInternalLoadIDHint,
  });

  return (
    <Stack space="large">
      <Columns space="small" collapseBelow="tablet">
        <Column width={['fluid', '1/2', 'content']}>
          <FormikTextField
            disabled={isDisableLoadId}
            fullWidth={true}
            label="Load ID"
            name="number"
          />
        </Column>

        <Column width={['fluid', '1/2', 'content']}>
          <FormikTextField
            fullWidth={true}
            label="Internal Load ID"
            name="internal_load_id"
            helperText={
              !!generalSettings?.initial_load_id &&
              'Leave this field empty to assign ID automatically.'
            }
          />
        </Column>
      </Columns>

      <Stack space="xsmall">
        <TextBox variant="body-semibold" color="secondary">
          Inspection Type
        </TextBox>

        <FormikRadioGroupField
          disabled={!isEnabledEditLoad}
          name="inspection_type"
        >
          <Stack space="xsmall">
            {LOAD_INSPECTION_TYPES.map((inspectionType) => (
              <Fragment key={inspectionType}>
                <FormControlLabel
                  value={inspectionType}
                  control={<Radio />}
                  label={
                    <>
                      {formatLoadInspectionType(inspectionType)}
                      <br />
                      <TextBox color="secondary" variant="caption">
                        {getLoadInspectionTypeDescription(inspectionType)}
                      </TextBox>
                    </>
                  }
                />
              </Fragment>
            ))}
          </Stack>
        </FormikRadioGroupField>
      </Stack>
    </Stack>
  );
}
