import { useEffect, useState } from 'react';
import { requestCarrierAPI, requestJSON } from 'shared/api/APIClient';
import {
  APIQueryResult,
  useAPIQuery,
  UseAPIQueryOptions,
} from 'shared/api/APIQuery';
import { useExperimentState } from 'shared/modules/experiments/ExperimentsAPI';
import { useFlag } from 'shared/settings/FeatureToggles';
import { yupEnum, yupObject } from 'shared/utils/YupUtils';
import { InferType } from 'yup';

export type VerifiedCarrierApplicationStatusDTO = InferType<
  typeof carrierApplicationStatusSchema
>;
export const carrierApplicationStatusSchema = yupObject({
  verified_carrier_application: yupObject({
    status: yupEnum([
      'not_submitted',
      'approved',
      'declined',
      'revoked',
      'pending',
    ]).required(),
  }),
});

export function useCarrierApplicationStatus(
  options?: UseAPIQueryOptions<VerifiedCarrierApplicationStatusDTO>,
): APIQueryResult<VerifiedCarrierApplicationStatusDTO> {
  return useAPIQuery(
    ['carrier-application-status', 'experiment'],
    () =>
      requestCarrierAPI(
        'GET /internal/web/carrier/verified-carrier-application/status/',
      ),
    { ...options, schema: carrierApplicationStatusSchema },
  );
}

function useIsTrialExpired({ skip = true }: { skip: boolean }) {
  const [isTrialExpired, setIsTrialExpired] = useState(false);

  useEffect(() => {
    if (!skip) {
      // profile API is behind the paywall, so we are requesting that to check the if trial is expired
      void requestJSON<{ error?: Record<string, unknown> }>(
        'GET /internal/web/dispatchers/profile/',
      ).then((response) => {
        if (response.error?.type === 'trial_expired') {
          setIsTrialExpired(true);
        }
      });
    }
  }, [skip]);

  return isTrialExpired;
}

export function useSignupExperiment() {
  const isSignupExperimentEnabled = useFlag('carrier_signup_experiment');
  const experimentState1 = useExperimentState('Signup1');
  const experimentState2 = useExperimentState('Signup1');

  const isInExperiment =
    (experimentState1.isInExperiment || experimentState2.isInExperiment) &&
    isSignupExperimentEnabled;

  const { data: carrierApplication } = useCarrierApplicationStatus({
    enabled: isInExperiment,
  });
  const isTrialExpired = useIsTrialExpired({ skip: !isInExperiment });

  const isApplicationNotApproved =
    carrierApplication?.verified_carrier_application.status ===
      'not_submitted' ||
    carrierApplication?.verified_carrier_application.status === 'pending' ||
    carrierApplication?.verified_carrier_application.status === 'declined';

  const shouldRunExperiment =
    isApplicationNotApproved && isInExperiment && isTrialExpired;

  return {
    shouldRunExperiment,
    carrierApplication,
  };
}
