import { createSvgIcon } from 'shared/icons/IconUtils';

export const LoadsSidebarIcon = createSvgIcon(
  'LoadsSidebar',
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M16.8 2C17.9 2 18.8 2.9 18.8 4H4.8C4.8 2.9 5.7 2 6.8 2H16.8ZM20.8 8C20.8 6.9 19.9 6 18.8 6H4.8C3.7 6 2.8 6.9 2.8 8H20.8ZM21.6 10H2C1.4 10 0.900003 10.6 1 11.2L2.5 19.6C2.8 21 4.1 22 5.5 22H18.1C19.5 22 20.8 21 21.1 19.5L22.6 11.1C22.7 10.6 22.2 10 21.6 10Z"
    fill="currentColor"
  />,
);
