import { SidebarContainer } from '@superdispatch/ui-lab';
import { Route, Switch } from 'react-router-dom';
import { ProfileACHPaymentInfoPage } from './ProfileACHPaymentInfoPage';
import { ProfileBillingInfoPage } from './ProfileBillingInfoPage';
import { ProfileBusinessLicensePage } from './ProfileBusinessLicensePage';
import { ProfileCargoInsurancePage } from './ProfileCargoInsurancePage';
import { ProfileCarrierInfoPage } from './ProfileCarrierInfoPage';
import { ProfileOverviewPage } from './ProfileOverviewPage';
import { ProfilePhoneVerificationPage } from './ProfilePhoneVerificationPage';
import { ProfileSidebar } from './ProfileSidebar';
import { ProfileStateMotorCarrierPermitPage } from './ProfileStateMotorCarrierPermit';
import { ProfileSuperPayPage } from './ProfileSuperPayPage';
import { ProfileTermsAndConditionsPage } from './ProfileTermsAndConditionsPage';
import { ProfileTransporterPlateRegistrationPage } from './ProfileTransporterPlateRegistrationPage';
import { ProfileUSDOTPage } from './ProfileUSDOTPage';
import { ProfileW9Page } from './ProfileW9Page';
import { ProfileVerifiedCarrierApplicationPage } from './verified-carrier-application/ProfileVerifiedCarrierApplicationPage';

export function ProfileContainerPage() {
  return (
    <Switch>
      <Route path="/profile/verified-carrier-application/:step?">
        <ProfileVerifiedCarrierApplicationPage />
      </Route>

      <Route path="/profile/phone-verification">
        <ProfilePhoneVerificationPage />
      </Route>

      <Route path="/profile/:step?">
        <SidebarContainer sidebar={<ProfileSidebar />}>
          <Switch>
            <Route path="/profile/overview">
              <ProfileOverviewPage />
            </Route>

            <Route path="/profile/carrier_info">
              <ProfileCarrierInfoPage />
            </Route>

            <Route path="/profile/cargo_insurance">
              <ProfileCargoInsurancePage />
            </Route>

            <Route path="/profile/w9">
              <ProfileW9Page />
            </Route>

            <Route path="/profile/usdot">
              <ProfileUSDOTPage />
            </Route>

            <Route path="/profile/transporter_plate_registration">
              <ProfileTransporterPlateRegistrationPage />
            </Route>

            <Route path="/profile/state_motor_carrier_permit">
              <ProfileStateMotorCarrierPermitPage />
            </Route>

            <Route path="/profile/business_license">
              <ProfileBusinessLicensePage />
            </Route>

            <Route path="/profile/billing_info">
              <ProfileBillingInfoPage />
            </Route>

            <Route path="/profile/ach_payment">
              <ProfileACHPaymentInfoPage />
            </Route>

            <Route path="/profile/superpay">
              <ProfileSuperPayPage />
            </Route>

            <Route path="/profile/terms_and_conditions">
              <ProfileTermsAndConditionsPage />
            </Route>
          </Switch>
        </SidebarContainer>
      </Route>
    </Switch>
  );
}
