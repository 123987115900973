import { Typography } from '@material-ui/core';
import { Box } from '@superdispatch/ui-lab';
import { useEffect, useState } from 'react';
import { LoginLayout } from 'shared/auth/core/LoginLayout';
import { identifyAnalytics } from 'shared/helpers/Analytics';
import { showIntercomLauncher } from 'shared/helpers/Intercom';
import styled from 'styled-components';
import { UserAccountLoginPage } from './DriverAccountLoginPage';

const Copyright = styled(Box)`
  margin: 16px auto;
`;

export function DriverAccountContainerPage() {
  const [email, setEmail] = useState('');

  useEffect(() => {
    identifyAnalytics();
    return showIntercomLauncher;
  }, []);

  return (
    <LoginLayout
      footerAction={
        <Copyright>
          <Typography align="center" color="textSecondary">
            &copy; {new Date().getFullYear()} Super Dispatch. All rights
            reserved.
          </Typography>
        </Copyright>
      }
    >
      <UserAccountLoginPage initialEmail={email} onEmailChange={setEmail} />
    </LoginLayout>
  );
}
