import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  Typography,
} from '@material-ui/core';
import { Stack } from '@superdispatch/ui';
import { Button } from '@superdispatch/ui-lab';
import { LocalStore } from 'shared/helpers/Store';

export interface SplitLoadsIntroDialogProps {
  isOpen: boolean;
  onClose: () => void;
}

export function SplitLoadsIntroDialog({
  isOpen,
  onClose,
}: SplitLoadsIntroDialogProps) {
  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="xs" fullWidth={true}>
      <DialogTitle>
        <Typography variant="h4">How to Split Orders?</Typography>
      </DialogTitle>

      <DialogContent>
        <Stack>
          <img
            width="100%"
            height="300px"
            src="https://s3.amazonaws.com/superdispatch-attachments/files/Webp.net-gifmaker+(4).gif"
            alt="Order splitting demo"
          />
          <Link
            rel="noopener noreferrer"
            href="https://support.superdispatch.com/everything-dispatch-dashboard/how-to-split-an-order-by-vehicle"
            target="_blank"
          >
            Detailed instructions
          </Link>
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>Got it</Button>
      </DialogActions>
    </Dialog>
  );
}

export function shouldShowIntroModal(): boolean {
  return LocalStore.get('displayIntroModal') === null;
}

export function setShownIntroModal() {
  LocalStore.set('displayIntroModal', '1');
}
