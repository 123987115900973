import { createSvgIcon } from 'shared/icons/IconUtils';

export const RepoYardIcon = createSvgIcon(
  'BusinessTypeRepoYard',
  <path
    fill="currentColor"
    d="M10 4.667h3.593L11.373 2H10v2.667Zm1.667 5.666a.94.94 0 0 0 .693-.286c.2-.2.307-.434.307-.714a.963.963 0 0 0-.307-.693.963.963 0 0 0-.693-.307.974.974 0 0 0-.714.307.94.94 0 0 0-.286.693c0 .28.086.514.286.714.2.2.434.286.714.286Zm-7.667 0c.293 0 .533-.086.72-.286.187-.2.28-.434.28-.714a.99.99 0 0 0-.28-.693.946.946 0 0 0-.72-.307.946.946 0 0 0-.72.307.99.99 0 0 0-.28.693c0 .28.093.514.28.714.187.2.427.286.72.286Zm8-9.666 3.333 4v4.666h-1.666c0 .554-.207 1.02-.607 1.42a1.9 1.9 0 0 1-1.393.58 1.91 1.91 0 0 1-1.414-.58c-.4-.4-.586-.866-.586-1.42H6c0 .554-.2 1.02-.593 1.42-.407.394-.867.58-1.407.58s-1-.186-1.407-.58C2.2 10.353 2 9.887 2 9.333H.667V6.667h5.46L2 3.407v1.926H.667v-4h.666l7.334 4.04V.667H12Z"
  />,
  { viewBox: '0 -1 15 15' },
);
