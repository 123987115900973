import { Tooltip } from '@material-ui/core';
import { Info } from '@material-ui/icons';
import { FormikCheckboxField } from '@superdispatch/forms';
import { Column, Inline } from '@superdispatch/ui';
import { Box, TextBox } from '@superdispatch/ui-lab';
import { useFormikContext } from 'formik';
import { useEffect } from 'react';
import { SuperPayLabel } from 'shared/helpers/PaymentHelpers';
import { useCarrierSettings } from 'shared/settings/CarrierSettingsAPI';
import { useFlag } from 'shared/settings/FeatureToggles';
import { GradientBorderBox } from 'shared/ui/PaymentGradientBorder';
import { LoadSendInvoiceDTO } from '../data/LoadActionsDTO';
import { LOAD_PAYMENT_TYPES } from '../data/LoadPaymentTypes';

type PaymentMethodProp = typeof LOAD_PAYMENT_TYPES | string | undefined | null;

export function useSuperPayRequested(
  paymentMethod: PaymentMethodProp,
  isCreatedByBroker?: boolean,
) {
  const isCarrierRequestedSuperPayEnabled = useFlag(
    'payments_carrier_requests_superpay',
  );
  const { data: carrierSettings } = useCarrierSettings();
  const isSuperPayRequested = carrierSettings?.superpay.is_superpay_requested;
  const isSuperPayVerified =
    carrierSettings?.superpay.verification === 'verified';
  const hasShowSuperPayRequestCheckBox =
    paymentMethod !== 'superpay' &&
    isSuperPayVerified &&
    isCarrierRequestedSuperPayEnabled &&
    isCreatedByBroker;

  return {
    hasShowSuperPayRequestCheckBox,
    isEnableSuperPayRequestCheckBox: hasShowSuperPayRequestCheckBox
      ? isSuperPayRequested
      : null,
  };
}

export function LoadSendInvoiceRequestPayment({
  paymentMethod,
  isCreatedByBroker,
}: {
  paymentMethod: PaymentMethodProp;
  isCreatedByBroker?: boolean;
}) {
  const fieldName = 'is_carrier_requested_superpay';
  const { values, setFieldValue } = useFormikContext<LoadSendInvoiceDTO>();
  const { factor_this_invoice: isFactorThisInvoiceAvailable, orders } = values;
  const isMultipleLoads = orders && orders.length > 1;
  const isDisabled = isFactorThisInvoiceAvailable || isMultipleLoads;
  const { hasShowSuperPayRequestCheckBox, isEnableSuperPayRequestCheckBox } =
    useSuperPayRequested(paymentMethod, isCreatedByBroker);

  useEffect(() => {
    if (isDisabled && hasShowSuperPayRequestCheckBox) {
      void setFieldValue(fieldName, false);
    }
  }, [hasShowSuperPayRequestCheckBox, isDisabled, setFieldValue]);

  return hasShowSuperPayRequestCheckBox ? (
    <Column>
      <GradientBorderBox
        isActive={!isEnableSuperPayRequestCheckBox}
        data-intercom-target="request payment via superpay"
      >
        <FormikCheckboxField
          name={fieldName}
          disabled={isDisabled}
          helperText={
            isDisabled && (
              <TextBox color="secondary" variant="caption">
                {isFactorThisInvoiceAvailable
                  ? 'This is not available when you factor invoice.'
                  : 'This is not available for invoices with multiple loads.'}
              </TextBox>
            )
          }
          label={
            <Inline noWrap={false} horizontalAlign="left" space="xxsmall">
              <TextBox color="secondary">Request payment via</TextBox>
              <Tooltip
                title={
                  <Box>
                    This adds a SuperPay payment request on the Invoice and
                    Shipper TMS, but does not guarantee method change to
                    SuperPay.
                    <br />
                    <br /> Order will be updated if Shipper <br /> changes the
                    payment method.
                  </Box>
                }
                placement="bottom-end"
              >
                <Box>
                  <Inline noWrap={false} verticalAlign="center" space="xxsmall">
                    <SuperPayLabel target="_blank" />
                    <Info color="action" fontSize="small" />
                  </Inline>
                </Box>
              </Tooltip>
            </Inline>
          }
        />
      </GradientBorderBox>
    </Column>
  ) : null;
}
