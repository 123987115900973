import { Typography } from '@material-ui/core';
import { useSnackbarStack } from '@superdispatch/ui';
import { MutationDialog } from 'shared/form/MutationDialog';
import { ConfirmDialogContent } from 'shared/ui/ConfirmDialog';
import { useUnassignTripDriver } from '../data/TripsAPI';

interface UnassignTripDriverDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmitSuccess: () => void;
  currentTripGUID: string;
}

export function UnassignTripDriverDialog({
  isOpen,
  currentTripGUID,
  onClose,
  onSubmitSuccess,
}: UnassignTripDriverDialogProps) {
  const { addSnackbar } = useSnackbarStack();

  const unassign = useUnassignTripDriver(currentTripGUID, {
    onSuccess(response) {
      addSnackbar(response.user_message || 'Trip was successfully unassigned', {
        variant: 'success',
      });

      onSubmitSuccess();
    },
    onError() {
      addSnackbar('Error occurred while assigning the trip to driver', {
        variant: 'error',
      });
    },
  });

  return (
    <MutationDialog
      open={isOpen}
      onClose={onClose}
      fullWidth={true}
      maxWidth="xs"
    >
      <ConfirmDialogContent
        onClose={onClose}
        title="Are you sure you want to unassign the driver?"
        cancelButtonProps={{ disabled: unassign.isLoading }}
        confirmButtonProps={{
          children: 'Unassign',
          pending: unassign.isLoading,
          onClick() {
            unassign.mutate();
          },
        }}
      >
        <Typography>
          If you confirm the operation, driver will be unassigned from this
          trip.
        </Typography>
      </ConfirmDialogContent>
    </MutationDialog>
  );
}
