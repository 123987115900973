import { FormControlLabel, Radio } from '@material-ui/core';
import { FormikRadioGroupField, useFormikEnhanced } from '@superdispatch/forms';
import { useSnackbarStack } from '@superdispatch/ui';
import { APIResponse } from 'shared/api/APIClient';
import { FormikDrawerActions } from 'shared/form/FormikDrawer';
import { SlowFormikDrawer } from 'shared/form/SlowFormikDrawer';
import { joinStrings } from 'shared/utils/StringUtils';
import { useTerminalPage } from '../../terminals/data/TerminalsAPI';
import { LoadDTO, LoadUpdateTerminalDTO } from '../data/LoadDTO';
import { useLoadsContext } from '../data/LoadsContext';

export interface LoadAddTerminalDrawerValues {
  loadGUID: string;
  terminalGUID: string;
}

export interface LoadAddTerminalDrawerProps {
  load?: LoadDTO;
  onClose: () => void;
  onSubmitSuccess: (
    response: LoadUpdateTerminalDTO,
    values: LoadAddTerminalDrawerValues,
  ) => void;
}

export function LoadAddTerminalDrawer({
  load,
  onClose,
  onSubmitSuccess,
}: LoadAddTerminalDrawerProps) {
  const { data: terminalPage } = useTerminalPage();
  const { loadsAPI } = useLoadsContext();
  const { addSnackbar } = useSnackbarStack();

  const formik = useFormikEnhanced<
    LoadAddTerminalDrawerValues,
    APIResponse<LoadUpdateTerminalDTO>
  >({
    initialValues: { terminalGUID: '', loadGUID: load?.guid || '' },
    onSubmit: ({ loadGUID, terminalGUID }) =>
      loadsAPI.addTerminal(loadGUID, terminalGUID),
    onSubmitSuccess: ({ user_message, data }, values) => {
      onSubmitSuccess(data, values);
      addSnackbar(user_message || 'Terminal added', { variant: 'success' });
    },
    onSubmitFailure: ({ message }) => {
      addSnackbar(message, { variant: 'error' });
    },
  });

  return (
    <SlowFormikDrawer
      formik={formik}
      open={!!load}
      onClose={onClose}
      title="Add Terminal"
      actions={
        !!terminalPage && <FormikDrawerActions submitButtonLabel="Add" />
      }
    >
      <FormikRadioGroupField name="terminalGUID">
        {terminalPage?.data.map((option) => {
          const isDisabled = load?.terminals.some(
            (terminal) => terminal.guid === option.guid,
          );
          return (
            <FormControlLabel
              disabled={isDisabled}
              key={option.guid}
              value={option.guid}
              control={<Radio />}
              label={joinStrings(', ', option.name, option.city, option.state)}
            />
          );
        })}
      </FormikRadioGroupField>
    </SlowFormikDrawer>
  );
}
