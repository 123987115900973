import { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { usePageTrack } from 'shared/helpers/Analytics';
import { trackTripsEvent } from './data/TripsAnalytics';
import { TripDetailsPage } from './trip-details-page/TripDetailsPage';
import { TripsListPage } from './trips-list-page/TripsListPage';

const TRIPS_PATHS = {
  trips: '/trips',
  view: '/trips/view',
};

export function TripsContainerPage() {
  usePageTrack('Trips');

  useEffect(() => {
    trackTripsEvent({
      name: 'CTMS: Opened React Trips Page',
    });
  }, []);

  return (
    <Switch>
      <Route path={`${TRIPS_PATHS.view}/:guid`}>
        <TripDetailsPage />
      </Route>

      <Route path={TRIPS_PATHS.trips}>
        {/* The 'path' prop was introduced as a replacement for 'useRouteMatch' in order to facilitate the migration to React Router v6. */}
        <TripsListPage path={TRIPS_PATHS.trips} />
      </Route>
    </Switch>
  );
}
