import { parseDate } from '@superdispatch/dates';
import { useCallback, useEffect, useMemo } from 'react';
import { LocalStore, useLocalStore } from 'shared/helpers/Store';
import { useDispatcherProfile } from 'shared/modules/dispatcher/DispatcherAPI';

const TRIAL_EXPIRED_NOTIFICATION_EXPIRE_TIME = 24; //hours
const TRIAL_EXPIRED_NOTIFICATION_EXPIRE_TIME_HOURS =
  'trial_expired_notification_expire_time_hours';

export function useTrialExpiredNotificationStorageService(
  isTrialExpired: boolean,
  onClose: () => void,
) {
  const { data: currentDispatcher } = useDispatcherProfile();

  const currentDispatcherGUID = currentDispatcher?.guid;

  const trialExpiredNotificationSentTime = useLocalStore(
    TRIAL_EXPIRED_NOTIFICATION_EXPIRE_TIME_HOURS,
  );

  const parsedTrialExpiredNotificationSentTime = useMemo(() => {
    if (trialExpiredNotificationSentTime) {
      return new Map<string, string>(
        JSON.parse(trialExpiredNotificationSentTime),
      );
    }
    return new Map<string, string>();
  }, [trialExpiredNotificationSentTime]);

  const setSentTime = useCallback(() => {
    if (!currentDispatcherGUID) {
      return;
    }

    parsedTrialExpiredNotificationSentTime.set(
      currentDispatcherGUID,
      new Date().toISOString(),
    );

    LocalStore.set(
      TRIAL_EXPIRED_NOTIFICATION_EXPIRE_TIME_HOURS,
      JSON.stringify(
        Array.from(parsedTrialExpiredNotificationSentTime.entries()),
      ),
    );
  }, [currentDispatcherGUID, parsedTrialExpiredNotificationSentTime]);

  const sentTime = useMemo(() => {
    if (!currentDispatcherGUID) {
      return null;
    }

    return parseDate(
      parsedTrialExpiredNotificationSentTime.get(currentDispatcherGUID),
      {
        format: 'DateTimeISO',
      },
    );
  }, [currentDispatcherGUID, parsedTrialExpiredNotificationSentTime]);

  const isActiveTrialExpiredNotificationOpen = useMemo(() => {
    if (!sentTime) {
      return false;
    }
    const diffTime = Math.abs(sentTime.diffNow('hours').hours);
    return diffTime < TRIAL_EXPIRED_NOTIFICATION_EXPIRE_TIME;
  }, [sentTime]);

  useEffect(() => {
    if (!isActiveTrialExpiredNotificationOpen && currentDispatcherGUID) {
      parsedTrialExpiredNotificationSentTime.delete(currentDispatcherGUID);
      LocalStore.set(
        TRIAL_EXPIRED_NOTIFICATION_EXPIRE_TIME_HOURS,
        JSON.stringify(
          Array.from(parsedTrialExpiredNotificationSentTime.entries()),
        ),
      );
    }
  }, [
    isActiveTrialExpiredNotificationOpen,
    currentDispatcherGUID,
    parsedTrialExpiredNotificationSentTime,
  ]);

  useEffect(() => {
    if (isTrialExpired && isActiveTrialExpiredNotificationOpen) {
      onClose();
    }
  }, [isActiveTrialExpiredNotificationOpen, isTrialExpired, onClose]);

  return useMemo(
    () => ({
      setSentTime,
      sentTime,
      isActiveTrialExpiredNotificationOpen,
    }),
    [setSentTime, sentTime, isActiveTrialExpiredNotificationOpen],
  );
}
