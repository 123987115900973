import { Inline, Tag } from '@superdispatch/ui';
import { TextBox } from '@superdispatch/ui-lab';
import { PrivateIcon } from 'shared/icons/PrivateIcon';
import { ResponsiveTooltip } from 'shared/ui/ResponsiveTooltip';
import styled from 'styled-components';

const StyledTag = styled(Tag)`
  display: block;
`;

export function PostingPrivateTag() {
  return (
    <ResponsiveTooltip title="This load was posted to shipper’s Private Loadboard">
      <StyledTag
        color="grey"
        variant="subtle"
        data-intercom-target="Private Load Label"
      >
        <Inline space="xxsmall" verticalAlign="center">
          <PrivateIcon fontSize="small" />
          <TextBox variant="body-semibold">Private Load</TextBox>
        </Inline>
      </StyledTag>
    </ResponsiveTooltip>
  );
}
