import { OffersPageLayout } from '../../core/OffersPageLayout';
import { PublicOfferDTO } from '../data/PublicOfferDTO';
import { PublicOfferDetails } from '../PublicOfferDetails';
import { PublicOfferPageHeader } from '../PublicOfferPageHeader';

export function PublicOfferCanceledPage({ offer }: { offer: PublicOfferDTO }) {
  return (
    <>
      <PublicOfferPageHeader offer={offer} />

      <OffersPageLayout>
        <PublicOfferDetails offer={offer} />
      </OffersPageLayout>
    </>
  );
}
