import { useDeepEqualMemo, useEventHandler } from '@superdispatch/hooks';
import { startCase } from 'lodash-es';
import { useEffect, useState } from 'react';

function isIOS() {
  const isIOSDevice = /iPhone|iPad|iPod/i.test(navigator.userAgent);
  const isIPadOS13Up =
    /Macintosh/i.test(navigator.userAgent) &&
    !!navigator.maxTouchPoints &&
    navigator.maxTouchPoints > 2;

  return isIOSDevice || isIPadOS13Up;
}

export function getPlatform(): 'mobile' | 'desktop' {
  const isMobile =
    isIOS() ||
    /Android|Windows Phone|BlackBerry|webOS/i.test(navigator.userAgent);

  return isMobile ? 'mobile' : 'desktop';
}

export function getOS(): 'ios' | 'android' | 'unknown' {
  return isIOS()
    ? 'ios'
    : /android/i.test(navigator.userAgent)
    ? 'android'
    : 'unknown';
}

export function getBrowser():
  | 'chrome'
  | 'firefox'
  | 'safari'
  | 'edge'
  | 'opera'
  | 'unknown' {
  if (/opera|OPR\//i.test(navigator.userAgent)) {
    return 'opera';
  }

  if (/edg/i.test(navigator.userAgent)) {
    return 'edge';
  }

  if (/firefox/i.test(navigator.userAgent)) {
    return 'firefox';
  }

  if (/chrome/i.test(navigator.userAgent)) {
    return 'chrome';
  }

  if (/safari/i.test(navigator.userAgent)) {
    return 'safari';
  }

  return 'unknown';
}

export function getFormattedBrowser() {
  const browser = getBrowser();

  switch (browser) {
    case 'edge':
      return 'Microsoft Edge';
    default:
      return startCase(browser);
  }
}

export function useVisibilityState(): DocumentVisibilityState {
  const [state, setState] = useState<DocumentVisibilityState>(
    () => document.visibilityState,
  );

  useEffect(() => {
    function handleVisibilityChange() {
      setState(document.visibilityState);
    }

    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [setState]);

  return state;
}

export function useOnlineStatus() {
  const [status, setStatus] = useState<'online' | 'offline'>('online');

  useEffect(() => {
    const handleOnline = () => {
      setStatus('online');
    };
    const handleOffline = () => {
      setStatus('offline');
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  return status;
}

interface DocumentWithFullscreen extends HTMLDocument {
  mozFullScreenElement?: Element;
  msFullscreenElement?: Element;
  webkitFullscreenElement?: Element;
  msExitFullscreen?: () => void;
  mozCancelFullScreen?: () => void;
  webkitExitFullscreen?: () => void;
}

export function isFullScreenSupported(): boolean {
  const doc = document as DocumentWithFullscreen;
  return !!(
    doc.msExitFullscreen ||
    doc.mozCancelFullScreen ||
    doc.webkitExitFullscreen
  );
}

export function isFullScreen(): boolean {
  const doc = document as DocumentWithFullscreen;
  return !!(
    doc.fullscreenElement ||
    doc.mozFullScreenElement ||
    doc.webkitFullscreenElement ||
    doc.msFullscreenElement
  );
}

interface DocumentElementWithFullscreen extends HTMLElement {
  msRequestFullscreen?: () => void;
  mozRequestFullScreen?: () => void;
  webkitRequestFullscreen?: () => void;
}

export function requestFullScreen(element: DocumentElementWithFullscreen) {
  if (element.requestFullscreen) {
    void element.requestFullscreen();
  } else if (element.msRequestFullscreen) {
    element.msRequestFullscreen();
  } else if (element.webkitRequestFullscreen) {
    element.webkitRequestFullscreen();
  } else if (element.mozRequestFullScreen) {
    element.mozRequestFullScreen();
  }
}

export function exitFullScreen() {
  if (!isFullScreen()) {
    return;
  }

  const doc = document as DocumentWithFullscreen;
  if (doc.exitFullscreen) {
    void doc.exitFullscreen();
  } else if (doc.msExitFullscreen) {
    doc.msExitFullscreen();
  } else if (doc.webkitExitFullscreen) {
    doc.webkitExitFullscreen();
  } else if (doc.mozCancelFullScreen) {
    doc.mozCancelFullScreen();
  }
}

export function toggleFullScreen(): void {
  if (!isFullScreen()) {
    requestFullScreen(document.documentElement);
  } else {
    exitFullScreen();
  }
}

export function isChromium() {
  // For e2e tests
  if ('Cypress' in window) {
    return true;
  }

  return 'chrome' in window;
}

export function useMutationObserver(
  targetNode: HTMLElement | null,
  callback: (mutations: MutationRecord[]) => void,
  config: MutationObserverInit = {},
) {
  const configMemo = useDeepEqualMemo(() => config, [config]);
  const callbackHandler = useEventHandler(callback);

  useEffect(() => {
    if (!targetNode) {
      return;
    }

    const observer = new MutationObserver(callbackHandler);
    observer.observe(targetNode, configMemo);

    return () => {
      observer.disconnect();
    };
  }, [configMemo, targetNode, callbackHandler]);
}
