import { Card, CardContent, Typography } from '@material-ui/core';
import { AttachFile } from '@material-ui/icons';
import { Inline, Stack, useUID } from '@superdispatch/ui';
import { FileListItem } from '@superdispatch/ui-lab';
import { useEffect, useMemo, useState } from 'react';
import { FileDropZone } from 'shared/form/FileDropZone';
import { getFileHash } from 'shared/utils/FileUtils';
import { LoadAttachmentDeleteDialog } from '../core/LoadAttachmentDeleteDialog';
import { AddAttachmentInput, useAddAttachment } from '../data/AttachmentsAPI';
import { formatLoadStatus, LoadAttachmentDTO, LoadDTO } from '../data/LoadDTO';
import { trackLoadsEvent } from '../data/LoadsAnalytics';
import { useLoadStageFromBackURL } from './ViewLoadHeaderActions';

interface AddLoadAttachmentListItemProps {
  file: File;
  loadGUID: string;
  canDelete: boolean;
  onDelete: () => void;
  onSuccess: () => void;
}

function AddLoadAttachmentListItem({
  file,
  loadGUID,
  canDelete,
  onDelete,
  onSuccess,
}: AddLoadAttachmentListItemProps) {
  const { status, mutate } = useAddAttachment({ onSuccess });
  const input = useMemo<AddAttachmentInput>(
    () => ({ file, loadGUID }),
    [file, loadGUID],
  );

  useEffect(() => {
    mutate(input);
  }, [input, mutate]);

  return (
    <FileListItem
      status={status}
      name={file.name}
      canDelete={canDelete}
      onDelete={onDelete}
      onRetry={() => {
        mutate(input);
      }}
    />
  );
}

interface FileState {
  file: File;
  url?: string;
}

interface ViewLoadAttachmentProps {
  load: LoadDTO;
}

export function ViewLoadAttachments({ load }: ViewLoadAttachmentProps) {
  const uid = useUID();
  const [files, setFiles] = useState<FileState[]>([]);
  const [attachmentToDelete, setAttachmentToDelete] =
    useState<LoadAttachmentDTO>();
  const loadStage = useLoadStageFromBackURL();

  useEffect(() => {
    setFiles((prev) =>
      prev.filter(({ url }) =>
        load.attachments?.some((attachment) => url === attachment.file_url),
      ),
    );
  }, [load.attachments]);

  return (
    <Card aria-labelledby={uid}>
      <LoadAttachmentDeleteDialog
        attachment={attachmentToDelete}
        onClose={() => {
          setAttachmentToDelete(undefined);
        }}
        onSuccess={() => {
          trackLoadsEvent({
            name: 'Carrier Edited Load',
            utm_medium: loadStage,
            utm_content: 'View Page',
            load_status: formatLoadStatus(load.status),
            is_created_by_broker: !!load.is_created_by_broker,
            attachments: 'Edited',
          });
        }}
      />

      <CardContent aria-label="Attachment Card">
        <Stack space="small">
          <Inline verticalAlign="center" space="xsmall">
            <AttachFile />
            <Typography variant="h3" id={uid}>
              Attachments
            </Typography>
          </Inline>

          <Stack space="small">
            <FileDropZone
              onDropAccepted={(acceptedFiles) => {
                setFiles((prev) => [
                  ...prev,
                  ...acceptedFiles.map((file) => ({ file })),
                ]);
              }}
            />

            <Stack space="xsmall">
              {files.map(({ file }) => (
                <AddLoadAttachmentListItem
                  file={file}
                  loadGUID={load.guid}
                  key={getFileHash(file)}
                  onDelete={() => {
                    setFiles((prev) =>
                      prev.filter((item) => item.file !== file),
                    );
                  }}
                  canDelete={true}
                  onSuccess={() => {
                    trackLoadsEvent({
                      name: 'Carrier Edited Load',
                      utm_medium: loadStage,
                      utm_content: 'View Page',
                      load_status: formatLoadStatus(load.status),
                      is_created_by_broker: !!load.is_created_by_broker,
                      attachments: load.attachments?.length
                        ? 'Edited'
                        : 'Added',
                    });
                  }}
                />
              ))}

              {load.attachments?.map((attachment) => (
                <FileListItem
                  key={attachment.guid}
                  name={attachment.name}
                  url={attachment.file_url}
                  canDelete={!attachment.is_created_by_broker}
                  onDelete={() => {
                    setAttachmentToDelete(attachment);
                  }}
                />
              ))}
            </Stack>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
}
