import {
  MutationFunction,
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from 'react-query';
import { APIResponse } from 'shared/api/APIClient';

export type APIMutationFunction<TInput, TData = unknown> = MutationFunction<
  APIResponse<TData>,
  TInput
>;

export type APIMutationOptions<
  TInput = void,
  TData = unknown,
  TContext = unknown,
> = Omit<
  UseMutationOptions<APIResponse<TData>, Error, TInput, TContext>,
  'mutationKey'
>;

export type APIMutationResult<
  TInput = void,
  TData = unknown,
  TContext = unknown,
> = UseMutationResult<APIResponse<TData>, Error, TInput, TContext>;

export function useAPIMutation<TInput, TData = unknown, TContext = unknown>(
  mutation: APIMutationFunction<TInput, TData>,
  options?: APIMutationOptions<TInput, TData, TContext>,
): APIMutationResult<TInput, TData, TContext> {
  return useMutation(mutation, options);
}
