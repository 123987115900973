import { hasOnlyDigits } from 'shared/utils/StringUtils';
import {
  yupDateString,
  yupEnum,
  yupNumber,
  yupObject,
  yupString,
} from 'shared/utils/YupUtils';
import { InferType } from 'yup';

export type CarrierEditFmcsaPhoneDTO = InferType<
  typeof carrierEditFmcsaPhoneSchema
>;
export const carrierEditFmcsaPhoneSchema = yupObject({
  phone_number: yupString().required(),
});

export type CarrierFmcsaPhoneDTO = InferType<typeof carrierFmcsaPhoneSchema>;
export const carrierFmcsaPhoneSchema = yupObject({
  number: yupString(),
  source: yupEnum(['manual', 'fmcsa_database']),
});

export type VerificationCodeRequestDTO = InferType<
  typeof verificationCodeRequestSchema
>;
export const verificationCodeRequestSchema = yupObject({
  attempt_count: yupNumber(),
  attempt_datetime: yupDateString('DateTimeISO'),
});

export type VerificationCodeSubmittingDTO = InferType<
  typeof verificationCodeSubmittingSchema
>;
export const verificationCodeSubmittingSchema = yupObject({
  code: yupString()
    .required()
    .length(6, 'Your code must be composed of 6 digits')
    .test('only digits', 'Your code must contain only digits', hasOnlyDigits),
});
