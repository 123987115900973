import { parseDate } from '@superdispatch/dates';
import { formatPaymentTerm } from '@superdispatch/sdk';
import { ElementVisibility } from '@superdispatch/ui';
import { useEffect } from 'react';
import { trackEvent } from 'shared/helpers/Analytics';
import { useDebouncedValue } from 'shared/helpers/ReactHelpers';
import { joinStrings } from 'shared/utils/StringUtils';
import { LoadSuggestionDTO } from './LoadSuggestionDTO';

function formatBooleanValue(
  value: boolean | null | undefined,
): 'yes' | 'no' | undefined {
  if (value == null) {
    return undefined;
  }

  return value ? 'yes' : 'no';
}

function formatSuggestedLoadEventProps(load: LoadSuggestionDTO) {
  const postedToLoadboardAt = parseDate(load.posted_to_loadboard_at, {
    format: 'DateTimeISO',
  });

  return {
    payment: formatPaymentTerm(load.payment.terms, {
      fallback: load.payment.terms,
      short: true,
    }),
    guid: load.guid,
    price: load.price,
    utm_medium: 'Suggested Load',
    'delivery state': load.delivery.venue.state,
    'delivery state and city': joinStrings(
      ', ',
      load.delivery.venue.state,
      load.delivery.venue.city,
    ),
    'is exclusive': formatBooleanValue(load.is_exclusive),
    'pickup state': load.pickup.venue.state,
    'pickup state and city': joinStrings(
      ', ',
      load.pickup.venue.state,
      load.pickup.venue.city,
    ),
    'posted date': postedToLoadboardAt.toUTC().toISO(),
    'posting guid': load.posting_guid,
    'price per mile': load.price_per_mile,
    'shipper guid': load.shipper.guid,
  };
}

const viewedLoads = new Set();

export function useTrackViewedLoad({
  loadVisibilityState,
  load,
}: {
  loadVisibilityState: ElementVisibility;
  load: LoadSuggestionDTO;
}) {
  const debouncedVisibilityState = useDebouncedValue(loadVisibilityState, 600);

  useEffect(() => {
    if (debouncedVisibilityState === 'visible' && !viewedLoads.has(load.guid)) {
      trackEvent('SLB: Viewed Load', formatSuggestedLoadEventProps(load));
      viewedLoads.add(load.guid);
    }
  }, [debouncedVisibilityState, load]);
}
