import { Color } from '@superdispatch/ui';
import { createSvgIcon } from 'shared/icons/IconUtils';

export const VerifiedIcon = createSvgIcon(
  'VerifiedIcon',
  <>
    <circle cx="16" cy="16" r="16" fill={Color.Green400} />
    <path
      d="M13.0847 19.4676L9.29164 15.736L8 16.9978L13.0847 22L24 11.2617L22.7175 10L13.0847 19.4676Z"
      fill={Color.White}
    />
  </>,
  {
    viewBox: '0 0 32 32',
    style: { height: '32px', width: '32px' },
  },
);
