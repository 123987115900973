import { Stack } from '@superdispatch/ui';
import { Box, Button, TextBox } from '@superdispatch/ui-lab';
import { trackEvent } from 'shared/helpers/Analytics';
import { CHROME_EXTENSION_URL } from 'shared/helpers/ExtensionHelper';
import { LocalStore, useLocalStore } from 'shared/helpers/Store';
import { openExternalURL } from 'shared/helpers/URLHelpers';
import ExtensionExperimentIllustration from './assets/extension-experiment-illustration.svg';

const CHROME_EXTENSION_REMINDER_SEEN_STORAGE_KEY =
  'chrome_extension_reminder_seen_storage_key';

export function useIsChromeExtensionReminderSeen() {
  const isChromeExtensionReminderSeen = useLocalStore(
    CHROME_EXTENSION_REMINDER_SEEN_STORAGE_KEY,
  );

  return isChromeExtensionReminderSeen === 'true';
}

export function ChromeExtensionReminderPage() {
  return (
    <Box maxWidth="360px" margin="auto">
      <Stack space="medium" align="center">
        <img width={260} height={80} src={ExtensionExperimentIllustration} />

        <Stack>
          <TextBox variant="heading-3" align="center">
            Import loads from Central Dispatch{' '}
          </TextBox>
          <TextBox variant="body" align="center">
            Manage all your loads in one place by importing them using our
            chrome extension
          </TextBox>
        </Stack>

        <Button
          variant="primary"
          size="large"
          onClick={() => {
            trackEvent('Carrier Clicked Install for Chrome', {
              utm_medium: 'empty_state',
            });

            LocalStore.set(CHROME_EXTENSION_REMINDER_SEEN_STORAGE_KEY, 'true');
            openExternalURL(CHROME_EXTENSION_URL, { target: '_blank' });
            window.location.reload();
          }}
        >
          Add to Chrome for FREE
        </Button>
        <Button
          variant="text"
          size="large"
          onClick={() => {
            trackEvent('Carrier Clicked No, Thanks', {
              utm_medium: 'empty_state',
            });

            LocalStore.set(CHROME_EXTENSION_REMINDER_SEEN_STORAGE_KEY, 'true');
            window.location.reload();
          }}
        >
          No, Thanks
        </Button>
      </Stack>
    </Box>
  );
}
