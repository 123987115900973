import { IconButton, Link, Typography } from '@material-ui/core';
import { ArrowForward } from '@material-ui/icons';
import { Inline } from '@superdispatch/ui';
import { useEffect, useState } from 'react';
import { trackEvent } from 'shared/helpers/Analytics';
import { SuperPayIcon } from 'shared/icons/SuperPayLabel';
import { Source } from 'shared/modules/superpay/SuperPayRequestShipperContent';
import { SuperPayRequestShipperDialog } from 'shared/modules/superpay/SuperPayRequestShipperDialog';
import { SuperPayRequestShipperGradientBox } from 'shared/modules/superpay/SuperPayRequestShipperGradientBox';
import { useCarrierSettings } from 'shared/settings/CarrierSettingsAPI';
import { useFlag } from 'shared/settings/FeatureToggles';

export function SuperPayYouGotPaidBanner({ source }: { source: Source }) {
  const { data: settings } = useCarrierSettings();
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const isCarrierInvitesToSuperPayEnabled = useFlag(
    'payments_carrier_invites_to_superpay',
  );

  const isCorrectTime =
    settings?.superpay.show_invite_banner_until &&
    new Date(settings.superpay.show_invite_banner_until).getTime() >
      new Date().getTime();

  const hasBanner =
    isCarrierInvitesToSuperPayEnabled &&
    settings?.superpay.is_invite_available &&
    isCorrectTime;

  useEffect(() => {
    if (hasBanner) {
      trackEvent('Carrier Viewed Request SuperPay Banner', {
        utm_medium: source,
      });
    }
  }, [hasBanner, source]);

  if (!hasBanner) {
    return null;
  }

  return (
    <>
      <Link
        onClick={(e) => {
          e.preventDefault();
          setIsOpenDialog(true);
        }}
        color="inherit"
        href="#"
      >
        <SuperPayRequestShipperGradientBox>
          <Inline verticalAlign="center">
            <Typography variant="body1">You got SuperPaid!</Typography>
            <Typography>
              Streamline even more payments by requesting other shippers you
              work with to use
            </Typography>
            <Inline space="xxsmall" verticalAlign="center">
              <SuperPayIcon />
              <IconButton
                size="small"
                edge="start"
                onClick={() => {
                  setIsOpenDialog(true);
                }}
              >
                <ArrowForward fontSize="small" color="action" />
              </IconButton>
            </Inline>
          </Inline>
        </SuperPayRequestShipperGradientBox>
      </Link>
      <SuperPayRequestShipperDialog
        source={source}
        onClose={() => {
          setIsOpenDialog(false);
        }}
        isOpen={isOpenDialog}
      />
    </>
  );
}
