import { useWebSocketURL } from './APISocket';
import { WebSocketState } from './WebSocket';
import { WorkerWebSocketDispatch } from './WebSocketWorker';
import { useWebSocketWorker } from './WebSocketWorkerClient';

export function useAPISocketWorker(
  url: null | string,
  fn: WorkerWebSocketDispatch,
): WebSocketState {
  const webSocketURL = useWebSocketURL(url);

  return useWebSocketWorker(webSocketURL, fn);
}
