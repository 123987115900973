import {
  IconButton,
  Link,
  Tooltip as MuiTooltip,
  Typography,
  withStyles,
} from '@material-ui/core';
import { Done, FileCopy } from '@material-ui/icons';
import { Color } from '@superdispatch/ui';
import { Box, TextBox } from '@superdispatch/ui-lab';
import { parseURITemplate } from '@superdispatch/uri';
import { Link as RouterLink } from 'react-router-dom';
import { useClipboard } from 'shared/helpers/DomHelpers';
import styled from 'styled-components';
import { LoadDTO } from '../data/LoadDTO';
import { trackLoadsEvent } from '../data/LoadsAnalytics';

interface LoadPageLoadIdProps {
  load: LoadDTO;
}

const CopyButton = styled(IconButton)`
  padding: 8px 12px;
  border-radius: 0;
  background: none !important;
`;

//Override tooltip styles is only way to change with withStyles, need to update from #web-ui
const Tooltip = withStyles({
  tooltip: {
    padding: 0,
    marginBottom: 5,
  },
})(MuiTooltip);

export function LoadsPageLoadId({ load }: LoadPageLoadIdProps) {
  const { copy, status, reset } = useClipboard();

  const currentURL = window.location.pathname + window.location.search;

  return (
    <Tooltip
      interactive={true}
      onClose={() => {
        // Reset copy status after tooltip is closed, there was issue with resetting status before tooltip is closed
        setTimeout(reset, 200);
      }}
      placement="top"
      title={
        load.number && (
          <CopyButton
            disableFocusRipple={true}
            disableRipple={true}
            onClick={() => {
              if (load.number && status !== 'copied') {
                copy(load.number);
                trackLoadsEvent({
                  name: 'Carrier Copied Load ID',
                });
              }
            }}
          >
            <Box marginRight="xxsmall">
              {status === 'copied' ? (
                <Done htmlColor={Color.White} fontSize="small" />
              ) : (
                <FileCopy htmlColor={Color.White} fontSize="small" />
              )}
            </Box>
            <TextBox color="white">
              {status === 'copied' ? 'Copied' : 'Copy ID'}
            </TextBox>
          </CopyButton>
        )
      }
    >
      <Link
        data-intercom-target="loads-page-load-id"
        component={RouterLink}
        to={parseURITemplate('/loads/{guid}{?next,stage}', {
          guid: load.guid,
          next: currentURL,
          stage: load.stage,
        })}
        onClick={() => {
          trackLoadsEvent({
            name: 'CTMS: View Load Page Opened',
            referrer_button: 'load_id',
          });
        }}
      >
        <Typography variant="h3" component="div">
          {load.number || "Load doesn't have ID"}
        </Typography>
      </Link>
    </Tooltip>
  );
}
