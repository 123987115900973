import { requestCarrierAPI } from 'shared/api/APIClient';
import { APIQueryResult, useAPIQuery } from 'shared/api/APIQuery';
import {
  VerifiedCarrierApplicationStatusDTO,
  verifiedCarrierApplicationStatusSchema,
} from './VerifiedCarrierApplicationStatusDTO';

export function useVerifiedCarrierApplicationStatus(): APIQueryResult<VerifiedCarrierApplicationStatusDTO> {
  return useAPIQuery(
    ['verified-carrier-application', 'status'],
    () =>
      requestCarrierAPI(
        'GET /internal/web/carrier/verified-carrier-application/status/',
      ),
    { schema: verifiedCarrierApplicationStatusSchema },
  );
}
