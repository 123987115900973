import {
  FormikTextField,
  SuspendedFormikPhoneField,
} from '@superdispatch/forms';
import { Stack } from '@superdispatch/ui';
import { Alert, Box } from '@superdispatch/ui-lab';
import { useAppFormik } from 'shared/form/AppFormik';
import {
  FormikDrawer,
  FormikDrawerActions,
  FormikDrawerContent,
} from 'shared/form/FormikDrawer';
import { WarningIcon } from 'shared/icons/WarningIcon';
import { useDispatcherProfile } from 'shared/modules/dispatcher/DispatcherAPI';
import { performNavigation } from 'shared/routing/NavigationBlock';
import { useMatch } from 'shared/routing/react-router-6';
import { useFlag } from 'shared/settings/FeatureToggles';
import { useDispatcherProfileAPI } from '../data/DispatchersAPI';
import { dispatcherEditSchema } from '../data/DispatchersDTO';

interface DispatcherEditDrawerContentProps {
  onClose: () => void;
}

function DispatcherEditDrawerContent({
  onClose,
}: DispatcherEditDrawerContentProps) {
  const { data: profile } = useDispatcherProfile();
  const { updateDispatcherProfile } = useDispatcherProfileAPI();
  const isChangeDispatcherProfileMessageEnabled = useFlag(
    'change_dispatcher_profile_message',
  );

  const formik = useAppFormik({
    initialValues: profile,
    validationSchema: dispatcherEditSchema,
    onSubmit(values) {
      return updateDispatcherProfile(values);
    },
    onSubmitSuccess() {
      performNavigation(onClose);
    },
    getSuccessMessage({ user_message }) {
      return user_message || 'Dispatcher Profile Changed';
    },
  });

  return (
    <FormikDrawerContent
      formik={formik}
      title="Edit Profile"
      onClose={onClose}
      disableAutoClose={true}
      actions={<FormikDrawerActions />}
    >
      <Stack space="small">
        {isChangeDispatcherProfileMessageEnabled && !!profile?.driver && (
          <Box maxWidth="368px">
            <Alert icon={<WarningIcon />} severity="caution">
              You have both driver and dispatcher roles. Your profile
              information for both roles will be updated.
            </Alert>
          </Box>
        )}

        <FormikTextField label="Full Name" name="name" fullWidth={true} />

        <SuspendedFormikPhoneField
          name="phone"
          label="Phone Number"
          fullWidth={true}
        />

        <FormikTextField
          label="Email"
          name="email"
          fullWidth={true}
          helperText="For logging in and receiving notifications"
        />
      </Stack>
    </FormikDrawerContent>
  );
}

interface DispatcherEditDrawerProps extends DispatcherEditDrawerContentProps {
  path: string;
}

export function DispatcherEditDrawer({
  path,
  onClose,
}: DispatcherEditDrawerProps) {
  const match = useMatch(path);

  return (
    <FormikDrawer open={!!match} onClose={onClose}>
      <DispatcherEditDrawerContent onClose={onClose} />
    </FormikDrawer>
  );
}
