import { CheckboxField, CheckboxFieldProps } from '@superdispatch/ui';
import { useField } from 'formik';
import { createContext, ReactNode, useContext, useMemo } from 'react';

interface FormikCheckboxArrayContext {
  getValue: (name: string) => boolean;
  setValue: (name: string, checked: boolean) => void;
}

const Context = createContext<FormikCheckboxArrayContext | null>(null);

interface FormikCheckboxArrayGroupProps {
  children: ReactNode;
  name: string;
}

export function FormikCheckboxArrayGroup({
  name: nameProp,
  children,
}: FormikCheckboxArrayGroupProps) {
  const [{ value = [] }, _meta, { setValue }] = useField<string[]>(nameProp);

  const context = useMemo<FormikCheckboxArrayContext>(
    () => ({
      getValue: (name) => {
        return value.includes(name);
      },
      setValue: (name, checked) => {
        const set = new Set(value);

        if (checked) set.add(name);
        else set.delete(name);

        void setValue(Array.from(set));
      },
    }),
    [setValue, value],
  );

  return <Context.Provider value={context}>{children}</Context.Provider>;
}

export function FormikCheckboxArrayField({
  name,
  ...props
}: Omit<CheckboxFieldProps, 'onChange' | 'value'> & { name: string }) {
  const context = useContext(Context);
  if (!context) {
    throw Error('Cannot find Provider for FormikCheckboxArrayGroup');
  }
  const { getValue, setValue } = context;

  return (
    <CheckboxField
      {...props}
      name={name}
      checked={getValue(name)}
      onChange={(_, checked) => {
        setValue(name, checked);
      }}
    />
  );
}
