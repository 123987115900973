import { Divider } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { Column, Columns, Stack } from '@superdispatch/ui';
import { Box, Button } from '@superdispatch/ui-lab';
import styled from 'styled-components';

const StyledBox = styled(Box)`
  display: flex;
  align-items: flex-end;
  width: 100%;
  justify-content: end;
`;
const StyledButton = styled(Button)`
  height: 40px;
`;

export function PlanUpdateDrawerLoadingContent() {
  return (
    <Box width="432px">
      <Stack space="large">
        <Stack space="small">
          <Box
            padding="small"
            paddingLeft="xlarge"
            borderRadius="small"
            backgroundColor="Blue50"
          >
            <Skeleton />
            <Skeleton width="80%" variant="text" />
            <Skeleton width="80%" variant="text" />
            <Skeleton width="60%" variant="text" />
          </Box>
          <Box
            padding="small"
            paddingLeft="large"
            borderRadius="small"
            backgroundColor="Silver200"
          >
            <Stack space="small">
              <Stack space="none">
                <Skeleton width="75%" variant="text" />
                <Skeleton width="82%" variant="text" />
                <Skeleton width="79%" variant="text" />
              </Stack>
              <Divider />
              <Columns space="small">
                <Column width="3/5">
                  <StyledBox>
                    <Skeleton width="60%" />
                  </StyledBox>
                </Column>
                <Column width="2/5">
                  <Skeleton width="40%" />
                </Column>
              </Columns>
            </Stack>
          </Box>
        </Stack>

        <Box paddingBottom="small">
          <Stack space="large">
            <Stack space="xsmall">
              <Skeleton width="28%" />
              <Columns space="medium">
                <Column width="1/4">
                  <Skeleton />
                </Column>
                <Column width="3/4">
                  <Skeleton width="15%" />
                </Column>
              </Columns>
              <Columns space="small">
                <Column width="1/4">
                  <Skeleton />
                </Column>
                <Column width="3/4">
                  <Skeleton width="10%" />
                </Column>
              </Columns>
            </Stack>

            <Stack space="xsmall">
              <Skeleton width="35%" />
              <Skeleton width="70%" />
              <Skeleton width="30%" />
            </Stack>

            <Stack space="small">
              <Stack space="xsmall">
                <Columns space="xxlarge">
                  <Column width="4/5">
                    <Skeleton width="60%" />
                  </Column>
                  <Column width="1/5">
                    <Skeleton />
                  </Column>
                </Columns>
                <Skeleton width="55%" />
                <Skeleton width="40%" />
              </Stack>
              <Stack space="xsmall">
                <Columns space="medium">
                  <Column width="4/5">
                    <Skeleton width="70%" />
                  </Column>
                  <Column width="1/5">
                    <Skeleton />
                  </Column>
                </Columns>

                <Columns space="xlarge">
                  <Column width="4/5" />
                  <Column width="1/5">
                    <Skeleton />
                  </Column>
                </Columns>
              </Stack>

              <Columns space="large">
                <Column width="4/5">
                  <Skeleton width="70%" />
                </Column>
                <Column width="1/5">
                  <Skeleton />
                </Column>
              </Columns>
            </Stack>

            <StyledButton fullWidth={true} size="large" />
          </Stack>
        </Box>
      </Stack>
    </Box>
  );
}
