import {
  FormattedRelativeTimeOptions,
  FormattedRelativeTimeProps,
  NullableDateInput,
  parseDate,
  toPrimitiveDateInput,
  useDateConfig,
  useFormattedRelativeTime,
} from '@superdispatch/dates';
import { renderChildren } from '@superdispatch/ui';
import { useEffect, useMemo, useState } from 'react';

export function useFormattedRelativeTimeToNow(
  input: NullableDateInput,
  { format, ...options }: Omit<FormattedRelativeTimeOptions, 'base'> = {},
): string {
  const [now, setNow] = useState(Date.now);
  const config = useDateConfig({ format });
  const primitiveInput = toPrimitiveDateInput(input);
  const date = useMemo(
    () => parseDate(primitiveInput, config),
    [config, primitiveInput],
  );

  useEffect(() => {
    const diffInSeconds = Math.max(
      // Do not update less then once per hour.
      3600,
      Math.ceil(
        // Round up seconds.
        Math.abs(
          // Get difference in seconds.
          now - date.toMillis() / 1000,
        ),
      ),
    );

    const timeoutID = setTimeout(() => {
      setNow(Date.now);
    }, diffInSeconds * 1000);

    return () => {
      clearTimeout(timeoutID);
    };
  }, [now, date]);

  return useFormattedRelativeTime(primitiveInput, {
    ...options,
    format,
    base: now,
  });
}

export type FormattedRelativeTimeToNowProps = Omit<
  FormattedRelativeTimeProps,
  'compare'
>;

export function FormattedRelativeTimeToNow({
  date,
  fallback = 'Invalid Date',
  ...options
}: FormattedRelativeTimeToNowProps) {
  const formatted = useFormattedRelativeTimeToNow(date, {
    ...options,
    fallback: '',
  });

  return renderChildren(formatted || fallback);
}
