import { useEffect } from 'react';
import {
  isLoadboardDesktop,
  isWebView,
} from 'shared/constants/AppTypeConstants';
import { joinStrings } from 'shared/utils/StringUtils';

const BASE_TITLE = isLoadboardDesktop ? 'Super Loadboard' : 'Carrier TMS';

export function useDocumentTitle(title: string | undefined): void {
  useEffect(() => {
    if (!title) return;
    const previousTitle = document.title;

    document.title = joinStrings(
      ' - ',
      title,
      !isWebView && BASE_TITLE,
      import.meta.env.MODE === 'development' && 'DEV',
    );

    return () => {
      document.title = previousTitle;
    };
  }, [title]);
}

interface DocumentTitleProps {
  title: string | undefined;
}

export function DocumentTitle({ title }: DocumentTitleProps) {
  useDocumentTitle(title);

  return null;
}
