import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { TEN_MINUTES } from 'shared/constants/NumberConstants';
import { trackEvent } from 'shared/helpers/Analytics';
import { useVisibilityState } from 'shared/helpers/BrowserHelpers';
import { openExternalURL } from 'shared/helpers/URLHelpers';
import { requestBuildInfo } from './data/AppVersionApi';

const APP_NAME = import.meta.env.VITE_APP_NAME.toUpperCase();

function useForceRefreshOnNavigation(
  shouldForceRefresh: boolean,
  buildInfo?: AppBuildInfo,
) {
  const history = useHistory();

  useEffect(() => {
    //  https://github.com/remix-run/history/pull/614#issuecomment-908367092
    const unlisten = history.listen((location) => {
      if (shouldForceRefresh) {
        trackEvent(
          'Force Refreshed On Navigation',
          { app_name: APP_NAME, ...buildInfo },
          () => {
            openExternalURL(history.createHref(location));
          },
        );
      }
    });

    return unlisten;
  }, [buildInfo, history, shouldForceRefresh]);
}

interface AppBuildInfo {
  created_at: string;
}

export function AppVersionChecker() {
  const [currentBuildInfo, setCurrentBuildInfo] = useState<AppBuildInfo>();
  const [shouldForceRefresh, setShouldForceRefresh] = useState(false);
  const visibilityStatus = useVisibilityState();

  useQuery<AppBuildInfo>('version', requestBuildInfo, {
    refetchInterval: visibilityStatus === 'visible' ? TEN_MINUTES : false,
    onSuccess(response) {
      if (currentBuildInfo) {
        setShouldForceRefresh(
          currentBuildInfo.created_at !== response.created_at,
        );
      } else {
        setCurrentBuildInfo(response);
      }
    },
  });

  useForceRefreshOnNavigation(shouldForceRefresh, currentBuildInfo);

  return null;
}
