import { trim } from 'shared/helpers/CommonHelpers';

export type Printable = null | string | number | boolean | undefined;

export function compactStringArray(values: Printable[]): string[] {
  return values.reduce<string[]>((acc, value) => {
    if (value != null && value !== false) {
      const text = trim(value);

      if (text) {
        acc.push(text);
      }
    }

    return acc;
  }, []);
}

export function joinStrings(separator: string, ...args: Printable[]): string {
  return compactStringArray(args).join(separator);
}

export const POSSIBLE_UUID_PATTERN =
  /^[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}$/;
export function isPossibleUUID(input: unknown): boolean {
  return typeof input == 'string' && POSSIBLE_UUID_PATTERN.test(input);
}

const ONLY_DIGITS_PATTERN = /^\d+$/;
export function hasOnlyDigits(input: unknown): boolean {
  return typeof input == 'string' && ONLY_DIGITS_PATTERN.test(input);
}

export function capitalize(text: string) {
  return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
}
