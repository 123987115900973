import { atom, useAtom } from 'jotai';
import { debounce } from 'lodash-es';
import { SetStateAction, useCallback, useEffect, useMemo } from 'react';
import { startIntercomTour } from './Intercom';
import { LocalStore, useLocalStore } from './Store';

const ACTIVE_TOUR_STORAGE_KEY = 'intercom-active-tour-id';
const TOUR_QUEUE_STORAGE_KEY = 'intercom-tour-queue';
const INTERCOM_POSITIONER_TREE_SELECTOR = '#intercom-positioner-tree';
const DONE_BUTTON_SELECTOR = '[aria-label=Done][role=button]';
const CLOSE_BUTTON_SELECTOR = '[aria-label=Close][role=button]';
const SKIP_BUTTON_SELECTOR = '[aria-label=Skip][role=button]';

const tourActiveStateAtom = atom<boolean>(false);

function useIntercomTourManagerState() {
  const activeTourId = useLocalStore<string | null>(
    ACTIVE_TOUR_STORAGE_KEY,
    null,
  );
  const tourQueueStorageValue = useLocalStore<string>(
    TOUR_QUEUE_STORAGE_KEY,
    '[]',
  );
  const [isActive, setIsActive] = useAtom(tourActiveStateAtom);

  return useMemo(() => {
    const tourQueue = JSON.parse(tourQueueStorageValue) as string[];

    const setTourQueue = (value: SetStateAction<string[]>) => {
      LocalStore.set(
        TOUR_QUEUE_STORAGE_KEY,
        JSON.stringify(typeof value === 'function' ? value(tourQueue) : value),
      );
    };

    return {
      tourQueue,
      activeTourId,
      setTourQueue,
      setActiveTourId: (value: SetStateAction<string | null>) => {
        LocalStore.set(ACTIVE_TOUR_STORAGE_KEY, value);
      },
      isActive,
      setIsActive,
    };
  }, [activeTourId, isActive, setIsActive, tourQueueStorageValue]);
}

interface IntercomTourManagerProps {
  onCompleted?: (activeTourId: string | null) => void;
  onClose?: (activeTourId: string | null) => void;
}

let lastTourId: string | null = null;

export function useIntercomTourManager({
  onCompleted,
  onClose,
}: IntercomTourManagerProps | undefined = {}) {
  const {
    activeTourId,
    isActive,
    tourQueue,
    setActiveTourId,
    setIsActive,
    setTourQueue,
  } = useIntercomTourManagerState();

  /**
   * Use this function inside useEffect to start a tour on page load
   */
  const startTour = useCallback(
    (tourId: string) => {
      setTourQueue((prevQueue: string[]) => {
        return prevQueue.includes(tourId) ? prevQueue : [...prevQueue, tourId];
      });
    },
    [setTourQueue],
  );

  const handleCompleted = useCallback(() => {
    onCompleted?.(activeTourId);
    setActiveTourId(null);
    lastTourId = null;
  }, [onCompleted, activeTourId, setActiveTourId]);

  const handleClose = useCallback(() => {
    onClose?.(activeTourId);
    setActiveTourId(null);
    lastTourId = null;
  }, [onClose, activeTourId, setActiveTourId]);

  const handleTourIframe = useCallback(
    (tourIframe: HTMLIFrameElement | null) => {
      const doneButton =
        tourIframe?.contentWindow?.document.querySelector(DONE_BUTTON_SELECTOR);
      const closeButton = tourIframe?.contentWindow?.document.querySelector(
        CLOSE_BUTTON_SELECTOR,
      );
      const skipButton =
        tourIframe?.contentWindow?.document.querySelector(SKIP_BUTTON_SELECTOR);

      doneButton?.addEventListener('click', handleCompleted);
      closeButton?.addEventListener('click', handleClose);
      skipButton?.addEventListener('click', handleClose);
    },
    [handleCompleted, handleClose],
  );

  useEffect(() => {
    const [tour] = tourQueue;
    if (!isActive && tour && tour !== lastTourId) {
      startIntercomTour(tour);
      setActiveTourId(tour);
      lastTourId = tour;
      setIsActive(true);
      setTourQueue(tourQueue.slice(1));
    }
  }, [isActive, setActiveTourId, setIsActive, setTourQueue, tourQueue]);

  useEffect(() => {
    const handleRemovedRecord = (removedRecord: MutationRecord | undefined) => {
      debounce(() => {
        const intercomPositionerTree = (
          removedRecord?.target as HTMLElement | null
        )?.querySelector(INTERCOM_POSITIONER_TREE_SELECTOR);
        if (!intercomPositionerTree) {
          setIsActive(false);
          setActiveTourId(null);
        }
      }, 3000)();
    };

    const callback: MutationCallback = (mutationList) => {
      for (const mutationRecord of mutationList) {
        const target = mutationRecord.target as HTMLElement;

        if (
          target.tagName === 'IFRAME' &&
          target.getAttribute('name') === 'intercom-tour-frame'
        ) {
          handleTourIframe(target as HTMLIFrameElement);
        }

        if (mutationRecord.addedNodes.length > 0) {
          Array.from(mutationRecord.addedNodes).forEach((node) => {
            if (
              node.nodeType === Node.ELEMENT_NODE &&
              (node as HTMLElement).getAttribute('id') ===
                'intercom-positioner-tree'
            ) {
              setIsActive(true);
            }
          });
        }

        if (mutationRecord.removedNodes.length > 0) {
          Array.from(mutationRecord.removedNodes).forEach((node) => {
            if (
              node.nodeType === Node.ELEMENT_NODE &&
              (node as HTMLElement).getAttribute('id') ===
                'intercom-positioner-tree'
            ) {
              handleRemovedRecord(mutationRecord);
            }
          });
        }
      }
    };

    const observer = new MutationObserver(callback);
    const config: MutationObserverInit = {
      attributes: true,
      childList: true,
      subtree: true,
    };

    observer.observe(document, config);

    return () => {
      observer.disconnect();
    };
  }, [handleTourIframe, setActiveTourId, setIsActive]);

  return { isActive, startTour };
}
