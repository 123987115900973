import { BaseTextFieldProps, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { FocusEvent } from 'react';
import { Geocoding } from 'shared/geo/GeoHelpers';
import { useGeoFieldProps } from 'shared/geo/MapboxGeoField';

export interface AddressAutofillProps
  extends Pick<
    BaseTextFieldProps,
    'label' | 'fullWidth' | 'error' | 'helperText' | 'disabled'
  > {
  value: string;
  onBlur: (value: string) => void;
  onChange: (value: string) => void;
  onGeocode: (value: Geocoding) => void;
}

export function AddressAutofill({
  value,
  onBlur,
  onChange,
  onGeocode,

  disabled,
  ...textFieldProps
}: AddressAutofillProps) {
  const [_extraProps, geoFieldProps] = useGeoFieldProps({
    types: ['place', 'region', 'postcode', 'address'],
    formatOptionLabel: (option) => option.place_name || '',
  });

  return (
    <Autocomplete
      {...geoFieldProps}
      freeSolo={true}
      multiple={false}
      selectOnFocus={true}
      disableClearable={true}
      value={value || ''}
      disabled={disabled}
      onBlur={(event: FocusEvent<HTMLInputElement>) => {
        onBlur(event.target.value);
      }}
      onChange={(_, nextValue) => {
        if (typeof nextValue == 'string') {
          onChange(nextValue);
        } else {
          onGeocode(nextValue);
        }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          {...textFieldProps}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password',
          }}
        />
      )}
    />
  );
}
