import { Tooltip, Typography } from '@material-ui/core';
import { QueryBuilder } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import {
  Color,
  Column,
  Columns,
  Inline,
  Tag,
  useSnackbarStack,
} from '@superdispatch/ui';
import { Button, TextBox } from '@superdispatch/ui-lab';
import { DateTime } from 'luxon';
import { useEffect, useMemo, useState } from 'react';
import { AccountOffIcon } from 'shared/icons';
import { PersonAddAlt } from 'shared/icons/PersonAddAlt';
import { DocumentTitle } from 'shared/layout/DocumentTitle';
import { DispatcherDTO } from 'shared/modules/dispatcher/DispatcherDTO';
import { useFlag } from 'shared/settings/FeatureToggles';
import { Countdown } from 'shared/ui/Countdown';
import { trackDispatchersEvent } from '../data/DispatchersAnalytics';
import {
  useReactivateDispatcher,
  useResendDispatcherInvite,
} from '../data/DispatchersAPI';
import { useDispatcherPageContext } from '../data/DispatchersProvider';
import { DispatcherInviteCancelDialog } from '../dispatcher-invitation/DispatcherInvitationCancelDialog';
import { DispatcherDeactivationDialog } from './DispatcherDeactivationDialog';
import { DispatcherDeletionDialog } from './DispatcherDeletionDialog';
import { DispatcherDetailsMenuButton } from './DispatcherDetailMenuButton';

export type DispatcherPageMutationDialogType = 'delete' | 'deactivate';

interface ResendDispatcherProps {
  guid: string;
  retryDate: DateTime;
}

export function DispatchersPageDetailsHeader() {
  const { addSnackbar } = useSnackbarStack();
  const shouldShowDispatcherDeactivation = useFlag(
    'dispatcher_management_deactivation',
  );
  const [currentModal, setCurrentModal] =
    useState<DispatcherPageMutationDialogType | null>(null);
  const { dispatcher, currentDispatcherGUID } = useDispatcherPageContext();
  const [isCancelInvite, setIsCancelInvite] = useState(false);

  const [resentDispatchers, setResentDispatchers] = useState<
    ResendDispatcherProps[]
  >([
    {
      guid: '',
      retryDate: DateTime.local(),
    },
  ]);
  const { data: currentDispatcher, isFetching: isDispatcherLoading } =
    dispatcher;

  const { mutate: resendInvite, isLoading } = useResendDispatcherInvite({
    onSuccess: () => {
      addSnackbar('Invite sent', { variant: 'success' });
      if (currentDispatcherGUID) {
        setResentDispatchers((prevResendDispatchers) => [
          ...prevResendDispatchers,
          {
            guid: currentDispatcherGUID,
            retryDate: DateTime.local().plus({ seconds: 32 }),
          },
        ]);
      }
    },
  });

  const { mutate: reactivateDispatcher, isLoading: isReactivating } =
    useReactivateDispatcher({
      onSuccess: () => {
        addSnackbar(`${currentDispatcher?.name || 'Dispacher'} reactivated`, {
          variant: 'success',
        });
        trackDispatchersEvent({ name: 'Carrier Reactivated Dispatcher' });
      },
    });

  const resentDispatcher = useMemo(() => {
    return resentDispatchers.find(
      (resend) => resend.guid === currentDispatcherGUID,
    );
  }, [resentDispatchers, currentDispatcherGUID]);

  const isInvitedDispatcher =
    currentDispatcher?.connection_status === 'pending' &&
    currentDispatcher.connection_type === 'invite';

  const dispatcherLabel = isInvitedDispatcher
    ? currentDispatcher.email
    : currentDispatcher?.name;

  useEffect(() => {
    if (
      currentDispatcherGUID &&
      resentDispatcher &&
      resentDispatcher.retryDate < DateTime.local()
    ) {
      setResentDispatchers((prevResendDispatchers) =>
        prevResendDispatchers.filter(
          (resend) => resend.guid !== currentDispatcherGUID,
        ),
      );
    }
  }, [resentDispatcher, currentDispatcherGUID]);

  if (!currentDispatcher) {
    return (
      <TextBox variant="heading-2">
        <Skeleton width="200px" />
      </TextBox>
    );
  }

  return (
    <>
      <DocumentTitle title={`${dispatcherLabel} - Dispatchers`} />

      <DispatcherInviteCancelDialog
        dispatcher={currentDispatcher}
        open={isCancelInvite}
        onClose={() => {
          setIsCancelInvite(false);
        }}
      />

      <Columns>
        <Column width="1/2">
          <Columns align="bottom" space="xsmall">
            <Column width="adaptive">
              <TextBox variant="heading-2" noWrap={true}>
                {dispatcherLabel}
              </TextBox>
            </Column>

            <Column width="content">
              <DispatcherStatus dispatcher={currentDispatcher} />
            </Column>
          </Columns>
        </Column>
        <Column>
          <Inline space="small" horizontalAlign="right">
            {currentDispatcher.connection_status === 'pending' &&
              currentDispatcher.connection_type === 'invite' && (
                <Countdown targetDate={resentDispatcher?.retryDate}>
                  {(timeLeft, isElapsed) => {
                    const seconds = timeLeft?.toFormat('s');

                    return (
                      <Tooltip
                        title={
                          !isElapsed &&
                          !!resentDispatcher?.guid &&
                          seconds !== '0' &&
                          !isDispatcherLoading &&
                          !isLoading ? (
                            <ResendInviteTooltip seconds={seconds} />
                          ) : (
                            ''
                          )
                        }
                      >
                        <Inline space="small">
                          <Button
                            disabled={
                              (!isElapsed && !!resentDispatcher?.guid) ||
                              isDispatcherLoading
                            }
                            pending={isLoading}
                            onClick={() => {
                              resendInvite(currentDispatcher.guid);
                            }}
                          >
                            Resend Invite
                          </Button>
                          <Button
                            variant="neutral"
                            disabled={isDispatcherLoading}
                            onClick={() => {
                              setIsCancelInvite(true);
                            }}
                          >
                            Cancel Invite
                          </Button>
                        </Inline>
                      </Tooltip>
                    );
                  }}
                </Countdown>
              )}

            {shouldShowDispatcherDeactivation &&
              currentDispatcher.connection_status === 'deactivated' && (
                <Button
                  pending={isReactivating}
                  variant="neutral"
                  onClick={() => {
                    reactivateDispatcher(currentDispatcher.guid);
                  }}
                >
                  Reactivate
                </Button>
              )}

            {(currentDispatcher.connection_status === 'activated' ||
              currentDispatcher.connection_status === 'deactivated') &&
              shouldShowDispatcherDeactivation && (
                <DispatcherDetailsMenuButton
                  connectionStatus={currentDispatcher.connection_status}
                  onSelect={setCurrentModal}
                />
              )}
          </Inline>
        </Column>
      </Columns>

      <DispatcherDeactivationDialog
        open={currentModal === 'deactivate'}
        onClose={() => {
          setCurrentModal(null);
        }}
      />

      <DispatcherDeletionDialog
        open={currentModal === 'delete'}
        onClose={() => {
          setCurrentModal(null);
        }}
      />
    </>
  );
}

interface DispatcherStatusProps {
  dispatcher: DispatcherDTO;
}

function DispatcherStatus({ dispatcher }: DispatcherStatusProps) {
  if (dispatcher.connection_status === 'deactivated') {
    return (
      <Tag variant="subtle" color="red">
        <Inline noWrap={true} space="xxsmall" verticalAlign="center">
          <AccountOffIcon fontSize="small" />
          Deactivated
        </Inline>
      </Tag>
    );
  } else if (
    dispatcher.connection_status === 'pending' &&
    dispatcher.connection_type === 'invite'
  ) {
    return (
      <Tag variant="subtle" color="yellow">
        <Inline noWrap={true} space="xxsmall" verticalAlign="center">
          <QueryBuilder fontSize="small" />
          Invited
        </Inline>
      </Tag>
    );
  } else if (
    dispatcher.connection_status === 'pending' &&
    dispatcher.connection_type === 'join_request'
  ) {
    return (
      <Tag variant="subtle" color="blue">
        <Inline noWrap={true} space="xxsmall" verticalAlign="center">
          <PersonAddAlt htmlColor={Color.Blue300} />
          Join Request
        </Inline>
      </Tag>
    );
  }

  return null;
}

interface ResendInviteTooltipProps {
  seconds?: string;
}

function ResendInviteTooltip({ seconds }: ResendInviteTooltipProps) {
  return (
    <Typography>Wait {seconds} seconds before resending the invite.</Typography>
  );
}
