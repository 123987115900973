import { ReactElement } from 'react';
import { useLocation } from 'react-router-dom';
import { Navigate } from 'shared/routing/react-router-6';
import { SuperPayVerificationStatus } from 'shared/settings/CarrierSettingsAPI';
import { useSuperPaySettings } from '../data/SuperPayAPI';

export const SUPERPAY_PROFILE_ROUTES = {
  promo: '/profile/superpay',
  businessDetails: '/profile/superpay/onboarding/create-account',
  bankDetails: '/profile/superpay/onboarding/bank-account',
  accountDetails: '/profile/superpay/onboarding/account-details',
  bankVerification:
    '/profile/superpay/onboarding/account-details/verify-bank-account',
};

export const SUPERPAY_WEBVIEW_ROUTES = {
  promo: '/superpay',
  businessDetails: '/superpay/onboarding/create-account',
  bankDetails: '/superpay/onboarding/bank-account',
  microDepositInitiated: '/superpay/onboarding/micro-deposit-initiated',
  bankVerification: '/superpay/onboarding/account-details/verify-bank-account',
  accountDetails: '/superpay/onboarding/account-details',
  requestShipper: '/superpay/request-shipper',
};

interface SuperPayRouteProps {
  children: ReactElement;
  path: string;
  getPathByStatus: (
    verificationStatus?: SuperPayVerificationStatus | null,
  ) => string[];
}

export function SuperPayRoute({
  children,
  path,
  getPathByStatus,
}: SuperPayRouteProps) {
  const { search } = useLocation();
  const { data, isLoading } = useSuperPaySettings();

  if (isLoading) return null;

  const pathsFromStatus = getPathByStatus(data?.verification_step);

  if (!pathsFromStatus.includes(path)) {
    const [pathname] = pathsFromStatus;
    if (!pathname) return null;
    return <Navigate replace={true} to={{ pathname, search }} />;
  }

  return children;
}
