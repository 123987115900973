import { Link } from '@material-ui/core';
import { Inline } from '@superdispatch/ui';
import React from 'react';
import { PageHeader } from 'shared/layout/PageLayout';

interface ReportsPageHeaderProps {
  title: string;
  helpLink?: string;
  actions?: React.ReactNode;
}

export function ReportsPageHeader({
  title,
  helpLink,
  actions,
}: ReportsPageHeaderProps) {
  return (
    <PageHeader
      title={
        <Inline space="small" verticalAlign="center" noWrap={true}>
          <span>{title}</span>

          {helpLink && (
            <Link
              display="block"
              variant="body2"
              target="_blank"
              rel="noopener noreferrer"
              href={helpLink}
            >
              Learn more
            </Link>
          )}
        </Inline>
      }
      endActions={actions}
    />
  );
}
