import { Dialog, DialogProps, Typography } from '@material-ui/core';
import {
  HorizontalAlign,
  ResponsiveProp,
  Stack,
  useUID,
} from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import { ReactNode } from 'react';

export interface DialogWithIllustrationProps
  extends Omit<DialogProps, 'title' | 'onClose'> {
  illustration?: ReactNode;
  alignContent?: ResponsiveProp<HorizontalAlign>;
  alignActions?: ResponsiveProp<HorizontalAlign>;
  title?: ReactNode;
  actions?: ReactNode;
  onClose?: () => void;
}

export function DialogWithIllustration({
  illustration,
  alignContent = 'center',
  alignActions = 'center',
  title,
  actions,
  children,
  ...props
}: DialogWithIllustrationProps) {
  const uid = useUID();

  return (
    <Dialog {...props} aria-labelledby={uid}>
      <Box width={['auto', '480px']} padding="large">
        <Stack space="large">
          <Stack align={alignContent} space="large">
            {illustration}

            <div>
              <Typography
                id={uid}
                variant="h2"
                gutterBottom={true}
                align="center"
              >
                {title}
              </Typography>

              {children}
            </div>
          </Stack>
          <Stack align={alignActions}>{actions}</Stack>
        </Stack>
      </Box>
    </Dialog>
  );
}
