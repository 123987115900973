import { Fade } from '@material-ui/core';
import { ArrowDownward } from '@material-ui/icons';
import { Button } from '@superdispatch/ui-lab';
import { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

const ArrowBox = styled.div`
  position: sticky;
  bottom: 100px;
  right: 0;
  display: flex;
  justify-content: flex-end;
`;

interface ScrollToBottomProps {
  shouldShowBottomButton: boolean;
  hasBackButton?: boolean;
}

export const ScrollToBottom = ({
  shouldShowBottomButton,
  hasBackButton,
}: ScrollToBottomProps) => {
  const [isAtBottom, setIsAtBottom] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const shouldShowButton =
    (!isAtBottom && !hasBackButton) || shouldShowBottomButton;

  const handleScroll = () => {
    ref.current?.scrollIntoView({
      behavior: 'smooth',
    });
  };

  const listenChange = useCallback(() => {
    if (ref.current) {
      const { top } = ref.current.getBoundingClientRect();
      const isBottom = top <= window.innerHeight;
      setIsAtBottom(isBottom);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', listenChange);
    return () => {
      window.removeEventListener('scroll', listenChange);
    };
  }, [listenChange]);

  return (
    <>
      <Fade in={shouldShowButton}>
        <ArrowBox>
          <Button onClick={handleScroll}>
            <ArrowDownward />
          </Button>
        </ArrowBox>
      </Fade>

      <div ref={ref} />
    </>
  );
};
