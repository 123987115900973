import { Route, Switch } from 'react-router-dom';
import { LoadsContextProvider } from '../loads/data/LoadsContext';
import { LoadsPageContextProvider } from '../loads/loads-page/data/LoadsPageParamsContext';
import { DashboardLoadsPage } from './DashboardLoadsPage';
import { DashboardPage } from './DashboardPage';

export function DashboardContainerPage() {
  return (
    <Switch>
      <Route path="/dashboard/loads">
        <LoadsContextProvider>
          <LoadsPageContextProvider>
            <DashboardLoadsPage />
          </LoadsPageContextProvider>
        </LoadsContextProvider>
      </Route>
      <Route path="/dashboard">
        <DashboardPage />
      </Route>
    </Switch>
  );
}
