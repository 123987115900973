import { toString } from 'lodash-es';
import { logError } from 'shared/helpers/ErrorTracker';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type AnyRecord = Record<string, any>;

export function trim(value: unknown): string {
  return value == null ? '' : toString(value).trim();
}

export function isEmpty(value: unknown): boolean {
  if (typeof value == 'number') return !Number.isFinite(value);
  if (typeof value == 'string') return value.trim().length === 0;
  if (typeof value == 'object' || typeof value == 'undefined') {
    if (value == null) return true;
    if (Array.isArray(value)) return value.length === 0;
    if (value instanceof Date) return !Number.isFinite(value.valueOf());
    return Object.keys(value).length === 0;
  }

  return false;
}

export function isEmptyRecord<T extends AnyRecord>(
  record: T | null | undefined,
  isEmptyValue: (value: T[keyof T]) => boolean = isEmpty,
): boolean {
  if (record != null) {
    for (const value of Object.values(record)) {
      if (!isEmptyValue(value)) return false;
    }
  }

  return true;
}

export function tryReach<T>(fn: () => undefined | T, fallback: T): T {
  try {
    return fn() ?? fallback;
  } catch (e: unknown) {
    logError(e, 'tryReach');
    return fallback;
  }
}

export function getFilenameFromURL(url: string): string {
  if (url.endsWith('/')) return url;
  return url.split('/').pop() as string;
}
