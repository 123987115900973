import { DialogActions } from '@material-ui/core';
import { Button } from '@superdispatch/ui-lab';
import { useMemo } from 'react';
import { MutationDialogContent } from 'shared/form/MutationDialog';
import { performNavigation } from 'shared/routing/NavigationBlock';
import { useDeleteExpense } from '../data/ExpensesAPI';
import { formatLoadExpenseType, formatLoadStatus } from '../data/LoadDTO';
import { trackLoadsEvent } from '../data/LoadsAnalytics';
import { useLoadDetails } from '../data/LoadsAPI';
import { useLoadStageFromBackURL } from '../view/ViewLoadHeaderActions';

export interface LoadExpenseDeleteDialogContentProps {
  loadGUID: string;
  expenseGUID: string;
  onClose: () => void;
}

export function LoadExpenseDeleteDialogContent({
  onClose,
  loadGUID,
  expenseGUID,
}: LoadExpenseDeleteDialogContentProps) {
  const { data, refetch } = useLoadDetails(loadGUID);
  const loadStage = useLoadStageFromBackURL();
  const { mutate, isLoading } = useDeleteExpense({
    onSuccess() {
      trackLoadsEvent({
        name: 'Carrier Edited Load',
        utm_medium: loadStage,
        utm_content: 'View Page',
        load_status: formatLoadStatus(data?.status),
        is_created_by_broker: !!data?.is_created_by_broker,
        expenses: 'Edited',
      });

      void refetch();
      performNavigation(onClose);
    },
  });
  const expense = useMemo(
    () => data?.expenses?.find((item) => item.guid === expenseGUID),
    [expenseGUID, data?.expenses],
  );

  return (
    <MutationDialogContent
      disableAutoClose={true}
      title={`Delete ${
        expense ? formatLoadExpenseType(expense.type) : 'expense'
      }?`}
      actions={
        <DialogActions>
          <Button variant="neutral" onClick={onClose} disabled={isLoading}>
            Cancel
          </Button>

          <Button
            autoFocus={true}
            variant="critical"
            pending={isLoading}
            onClick={() => {
              mutate(expenseGUID);
            }}
          >
            Delete
          </Button>
        </DialogActions>
      }
    />
  );
}
