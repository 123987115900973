import { Dialog, DialogActions, DialogTitle } from '@material-ui/core';
import { Stack } from '@superdispatch/ui';
import { Button, FileDropZoneProps, FileListItem } from '@superdispatch/ui-lab';
import { useField, useFormikContext } from 'formik';
import { useState } from 'react';
import { useUploadMedia } from 'shared/data/MediaServiceAPI';
import { getFilenameFromURL } from 'shared/helpers/CommonHelpers';
import { FileDropZone } from './FileDropZone';

interface FormikFileListDeleteConfirmDialogProps {
  onClose: () => void;
  onConfirm: () => void;
  url?: null | false | string;
}

function FormikFileListDeleteConfirmDialog({
  url,
  onClose,
  onConfirm,
}: FormikFileListDeleteConfirmDialogProps) {
  return (
    <Dialog open={!!url}>
      <DialogTitle>
        Delete <b>{getFilenameFromURL(url || 'file')}?</b>
      </DialogTitle>
      <DialogActions>
        <Button variant="neutral" onClick={onClose}>
          Cancel
        </Button>

        <Button autoFocus={true} variant="critical" onClick={onConfirm}>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}

interface FormikFileDropZoneProps
  extends Pick<FileDropZoneProps, 'children' | 'hintText'> {
  name: string;
}

export function FormikFileDropZone({
  name,
  children,
  hintText,
}: FormikFileDropZoneProps) {
  const { isSubmitting } = useFormikContext();
  const [shouldConfirmDelete, setShouldConfirmDelete] = useState(false);
  const [, { value }, { setValue }] = useField<string | null | undefined>(name);

  const { mutate, reset, variables, isLoading, error } = useUploadMedia({
    onSuccess(nextValue) {
      void setValue(nextValue);
    },
  });

  return (
    <>
      <FormikFileListDeleteConfirmDialog
        url={shouldConfirmDelete && value}
        onClose={() => {
          setShouldConfirmDelete(false);
        }}
        onConfirm={() => {
          void setValue(null);
          setShouldConfirmDelete(false);
        }}
      />

      <Stack>
        <FileDropZone
          maxFiles={1}
          hintText={hintText}
          disabled={isSubmitting}
          onDropAccepted={(files) => {
            if (files[0]) mutate(files[0]);
          }}
        >
          {children}
        </FileDropZone>

        {variables && (error || isLoading) ? (
          <FileListItem
            name={variables.name}
            onDelete={reset}
            onRetry={() => {
              mutate(variables);
            }}
            status={error ? 'error' : 'loading'}
            helperText={!error ? null : error.message}
          />
        ) : value ? (
          <FileListItem
            url={value}
            name={getFilenameFromURL(value)}
            onDelete={() => {
              setShouldConfirmDelete(true);
            }}
          />
        ) : null}
      </Stack>
    </>
  );
}
