import { CustomerType } from '@superdispatch/sdk';
import { Color, Inline } from '@superdispatch/ui';
import { TextBox } from '@superdispatch/ui-lab';
import { ReactNode } from 'react';
import styled from 'styled-components';
import { BusinessTypeTag } from './BusinessTypeTag';

export const Waypoints = styled.ul`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  margin: 0;
  padding: 0;
`;

export const WaypointSeparator = styled.div`
  flex: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const WaypointDot = styled.div<{
  $variant: 'origin' | 'destination';
  $dense?: boolean;
}>`
  display: flex;
  padding: 2px;
  align-self: baseline;
  margin-top: ${({ $dense }) => ($dense ? '9px' : '7px')};
  border-radius: 50%;
  border: 2px solid transparent;

  background-color: ${({ $variant }) =>
    $variant === 'origin' ? Color.Yellow300 : Color.Green300};
`;

const waypointSpaces = {
  none: '0px',
  small: '4px',
  medium: '8px',
};
export const Waypoint = styled.li<{ $space?: 'none' | 'small' | 'medium' }>`
  display: flex;
  position: relative;
  list-style: none;

  &:not(:first-child) {
    margin-top: ${({ $space = 'medium' }) => waypointSpaces[$space]};
  }
`;

export const WaypointConnector = styled.div`
  width: 1px;
  flex-grow: 1;
  margin-top: 4px;
  margin-bottom: -11px;

  background: repeating-linear-gradient(
    ${Color.Silver500},
    ${Color.Silver500} 4px,
    transparent 2px,
    transparent 6px
  );
`;

export const WaypointContent = styled.div`
  width: 100%;
  flex-shrink: 1;
  flex-grow: 0;
  padding-left: 11px;
  display: flex;
  align-items: center;

  & > :not(:first-child) {
    margin-left: 8px;
  }
`;

export const WaypointContentAdornment = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;

  @media (min-width: 768px) {
    & > :not(:first-child) {
      margin-left: 8px;
    }
    flex-direction: initial;
    align-items: center;
  }
`;

export interface WaypointsProps {
  pickupDate?: ReactNode;
  pickupAddress: ReactNode;
  pickupBusinessType?: CustomerType | null;

  deliveryDate?: ReactNode;
  deliveryAddress: ReactNode;
  deliveryBusinessType?: CustomerType | null;

  dense?: boolean;
  space?: 'none' | 'small' | 'medium';
  inline?: boolean;
}

export function LoadWaypoints({
  pickupDate,
  deliveryDate,
  pickupAddress,
  deliveryAddress,
  pickupBusinessType,
  deliveryBusinessType,
  dense,
  space = 'medium',
  inline = false,
}: WaypointsProps) {
  const pickupContent = (
    <>
      <WaypointContentAdornment>
        <div>{pickupAddress}</div>
        {!!pickupBusinessType && (
          <BusinessTypeTag businessType={pickupBusinessType} />
        )}
      </WaypointContentAdornment>
      {!!pickupDate && <TextBox color="secondary">{pickupDate}</TextBox>}
    </>
  );

  const deliveryContent = (
    <>
      <WaypointContentAdornment>
        <div>{deliveryAddress}</div>
        {!!deliveryBusinessType && (
          <BusinessTypeTag businessType={deliveryBusinessType} />
        )}
      </WaypointContentAdornment>
      {!!deliveryDate && <TextBox color="secondary">{deliveryDate}</TextBox>}
    </>
  );

  return (
    <Waypoints>
      <Waypoint>
        <WaypointSeparator>
          <WaypointDot $variant="origin" $dense={dense} />
          <WaypointConnector />
        </WaypointSeparator>

        <WaypointContent>
          <TextBox variant="heading-4">
            {inline ? (
              <Inline verticalAlign="center" space="xsmall">
                {pickupContent}
              </Inline>
            ) : (
              pickupContent
            )}
          </TextBox>
        </WaypointContent>
      </Waypoint>

      <Waypoint $space={space}>
        <WaypointSeparator>
          <WaypointDot $variant="destination" $dense={dense} />
        </WaypointSeparator>

        <WaypointContent>
          <TextBox variant="heading-4">
            {inline ? (
              <Inline verticalAlign="center" space="xsmall">
                {deliveryContent}
              </Inline>
            ) : (
              deliveryContent
            )}
          </TextBox>
        </WaypointContent>
      </Waypoint>
    </Waypoints>
  );
}
