import { useSnackbarStack } from '@superdispatch/ui';
import { APIError } from 'shared/api/APIError';
import { APIMutationOptions } from 'shared/api/APIMutation';
import { trackEvent } from 'shared/helpers/Analytics';
import { SaveOnboardingProps, useSaveOnboardingStep } from './OnboardingAPI';

export function useSaveOnboardingWithErrorHandler(
  options?: APIMutationOptions<SaveOnboardingProps>,
) {
  const { addSnackbar } = useSnackbarStack();

  return useSaveOnboardingStep({
    ...options,
    onError: (error: APIError, variables, context) => {
      addSnackbar(error.message, { variant: 'error' });

      void options?.onError?.(error, variables, context);
    },
    onSuccess: (data, variables, context) => {
      trackEvent('Carrier Dispatcher finished onboarding step', {
        step_name: variables.stepName,
        status: variables.status,
      });

      void options?.onSuccess?.(data, variables, context);
    },
  });
}
