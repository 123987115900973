import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  Typography,
} from '@material-ui/core';
import { Stack } from '@superdispatch/ui';
import { Button } from '@superdispatch/ui-lab';
import { Fragment, useMemo } from 'react';
import { formatPlural } from 'shared/helpers/IntlHelpers';
import { DuplicatedLoad } from '../data/VINsAPI';

interface DuplicatedVINsDialogProps {
  onClose: () => void;
  duplicateVINs?: Record<string, undefined | DuplicatedLoad[]>;
}

export function DuplicatedVINsDialog({
  onClose,
  duplicateVINs = {},
}: DuplicatedVINsDialogProps) {
  const [duplicates, maxLoadsAmount] = useMemo(() => {
    let nextMaxLoadsAmount = 0;
    const nextDuplicates: Array<[string, DuplicatedLoad[]]> = [];

    for (let [key, loads] of Object.entries(duplicateVINs)) {
      if (loads) {
        nextDuplicates.push([key, loads]);
        nextMaxLoadsAmount = Math.max(loads.length, nextMaxLoadsAmount);
      }
    }

    return [nextDuplicates, nextMaxLoadsAmount] as const;
  }, [duplicateVINs]);

  return (
    <Dialog fullWidth={true} open={duplicates.length > 0}>
      <DialogTitle>
        {formatPlural(
          duplicates.length,
          'Duplicate VIN found',
          'Duplicate VINs found',
        )}
      </DialogTitle>

      <DialogContent>
        <Stack space="xsmall">
          {duplicates.length > 0 && (
            <Typography>
              Entered {formatPlural(duplicates.length, 'VIN', 'VINs')} already
              exist in the following{' '}
              {!!maxLoadsAmount &&
                formatPlural(maxLoadsAmount, 'load:', 'loads:')}
            </Typography>
          )}

          {duplicates.map(([vin, loads]) => (
            <div key={vin}>
              <Typography display="inline" variant="body1">
                {vin}:{' '}
              </Typography>

              {loads.length > 0
                ? loads.map((load, idx) => (
                    <Fragment key={`${load.vin}${load.guid}`}>
                      {idx > 0 && ', '}

                      <Link
                        target="_blank"
                        color="textPrimary"
                        rel="noopener noreferrer"
                        href={
                          load.guid
                            ? `/tms/loads/${load.guid}`
                            : `/dashboard/orders/${load.id}/view/`
                        }
                      >
                        {load.number || 'No Load ID'}
                      </Link>
                    </Fragment>
                  ))
                : 'In the current load'}
            </div>
          ))}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Got It</Button>
      </DialogActions>
    </Dialog>
  );
}
