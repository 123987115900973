import { Box } from '@superdispatch/ui-lab';
import { useAppFormik } from 'shared/form/AppFormik';
import {
  FormikDrawer,
  FormikDrawerActions,
  FormikDrawerContent,
} from 'shared/form/FormikDrawer';
import { loadCustomerEditSchema } from '../data/LoadActionsDTO';
import { formatLoadStatus, isEmptyLoadLegVenue } from '../data/LoadDTO';
import { trackLoadsEvent } from '../data/LoadsAnalytics';
import { useLoadDetails, useLoadsAPI, useLoadsCache } from '../data/LoadsAPI';
import { useLoadStageFromBackURL } from '../view/ViewLoadHeaderActions';
import { LoadCustomerForm } from './LoadCustomerForm';

interface EditCustomerDrawerContentProps {
  loadGUID: string;
  onClose: () => void;
}

function EditCustomerDrawerContent({
  onClose,
  loadGUID,
}: EditCustomerDrawerContentProps) {
  const { data } = useLoadDetails(loadGUID);
  const { editLoadCustomer } = useLoadsAPI();
  const { invalidateLoads } = useLoadsCache();
  const loadStage = useLoadStageFromBackURL();

  const formik = useAppFormik({
    initialValues: data?.customer,
    validationSchema: loadCustomerEditSchema,
    onSubmit(values) {
      return editLoadCustomer(loadGUID, values);
    },
    onSubmitSuccess() {
      onClose();
      invalidateLoads();

      trackLoadsEvent({
        name: 'Carrier Edited Load',
        utm_medium: loadStage,
        utm_content: 'View Page',
        load_status: formatLoadStatus(data?.status),
        is_created_by_broker: !!data?.is_created_by_broker,
        customer_information:
          !data?.customer || isEmptyLoadLegVenue(data.customer.venue)
            ? 'Added'
            : 'Edited',
      });
    },
    getSuccessMessage() {
      return 'Successfully changed customer information.';
    },
    getErrorMessage({ message }) {
      return message || 'Something went wrong, contact support.';
    },
  });

  return (
    <FormikDrawerContent
      formik={formik}
      onClose={onClose}
      title="Customer Information"
      actions={<FormikDrawerActions />}
    >
      <Box maxWidth="432px">
        <LoadCustomerForm />
      </Box>
    </FormikDrawerContent>
  );
}

interface EditCustomerDrawerProps extends EditCustomerDrawerContentProps {
  open: boolean;
}

export function EditCustomerDrawer({
  open,
  onClose,
  loadGUID,
}: EditCustomerDrawerProps) {
  return (
    <FormikDrawer open={open} onClose={onClose}>
      <EditCustomerDrawerContent loadGUID={loadGUID} onClose={onClose} />
    </FormikDrawer>
  );
}
