import { Link, Typography } from '@material-ui/core';
import { useFormikEnhanced } from '@superdispatch/forms';
import { Column, Columns, Stack, useSnackbarStack } from '@superdispatch/ui';
import { Box, Button } from '@superdispatch/ui-lab';
import { MouseEvent } from 'react';
import { APIError } from 'shared/api/APIError';
import { isWebView } from 'shared/constants/AppTypeConstants';
import { useInitialValues } from 'shared/form/AppFormik';
import { trackAnchorClick } from 'shared/helpers/Analytics';
import { DocumentTitle } from 'shared/layout/DocumentTitle';
import { InsuranceCertificateHolderInstruction } from 'shared/modules/carrier-profile/InsuranceCertificateHolderInstruction';
import { useVerifiedCarrierApplicationStatus } from 'shared/modules/carrier-profile/VerifiedCarrierApplicationStatus';
import { CargoInsurancePreview } from './core/CargoInsurancePreview';
import { ProfileCargoInsuranceForm } from './core/ProfileCargoInsuranceForm';
import {
  useSubmitVerificationDocument,
  VerificationDialogs,
} from './core/VerificationDialogs';
import { trackCarrierProfileEvent } from './data/CarrierProfileAnalytics';
import {
  useCargoInsurance,
  useCarrierProfileAPI,
} from './data/CarrierProfileAPI';
import { cargoInsuranceSchema } from './data/CarrierProfileDTO';
import { ProfilePageLayout } from './ProfilePageLayout';

export function ProfileCargoInsurancePage() {
  const { addSnackbar } = useSnackbarStack();
  const { data } = useVerifiedCarrierApplicationStatus();
  const verifiedCarrierApplication = data?.verified_carrier_application;
  const isApplicationReviewed =
    verifiedCarrierApplication?.status === 'reviewed';
  const profileCargoInsurance = useCargoInsurance({
    onError: () => {
      addSnackbar('Failed to fetch cargo insurance', {
        variant: 'error',
      });
    },
  });
  const { updateCargoInsurance } = useCarrierProfileAPI();

  const initialValues = useInitialValues(
    cargoInsuranceSchema,
    profileCargoInsurance.data,
  );

  const formik = useFormikEnhanced({
    initialValues,
    validationSchema: cargoInsuranceSchema,
    onSubmit: updateCargoInsurance,
    onSubmitSuccess: () => {
      addSnackbar('Cargo insurance was successfully updated', {
        variant: 'success',
      });

      trackCarrierProfileEvent({
        name: 'Carrier Updated Carrier Info',
        utm_medium: 'Carrier Profile',
        utm_content: 'Cargo Insurance',
      });
    },
    onSubmitFailure: ({ message }: APIError) => {
      addSnackbar(`Failed to update cargo insurance: ${message}`, {
        variant: 'error',
      });
    },
  });

  const submitVerificationForm = useSubmitVerificationDocument(formik);

  return (
    <>
      <DocumentTitle title="Cargo Insurance - Profile" />

      <VerificationDialogs formik={formik} />

      <ProfilePageLayout
        formik={formik}
        size={isWebView ? 'small' : isApplicationReviewed ? 'large' : 'medium'}
        title="Cargo Insurance"
        isLoading={profileCargoInsurance.isLoading}
        headerEndActions={
          <Button
            type="button"
            onClick={submitVerificationForm}
            pending={formik.isSubmitting}
            disabled={!formik.dirty}
            fullWidth={isWebView}
          >
            Save
          </Button>
        }
      >
        <Columns space="xxlarge" collapseBelow="desktop">
          <Column>
            {isApplicationReviewed && <ReviewedBanner />}

            <Box
              maxWidth={['496px', '496px', '440px']}
              paddingTop={
                isWebView
                  ? 'small'
                  : isApplicationReviewed
                  ? 'large'
                  : 'xxlarge'
              }
            >
              <ProfileCargoInsuranceForm />
            </Box>
          </Column>

          {!isWebView && (
            <Column>
              <CargoInsurancePreview />
            </Column>
          )}
        </Columns>
      </ProfilePageLayout>
    </>
  );
}

function ReviewedBanner() {
  return (
    <Box
      maxWidth="500px"
      backgroundColor="Yellow50"
      padding="small"
      marginTop={isWebView ? 'small' : 'xxlarge'}
      borderRadius="small"
    >
      <Stack space="xsmall">
        <Typography>
          We are waiting for a third party to confirm your insurance.
        </Typography>

        <InsuranceCertificateHolderInstruction boldHeader={true} />

        <Link
          href="https://support.superdispatch.com/en/articles/6167393-listing-super-dispatch-as-the-certificate-holder-on-your-coi"
          target="_blank"
          rel="noopener noreferrer"
          onClick={(event: MouseEvent<HTMLAnchorElement>) => {
            trackAnchorClick(
              'Clicked Learn More In Carrier Profile Reviewed Banner',
              {},
              event,
            );
          }}
        >
          Learn More
        </Link>
      </Stack>
    </Box>
  );
}
