import { Typography } from '@material-ui/core';
import { Color, Stack } from '@superdispatch/ui';
import { Box, Button } from '@superdispatch/ui-lab';
import { DriverSidebarIcon } from 'shared/icons/DriverSidebarIcon';
import { useNavigate } from 'shared/routing/react-router-6';
import styled from 'styled-components';
import { useDriverAccountDeletionContext } from '../DriverAccountDeletionPage';

const IconWrapper = styled.div`
  width: 56px;
  height: 56px;
  border-radius: 28px;
  background-color: ${Color.Silver400};
  display: flex;
  align-items: center;
  justify-content: center;
`;
const DriverEmail = styled(Typography)`
  overflow-wrap: anywhere;
`;

export function MainStepPage() {
  const navigate = useNavigate();
  const { token, driverAccount } = useDriverAccountDeletionContext();

  return (
    <Box padding="medium">
      <Stack space="large" align="center">
        <IconWrapper>
          <DriverSidebarIcon color="action" fontSize="small" />
        </IconWrapper>

        <Stack space="xxsmall" align="center">
          <Typography variant="h2">{driverAccount?.name}</Typography>
          <DriverEmail>{driverAccount?.email}</DriverEmail>
          <Typography> {driverAccount?.phone}</Typography>
        </Stack>

        <Button
          variant="critical"
          fullWidth={true}
          onClick={() => {
            navigate(`/driver-account-deletion/${token}/questionnaire/`);
          }}
        >
          Delete Account
        </Button>
      </Stack>
    </Box>
  );
}
