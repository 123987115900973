import { Stack } from '@superdispatch/ui';
import { Button } from '@superdispatch/ui-lab';
import { isWebView } from 'shared/constants/AppTypeConstants';
import { showNewIntercomMessage } from 'shared/helpers/Intercom';
import { useVerifiedCarrierApplicationStatus } from '../VerifiedCarrierApplicationStatus';
import { FieldAdditionalMessages } from './AdditionalMessages';

export function CarrierOverviewDecliningReasons() {
  const { data } = useVerifiedCarrierApplicationStatus();
  const application = data?.verified_carrier_application;
  const decliningReasons = application?.declining_reasons?.reliability_score;
  const externalNotes = application?.external_notes?.low_reliability;

  if (!decliningReasons?.length) {
    return null;
  }

  return (
    <FieldAdditionalMessages
      variant="error"
      listStyle="plain"
      messages={decliningReasons}
      externalNotes={externalNotes}
      actions={
        <Stack space="xxsmall">
          <Button
            size="small"
            fullWidth={isWebView}
            variant="neutral"
            onClick={() => {
              showNewIntercomMessage(
                'I want to resubmit Verification Application. My previous verification was declined due to not meeting Super Dispatch standards.',
              );
            }}
          >
            Contact Support
          </Button>
        </Stack>
      }
    />
  );
}

export function CarrierOverviewRevokingReasons() {
  const { data } = useVerifiedCarrierApplicationStatus();
  const application = data?.verified_carrier_application;
  const revokingReasons = application?.revoking_reasons?.terms_and_conditions;
  const externalNotes = application?.external_notes?.terms_and_conditions;

  if (!revokingReasons?.length) {
    return null;
  }

  return (
    <FieldAdditionalMessages
      variant="error"
      listStyle="plain"
      title="Your Verified Carrier Status was revoked. You can no longer submit requests on Super Loadboard."
      messages={revokingReasons}
      externalNotes={externalNotes}
      actions={
        <Button
          size="small"
          fullWidth={isWebView}
          variant="neutral"
          onClick={() => {
            showNewIntercomMessage(
              'I want to resubmit Verification Application. My previous verification was revoked due to breach of Terms and Conditions of Super Dispatch.',
            );
          }}
        >
          Contact Support
        </Button>
      }
    />
  );
}
