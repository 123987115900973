import { ReactElement, useCallback, useEffect } from 'react';
import { match, useHistory, useRouteMatch } from 'react-router-dom';

interface NavigateOptions {
  replace?: boolean;
  state?: unknown;
}

interface Path {
  pathname: string;
  search: string;
  hash: string;
}

type To = string | Partial<Path>;

export interface NavigateFunction {
  (to: To, options?: NavigateOptions): void;
  (delta: number): void;
}

export function useNavigate(): NavigateFunction {
  const history = useHistory();
  return useCallback(
    (to: To | number, { state, replace }: NavigateOptions = {}) => {
      if (typeof to === 'number') {
        history.go(to);
      } else if (replace) {
        history.replace(to, state);
      } else {
        history.push(to, state);
      }
    },
    [history],
  );
}

interface NavigateProps {
  to: To;
  replace?: boolean;
  state?: unknown;
}

/**
 * This component should be used only for the programmatic navigation, and therefore it will unblock the history
 * navigation and might lead to loss of the form data.
 */
export function Navigate(props: NavigateProps): null | ReactElement {
  const history = useHistory();
  const navigate = useNavigate();
  const propsJSON = JSON.stringify(props);

  useEffect(() => {
    const {
      to,
      state,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      replace,
    } = JSON.parse(propsJSON) as NavigateProps;

    // When filling out the form we have a route blocked and if we do a redirect we should unblock the route.
    const unblock = history.block(() => undefined);
    navigate(to, { state, replace });
    return unblock;
  }, [history, navigate, propsJSON]);

  return null;
}

export function useMatch<TParams extends { [K in keyof TParams]?: string }>(
  path: string,
): match<TParams> | null {
  return useRouteMatch<TParams>(path);
}
