export const CTMS_HOST =
  import.meta.env.VITE_APP_TARGET === 'staging' ||
  import.meta.env.VITE_APP_TARGET === 'production'
    ? `https://${window.location.host}` // http://dashboard.superdispatch.com or https://carrier.superdispatch.com
    : 'https://staging.carrier.superdispatch.org';

export const CTMS_GATEWAY_HOST =
  import.meta.env.VITE_APP_TARGET === 'production'
    ? 'https://internal-web-api.carrier.superdispatch.com'
    : 'https://staging.internal-web-api.carrier.superdispatch.org';

export const CTMS_WEBSOCKET_HOST =
  import.meta.env.VITE_APP_TARGET === 'production'
    ? `wss://${window.location.host}/ws`
    : 'wss://staging.carrier.superdispatch.org/ws';

export const CTMS_MESSAGING_WEBSOCKET =
  'wss://staging.messaging.superdispatch.org/api/ws/';

export const MEDIA_SERVICE_API_HOST =
  import.meta.env.VITE_APP_TARGET === 'production'
    ? 'https://media.superdispatch.com'
    : 'https://staging.media.superdispatch.org';
