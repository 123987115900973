import {
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { formatVehicleType } from '@superdispatch/sdk';
import { Color, Inline, Stack } from '@superdispatch/ui';
import { Box, MultilineText } from '@superdispatch/ui-lab';
import { truncate } from 'lodash-es';
import { useMemo, useState } from 'react';
import { formatCurrency } from 'shared/helpers/IntlHelpers';
import {
  formatCurbWeight,
  formatVehicleDimensions,
  hasVehicleDimension,
} from 'shared/helpers/VehicleHelpers';
import { EnclosedTrailerIcon } from 'shared/icons/EnclosedTrailerIcon';
import { InoperableIcon } from 'shared/icons/InoperableIcon';
import { WarningIcon } from 'shared/icons/WarningIcon';
import { joinStrings } from 'shared/utils/StringUtils';
import { LoadVehicleStatusTag } from '../core/LoadVehicleStatusTag';
import { LoadNotPickedUpVehicleDTO } from '../data/LoadDTO';

interface ViewLoadNotPickedVehiclesProps {
  notPickedUpVehicles: LoadNotPickedUpVehicleDTO[];
}

export function ViewLoadNotPickedVehicles({
  notPickedUpVehicles,
}: ViewLoadNotPickedVehiclesProps) {
  return (
    <Box marginTop="large">
      <Stack space="xsmall">
        <Box paddingLeft="small">
          <Inline verticalAlign="center" space="xsmall">
            <WarningIcon />
            <Typography variant="h4">Not Picked Up Vehicles</Typography>
          </Inline>
        </Box>

        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell scope="row">Vehicle</TableCell>
                <TableCell scope="row">Vehicle Details</TableCell>
                <TableCell>
                  <Typography noWrap={true} variant="inherit">
                    Lot Number
                  </Typography>
                </TableCell>
                <TableCell>Price</TableCell>
                <TableCell>Reason</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {notPickedUpVehicles.map((vehicle) => (
                <NotPickedUpVehiclesTableRow
                  vehicle={vehicle}
                  key={vehicle.guid}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
    </Box>
  );
}

interface NotPickedUpVehiclesTableRowProps {
  vehicle: LoadNotPickedUpVehicleDTO;
}

function NotPickedUpVehiclesTableRow({
  vehicle,
}: NotPickedUpVehiclesTableRowProps) {
  return (
    <TableRow>
      <TableCell>
        <Inline space="xsmall">
          <LoadVehicleStatusTag status={vehicle.status} />
          <Typography variant="body1">
            {joinStrings(' ', vehicle.year, vehicle.make, vehicle.model)}
          </Typography>
          <Typography color="textSecondary">
            {vehicle.color}{' '}
            {formatVehicleType(vehicle.type, { fallback: vehicle.type })}
          </Typography>
        </Inline>
        <Inline verticalAlign="center">
          <Typography color="textSecondary">{vehicle.vin}</Typography>
          <Inline>
            {vehicle.is_inoperable && (
              <Tooltip title="Inoperable vehicle" enterTouchDelay={0}>
                <InoperableIcon htmlColor={Color.Red500} />
              </Tooltip>
            )}
            {vehicle.requires_enclosed_trailer && (
              <Tooltip title="Requires enclosed trailer" enterTouchDelay={0}>
                <EnclosedTrailerIcon htmlColor={Color.Yellow500} />
              </Tooltip>
            )}
          </Inline>
        </Inline>
      </TableCell>
      <TableCell>
        {vehicle.curb_weight && (
          <Typography>{formatCurbWeight(vehicle.curb_weight)}</Typography>
        )}
        {hasVehicleDimension(vehicle) && (
          <Typography noWrap={true} color="textSecondary">
            {formatVehicleDimensions(vehicle)}
          </Typography>
        )}
      </TableCell>
      <TableCell>{vehicle.lot_number}</TableCell>
      <TableCell>{!!vehicle.price && formatCurrency(vehicle.price)}</TableCell>
      <TableCell>
        {!!vehicle.reason && <ReasonText content={vehicle.reason} />}
      </TableCell>
    </TableRow>
  );
}

function ReasonText({ content }: { content: string }) {
  const [isExpanded, setIsExpanded] = useState(false);
  const contentTruncated = useMemo(
    () =>
      truncate(content, {
        length: 60,
        omission: '…',
        // Separate words by non-word characters
        separator: /\W/,
      }),
    [content],
  );
  const isExpandable = content.length > contentTruncated.length;

  return (
    <Box
      backgroundColor="Yellow50"
      paddingTop="xxsmall"
      paddingBottom="xxsmall"
      paddingLeft="xsmall"
      paddingRight="xsmall"
      borderRadius="small"
      maxWidth="260px"
      width="max-content"
    >
      <MultilineText overflowWrap="break-word">
        {isExpanded ? content : contentTruncated}
      </MultilineText>

      {isExpandable && (
        <Box as="span" marginLeft="xxsmall">
          <Link
            component="button"
            type="button"
            color="primary"
            onClick={() => {
              setIsExpanded(!isExpanded);
            }}
          >
            {isExpanded ? 'less' : 'more'}
          </Link>
        </Box>
      )}
    </Box>
  );
}
