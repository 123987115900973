import { Typography } from '@material-ui/core';
import { useResponsiveValue } from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import styled from 'styled-components';
import { ResetPasswordAction } from './ResetPasswordAction';

const Copyright = styled.div`
  margin: 16px auto;
`;

interface ResetPasswordSuccessFooterProps {
  token: string;
}

export function ResetPasswordSuccessFooter({
  token,
}: ResetPasswordSuccessFooterProps) {
  const isMobile = useResponsiveValue(true, false);

  if (isMobile) {
    return (
      <Box position="absolute" bottom={0} right={0} left={0}>
        <Box>
          <ResetPasswordAction token={token} />
        </Box>
      </Box>
    );
  }
  return (
    <Copyright>
      <Typography align="center" color="textSecondary">
        &copy; {new Date().getFullYear()} Super Dispatch. All rights reserved.
      </Typography>
    </Copyright>
  );
}
