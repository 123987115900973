import { createSvgIcon } from 'shared/icons/IconUtils';

export const PrivateIcon = createSvgIcon(
  'BusinessTypePrivate',
  <path
    fill="currentColor"
    d="M0 12.9999V5.66659L5 2.33325L10 5.66659V12.9999H6.66667V8.33325H3.33333V12.9999H0ZM16 0.333252V12.9999H11.3333V4.95325L10.6667 4.51325V2.99992H9.33333V3.61992L6.66667 1.84659V0.333252H16ZM14 8.33325H12.6667V9.66659H14V8.33325ZM14 5.66659H12.6667V6.99992H14V5.66659ZM14 2.99992H12.6667V4.33325H14V2.99992Z"
  />,
  { viewBox: '0 0 16 13' },
);
