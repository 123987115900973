import { SvgIcon, SvgIconProps } from '@material-ui/core';
import { Color } from '@superdispatch/ui';
import { Fragment } from 'react';
import styled from 'styled-components';
import { LoadTerminalDiagramDTO } from '../data/LoadDTO';

type LegType = 'origin' | 'destination' | 'terminal';

interface LegProps extends SvgIconProps {
  type: LegType;
}

function Leg({ type, ...props }: LegProps) {
  return (
    <SvgIcon {...props} fontSize="inherit">
      {type === 'origin' ? (
        <>
          <circle cx="12" cy="12" r="12" fill="currentColor" />
          <circle cx="12" cy="12" r="7" stroke="white" strokeWidth="2" />
        </>
      ) : type === 'destination' ? (
        <>
          <circle cx="12" cy="12" r="12" fill="currentColor" />
          <path
            fill="white"
            d="M7 4v15h5.26c4.56 0 7.24-2.78 7.24-7.5 0-4.72-2.69-7.5-7.24-7.5H7zm1.91 1.68h3.22c3.4 0 5.41 2.17 5.41 5.84 0 3.65-1.98 5.8-5.4 5.8H8.9V5.68z"
          />
        </>
      ) : (
        <>
          <circle cx="12" cy="12" r="12" fill="currentColor" />
          <path stroke="white" strokeWidth="2" d="M5 6h14M12 19.13V6" />
        </>
      )}
    </SvgIcon>
  );
}

const Main = styled.span`
  display: flex;
  align-items: center;
`;

const Item = styled.span`
  display: flex;
  position: relative;
  color: ${Color.Dark300};
  background-color: ${Color.Dark300};

  &[data-active='true'] {
    box-shadow: 0 0 0 3px ${Color.Blue100};
  }

  &[data-active='true'],
  &[data-complete='true'] {
    color: ${Color.Blue300};
    background-color: ${Color.Blue300};
  }
`;

const LegContainer = styled(Item)`
  z-index: 1;
  font-size: 20px;
  border-radius: 50%;
`;

const LegProgress = styled(Item)`
  width: 18px;
  height: 6px;
  margin-left: -1px;
  margin-right: -1px;
`;

export interface LoadLegsDiagramProps {
  terminalsDiagram?: LoadTerminalDiagramDTO;
}

export function LoadLegsDiagram({ terminalsDiagram }: LoadLegsDiagramProps) {
  if (!terminalsDiagram?.length) {
    return null;
  }

  return (
    <Main aria-label="terminals diagram">
      {terminalsDiagram.map((leg, idx, list) => {
        const type: LegType =
          idx === 0
            ? 'origin'
            : idx === list.length - 1
            ? 'destination'
            : 'terminal';

        return (
          <Fragment key={idx}>
            <LegContainer
              aria-label={`terminal ${idx}`}
              key={idx}
              data-active={leg.is_active}
              data-complete={leg.is_complete}
            >
              <Leg type={type} />
            </LegContainer>

            {type !== 'destination' && (
              <LegProgress
                aria-label={`progress bar ${idx}`}
                data-complete={leg.is_complete}
              />
            )}
          </Fragment>
        );
      })}
    </Main>
  );
}
