import {
  yupArray,
  yupBoolean,
  yupDateString,
  yupEnum,
  yupNumber,
  yupObject,
  yupString,
} from 'shared/utils/YupUtils';
import { InferType } from 'yup';

const bankAccountVerificationStatuses = [
  'new',
  'pending',
  'verified',
  'failed',
  'errored',
  'locked',
] as const;

export const verificationSteps = [
  'restricted',
  'not_started',
  'bank_account',
  'bank_account_verification',
  'verified',
  'bank_account_failed',
  'bank_account_errored',
] as const;

export type BankPaymentErrorDTO = InferType<typeof bankPaymentErrorSchema>;
const bankPaymentErrorSchema = yupObject({
  name: yupString(),
  code: yupString(),
  action: yupString(),
  description: yupString(),
});

export type BankAccountVerificationStatusType =
  (typeof bankAccountVerificationStatuses)[number];

export type SuperPayBankAccountDTO = InferType<typeof bankAccountSchema>;

export const bankAccountSchema = yupObject({
  bank_name: yupString().nullable(true),
  display_number: yupString().nullable(true),
  created_at: yupDateString('DateISO'),
  microdeposit_sent_at: yupDateString('DateISO'),
  verification_available_date: yupDateString('DateISO'),
  is_verification_available: yupBoolean().required(),
  verification_status: yupEnum(bankAccountVerificationStatuses),
  verification_error_count: yupNumber().nullable(),
  payment_error: bankPaymentErrorSchema.nullable(),
});

export type SuperPaySettingsDTO = InferType<typeof superPaySettingsSchema>;
export const superPaySettingsSchema = yupObject({
  bank_accounts: yupArray(bankAccountSchema),
  verification_step: yupEnum(verificationSteps),
});

export type SuperPayInvitesShippersDTO = InferType<
  typeof superPayInvitesShippersSchema
>;
export const superPayInvitesShippersSchema = yupObject({
  shippers_to_invite: yupArray(
    yupObject({
      name: yupString(),
      guid: yupString(),
      loads_count: yupNumber(),
    }),
  ).defined(),
});

export type SuperPayExpeditedPayDTO = InferType<
  typeof superPayExpeditedPaySchema
>;
export const superPayExpeditedPaySchema = yupObject({
  current_price: yupNumber(),
  current_terms: yupString(),
  current_expected_date: yupDateString('DateISO'),
  fee_percent: yupNumber(),
  fee_amount: yupNumber(),
  new_price: yupNumber(),
  new_expected_date: yupDateString('DateISO'),
});
