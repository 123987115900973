import { AppBar, Dialog, IconButton, Toolbar } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { Box, TextBox } from '@superdispatch/ui-lab';
import { useEffect, useRef } from 'react';
import { LoadBOLPReviewParams, useLoadBOLPreview } from '../data/LoadsAPI';

interface LoadBOLPreviewDialogContentProps {
  onClose: () => void;
  params: LoadBOLPReviewParams;
  loadGUID: null | string | undefined;
}

function LoadBOLPreviewDialogContent({
  params,
  onClose,
  loadGUID,
}: LoadBOLPreviewDialogContentProps) {
  const ref = useRef<HTMLDivElement | null>(null);
  const { data } = useLoadBOLPreview(loadGUID, params);

  useEffect(() => {
    if (data?.html && ref.current) {
      ref.current.innerHTML = data.html;
    }
  }, [data]);

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if ((event.metaKey || event.ctrlKey) && event.key === 'p' && data?.html) {
        event.preventDefault();
        printHTMLContent(data.html);
      }
    };

    window.addEventListener('keydown', handleKeyPress);
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [data?.html]);

  return (
    <>
      <AppBar position="fixed">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="close"
            onClick={onClose}
          >
            <Close />
          </IconButton>
          <TextBox variant="heading-3">BOL Preview</TextBox>
        </Toolbar>
      </AppBar>

      <Box
        width="21cm"
        height="100%"
        marginLeft="auto"
        marginRight="auto"
        marginTop="xxlarge"
        marginBottom="small"
        paddingTop="xxlarge"
      >
        <div ref={ref} id="bol-preview" />
      </Box>
    </>
  );
}

export interface LoadBOLPreviewDialogProps
  extends LoadBOLPreviewDialogContentProps {
  open: boolean;
}

export function LoadBOLPreviewDialog({
  open,
  onClose,
  ...props
}: LoadBOLPreviewDialogProps) {
  return (
    <Dialog open={open} onClose={onClose} fullScreen={true}>
      <LoadBOLPreviewDialogContent {...props} onClose={onClose} />
    </Dialog>
  );
}

function printHTMLContent(html: string) {
  // Open a new window for printing
  const printWindow = window.open('', 'print') as Window;
  // Write the entire HTML document to the new window
  printWindow.document.open();
  printWindow.document.write(html);
  printWindow.document.close();
  //Timeout for getting the content css and js
  setTimeout(() => {
    printWindow.print();
    printWindow.close();
  }, 500);
}
