import { createSvgIcon } from 'shared/icons/IconUtils';

export const BusinessIcon = createSvgIcon(
  'BusinessTypeBusiness',
  <path
    fill="currentColor"
    d="M7 2.667V0H.333v12h13.334V2.667H7Zm-4 8H1.667V9.333H3v1.334ZM3 8H1.667V6.667H3V8Zm0-2.667H1.667V4H3v1.333Zm0-2.666H1.667V1.333H3v1.334Zm2.667 8H4.333V9.333h1.334v1.334Zm0-2.667H4.333V6.667h1.334V8Zm0-2.667H4.333V4h1.334v1.333Zm0-2.666H4.333V1.333h1.334v1.334Zm6.666 8H7V9.333h1.333V8H7V6.667h1.333V5.333H7V4h5.333v6.667ZM11 5.333H9.667v1.334H11V5.333ZM11 8H9.667v1.333H11V8Z"
  />,
  { viewBox: '0 -1 15 15' },
);
