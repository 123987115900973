import { useEffect, useState } from 'react';
import { useMoovAPI } from '../data/MoovAPI';

export function useMoovAvailable() {
  const { isAvailable } = useMoovAPI();
  const [isMoovAvailable, setIsMoovAvailable] = useState<boolean | null>(null);

  useEffect(() => {
    isAvailable()
      .then((value) => {
        setIsMoovAvailable(value);
      })
      .catch(() => {
        setIsMoovAvailable(false);
      });
  }, [isAvailable]);

  return { isMoovAvailable, isLoading: isMoovAvailable === null };
}
