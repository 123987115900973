import { Info } from '@material-ui/icons';
import { useFormikEnhanced } from '@superdispatch/forms';
import {
  Color,
  Column,
  Columns,
  DrawerActions,
  Stack,
  useSnackbarStack,
} from '@superdispatch/ui';
import { Alert, Box, Button } from '@superdispatch/ui-lab';
import { SlowFormikDrawer } from 'shared/form/SlowFormikDrawer';
import {
  useBillingAddress,
  useSubscriptionAPI,
} from 'shared/modules/subscription/SubscriptionAPI';
import { useCarrierInfo } from '../carrier-profile/CarrierInfo';
import { SubscriptionBillingForm } from './core/SubscriptionBillingForm';
import { billingAddressFormErrorsGetter } from './core/SubscriptionUtils';
import { useShowAddBillingAddressDialog } from './core/useShowAddBillingAddressDialog';
import { trackSubscriptionEvent } from './data/SubscriptionAnalytics';
import { subscriptionSchema } from './SubscriptionDTO';

export interface BillingLocationState {
  enableReactivation?: boolean;
  canAddBilling?: boolean;
}

interface SubscriptionEditBillingAddressDrawerProps
  extends BillingLocationState {
  open: boolean;
  onClose: () => void;
  onSubmitSuccess?: () => void;
}

export function SubscriptionEditBillingAddressDrawer({
  open,
  onClose,
  enableReactivation = false,
  onSubmitSuccess,
  canAddBilling = false,
}: SubscriptionEditBillingAddressDrawerProps) {
  const { addSnackbar } = useSnackbarStack();
  const { data: billingAddress } = useBillingAddress();
  const { saveBillingAddress, reactivateSubscription } = useSubscriptionAPI();
  const { doesBillingAddressNotExist } = useShowAddBillingAddressDialog();
  const { data: carrierInfo } = useCarrierInfo({
    enabled: doesBillingAddressNotExist && canAddBilling,
  });

  const formik = useFormikEnhanced({
    key: open,
    validateOnBlur: false,
    validationSchema: subscriptionSchema,
    initialValues: {
      zip: billingAddress?.billing_zip || carrierInfo?.zip || '',
      country: billingAddress?.billing_country || carrierInfo?.country || 'US',
      state: billingAddress?.billing_state || carrierInfo?.state || '',
      city: billingAddress?.billing_city || carrierInfo?.city || '',
      address: billingAddress?.billing_address || carrierInfo?.address || '',
    },
    getFormErrors: billingAddressFormErrorsGetter,
    onSubmit: async (values) => {
      await saveBillingAddress({
        billing_country: values.country,
        billing_zip: values.zip,
        billing_state: values.state,
        billing_city: values.city,
        billing_address: values.address,
      });

      if (enableReactivation) {
        await reactivateSubscription();
      }
    },
    onSubmitSuccess: () => {
      onClose();
      onSubmitSuccess?.();

      trackSubscriptionEvent({
        name: 'Carrier Edited Subscription Billing Address',
      });

      if (enableReactivation) {
        trackSubscriptionEvent({ name: 'Carrier Reactivated Subscription' });
      }

      addSnackbar('Billing Address was successfully updated', {
        variant: 'success',
      });
    },
  });

  return (
    <SlowFormikDrawer
      formik={formik}
      open={open}
      onClose={onClose}
      title={canAddBilling ? 'Add Billing Address' : 'Edit Billing Address'}
      actions={
        <Stack space="small">
          <DrawerActions>
            <Columns align="center">
              <Column width="fluid">
                <Button type="submit" pending={formik.isSubmitting}>
                  {enableReactivation ? 'Continue to Reactivation' : 'Save'}
                </Button>
              </Column>
            </Columns>
          </DrawerActions>

          {formik.status.type === 'rejected' && (
            <Box maxWidth="500px" paddingRight="large" paddingLeft="large">
              <Alert
                icon={<Info fontSize="small" htmlColor={Color.Red500} />}
                severity="critical"
              >
                {formik.status.payload.message}
              </Alert>
            </Box>
          )}
        </Stack>
      }
    >
      <SubscriptionBillingForm />
    </SlowFormikDrawer>
  );
}
