import { Divider, Typography } from '@material-ui/core';
import { ThumbUp } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import { Column, Columns, InfoCard, Inline, Stack } from '@superdispatch/ui';
import { useState } from 'react';
import { trackEvent } from 'shared/helpers/Analytics';
import { useMutationObserver } from 'shared/helpers/BrowserHelpers';
import { logInfo } from 'shared/helpers/ErrorTracker';
import { formatNumber } from 'shared/helpers/IntlHelpers';
import { VerificationBadge } from 'shared/modules/carrier-profile/core/VerificationBadge';
import { CarrierAvatar } from '../../ratings/CarrierAvatar';
import { CarrierRatingsSummaryDTO } from '../../ratings/data/CarrierRatingsDTO';
import {
  CarrierOverviewDecliningReasons,
  CarrierOverviewRevokingReasons,
} from '../core/CarrierOverviewReasons';
import { CarrierPerformanceIndicators } from './CarrierPerformanceIndicators';

interface Props {
  carrierRatingsSummary?: CarrierRatingsSummaryDTO;
}

export function CarrierOverviewHeader({ carrierRatingsSummary }: Props) {
  const [node, setNode] = useState<HTMLElement | null>(null);

  useMutationObserver(
    node,
    () => {
      logInfo('Carrier Overview Header Rendered', {
        content: node?.textContent,
        carrier_name: carrierRatingsSummary?.carrier_name,
      });
      trackEvent('CTMS: Carrier Overview Header Rendered', {
        content: node?.textContent,
        carrier_name: carrierRatingsSummary?.carrier_name,
      });
    },
    {
      attributes: true,
      childList: true,
      subtree: true,
      characterData: true,
    },
  );

  return (
    <InfoCard size="large">
      <Stack space="small">
        <Columns
          space="small"
          align={['top', 'center', 'center']}
          collapseBelow="tablet"
        >
          <Column width="content">
            <CarrierAvatar size="large" />
          </Column>

          <Column>
            {carrierRatingsSummary ? (
              <Inline verticalAlign="center">
                <Typography
                  variant="h1"
                  ref={(ref) => {
                    setNode(ref);
                  }}
                >
                  {carrierRatingsSummary.carrier_name}
                </Typography>
                <VerificationBadge />
              </Inline>
            ) : (
              <Skeleton width="300px" height="40px" />
            )}
          </Column>

          <Column width="content">
            {carrierRatingsSummary ? (
              <Inline space="xxsmall" verticalAlign="center">
                <ThumbUp fontSize="small" color="action" />

                <div>
                  <Typography
                    aria-label="rating percentage"
                    variant="h3"
                    component="span"
                  >
                    {carrierRatingsSummary.rating.percentage}%
                  </Typography>
                  &nbsp;
                  <Typography
                    aria-label="ratings count"
                    color="textSecondary"
                    component="span"
                  >
                    ({formatNumber(carrierRatingsSummary.rating.count)})
                  </Typography>
                </div>
              </Inline>
            ) : (
              <Skeleton width="80px" height="40px" />
            )}
          </Column>
        </Columns>

        <CarrierOverviewDecliningReasons />

        <CarrierOverviewRevokingReasons />

        <Divider />

        <CarrierPerformanceIndicators />
      </Stack>
    </InfoCard>
  );
}
