import {
  Card,
  CardContent,
  CircularProgress,
  IconButton,
  Paper,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import {
  Column,
  Columns,
  Inline,
  Stack,
  useSnackbarStack,
} from '@superdispatch/ui';
import { AnchorButton } from '@superdispatch/ui-lab';
import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { formatPlural } from 'shared/helpers/IntlHelpers';
import { DocumentTitle } from 'shared/layout/DocumentTitle';
import { PageLayout } from 'shared/layout/PageLayout';
import { LinkButton } from 'shared/routing/Links';
import { useMatch, useNavigate } from 'shared/routing/react-router-6';
import styled from 'styled-components';
import { useTrip } from '../data/TripsAPI';
import { formatLoadStatusList } from '../data/TripsDTO';
import { AddLoadsToTripDialog } from './add-loads-to-trip-dialog/AddLoadsToTripDialog';
import { AssignTripToDriverDialog } from './AssignTripToDriverDialog';
import { EmptyTripLoadsTable } from './EmptyTripLoadsTable';
import { RemoveTripLoadDialog } from './RemoveTripLoadDialog';
import { TripAssignDriver } from './TripAssignDriver';
import { TripLoadsTable } from './TripLoadsTable';
import { TripNameEdit } from './TripNameEdit';
import { UnassignTripDriverDialog } from './UnassignTripDriverDialog';

const Wrapper = styled.div`
  flex-grow: 1;

  @media print {
    @page {
      size: landscape;
    }

    .MuiAppBar-root {
      display: none;
    }

    #detail-cards-wrapper > div {
      & > div {
        max-width: 50%;
        flex-basis: 50%;
      }
      & > div > div {
        height: 100%;

        a {
          display: none;
        }
      }
    }

    #empty-table-subtitle,
    #empty-table-add-loads-link {
      display: none;
    }

    .MuiTable-root th.table-row-actions-column,
    .MuiTable-root td.table-row-actions-column {
      display: none;
    }

    .MuiTextField-root {
      flex-direction: row;

      label {
        margin: 0;
      }

      label:after {
        content: ':';
      }

      & > div {
        flex: 1;

        div,
        input {
          padding: 0 0 0 4px;
          height: initial;
          font-weight: 600;
        }
        fieldset,
        svg {
          display: none;
        }
      }
    }
  }
`;

const TableWrapper = styled(Paper)`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
`;

const FeesWrapper = styled(Paper)`
  padding: 16px 16px 22px;
  height: 100%;
`;

const ProgressContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 48px;
`;

export function TripDetailsPage() {
  const navigate = useNavigate();
  const { guid = '' } = useParams<{ guid: string }>();
  const { addSnackbar } = useSnackbarStack();
  const { data: trip, error } = useTrip(guid);

  const statuses = useMemo(
    () => formatLoadStatusList(trip?.loads || []),
    [trip],
  );

  useEffect(() => {
    if (error) {
      addSnackbar('Error occurred while fetching trip details', {
        variant: 'error',
      });
    }
  }, [addSnackbar, error]);

  const url = `/trips/view/${guid}`;
  const assignTripRouteMatch = useMatch<{ driverGUID: string }>(
    `${url}/assign/:driverGUID`,
  );
  const unasignTripRouteMatch = useMatch(`${url}/unassign`);
  const deleteTripLoadRouteMatch = useMatch<{ loadGUID: string }>(
    `${url}/loads/delete/:loadGUID`,
  );
  const addTripLoadRouteMatch = useMatch(`${url}/loads/add`);

  const onModalClose = () => {
    navigate(url);
  };

  const onModalSubmitSuccess = () => {
    navigate(url, { replace: true });
  };

  return (
    <Wrapper>
      <AssignTripToDriverDialog
        isOpen={assignTripRouteMatch != null}
        nextDriverGUID={assignTripRouteMatch?.params.driverGUID}
        currentTripGUID={guid}
        onClose={onModalClose}
        onSubmitSuccess={onModalSubmitSuccess}
      />

      <UnassignTripDriverDialog
        isOpen={unasignTripRouteMatch != null}
        currentTripGUID={guid}
        onClose={onModalClose}
        onSubmitSuccess={onModalSubmitSuccess}
      />

      <RemoveTripLoadDialog
        isOpen={deleteTripLoadRouteMatch != null}
        redirectUrl={url}
        loadGUID={deleteTripLoadRouteMatch?.params.loadGUID}
        currentTripGUID={guid}
        onClose={onModalClose}
      />

      <AddLoadsToTripDialog
        isOpen={addTripLoadRouteMatch != null}
        onClose={onModalClose}
        currentTripGUID={guid}
        currentTripName={trip?.name}
      />

      <DocumentTitle title={(trip?.name || 'Trip') + ' - Trips'} />

      <PageLayout
        stickyHeader={true}
        header={
          <Toolbar>
            <Columns space="none" align="center">
              <Column width="fluid">
                <Inline space="xsmall" verticalAlign="center">
                  <IconButton
                    size="small"
                    onClick={() => {
                      navigate('/trips');
                    }}
                  >
                    <ArrowBack />
                  </IconButton>
                  <Typography variant="h2">{trip?.name || 'Trip'}</Typography>
                </Inline>
              </Column>

              {trip &&
                !trip.archived &&
                trip.loads &&
                trip.loads.length > 0 && (
                  <Column width="content">
                    <LinkButton variant="default" to={`${url}/loads/add`}>
                      Add Load
                    </LinkButton>
                  </Column>
                )}
            </Columns>
          </Toolbar>
        }
      >
        <div id="detail-cards-wrapper">
          <Columns collapseBelow="tablet" space="small">
            <Column width={['fluid', '1/2', '1/3']}>
              <Card>
                <CardContent>
                  <Stack space="small">
                    {!trip ? (
                      <Skeleton width="100%" height="50px" />
                    ) : (
                      <TripNameEdit
                        initialTripName={trip.name}
                        tripGUID={trip.guid}
                        isArchived={trip.archived}
                      />
                    )}
                    {!trip ? (
                      <Skeleton width="100%" height="50px" />
                    ) : (
                      <TripAssignDriver path={url} trip={trip} />
                    )}
                  </Stack>
                </CardContent>
              </Card>
            </Column>
            <Column width={['fluid', '1/2', '1/3']}>
              <FeesWrapper>
                <Stack space="none">
                  <Typography>
                    {!trip ? (
                      <Skeleton width="100px" height="16px" />
                    ) : (
                      <>
                        Total Price: <b>{trip.total_price}</b>
                      </>
                    )}
                  </Typography>
                  <Typography>
                    {!trip ? (
                      <Skeleton width="100px" height="16px" />
                    ) : (
                      <>
                        Broker Fee: <b>{trip.broker_fee_sum}</b>
                      </>
                    )}
                  </Typography>
                  <Typography gutterBottom={true}>
                    {!trip ? (
                      <Skeleton width="100px" height="16px" />
                    ) : (
                      <>
                        Expenses: <b>{trip.expenses_sum}</b>
                      </>
                    )}
                  </Typography>
                  <Typography gutterBottom={true}>
                    {!trip ? (
                      <Skeleton width="100px" height="16px" />
                    ) : trip.loads?.length ? (
                      <>
                        {trip.loads.length}&nbsp;
                        {formatPlural(trip.loads.length, 'load', 'loads')}
                        :&nbsp;
                        <b>{statuses}</b>
                      </>
                    ) : (
                      'No loads'
                    )}
                  </Typography>
                  {trip?.map_route_url && (
                    <AnchorButton href={trip.map_route_url} target="_blank">
                      Route map
                    </AnchorButton>
                  )}
                </Stack>
              </FeesWrapper>
            </Column>
          </Columns>
        </div>

        <TableWrapper aria-label="Trips Loads Table">
          {!trip ? (
            <ProgressContainer>
              <CircularProgress />
            </ProgressContainer>
          ) : trip.loads?.length ? (
            <TripLoadsTable
              path={url}
              tripGUID={guid}
              loads={trip.loads}
              isTripArchived={!!trip.archived}
            />
          ) : (
            <EmptyTripLoadsTable
              addLoadLink={!trip.archived ? `${url}/loads/add` : undefined}
            />
          )}
        </TableWrapper>
      </PageLayout>
    </Wrapper>
  );
}
