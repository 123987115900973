import { TableCell, TableCellProps } from '@material-ui/core';
import styled, { css } from 'styled-components';

const StyledInputTableCell = styled(TableCell)(
  ({ width, padding }) => css`
    min-width: ${width};
    max-width: ${width};
    vertical-align: top;
    box-sizing: border-box;

    ${padding !== 'none' &&
    css`
      padding: 16px 16px 16px 0;

      &:first-child {
        padding-left: 16px;
      }
    `}
  `,
);

export function InputTableCell(
  props: Pick<TableCellProps, 'children' | 'aria-label' | 'padding' | 'width'>,
) {
  return <StyledInputTableCell {...props} />;
}
