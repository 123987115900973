import { LinearProgress } from '@material-ui/core';
import { Autocomplete, AutocompleteProps } from '@material-ui/lab';
import { useResponsiveValue } from '@superdispatch/ui';
import {
  Children,
  forwardRef,
  HTMLAttributes,
  ReactElement,
  RefObject,
} from 'react';
import { ListChildComponentProps, VariableSizeList } from 'react-window';
import styled from 'styled-components';

const ListBoxProgress = styled(LinearProgress)`
  left: 0;
  right: 0;
  bottom: 8px;
  position: absolute;
`;

interface ListBoxItemProps extends ListChildComponentProps {
  data: ReactElement[];
}

function ListBoxItem({ data, index, style }: ListBoxItemProps) {
  // Autocomplete passed `li` elements, but we want to use `div` elements and
  // just pass `li` props.
  return <div {...data[index]?.props} style={style} />;
}

interface ListBoxProps extends HTMLAttributes<HTMLElement> {
  loading?: boolean;
  onScrollEnd?: () => void;
  listRef?: RefObject<VariableSizeList>;
  calculateItemSize?: (index: number, responsiveSize: number) => number;
}

const ListBox = forwardRef<HTMLDivElement, ListBoxProps>(
  (
    { children, loading, onScrollEnd, calculateItemSize, listRef, ...props },
    ref,
  ) => {
    const items = Children.toArray(children);
    const responsiveItemSize = useResponsiveValue(48, 36);
    const height = Math.min(items.length, 8) * responsiveItemSize;

    return (
      <div ref={ref} {...props}>
        {loading && <ListBoxProgress />}

        <VariableSizeList
          ref={listRef}
          width="100%"
          height={height}
          itemData={items}
          itemSize={(index) =>
            calculateItemSize?.(index, responsiveItemSize) ?? responsiveItemSize
          }
          itemCount={items.length}
          onItemsRendered={({ visibleStopIndex, overscanStopIndex }) => {
            if (visibleStopIndex === overscanStopIndex) {
              onScrollEnd?.();
            }
          }}
        >
          {ListBoxItem}
        </VariableSizeList>
      </div>
    );
  },
);

ListBox.displayName = 'ListBox';

export type VirtualizedAutocompleteProps<
  T,
  TMultiple extends boolean | undefined,
  TDisableClearable extends boolean | undefined,
  TFreeSolo extends boolean | undefined,
> = Omit<
  AutocompleteProps<T, TMultiple, TDisableClearable, TFreeSolo>,
  'ListboxComponent' | 'ListboxProps'
> &
  Pick<ListBoxProps, 'onScrollEnd' | 'listRef' | 'calculateItemSize'>;

export function VirtualizedAutocomplete<
  T,
  TMultiple extends boolean | undefined,
  TDisableClearable extends boolean | undefined,
  TFreeSolo extends boolean | undefined,
>({
  listRef,
  loading,
  onScrollEnd,
  calculateItemSize,
  ...props
}: VirtualizedAutocompleteProps<T, TMultiple, TDisableClearable, TFreeSolo>) {
  return (
    <Autocomplete
      {...props}
      loading={loading}
      ListboxComponent={ListBox}
      ListboxProps={{ listRef, loading, onScrollEnd, calculateItemSize }}
    />
  );
}
