export type CanadaProvince = (typeof CanadaProvinceOptions)[number];

export const CanadaProvinceOptions = [
  'Alberta',
  'British Columbia',
  'Manitoba',
  'New Brunswick',
  'Newfoundland and Labrador',
  'Northwest Territories',
  'Nova Scotia',
  'Nunavut',
  'Ontario',
  'Prince Edward Island',
  'Quebec',
  'Saskatchewan',
  'Yukon',
] as const;

export const CanadaProvinceOptionsSet: Set<CanadaProvince> =
  new Set<CanadaProvince>(CanadaProvinceOptions);
