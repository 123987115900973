import { Link, Typography } from '@material-ui/core';
import {
  FormikCheckboxField,
  FormikPasswordField,
  FormikTextField,
  SuspendedFormikPhoneField,
  useFormikEnhanced,
} from '@superdispatch/forms';
import { Inline, Stack, useSnackbarStack } from '@superdispatch/ui';
import { Box, Button } from '@superdispatch/ui-lab';
import { Form, FormikProvider } from 'formik';
import { useMemo } from 'react';
import { openExternalURL } from 'shared/helpers/URLHelpers';
import { PasswordValidationStepper } from 'shared/ui/PasswordValidationStepper';
import { validatePasswordStrength } from 'shared/utils/PasswordUtils';
import {
  DispatcherAcceptInvitationResponse,
  useDispatchersAPI,
} from '../data/DispatchersAPI';
import {
  dispatcherActivationSchema,
  DispatcherInvitationDTO,
} from '../data/DispatchersDTO';

interface DispatcherInvitationFormProps {
  invitationCode: string;
  dispatcher?: DispatcherInvitationDTO;
}

export function DispatcherInvitationForm({
  invitationCode,
  dispatcher,
}: DispatcherInvitationFormProps) {
  const { addSnackbar } = useSnackbarStack();
  const { acceptInvite } = useDispatchersAPI();

  const formik = useFormikEnhanced({
    validationSchema: dispatcherActivationSchema,
    initialValues: {
      email: dispatcher?.email,
      phone_number: dispatcher?.phone_number,
      password: '',
      full_name: '',
      is_subscribed: false,
    },
    onSubmit(values) {
      return acceptInvite(invitationCode, {
        email: values.email || '',
        phone_number: values.phone_number || '',
        password: values.password,
        full_name: values.full_name,
        is_subscribed: values.is_subscribed,
      });
    },
    onSubmitSuccess(response: DispatcherAcceptInvitationResponse) {
      if (response.url) {
        openExternalURL(response.url, { target: '_self' });
      }
    },
    onSubmitFailure: (error) => {
      addSnackbar(error.message, { variant: 'error' });
    },
  });

  const passwordstrength = useMemo(
    () => validatePasswordStrength(formik.values.password),
    [formik.values.password],
  );

  return (
    <FormikProvider value={formik}>
      <Form>
        <Stack space="small">
          <Inline horizontalAlign="center">
            <Box
              padding="large"
              marginTop="large"
              maxWidth="440px"
              borderRadius="small"
              borderWidth="small"
              borderColor="Silver400"
              backgroundColor="White"
            >
              <Stack space="medium">
                <Stack space="small">
                  <Typography variant="h2">Let’s Set You Up!</Typography>
                  <Typography>
                    Let&apos;s get to know each other better so we can add you
                    to <b>{dispatcher?.carrier_name}</b>
                  </Typography>
                </Stack>
                <Stack space="small">
                  <FormikTextField
                    fullWidth={true}
                    name="full_name"
                    label="Full Name"
                  />
                  <FormikTextField
                    type="email"
                    name="email"
                    label="Email"
                    disabled={true}
                    fullWidth={true}
                  />
                  <SuspendedFormikPhoneField
                    fullWidth={true}
                    name="phone_number"
                    label="Phone Number"
                  />

                  <Stack space="xsmall">
                    <FormikPasswordField
                      label="Password"
                      name="password"
                      fullWidth={true}
                      autoFocus={true}
                    />

                    <PasswordValidationStepper
                      passwordstrength={passwordstrength}
                      value={formik.values.password}
                    />
                  </Stack>
                </Stack>

                <FormikCheckboxField
                  name="is_subscribed"
                  label={
                    <Typography variant="caption">
                      I agree to receive SMS and Email messages from Super
                      Dispatch such as the latest offers and deals.
                    </Typography>
                  }
                />

                <Stack space="small">
                  <Button
                    type="submit"
                    size="large"
                    fullWidth={true}
                    pending={formik.isSubmitting}
                    disabled={!formik.dirty}
                  >
                    Join The Carrier
                  </Button>
                </Stack>
              </Stack>
            </Box>
          </Inline>
          <Stack align="center" space="xxsmall">
            <Typography variant="caption" color="textSecondary">
              By creating account you agree to Super Dispatch’s
            </Typography>
            <Inline space="xxsmall">
              <Link
                rel="noreferrer"
                target="_blank"
                variant="caption"
                href="https://superdispatch.com/terms-of-service"
              >
                Terms & Conditions
              </Link>{' '}
              <Typography variant="caption" color="textSecondary">
                and
              </Typography>{' '}
              <Link
                rel="noreferrer"
                target="_blank"
                variant="caption"
                href="https://superdispatch.com/privacy-policy/"
              >
                Privacy Policy
              </Link>
            </Inline>
          </Stack>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
