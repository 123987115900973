import { Column, Columns, Stack } from '@superdispatch/ui';
import { Box, Button, TextBox } from '@superdispatch/ui-lab';
import { trackEvent } from 'shared/helpers/Analytics';
import { formatCurrency } from 'shared/helpers/IntlHelpers';
import { openExternalURL } from 'shared/helpers/URLHelpers';
import { useSubscriptionSettings } from 'shared/modules/subscription/SubscriptionAPI';

export function PaywallSubscriptionPlan() {
  const { data: subscriptionDetails } = useSubscriptionSettings();

  if (!subscriptionDetails?.plan) return null;

  return (
    <Box backgroundColor="Silver200" borderRadius="small" padding="small">
      <Columns align="center">
        <Column width="1/2">
          <Stack space="xxsmall">
            <TextBox variant="heading-6">
              {subscriptionDetails.plan.type}
            </TextBox>
            <TextBox align="left" color="primary" variant="body-semibold">
              {formatCurrency(subscriptionDetails.plan.price)}
            </TextBox>
            <TextBox variant="caption" color="secondary">
              {formatCurrency(
                Number(subscriptionDetails.plan.price) /
                  Number(subscriptionDetails.plan.drivers_count),
              )}{' '}
              / driver / month x {subscriptionDetails.plan.drivers_count}{' '}
              drivers
            </TextBox>
          </Stack>
        </Column>
        <Column width="1/2">
          <Button
            fullWidth={true}
            variant="text"
            onClick={() => {
              openExternalURL('/tms/drivers', { target: '_blank' });
              trackEvent('Carrier Clicked Manage Drivers', {
                utm_medium: 'Paywall Dialog',
              });
            }}
          >
            Manage Drivers
          </Button>
        </Column>
      </Columns>
    </Box>
  );
}
