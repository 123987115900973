import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { LocalStore, useLocalStore } from 'shared/helpers/Store';

const INDICATOR_SEEN_DATE_KEY = 'loadboard_indicator_seen_date';
const ALLOWED_INDICATOR_SHOW_COUNT_KEY =
  'allowed_loadboard_indicator_show_count';

export function useLoadboardNudgeIndicator() {
  const indicatorSeenDate = useLocalStore(INDICATOR_SEEN_DATE_KEY);
  const allowedIndicatorShowCount = useLocalStore(
    ALLOWED_INDICATOR_SHOW_COUNT_KEY,
    '10',
  );
  const isSeenToday = useMemo(
    () =>
      DateTime.fromISO(indicatorSeenDate || '')
        .startOf('day')
        .equals(DateTime.local().startOf('day')),
    [indicatorSeenDate],
  );

  const markIndicatorAsSeen = () => {
    if (Number(allowedIndicatorShowCount) > 0 && !isSeenToday) {
      LocalStore.set(INDICATOR_SEEN_DATE_KEY, new Date().toISOString());
      LocalStore.set(
        ALLOWED_INDICATOR_SHOW_COUNT_KEY,
        String(Number(allowedIndicatorShowCount) - 1),
      );
    }
  };

  return {
    isIndicatorVisible: Number(allowedIndicatorShowCount) > 0 && !isSeenToday,
    markIndicatorAsSeen,
  };
}
