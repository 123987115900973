import { Color } from '@superdispatch/ui';
import { createSvgIcon } from 'shared/icons/IconUtils';

export const CheckIcon = createSvgIcon(
  'CheckIcon',
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM15.7321 8.08231L10.3805 13.3421L8.27416 11.2699L6.96035 12.5533L10.3804 15.9178L17.0386 9.36764L15.7321 8.08231Z"
    fill={Color.Green300}
  />,
  { viewBox: '0 0 24 24', style: { height: '24px', width: '24px' } },
);
