import { MenuItem, Typography } from '@material-ui/core';
import {
  FormikCheckboxField,
  FormikContextTypeEnhanced,
  FormikCurrencyField,
  FormikNumberField,
  FormikTextField,
} from '@superdispatch/forms';
import { formatVehicleType, VEHICLE_TYPES } from '@superdispatch/sdk';
import { Column, Columns, Stack, useSnackbarStack } from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import { useMemo, useState } from 'react';
import { useAppFormik } from 'shared/form/AppFormik';
import {
  FormikDrawer,
  FormikDrawerActions,
  FormikDrawerContent,
} from 'shared/form/FormikDrawer';
import { formatCurrency } from 'shared/helpers/IntlHelpers';
import {
  LoadVehicleCreateDTO,
  loadVehicleCreateSchema,
  LoadVehicleEditDTO,
  loadVehicleEditSchema,
} from '../data/LoadActionsDTO';
import { formatLoadStatus } from '../data/LoadDTO';
import { trackLoadsEvent } from '../data/LoadsAnalytics';
import { useLoadDetails, useLoadsCache } from '../data/LoadsAPI';
import { useVehiclesAPI } from '../data/VehiclesAPI';
import { DuplicateLoadsRecord } from '../data/VINsAPI';
import { useLoadStageFromBackURL } from '../view/ViewLoadHeaderActions';
import { DuplicatedVINsDialog } from './DuplicatedVINsDialog';
import { FormikVehicleMakeField } from './FormikVehicleMakeField';
import { FormikVehicleModelField } from './FormikVehicleModelField';
import { FormikVINDecoder } from './FormikVINDecoder';

interface BaseLoadVehicleDrawerProps {
  loadGUID: string;
  onClose: () => void;
}

interface LoadVehicleDrawerContentProps extends BaseLoadVehicleDrawerProps {
  title: string;
  autoFocusVIN?: boolean;

  formik: FormikContextTypeEnhanced<
    LoadVehicleCreateDTO | LoadVehicleEditDTO,
    unknown
  >;
}

function LoadVehicleDrawerContent({
  title,
  formik,
  onClose,
  autoFocusVIN,
}: LoadVehicleDrawerContentProps) {
  const [duplicateLoads, setDuplicateLoads] = useState<DuplicateLoadsRecord>();

  return (
    <>
      <DuplicatedVINsDialog
        duplicateVINs={duplicateLoads}
        onClose={() => {
          setDuplicateLoads(undefined);
        }}
      />

      <FormikDrawerContent
        title={title}
        formik={formik}
        onClose={onClose}
        actions={<FormikDrawerActions />}
      >
        <Stack space="large">
          <Stack space="small">
            <FormikCheckboxField
              name="is_inoperable"
              label="Vehicle is inoperable"
            />

            <Box width="60%">
              <FormikVINDecoder
                name="vin"
                label="VIN"
                autoFocus={autoFocusVIN}
                fullWidth={true}
                inputProps={{ maxLength: 17 }}
                onDecodeSuccess={(vehicle, duplicatedLoads) => {
                  if (duplicatedLoads.length) {
                    setDuplicateLoads({ [vehicle.vin]: duplicatedLoads });
                  }

                  void formik.setValues((values) =>
                    loadVehicleEditSchema.cast({ ...values, ...vehicle }),
                  );
                }}
              />
            </Box>

            <Columns space="xsmall">
              <Column width="1/3">
                <FormikNumberField
                  name="year"
                  label="Year"
                  fullWidth={true}
                  inputProps={{
                    thousandSeparator: '',
                    maxLength: 4,
                  }}
                />
              </Column>
              <Column width="2/3">
                <FormikVehicleMakeField
                  name="make"
                  label="Make"
                  fullWidth={true}
                  inputProps={{ maxLength: 200 }}
                />
              </Column>
            </Columns>

            <Columns space="xsmall">
              <Column width="1/2">
                <FormikVehicleModelField
                  vehicleMake={formik.values.make}
                  name="model"
                  label="Model"
                  fullWidth={true}
                  inputProps={{ maxLength: 200 }}
                />
              </Column>
              <Column width="1/2">
                <FormikTextField
                  name="type"
                  label="Type"
                  fullWidth={true}
                  select={true}
                >
                  {VEHICLE_TYPES.map((key) => (
                    <MenuItem key={key} value={key}>
                      {formatVehicleType(key)}
                    </MenuItem>
                  ))}
                </FormikTextField>
              </Column>
            </Columns>

            <Columns>
              <Column width="1/2">
                <FormikTextField
                  name="requires_enclosed_trailer"
                  label="Transport Type"
                  select={true}
                  fullWidth={true}
                >
                  <MenuItem value="false">Open</MenuItem>
                  <MenuItem value="true">Enclosed</MenuItem>
                </FormikTextField>
              </Column>
            </Columns>
          </Stack>

          <Stack space="small">
            <Typography variant="h3">Additional Details</Typography>

            <Box width="50%">
              <FormikTextField
                name="color"
                label="Color"
                fullWidth={true}
                inputProps={{ maxLength: 200 }}
              />
            </Box>

            <Box width="50%">
              <FormikCurrencyField
                name="price"
                label="Price"
                fullWidth={true}
              />
            </Box>

            <Box width="50%">
              <FormikTextField
                name="lot_number"
                label="Lot Number"
                fullWidth={true}
                inputProps={{ maxLength: 200 }}
              />
            </Box>
          </Stack>
        </Stack>
      </FormikDrawerContent>
    </>
  );
}

function AddLoadVehicleDrawerContent({
  onClose,
  loadGUID,
}: BaseLoadVehicleDrawerProps) {
  const { data } = useLoadDetails(loadGUID);
  const { createVehicle } = useVehiclesAPI();
  const { addSnackbar } = useSnackbarStack();
  const { invalidateLoads } = useLoadsCache();
  const loadStage = useLoadStageFromBackURL();
  const initialValues = useMemo(
    () => ({ load_guid: loadGUID, type: 'other' }),
    [loadGUID],
  );
  const formik = useAppFormik({
    initialValues,
    validationSchema: loadVehicleCreateSchema,
    onSubmit(values) {
      return createVehicle(values, { adjust_load_price: true });
    },
    onSubmitSuccess(_, values) {
      onClose();
      invalidateLoads();
      trackLoadsEvent({
        name: 'Carrier Edited Load',
        utm_medium: loadStage,
        utm_content: 'View Page',
        load_status: formatLoadStatus(data?.status),
        is_created_by_broker: !!data?.is_created_by_broker,
        vehicles: data?.vehicles.length === 0 ? 'Added' : 'Edited',
      });

      const loadTotalPrice = data?.payments.reduce(
        (sum, payment) => (!payment.price ? sum : sum + payment.price),
        0,
      );
      const vehiclesTotalPrice = data?.vehicles.reduce((sum, { price }) => {
        return !price ? sum : sum + price;
      }, 0);

      if (
        values.price &&
        loadTotalPrice != null &&
        loadTotalPrice === vehiclesTotalPrice
      ) {
        addSnackbar(
          `Vehicle added and the load price is changed to ${formatCurrency(
            vehiclesTotalPrice + values.price,
          )}`,
          { autoHideDuration: 12000 },
        );
      } else {
        addSnackbar('Vehicle added');
      }
    },

    getErrorMessage({ message }) {
      return (
        message ||
        'Something went wrong while updating vehicle information, contact support.'
      );
    },
  });

  return (
    <LoadVehicleDrawerContent
      formik={formik}
      onClose={onClose}
      loadGUID={loadGUID}
      title="Add Vehicle"
      autoFocusVIN={true}
    />
  );
}

interface EditLoadVehicleDrawerContentProps extends BaseLoadVehicleDrawerProps {
  vehicleGUID?: string;
}

function EditLoadVehicleDrawerContent({
  onClose,
  loadGUID,
  vehicleGUID,
}: EditLoadVehicleDrawerContentProps) {
  const { editVehicle } = useVehiclesAPI();
  const { addSnackbar } = useSnackbarStack();
  const { data } = useLoadDetails(loadGUID);
  const { invalidateLoads } = useLoadsCache();
  const vehicle = useMemo(
    () => data?.vehicles.find((v) => v.guid === vehicleGUID),
    [data?.vehicles, vehicleGUID],
  );
  const loadStage = useLoadStageFromBackURL();
  const currentVehiclePrice = vehicle?.price || 0;
  const initialValues = useMemo(
    () => ({ ...vehicle, load_guid: loadGUID }),
    [vehicle, loadGUID],
  );

  const formik = useAppFormik({
    initialValues,
    validationSchema: loadVehicleEditSchema,
    onSubmit(values) {
      const isVehiclePriceChanged = values.price !== initialValues.price;

      return editVehicle(values.guid, values, {
        adjust_load_price: isVehiclePriceChanged,
      });
    },
    onSubmitSuccess(_, values) {
      onClose();
      invalidateLoads();
      trackLoadsEvent({
        name: 'Carrier Edited Load',
        utm_medium: loadStage,
        utm_content: 'View Page',
        load_status: formatLoadStatus(data?.status),
        is_created_by_broker: !!data?.is_created_by_broker,
        vehicles: 'Edited',
      });

      const newPrice = values.price || 0;
      const loadTotalPrice = data?.payments.reduce(
        (sum, payment) => (!payment.price ? sum : sum + payment.price),
        0,
      );
      const vehiclesTotalPrice = data?.vehicles.reduce((sum, { price }) => {
        return !price ? sum : sum + price;
      }, 0);

      if (
        loadTotalPrice != null &&
        vehiclesTotalPrice === loadTotalPrice &&
        currentVehiclePrice !== newPrice
      ) {
        addSnackbar(
          `Vehicle edited and the load price is changed to ${formatCurrency(
            vehiclesTotalPrice - currentVehiclePrice + newPrice,
          )}`,
          { autoHideDuration: 12000 },
        );
      } else {
        addSnackbar('Vehicle edited');
      }
    },

    getErrorMessage({ message }) {
      return (
        message ||
        'Something went wrong while updating vehicle information, contact support.'
      );
    },
  });

  return (
    <LoadVehicleDrawerContent
      formik={formik}
      onClose={onClose}
      loadGUID={loadGUID}
      title="Edit Vehicle"
    />
  );
}

export type LoadVehicleDrawerProps = BaseLoadVehicleDrawerProps &
  ({ type: 'add'; open: boolean } | { type: 'edit'; vehicleGUID?: string });

export function LoadVehicleDrawer(props: LoadVehicleDrawerProps) {
  return (
    <FormikDrawer
      onClose={props.onClose}
      open={props.type === 'add' ? props.open : !!props.vehicleGUID}
    >
      {props.type === 'add' && <AddLoadVehicleDrawerContent {...props} />}
      {props.type === 'edit' && <EditLoadVehicleDrawerContent {...props} />}
    </FormikDrawer>
  );
}
