import { useResponsiveValue } from '@superdispatch/ui';
import { ReactNode, useEffect, useState } from 'react';
import { launchIntercomTour } from 'shared/helpers/Intercom';
import { useNavbarContext } from 'shared/layout/sidebar/NavbarContext';
import { useLoadSuggestionCountSubscription } from './data/LoadSuggestionsAPI';
import { LoadSuggestionsDrawer } from './LoadSuggestionsDrawer';

interface LoadSuggestionsButtonProps {
  render: (props: {
    loadSuggestionsCount?: number;
    onClick: () => void;
  }) => ReactNode;
}

export function LoadSuggestionsButton({ render }: LoadSuggestionsButtonProps) {
  const [isOpen, setIsOpen] = useState(false);
  const { setDrawerOpen } = useNavbarContext();
  const screen = useResponsiveValue('mobile', 'tablet', 'desktop');
  const isMobile = screen === 'mobile' || screen === 'tablet';
  const data = useLoadSuggestionCountSubscription();

  useEffect(() => {
    launchIntercomTour('load_suggestions_menu_item');
  }, []);

  return (
    <>
      <LoadSuggestionsDrawer
        open={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
      />

      {render({
        loadSuggestionsCount: data?.number_of_unseen_suggestions,
        onClick: () => {
          if (isMobile) {
            setDrawerOpen('suggested-loads');
          } else {
            setIsOpen(true);
          }
        },
      })}
    </>
  );
}
