import { parseDate } from '@superdispatch/dates';
import { useCallback, useMemo } from 'react';
import { LocalStore, useLocalStore } from 'shared/helpers/Store';
import { useCarrierSettings } from 'shared/settings/CarrierSettingsAPI';
import { useFlag } from 'shared/settings/FeatureToggles';
import { tryParseJSON } from 'shared/utils/DataUtils';
import { useBillingAddress } from '../SubscriptionAPI';

const SHOW_ADD_BILLING_ADDRESS_SENT_TIME_STORAGE_KEY =
  'show_add_billing_address_time';
const SHOW_ADD_BILLING_ADDRESS_DIALOG_EXPIRE_TIME = 24; //hours

export function useShowAddBillingAddressDialog() {
  const { data: settings } = useCarrierSettings();
  const carrierGuid = settings?.carrier.guid;
  const shouldShowAddBillingAddressDialog = useFlag(
    'complete_billing_address_warning',
  );
  const { data: billingAddress } = useBillingAddress();

  const isActive = !!settings?.is_paying;
  const isBillingAddressEmpty = !billingAddress?.billing_country;
  const shouldShowDialog =
    shouldShowAddBillingAddressDialog && isActive && isBillingAddressEmpty;

  const addBillingAddressSentTime = useLocalStore(
    SHOW_ADD_BILLING_ADDRESS_SENT_TIME_STORAGE_KEY,
  );

  const parsedLolRestrictionSentTime = useMemo(() => {
    if (addBillingAddressSentTime) {
      return new Map<string, string>(tryParseJSON(addBillingAddressSentTime));
    }
    return new Map<string, string>();
  }, [addBillingAddressSentTime]);

  const closeUpgradeWarningDialog = useCallback(() => {
    if (carrierGuid) {
      parsedLolRestrictionSentTime.set(carrierGuid, new Date().toISOString());

      LocalStore.set(
        SHOW_ADD_BILLING_ADDRESS_SENT_TIME_STORAGE_KEY,
        JSON.stringify(Array.from(parsedLolRestrictionSentTime.entries())),
      );
    }
  }, [carrierGuid, parsedLolRestrictionSentTime]);

  const sentTime = useMemo(() => {
    if (!carrierGuid || !addBillingAddressSentTime) {
      return undefined;
    }

    const restrictionSentTime = parsedLolRestrictionSentTime.get(carrierGuid);

    return (
      !!restrictionSentTime &&
      parseDate(restrictionSentTime, {
        format: 'DateTimeISO',
      })
    );
  }, [carrierGuid, parsedLolRestrictionSentTime, addBillingAddressSentTime]);

  const isOpenUpgradeWarningDialog = useMemo(() => {
    if (sentTime && shouldShowDialog) {
      return (
        Math.abs(sentTime.diffNow('hours').hours) >
        SHOW_ADD_BILLING_ADDRESS_DIALOG_EXPIRE_TIME
      );
    }
    return shouldShowDialog;
  }, [shouldShowDialog, sentTime]);

  return useMemo(
    () => ({
      isOpenUpgradeWarningDialog,
      closeUpgradeWarningDialog,
      doesBillingAddressNotExist: shouldShowDialog,
    }),
    [isOpenUpgradeWarningDialog, closeUpgradeWarningDialog, shouldShowDialog],
  );
}
