import { SidebarContainer } from '@superdispatch/ui-lab';
import { useEffect } from 'react';
import { LocalStore, useLocalStore } from 'shared/helpers/Store';
import { useExperimentState } from 'shared/modules/experiments/ExperimentsAPI';
import { useSubscriptionSettings } from 'shared/modules/subscription/SubscriptionAPI';
import { useFlag } from 'shared/settings/FeatureToggles';
import { DriverSubscriptionStatusBanner } from './core/DriverSubscriptionStatusBanner';
import { trackDriversEvent } from './data/DriversAnalytics';
import { DriversPageContextProvider } from './DriversPageContext';
import { DriversPageDetails } from './DriversPageDetails';
import { DriversPageList } from './DriversPageList';

const IS_DRIVER_ACTIVATION_BANNER_SEEN_STORAGE_KEY =
  'is_driver_activation_banner_seen';
const IS_FIRST_DRIVER_ADDED_STORAGE_KEY = 'is_first_driver_added';

function useDriverActivationBannerExperiment() {
  const { isInExperiment } = useExperimentState('Driver Activation');
  const driverActivationBannerStoageItem = useLocalStore(
    IS_DRIVER_ACTIVATION_BANNER_SEEN_STORAGE_KEY,
  );
  const firstDriverAddedStoageItem = useLocalStore(
    IS_FIRST_DRIVER_ADDED_STORAGE_KEY,
  );

  return {
    isInExperiment: isInExperiment && !driverActivationBannerStoageItem,
    isFirstTimeAddingDriver: firstDriverAddedStoageItem === 'true',
    setIsFirstTimeAddingDriverStatus: (value: string) => {
      LocalStore.set(IS_FIRST_DRIVER_ADDED_STORAGE_KEY, value);
    },
    markAsViewed: () => {
      LocalStore.set(IS_DRIVER_ACTIVATION_BANNER_SEEN_STORAGE_KEY, 'true');
    },
  };
}

export function DriversPage() {
  const {
    isFirstTimeAddingDriver,
    setIsFirstTimeAddingDriverStatus,
    markAsViewed,
  } = useDriverActivationBannerExperiment();
  const { data: subscription } = useSubscriptionSettings();
  const shouldShowTrialExpiredBanner = useFlag('trial_expired_banner');
  const isTrialExpired =
    subscription?.status === 'Canceled' ||
    (subscription?.status !== 'Active' && subscription?.status !== 'In Trial');

  useEffect(() => {
    trackDriversEvent({ name: 'CTMS: Opened React Drivers Page' });
  }, []);

  return (
    <DriversPageContextProvider>
      {shouldShowTrialExpiredBanner && isTrialExpired && (
        <DriverSubscriptionStatusBanner />
      )}
      <SidebarContainer sidebar={<DriversPageList />}>
        <DriversPageDetails
          isFirstDriverAdded={isFirstTimeAddingDriver}
          onInstrcutionsClose={() => {
            setIsFirstTimeAddingDriverStatus('false');
            markAsViewed();
          }}
        />
      </SidebarContainer>
    </DriversPageContextProvider>
  );
}
