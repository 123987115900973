import { useMemo, useState } from 'react';
import {
  PlanUpdateDrawer,
  useNextPlanEstimate,
  useShowPlanUpdateDrawer,
} from 'shared/modules/subscription/core/PlanUpdateDrawer';
import { useSubscriptionSettings } from 'shared/modules/subscription/SubscriptionAPI';
import { DriverInviteDrawerLoading } from '../../drivers/core/DriverInviteLoading';
import { DriverDTO } from '../../drivers/data/DriverDTO';
import { LoadDTO } from '../data/LoadDTO';
import {
  LoadAssignDrawer,
  LoadAssignDrawerStep,
  LoadAssignDrawerValues,
} from './LoadAssignDrawer';
import { PendingActivationDriverDialog } from './PendingActivationDriverDialog';

export interface LoadAssignProps {
  load?: LoadDTO;
  onClose: () => void;
  onSubmitSuccess: (values: LoadAssignDrawerValues) => void;
}

export function LoadAssign({
  load,
  onClose,
  onSubmitSuccess,
}: LoadAssignProps) {
  const { shouldShowPlanUpdateDrawer } = useShowPlanUpdateDrawer();
  const [createdDriver, setCurrentDriver] = useState<DriverDTO | null>(null);
  const [drawer, setDrawer] = useState<'assign_drawer' | 'paywall_drawer'>(
    'assign_drawer',
  );
  const [step, setStep] = useState<LoadAssignDrawerStep>('assign_driver');
  const [pendingActivationDriver, setPendingActivationDriver] = useState<
    DriverDTO | null | undefined
  >(null);

  const nextPlan = useNextPlanEstimate();
  const { data: subscription } = useSubscriptionSettings();

  const canInviteDriver = useMemo(() => {
    if (subscription?.plan) {
      const { plan } = subscription;
      const driversCount = Number(plan.drivers_count);
      const seatsCount = Number(plan.seats_count);
      return (
        seatsCount > driversCount && driversCount === 0 && seatsCount === 1
      );
    }
    return false;
  }, [subscription]);

  if (nextPlan.isFetching) {
    return <DriverInviteDrawerLoading open={true} onClose={onClose} />;
  }

  return (
    <>
      <PendingActivationDriverDialog
        onClose={() => {
          setPendingActivationDriver(null);
        }}
        driver={pendingActivationDriver}
      />

      <PlanUpdateDrawer
        open={!!load && drawer === 'paywall_drawer'}
        onClose={() => {
          setDrawer('assign_drawer');
        }}
        onSubmitSuccess={() => {
          setDrawer('assign_drawer');
          setStep('invite_driver');
        }}
      />

      <LoadAssignDrawer
        step={step}
        load={load}
        onSetStep={setStep}
        onSubmitSuccess={onSubmitSuccess}
        onInviteDriver={() => {
          if (shouldShowPlanUpdateDrawer) {
            void nextPlan.refetch().then(({ data }) => {
              if (data && !canInviteDriver) {
                setDrawer('paywall_drawer');
              } else {
                setStep('invite_driver');
              }
            });
            return;
          }
          setStep('invite_driver');
        }}
        onSetCurrentDriver={setCurrentDriver}
        onSetPendingActivationDriver={(driver) => {
          setPendingActivationDriver(driver);
        }}
        open={!!load && drawer === 'assign_drawer'}
        onClose={() => {
          onClose();
        }}
      />

      <PendingActivationDriverDialog
        driver={createdDriver}
        onClose={() => {
          setCurrentDriver(null);
        }}
      />
    </>
  );
}
