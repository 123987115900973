import type { GeocodeQueryType } from '@mapbox/mapbox-sdk/services/geocoding';
import {
  Chip,
  CircularProgress,
  InputAdornment,
  StandardTextFieldProps,
  TextField,
} from '@material-ui/core';
import {
  Autocomplete,
  AutocompleteChangeReason,
  AutocompleteProps,
} from '@material-ui/lab';
import { dequal } from 'dequal';
import { forwardRef, ReactNode, useState } from 'react';
import { Geocoding, isGeocoding } from 'shared/geo/GeoHelpers';
import { formatAddressLine } from 'shared/helpers/AddressHelpers';
import { useDebouncedValue } from 'shared/helpers/ReactHelpers';
import { useFlag } from 'shared/settings/FeatureToggles';
import styled from 'styled-components';
import { useGeoPredictions } from './GeoService';

interface GeoFieldCommonProps
  extends Omit<
    AutocompleteProps<Geocoding, true, true, false>,
    'renderInput' | 'defaultValue' | 'value'
  > {
  TextFieldProps?: StandardTextFieldProps;
}

export interface UseGeoFieldPropsOptions {
  types: GeocodeQueryType[];
  countries?: string[];
  formatOptionLabel?: (option: Geocoding) => string;
  proximity?: 'US' | 'CA';
  sortByCountry?: 'US' | 'CA';
}

export function useGeoFieldProps({
  types,
  countries,
  sortByCountry,
  formatOptionLabel = (option) =>
    formatAddressLine(
      option.postcode,
      option.place,
      option.region_short,
      option.country,
    ),
}: UseGeoFieldPropsOptions): [{ clearQuery: () => void }, GeoFieldCommonProps] {
  const isGeoFieldUpdateReasonEnabled = useFlag('prevent_extra_mapbox_request');
  const [isOpen, setIsOpen] = useState(false);
  const [query, setQuery] = useState<string | undefined>();
  const debouncedQuery = useDebouncedValue(query, 200);

  const { data: predictions = [], isLoading: isPredictionsLoading } =
    useGeoPredictions(debouncedQuery, types, countries, sortByCountry);
  const isLoading = isOpen && isPredictionsLoading;

  return [
    {
      clearQuery: () => {
        setQuery(undefined);
      },
    },
    {
      forcePopupIcon: false,
      onOpen: () => {
        setIsOpen(true);
      },
      onClose: () => {
        setIsOpen(false);
      },
      includeInputInList: true,
      getOptionLabel: (option: Geocoding | string) =>
        typeof option === 'string' ? option : formatOptionLabel(option),
      disableClearable: isLoading as true,
      loading: isLoading,
      options: predictions,
      getOptionSelected: dequal,
      onInputChange: (_, inputValue, reason) => {
        //skipping geocoding request for programmatic updates that usually happens on initial form filling.
        // do not need to show options for already filled address.
        if (isGeoFieldUpdateReasonEnabled && reason === 'reset') return;
        setQuery(inputValue);
      },
    },
  ];
}

const StartIcon = styled.div`
  display: flex;
  align-items: center;
  position: absolute;

  top: 0;
  bottom: 0;
  left: 8px;
`;

const MultipleGeoFieldText = styled(TextField)`
  /* Inline input next to tags rather than next line on focus */
  & .MuiInputBase-input:not(:focus) {
    min-width: auto;
  }

  &[data-start-icon='true'] .MuiOutlinedInput-adornedStart {
    padding-left: 40px;
  }
`;

export interface MapboxGeoFieldMultipleProps
  extends Omit<GeoFieldCommonProps, 'options' | 'onChange' | 'getOptionLabel'>,
    UseGeoFieldPropsOptions {
  value: Geocoding[];
  startIcon?: ReactNode;
  defaultValue?: Geocoding[];
  onChange?: (
    value: Geocoding[] | undefined,
    reason: AutocompleteChangeReason,
  ) => void;
}

export const MapboxGeoFieldMultiple = forwardRef(
  (
    {
      types,
      onChange,
      startIcon,
      TextFieldProps,
      formatOptionLabel,
      disableClearable = true,
      ...props
    }: MapboxGeoFieldMultipleProps,
    ref,
  ) => {
    const [{ clearQuery }, autocompleteProps] = useGeoFieldProps({
      types,
      formatOptionLabel,
    });

    return (
      <Autocomplete
        {...props}
        {...autocompleteProps}
        ref={ref}
        multiple={true}
        onChange={(_, selectedValue, reason) => {
          clearQuery();
          onChange?.(
            !Array.isArray(selectedValue)
              ? undefined
              : selectedValue.filter(isGeocoding),
            reason,
          );
        }}
        renderTags={(items: Geocoding[], getTagProps) =>
          items.map((option, index) => (
            <Chip
              key={index}
              label={formatAddressLine(
                option.postcode,
                option.place,
                option.region_short,
              )}
              {...getTagProps({ index })}
            />
          ))
        }
        renderInput={(params) => (
          <MultipleGeoFieldText
            {...params}
            {...TextFieldProps}
            data-start-icon={String(!!startIcon)}
            InputProps={{
              ...TextFieldProps?.InputProps,
              ...params.InputProps,
              startAdornment: (
                <>
                  {startIcon && <StartIcon>{startIcon}</StartIcon>}
                  {params.InputProps.startAdornment}
                </>
              ),
              endAdornment: autocompleteProps.loading ? (
                <InputAdornment position="end">
                  <CircularProgress color="inherit" size={20} />
                </InputAdornment>
              ) : (
                params.InputProps.endAdornment
              ),
            }}
            variant="outlined"
            fullWidth={true}
          />
        )}
      />
    );
  },
);

MapboxGeoFieldMultiple.displayName = 'MapboxGeoFieldMultiple';
