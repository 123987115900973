import { renderChildren } from '@superdispatch/ui';
import React from 'react';
import { useExperimentState } from 'shared/modules/experiments/ExperimentsAPI';
import { useFlag } from 'shared/settings/FeatureToggles';
import { VerifyCentralDispatchAccountPage } from './central-dispatch-integration/VerifyCentralDispatchAccountPage';

export function ExperimentsContainer({
  children,
}: {
  children: React.ReactNode;
}) {
  const { isExperimentRunning } = useExperimentState(
    'Central Dispatch Integration',
  );
  const isCentralDispatchExperimentEnabled = useFlag(
    'central_dispatch_experiment',
  );

  if (isExperimentRunning && isCentralDispatchExperimentEnabled) {
    return <VerifyCentralDispatchAccountPage />;
  }

  return renderChildren(children);
}
