import { LocalStore } from 'shared/helpers/Store';
import {
  FeatureToggle,
  FeatureToggles,
  useCarrierFeatures,
} from 'shared/settings/CarrierSettingsAPI';

/**
 * Each feature has 3 states:
 * 1. Not found (`undefined`) - feature is removed and should be enabled
 * 2. Found and equals `false` - feature should be disabled
 * 3. Found and equals `true` - feature should be enabled
 *
 * @param feature
 */
export function useFlag(feature: FeatureToggle): boolean {
  const features = useCarrierFeatures();
  return isFlagEnabled(features, feature);
}

export function isFlagEnabled(
  features: null | FeatureToggles,
  feature: FeatureToggle,
) {
  const isEnabled = features?.[feature];

  if (import.meta.env.MODE !== 'production') {
    const storageValue = LocalStore.get(`features:${feature}`);
    if (storageValue != null) return storageValue === 'true';
  }

  // We assume that feature flag was removed and should be enabled.
  if (typeof isEnabled != 'boolean') return true;

  return isEnabled;
}
