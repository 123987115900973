import { Typography } from '@material-ui/core';
import { Info } from '@material-ui/icons';
import {
  Color,
  Column,
  Columns,
  Inline,
  Stack,
  useUID,
} from '@superdispatch/ui';
import { TextBox } from '@superdispatch/ui-lab';
import { useMemo } from 'react';
import { formatNumber } from 'shared/helpers/IntlHelpers';
import { useVerifiedCarrierApplicationStatus } from 'shared/modules/carrier-profile/VerifiedCarrierApplicationStatus';
import { WebViewLink } from 'shared/routing/WebViewLinks';
import { BlurredBackdrop } from 'shared/ui/BlurredBackdrop';
import styled, { css } from 'styled-components';
import { calculateAverageRate, calculateRate } from './PerformanceHelpers';

export const CustomColumns = styled(Columns)`
  width: auto;
`;

export const IndicatorCardBase = styled.div`
  padding: 16px;
  border-radius: 4px;

  border: 1px solid ${Color.Silver200};
  color: ${Color.Dark300};
  transition: 0.2s ease-in-out;
`;

const SelectableIndicator = css`
  cursor: pointer;
  &:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 0 4px 0 rgba(0, 0, 0, 0.1);
  }
`;

const GoodIndicator = css`
  border: 1px solid ${Color.Green50};
  color: ${Color.Green300};

  &:hover {
    border-color: ${Color.Green300};
  }
`;

const CanImproveIndicator = css`
  border: 1px solid ${Color.Yellow50};
  color: ${Color.Yellow300};

  &:hover {
    border-color: ${Color.Yellow300};
  }
`;

export const IndicatorCard = styled(IndicatorCardBase)<{
  selectable: boolean;
  canImprove: boolean;
}>`
  ${({ selectable }) => selectable && SelectableIndicator}
  ${({ canImprove, selectable }) =>
    selectable && (canImprove ? CanImproveIndicator : GoodIndicator)}
`;

export const IndicatorNumbers = styled.div`
  display: flex;
  align-items: center;
  height: 28px;
`;

export const Caption = styled(Typography).attrs({ variant: 'caption' })`
  color: ${Color.Dark100};
`;

export const IndicatorValue = styled(Typography).attrs({
  variant: 'h2',
  color: 'inherit',
})`
  font-weight: 900;
`;

interface IndicatorProps {
  label: string;
  finalCount?: number | null;
  finalPickupCount?: number | null;
  finalDeliveryCount?: number | null;
  generalCount?: number | null;
  generalCountCaption?: string;
  notEnoughDataTitle?: string;
  thresholds: (value: number) => {
    top: boolean;
    good: boolean;
    canImprove: boolean;
  };
  onClick?: () => void;
}

export function Indicator({
  label,
  finalCount,
  finalPickupCount,
  finalDeliveryCount,
  generalCount,
  generalCountCaption,
  notEnoughDataTitle,
  thresholds,
  onClick,
}: IndicatorProps) {
  const uid = useUID();

  const isNotEnoughData = !generalCount;

  const meanRate = useMemo(() => {
    if (!generalCount) {
      return null;
    }

    if (finalCount != null) {
      return calculateRate(finalCount, generalCount);
    }

    if (finalPickupCount != null && finalDeliveryCount != null) {
      return calculateAverageRate(
        [finalPickupCount, finalDeliveryCount],
        generalCount,
      );
    }

    return null;
  }, [finalCount, finalPickupCount, finalDeliveryCount, generalCount]);

  const variants = meanRate != null ? thresholds(meanRate) : undefined;

  return (
    <IndicatorCard
      selectable={!isNotEnoughData}
      canImprove={!!variants?.canImprove}
      onClick={!isNotEnoughData ? onClick : undefined}
    >
      <Stack space="small">
        <Typography id={uid} variant="body1" color="inherit">
          {label}
        </Typography>

        <IndicatorNumbers>
          {!isNotEnoughData ? (
            <Inline verticalAlign="center" space="xsmall" noWrap={true}>
              <IndicatorValue aria-labelledby={uid}>
                {formatNumber(meanRate, { maximumFractionDigits: 0 })}%
              </IndicatorValue>
              <Caption>({generalCountCaption})</Caption>
            </Inline>
          ) : (
            <Caption aria-labelledby={uid}>({notEnoughDataTitle})</Caption>
          )}
        </IndicatorNumbers>
      </Stack>
    </IndicatorCard>
  );
}

const noopThreshold = () => ({
  top: false,
  good: false,
  canImprove: false,
});

export function NotVerifiedInfo() {
  const { data: verificationStatus } = useVerifiedCarrierApplicationStatus();
  const isNonVerifiable =
    verificationStatus?.verified_carrier_application.status ===
    'non_verifiable';

  return (
    <BlurredBackdrop
      blur={5}
      blurredElements={
        <Columns>
          <Column>
            <Indicator
              label="Provided Pickup ETA"
              finalCount={Number.NaN}
              generalCount={Number.NaN}
              thresholds={noopThreshold}
            />
          </Column>
          <Column>
            <Indicator
              label="Provided Pickup ETA"
              finalCount={Number.NaN}
              generalCount={Number.NaN}
              thresholds={noopThreshold}
            />
          </Column>
          <Column>
            <Indicator
              label="Provided Delivery ETA"
              finalCount={Number.NaN}
              generalCount={Number.NaN}
              thresholds={noopThreshold}
            />
          </Column>
        </Columns>
      }
    >
      <Stack align="center">
        <CustomColumns align="center" space="xxsmall">
          <Column width="content">
            <Info fontSize="small" htmlColor={Color.Blue500} />
          </Column>
          <Column width="adaptive">
            {isNonVerifiable ? (
              <Typography>
                Performance metrics are not available to Intrastate carriers.
              </Typography>
            ) : (
              <TextBox as="div">
                Performance metrics available only to{' '}
                <WebViewLink
                  color="textPrimary"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://support.superdispatch.com/en/articles/2644754-verified-carrier-what-is-verified-carrier-status"
                >
                  Verified Carriers.
                </WebViewLink>
              </TextBox>
            )}
          </Column>
        </CustomColumns>
      </Stack>
    </BlurredBackdrop>
  );
}
