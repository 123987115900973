import { Card, CardContent, Typography } from '@material-ui/core';
import { AttachFile } from '@material-ui/icons';
import { Inline, Stack } from '@superdispatch/ui';
import { FileListItem, TextBox } from '@superdispatch/ui-lab';
import { FormikErrors, getIn, setIn, useFormikContext } from 'formik';
import { Ref, useCallback, useEffect, useMemo } from 'react';
import { ONE_AND_HALF_SECOND } from 'shared/constants/NumberConstants';
import { useUploadMedia } from 'shared/data/MediaServiceAPI';
import { FileDropZone } from 'shared/form/FileDropZone';
import {
  LoadAttachmentDTO,
  parseLoadAttachmentFileType,
} from '../data/LoadDTO';

function getFileHash(file: File): string {
  return `${file.name}-${file.type}-${file.size}-${file.lastModified}`;
}

function extractErrorMessage(input: unknown): null | string {
  if (typeof input == 'string' && input) return input;
  if (typeof input == 'object' && input) {
    for (const value of Object.values(input)) {
      const message = extractErrorMessage(value);
      if (message) return message;
    }
  }
  return null;
}

function getAttachmentsError(errors: FormikErrors<unknown>): null | string {
  return extractErrorMessage(getIn(errors, 'attachments'));
}

function getAttachments(values: unknown): LoadAttachmentDTO[] {
  const value = getIn(values, 'attachments') as LoadAttachmentDTO[];
  return Array.isArray(value) ? value : [];
}

interface LoadAttachmentFieldItemProps {
  file?: File;
  canDelete: boolean;
  attachment: LoadAttachmentDTO;
  onDelete: () => void;
  onSuccess: (url: string) => void;
}

function LoadAttachmentFieldItem({
  file,
  onDelete,
  onSuccess,
  attachment,
  canDelete,
}: LoadAttachmentFieldItemProps) {
  const { reset, mutate, status } = useUploadMedia({ onSuccess });

  useEffect(() => {
    if (file?.size) mutate(file);
    else reset();
  }, [file, mutate, reset]);

  useEffect(() => {
    if (status !== 'success') return;
    const timeout = setTimeout(reset, ONE_AND_HALF_SECOND);
    return () => {
      clearTimeout(timeout);
    };
  }, [reset, status]);

  return (
    <FileListItem
      status={status}
      onDelete={onDelete}
      key={attachment.guid}
      name={attachment.name}
      canDelete={canDelete}
    />
  );
}

export interface LoadAttachmentsCardProps {
  nodeRef?: Ref<HTMLElement>;
  errorsRef?: Ref<HTMLElement>;
}

export function LoadAttachmentsCard({
  nodeRef,
  errorsRef,
}: LoadAttachmentsCardProps) {
  const filesMap = useMemo(() => new Map<string, File>(), []);
  const { values, errors, setValues, isSubmitting } = useFormikContext();
  const attachments = useMemo(() => getAttachments(values), [values]);
  const errorText = useMemo(() => getAttachmentsError(errors), [errors]);

  const setAttachments = useCallback(
    (fn: (prevAttachments: LoadAttachmentDTO[]) => LoadAttachmentDTO[]) => {
      void setValues((prevValues: unknown) => {
        const prevAttachments = getAttachments(prevValues);
        return setIn(prevValues, 'attachments', fn(prevAttachments)) as unknown;
      });
    },
    [setValues],
  );

  return (
    <Card ref={nodeRef}>
      <CardContent aria-label="Attachments Card">
        <Stack space="small">
          <Inline verticalAlign="center" space="xsmall">
            <AttachFile />
            <Typography variant="h3">Attachments</Typography>
          </Inline>

          <Stack space="small">
            <FileDropZone
              disabled={isSubmitting}
              onDropAccepted={(acceptedFiles) => {
                setAttachments((prevAttachments) => [
                  ...prevAttachments,
                  ...acceptedFiles.map((file) => {
                    const guid = getFileHash(file);
                    filesMap.set(guid, file);
                    return {
                      guid,
                      file_url: '',
                      name: file.name,
                      file_type: parseLoadAttachmentFileType(file.name),
                      is_created_by_broker: false,
                    };
                  }),
                ]);
              }}
            />

            {!!attachments.length && (
              <Stack>
                {attachments.map((attachment) => (
                  <LoadAttachmentFieldItem
                    key={attachment.guid}
                    attachment={attachment}
                    file={filesMap.get(attachment.guid)}
                    canDelete={!attachment.is_created_by_broker}
                    onSuccess={(url) => {
                      filesMap.delete(attachment.guid);
                      setAttachments((prevAttachments) =>
                        prevAttachments.map((prevAttachment) =>
                          prevAttachment.guid === attachment.guid
                            ? { ...attachment, file_url: url, guid: url }
                            : prevAttachment,
                        ),
                      );
                    }}
                    onDelete={() => {
                      setAttachments((prevAttachments) =>
                        prevAttachments.filter(
                          (prevAttachment) =>
                            prevAttachment.guid !== attachment.guid,
                        ),
                      );
                    }}
                  />
                ))}
              </Stack>
            )}

            {!!errorText && (
              <TextBox color="red" ref={errorsRef}>
                {errorText}
              </TextBox>
            )}
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
}
