import { useMemo } from 'react';
import { requestCarrierAPI } from 'shared/api/APIClient';
import {
  APIMutationOptions,
  APIMutationResult,
  useAPIMutation,
} from 'shared/api/APIMutation';
import { LoadExpenseEditDTO } from './LoadActionsDTO';

export function useExpensesAPI() {
  return useMemo(() => {
    function addExpense(json: LoadExpenseEditDTO) {
      return requestCarrierAPI('POST /internal/web/expenses/', { json });
    }

    function editExpense(json: LoadExpenseEditDTO) {
      return requestCarrierAPI(
        ['PUT /internal/web/expenses/{guid}/', { guid: json.guid }],
        { json },
      );
    }

    function deleteExpense(guid: string) {
      return requestCarrierAPI([
        'DELETE /internal/web/expenses/{guid}/',
        { guid },
      ]);
    }

    return { addExpense, editExpense, deleteExpense };
  }, []);
}

export function useDeleteExpense(
  options?: APIMutationOptions<string>,
): APIMutationResult<string> {
  const { deleteExpense } = useExpensesAPI();
  return useAPIMutation(deleteExpense, options);
}
