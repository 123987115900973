import { Typography } from '@material-ui/core';
import { FormikTextField } from '@superdispatch/forms';
import { Column, Columns, Stack } from '@superdispatch/ui';
import { loadVenueSchema } from '../data/LoadDTO';
import { FormikVenueAddressAutofill } from './FormikVenueAddressAutofill';
import { FormikVenueAutofill } from './FormikVenueAutofill';

export interface LoadCustomerFormProps {
  name?: string;
  disabled?: boolean;
}

export function LoadCustomerForm({ name, disabled }: LoadCustomerFormProps) {
  const prefix = !name ? '' : `${name}.`;

  return (
    <Stack space="large">
      <FormikVenueAutofill
        fullWidth={true}
        label="Shipper Name"
        name={`${prefix}venue`}
        schema={loadVenueSchema}
        inputProps={{ maxLength: 100 }}
        disabled={disabled}
      />

      <Stack space="small">
        <FormikVenueAddressAutofill
          fullWidth={true}
          label="Street Address"
          name={`${prefix}venue`}
          disabled={disabled}
        />

        <Columns space="small" collapseBelow="desktop">
          <Column width="1/2">
            <FormikTextField
              fullWidth={true}
              label="City"
              name={`${prefix}venue.city`}
              disabled={disabled}
            />
          </Column>

          <Column width="1/4">
            <FormikTextField
              fullWidth={true}
              label="State"
              name={`${prefix}venue.state`}
              disabled={disabled}
            />
          </Column>

          <Column width="1/4">
            <FormikTextField
              fullWidth={true}
              label="ZIP"
              name={`${prefix}venue.zip`}
              disabled={disabled}
            />
          </Column>
        </Columns>
      </Stack>

      <Stack space="small">
        <Typography variant="h4">Contact Details</Typography>

        <FormikTextField
          fullWidth={true}
          label="Full Name"
          name={`${prefix}venue.contacts[0].name`}
          disabled={disabled}
        />

        <Columns space="small" collapseBelow="desktop">
          <Column width="1/2">
            <FormikTextField
              fullWidth={true}
              label="Phone"
              name={`${prefix}venue.contacts[0].phone`}
              disabled={disabled}
            />
          </Column>
          <Column width="1/2">
            <FormikTextField
              fullWidth={true}
              label="Email"
              name={`${prefix}venue.contacts[0].email`}
              disabled={disabled}
            />
          </Column>
        </Columns>
      </Stack>
    </Stack>
  );
}
