import { IconButton } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { basePath } from 'shared/app/AppRouter';
import { useNavigate } from 'shared/routing/react-router-6';

function normalizeBackURL(input: unknown): string | undefined {
  return typeof input == 'string' && input.startsWith('/') ? input : undefined;
}

export function getBackURL(search: string, fallbackURL: string) {
  const searchParams = new URLSearchParams(search);
  return (
    normalizeBackURL(searchParams.get('next')) ||
    normalizeBackURL(searchParams.get('backUrl')) ||
    normalizeBackURL(searchParams.get('back_url')) ||
    fallbackURL
  );
}

export function useGoBack(fallbackURL: string): () => void {
  const navigate = useNavigate();
  const location = useLocation();

  return useCallback(() => {
    let nextURL = getBackURL(location.search, fallbackURL);
    if (nextURL.startsWith(basePath)) nextURL = nextURL.slice(basePath.length);
    if (!nextURL.startsWith('/')) nextURL = `/${nextURL}`;
    navigate(nextURL, { replace: true });
  }, [navigate, location.search, fallbackURL]);
}

export interface BackButtonProps extends React.ComponentProps<'button'> {
  label?: string;
  onClick?: () => void;
}

export function BackButton({ onClick, label = 'go back' }: BackButtonProps) {
  return (
    <IconButton edge="start" size="small" onClick={onClick} aria-label={label}>
      <ArrowBack />
    </IconButton>
  );
}
