import { AvatarButton, AvatarButtonProps } from '@superdispatch/ui';
import { useMemo } from 'react';
import { getInitials } from 'shared/utils/TextUtils';
import { useCarrierRatingsSummary } from './data/CarrierRatingsAPI';

export function CarrierAvatar(props: Omit<AvatarButtonProps, 'src'>) {
  const { data: carrierRatingsSummary } = useCarrierRatingsSummary();
  const companyNameInitials = useMemo(
    () =>
      carrierRatingsSummary?.carrier_name &&
      getInitials(carrierRatingsSummary.carrier_name),
    [carrierRatingsSummary?.carrier_name],
  );

  return (
    <AvatarButton {...props} src={carrierRatingsSummary?.carrier_logo_url}>
      {companyNameInitials}
    </AvatarButton>
  );
}
