import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

let isNavigationAllowed = false;

export function performNavigation(fn: () => void) {
  isNavigationAllowed = true;

  try {
    fn();
  } finally {
    isNavigationAllowed = false;
  }
}

/**
 * Block router navigation or browser tab close.
 * @param message - e.g: "Changes have not been saved. Leaving can result in unsaved changes being lost."
 */
export function useNavigationBlock(message: null | false | string): void {
  const history = useHistory();

  useEffect(() => {
    if (!message || 'Cypress' in window) return;

    const release = history.block(() => {
      // Allow calls with `performNavigation`
      return !isNavigationAllowed ? message : undefined;
    });

    const onBeforeUnload = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      event.returnValue = message;
    };

    window.addEventListener('beforeunload', onBeforeUnload);

    return () => {
      release();
      window.removeEventListener('beforeunload', onBeforeUnload);
    };
  }, [message, history]);
}
