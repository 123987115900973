import { IconButton as MuiIconButton } from '@material-ui/core';
import { Color, Column, Columns, Inline, Stack } from '@superdispatch/ui';
import { Box, TextBox } from '@superdispatch/ui-lab';
import { useCallback, useEffect, useState } from 'react';
import { useSwipeable } from 'react-swipeable';
import styled from 'styled-components';
import alanPhoto from '../../../assets/alan.png';
import jamiePhoto from '../../../assets/jamie.png';
import kevinPhoto from '../../../assets/kevin.png';

const comments = [
  {
    photo: (
      <img src={jamiePhoto} alt="Jamie" width={48} height={48} loading="lazy" />
    ),
    name: 'Jamie G',
    company: 'JP Auto Transport',
    comment:
      "“I've been able to grow from 1 to 3 trucks just from joining Super Dispatch”",
  },
  {
    photo: (
      <img src={alanPhoto} alt="Alan" width={48} height={48} loading="lazy" />
    ),
    name: 'Alan W. Bewley',
    company: 'Best Bet Logistics',
    comment:
      '“I love Super Dispatch, it keeps us organized and out of trouble”',
  },

  {
    photo: (
      <img src={kevinPhoto} alt="Kevin" width={48} height={48} loading="lazy" />
    ),
    name: 'Kevin',
    company: 'JP Auto Transport',
    comment:
      '“Great software streamlined load management, dispatching and invoicing”',
  },
];

const ActiveTab = styled(Box)`
  width: 12px;
  height: 3px;
  border-radius: 3px;
  background-color: ${Color.Blue300};
`;

const Tab = styled(Box)`
  width: 6px;
  height: 3px;
  border-radius: 3px;
  opacity: 0.3;
`;

const IconButton = styled(MuiIconButton)`
  padding: 0;
`;

const TextBoxWrapper = styled(TextBox)<{ textColor?: string }>`
  color: ${({ textColor }) => textColor};
`;

const TextBoxCaption = styled(TextBox)<{ textColor?: string }>`
  opacity: 0.6;
  color: ${({ textColor }) => textColor};
`;

interface CustomerCommentsProps {
  color?: string;
  source?: 'trial' | 'canceled';
}

export function CustomerComments({ color, source }: CustomerCommentsProps) {
  const [currentCommentIndex, setCurrentCommentIndex] = useState(0);

  const handleSwipe = useCallback(
    (direction: 'left' | 'right') => {
      if (direction === 'left') {
        setCurrentCommentIndex((currentCommentIndex + 1) % comments.length);
      } else {
        setCurrentCommentIndex(
          currentCommentIndex <= 0
            ? comments.length - 1
            : currentCommentIndex - 1,
        );
      }
    },
    [setCurrentCommentIndex, currentCommentIndex],
  );

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      handleSwipe('left');
    },
    onSwipedRight: () => {
      handleSwipe('right');
    },
    swipeDuration: 12000,
    preventScrollOnSwipe: true,
    trackMouse: true,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      handleSwipe('left');
    }, 10000);

    return () => {
      clearInterval(interval);
    };
  }, [handleSwipe, currentCommentIndex]);

  return (
    <Stack space="small">
      <Box {...handlers}>
        {comments.map((comment, index) => {
          if (index === currentCommentIndex) {
            return (
              <Columns align="top" key={index} space="xsmall">
                <Column width="content">
                  <Inline horizontalAlign="right">{comment.photo}</Inline>
                </Column>
                <Column>
                  <Stack space="xxsmall">
                    <TextBoxWrapper textColor={color} variant="heading-5">
                      {comment.comment}
                    </TextBoxWrapper>
                    <Inline>
                      <TextBoxWrapper textColor={color} variant="body">
                        {comment.name},
                      </TextBoxWrapper>
                      <TextBoxCaption variant="caption" textColor={color}>
                        {comment.company}
                      </TextBoxCaption>
                    </Inline>
                  </Stack>
                </Column>
              </Columns>
            );
          }
          return null;
        })}
      </Box>

      <Columns>
        <Column width="content">
          <Box width="56px" />
        </Column>
        <Column width="fluid">
          <Inline space="xxsmall">
            {comments.map((comment, index) => (
              <IconButton
                aria-label={comment.name}
                key={index}
                onClick={() => {
                  setCurrentCommentIndex(index);
                }}
              >
                {index === currentCommentIndex ? (
                  <ActiveTab />
                ) : (
                  <Tab
                    backgroundColor={
                      source === 'trial' ? 'Silver500' : 'Dark500'
                    }
                  />
                )}
              </IconButton>
            ))}
          </Inline>
        </Column>
      </Columns>
    </Stack>
  );
}
