export const passwordCheckTypes = {
  toNumber: /(?=.*[0-9])/,
  toLowerCaseAndUpperCase: /^(?=.*[a-z])(?=.*[A-Z]).+$/,
  toSpecialCharacter: /[!@#$%^&*()_+\-={[}\]|\\;:'"<>?,.]/,
  matchSpecialCharacter: /[!@#$%^&*()_+\-={[}\]|\\;:'"<>?,.]/g,
};

export function validatePassword(value: string) {
  if (
    !value ||
    !validateToPasswordLength(value) ||
    !validateToUpperAndLowerCase(value) ||
    !validateToSpecailCharacters(value) ||
    !validateToNumber(value)
  ) {
    return 'Wrong Password';
  }
  return undefined;
}

// if there would pass all validation, more than 11 length of password and consists of more than one special character, count will be increased. It means that it is strong password here. On other other cases if just past all validation, it is good password only
export function validatePasswordStrength(value: string) {
  let count = 6;
  if (!value) {
    count -= 1;
  }
  if (!validateToPasswordLength(value)) {
    count -= 1;
  }
  if (!validateToUpperAndLowerCase(value)) {
    count -= 1;
  }
  if (!validateToSpecailCharacters(value)) {
    count -= 1;
  }
  if (!validateToNumber(value)) {
    count -= 1;
  }
  if (
    value &&
    validateToNumber(value) &&
    validateToSpecailCharacters(value) &&
    validateToUpperAndLowerCase(value) &&
    value.trim().length > 11 &&
    checkSpecialCharactersLength(value)
  ) {
    count += 1;
  }
  return count;
}

export function validateToNumber(text: string) {
  return passwordCheckTypes.toNumber.test(text);
}

export function validateToUpperAndLowerCase(text: string) {
  return passwordCheckTypes.toLowerCaseAndUpperCase.test(text);
}

export function validateToSpecailCharacters(text: string) {
  return passwordCheckTypes.toSpecialCharacter.test(text);
}

export function checkSpecialCharactersLength(text: string) {
  const charactersList = text.match(passwordCheckTypes.matchSpecialCharacter);
  return charactersList && charactersList.length > 1;
}

export function validateToPasswordLength(text: string) {
  return !!text && text.trim().length > 7;
}

// if strength is weak, only first stepperItem will be red. If it is average, only two stepperItems will be orange. If it is good, 3 stepperItems will be green. if it is strong, 4 stepperItems will be green. Strong means that, if its length over 11 length and consists of at lease 2 characters and validate all cases(Upper&lower case, at least one number)
export function passwordType(passwordStrength: number, k: number) {
  switch (true) {
    case passwordStrength === 3 && k === 0:
      return 'weak';
    case (passwordStrength === 4 || passwordStrength === 5) &&
      (k === 0 || k === 1):
      return 'average';
    case passwordStrength === 6 && (k === 0 || k === 1 || k === 2):
      return 'good';
    case passwordStrength === 7:
      return 'strong';
    default:
      return undefined;
  }
}

export type PasswordType = 'weak' | 'average' | 'good' | 'strong';
