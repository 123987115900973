import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import { Button } from '@superdispatch/ui-lab';

export interface LoadCustomerReplaceConfirmDialogProps {
  open: boolean;
  onDismiss: () => void;
  onConfirm: () => void;
}

export function LoadCustomerReplaceConfirmDialog({
  open,
  onDismiss,
  onConfirm,
}: LoadCustomerReplaceConfirmDialogProps) {
  return (
    <Dialog open={open}>
      <DialogTitle>Replace Customer Information?</DialogTitle>
      <DialogContent>
        Customer information filled before will be replaced.
      </DialogContent>
      <DialogActions>
        <Button variant="neutral" onClick={onDismiss}>
          Cancel
        </Button>
        <Button autoFocus={true} variant="critical" onClick={onConfirm}>
          Replace
        </Button>
      </DialogActions>
    </Dialog>
  );
}
