import { FieldValidator, useField } from 'formik';
import { FocusEvent } from 'react';
import {
  SettingsTimezoneField,
  SettingsTimezoneFieldProps,
} from './SettingsTimezoneField';

export interface Props
  extends Omit<SettingsTimezoneFieldProps, 'TextFieldProps'> {
  name: string;
  validate?: FieldValidator;

  TextFieldProps?: Omit<
    NonNullable<SettingsTimezoneFieldProps['TextFieldProps']>,
    'error'
  >;
}

export function SettingTimezoneFormikField({
  name,
  validate,
  onBlur,
  onChange,
  TextFieldProps: { helperText, ...textFieldProps } = {},
  ...props
}: Props) {
  const [field, { error, touched }, { setValue }] = useField<string | null>({
    name,
    validate,
  });
  const errorText = touched && error;

  return (
    <SettingsTimezoneField
      {...props}
      {...field}
      value={field.value}
      onChange={(value) => {
        onChange?.(value);
        void setValue(value);
      }}
      onBlur={(event: FocusEvent<HTMLDivElement>) => {
        onBlur?.(event);
        field.onBlur(event);
      }}
      TextFieldProps={{
        ...textFieldProps,
        error: !!errorText,
        helperText: errorText || helperText,
      }}
    />
  );
}
