import { LocalStore, useLocalStore } from 'shared/helpers/Store';

const LOAD_SEND_INVOICE_TOUR_OPENED_STORAGE_KEY =
  'load_send_invoice_tour_opened';

export function useSendInvoiceTourAction() {
  const loadSendInvoiceTourOpened = useLocalStore(
    LOAD_SEND_INVOICE_TOUR_OPENED_STORAGE_KEY,
    null,
  );
  const isTourOpened = Boolean(loadSendInvoiceTourOpened);

  const setTourOpened = () => {
    LocalStore.set(LOAD_SEND_INVOICE_TOUR_OPENED_STORAGE_KEY, 'true');
  };

  return {
    isTourOpened,
    setTourOpened,
  };
}
