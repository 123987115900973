import { Typography } from '@material-ui/core';
import { Color, Stack, useResponsiveValue } from '@superdispatch/ui';
import { Button } from '@superdispatch/ui-lab';
import { CheckDecagramIcon } from 'shared/icons';
import driver from 'shared/modules/carrier-profile/assets/driver.png';
import styled from 'styled-components';
import { useCarrierFmcsaPhoneNumber } from '../data/PhoneVerificationAPI';

const MainImage = styled.img`
  max-width: 120px;
`;

const PhoneNumber = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 12px;
  width: auto;

  background-color: ${Color.Silver200};
  border: 1px solid ${Color.Silver400};
  border-radius: 4px;
  padding: 4px 8px;
`;

interface PhoneVerificationCompleteProps {
  onComplete: () => void;
}

export function PhoneVerificationComplete({
  onComplete,
}: PhoneVerificationCompleteProps) {
  const { data: fmcsaPhoneInfo } = useCarrierFmcsaPhoneNumber();
  const isMobile = useResponsiveValue(true, false);
  return (
    <Stack space="small">
      <MainImage src={driver} />
      <Stack space="xxsmall">
        <Typography variant="h4">
          Your phone number was verified successfully
        </Typography>
        <Typography color="textSecondary">
          You can now proceed to your full verification, and start moving cars.
        </Typography>
      </Stack>
      {fmcsaPhoneInfo && (
        <PhoneNumber>
          <Typography variant="h2">{fmcsaPhoneInfo.number}</Typography>
          <CheckDecagramIcon htmlColor={Color.Dark100} />
        </PhoneNumber>
      )}
      <Button onClick={onComplete} fullWidth={isMobile}>
        Continue to Carrier Info
      </Button>
    </Stack>
  );
}
