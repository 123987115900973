import { renderChildren } from '@superdispatch/ui';
import { atom, useAtom } from 'jotai';
import { ReactNode } from 'react';
import { MobileAppBridge } from 'shared/data/MobileAppBridge';
import { useIntercomTourManager } from 'shared/helpers/IntercomTourManager';
import { SplashScreen } from 'shared/layout/SplashScreen';
import { useFlag } from 'shared/settings/FeatureToggles';
import { useOnboardingDetails } from '../data/OnboardingAPI';
import { ONBOARDING_TOURS } from '../data/OnboardingConstants';
import { OnboardingStepName } from '../data/OnboardingDTO';
import { useSaveOnboardingWithErrorHandler } from '../data/useSaveOnboardingWithErrorHandler';
import { useStartOnboarding } from '../useStartOnboarding';

function findKeyByValue<T>(
  obj: Record<string, T>,
  value: T,
): string | undefined {
  return Object.keys(obj).find((key) => obj[key] === value);
}

const onboardingDialogStateAtom = atom<boolean>(false);
const becomeVerifiedDialogStateAtom = atom<boolean>(false);

export function useOnboardingDialogState() {
  const [isOnboardingDialogOpen, setOnboardingDialogOpen] = useAtom(
    onboardingDialogStateAtom,
  );
  const closeOnboardingDialog = () => {
    setOnboardingDialogOpen(false);
  };
  const openOnboardingDialog = () => {
    setOnboardingDialogOpen(true);
  };
  const [isBecomeVerifiedDialogOpen, setBecomeVerifiedDialogOpen] = useAtom(
    becomeVerifiedDialogStateAtom,
  );
  const closeBecomeVerifiedDialog = () => {
    setBecomeVerifiedDialogOpen(false);
  };
  const openBecomeVerifiedDialog = () => {
    setBecomeVerifiedDialogOpen(true);
  };
  return {
    isOnboardingDialogOpen,
    isBecomeVerifiedDialogOpen,
    closeOnboardingDialog,
    openOnboardingDialog,
    closeBecomeVerifiedDialog,
    openBecomeVerifiedDialog,
  };
}

export interface OnboardingWrapperProps {
  children?: ReactNode;
}

export function OnboardingWrapper({ children }: OnboardingWrapperProps) {
  const { mutate: saveStep } = useSaveOnboardingWithErrorHandler();
  const isOnboardingEnabled = useFlag('ctms_onboarding');
  const { data: onboarding } = useOnboardingDetails({
    enabled: isOnboardingEnabled && !MobileAppBridge.isInjected(),
  });

  useIntercomTourManager({
    onClose: (id) => {
      const stepName = findKeyByValue(ONBOARDING_TOURS, id);
      if (
        stepName &&
        onboarding?.steps?.[stepName as OnboardingStepName]?.status ===
          'pending'
      ) {
        saveStep({
          stepName: stepName as OnboardingStepName,
          status: 'skipped',
        });
      }
    },
    onCompleted: (id) => {
      const stepName = findKeyByValue(ONBOARDING_TOURS, id);
      if (
        stepName &&
        onboarding?.steps?.[stepName as OnboardingStepName]?.status ===
          'pending'
      ) {
        saveStep({
          stepName: stepName as OnboardingStepName,
          status: 'completed',
        });
      }
    },
  });

  const { isLoading } = useStartOnboarding();

  if (isLoading) {
    return <SplashScreen />;
  }

  return renderChildren(children);
}
