import { MenuItem } from '@material-ui/core';
import { FormikCheckboxField, FormikTextField } from '@superdispatch/forms';
import { DrawerActions, Inline, Stack } from '@superdispatch/ui';
import { Button } from '@superdispatch/ui-lab';
import { useMemo, useState } from 'react';
import { useAppFormik } from 'shared/form/AppFormik';
import { FormikDrawer, FormikDrawerContent } from 'shared/form/FormikDrawer';
import { useCarrierSettings } from 'shared/settings/CarrierSettingsAPI';
import { yupString } from 'shared/utils/YupUtils';
import { InferType } from 'yup';
import { FormikContactEmailField } from '../../contacts/core/FormikContactEmailField';
import {
  formatLoadBOLTemplate,
  loadBOLSendSchema,
  LOAD_BOL_TEMPLATES,
} from '../data/LoadActionsDTO';
import { LoadsPageType, trackLoadsEvent } from '../data/LoadsAnalytics';
import { useLoadDetails, useLoadsAPI, useLoadsCache } from '../data/LoadsAPI';
import { LoadBOLPreviewDialog } from './LoadBOLPreviewDialog';

const loadBOLSendFormSchema = loadBOLSendSchema.shape({
  guid: yupString(),
});

export interface LoadSendBOLDrawerContentProps {
  loadGUID?: string;
  onClose: () => void;
  pageType: LoadsPageType;
  onSubmitSuccess: () => void;
}

export function LoadSendBOLDrawerContent({
  onClose,
  loadGUID,
  pageType,
  onSubmitSuccess,
}: LoadSendBOLDrawerContentProps) {
  const { data: settings } = useCarrierSettings();
  const { sendBOL } = useLoadsAPI();
  const { data } = useLoadDetails(loadGUID);
  const { invalidateLoads } = useLoadsCache();
  const [modalState, setModalState] = useState<'preview'>();
  const initialValues = useMemo<
    Partial<InferType<typeof loadBOLSendFormSchema>>
  >(
    () => ({
      guid: loadGUID,
      use_broker_info: data?.is_created_by_broker,
      email: data?.customer.venue.contacts[0]?.email,
    }),
    [data, loadGUID],
  );
  const formik = useAppFormik({
    initialValues,
    validationSchema: loadBOLSendFormSchema,
    onSubmit({ guid, ...values }) {
      return sendBOL(guid, values);
    },
    onSubmitSuccess() {
      invalidateLoads();
      onSubmitSuccess();
      trackLoadsEvent({ name: 'CTMS: BOL Sent', page: pageType });
    },
    getSuccessMessage({ user_message }) {
      return user_message || 'BOL Sent';
    },
  });

  const { values, isSubmitting } = formik;
  const isLoading = !data || isSubmitting;

  return (
    <>
      <LoadBOLPreviewDialog
        params={values}
        loadGUID={loadGUID}
        open={modalState === 'preview'}
        onClose={() => {
          setModalState(undefined);
        }}
      />

      <FormikDrawerContent
        formik={formik}
        title="Send BOL"
        onClose={onClose}
        actions={
          <DrawerActions>
            <Inline>
              <Button type="submit" disabled={isLoading} pending={isSubmitting}>
                Send BOL
              </Button>

              <Button
                variant="neutral"
                disabled={isLoading}
                onClick={() => {
                  trackLoadsEvent({
                    name: 'CTMS: Previewed BOL In Send BOL',
                    page: pageType,
                  });
                  setModalState('preview');
                }}
              >
                Preview BOL
              </Button>
            </Inline>
          </DrawerActions>
        }
      >
        <Stack space="small">
          <FormikContactEmailField
            name="email"
            disabled={isLoading}
            TextFieldProps={{
              label: 'Email',
              fullWidth: true,
              placeholder: 'example@mail.com',
            }}
          />

          {data?.inspection_type !== 'aiag' && (
            <FormikTextField
              select={true}
              name="template"
              label="BOL template"
              fullWidth={true}
              disabled={isLoading}
            >
              {LOAD_BOL_TEMPLATES.map((value) => (
                <MenuItem key={value} value={value}>
                  {formatLoadBOLTemplate(value)}
                </MenuItem>
              ))}
            </FormikTextField>
          )}

          {settings?.can_show_shipper_info_in_bol && (
            <FormikCheckboxField
              disabled={isLoading}
              name="use_broker_info"
              label="Show Shipper/Customer info in BOL header"
            />
          )}
        </Stack>
      </FormikDrawerContent>
    </>
  );
}

export function LoadSendBOLDrawer({
  loadGUID,
  onClose,
  ...props
}: LoadSendBOLDrawerContentProps) {
  return (
    <FormikDrawer onClose={onClose} open={!!loadGUID}>
      <LoadSendBOLDrawerContent
        {...props}
        onClose={onClose}
        loadGUID={loadGUID}
      />
    </FormikDrawer>
  );
}
