import { IconButton, Typography } from '@material-ui/core';
import { OpenInNew } from '@material-ui/icons';
import { Column, Columns, Stack } from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import { useField } from 'formik';
import { useMemo } from 'react';
import {
  FormikCheckboxArrayField,
  FormikCheckboxArrayGroup,
} from 'shared/form/FormikCheckboxArrayGroup';
import { openExternalURL } from 'shared/helpers/URLHelpers';
import { AttachmentDTO, OrdersDTO } from '../data/LoadDTO';

interface LoadSendInvoiceAttachmentsProps {
  name: string;
}

export function LoadSendInvoiceAttachments({
  name,
}: LoadSendInvoiceAttachmentsProps) {
  const handleFile = (attachment: AttachmentDTO) => {
    openExternalURL(attachment.file, { target: '_blank' });
  };

  const [field] = useField<OrdersDTO[]>({ name: 'orders' });
  const orderWithAttachments = useMemo(() => {
    return field.value.filter((item) => !!item.attachments?.length);
  }, [field.value]);

  const doesAttachmentsNotExist = useMemo(() => {
    return field.value.every((item) => item.attachments?.length === 0);
  }, [field.value]);

  if (doesAttachmentsNotExist) {
    return null;
  }

  return (
    <Stack>
      <Box>
        <Typography variant="h4">Attachments</Typography>
        <Typography color="textSecondary">
          Selected attachments will be included to the email message.
        </Typography>
      </Box>
      <Stack>
        {orderWithAttachments.map((order) => (
          <Box key={order.guid}>
            <Typography color="textSecondary" variant="h6">
              {order.number}
            </Typography>
            <FormikCheckboxArrayGroup name={name}>
              {order.attachments?.map((attachment) => (
                <Columns key={attachment.id} align="center">
                  <Column>
                    <FormikCheckboxArrayField
                      name={`${attachment.id}`}
                      label={attachment.name}
                    />
                  </Column>
                  <Column width="content">
                    <IconButton
                      onClick={() => {
                        handleFile(attachment);
                      }}
                    >
                      <OpenInNew color="action" fontSize="small" />
                    </IconButton>
                  </Column>
                </Columns>
              ))}
            </FormikCheckboxArrayGroup>
          </Box>
        ))}
      </Stack>
    </Stack>
  );
}
