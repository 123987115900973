import { useEffect, useState } from 'react';
import { logError } from 'shared/helpers/ErrorTracker';

export type StorageSubscriber = () => void;

export class Store {
  protected static instance: undefined | Store;

  protected static getInstance() {
    if (this.instance == null) this.instance = new Store();
    return this.instance;
  }

  static get(key: string) {
    return this.getInstance().get(key);
  }

  static set(
    key: string,
    input: null | string | ((current: null | string) => null | string),
  ): void {
    const storage = this.getInstance();
    if (typeof input == 'function') input = input(storage.get(key));
    storage.update(key, input);
  }

  static delete(key: string): void {
    this.getInstance().update(key, null);
  }

  static subscribe(subscriber: StorageSubscriber): () => void {
    const storage = this.getInstance();
    storage.subscribers.add(subscriber);

    try {
      subscriber();
    } catch (error: unknown) {
      logError(error, 'Storage');
    }

    return () => {
      storage.subscribers.delete(subscriber);
    };
  }

  static subscribeTo(
    key: string,
    subscriber: (value: null | string) => void,
  ): () => void {
    let previous = this.get(key);

    return this.subscribe(() => {
      const current = this.get(key);
      if (!Object.is(previous, current)) {
        previous = current;
        subscriber(current);
      }
    });
  }

  private memory = new Map<string, string>();

  protected subscribers = new Set<StorageSubscriber>();

  protected set(key: string, value: string): void {
    this.memory.set(key, value);
  }

  protected get(key: string): string | null {
    return this.memory.get(key) ?? null;
  }

  protected delete(key: string): void {
    this.memory.delete(key);
  }

  protected emitChange() {
    for (const subscriber of this.subscribers) {
      try {
        subscriber();
      } catch (error: unknown) {
        logError(error, 'Storage');
      }
    }
  }

  protected update(key: string, value: null | string) {
    if (value == null) this.delete(key);
    else this.set(key, value);
    this.emitChange();
  }
}

export class LocalStore extends Store {
  protected static getInstance() {
    if (this.instance == null) {
      try {
        localStorage.getItem('test');
        localStorage.setItem('test', '');
        localStorage.removeItem('test');
        this.instance = new LocalStore();
      } catch {
        this.instance = new Store();
      }
    }

    return this.instance;
  }

  constructor() {
    super();

    window.addEventListener('storage', () => {
      this.emitChange();
    });
  }

  protected set(key: string, value: string): void {
    try {
      localStorage.setItem(key, value);
    } catch {
      super.set(key, value);
    }
  }

  protected get(key: string): string | null {
    try {
      return localStorage.getItem(key);
    } catch {
      return super.get(key);
    }
  }

  protected delete(key: string): void {
    try {
      localStorage.removeItem(key);
    } catch {
      super.delete(key);
    }
  }
}

export class SessionStore extends Store {
  protected static getInstance() {
    if (this.instance == null) {
      try {
        sessionStorage.setItem('test', '');
        sessionStorage.removeItem('test');
        this.instance = new SessionStore();
      } catch {
        this.instance = new Store();
      }
    }

    return this.instance;
  }

  protected set(key: string, value: string): void {
    sessionStorage.setItem(key, value);
  }

  protected get(key: string): string | null {
    return sessionStorage.getItem(key);
  }

  protected delete(key: string): void {
    sessionStorage.removeItem(key);
  }
}

export function useLocalStore<T extends null | string = null>(
  key: string,
  defaultValue?: T,
): T | string {
  const [value, setValue] = useState(() => LocalStore.get(key));
  useEffect(() => LocalStore.subscribeTo(key, setValue), [key]);
  return (value ?? defaultValue) as T;
}

export function useSessionStore<T extends null | string = null>(
  key: string,
  defaultValue?: T,
): T | string {
  const [value, setValue] = useState(() => SessionStore.get(key));
  useEffect(() => SessionStore.subscribeTo(key, setValue), [key]);
  return (value ?? defaultValue) as T;
}
