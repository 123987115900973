import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  IconButton,
  Link,
  TextField,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import {
  DrawerActions,
  DrawerContent,
  DrawerTitle,
  Stack,
} from '@superdispatch/ui';
import { Box, Button, TextBox } from '@superdispatch/ui-lab';
import { useField } from 'formik';
import { useMemo } from 'react';
import { showNewIntercomMessage } from 'shared/helpers/Intercom';
import { StepProps } from './DowngradeConfirmationStep';

const questionsMap = new Map([
  ['out_of_business', { question: 'We are going out of business permanently' }],
  ['business_on_hold', { question: 'We are putting our business on hold' }],
  ['not_enough_loads', { question: 'Not enough loads on Super Loadboard' }],
  ['too_expensive', { question: 'Super Dispatch is too expensive' }],
  ['not_using', { question: 'We are not using Super Dispatch' }],
  [
    'using_different_loadboard',
    {
      question: 'We use a different loadboard',
      subQuestion: 'If you don’t mind sharing, which loadboard are you using?',
    },
  ],
  [
    'using_different_software',
    {
      question: 'We use a different software for Car Hauling',
      subQuestion: 'If you don’t mind sharing, which software are you using?',
    },
  ],
  ['moving_to_freight', { question: "We're moving to freight" }],
  [
    'moving_to_another_industry',
    { question: "We're moving to another industry entirely" },
  ],
  ['just_downgrade', { question: 'I just want to downgrade my account' }],
  [
    'opening_new_company',
    { question: "I'm opening a new company and don't need this one anymore" },
  ],
  [
    'other',
    {
      question: 'Other',
      subQuestion: 'Explain us in few words why do you want to downgrade',
    },
  ],
]);

interface SubscriptionDowngradeQuestionsFormikFieldProps {
  name: string;
}

function DowngradeQuestionsFormikField({
  name,
}: SubscriptionDowngradeQuestionsFormikFieldProps) {
  const [{ value }, { error }, { setValue, setTouched }] = useField<
    Map<string, { reason: string; comment?: string }>
  >({ name });

  const questions = useMemo(() => Array.from(questionsMap), []);

  return (
    <FormGroup>
      {questions.map(([key, { question, subQuestion }]) => {
        const isChecked = value.has(key);

        return (
          <Box key={key}>
            <FormControlLabel
              checked={isChecked}
              onChange={() => {
                const nextValues = new Map(value);

                if (isChecked) {
                  nextValues.delete(key);
                } else {
                  nextValues.set(key, { reason: key });
                }

                void setValue(nextValues);
                void setTouched(true);
              }}
              control={<Checkbox />}
              label={question}
            />
            {!!subQuestion && isChecked && (
              <Box marginLeft="large">
                <TextField
                  placeholder={subQuestion}
                  value={value.get(key)?.comment}
                  onChange={(event) => {
                    const nextValues = new Map(value);
                    nextValues.set(key, {
                      reason: key,
                      comment: event.target.value,
                    });
                    void setValue(nextValues);
                  }}
                  multiline={true}
                  fullWidth={true}
                />
              </Box>
            )}
          </Box>
        );
      })}

      {typeof error == 'string' && <FormHelperText>{error}</FormHelperText>}
    </FormGroup>
  );
}

export function DowngradeSelectReasonsStep({
  onNext,
  onClose,
  formik,
}: StepProps) {
  return (
    <>
      <DrawerTitle
        title="Downgrading Plan"
        endAction={
          <IconButton edge="end" onClick={onClose}>
            <Close aria-label="close" />
          </IconButton>
        }
      />

      <Stack space="small">
        <DrawerContent>
          <Stack space="small">
            <Stack space="none">
              <TextBox variant="body-semibold">
                Why do you want to downgrade your plan to free?
              </TextBox>
              <TextBox variant="caption" color="secondary">
                Select at least one option to continue
              </TextBox>
            </Stack>

            <DowngradeQuestionsFormikField name="reasons" />
          </Stack>
        </DrawerContent>

        <DrawerActions>
          <Stack space="large">
            <Button onClick={onNext} disabled={!formik.values.reasons.size}>
              Next
            </Button>

            <TextBox color="secondary">
              For any questions or feedback, please{' '}
              <Link
                component="button"
                type="button"
                align="center"
                onClick={() => {
                  showNewIntercomMessage();
                }}
              >
                contact support
              </Link>
            </TextBox>
          </Stack>
        </DrawerActions>
      </Stack>
    </>
  );
}
