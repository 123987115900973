import { Inline, Tag } from '@superdispatch/ui';
import { AccountOffIcon } from 'shared/icons';

interface DriverDisconnectedTagProps {
  disconnected: boolean;
}

export function DriverDisconnectedTag({
  disconnected,
}: DriverDisconnectedTagProps) {
  if (!disconnected) return null;

  return (
    <Tag variant="subtle" color="red">
      <Inline space="xxsmall" verticalAlign="center">
        <AccountOffIcon fontSize="small" />
        Deactivated
      </Inline>
    </Tag>
  );
}
