import { Color } from '@superdispatch/ui';
import { createSvgIcon } from './IconUtils';

export const OutlinedWeakPasswordIcon = createSvgIcon(
  'OutlinedWeakPassword',
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M8.00008 14.65C6.44453 14.2611 5.16675 13.3583 4.16675 11.9416C3.16675 10.525 2.66675 8.97221 2.66675 7.28332V3.31665L8.00008 1.31665L13.3334 3.31665V7.28332C13.3334 8.97221 12.8334 10.525 11.8334 11.9416C10.8334 13.3583 9.55564 14.2611 8.00008 14.65ZM7.33341 8.50749V4.50749H8.66675V8.50749H7.33341ZM7.33341 9.84082V11.1742H8.66675V9.84082H7.33341Z"
    fill={Color.Blue300}
  />,
);
