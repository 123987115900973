import { useEffect } from 'react';
import { startIntercomTour } from 'shared/helpers/Intercom';
import { listenToLoadEvents } from '../../../../carrier/loads/data/LoadEvents';
import { useExperiments, useExperimentState } from '../ExperimentsAPI';

const EXPERIMENT_INTERCOM_TOUR_ID = {
  staging: '347069',
  production: '345090',
};

export function useDriverActivationExperiment() {
  const { isExperimentRunning, markAsViewed } =
    useExperimentState('Driver Activation');
  const { refetch } = useExperiments();

  useEffect(
    () =>
      listenToLoadEvents(() => {
        if (isExperimentRunning) {
          // Start Driver Activation Experiment Intercom Tour here
          if (import.meta.env.VITE_APP_TARGET !== 'production') {
            startIntercomTour(EXPERIMENT_INTERCOM_TOUR_ID.staging);
          } else {
            startIntercomTour(EXPERIMENT_INTERCOM_TOUR_ID.production);
          }

          void markAsViewed().then(() => {
            void refetch();
          });
        }
      }),
    [isExperimentRunning, refetch, markAsViewed],
  );
}
