import { useLocalStore } from 'shared/helpers/Store';
import { useCarrierSettings } from 'shared/settings/CarrierSettingsAPI';
import { getAuthToken, STORAGE_TOKEN_KEY } from './AuthToken';

export type AuthState = 'undetermined' | 'authorized' | 'unauthorized';

export function useAuthState(): AuthState {
  const { data, error } = useCarrierSettings();
  if (data) return 'authorized';
  if (error) return 'unauthorized';
  return 'undetermined';
}

export function useAuthToken(): string {
  const token = getAuthToken();
  const authToken = useLocalStore(STORAGE_TOKEN_KEY);

  // check LocalStore first and then fallback to getAuthToken
  return authToken || token || '';
}
