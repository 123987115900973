import { Link, Typography } from '@material-ui/core';
import { useState } from 'react';
import { SuperPayRequestShipperDialog } from 'shared/modules/superpay/SuperPayRequestShipperDialog';

interface SuperPayRequestShipperNudgingProps {
  isSuperPay?: boolean;
}

export function SuperPayRequestShipperNudging({
  isSuperPay,
}: SuperPayRequestShipperNudgingProps) {
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  return (
    <>
      <Link
        component="button"
        onClick={() => {
          setIsOpenDialog(true);
        }}
      >
        <Typography color="textSecondary">
          {isSuperPay
            ? 'Unlock More SuperPay Loads'
            : 'Request SuperPay from Shippers'}
        </Typography>
      </Link>
      <SuperPayRequestShipperDialog
        source="Load List"
        isSuperPay={isSuperPay}
        onClose={() => {
          setIsOpenDialog(false);
        }}
        isOpen={isOpenDialog}
      />
    </>
  );
}
