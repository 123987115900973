import { useMemo } from 'react';
import { useQueryClient } from 'react-query';
import { APIPageResponse, requestCarrierAPI } from 'shared/api/APIClient';
import {
  APIListQueryResult,
  BaseAPIListQueryOptions,
  useAPIListQuery,
} from 'shared/api/APIListQuery';
import {
  APIMutationOptions,
  APIMutationResult,
  useAPIMutation,
} from 'shared/api/APIMutation';
import {
  APIQueryOptions,
  APIQueryResult,
  useAPIQuery,
} from 'shared/api/APIQuery';
import { ONE_MINUTE } from 'shared/constants/NumberConstants';
import { OfferDeclinePayloadDTO } from '../../../loads/data/LoadDTO';
import { useLoadsCache } from '../../../loads/data/LoadsAPI';
import {
  OfferDetailsDTO,
  offerDetailsSchema,
  OfferDTO,
  offerSchema,
} from './OffersDTO';

export function useOffersCache() {
  const queryClient = useQueryClient();

  return useMemo(() => {
    function invalidateOffers() {
      void queryClient.invalidateQueries('offers');
    }

    function setOffersCount(count: number) {
      queryClient.setQueryData(['offers', 'count'], count);
      void queryClient.invalidateQueries(['offers', 'list']);
    }

    return { setOffersCount, invalidateOffers };
  }, [queryClient]);
}

export function useOffersCount(
  options?: APIQueryOptions<number>,
): APIQueryResult<number> {
  return useAPIQuery(
    ['offers', 'count'],
    () =>
      requestCarrierAPI('GET /internal/web/offers/?page_size=1').then(
        (response: APIPageResponse) => ({
          ...response,
          data: response.pagination.count,
        }),
      ),
    options,
  );
}

export function useOffersList({
  staleTime = ONE_MINUTE,
  ...options
}: BaseAPIListQueryOptions<OfferDTO> = {}): APIListQueryResult<OfferDTO> {
  return useAPIListQuery(
    ['offers', 'list'],
    (page) => {
      return requestCarrierAPI([
        'GET /internal/web/offers/?page_size=100{&page}',
        { page },
      ]);
    },
    { ...options, staleTime, schema: offerSchema },
  );
}

export function useOffer(
  guid: null | string | undefined,
): APIQueryResult<OfferDetailsDTO> {
  return useAPIQuery(
    ['offers', 'item', { guid }],
    () => requestCarrierAPI(['GET /internal/web/offers/{guid}/', { guid }]),
    { enabled: !!guid, schema: offerDetailsSchema },
  );
}

export function useAcceptOffer(
  options?: APIMutationOptions<string>,
): APIMutationResult<string> {
  const { invalidateLoads } = useLoadsCache();
  const { invalidateOffers } = useOffersCache();
  const queryClient = useQueryClient();

  function invalidateSettings() {
    void queryClient.invalidateQueries('settings');
  }

  return useAPIMutation(
    (guid) =>
      requestCarrierAPI([
        'POST /internal/web/offers/{guid}/accept/',
        { guid },
      ]).then(
        (response) => {
          invalidateLoads();
          invalidateOffers();
          invalidateSettings();
          return response;
        },
        (error) => {
          invalidateOffers();
          return Promise.reject(error);
        },
      ),
    options,
  );
}

export function useAcceptOffers(
  options?: APIMutationOptions<string[]>,
): APIMutationResult<string[]> {
  const { invalidateLoads } = useLoadsCache();
  const { invalidateOffers } = useOffersCache();

  return useAPIMutation(
    (guids) =>
      requestCarrierAPI('POST /internal/web/offers/accept/', {
        json: { offer_guids: guids },
      }).then(
        (response) => {
          invalidateLoads();
          invalidateOffers();
          return response;
        },
        (error) => {
          invalidateOffers();
          return Promise.reject(error);
        },
      ),
    options,
  );
}

export function useOffersAPI() {
  const { invalidateOffers } = useOffersCache();

  return useMemo(() => {
    return {
      declineOffer: (guid: string, payload: OfferDeclinePayloadDTO) =>
        requestCarrierAPI(
          ['POST /internal/web/offers/{guid}/decline/', { guid }],
          { json: payload },
        ).then(
          (response) => {
            invalidateOffers();
            return response;
          },
          (error) => {
            invalidateOffers();
            return Promise.reject(error);
          },
        ),
    };
  }, [invalidateOffers]);
}
