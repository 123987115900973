export type MoovScope = 'account' | 'bank_account' | 'micro_deposit';
export type MoovBusinessType =
  | 'llc'
  | 'privateCorporation'
  | 'soleProprietorship'
  | 'partnership'
  | 'publicCorporation';

export const MOOV_BUSINESS_TYPES: MoovBusinessType[] = [
  'llc',
  'privateCorporation',
  'soleProprietorship',
  'partnership',
  'publicCorporation',
];

export const MOOV_BUSINESS_TYPE_DISPAY_NAMES: Record<MoovBusinessType, string> =
  {
    llc: 'LLC',
    privateCorporation: 'Private Corporation',
    soleProprietorship: 'Sole Proprietorship',
    partnership: 'Partnership',
    publicCorporation: 'Public Corporation',
  };

export interface MoovTokenResponse {
  access_token: string;
  moov_account_id: string | null; // null for account scope
  moov_bank_account_id: string | null; // not null for micro_deposit scope
}

export interface NewCarrierMoovAccount {
  businessType: MoovBusinessType;
  legalBusinessName: string;
  description: string;
  carrier_guid: string;
  einNumber: string;
}

export interface NewCarrierBankAccount {
  holderName: string;
  accountNumber: string;
  routingNumber: string;
}

export interface MoovRequestError {
  statusCode?: number;
  error?: string;
}

export interface AchParticipant {
  routingNumber: string;
  newRoutingNumber: string;
  customerName: string;
  cleanName: string;
}

export interface SyncMoovStep {
  scope: MoovScope;
  moov_account_id?: string | null;
  moov_bank_account_id?: string | null;
}

export type MoovActionError = Error & { statusCode?: number };

export function isMoovRequestError(error: unknown): error is MoovRequestError {
  return (
    error instanceof Object &&
    !!(
      (error as MoovRequestError).statusCode ||
      (error as MoovRequestError).error
    )
  );
}
