import { Duration } from 'luxon';
import { ReactNode, useMemo } from 'react';
import { useFlag } from 'shared/settings/FeatureToggles';
import type { ImageFit } from 'yet-another-react-lightbox';
import { PhotoDTO, VideoDTO } from '../../carrier/loads/data/LoadDTO';

export function parsePhotos(
  photos: Array<{
    id: string;
    url: string;
    thumbnail_url: string;
    is_vehicle_detected: boolean | null;
  }>,
) {
  return photos.map((photo) => ({
    ...photo,
    is_video: false,
    duration_in_seconds: null,
  }));
}

export function parseVideo(video: VideoDTO) {
  return {
    id: video.guid,
    url: video.thumbnail_url,
    thumbnail_url: video.thumbnail_url,
    duration_in_seconds: video.duration_in_seconds,
    is_vehicle_detected: null,
    is_video: true,
  };
}

export function formatDuration(seconds: number) {
  let duration = Duration.fromObject({ seconds });

  let formatted = duration.toFormat('mm:ss');

  return formatted;
}

export function useInlineMedia(photos: PhotoDTO[], videos?: VideoDTO[]) {
  const isVideoInspectionEnabled = useFlag('video_inspection_carrier_tms');

  return useMemo(() => {
    const deliveryVideos =
      videos && videos.length > 0
        ? videos.filter((video) => video.type === 'Delivery').map(parseVideo)
        : [];
    const deliveryPhotos = parsePhotos(
      photos.filter((photo) => photo.type === 'Delivery'),
    );
    const pickUpVideos =
      videos && videos.length > 0
        ? videos.filter((video) => video.type === 'Pickup').map(parseVideo)
        : [];
    const pickUpPhotos = parsePhotos(
      photos.slice(1, photos.length).filter((photo) => photo.type === 'Pickup'),
    );

    if (isVideoInspectionEnabled) {
      return [
        ...parsePhotos(photos.slice(0, 1)),
        ...pickUpVideos,
        ...pickUpPhotos,
        ...deliveryVideos,
        ...deliveryPhotos,
      ];
    }
    return parsePhotos(photos);
  }, [isVideoInspectionEnabled, photos, videos]);
}

interface SlidesProps {
  photos: PhotoDTO[];
  videos?: VideoDTO[];
  title: ReactNode;
}

export function useSlides({ photos, videos, title }: SlidesProps) {
  const isVideoInspectionEnabled = useFlag('video_inspection_carrier_tms');

  const photosList = photos.map((image) => parsePhotoSlide(image, title));

  const slides = useMemo(() => {
    const deliveryVideos =
      videos && videos.length > 0
        ? videos
            .filter((video) => video.type === 'Delivery')
            .map(parseVideoSlide)
        : [];
    const deliveryPhotos = photosList.filter(
      (photo) => photo.mediaType === 'Delivery',
    );
    const pickUpVideos =
      videos && videos.length > 0
        ? videos.filter((video) => video.type === 'Pickup').map(parseVideoSlide)
        : [];
    const pickUpPhotos = photosList
      .slice(1, photos.length)
      .filter((photo) => photo.mediaType === 'Pickup');

    if (isVideoInspectionEnabled) {
      return [
        ...photosList.slice(0, 1),
        ...pickUpVideos,
        ...pickUpPhotos,
        ...deliveryVideos,
        ...deliveryPhotos,
      ];
    }
    return photosList;
  }, [isVideoInspectionEnabled, photosList, videos, photos.length]);

  return slides;
}

function parseVideoSlide(video: VideoDTO) {
  return {
    type: 'video' as const,
    width: 1280,
    height: 720,
    poster: video.thumbnail_url,
    autoplay: true,
    sources: [
      {
        src: video.url,
        type: 'video/mp4',
      },
    ],
  };
}

export function parsePhotoSlide(photo: PhotoDTO, title: ReactNode) {
  return {
    src: photo.url,
    alt: '',
    imageFit: 'contain' as ImageFit,
    title,
    mediaType: photo.type,
  };
}
