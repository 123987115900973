import { useDeepEqualValue, useEventHandler } from '@superdispatch/hooks';
import { useEffect, useState } from 'react';
import { logError } from 'shared/helpers/ErrorTracker';
import {
  connect,
  disconnect,
  WebSocketState,
  WebSocketStatus,
} from './WebSocket';
import type {
  WebSocketWorkerAction,
  WorkerAction,
  WorkerWebSocketDispatch,
} from './WebSocketWorker';
import webSocketWorkerUrl from './WebSocketWorker?sharedworker&url';

function isSharedWorkerSupported() {
  if (!('SharedWorker' in window)) {
    return false;
  }

  // Webview crashes when instantiating shared workers in iOS 16.1 https://developer.apple.com/forums/thread/718757
  if (/iPhone\sOS\s16_1(_\d+)?/.test(navigator.userAgent)) {
    return false;
  }

  return true;
}

class WebSocketWorkerClient {
  private worker: SharedWorker | null;

  constructor() {
    if (!isSharedWorkerSupported()) {
      return;
    }

    try {
      this.worker = new SharedWorker(webSocketWorkerUrl);
      this.worker.port.start();

      window.addEventListener('beforeunload', () => {
        this.postMessage({ type: 'close-port' });
      });
    } catch (error: unknown) {
      logError(error, 'WebSocketWorkerClient');
    }
  }

  getWorkerPort() {
    if (this.worker) return this.worker.port;
    return null;
  }

  postMessage(action: WorkerAction) {
    if (this.worker) {
      this.worker.port.postMessage(action);
    }
  }

  connectWorkerWebSocket(url: string) {
    this.postMessage({ type: 'connect', url });
  }

  checkConnectionStatus(url: string) {
    this.postMessage({ type: 'status', url });
  }
}

const workerClient = new WebSocketWorkerClient();

export function useWebSocketWorker(
  url: null | string,
  fn: WorkerWebSocketDispatch,
): WebSocketState {
  const [status, setStatus] = useState<WebSocketStatus>('unknown');
  const dispatch = useEventHandler(fn);

  useEffect(() => {
    if (!url || 'Cypress' in window || !('WebSocket' in window)) {
      setStatus('unknown');

      return;
    }

    const onOpen = () => {
      setStatus('open');
      dispatch({ type: 'open' });
    };

    const onClose = () => {
      setStatus('unknown');
      dispatch({ type: 'close' });
    };

    const onError = () => {
      setStatus('unknown');
      dispatch({ type: 'error' });
    };

    const onMessage = (event: Pick<MessageEvent, 'data'>) => {
      dispatch({ type: 'message', event });
    };

    const onWorkerMessage = (event: MessageEvent<WebSocketWorkerAction>) => {
      switch (event.data.type) {
        case 'open':
          onOpen();
          break;

        case 'close':
          onClose();
          break;

        case 'error':
          onError();
          break;

        case 'message':
          onMessage(event.data.event);
          break;

        default:
          break;
      }
    };

    if (isSharedWorkerSupported()) {
      workerClient.connectWorkerWebSocket(url);

      const workerPort = workerClient.getWorkerPort();

      workerPort?.addEventListener('message', onWorkerMessage);
      workerPort?.addEventListener('messageerror', onError);

      return () => {
        workerPort?.removeEventListener('message', onWorkerMessage);
        workerPort?.removeEventListener('messageerror', onError);
      };
    }

    const id = Math.random();
    const ws = connect(id, url);

    // Manually trigger open event when we get WebSocket from the pool.
    if (ws.readyState === ws.OPEN) onOpen();

    ws.addEventListener('open', onOpen);
    ws.addEventListener('close', onClose);
    ws.addEventListener('error', onError);
    ws.addEventListener('message', onMessage);

    return () => {
      disconnect(id, url);

      ws.removeEventListener('open', onOpen);
      ws.removeEventListener('close', onClose);
      ws.removeEventListener('error', onError);
      ws.removeEventListener('message', onMessage);
    };
  }, [url, dispatch]);

  return useDeepEqualValue({ status });
}
