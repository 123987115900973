import { Card, CardContent, Link, Typography } from '@material-ui/core';
import { FormikCheckboxField } from '@superdispatch/forms';
import { Stack } from '@superdispatch/ui';
import { FormikSettingsEmailsField } from './core/SettingsMultipleEmailField';

export function SettingsEmailNotifications() {
  return (
    <Card aria-label="Notification Emails Settings Card">
      <CardContent>
        <Stack space="xsmall">
          <Typography variant="h3">Notification Emails</Typography>

          <Stack space="medium">
            <FormikSettingsEmailsField
              name="notification_emails"
              label="Notification emails"
              fullWidth={true}
              helperText={
                <>
                  Receive pickup/delivery notifications. Enter multiple emails
                  with comma.{' '}
                  <Link
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://support.superdispatch.com/everything-dispatch-dashboard/pickupdelivery-notification-emails"
                  >
                    Learn More
                  </Link>
                </>
              }
            />

            <FormikSettingsEmailsField
              fullWidth={true}
              name="delivery_confirmation_emails"
              label="Delivery confirmation emails"
              helperText={
                <>
                  Receive copy of BOLs for all delivered loads. Enter multiple
                  emails with comma.{' '}
                  <Link
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://support.superdispatch.com/everything-dispatch-dashboard/delivery-confirmation-emails"
                  >
                    Learn More
                  </Link>
                </>
              }
            />

            <FormikCheckboxField
              name="broker_notifications_enabled"
              label={
                <>
                  Send pickup/delivery notifications to brokers.{' '}
                  <Link
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://support.superdispatch.com/everything-dispatch-dashboard/broker-notifications"
                  >
                    Learn More
                  </Link>
                </>
              }
            />
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
}
