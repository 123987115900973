import { Toolbar, ToolbarProps, Typography } from '@material-ui/core';
import { Column, Columns } from '@superdispatch/ui';
import { FC, ReactNode } from 'react';
import styled from 'styled-components';

export const PageToolbar: FC<ToolbarProps> = styled(Toolbar)`
  padding: 16px 32px;
`;

export interface PageToolbarContentProps {
  title?: ReactNode;
  startActions?: ReactNode;
  endActions?: ReactNode;
}

export function PageToolbarContent({
  title,
  startActions,
  endActions,
}: PageToolbarContentProps) {
  return (
    <Columns space="xsmall" align="center">
      {!!startActions && <Column width="content">{startActions}</Column>}

      {!!title && (
        <Column width="fluid">
          <Typography variant="h2">{title}</Typography>
        </Column>
      )}

      {!!endActions && <Column width="content">{endActions}</Column>}
    </Columns>
  );
}
