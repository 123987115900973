import { trackEvent } from 'shared/helpers/Analytics';
import { NotificationVariant } from './NotificationDTO';

export type NotificationAnalyticsEvent =
  | {
      name: 'Carrier Disabled Verification Process Notifications';
      utm_content?:
        | 'Request Pending'
        | 'Request Approved'
        | 'Request Declined'
        | 'Request Reviewed'
        | 'Request Revoked';
    }
  | { name: 'Carrier Clicked Update Password Banner'; utm_content?: never }
  | { name: 'Carrier Clicked Upgrade Now Alert'; utm_content?: never }
  | {
      name: 'Carrier Clicked Verify FMCSA Phone Number Snackbar';
      utm_content?: never;
    };

export function trackNotificationEvent(event: NotificationAnalyticsEvent) {
  trackEvent(event.name, {
    utm_medium: 'Alert Banner',
    utm_content: event.utm_content,
  });
}

type NotificationUtmContentMap = Map<
  NotificationVariant['notification_type'],
  NotificationAnalyticsEvent['utm_content']
>;

export function getUtmContentForNotification(
  type: NotificationVariant['notification_type'],
) {
  const utmContent: NotificationUtmContentMap = new Map([
    ['verified_carrier_request_pending', 'Request Pending'],
    ['verified_carrier_request_approved', 'Request Approved'],
    ['verified_carrier_request_declined', 'Request Declined'],
    ['verified_carrier_request_reviewed', 'Request Reviewed'],
    ['verified_carrier_status_revoked', 'Request Revoked'],
  ]);

  return utmContent.get(type);
}
