import { Card, CardContent, IconButton, Typography } from '@material-ui/core';
import { Add, Edit, Send } from '@material-ui/icons';
import { formatDate, FormattedDate } from '@superdispatch/dates';
import { CardButton, Column, Columns, Inline, Stack } from '@superdispatch/ui';
import { DescriptionItem } from '@superdispatch/ui-lab';
import { round } from 'lodash-es';
import { useMemo, useState } from 'react';
import { BankErrorSimpleBanner } from 'shared/errors/SuperPayBankErrors';
import { isEmptyRecord } from 'shared/helpers/CommonHelpers';
import { formatCurrency } from 'shared/helpers/IntlHelpers';
import {
  formatLoadPayment,
  SuperPayLabel,
} from 'shared/helpers/PaymentHelpers';
import { SuperPayNudgingBanner } from 'shared/modules/superpay/SuperPayNudgingBanner';
import {
  useCarrierPaidMethodOptions,
  useCarrierSettings,
} from 'shared/settings/CarrierSettingsAPI';
import { useFlag } from 'shared/settings/FeatureToggles';
import { DottedLine } from 'shared/ui/DottedLine';
import { InlineBulletItems } from 'shared/ui/InlineBulletItems';
import { HiddenForPrint } from 'shared/ui/Print';
import { computePrice } from 'shared/utils/NumberUtils';
import { EditPaymentDrawer } from '../core/EditLoadPaymentDrawer';
import { LoadPaymentStatus } from '../core/LoadPaymentStatus';
import { LoadDTO, loadPaymentSchema } from '../data/LoadDTO';
import { ViewLoadLegNotes } from './ViewLoadLegNotes';

export interface ViewLoadPaymentProps {
  load: LoadDTO;
  layout?: 'print';
  isEnabledEditLoad?: boolean;
}

export function ViewLoadPayment({
  load,
  layout,
  isEnabledEditLoad = true,
}: ViewLoadPaymentProps) {
  const isSuperPayErrorNotificationsEnabled = useFlag(
    'superpay_bank_error_notifications',
  );
  const isExpeditedPayEnabled = useFlag('payments_expedited_pay');
  const { data: settings } = useCarrierSettings();
  const defaultValuePlaceholder = 'No details';
  const paidMethodOptions = useCarrierPaidMethodOptions();
  const payment = useMemo(
    () => loadPaymentSchema.cast(load.payments[0]),
    [load.payments],
  );

  const [modalState, setModalState] = useState<'edit'>();

  const renderCardRow = (
    title: string,
    e2eValue: string,
    value: string | number | JSX.Element,
    boldValue?: boolean,
  ) => (
    <Columns align="center">
      <Column width="content">
        <Typography color="textSecondary" gutterBottom={true}>
          {title}
        </Typography>
      </Column>
      <Column width="fluid">
        <DottedLine />
      </Column>
      <Column width="content">
        <Typography
          align="right"
          color="textPrimary"
          variant={boldValue ? 'h4' : 'body2'}
          data-e2e={e2eValue}
        >
          {value}
        </Typography>
      </Column>
    </Columns>
  );

  const renderNotes = (notes: string, e2eValue?: string) => (
    <Stack>
      <Typography color="textSecondary">Notes</Typography>
      <ViewLoadLegNotes
        content={notes}
        data-e2e={e2eValue}
        disableTruncate={layout === 'print'}
      />
    </Stack>
  );

  const openDrawer = () => {
    setModalState('edit');
  };

  const isEmpty = useMemo(() => {
    const { invoice, price, ...paymentDetails } = payment;
    return !price && isEmptyRecord(invoice) && isEmptyRecord(paymentDetails);
  }, [payment]);

  const vehiclesTotalPrice = useMemo(
    () => computePrice(load.vehicles),
    [load.vehicles],
  );

  const paidMethod = useMemo(
    () => !!payment.paid_method && paidMethodOptions.get(payment.paid_method),
    [payment.paid_method, paidMethodOptions],
  );

  const { price, expedited_pay_fee: expeditedPayFee } = payment;
  const { expeditedFeePercent, expeditedTotalPrice } = useMemo(() => {
    const isExpeditedPay = price && expeditedPayFee && isExpeditedPayEnabled;

    const feeValue = isExpeditedPay ? (expeditedPayFee * 100) / price : 0;
    const remainsPrice = isExpeditedPay ? price - expeditedPayFee : 0;

    return {
      expeditedFeePercent: round(feeValue),
      expeditedTotalPrice: remainsPrice,
    };
  }, [expeditedPayFee, isExpeditedPayEnabled, price]);

  return (
    <>
      <EditPaymentDrawer
        loadGUID={load.guid}
        open={modalState === 'edit'}
        isEnabledEditLoad={isEnabledEditLoad}
        onClose={() => {
          setModalState(undefined);
        }}
      />

      {isEmpty ? (
        layout !== 'print' && (
          <HiddenForPrint>
            <CardButton
              startIcon={<Add />}
              onClick={openDrawer}
              aria-label="Add payment"
              disabled={!load.can_be_edited}
            >
              Add payment
            </CardButton>
          </HiddenForPrint>
        )
      ) : (
        <Card
          aria-label="Payment Details"
          elevation={0}
          variant={layout === 'print' ? 'elevation' : 'outlined'}
        >
          <CardContent>
            <Stack space="large">
              <Stack space="small">
                <Stack>
                  <Columns space="xxsmall">
                    <Column width="fluid">
                      <Stack>
                        <Typography variant="h3" color="textPrimary">
                          Payment
                        </Typography>
                        {payment.method === 'superpay' ? (
                          settings?.superpay.payment_error &&
                          isSuperPayErrorNotificationsEnabled ? (
                            <BankErrorSimpleBanner
                              source="Load Details"
                              paymentError={settings.superpay.payment_error}
                            />
                          ) : (
                            <SuperPayNudgingBanner source="Load Details" />
                          )
                        ) : null}
                      </Stack>
                    </Column>

                    {layout !== 'print' && load.can_be_edited && (
                      <Column width="content">
                        <IconButton
                          size="small"
                          onClick={openDrawer}
                          aria-label="Edit Payment Button"
                        >
                          <Edit />
                        </IconButton>
                      </Column>
                    )}
                  </Columns>

                  <Stack>
                    <LoadPaymentStatus payment={payment} />

                    {!!payment.invoice.sent_at && (
                      <DescriptionItem
                        icon={<Send />}
                        label={
                          <>
                            Invoiced on{' '}
                            <FormattedDate
                              date={payment.invoice.sent_at}
                              variant="DateTime"
                            />
                          </>
                        }
                      />
                    )}
                  </Stack>
                </Stack>

                <Stack space="xsmall">
                  <Stack space="none">
                    {renderCardRow(
                      'Price',
                      'payment.price',
                      (payment.price && formatCurrency(payment.price)) ||
                        defaultValuePlaceholder,
                      true,
                    )}
                    {!!vehiclesTotalPrice &&
                      vehiclesTotalPrice !== payment.price && (
                        <Typography color="textSecondary" align="right">
                          Vehicles Total {formatCurrency(vehiclesTotalPrice)}
                        </Typography>
                      )}
                  </Stack>
                  {renderCardRow(
                    'Method',
                    'payment.method',
                    payment.method === 'superpay' ? (
                      <InlineBulletItems>
                        <SuperPayLabel source="Load Details" />
                        {payment.terms}
                      </InlineBulletItems>
                    ) : (
                      formatLoadPayment(payment.method) ||
                        defaultValuePlaceholder
                    ),
                  )}
                  {!!expeditedFeePercent &&
                    renderCardRow(
                      'Expedite Fee',
                      'payment.expedited_pay_fee',
                      <Inline space="xxsmall">
                        <Typography>
                          {formatCurrency(payment.expedited_pay_fee)}
                        </Typography>
                        <Typography color="textSecondary">
                          ({expeditedFeePercent}%)
                        </Typography>
                      </Inline>,
                    )}
                  {!!expeditedTotalPrice &&
                    renderCardRow(
                      'Expected Payment',
                      'payment.expedited_payment',
                      formatCurrency(expeditedTotalPrice),
                    )}
                  {renderCardRow(
                    'Broker Fee',
                    'payment.broker_fee',
                    (payment.broker_fee &&
                      formatCurrency(payment.broker_fee)) ||
                      defaultValuePlaceholder,
                  )}
                  {renderCardRow(
                    'Driver Pay',
                    'payment.driver_pay',
                    (payment.driver_pay &&
                      formatCurrency(payment.driver_pay)) ||
                      defaultValuePlaceholder,
                  )}
                </Stack>

                {!!payment.notes && renderNotes(payment.notes, 'payment.notes')}
              </Stack>

              {!!payment.paid_at && (
                <Stack>
                  {renderCardRow(
                    'Paid Amount',
                    'payment.paid_amount',
                    payment.paid_amount
                      ? formatCurrency(payment.paid_amount)
                      : defaultValuePlaceholder,
                    true,
                  )}
                  {renderCardRow(
                    'Method',
                    'payment.paid_method',
                    !paidMethod ? (
                      defaultValuePlaceholder
                    ) : paidMethod.toLowerCase() === 'superpay' ? (
                      <InlineBulletItems>
                        <SuperPayLabel source="Load Details" />
                        {payment.terms}
                      </InlineBulletItems>
                    ) : (
                      paidMethod
                    ),
                  )}
                  {renderCardRow(
                    'Receipt Date',
                    'payment.receipt_date',
                    (payment.paid_at &&
                      formatDate(payment.paid_at, { variant: 'DateTime' })) ||
                      defaultValuePlaceholder,
                  )}
                  {renderCardRow(
                    'Reference Number',
                    'payment.payment_reference_number',
                    payment.payment_reference_number || defaultValuePlaceholder,
                  )}
                </Stack>
              )}

              {!isEmptyRecord(payment.invoice) && (
                <Stack>
                  {!!payment.invoice.invoice_id && (
                    <Typography variant="h4" data-e2e="payment.invoice.title">
                      Invoice #{payment.invoice.invoice_id}
                    </Typography>
                  )}
                  {!!payment.invoice.invoice_notes &&
                    renderNotes(
                      payment.invoice.invoice_notes,
                      'payment.invoice_notes',
                    )}
                </Stack>
              )}
            </Stack>
          </CardContent>
        </Card>
      )}
    </>
  );
}
