import { Route, Switch } from 'react-router-dom';
import { DispatchersPage } from './dispatcher-management/DispatchersPage';
import { DispatcherProfilePage } from './dispatchers-profile/DispatcherProfilePage';

export function DispatchersContainerPage() {
  return (
    <Switch>
      <Route path="/dispatchers/profile">
        <DispatcherProfilePage />
      </Route>
      <Route path="/dispatchers">
        <DispatchersPage />
      </Route>
    </Switch>
  );
}
