import {
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  makeStyles,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { Color, Column, Columns, Inline, Stack } from '@superdispatch/ui';
import { Box, Button, TextBox } from '@superdispatch/ui-lab';
import { PERMITTED_PAGES } from 'shared/errors/data/useShowSubscriptionCancelledDialog';
import { LoadsSidebarIcon } from 'shared/icons/LoadsSidebarIcon';
import { OrganizationIcon } from 'shared/icons/OrganizationIcon';
import { UploadCloudIcon } from 'shared/icons/UploadCloudIcon';
import { CustomerComments } from 'shared/modules/subscription/core/CustomerComments';
import styled from 'styled-components';
import { trackLoadboardEvent } from './LoadboardAnalytics';

interface TrialExpiredDialogProps {
  open: boolean;
  onClose: () => void;
  onReactivate: () => void;
  type: 'trial_expired' | 'cancelled';
}

const items = [
  {
    icon: <LoadsSidebarIcon fontSize="large" htmlColor={Color.Dark300} />,
    title: 'Advanced Load Management',
    description:
      'Effortlessly create, import, request, negotiate, and book loads without making a single phone call.',
  },
  {
    icon: <OrganizationIcon htmlColor={Color.Dark300} />,
    title: 'Unified Load Organization',
    description:
      'Keep track of all your loads from any load board in one convenient place.',
  },
  {
    icon: <UploadCloudIcon htmlColor={Color.Dark300} />,
    title: 'Boost Productivity',
    description:
      'Speed up your process by importing loads from PDF or CSV files, minimizing manual tasks and errors.',
  },
];

const StyledButton = styled(Button)`
  padding-left: 24px;
  padding-right: 24px;
`;

const StyledIconButton = styled(IconButton)`
  padding: 0;
`;

const useStyles = makeStyles(() => ({
  backDrop: {
    backdropFilter: 'blur(3px)',
    backgroundColor: 'rgba(0,0,0,0.32)',
  },
}));

export function TrialExpiredDialog({
  open,
  onClose,
  onReactivate,
  type,
}: TrialExpiredDialogProps) {
  const classes = useStyles();
  const isPermitted = PERMITTED_PAGES.some((page) =>
    window.location.pathname.startsWith(page),
  );

  if (isPermitted) {
    return null;
  }
  return (
    <Dialog
      BackdropProps={{
        classes: {
          root: classes.backDrop,
        },
      }}
      open={open}
    >
      <DialogContent>
        <Box padding="medium" paddingBottom="xlarge">
          <Stack space="medium">
            <Stack space="small">
              <Columns align="center">
                <Column width="fluid">
                  <TextBox variant="heading-2">
                    {type === 'trial_expired'
                      ? 'Your Trial of Carrier TMS Pro has Ended'
                      : 'Your Subscription is Canceled'}
                  </TextBox>
                </Column>
                <Column width="content">
                  <Inline horizontalAlign="right">
                    <StyledIconButton onClick={onClose}>
                      <Close fontSize="medium" />
                    </StyledIconButton>
                  </Inline>
                </Column>
              </Columns>
              <TextBox variant="body">
                {type === 'trial_expired'
                  ? 'We hope you enjoyed your trial of Carrier TMS Pro. While browsing the Super Loadboard remains free, access to advanced load management features are no longer available. Upgrade to Carrier TMS Pro today to maintain full access to everything Carrier Pro has to offer.'
                  : 'While browsing the Super Loadboard remains free, access to advanced load management features are no longer available. Reactivate Carrier TMS Pro today to maintain full access to everything Carrier Pro has to offer.'}
              </TextBox>
            </Stack>

            {items.map((item) => (
              <Columns align="top" key={item.title} space="medium">
                <Column width="content">
                  <Inline horizontalAlign="right">{item.icon}</Inline>
                </Column>
                <Column>
                  <Stack space="xxsmall">
                    <TextBox variant="heading-4">{item.title}</TextBox>
                    <TextBox variant="body" color="secondary">
                      {item.description}
                    </TextBox>
                  </Stack>
                </Column>
              </Columns>
            ))}

            <Divider />

            <CustomerComments source="canceled" />

            <Box paddingTop="xsmall">
              <Columns space="small">
                <Column width="1/2">
                  <StyledButton
                    onClick={() => {
                      onClose();
                      trackLoadboardEvent({
                        name: 'Carrier Clicked Access Free Loadboard',
                      });
                    }}
                    fullWidth={true}
                    size="large"
                    variant="neutral"
                  >
                    Access Free Loadboard
                  </StyledButton>
                </Column>
                <Column width="1/2">
                  <Button
                    fullWidth={true}
                    size="large"
                    onClick={() => {
                      onReactivate();
                      if (type === 'trial_expired') {
                        trackLoadboardEvent({
                          name: 'Carrier Clicked Upgrade Now',
                          utm_medium: 'Paywall',
                        });
                      } else {
                        trackLoadboardEvent({
                          name: 'Carrier Clicked Reactivate Now',
                          utm_medium: 'Paywall',
                        });
                      }
                    }}
                  >
                    {type === 'trial_expired'
                      ? 'Upgrade Now'
                      : 'Reactivate Now'}
                  </Button>
                </Column>
              </Columns>
            </Box>
          </Stack>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
