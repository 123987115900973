import { useSplashScreen } from 'shared/layout/AppLayoutManager';

export interface SplashScreenProps {
  isVisible?: boolean;
}

export function SplashScreen({ isVisible }: SplashScreenProps) {
  useSplashScreen(isVisible);

  return null;
}
