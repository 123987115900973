import { Divider, Typography } from '@material-ui/core';
import { Inline, Stack } from '@superdispatch/ui';
import { AnchorButton, Button } from '@superdispatch/ui-lab';
import { MobileAppBridge } from 'shared/data/MobileAppBridge';
import { EmptySearchLoadsIcon } from 'shared/icons/EmptySearchLoadsIcon';
import styled from 'styled-components';

const EmptyStateContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  margin: auto;
  max-width: 280px;
`;

const StyledDivider = styled(Divider)`
  width: 125px;
`;

export function LoadSuggestionsNotFound({
  hasSelection,
  onClear,
}: {
  hasSelection?: boolean;
  onClear?: () => void;
}) {
  return (
    <EmptyStateContainer>
      <Stack space="medium" align="center">
        <EmptySearchLoadsIcon />
        <Stack>
          <Typography variant="h3" align="center">
            No Suggestions Yet
          </Typography>
          <Typography color="textSecondary" align="center">
            Before we prepare suggestions that fit your needs try browsing our
            Loadboard.
          </Typography>
        </Stack>
        {MobileAppBridge.isInjected() ? (
          <Button
            onClick={() => {
              MobileAppBridge.openAvailableLoads();
            }}
          >
            Find Loads on Loadboard
          </Button>
        ) : (
          <AnchorButton href="/loadboard">Find Loads on Loadboard</AnchorButton>
        )}

        {hasSelection && (
          <Stack align="center">
            <Inline verticalAlign="center">
              <StyledDivider />
              <Typography color="textSecondary">or</Typography>
              <StyledDivider />
            </Inline>

            <Button onClick={onClear} variant="text">
              Clear Filter
            </Button>
          </Stack>
        )}
      </Stack>
    </EmptyStateContainer>
  );
}
