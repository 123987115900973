import {
  Card,
  CardContent,
  IconButton,
  MenuItem,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { Add, Delete } from '@material-ui/icons';
import {
  FormikCheckboxField,
  FormikCurrencyField,
  FormikDateField,
  FormikTextField,
} from '@superdispatch/forms';
import { Inline, Stack, useUID } from '@superdispatch/ui';
import { Button } from '@superdispatch/ui-lab';
import { dequal } from 'dequal';
import { FormikProvider } from 'formik';
import { useCallback, useMemo } from 'react';
import { APIResponse } from 'shared/api/APIClient';
import { useAppFormik } from 'shared/form/AppFormik';
import { useFormikComposerForm } from 'shared/form/FormikComposer';
import { FormikMediaField } from 'shared/form/FormikMediaField';
import { InputTableCell } from 'shared/ui/Table';
import { isPossibleUUID } from 'shared/utils/StringUtils';
import { yupArray, yupObject } from 'shared/utils/YupUtils';
import { useExpensesAPI } from '../data/ExpensesAPI';
import {
  isEmptyLoadExpenseEditDTO,
  LoadExpenseEditDTO,
  loadExpenseEditSchema,
} from '../data/LoadActionsDTO';
import {
  formatLoadExpenseType,
  LoadDTO,
  LoadExpenseDTO,
  LOAD_EXPENSE_TYPES,
} from '../data/LoadDTO';
import { trackLoadsEvent } from '../data/LoadsAnalytics';

const editLoadPageExpensesSchema = yupObject({
  expenses: yupArray(loadExpenseEditSchema).required(),
});

export interface EditLoadPageExpensesProps {
  load: LoadDTO;
}

export function EditLoadPageExpenses({ load }: EditLoadPageExpensesProps) {
  const uid = useUID();
  const { addExpense, editExpense, deleteExpense } = useExpensesAPI();

  const [initialValues, initialExpensesMap] = useMemo(() => {
    const nextInitialValues = { expenses: [] as unknown[] };
    const nextInitialExpensesMap = new Map<string, LoadExpenseDTO>();

    if (!load.expenses?.length) {
      nextInitialValues.expenses.push({ date: Date.now() });
    } else {
      for (const expense of load.expenses) {
        nextInitialValues.expenses.push(expense);
        nextInitialExpensesMap.set(expense.guid, expense);
      }
    }

    return [nextInitialValues, nextInitialExpensesMap];
  }, [load.expenses]);
  const formik = useAppFormik({
    initialValues,
    validationSchema: editLoadPageExpensesSchema,
    onSubmit(values) {
      const requests: Array<Promise<APIResponse>> = [];
      const expensesToRemove = new Set(initialExpensesMap.keys());

      for (const expense of values.expenses) {
        if (isPossibleUUID(expense.guid)) {
          const initialExpense = initialExpensesMap.get(expense.guid);
          expensesToRemove.delete(expense.guid);
          if (!dequal(expense, initialExpense)) {
            requests.push(editExpense({ ...expense, load_guid: load.guid }));
          }
        } else if (!isEmptyLoadExpenseEditDTO(expense)) {
          requests.push(addExpense({ ...expense, load_guid: load.guid }));
        }
      }

      for (const guid of expensesToRemove) {
        requests.push(deleteExpense(guid));
      }

      return Promise.all(requests).then(
        ([firstResponse]) => firstResponse as APIResponse,
      );
    },
    onSubmitSuccess: (_, values) => {
      values.expenses.forEach((expense) => {
        const initialValue = initialExpensesMap.get(expense.guid);
        if (expense.type === 'other' && expense.type !== initialValue?.type) {
          trackLoadsEvent({ name: 'CTMS: Selected Other Expense Type' });
        }

        if (expense.name && expense.name !== initialValue?.name) {
          trackLoadsEvent({
            name: 'CTMS: Added Other Expense Type Description',
          });
        }
      });
    },
  });

  const nodeRef = useFormikComposerForm('expenses', formik);

  const {
    setValues,
    setTouched,
    values: { expenses },
  } = formik;
  const setExpenses = useCallback(
    (updater: (prev: LoadExpenseEditDTO[]) => LoadExpenseEditDTO[]): void => {
      void setTouched({});
      void setValues((previousValues) => ({
        ...previousValues,
        expenses: updater(previousValues.expenses),
      }));
    },
    [setTouched, setValues],
  );

  return (
    <FormikProvider value={formik}>
      <Card aria-label="Expenses Card" ref={nodeRef}>
        <CardContent>
          <Inline verticalAlign="center" space="xsmall" noWrap={true}>
            <Typography variant="h3" id={uid}>
              Expenses
            </Typography>

            <Button
              variant="text"
              startIcon={<Add />}
              onClick={() => {
                setExpenses((prev) => [
                  loadExpenseEditSchema.cast({ date: Date.now() }),
                  ...prev,
                ]);
              }}
            >
              Add
            </Button>
          </Inline>
        </CardContent>

        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <InputTableCell>Type</InputTableCell>
                <InputTableCell>Cost</InputTableCell>
                <InputTableCell>Date</InputTableCell>
                <InputTableCell>Receipt File</InputTableCell>
                <InputTableCell>Show on Invoice</InputTableCell>
                <InputTableCell>Deduct from Driver Pay</InputTableCell>
                <InputTableCell />
              </TableRow>
            </TableHead>
            <TableBody aria-label="Expenses List">
              {expenses.map((expense, idx) => (
                <TableRow aria-label={`expense ${idx}`} key={idx}>
                  <InputTableCell width="240px">
                    <Stack>
                      <FormikTextField
                        select={true}
                        fullWidth={true}
                        name={`expenses[${idx}].type`}
                        onChange={(e) => {
                          if (e.target.value !== 'other') {
                            void formik.setFieldValue(
                              `expenses[${idx}].name`,
                              '',
                            );
                          }
                        }}
                      >
                        {LOAD_EXPENSE_TYPES.map((expenseType) => (
                          <MenuItem key={expenseType} value={expenseType}>
                            {formatLoadExpenseType(expenseType)}
                          </MenuItem>
                        ))}
                      </FormikTextField>

                      {expense.type === 'other' && (
                        <FormikTextField
                          fullWidth={true}
                          placeholder="Specify Type"
                          name={`expenses[${idx}].name`}
                        />
                      )}
                    </Stack>
                  </InputTableCell>
                  <InputTableCell width="104px">
                    <FormikCurrencyField
                      fullWidth={true}
                      name={`expenses[${idx}].price`}
                    />
                  </InputTableCell>
                  <InputTableCell width="160px">
                    <FormikDateField
                      fullWidth={true}
                      name={`expenses[${idx}].date`}
                    />
                  </InputTableCell>
                  <InputTableCell>
                    <FormikMediaField
                      fullWidth={true}
                      name={`expenses[${idx}].receipt_url`}
                    />
                  </InputTableCell>
                  <InputTableCell width="136px">
                    <FormikCheckboxField
                      aria-label="expense show on invoice"
                      label=""
                      name={`expenses[${idx}].show_on_invoice`}
                    />
                  </InputTableCell>
                  <InputTableCell width="172px">
                    <FormikCheckboxField
                      aria-label="deduct from driver pay"
                      label=""
                      name={`expenses[${idx}].deduct_from_driver_pay`}
                    />
                  </InputTableCell>

                  <InputTableCell padding="none" width="48px">
                    <IconButton
                      edge="end"
                      aria-label={`Remove expense ${idx + 1}`}
                      onClick={() => {
                        setExpenses((prevVehicles) =>
                          prevVehicles.filter(
                            (_vehicle, index) => idx !== index,
                          ),
                        );
                      }}
                    >
                      <Delete />
                    </IconButton>
                  </InputTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    </FormikProvider>
  );
}
