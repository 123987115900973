import { Link } from '@material-ui/core';
import { Email, Phone } from '@material-ui/icons';
import { SuspendedPhoneLink } from '@superdispatch/phones';
import { InfoCard, Stack } from '@superdispatch/ui';
import { Box, DescriptionItem, TextBox } from '@superdispatch/ui-lab';
import { DispatcherDTO } from 'shared/modules/dispatcher/DispatcherDTO';

interface DriverInfoCardProps {
  currentDispatcher: DispatcherDTO;
}

export function DispatcherInfoCard({ currentDispatcher }: DriverInfoCardProps) {
  return (
    <Box maxWidth="400px" role="description">
      <InfoCard>
        <Stack space="small">
          <TextBox variant="heading-3">Dispatcher Info</TextBox>

          <DescriptionItem
            wrap={true}
            label="Email"
            icon={<Email />}
            fallback="Not Available"
          >
            {!!currentDispatcher.email && (
              <Link href={`mailto:${currentDispatcher.email}`}>
                {currentDispatcher.email}
              </Link>
            )}
          </DescriptionItem>

          <DescriptionItem
            label="Phone"
            icon={<Phone />}
            fallback="Not Available"
          >
            {!!currentDispatcher.phone && (
              <SuspendedPhoneLink phone={currentDispatcher.phone} />
            )}
          </DescriptionItem>
        </Stack>
      </InfoCard>
    </Box>
  );
}
