import { isWebView } from 'shared/constants/AppTypeConstants';
import { MobileAppBridge } from 'shared/data/MobileAppBridge';
import {
  openExternalURL,
  OpenExternalURLOptions,
} from 'shared/helpers/URLHelpers';
import { VERIFICATION_STATUS } from 'shared/modules/carrier-profile/data/VerificationStatusDTO';

type Target = OpenExternalURLOptions<URLSearchParams>['target'];

export interface BecomeVerifiedCarrierOptions {
  source:
    | 'slb_load_details'
    | 'become_verified_banner'
    | 'become_verified_popup'
    | 'become_verified_popup_expired'
    | 'advanced_search'
    | 'onboarding_dialog';
  backUrl?: string;
  target?: Target;
}

export type VerificationStatus = (typeof VERIFICATION_STATUS)[number];

export function becomeVerifiedCarrier(
  verificationStatus: VerificationStatus | undefined,
  options: BecomeVerifiedCarrierOptions,
) {
  const isSuspended =
    verificationStatus &&
    (verificationStatus === 'revoked' || verificationStatus === 'declined');

  if (isSuspended) {
    openCarrierProfile(options);
  } else {
    openCarrierVerification(options);
  }
}

function openCarrierProfile({
  source,
  backUrl,
  target,
}: BecomeVerifiedCarrierOptions) {
  if (MobileAppBridge.isInjected()) {
    MobileAppBridge.openProfile();
  } else {
    openExternalURL('/tms/profile{?utm_medium,back_url}', {
      utm_medium: source,
      back_url: backUrl,
      target,
    });
  }
}

function openCarrierVerification({
  source,
  backUrl,
  target,
}: BecomeVerifiedCarrierOptions) {
  if (MobileAppBridge.isInjected()) {
    MobileAppBridge.openVerifiedCarrierPromotionalModal();
    return;
  }

  const postfix = isWebView ? '-webview' : '';

  openExternalURL(
    `/tms${postfix}/profile/verified-carrier-application/step-1{?utm_medium,back_url}`,
    {
      utm_medium: source,
      back_url: backUrl,
      target,
    },
  );
}
