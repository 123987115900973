import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { useSnackbarStack, VisibilityObserver } from '@superdispatch/ui';
import { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { useTripLoadsList } from '../../data/TripsAPI';
import { AddTripLoadsTableRow } from './AddTripLoadsTableRow';

const LoaderRow = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface AddTripLoadsTableProps {
  tripGUID: string;
  searchQuery?: string;
}

export function AddTripLoadsTable({
  tripGUID,
  searchQuery,
}: AddTripLoadsTableProps) {
  const { addSnackbar } = useSnackbarStack();
  const loadsList = useTripLoadsList({
    trip_guid: tripGUID,
    q: searchQuery,
  });

  const loads = useMemo(
    () => loadsList.data?.pages.flatMap((page) => page.data),
    [loadsList],
  );

  const shouldShowLoader = loadsList.isLoading || !!loadsList.hasNextPage;

  useEffect(() => {
    if (loadsList.error) {
      addSnackbar('Error occurred while fetching the loads', {
        variant: 'error',
      });
    }
  }, [loadsList.error, addSnackbar]);

  return (
    <VisibilityObserver
      onChange={(visibility) => {
        if (visibility === 'visible') {
          void loadsList.fetchNextPage();
        }
      }}
      render={({ ref }) => (
        <Table size="small" stickyHeader={true}>
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography variant="h5">Load ID</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h5">Status</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h5">Vehicle</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h5">Origin</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h5">Destination</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h5">Customer Info</Typography>
              </TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {loads?.map((load) => (
              <AddTripLoadsTableRow
                key={load.guid}
                tripGUID={tripGUID}
                {...load}
              />
            ))}

            {shouldShowLoader && (
              <TableRow ref={ref}>
                <TableCell colSpan={7}>
                  <LoaderRow>
                    <CircularProgress size={24} />
                  </LoaderRow>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      )}
    />
  );
}
