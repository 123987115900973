import { CircularProgress, InputAdornment } from '@material-ui/core';
import { FormikTextField } from '@superdispatch/forms';
import { useSnackbarStack } from '@superdispatch/ui';
import { FormikProvider } from 'formik';
import { useAppFormik } from 'shared/form/AppFormik';
import { useTripsAPI } from '../data/TripsAPI';
import { editTripSchema } from '../data/TripsDTO';

interface TripNameEditProps {
  initialTripName: string;
  tripGUID: string;
  isArchived: boolean | null;
}

export function TripNameEdit({
  initialTripName,
  tripGUID,
  isArchived,
}: TripNameEditProps) {
  const { editTrip } = useTripsAPI();
  const { addSnackbar } = useSnackbarStack();

  const formik = useAppFormik({
    validationSchema: editTripSchema,
    initialValues: { name: initialTripName },
    onSubmit: ({ name }) => editTrip(tripGUID, { name }),
    onSubmitSuccess: () => {
      addSnackbar('Trip name was updated', { variant: 'success' });
    },
    onSubmitFailure: () =>
      addSnackbar("Error occurred while updating trip's name", {
        variant: 'error',
      }),
    validate: ({ name }) => {
      if (name === initialTripName) {
        return { tripName: '' };
      }
      return {};
    },
  });

  return (
    <FormikProvider value={formik}>
      <FormikTextField
        id="trip_name"
        name="name"
        label="Trip name"
        fullWidth={true}
        disabled={formik.isSubmitting || !!isArchived}
        onBlur={() => {
          formik.handleSubmit();
        }}
        InputProps={{
          endAdornment: formik.isSubmitting && (
            <InputAdornment position="end">
              <CircularProgress size={16} />
            </InputAdornment>
          ),
        }}
      />
    </FormikProvider>
  );
}
