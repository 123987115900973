import { DialogActions } from '@material-ui/core';
import { Button } from '@superdispatch/ui-lab';
import {
  MutationDialog,
  MutationDialogContent,
} from 'shared/form/MutationDialog';
import { useDeleteAttachment } from '../data/AttachmentsAPI';
import { LoadAttachmentDTO } from '../data/LoadDTO';

interface LoadAttachmentDeleteDialogContentProps {
  onClose: () => void;
  onSuccess: () => void;
  attachment?: LoadAttachmentDTO;
}

function LoadAttachmentDeleteDialogContent({
  onClose,
  onSuccess,
  attachment,
}: LoadAttachmentDeleteDialogContentProps) {
  const { mutate, isLoading } = useDeleteAttachment({
    onSuccess: () => {
      onClose();
      onSuccess();
    },
  });

  return (
    <MutationDialogContent
      disableAutoClose={true}
      title={
        <>
          Delete <strong>{attachment?.name || 'file'}</strong>?
        </>
      }
      actions={
        <DialogActions>
          <Button variant="neutral" onClick={onClose} disabled={isLoading}>
            Cancel
          </Button>
          <Button
            variant="critical"
            autoFocus={true}
            pending={isLoading}
            disabled={!attachment}
            onClick={() => {
              if (attachment) {
                mutate(attachment.guid);
              }
            }}
          >
            Delete
          </Button>
        </DialogActions>
      }
    />
  );
}

export function LoadAttachmentDeleteDialog({
  onClose,
  onSuccess,
  attachment,
}: LoadAttachmentDeleteDialogContentProps) {
  return (
    <MutationDialog onClose={onClose} open={!!attachment}>
      <LoadAttachmentDeleteDialogContent
        onClose={onClose}
        onSuccess={onSuccess}
        attachment={attachment}
      />
    </MutationDialog>
  );
}
