import {
  Divider,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core';
import { useUID } from '@superdispatch/ui';
import { ReactNode, useMemo } from 'react';
import { isLoadStageComplete, LoadStage } from '../data/LoadDTO';
import {
  formatLoadOrderByField,
  isLoadOrderByField,
  LoadOrderByField,
} from '../data/LoadsPageParams';

function getOrderByKeys(stage: LoadStage): LoadOrderByField[][] {
  const isPickedUp = isLoadStageComplete(stage, 'picked_up');
  const isDelivered = isLoadStageComplete(stage, 'delivered');

  return [
    ['broker_name'],
    ['pickup_city', 'delivery_city'],
    ['pickup_state', 'delivery_state'],
    [
      isPickedUp ? 'pickedup_date' : 'pickup_date',
      isDelivered ? 'delivered_date' : 'delivery_date',
    ],
    ['is_dispatched_to_carrier'],
  ];
}

interface LoadsPageFilterOrderByProps {
  stage: LoadStage;
  orderBy: null | LoadOrderByField;
  onChange: (orderBy: null | LoadOrderByField) => void;
}

export function LoadsPageFilterOrderBy({
  stage,
  orderBy,
  onChange,
}: LoadsPageFilterOrderByProps) {
  const uid = useUID();
  const orderKeys = useMemo(() => getOrderByKeys(stage), [stage]);

  return (
    <TextField
      select={true}
      value={orderBy || ''}
      SelectProps={{ displayEmpty: true }}
      InputProps={{
        'aria-labelledby': uid,
        startAdornment: (
          <InputAdornment position="start">
            <Typography id={uid} variant="body1" color="textSecondary">
              Sort by
            </Typography>
          </InputAdornment>
        ),
      }}
      onChange={({ target: { value } }) => {
        if (isLoadOrderByField(value)) {
          onChange(value);
        } else {
          onChange(null);
        }
      }}
    >
      {orderKeys.reduce<ReactNode[]>((acc, fields, idx) => {
        if (idx === 0) {
          acc.push(
            <MenuItem key="reset-order-by" value="">
              None
            </MenuItem>,
          );
        }

        acc.push(<Divider key={`divider-${idx}`} />);

        fields.forEach((value) => {
          acc.push(
            <MenuItem key={value} value={value}>
              {formatLoadOrderByField(value)}
            </MenuItem>,
          );
        });

        return acc;
      }, [])}
    </TextField>
  );
}
