import { useEffect } from 'react';
import { useShowTrialExpiredDialog } from 'shared/errors/data/useShowTrialExpiredDialog';
import { LocalStore, useLocalStore } from 'shared/helpers/Store';

const CELEBRATION_NOTIFICATION_STORAGE_KEY = 'celebration_notification';
const CLEBRATION_CONFEETTI_STORAGE_KEY = 'celebration_confetti';

export function useShowCelebrationNotification() {
  const shouldShowCelebrationNotification = useLocalStore(
    CELEBRATION_NOTIFICATION_STORAGE_KEY,
  );
  const shouldShowConfetti = useLocalStore(CLEBRATION_CONFEETTI_STORAGE_KEY);
  const { closeTrialExpiredDialog } = useShowTrialExpiredDialog();

  function showCelebrationNotification() {
    LocalStore.set(CELEBRATION_NOTIFICATION_STORAGE_KEY, 'true');
    LocalStore.set(CLEBRATION_CONFEETTI_STORAGE_KEY, 'true');
  }

  function closeCelebrationNotification() {
    LocalStore.set(CELEBRATION_NOTIFICATION_STORAGE_KEY, null);
  }

  function onCelebrate() {
    LocalStore.set(CLEBRATION_CONFEETTI_STORAGE_KEY, 'true');
  }

  function closeCelebration() {
    LocalStore.set(CLEBRATION_CONFEETTI_STORAGE_KEY, null);
  }

  useEffect(() => {
    if (shouldShowConfetti) {
      closeTrialExpiredDialog();
    }
  }, [shouldShowConfetti, closeTrialExpiredDialog]);

  useEffect(() => {
    if (!shouldShowConfetti) return;

    const celebrationTimeout = setTimeout(() => {
      closeCelebration();
    }, 5000);

    return () => {
      clearTimeout(celebrationTimeout);
    };
  }, [shouldShowConfetti]);

  return {
    shouldShowCelebrationNotification,
    showCelebrationNotification,
    closeCelebrationNotification,
    closeCelebration,
    onCelebrate,
    shouldShowConfetti,
  };
}
