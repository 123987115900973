import {
  Card,
  CardContent,
  IconButton,
  Link,
  Typography,
} from '@material-ui/core';
import { Add, Business, Edit, LocationOn, Person } from '@material-ui/icons';
import { CardButton, Column, Columns, Stack } from '@superdispatch/ui';
import { DescriptionItem } from '@superdispatch/ui-lab';
import { useMemo, useState } from 'react';
import { HiddenForPrint } from 'shared/ui/Print';
import { joinStrings } from 'shared/utils/StringUtils';
import { EditCustomerDrawer } from '../core/EditLoadCustomerDrawer';
import { isEmptyLoadLegVenue, LoadDTO } from '../data/LoadDTO';
import { trackLoadsEvent } from '../data/LoadsAnalytics';

export interface ViewLoadCustomerProps {
  load: LoadDTO;
  layout?: 'print';
}

export function ViewLoadCustomer({ load, layout }: ViewLoadCustomerProps) {
  const { guid, customer } = load;
  const fullAddress = useMemo(
    () =>
      joinStrings(
        ', ',
        customer.venue.address,
        customer.venue.city,
        `${customer.venue.state} ${customer.venue.zip}`,
      ),
    [customer.venue],
  );
  const isEmpty = useMemo(
    () => isEmptyLoadLegVenue(customer.venue),
    [customer.venue],
  );

  const [modalState, setModalState] = useState<'edit'>();

  const openDrawer = () => {
    setModalState('edit');
  };

  const canBeEdited =
    layout !== 'print' &&
    load.can_be_edited &&
    load.can_edit_customer_information;

  return (
    <>
      <EditCustomerDrawer
        open={modalState === 'edit'}
        loadGUID={guid}
        onClose={() => {
          setModalState(undefined);
        }}
      />

      {isEmpty ? (
        layout !== 'print' && (
          <HiddenForPrint>
            <CardButton
              startIcon={<Add />}
              onClick={openDrawer}
              aria-label="Add customer"
              disabled={!load.can_be_edited}
            >
              Add Customer
            </CardButton>
          </HiddenForPrint>
        )
      ) : (
        <Card
          aria-label="Customer Details"
          elevation={0}
          variant={layout === 'print' ? 'elevation' : 'outlined'}
        >
          <CardContent>
            <Stack>
              <Columns space="xsmall">
                <Column width="fluid">
                  <Typography
                    variant="h3"
                    color="textPrimary"
                    gutterBottom={true}
                  >
                    Customer Information
                  </Typography>
                </Column>

                {canBeEdited && (
                  <Column width="content">
                    <IconButton
                      size="small"
                      onClick={openDrawer}
                      aria-label="Edit Customer Button"
                    >
                      <Edit />
                    </IconButton>
                  </Column>
                )}
              </Columns>
              <Stack data-e2e="venue">
                <DescriptionItem
                  icon={<Business />}
                  label={!customer.venue.name && 'No name'}
                >
                  <Typography
                    component="span"
                    variant="body1"
                    data-e2e="venue.name"
                  >
                    {customer.venue.name && customer.shipper_profile_url ? (
                      <Link
                        href={customer.shipper_profile_url}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={() => {
                          trackLoadsEvent({
                            name: 'CTMS: Clicked Shipper Name',
                            page: 'view_load',
                          });
                        }}
                      >
                        {customer.venue.name}
                      </Link>
                    ) : (
                      customer.venue.name || 'N/A'
                    )}
                  </Typography>
                </DescriptionItem>

                <DescriptionItem
                  icon={<LocationOn />}
                  data-e2e="venue.address"
                  wrap={true}
                  label={!fullAddress && 'No address'}
                >
                  <Typography component="span" data-e2e="venue.address">
                    {fullAddress}
                  </Typography>
                </DescriptionItem>
                {customer.venue.contacts.map((contact, index) => (
                  <Stack space="xxsmall" key={index} data-e2e="venue.contact">
                    <DescriptionItem
                      icon={<Person />}
                      label={!contact.name && 'No contact name'}
                    >
                      {contact.name && (
                        <Typography
                          component="span"
                          data-e2e="venue.contact.name"
                        >
                          {contact.name}
                        </Typography>
                      )}
                    </DescriptionItem>

                    {contact.phone && (
                      <DescriptionItem inset={true} wrap={true}>
                        <Typography
                          component="span"
                          data-e2e="venue.contact.phone"
                        >
                          {contact.phone}
                        </Typography>
                      </DescriptionItem>
                    )}

                    {contact.email && (
                      <DescriptionItem inset={true}>
                        <Link
                          href={`mailto:${contact.email}`}
                          data-e2e="venue.contact.email"
                        >
                          {contact.email}
                        </Link>
                      </DescriptionItem>
                    )}
                  </Stack>
                ))}
              </Stack>
            </Stack>
          </CardContent>
        </Card>
      )}
    </>
  );
}
