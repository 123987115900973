import { Skeleton } from '@material-ui/lab';
import { InfoCard, Inline, Stack } from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';

export function DispatcherPageLoader() {
  return (
    <Box width="440px" role="description">
      <InfoCard>
        <Stack space="small">
          <Skeleton width="160px" />
          <Stack space="xsmall">
            <Inline space="xxsmall">
              <Skeleton width="120px" />
              <Skeleton width="120px" />
            </Inline>
            <Skeleton width="150px" />
            <Skeleton width="130px" />
            <Inline space="xxsmall">
              <Skeleton width="110px" />
              <Skeleton width="110px" />
            </Inline>
          </Stack>
        </Stack>
      </InfoCard>
    </Box>
  );
}
