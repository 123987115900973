import { Typography } from '@material-ui/core';
import { FormikTextField, useFormikEnhanced } from '@superdispatch/forms';
import { Stack, useSnackbarStack } from '@superdispatch/ui';
import { Box, Button } from '@superdispatch/ui-lab';
import { Form, FormikProvider } from 'formik';
import { LoginLayout } from 'shared/auth/core/LoginLayout';
import { LoginLayoutContent } from 'shared/auth/core/LoginLayoutContent';
import { useDriverAccount } from 'shared/auth/data/useDriverAccount';
import { useNavigate } from 'shared/routing/react-router-6';
import styled from 'styled-components';
import { deleteDriverAccount } from '../drivers/driver-account-deletion/data/DriverAccountDeletionAPI';
import { DriverLogoutButton } from './core/DriverLogoutButton';
import { useDriverAccountDetails } from './data/DriverAccountAPI';

const List = styled.ul`
  padding-inline-start: 20px;
  margin: 0;
`;

export function DriverDeleteAccountConfirmationPage() {
  const navigate = useNavigate();
  const { token, removeDriverToken } = useDriverAccount();
  const { data } = useDriverAccountDetails();

  const { addSnackbar } = useSnackbarStack();
  const formik = useFormikEnhanced({
    initialValues: {
      email: '',
    },
    validate: (values) => {
      if (values.email !== data?.email || !values.email) {
        return { email: 'Please type your account email' };
      }
      return {};
    },
    onSubmit: (values) => {
      return deleteDriverAccount({
        email: values.email,
        reason: "I don't use the App anymore",
        token,
      });
    },
    onSubmitSuccess: () => {
      removeDriverToken();
      navigate(`/driver-account/delete/completion/`, { replace: true });
    },
    onSubmitFailure: (error) => {
      addSnackbar(error.message || 'Failed delete your account', {
        variant: 'error',
      });
    },
  });

  return (
    <LoginLayout showDot={false} header={<DriverLogoutButton />}>
      <Box marginTop="medium">
        <FormikProvider value={formik}>
          <Form>
            <LoginLayoutContent>
              <Stack space="large">
                <Stack space="medium">
                  <Typography variant="h2">
                    Are you sure you want to delete your account?
                  </Typography>
                  <Typography>
                    Read the important notes below, before deleting your Super
                    Dispatch account.
                  </Typography>
                  <Typography variant="body1">Important:</Typography>
                  <List>
                    <Stack space="medium">
                      <li>
                        When you delete your account, all related data will be
                        deleted, including created loads any data associated
                        with those loads including photos, signatures, and
                        inspections.
                      </li>
                      <li>
                        We may securely keep some information or event that we
                        need to establish, exercise or defend a legal claim.
                        This information will be retained securely and will be
                        deleted permanently obfuscated once it is no longer
                        required.
                      </li>
                    </Stack>
                  </List>
                  <Typography>
                    To continue, please type the email for the account below.
                  </Typography>

                  <FormikTextField
                    name="email"
                    type="email"
                    label="Type email to confirm"
                    placeholder={data?.email}
                    fullWidth={true}
                  />

                  <Button
                    fullWidth={true}
                    disabled={!formik.dirty}
                    pending={formik.isSubmitting}
                    variant="critical"
                    type="submit"
                  >
                    Yes, I want to delete my account
                  </Button>
                  <Button
                    fullWidth={true}
                    variant="neutral"
                    disabled={formik.isSubmitting}
                    onClick={() => {
                      navigate(`/delete-account`, { replace: true });
                    }}
                  >
                    Cancel
                  </Button>
                </Stack>
              </Stack>
            </LoginLayoutContent>
          </Form>
        </FormikProvider>
      </Box>
    </LoginLayout>
  );
}
