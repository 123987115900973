import { Check, Warning } from '@material-ui/icons';
import { Color, Inline, Stack } from '@superdispatch/ui';
import { Box, Button, TextBox } from '@superdispatch/ui-lab';
import { openExternalURL } from 'shared/helpers/URLHelpers';
import { SuperCarrierBadgeIcon } from 'shared/icons/SuperCarrierBadgeIcon';
import { useCarrierInfo } from 'shared/modules/carrier-profile/CarrierInfo';
import { useExperimentState } from 'shared/modules/experiments/ExperimentsAPI';
import { ExperimentPageLayout } from '../core/ExperimentPageLayout';
import CarrierVerificationFigure from './assets/carrier-verification-figure.svg';
import { useSignupExperiment } from './useSignupExperiment';

function BenefitItem({ value }: { value: string }) {
  return (
    <Inline verticalAlign="center" space="small">
      <Check color="action" />
      <TextBox>{value}</TextBox>
    </Inline>
  );
}

function CarrierVerificationExperimentPage() {
  return (
    <ExperimentPageLayout>
      <Stack space="xlarge" align="center">
        <TextBox variant="heading-1" align="center">
          <Inline horizontalAlign="center" verticalAlign="center">
            Let&apos;s Set Up Your Account
            <SuperCarrierBadgeIcon
              fontSize="inherit"
              htmlColor={Color.Green300}
            />
          </Inline>
        </TextBox>
        <Inline space="xxlarge" horizontalAlign="center">
          <img src={CarrierVerificationFigure} />
          <Box maxWidth="360px">
            <Stack space="small">
              <TextBox variant="heading-4">
                First, become a Verified Carrier to get unlimited access to the
                load board and...
              </TextBox>
              <BenefitItem value="Book loads faster" />
              <BenefitItem value="Save time with saved documents" />
              <BenefitItem value="Get paid faster" />
              <BenefitItem value="Establish trust with Shippers" />
            </Stack>
          </Box>
        </Inline>

        <Button
          onClick={() => {
            openExternalURL(
              '/tms/profile/verified-carrier-application/step-1?utm_medium=account_setup',
              { target: '_self' },
            );
          }}
          size="large"
        >
          Become Verified Carrier
        </Button>
      </Stack>
    </ExperimentPageLayout>
  );
}

function CarrierVerificationExperimentProcessingPage() {
  return (
    <ExperimentPageLayout>
      <Stack space="xlarge" align="center">
        <Stack>
          <TextBox variant="heading-1" align="center">
            <Inline
              horizontalAlign="center"
              verticalAlign="center"
              space="small"
            >
              Verification Processing
            </Inline>
          </TextBox>
          <TextBox align="center" variant="heading-4" color="secondary">
            Don’t worry, it should take less than 5-6 business days.
          </TextBox>
        </Stack>
        <Inline space="xxlarge" horizontalAlign="center">
          <img src={CarrierVerificationFigure} />
          <Box maxWidth="360px">
            <Stack space="small">
              <TextBox variant="heading-4">
                First, become a Verified Carrier to get unlimited access to the
                load board and...
              </TextBox>
              <BenefitItem value="Book loads faster" />
              <BenefitItem value="Save time with saved documents" />
              <BenefitItem value="Get paid faster" />
              <BenefitItem value="Establish trust with Shippers" />
            </Stack>
          </Box>
        </Inline>

        <Button
          onClick={() => {
            openExternalURL(
              '/tms/profile/verified-carrier-application/step-1?utm_medium=account_setup',
              { target: '_self' },
            );
          }}
          size="large"
        >
          Edit Submitted Application
        </Button>
      </Stack>
    </ExperimentPageLayout>
  );
}

function CarrierVerificationExperimentDeclinedPage() {
  return (
    <ExperimentPageLayout>
      <Stack space="xlarge" align="center">
        <Stack>
          <TextBox variant="heading-1" align="center">
            <Inline
              horizontalAlign="center"
              verticalAlign="center"
              space="small"
            >
              Documents Need Review
              <Warning htmlColor={Color.Yellow300} />
            </Inline>
          </TextBox>
          <TextBox align="center" variant="heading-4" color="secondary">
            Some of you documents didn’t pass the check.
            <br /> Please ensure that your documents are corrected and
            resubmitted
          </TextBox>
        </Stack>

        <Button
          onClick={() => {
            openExternalURL('/tms/profile', { target: '_self' });
          }}
          size="large"
        >
          Resubmit Documents
        </Button>
      </Stack>
    </ExperimentPageLayout>
  );
}

function ExperimentVerification({
  status,
}: {
  status?: 'not_submitted' | 'approved' | 'declined' | 'revoked' | 'pending';
}) {
  switch (status) {
    case 'not_submitted':
      return <CarrierVerificationExperimentPage />;
    case 'pending':
      return <CarrierVerificationExperimentProcessingPage />;
    case 'declined':
      return <CarrierVerificationExperimentDeclinedPage />;
    default:
      return null;
  }
}

export function CarrierVerificationExperiment() {
  const { shouldRunExperiment, carrierApplication } = useSignupExperiment();
  const experimentState1 = useExperimentState('Signup1');
  const experimentState2 = useExperimentState('Signup1');

  const { data: carrierInfo } = useCarrierInfo({
    enabled: shouldRunExperiment,
  });

  if (!!experimentState1.isInExperiment && carrierApplication) {
    return (
      <ExperimentVerification
        status={carrierApplication.verified_carrier_application.status}
      />
    );
  }

  if (!!experimentState2.isInExperiment && carrierInfo?.usdot_number) {
    if (experimentState2.isExperimentViewed) {
      return (
        <ExperimentVerification
          status={carrierApplication?.verified_carrier_application.status}
        />
      );
    }

    void experimentState2.markAsViewed().then(() => {
      openExternalURL(
        `https://docs.google.com/forms/d/e/1FAIpQLSeLVAI8rkxNHo4_6v6q5SyROPIdogKMUPNOCcUObYxPmGjYMg/viewform?entry.1645741487=${carrierInfo.usdot_number}`,
        { target: '_self' },
      );
    });
  }

  return null;
}
