import { Link } from '@material-ui/core';
import {
  formatLoadPaymentMethod,
  formatLoadPaymentTerm,
  isValidLoadPaymentTerm,
} from '@superdispatch/sdk';
import { parseURITemplate } from '@superdispatch/uri';
import { chooseUTMSource } from 'shared/helpers/Analytics';
import { SuperPayIcon } from 'shared/icons/SuperPayLabel';

const isSLBM = import.meta.env.VITE_APP_NAME === 'slbm';

interface SuperPayLabelProps {
  source?:
    | 'Load Details'
    | 'Load List'
    | 'Load Offer'
    | 'Suggested Load'
    | 'Load Offer Details'
    | 'Public Load Offer Details'
    | 'Public Load Offer Declined';
  target?: '_blank' | '_self';
}

export function SuperPayLabel({
  source,
  target = '_self',
}: SuperPayLabelProps) {
  return (
    <Link
      rel="noreferrer"
      color="primary"
      href={parseURITemplate(
        `${
          isSLBM ? '/tms-webview/superpay' : '/tms/profile/superpay'
        }{?utm_source,utm_medium,utm_content}`,
        {
          utm_source: chooseUTMSource(),
          utm_medium: source,
          utm_content: 'Superpay label',
        },
      )}
      onClick={(event) => {
        event.stopPropagation();
      }}
      target={target}
    >
      <SuperPayIcon />
    </Link>
  );
}
export function formatLoadPayment(payment: unknown, fallback?: string) {
  return isValidLoadPaymentTerm(payment)
    ? formatLoadPaymentTerm(payment, { fallback })
    : formatLoadPaymentMethod(payment, { fallback });
}
