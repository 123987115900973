import { createSvgIcon } from 'shared/icons/IconUtils';

export const AuctionIcon = createSvgIcon(
  'BusinessTypeAuction',
  <path
    fill="currentColor"
    d="m.533 12.52 6.4-6.4L6 5.173l-.48.474c-.26.26-.68.26-.94 0l-.473-.474a.664.664 0 0 1 0-.94L7.88.46c.26-.26.68-.26.94 0l.473.473c.26.26.26.68 0 .94l-.473.46.947.954c.26-.26.68-.26.94 0 .26.26.26.686 0 .946l.94.94.473-.473c.26-.26.687-.26.947 0l.466.473c.26.26.26.687 0 .947L9.767 9.887a.672.672 0 0 1-.947 0l-.467-.467a.66.66 0 0 1 0-.947L8.82 8l-.94-.94-6.407 6.407c-.26.26-.68.26-.94 0a.672.672 0 0 1 0-.947Zm11.8-.853A1.333 1.333 0 0 1 13.667 13v.667H7V13a1.333 1.333 0 0 1 1.333-1.333h4Z"
  />,
  { viewBox: '0 0 15 15' },
);
