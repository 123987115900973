import { useMemo } from 'react';
import { useAuthState } from 'shared/auth/AuthState';
import { getAuthToken } from 'shared/auth/AuthToken';
import { CTMS_WEBSOCKET_HOST } from 'shared/constants/ServerConstants';
import { useWebSocket, WebSocketDispatch, WebSocketState } from './WebSocket';

export function useWebSocketURL(url: null | string) {
  const authState = useAuthState();

  return useMemo(() => {
    if (url && authState === 'authorized') {
      const token = getAuthToken();

      if (token) {
        const nextURL = new URL(
          url.startsWith('wss') ? url : CTMS_WEBSOCKET_HOST + url,
        );
        nextURL.searchParams.set('token', token);

        return nextURL.toString();
      }
    }

    return null;
  }, [url, authState]);
}

export function useAPISocket(
  url: null | string,
  fn: WebSocketDispatch,
): WebSocketState {
  const webSocketURL = useWebSocketURL(url);

  return useWebSocket(webSocketURL, fn);
}
