import { Drawer, IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { FormikContextTypeEnhanced } from '@superdispatch/forms';
import { DrawerContent, DrawerTitle, useUID } from '@superdispatch/ui';
import { Form, FormikProvider, FormikValues } from 'formik';
import { ReactNode } from 'react';
import { useNavigationBlock } from 'shared/routing/NavigationBlock';

export interface SlowFormikDrawerProps {
  formik: FormikContextTypeEnhanced<FormikValues, unknown>;

  open?: boolean;
  onClose?: () => void;

  title?: ReactNode;
  actions?: ReactNode;
  children?: ReactNode;
}

export function SlowFormikDrawer({
  formik,

  open,
  onClose,

  title,
  actions,
  children,
}: SlowFormikDrawerProps) {
  const uid = useUID();
  const iconID = `${uid}-icon`;
  const labelID = `${uid}-label`;

  const { dirty, status, isSubmitting } = formik;
  const isSubmitted = status.type === 'submitted';

  useNavigationBlock(
    !isSubmitted &&
      (dirty || isSubmitting) &&
      'Changes have not been saved. Leaving will result in unsaved changes being lost.',
  );

  return (
    <FormikProvider value={formik}>
      <Drawer
        open={open}
        anchor="right"
        onClose={() => {
          if (!isSubmitting) {
            onClose?.();
          }
        }}
      >
        {!!title && (
          <DrawerTitle
            title={title}
            titleTypographyProps={{ id: labelID }}
            endAction={
              <IconButton
                edge="end"
                onClick={onClose}
                disabled={isSubmitting}
                aria-labelledby={`${iconID} ${labelID}`}
              >
                <Close id={iconID} aria-label="close" />
              </IconButton>
            }
          />
        )}

        <Form aria-labelledby={labelID}>
          <DrawerContent>{children}</DrawerContent>

          {actions}
        </Form>
      </Drawer>
    </FormikProvider>
  );
}
