import { Card, CardContent, Typography } from '@material-ui/core';
import { Add, Edit, Info } from '@material-ui/icons';
import { CardButton, Column, Columns, Inline, Stack } from '@superdispatch/ui';
import { LinkIconButton } from 'shared/routing/Links';
import { useMatch, useNavigate } from 'shared/routing/react-router-6';
import { HiddenForPrint } from 'shared/ui/Print';
import { EditLoadInstructionsDrawer } from '../core/EditLoadInstructionsDrawer';
import { LoadDTO } from '../data/LoadDTO';

interface Props {
  load: LoadDTO;
}

export function ViewLoadInstructions({ load }: Props) {
  const navigate = useNavigate();
  const instructionsMatch = useMatch('/loads/:loadGUID/instructions');

  return (
    <>
      <EditLoadInstructionsDrawer
        open={!!instructionsMatch}
        loadGUID={load.guid}
        onClose={() => {
          navigate(-1);
        }}
      />

      {!load.instructions ? (
        <HiddenForPrint>
          <CardButton
            startIcon={<Add />}
            onClick={() => {
              navigate(`/loads/${load.guid}/instructions`);
            }}
          >
            Add Driver Instructions
          </CardButton>
        </HiddenForPrint>
      ) : (
        <Card>
          <CardContent>
            <Stack space="small">
              <Columns>
                <Column width="fluid">
                  <Inline verticalAlign="center" noWrap={true}>
                    <Info />
                    <Typography variant="h3">Driver Instructions</Typography>
                  </Inline>
                </Column>
                <Column width="content">
                  <LinkIconButton
                    aria-label="edit instructions"
                    size="small"
                    to={`/loads/${load.guid}/instructions`}
                  >
                    <Edit />
                  </LinkIconButton>
                </Column>
              </Columns>

              <Typography>{load.instructions}</Typography>
            </Stack>
          </CardContent>
        </Card>
      )}
    </>
  );
}
