import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
} from '@material-ui/core';
import { Button } from '@superdispatch/ui-lab';
import { useField } from 'formik';
import { useMemo } from 'react';

interface Props {
  name: string;
  label?: string;
  options: ReadonlyMap<string, string>;
  hasActions?: boolean;
}

export function ReportsCheckboxGroup({
  name,
  label,
  options,
  hasActions,
}: Props) {
  const [{ value }, { error }, { setValue }] = useField<string[]>({ name });
  const keys = useMemo(() => new Set(value), [value]);

  return (
    <FormControl error={!!error} fullWidth={true}>
      <Grid container={true} spacing={1} direction="column">
        <FormLabel>
          <Grid container={true} alignItems="center" spacing={1}>
            <Grid item={true} md={12} lg={true} xs={true}>
              {label}
            </Grid>

            {hasActions && (
              <>
                <Grid item={true}>
                  <Button
                    variant="text"
                    size="small"
                    onClick={() => {
                      void setValue(
                        Array.from(new Set([...value, ...options.keys()])),
                      );
                    }}
                  >
                    Select All
                  </Button>
                </Grid>

                <Grid item={true}>/</Grid>

                <Grid item={true}>
                  <Button
                    variant="text"
                    size="small"
                    onClick={() => {
                      void setValue(value.filter((key) => !options.has(key)));
                    }}
                  >
                    Clear All
                  </Button>
                </Grid>
              </>
            )}
          </Grid>
        </FormLabel>

        <FormGroup>
          {Array.from(options.entries(), ([key, optionLabel]) => (
            <FormControlLabel
              key={key}
              name={name}
              checked={keys.has(key)}
              onChange={(_, checked) => {
                if (checked) {
                  keys.add(key);
                } else {
                  keys.delete(key);
                }

                void setValue(Array.from(keys));
              }}
              control={<Checkbox />}
              label={optionLabel}
            />
          ))}
        </FormGroup>
      </Grid>

      <FormHelperText>{error}</FormHelperText>
    </FormControl>
  );
}
