import { parseURITemplate, URITemplateParams } from '@superdispatch/uri';

export type OpenExternalURLOptions<T extends URITemplateParams> = T & {
  target?: '_self' | '_blank';
};

export function openExternalURL<T extends URITemplateParams>(
  template: string,
  params?: OpenExternalURLOptions<T>,
): void {
  const url = !params ? template : parseURITemplate(template, params);
  const target = params?.target ?? '_self';

  if (target === '_self' && !('Cypress' in window)) {
    window.location.assign(url);
  } else {
    window.open(url, target)?.focus();
  }
}
