import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

const SPLASH_SCREEN_ID = 'splash-screen';

interface AppLayoutManager {
  registerSplashScreen: () => () => void;
}

const Context = createContext<AppLayoutManager>({
  registerSplashScreen: () => () => void 0,
});

export function useSplashScreen(isVisible = true) {
  const { registerSplashScreen } = useContext(Context);

  useEffect(
    () => (!isVisible ? undefined : registerSplashScreen()),
    [isVisible, registerSplashScreen],
  );
}

function hideSplashScreen() {
  document.getElementById(SPLASH_SCREEN_ID)?.setAttribute('aria-busy', 'false');
}

function showSplashScreen() {
  document.getElementById(SPLASH_SCREEN_ID)?.setAttribute('aria-busy', 'true');
}

export interface AppLayoutManagerProvider {
  children?: ReactNode;
}

export function AppLayoutManagerProvider({
  children,
}: AppLayoutManagerProvider) {
  const [splashScreens, setSplashScreens] = useState(() => new Set<number>());
  const isSplashScreenVisible = splashScreens.size > 0;

  // Remove splash-screen on component unmount.
  useEffect(() => hideSplashScreen, []);

  // We want to show instantly, but hide with delay.
  useEffect(() => {
    if (isSplashScreenVisible) {
      showSplashScreen();
      return;
    }

    const timeout = setTimeout(hideSplashScreen, 400);

    return () => {
      clearTimeout(timeout);
    };
  }, [isSplashScreenVisible]);

  const registerSplashScreen = useCallback<
    AppLayoutManager['registerSplashScreen']
  >(() => {
    const uid = Math.random();

    setSplashScreens((prev) => new Set(prev).add(uid));

    return () => {
      setSplashScreens((prev) => {
        const next = new Set(prev);

        next.delete(uid);

        return next;
      });
    };
  }, []);

  const ctx = useMemo<AppLayoutManager>(
    () => ({ registerSplashScreen }),
    [registerSplashScreen],
  );

  return <Context.Provider value={ctx}>{children}</Context.Provider>;
}
