// organize-imports-ignore

// Required by the `class-transformer`
import 'reflect-metadata';

// Fix for the https://sentry.io/organizations/superdispatch/issues/2298233907
if (typeof Object.fromEntries == 'undefined') {
  Object.defineProperty(Object, 'fromEntries', {
    value: <T>(entries: Iterable<readonly [string, T]>): Record<string, T> => {
      const object: Record<string, T> = {};
      for (const [key, value] of entries) object[key] = value;
      return object;
    },
  });
}

// Firefox 78 missing URLSearchParams https://sentry.io/organizations/superdispatch/issues/2485464290/?project=1297423&referrer=slack
if (typeof URLSearchParams === 'undefined') {
  const script = document.createElement('script');

  script.async = false;
  script.defer = false;
  script.src = 'https://unpkg.com/url-search-params-polyfill';

  document.body.insertBefore(script, document.body.firstChild);
}

// Needed by react-image-lightbox
window.global = window;
