import { ButtonBase, Typography } from '@material-ui/core';
import { Color, Column, Columns } from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import {
  PostingSearchCriteriaDTO,
  PostingSearchCriteriaVenueDTO,
} from 'shared/modules/loadboard/PostingSearchCriteriaDTO';
import styled from 'styled-components';
import { PostingRecentSearches } from './PostingRecentSearches';

export const USA_REGIONS = [
  {
    region: 'Northeast',
    values: ['CT', 'DE', 'MA', 'ME', 'NH', 'NJ', 'NY', 'PA', 'RI', 'VT'],
  },

  {
    region: 'Southeast',
    values: ['AL', 'DC', 'FL', 'GA', 'KY', 'MD', 'NC', 'SC', 'TN', 'VA', 'WV'],
  },
  {
    region: 'Midwest',
    values: [
      'IA',
      'IL',
      'IN',
      'KS',
      'MI',
      'MN',
      'MO',
      'ND',
      'NE',
      'OH',
      'SD',
      'WI',
    ],
  },
  { region: 'South Central', values: ['AR', 'LA', 'MS', 'OK', 'TX'] },
  { region: 'Northwest', values: ['ID', 'MT', 'OR', 'WA', 'WY'] },
  { region: 'Southwest', values: ['AZ', 'CA', 'CO', 'NM', 'NV', 'UT'] },
  { region: 'Pacific', values: ['AK', 'HI'] },
];

const Container = styled.div`
  margin-left: -16px;
  margin-right: -16px;
`;

const Button = styled(ButtonBase)`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  padding: 0 16px;
  color: ${Color.Dark500};

  :hover {
    background-color: ${Color.Silver30};
  }
`;

const StateList = styled(Typography)`
  word-break: break-word;
`;

interface RegionListProps {
  value?: PostingSearchCriteriaVenueDTO[];
  searchCriteria: PostingSearchCriteriaDTO;
  onRegionClick: (region: PostingSearchCriteriaVenueDTO[]) => void;
  onRecentClick: (search: PostingSearchCriteriaDTO) => void;
}

export function PostingSearchSuggestions({
  value,
  searchCriteria,
  onRegionClick,
  onRecentClick,
}: RegionListProps) {
  const handleClick = (values: string[]) => {
    const states = values.map((state) => ({
      state: state.toLowerCase(),
    }));

    if (value) {
      onRegionClick([...value, ...states]);
      return;
    }
    onRegionClick(states);
  };

  return (
    <Container
      // Prevents the closing of the dropdown when clicking on the regions
      onMouseDown={(event) => {
        event.preventDefault();
      }}
    >
      <PostingRecentSearches
        searchCriteria={searchCriteria}
        onClick={onRecentClick}
      />

      <Box paddingBottom="xsmall" paddingLeft="small" paddingRight="small">
        <Typography variant="h5" color="textSecondary">
          Regions
        </Typography>
      </Box>
      {USA_REGIONS.map(({ region, values }) => (
        <Button
          key={region}
          onClick={(event) => {
            event.preventDefault();
            handleClick(values);
          }}
        >
          <Box paddingTop="xsmall" paddingBottom="xsmall">
            <Columns align="top" space="xxsmall">
              <Column width="content">
                <Box width="108px">
                  <Typography variant="body1" align="left">
                    {region}
                  </Typography>
                </Box>
              </Column>
              <Column>
                <StateList color="textSecondary" align="left">
                  {values.join(', ')}
                </StateList>
              </Column>
            </Columns>
          </Box>
        </Button>
      ))}
    </Container>
  );
}
