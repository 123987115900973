import { Link, Typography } from '@material-ui/core';
import { Message, Phone } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import { FormikTextField } from '@superdispatch/forms';
import { Column, Columns, Stack, useResponsiveValue } from '@superdispatch/ui';
import { Alert, Button } from '@superdispatch/ui-lab';
import { useFormikContext } from 'formik';
import { DateTime } from 'luxon';
import { ReactNode } from 'react';
import { Countdown } from 'shared/ui/Countdown';
import styled from 'styled-components';

interface SmsActionProps {
  retryDate: DateTime | undefined;
  hasError: boolean;
  onClick: () => void;
}

export function SmsAction({ retryDate, hasError, onClick }: SmsActionProps) {
  const isMobile = useResponsiveValue(true, false);
  return (
    <Countdown targetDate={retryDate}>
      {(_, isElapsed) => (
        <Button
          onClick={onClick}
          variant="neutral"
          startIcon={<Message />}
          disabled={!isElapsed && !hasError}
          fullWidth={isMobile}
        >
          Receive text instead
        </Button>
      )}
    </Countdown>
  );
}

interface CallActionProps {
  retryDate: DateTime | undefined;
  hasError: boolean;
  onClick: () => void;
}

export function CallAction({ retryDate, hasError, onClick }: CallActionProps) {
  const isMobile = useResponsiveValue(true, false);
  return (
    <Countdown targetDate={retryDate}>
      {(_, isElapsed) => (
        <Button
          onClick={onClick}
          variant="neutral"
          startIcon={<Phone />}
          disabled={!isElapsed && !hasError}
          fullWidth={isMobile}
        >
          Receive call instead
        </Button>
      )}
    </Countdown>
  );
}

const FixedWidthFormikTextField = styled(FormikTextField)`
  width: 260px;
`;

interface CodeValidationContainerProps {
  details: ReactNode;
  retryDate: DateTime | undefined;
  retryText: string;
  onRetry: () => void;
  alternativeAction: ReactNode;
  isLoading: boolean;
  errorMessage: string;
}

export function CodeValidationContainer({
  details,
  retryDate,
  retryText,
  onRetry,
  alternativeAction,
  isLoading,
  errorMessage,
}: CodeValidationContainerProps) {
  const formik = useFormikContext();
  const isMobile = useResponsiveValue(true, false);

  return (
    <Stack space="xlarge">
      <Stack space="xxsmall">{details}</Stack>
      {isLoading ? (
        <Stack space="none">
          <Skeleton height="24px" />
          <Skeleton height="48px" />
          <Skeleton height="24px" />
        </Stack>
      ) : errorMessage ? (
        <Alert severity="critical">
          <Stack>
            <Typography color="textPrimary">{errorMessage}</Typography>
            <Button onClick={onRetry} size="small" variant="neutral">
              {retryText}
            </Button>
          </Stack>
        </Alert>
      ) : (
        <Stack>
          <FixedWidthFormikTextField
            disabled={formik.isSubmitting}
            label="Verification code"
            name="code"
          />
          <Countdown targetDate={retryDate}>
            {(timeLeft, isElapsed) =>
              timeLeft ? (
                !isElapsed ? (
                  <Typography>
                    {retryText} in {timeLeft.toFormat('mm:ss')} (3 attempts
                    maximum)
                  </Typography>
                ) : (
                  <Link
                    color="primary"
                    href="#"
                    onClick={(event) => {
                      event.preventDefault();
                      onRetry();
                    }}
                  >
                    {retryText}
                  </Link>
                )
              ) : (
                <Typography>&nbsp;</Typography>
              )
            }
          </Countdown>
        </Stack>
      )}

      <Columns collapseBelow="tablet" space="small">
        {alternativeAction && (
          <Column width="content">{alternativeAction}</Column>
        )}
        <Column width="content">
          <Button
            disabled={!formik.dirty || formik.isSubmitting}
            type="submit"
            fullWidth={isMobile}
          >
            Submit
          </Button>
        </Column>
      </Columns>
    </Stack>
  );
}
