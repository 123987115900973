import { Tooltip } from '@material-ui/core';
import { useMemo } from 'react';
import { PRICING_INSIGHTS_URL } from 'shared/constants/Constants';
import { chooseUTMSource, trackEvent } from 'shared/helpers/Analytics';
import { WebViewLink } from 'shared/routing/WebViewLinks';
import { stringifySearchQuery } from 'shared/utils/URLUtils';
import { PricingInsightsDTO } from './PricingInsightsDTO';

export interface Props {
  data: PricingInsightsDTO;
  source: string;
}

export function PricingInsightsLink({ data, source }: Props) {
  const url = useMemo(() => {
    const search = stringifySearchQuery({
      ...data,
      utm_medium: source,
      utm_source: chooseUTMSource(),
    });

    return `${PRICING_INSIGHTS_URL}?${search}`;
  }, [data, source]);

  return (
    <Tooltip
      enterDelay={500}
      title="Utilize data-driven insights for more accurate pricing decision-making."
    >
      <WebViewLink
        rel="noreferrer noopener"
        target="_blank"
        href={url}
        onClick={() => {
          trackEvent('Carrier Clicked Pricing Insights Link', {
            utm_medium: source,
            utm_source: chooseUTMSource(),
          });
        }}
      >
        See Pricing Insights
      </WebViewLink>
    </Tooltip>
  );
}
