import { useCallback } from 'react';
import { useQueryClient } from 'react-query';
import { LocalStore, useLocalStore } from 'shared/helpers/Store';
import { NotificationVariant } from '../data/NotificationDTO';

export const HIDE_VERIFICATION_NOTIFICATIONS =
  'hide_verification_notifications';

/**
 * @returns Function to check if notification type was hidden: "Don't show again" button was pressed on notification
 */
export function useVerificationNotificationsHidden() {
  const verificationNotificationHidden = useLocalStore(
    HIDE_VERIFICATION_NOTIFICATIONS,
    undefined,
  );

  return useCallback(
    (notification_type: NotificationVariant['notification_type']) => {
      return verificationNotificationHidden === notification_type;
    },
    [verificationNotificationHidden],
  );
}

/**
 * @returns Function to reset visibility of hidden verification notifications
 */
export function useVerificationNotificationsReset() {
  const queryClient = useQueryClient();
  return useCallback(() => {
    LocalStore.delete(HIDE_VERIFICATION_NOTIFICATIONS);
    //refresh notifications after resubmitting verification request
    void queryClient.invalidateQueries('notifications');
  }, [queryClient]);
}
