import { startCase } from 'lodash-es';
import {
  yupArray,
  yupDateString,
  yupEnum,
  yupNumber,
  yupObject,
  yupString,
} from 'shared/utils/YupUtils';
import { ArraySchema, InferType } from 'yup';

export const POSITIVE_SHIPPER_RATING_TAGS = [
  'ON_TIME_PAYMENT',
  'GOOD_COMMUNICATION',
  'ACCURATE_INFORMATION',
] as const;
export const NEGATIVE_SHIPPER_RATING_TAGS = [
  'LATE_PAYMENT',
  'NON_PAYMENT',
  'PARTIAL_PAYMENT',
  'INACCURATE_INFORMATION',
  'VEHICLE_NOT_READY_MISSING',
] as const;
export const SHIPPER_RATING_TAGS = [
  ...POSITIVE_SHIPPER_RATING_TAGS,
  ...NEGATIVE_SHIPPER_RATING_TAGS,
];
export type ShipperRatingTags = (typeof SHIPPER_RATING_TAGS)[number];

export function getShipperRatingTagDisplayName(tag: ShipperRatingTags) {
  switch (tag) {
    case 'ON_TIME_PAYMENT':
      return 'On-Time Payment';

    case 'VEHICLE_NOT_READY_MISSING':
      return 'Vehicle Not Ready / Missing';

    case 'NON_PAYMENT':
      return 'Non-Payment';

    default:
      return startCase(tag.toLowerCase());
  }
}

const RATING_TYPES = ['POSITIVE', 'NEGATIVE'] as const;
export type RatingType = (typeof RATING_TYPES)[number];

export type ShipperRatingDTO = InferType<typeof shipperRatingSchema>;
export const shipperRatingSchema = yupObject({
  guid: yupString(),
  carrier_guid: yupString(),
  carrier_name: yupString(),
  rating: yupString().oneOf(RATING_TYPES),
  comment: yupString(),
  tags: yupArray(yupString().oneOf(SHIPPER_RATING_TAGS)).default([]),
  created_at: yupDateString('DateTimeISO'),
  changed_at: yupDateString('DateTimeISO'),
  status: yupEnum(['PUBLISHED', 'PENDING']),
  response_date: yupDateString('DateTimeISO'),
  response: yupString(),
});

export type ShipperNewRatingDTO = InferType<typeof shipperNewRatingSchema>;
export const shipperNewRatingSchema = yupObject({
  rating: yupString()
    .oneOf([...RATING_TYPES, ''])
    .default('')
    .required('Rating is required'),
  comment: yupString(),
  tags: yupArray(yupString().oneOf(SHIPPER_RATING_TAGS))
    .default([])
    .when(
      'rating',
      (rating: RatingType | '', tagsSchema: ArraySchema<ShipperRatingTags>) =>
        rating === 'NEGATIVE'
          ? tagsSchema.min(1, 'Select at least one reason')
          : tagsSchema,
    ),
});

export type ShipperRatingDetailsDTO = InferType<
  typeof shipperRatingDetailsSchema
>;
export const shipperRatingDetailsSchema = yupObject({
  rating: yupObject({
    total_count: yupNumber().nullable(false).default(0),
    percentage: yupNumber().nullable(false).default(0),
  }),
});

export type ShipperRatingsSummaryDTO = InferType<
  typeof shipperRatingsSummarySchema
>;
export const shipperRatingsSummarySchema = yupObject({
  summary: yupObject({
    ON_TIME_PAYMENT: yupNumber().default(0),
    GOOD_COMMUNICATION: yupNumber().default(0),
    ACCURATE_INFORMATION: yupNumber().default(0),
  }).nullable(),
});
