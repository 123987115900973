import { CircularProgress } from '@material-ui/core';
import { lazy, Suspense, useMemo } from 'react';
import { parsePhotoSlide } from 'shared/utils/MediaUtils';
import { OfferVehicleDTO } from '../offers/data/OffersDTO';
const PhotoGallery = lazy(() => import('shared/ui/PhotoGallery'));

interface Props {
  vehicles: OfferVehicleDTO[];
  currentSlideIndex: number;
  setCurrentSlideIndex: (index: number) => void;
}

export function OffersVehicleGallery({
  vehicles,
  currentSlideIndex,
  setCurrentSlideIndex,
}: Props) {
  const photos = useMemo(
    () =>
      vehicles
        .map((vehicle) => vehicle.sample_photo)
        .filter(
          (vehicle): vehicle is NonNullable<OfferVehicleDTO['sample_photo']> =>
            !!vehicle,
        )
        .map((vehicle) => ({
          id: vehicle.guid,
          url: vehicle.photo_url,
          thumbnail_url: vehicle.thumbnail_url || undefined,
        }))
        .map(parsePhotoSlide),
    [vehicles],
  );

  return (
    <Suspense fallback={<CircularProgress />}>
      <PhotoGallery
        setCurrentSlideIndex={setCurrentSlideIndex}
        currentSlideIndex={currentSlideIndex}
        slides={photos}
      />
    </Suspense>
  );
}
