import { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { hideIntercomLauncher } from 'shared/helpers/Intercom';
import { PublicOfferPage } from './pages/PublicOfferPage';
import { PublicOfferPageContextProvider } from './PublicOfferPageContext';

export function PublicOfferContainerPage() {
  useEffect(() => {
    hideIntercomLauncher();
  }, []);

  return (
    <Switch>
      <Route path="/public-offer/:guid">
        <PublicOfferPageContextProvider>
          <PublicOfferPage />
        </PublicOfferPageContextProvider>
      </Route>
    </Switch>
  );
}
