import {
  CircularProgress,
  InputAdornment,
  StandardTextFieldProps,
  TextField,
} from '@material-ui/core';
import { useUID } from '@superdispatch/ui';
import {
  DecodedVehicle,
  DuplicatedLoad,
  isPossibleVIN,
  normalizeVIN,
  useDecodeVINs,
} from '../data/VINsAPI';

export interface VINDecoderProps
  extends Pick<
    StandardTextFieldProps,
    | 'autoFocus'
    | 'disabled'
    | 'error'
    | 'fullWidth'
    | 'helperText'
    | 'inputProps'
    | 'inputRef'
    | 'label'
    | 'name'
  > {
  value?: null | string;
  onChange?: (value: string) => void;
  onBlur?: () => void;
  onDecodeSuccess?: (
    vehicle: DecodedVehicle,
    duplicatedLoads: DuplicatedLoad[],
  ) => void;
  onDecodeError?: (error: Error) => void;
}

export function VINDecoder({
  value: valueProp,

  onBlur,
  onChange,
  onDecodeError,
  onDecodeSuccess,
  ...props
}: VINDecoderProps) {
  const uid = useUID();
  const value = normalizeVIN(valueProp);
  const { mutate, isLoading } = useDecodeVINs({
    onSuccess({ data }, vins) {
      const [vin] = vins;
      const [vehicle] = data.vehicles;
      if (vin && vehicle) {
        const duplicatedLoads = data.duplicate_loads[vin] || [];
        onDecodeSuccess?.(vehicle, duplicatedLoads);
      }
    },
    onError(error) {
      onDecodeError?.(error);
    },
  });

  return (
    <TextField
      {...props}
      id={uid}
      value={value}
      onBlur={onBlur}
      onChange={(event) => {
        const nextValue = normalizeVIN(event.target.value);

        onChange?.(nextValue);

        if (isPossibleVIN(nextValue)) {
          mutate([nextValue]);
        }
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <CircularProgress
              value={0}
              size="12px"
              color="inherit"
              variant={isLoading ? 'indeterminate' : 'determinate'}
            />
          </InputAdornment>
        ),
      }}
    />
  );
}
