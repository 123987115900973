import { Tab, Tabs, Typography } from '@material-ui/core';
import { Help } from '@material-ui/icons';
import {
  Color,
  Column,
  Columns,
  InfoCard,
  Inline,
  Stack,
  Tag,
} from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import { useSearchParam } from 'shared/helpers/SearchParamsHelpers';
import { CarrierRatingsToGive } from 'shared/modules/ratings/CarrierRatingsToGive';
import { CarrierReceivedRatings } from 'shared/modules/ratings/CarrierReceivedRatings';
import { useUnratedShippersCount } from 'shared/modules/ratings/data/CarrierRatingsAPI';
import { CarrierRatingsSummaryDTO } from 'shared/modules/ratings/data/CarrierRatingsDTO';
import { WebViewLink } from 'shared/routing/WebViewLinks';
import { useFlag } from 'shared/settings/FeatureToggles';
import styled from 'styled-components';

const UnderlinedTabs = styled(Tabs)`
  border-bottom: 1px solid ${Color.Silver400};
`;

const Badge = styled.div`
  height: 8px;
  width: 8px;
  border-radius: 4px;
  background-color: ${Color.Blue300};
`;

interface CarrierRatingsCardProps {
  carrierRatingsSummary?: CarrierRatingsSummaryDTO;
}

export function CarrierRatingsCard({
  carrierRatingsSummary,
}: CarrierRatingsCardProps) {
  const isRatingsToGiveEnabled = useFlag('ratings_to_give');
  const [activeTab, setActiveTab] = useSearchParam('tab', 'ratings_received');
  const { data } = useUnratedShippersCount();
  return (
    <InfoCard size="large">
      <Columns
        space="xsmall"
        align={['top', 'center', 'center']}
        collapseBelow="tablet"
      >
        <Column>
          <Typography variant="h3">Ratings</Typography>
        </Column>

        <Column width="content">
          <Inline space="xxsmall" verticalAlign="center">
            <Help fontSize="small" color="action" />

            <WebViewLink
              href="https://support.superdispatch.com/en/articles/5716958-ratings-feature-on-super-dispatch"
              target="_blank"
              rel="noreferrer"
            >
              How ratings work?
            </WebViewLink>
          </Inline>
        </Column>
      </Columns>

      <Stack space="small">
        {isRatingsToGiveEnabled && (
          <Box
            marginLeft={['-small', '-medium']}
            marginRight={['-small', '-medium']}
          >
            <UnderlinedTabs
              value={activeTab}
              onChange={(_, value) => {
                setActiveTab(value, { replace: true });
              }}
            >
              <Tab
                value="ratings_received"
                label={
                  <Inline>
                    <Typography>Ratings received</Typography>

                    {!!carrierRatingsSummary?.rating.count && (
                      <Tag
                        variant="subtle"
                        color={
                          activeTab === 'ratings_received' ? 'blue' : 'grey'
                        }
                      >
                        {carrierRatingsSummary.rating.count}
                      </Tag>
                    )}
                  </Inline>
                }
              />
              <Tab
                value="ratings_to_give"
                label={
                  <Inline verticalAlign="center">
                    <Typography>Ratings to Give</Typography>
                    {!!data?.unrated_count && <Badge />}
                  </Inline>
                }
              />
            </UnderlinedTabs>
          </Box>
        )}

        {!isRatingsToGiveEnabled || activeTab === 'ratings_received' ? (
          <CarrierReceivedRatings
            carrierRatingsSummary={carrierRatingsSummary}
          />
        ) : (
          <CarrierRatingsToGive />
        )}
      </Stack>
    </InfoCard>
  );
}
