import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import { Button, ButtonProps } from '@superdispatch/ui-lab';
import { ReactNode } from 'react';

export interface InformationDialogProps
  extends Omit<DialogProps, 'title' | 'onClose'> {
  title?: ReactNode;
  onClose?: () => void;
  children?: ReactNode;
  cancelButtonProps?: Partial<ButtonProps>;
}

export function InformationDialog({
  onClose,
  title,
  children,
  cancelButtonProps,
  maxWidth = 'xs',
  fullWidth = true,
  ...props
}: InformationDialogProps) {
  return (
    <Dialog
      {...props}
      onClose={onClose}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
    >
      <>
        {title && (
          <DialogTitle disableTypography={true}>
            <Typography variant="h3">{title}</Typography>
          </DialogTitle>
        )}

        {children && <DialogContent>{children}</DialogContent>}

        <DialogActions>
          <Button onClick={onClose} {...cancelButtonProps}>
            {cancelButtonProps?.children || 'Cancel'}
          </Button>
        </DialogActions>
      </>
    </Dialog>
  );
}
