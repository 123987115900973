export const GEOCODING_KEYS = [
  'address',
  'country',
  'country_short',
  'house_number',
  'locality',
  'place',
  'place_name',
  'postcode',
  'region',
  'region_short',
] as const;
export type GeocodingKey = (typeof GEOCODING_KEYS)[number];

export type Geocoding = {
  longitude?: number;
  latitude?: number;
} & {
  [TKey in GeocodingKey]?: string;
};

export function isGeocoding(value: unknown): value is Geocoding {
  if (typeof value == 'object' && value != null) {
    for (const geocodingKey of GEOCODING_KEYS) {
      if (geocodingKey in value) return true;
    }
  }

  return false;
}
