import { createSvgIcon } from 'shared/icons/IconUtils';

export const PrivateIcon = createSvgIcon(
  'PostingFilterDeliver',
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M3.058 14.275c.26.261.575.392.941.392h8c.367 0 .68-.13.942-.392.261-.261.392-.575.392-.942V6.667c0-.367-.13-.68-.392-.942a1.284 1.284 0 0 0-.942-.392h-.666V4c0-.922-.325-1.708-.975-2.358A3.213 3.213 0 0 0 7.999.667c-.922 0-1.708.325-2.358.975A3.213 3.213 0 0 0 4.666 4v1.333h-.667c-.366 0-.68.13-.941.392a1.284 1.284 0 0 0-.392.942v6.666c0 .367.13.68.392.942Zm6.941-8.942H6V4c0-.556.195-1.028.584-1.417A1.929 1.929 0 0 1 7.999 2c.556 0 1.028.194 1.417.583.389.39.583.861.583 1.417v1.333ZM4.933 9.867l2.366 2.366 3.767-3.766-.967-.967-2.8 2.8-1.4-1.4-.966.967Z"
    fill="currentColor"
  />,
  { viewBox: '0 0 16 16' },
);
