import { trackEvent } from 'shared/helpers/Analytics';

interface PerformanceAnalyticsEvent {
  name: 'Carrier Clicked on Performance Indicator';
  indicator_type: 'ETA provided' | 'Late cancellation' | 'Photos taken on-site';
}

export function trackPerformanceAnalytics({
  name,
  ...props
}: PerformanceAnalyticsEvent) {
  trackEvent(name, { ...props });
}
