import {
  Dialog,
  DialogActions as MuiDialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import { Box, Button } from '@superdispatch/ui-lab';
import styled from 'styled-components';
import { DriverDTO } from '../../drivers/data/DriverDTO';

const DialogActions = styled(MuiDialogActions)`
  padding-bottom: 16px;
`;

interface PendingActivationDriverDialogProps {
  driver: DriverDTO | null | undefined;
  onClose: () => void;
}

export function PendingActivationDriverDialog({
  driver,
  onClose,
}: PendingActivationDriverDialogProps) {
  return (
    <Dialog open={!!driver}>
      <DialogTitle>{driver?.name} is Pending Activation</DialogTitle>
      <DialogContent>
        <Typography>
          The load has been assigned but the driver is pending activation. The
          driver was sent a text message to <b>{driver?.phone}</b> and email to{' '}
          <b>{driver?.email}</b> with instructions on how to download the Super
          Dispatch driver app and activate their account.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Box paddingTop="xsmall">
          <Button variant="primary" onClick={onClose}>
            Got it
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}
