import { Stack } from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import { useEffect } from 'react';
import { useQuery } from 'shared/helpers/RoutingHelpers';
import { SuperPayRequestShipperBanner } from 'shared/modules/superpay/SuperPayRequestShipperBanner';
import { useNavigate } from 'shared/routing/react-router-6';
import { useCarrierSettings } from 'shared/settings/CarrierSettingsAPI';
import { useFlag } from 'shared/settings/FeatureToggles';
import { trackSuperPayEvent } from '../data/SuperPayAnalytics';
import { useSuperPaySettings } from '../data/SuperPayAPI';
import { useMoovAccountInfo } from '../data/useMoovAccountInfo';
import { usePaymentLog } from '../helpers/PaymentLogger';
import { ProfilePageLayout } from '../ProfilePageLayout';
import { MicroDepositInitiatedDialog } from './MicroDepositInitiatedDialog';
import { SuperPayBankAccountDetails } from './SuperPayBankAccountDetails';
import { SuperPayPromoTerms } from './SuperPayPromoTerms';
import { SUPERPAY_PROFILE_ROUTES } from './SuperPayRoute';
import { useVerificationAvailable } from './useVerificationAvailable';
import { VerifyBankAccountDrawer } from './VerifyBankAccountDrawer';

interface AccountDetailsPageProps {
  path: string;
}

export function AccountDetailsPage({ path }: AccountDetailsPageProps) {
  const [query, setQuery] = useQuery();
  const navigate = useNavigate();
  const isCarrierInvitesToSuperPayEnabled = useFlag(
    'payments_carrier_invites_to_superpay',
  );
  const { data: settings } = useCarrierSettings();
  const hasAccountingFeature = settings?.user.has_accounting_feature;
  const { data: superPaySettings, refetch: fetchSuperPaySettings } =
    useSuperPaySettings();
  const moovAccountInfo = useMoovAccountInfo();
  const moovBankAccount = moovAccountInfo.getBankAccount();
  const { logPaymentInfo } = usePaymentLog();

  useVerificationAvailable(SUPERPAY_PROFILE_ROUTES.accountDetails);

  const onDrawerClose = () => {
    navigate(
      `${SUPERPAY_PROFILE_ROUTES.accountDetails}?utm_medium=Verify Bank Account Drawer`,
    );
  };

  const onDialogClose = () => {
    setQuery({ open_microdeposit_dialog: undefined });
  };

  useEffect(() => {
    trackSuperPayEvent({ name: 'Carrier Opened SuperPay Settings Page' });
    logPaymentInfo(
      'Opened Bank Account Details Page',
      'BankAccountDetailsPage',
      {
        status: moovBankAccount?.status,
      },
    );
  }, [logPaymentInfo, moovBankAccount?.status]);

  return (
    <>
      <MicroDepositInitiatedDialog
        open={!!query.open_microdeposit_dialog}
        onClose={onDialogClose}
      />
      <ProfilePageLayout
        title="SuperPay"
        isLoading={!superPaySettings}
        headerEndActions={false}
        size="medium"
      >
        <Stack space="large">
          <Box margin="auto" marginTop="medium" maxWidth="496px">
            {/* The 'path' prop was introduced as a replacement for 'useRouteMatch' in order to facilitate the migration to React Router v6. */}
            <SuperPayBankAccountDetails
              path={path}
              bankAccount={superPaySettings?.bank_accounts?.[0]}
              hasAccountFeature={hasAccountingFeature}
            />
          </Box>
          {settings?.superpay.is_invite_available &&
            isCarrierInvitesToSuperPayEnabled && (
              <Box margin="auto" maxWidth="496px">
                <SuperPayRequestShipperBanner />
              </Box>
            )}
          <Box width="100%">
            <SuperPayPromoTerms />
          </Box>
        </Stack>
        <VerifyBankAccountDrawer
          bankAccount={superPaySettings?.bank_accounts?.[0]}
          onClose={onDrawerClose}
          onComplete={() => {
            onDrawerClose();
            void fetchSuperPaySettings();
          }}
        />
      </ProfilePageLayout>
    </>
  );
}
