import { useDeepEqualMemo } from '@superdispatch/hooks';
import { toString } from 'lodash-es';
import { SetStateAction, useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'shared/routing/react-router-6';
import { ObjectSchema } from 'yup';

export type SearchParamsShape = Record<
  string,
  null | undefined | string | number | boolean
>;

function castURLSearchParams<T extends SearchParamsShape>(
  schema: ObjectSchema<T>,
  params: URLSearchParams,
): T {
  return schema.cast(Object.fromEntries(params));
}

function castSearch<T extends SearchParamsShape>(
  schema: ObjectSchema<T>,
  search: string,
) {
  return castURLSearchParams(schema, new URLSearchParams(search));
}

export interface SearchParamsUpdateOptions {
  replace?: boolean;
}

export type SetSearchParams<T> = (
  input: SetStateAction<T>,
  options?: SearchParamsUpdateOptions,
) => void;

export type MergeSearchParams<T> = (
  input: Partial<T> | ((prev: T) => Partial<T>),
  options?: SearchParamsUpdateOptions,
) => void;

export type ResetSearchParams<T> = (
  input?: Partial<T> | ((prev: T) => Partial<T>),
  options?: SearchParamsUpdateOptions,
) => void;

export interface SearchParamsAPI<T extends SearchParamsShape> {
  params: T;
  /**
   * Updates `location.search` with values from `input`.
   */
  setParams: SetSearchParams<T>;

  /**
   * Merges values from `location.search` and partial `input`.
   */
  mergeParams: MergeSearchParams<T>;

  /**
   * Updates `location.search` with values provided in partial `input` and
   * sets default values for not provided properties.
   */
  resetParams: ResetSearchParams<T>;
}

export function useSearchParams<T extends SearchParamsShape>(
  schema: ObjectSchema<T>,
): SearchParamsAPI<T> {
  const navigate = useNavigate();
  const { search } = useLocation();
  const params = useMemo(() => castSearch(schema, search), [search, schema]);

  const setParams = useCallback<SearchParamsAPI<T>['setParams']>(
    (input, { replace = false } = {}) => {
      const searchParams = new URLSearchParams(search);

      if (typeof input === 'function') {
        input = input(castURLSearchParams(schema, searchParams));
      }

      for (const [key, value] of Object.entries(input)) {
        if (
          value == null ||
          (Array.isArray(value) && value.length === 0) ||
          (typeof value == 'string' && value.trim().length === 0)
        ) {
          searchParams.delete(key);
        } else {
          searchParams.set(key, toString(value));
        }
      }

      // Some browsers do not support this method yet.
      if (typeof searchParams.sort == 'function') searchParams.sort();
      const nextSearch = searchParams.toString();

      navigate({ search: nextSearch }, { replace });
    },
    [schema, navigate, search],
  );

  const mergeParams = useCallback<SearchParamsAPI<T>['mergeParams']>(
    (input, options) => {
      setParams((prev) => {
        if (typeof input == 'function') input = input(prev);
        return { ...prev, ...input };
      }, options);
    },
    [setParams],
  );

  const resetParams = useCallback<SearchParamsAPI<T>['resetParams']>(
    (input = {}) => {
      setParams((prev) => {
        if (typeof input == 'function') input = input(prev);
        return schema.cast(input);
      });
    },
    [schema, setParams],
  );

  return useDeepEqualMemo(
    () => ({ params, setParams, mergeParams, resetParams }),
    [params, setParams, mergeParams, resetParams],
  );
}

export type SetSearchParam = (
  input: SetStateAction<string>,
  options?: SearchParamsUpdateOptions,
) => void;

export function useSearchParam(
  name: string,
  defaultValue = '',
): [string, SetSearchParam] {
  const navigate = useNavigate();
  const location = useLocation();
  const param = useMemo(
    () => new URLSearchParams(location.search).get(name) ?? defaultValue,
    [name, defaultValue, location.search],
  );
  const setParam = useCallback<SetSearchParam>(
    (input, { replace = false } = {}) => {
      const searchParams = new URLSearchParams(location.search);
      if (typeof input == 'function') {
        input = input(searchParams.get(name) ?? defaultValue);
      }

      if (input) {
        searchParams.set(name, input);
      } else {
        searchParams.delete(name);
      }

      // Some browsers do not support this method yet.
      if (typeof searchParams.sort == 'function') {
        searchParams.sort();
      }

      const nextSearch = searchParams.toString();

      navigate({ search: nextSearch }, { replace });
    },
    [location.search, name, defaultValue, navigate],
  );

  return [param, setParam];
}
