import { Link } from '@material-ui/core';
import { Inline } from '@superdispatch/ui';
import { Box, MultilineText } from '@superdispatch/ui-lab';
import { truncate } from 'lodash-es';
import { useMemo, useState } from 'react';

interface ViewLoadLegNotesProps {
  content: string;
  disableTruncate?: boolean;
}

export function ViewLoadLegNotes({
  content,
  disableTruncate = false,
}: ViewLoadLegNotesProps) {
  const [isExpanded, setIsExpanded] = useState(false);
  const contentTruncated = useMemo(
    () =>
      disableTruncate
        ? content
        : truncate(content, {
            length: 115,
            omission: '…',
            // Separate words by non-word characters
            separator: /\W/,
          }),
    [content, disableTruncate],
  );
  const isExpandable = content.length > contentTruncated.length;

  return (
    <Inline>
      <Box backgroundColor="Blue50" padding="xsmall" borderRadius="small">
        <MultilineText overflowWrap="break-word">
          {isExpanded ? content : contentTruncated}
        </MultilineText>

        {isExpandable && (
          <Box as="span" marginLeft="xxsmall">
            <Link
              component="button"
              type="button"
              color="primary"
              onClick={() => {
                setIsExpanded(!isExpanded);
              }}
            >
              {isExpanded ? 'less' : 'more'}
            </Link>
          </Box>
        )}
      </Box>
    </Inline>
  );
}
