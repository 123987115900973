import { Typography } from '@material-ui/core';
import {
  FormikTextField,
  SuspendedFormikPhoneField,
  useFormikEnhanced,
} from '@superdispatch/forms';
import {
  Column,
  Columns,
  Inline,
  Stack,
  useSnackbarStack,
} from '@superdispatch/ui';
import { Alert, Box, Button } from '@superdispatch/ui-lab';
import { Form, FormikProvider } from 'formik';
import { get } from 'lodash-es';
import { useState } from 'react';
import { useFlag } from 'shared/settings/FeatureToggles';
import { trackDriversEvent } from '../../../drivers/data/DriversAnalytics';
import {
  PublicDriverAcceptDriverResponseDTO,
  publicDriverInviteDriverDTO,
  PublicOfferAssignResponseDTO,
  PublicOfferDTO,
  PublicOfferInviteDriverDTO,
  PublicOfferInviteResponseDTO,
} from '../data/PublicOfferDTO';
import { usePublicOfferAPI } from '../data/PublicOffersAPI';

interface PublicOfferInviteDriverFormProps {
  token: string;
  offer: PublicOfferDTO;
  onCancel: () => void;
  onSubmitSuccess: (
    driver: PublicOfferInviteResponseDTO | PublicDriverAcceptDriverResponseDTO,
    response: PublicOfferAssignResponseDTO,
  ) => void;
}

export function PublicOfferInviteDriverForm({
  token,
  offer,
  onCancel,
  onSubmitSuccess,
}: PublicOfferInviteDriverFormProps) {
  const { addSnackbar } = useSnackbarStack();
  const [pendingDriverGuid, setPendingDriverGuid] = useState<string>();
  const isInternalNameEnabled = useFlag(
    'multi_carrier_edit_internal_driver_name',
  );

  const { inviteDriver, assignDriver, acceptJoinRequest } = usePublicOfferAPI();

  const formik = useFormikEnhanced<
    PublicOfferInviteDriverDTO,
    {
      driver:
        | PublicOfferInviteResponseDTO
        | PublicDriverAcceptDriverResponseDTO;
      response: PublicOfferAssignResponseDTO;
    }
  >({
    validationSchema: publicDriverInviteDriverDTO,
    initialValues: {
      email: '',
      phone_number: '',
      name: '',
    },
    onSubmit: async (values) => {
      let driver: PublicOfferInviteResponseDTO;

      if (pendingDriverGuid) {
        driver = await acceptJoinRequest(pendingDriverGuid, values.name);
        trackDriversEvent({ name: 'Carrier Accepted Join Request' });
      } else {
        driver = await inviteDriver(token, values);
        if (offer.user) {
          trackDriversEvent({
            name: 'CTMS: Driver Invited',
            properties: {
              guid: driver.guid,
              userGuid: offer.user.guid,
              source: 'Assign Offer Page',
            },
          });
        }
      }

      return assignDriver(
        token,
        offer.guid,
        driver.guid,
        true,
        !!offer.is_first_offer,
      ).then((response) => ({ driver, response }));
    },
    onSubmitSuccess: ({ driver, response }) => {
      trackDriversEvent({
        name: 'Carrier Assigned Driver',
      });
      onSubmitSuccess(driver, response);
    },
    onSubmitFailure(error) {
      const guid = get(error, 'context.guid');
      if (error.message === 'JoinRequestAlreadySent' && !!guid) {
        setPendingDriverGuid(guid);
      } else {
        addSnackbar(error.message || 'Failed to invite driver');
      }
    },
  });

  return (
    <FormikProvider value={formik}>
      <Form>
        <Stack align="center" space="medium">
          <Typography align="center" variant="h2">
            Invite Driver
          </Typography>
          <Box width="400px" marginTop="small" marginBottom="small">
            <Stack space="small">
              {isInternalNameEnabled && (
                <FormikTextField
                  name="name"
                  label={
                    <Inline space="xxsmall">
                      Full Name
                      <Typography color="textSecondary">(Optional)</Typography>
                    </Inline>
                  }
                  fullWidth={true}
                />
              )}
              <FormikTextField
                name="email"
                label="Email"
                fullWidth={true}
                placeholder="Type driver’s email"
              />
              <SuspendedFormikPhoneField
                fullWidth={true}
                name="phone_number"
                label="Phone Number"
              />
              <Typography color="textSecondary">
                The driver will receive email and text message with the link to
                download Super Dispatch Mobile app.
              </Typography>

              {!!pendingDriverGuid && (
                <Alert severity="info">
                  This driver has already requested to join your carrier. Would
                  you like to accept the driver&apos;s request and assign the
                  load?
                </Alert>
              )}
            </Stack>
          </Box>
          <Box width="400px">
            <Columns space="small">
              <Column width="1/2">
                <Button
                  disabled={formik.isSubmitting}
                  size="large"
                  fullWidth={true}
                  variant="neutral"
                  onClick={onCancel}
                >
                  Cancel
                </Button>
              </Column>
              <Column width="1/2">
                <Button
                  size="large"
                  fullWidth={true}
                  type="submit"
                  pending={formik.isSubmitting}
                >
                  {pendingDriverGuid ? 'Accept & Assign' : 'Invite & Assign'}
                </Button>
              </Column>
            </Columns>
          </Box>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
