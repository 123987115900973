import { Link, Typography } from '@material-ui/core';
import { ArrowForward, Info } from '@material-ui/icons';
import { formatDate } from '@superdispatch/dates';
import { Color, Column, Columns, Inline, Stack } from '@superdispatch/ui';
import { Box, Button, TextBox } from '@superdispatch/ui-lab';
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { formatCurrency, formatPlural } from 'shared/helpers/IntlHelpers';
import { DriveawayIcon } from 'shared/icons/DriveawayIcon';
import { getPerDriverPrice } from 'shared/modules/subscription/core/SeatBasedPricingBox';
import { useSubscriptionSettings } from 'shared/modules/subscription/SubscriptionAPI';
import { useNavigate } from 'shared/routing/react-router-6';
import { useCarrierSettings } from 'shared/settings/CarrierSettingsAPI';
import styled from 'styled-components';

const InformationDivider = styled.div`
  border-bottom: 1px dotted ${Color.Silver400};
  height: 18px;
`;

export function SubscriptionPlanInformation() {
  const navigate = useNavigate();
  const { data: subscriptionDetails } = useSubscriptionSettings();
  const { data: settings } = useCarrierSettings();

  const upcomingBillingDate = useMemo(() => {
    const { status, billing_period_end_date } = subscriptionDetails || {};
    if (status === 'Active' && billing_period_end_date === null) {
      return formatDate(DateTime.local(), { variant: 'ShortDate' });
    }

    if (billing_period_end_date) {
      const endDate = DateTime.fromISO(billing_period_end_date);
      const daysUntilEndDate = endDate.diffNow('days').days;

      if (daysUntilEndDate >= 0) {
        return formatDate(billing_period_end_date, { variant: 'ShortDate' });
      }
    }

    return undefined;
  }, [subscriptionDetails]);

  const seatsCount = Number(subscriptionDetails?.plan?.seats_count);
  const driversCount = Number(subscriptionDetails?.plan?.drivers_count);
  const shouldDowngrade = seatsCount > driversCount;

  if (!subscriptionDetails?.plan) {
    return null;
  }

  const perDriverPrice = shouldDowngrade
    ? getPerDriverPrice(driversCount, subscriptionDetails.plan)
    : getPerDriverPrice(seatsCount, subscriptionDetails.plan);

  const totalPrice =
    driversCount > 0
      ? shouldDowngrade
        ? driversCount * perDriverPrice
        : subscriptionDetails.plan.price
      : getPerDriverPrice(1, subscriptionDetails.plan);

  return (
    <Stack space="small">
      <Typography display="inline" variant="h3">
        Plan
      </Typography>

      <Stack space="xxsmall">
        <Columns>
          <Column width="fluid">
            <Inline verticalAlign="center" space="xxsmall">
              <DriveawayIcon htmlColor={Color.Dark100} />
              <TextBox variant="body-semibold" color="secondary">
                {seatsCount} driver {formatPlural(seatsCount, 'seat', 'seats')}
              </TextBox>
            </Inline>
          </Column>
          <Column width="content">
            <Inline space="none" verticalAlign="center">
              <Typography variant="h4">
                {formatCurrency(subscriptionDetails.plan.price)}
              </Typography>
              <Typography variant="h5" color="textSecondary">
                /month
              </Typography>
            </Inline>
          </Column>
        </Columns>
        <Typography color="textSecondary">Billed monthly</Typography>
      </Stack>

      {shouldDowngrade && subscriptionDetails.status === 'Active' && (
        <Box borderRadius="small" backgroundColor="Silver200" padding="small">
          <Inline verticalAlign="center" space="xxsmall">
            Plan downgrading from{' '}
            <Typography variant="body1">
              <Inline verticalAlign="center" space="xxsmall">
                {seatsCount} <ArrowForward fontSize="small" /> {driversCount}
              </Inline>
            </Typography>{' '}
            drivers on{' '}
            <Typography variant="body1">
              {upcomingBillingDate}, {DateTime.now().year}
            </Typography>
          </Inline>
        </Box>
      )}

      <Stack space="xsmall">
        <Columns space="xsmall">
          <Column width="content">
            <Typography>
              Total {settings?.is_paying && <>due on {upcomingBillingDate}</>}
            </Typography>
          </Column>
          <Column width="fluid">
            <InformationDivider />
          </Column>
          <Column width="content">
            <Typography variant="h4">{formatCurrency(totalPrice)}</Typography>
          </Column>
        </Columns>
        <Typography align="right">+ tax if applicable</Typography>
      </Stack>

      <Button
        variant="neutral"
        onClick={() => {
          navigate('/drivers');
        }}
        size="large"
        fullWidth={true}
      >
        Manage Drivers
      </Button>

      <Box borderRadius="small" backgroundColor="Blue50" padding="xsmall">
        <Columns space="xsmall">
          <Column width="content">
            <Info htmlColor={Color.Blue300} />
          </Column>
          <Column width="fluid">
            {subscriptionDetails.status === 'Canceled' ? (
              <Typography>
                Invite or deactivate drivers to adjust plan.
              </Typography>
            ) : (
              <Stack>
                <Stack space="xxsmall">
                  <Typography variant="body1">Billing Notice</Typography>
                  <Typography variant="body1">
                    Adding Drivers:{' '}
                    <Typography display="inline" noWrap={false}>
                      Your plan updates immediately, and you&apos;ll be charged
                      a pro-rated amount for any drivers added today.
                    </Typography>
                  </Typography>

                  <Typography variant="body1">
                    Deactivating Drivers:{' '}
                    <Typography display="inline">
                      The number of available drivers remains unchanged until
                      your next billing cycle.
                    </Typography>
                  </Typography>
                </Stack>
                <Link
                  target="_blank"
                  rel="noreferrer noopener"
                  href="https://support.superdispatch.com/en/articles/5273802-super-dispatch-subscription-plans"
                >
                  Learn More
                </Link>
              </Stack>
            )}
          </Column>
        </Columns>
      </Box>
    </Stack>
  );
}
