import { Card, CardContent, Typography } from '@material-ui/core';
import { FormikTextField } from '@superdispatch/forms';
import { Inline, Stack } from '@superdispatch/ui';
import { LoadDetailsIcon } from 'shared/icons/LoadDetailsIcon';
import { LoadDetailsForm } from '../core/LoadDetailsForm';

export function AddLoadPageDetails() {
  return (
    <Card>
      <CardContent>
        <Stack space="small">
          <Inline verticalAlign="center" space="xsmall">
            <LoadDetailsIcon fontSize="small" />
            <Typography variant="h3">Load Details</Typography>
          </Inline>

          <Stack space="large">
            <LoadDetailsForm />

            <FormikTextField
              label="Driver Instructions"
              name="instructions"
              fullWidth={true}
              multiline={true}
            />
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
}
