import { openExternalURL } from './URLHelpers';

declare global {
  interface Window {
    intuit: {
      ipp: {
        anywhere: {
          setup: (options: OptionsProps) => void;
          service: {
            openExternalPopupWindow: (wind: WindowProps) => void;
          };
          controller: {
            onConnectToIntuitClicked: () => void;
          };
        };
      };
    };
  }
}

interface OptionsProps {
  grantUrl: string;
  datasources: {
    quickbooks: boolean;
    payments: boolean;
  };
}

interface WindowProps {
  url: string;
}

export function setupIntuitScript(grantUrl: string) {
  window.intuit.ipp.anywhere.setup({
    grantUrl,
    datasources: {
      quickbooks: true,
      payments: false,
    },
  });

  const openExternalPopup = ({ url }: WindowProps) => {
    openExternalURL(url, { target: '_blank' });
  };

  window.intuit.ipp.anywhere.service.openExternalPopupWindow =
    openExternalPopup;
}

export function loadIntuitScript(grantUrl: string) {
  const script = document.createElement('script');
  script.src = '//appcenter.intuit.com/Content/IA/intuit.ipp.anywhere.js';
  script.onload = () => {
    setupIntuitScript(grantUrl);
  };
  document.body.appendChild(script);
}

export function connectToQBO() {
  window.intuit.ipp.anywhere.controller.onConnectToIntuitClicked();
}
