import { Card, CardContent, Typography } from '@material-ui/core';
import { Stack } from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import { useEffect } from 'react';
import { AccountDeletionIcon } from 'shared/icons/AccountDeletionIcon';
import { useNavigate } from 'shared/routing/react-router-6';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  width: 100%;
`;

export function DriverDeleteAccountCompletionPage() {
  const navigate = useNavigate();

  useEffect(() => {
    return () => {
      navigate('/driver-login', { replace: true });
    };
  }, [navigate]);

  return (
    <Wrapper>
      <Box width="400px">
        <Card>
          <CardContent>
            <Stack align="center" space="small">
              <AccountDeletionIcon />

              <Stack align="center" space="xsmall">
                <Typography align="center" variant="h2">
                  Your account has been deleted
                </Typography>
                <Typography
                  align="center"
                  variant="body2"
                  color="textSecondary"
                >
                  You’ve successfully deleted your Super Dispatch driver
                  account. You can create a new one at anytime
                </Typography>
              </Stack>
            </Stack>
          </CardContent>
        </Card>
      </Box>
    </Wrapper>
  );
}
