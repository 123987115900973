import { ClickAwayListener, Tooltip, TooltipProps } from '@material-ui/core';
import { useResponsiveValue } from '@superdispatch/ui';
import { useState } from 'react';

export function ResponsiveTooltip(props: TooltipProps) {
  const [isTooltipOpen, setTooltipOpen] = useState(false);
  const isDesktop = useResponsiveValue(false, false, true);

  return isDesktop ? (
    <Tooltip {...props} />
  ) : (
    <ClickAwayListener
      onClickAway={() => {
        setTooltipOpen(false);
      }}
    >
      <Tooltip
        {...props}
        open={isTooltipOpen}
        disableFocusListener={true}
        disableHoverListener={false}
        disableTouchListener={true}
        onClick={(event) => {
          event.stopPropagation();
          setTooltipOpen(true);
        }}
        onClose={() => {
          setTooltipOpen(false);
        }}
      />
    </ClickAwayListener>
  );
}
