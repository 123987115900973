import { Button, ButtonProps } from '@superdispatch/ui-lab';
import { ReactNode } from 'react';
import { LocalStore } from 'shared/helpers/Store';
import {
  getUtmContentForNotification,
  trackNotificationEvent,
} from '../data/NotificationAnalytics';
import { NotificationVariant } from '../data/NotificationDTO';
import { HIDE_VERIFICATION_NOTIFICATIONS } from './HideVerificationNotifications';

export interface DontShowAgainButtonProps extends ButtonProps {
  children?: ReactNode;
  notificationType: NotificationVariant['notification_type'];
}

export function DontShowAgainButton({
  notificationType,
  children,
  onClick,
  ...props
}: DontShowAgainButtonProps) {
  return (
    <Button
      size="small"
      variant="neutral"
      {...props}
      onClick={(event) => {
        LocalStore.set(HIDE_VERIFICATION_NOTIFICATIONS, notificationType);
        trackNotificationEvent({
          name: 'Carrier Disabled Verification Process Notifications',
          utm_content: getUtmContentForNotification(notificationType),
        });
        onClick?.(event);
      }}
    >
      {children || 'Don’t show again'}
    </Button>
  );
}
