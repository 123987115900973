import { Typography } from '@material-ui/core';
import { Stack } from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import { CloseCircleIcon } from 'shared/icons';
import styled from 'styled-components';

const Wrapper = styled.div`
  padding: 24px;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export function DriverAccountDeletionErrorPage() {
  return (
    <Wrapper>
      <Stack space="medium">
        <Box maxWidth="64px" margin="auto" fontSize="64px">
          <CloseCircleIcon fontSize="inherit" color="error" />
        </Box>
        <Typography variant="h1" align="center">
          Link is expired
        </Typography>
        <Typography align="center">
          You need to go back and retry your request.
        </Typography>
      </Stack>
    </Wrapper>
  );
}
