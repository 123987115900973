import { Divider } from '@material-ui/core';
import { Inline, Stack, useResponsiveValue } from '@superdispatch/ui';
import { CarrierRatingsSummaryDTO } from './data/CarrierRatingsDTO';
import { RatingSummaryItem } from './RatingSummaryItem';

interface Props {
  summary: CarrierRatingsSummaryDTO['summary'];
}

export function RatingsSummary({
  summary: { good_customer_service, good_communication, on_time_transport },
}: Props) {
  const isMobile = useResponsiveValue(true, false);

  if (isMobile) {
    return (
      <Stack>
        <RatingSummaryItem
          label="On-Time Transport"
          count={on_time_transport}
        />

        <RatingSummaryItem
          label="Good Communication"
          count={good_communication}
        />

        <RatingSummaryItem
          label="Good Customer Service"
          count={good_customer_service}
        />
      </Stack>
    );
  }

  return (
    <Inline space={['none', 'none', 'medium']} noWrap={true}>
      <RatingSummaryItem label="On-Time Transport" count={on_time_transport} />

      <Divider orientation="vertical" />

      <RatingSummaryItem
        label="Good Communication"
        count={good_communication}
      />

      <Divider orientation="vertical" />

      <RatingSummaryItem
        label="Good Customer Service"
        count={good_customer_service}
      />
    </Inline>
  );
}
