import { Typography } from '@material-ui/core';
import { useSnackbarStack } from '@superdispatch/ui';
import { MutationDialog } from 'shared/form/MutationDialog';
import { ConfirmDialogContent } from 'shared/ui/ConfirmDialog';
import { useAssignTripDriver } from '../data/TripsAPI';

interface AssignTripToDriverDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmitSuccess: () => void;
  nextDriverGUID?: string;
  currentTripGUID: string;
}

export function AssignTripToDriverDialog({
  isOpen,
  currentTripGUID,
  nextDriverGUID,
  onClose,
  onSubmitSuccess,
}: AssignTripToDriverDialogProps) {
  const { addSnackbar } = useSnackbarStack();

  const assign = useAssignTripDriver(currentTripGUID, {
    onSuccess(response) {
      addSnackbar(response.user_message || 'Trip was successfully assigned', {
        variant: 'success',
      });

      onSubmitSuccess();
    },
    onError() {
      addSnackbar('Error occurred while assigning the trip to driver', {
        variant: 'error',
      });
    },
  });

  return (
    <MutationDialog
      open={isOpen}
      onClose={onClose}
      fullWidth={true}
      maxWidth="xs"
    >
      <ConfirmDialogContent
        onClose={onClose}
        title="Are you sure you want to assign this driver?"
        cancelButtonProps={{ disabled: assign.isLoading }}
        confirmButtonProps={{
          children: 'Assign',
          pending: assign.isLoading,
          onClick() {
            if (nextDriverGUID) {
              assign.mutate(nextDriverGUID);
            }
          },
        }}
      >
        <Typography>
          If you confirm the operation, driver will be assigned to this trip.
        </Typography>
      </ConfirmDialogContent>
    </MutationDialog>
  );
}
