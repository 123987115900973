import { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { performNavigation } from 'shared/routing/NavigationBlock';
import { useNavigate } from 'shared/routing/react-router-6';
import { useSuperPaySettings } from '../data/SuperPayAPI';

export function useVerificationAvailable(fallBackUrl: string) {
  const navigate = useNavigate();
  const { search } = useLocation();
  const { data: superPaySettings } = useSuperPaySettings();
  const isReadyForVerification = useMemo(
    () =>
      superPaySettings?.verification_step === 'bank_account_verification' &&
      superPaySettings.bank_accounts?.[0]?.is_verification_available,

    [superPaySettings],
  );

  useEffect(() => {
    if (!isReadyForVerification) {
      performNavigation(() => {
        navigate(
          {
            pathname: fallBackUrl,
            search,
          },
          { replace: true },
        );
      });
    }
  }, [isReadyForVerification, navigate, search, fallBackUrl]);
}
