import { Divider } from '@material-ui/core';
import { Add, Remove } from '@material-ui/icons';
import { FormikNumberField } from '@superdispatch/forms';
import { Color, Column, Columns, Inline, Stack } from '@superdispatch/ui';
import { Box, Button, TextBox } from '@superdispatch/ui-lab';
import { useField } from 'formik';
import { formatCurrency } from 'shared/helpers/IntlHelpers';
import { DriveawayIcon } from 'shared/icons/DriveawayIcon';
import styled from 'styled-components';
import { useSubscriptionSettings } from '../SubscriptionAPI';
import { SubscriptionSettingsDTO } from '../SubscriptionDTO';

const StyledNumberField = styled(FormikNumberField)`
  .MuiInputBase-root {
    background-color: white;
    min-width: 144px;
  }

  .MuiInputBase-input {
    text-align: center;
  }
`;

export function getPerDriverPrice(
  seatsCount: number,
  plan: NonNullable<SubscriptionSettingsDTO['plan']>,
) {
  const currentTier = plan.seats_pricing_tiers?.find(
    (tier) =>
      tier.starting_seat <= seatsCount && tier.ending_seat >= seatsCount,
  );

  if (!currentTier || !currentTier.price) {
    return Number(plan.price) / Number(plan.drivers_count);
  }

  return currentTier.price;
}

interface SeatBasedPricingBoxProps {
  isMinusDisabled: boolean;
  isReadOnly?: boolean;
}

export function SeatBasedPricingBox({
  isMinusDisabled,
  isReadOnly,
}: SeatBasedPricingBoxProps) {
  const { data: subscription } = useSubscriptionSettings();
  const [field, _, { setValue }] = useField<number>('seats_count');
  if (!subscription?.plan) return null;

  const perDriverPrice = getPerDriverPrice(field.value, subscription.plan);
  const isMinusButtonDisabled = field.value <= 1 || isMinusDisabled;

  return (
    <Box
      paddingTop="small"
      paddingBottom="small"
      paddingLeft="medium"
      paddingRight="medium"
      backgroundColor="Silver200"
      borderRadius="small"
    >
      <Stack space="small">
        <Columns space="large" align="center">
          <Column width="content">
            <Box maxWidth="140px">
              <Stack space="xxsmall">
                <TextBox variant="heading-6">{subscription.plan.type}</TextBox>
                <TextBox variant="caption" color="secondary">
                  {formatCurrency(perDriverPrice)} / driver / month
                </TextBox>
                <TextBox variant="caption" color="secondary">
                  dispatchers are free
                </TextBox>
              </Stack>
            </Box>
          </Column>
          <Column width="fluid">
            <Stack space="xsmall">
              <Columns space="xsmall" align="center" collapseBelow="desktop">
                <Column width="4/5">
                  <StyledNumberField
                    name="seats_count"
                    InputProps={{
                      readOnly: isReadOnly,
                      startAdornment: (
                        <Button
                          onClick={() => {
                            void setValue(field.value - 1);
                          }}
                          variant="neutral"
                          size="small"
                          disabled={isMinusButtonDisabled}
                        >
                          <Remove
                            color={isMinusButtonDisabled ? 'inherit' : 'action'}
                          />
                        </Button>
                      ),
                      endAdornment: (
                        <Button
                          onClick={() => {
                            void setValue(field.value + 1);
                          }}
                          variant="neutral"
                          size="small"
                        >
                          <Add htmlColor={Color.Dark100} />
                        </Button>
                      ),
                    }}
                    inputProps={{
                      decimalScale: 0,
                      allowNegative: false,
                      allowLeadingZeros: false,
                    }}
                  />
                </Column>

                <Column width="content">
                  <TextBox variant="body-semibold" color="secondary">
                    {field.value > 1 ? 'drivers' : 'driver'}
                  </TextBox>
                </Column>
              </Columns>
              <TextBox variant="caption" color="secondary">
                adjust the number if you need more
              </TextBox>
            </Stack>
          </Column>
        </Columns>
        <Divider />

        <Inline space="small" verticalAlign="center" horizontalAlign="center">
          <Inline verticalAlign="center" space="xxsmall">
            <DriveawayIcon htmlColor={Color.Dark100} />
            <TextBox variant="body-semibold" color="secondary">
              {field.value} driver {field.value > 1 ? 'seats' : 'seat'}
            </TextBox>
          </Inline>
          <Inline space="none" verticalAlign="center">
            <TextBox variant="heading-4">
              {formatCurrency(perDriverPrice * field.value)}
            </TextBox>
            <TextBox variant="body-semibold" color="secondary">
              /month
            </TextBox>
          </Inline>
        </Inline>
      </Stack>
    </Box>
  );
}
