import { GeocodeQueryType } from '@mapbox/mapbox-sdk/services/geocoding';
import { useMapboxPredictions } from './Mapbox';

export function useGeoPredictions(
  query: string | undefined,
  types: GeocodeQueryType[],
  countries?: string[],
  sortByCountry?: 'US' | 'CA',
) {
  return useMapboxPredictions(query, types, countries, sortByCountry);
}
