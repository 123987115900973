import { useSnackbarStack } from '@superdispatch/ui';
import { ReactNode, useEffect, useRef } from 'react';

function defaultErrorFormatter(error: unknown) {
  return error instanceof Error ? error.message : undefined;
}

export interface ErrorSnackbarOptions {
  onClose?: () => void;
  formatError?: (error: unknown) => ReactNode;
}

export function useErrorSnackbar(
  error: unknown,
  options: ErrorSnackbarOptions = {},
): void {
  const { addSnackbar } = useSnackbarStack();

  const optionsRef = useRef(options);

  useEffect(() => {
    optionsRef.current = options;
  });

  useEffect(() => {
    if (error != null) {
      const { onClose, formatError = defaultErrorFormatter } =
        optionsRef.current;

      const message = formatError(error);
      if (
        message &&
        message !== 'subscription_cancelled' &&
        message !== 'trial_expired'
      ) {
        addSnackbar(message, { onClose, variant: 'error' });
      }
    }
  }, [error, addSnackbar]);
}

export interface ErrorSnackbarProps extends ErrorSnackbarOptions {
  error: unknown;
}

export function ErrorSnackbar({ error, ...options }: ErrorSnackbarProps) {
  useErrorSnackbar(error, options);
  return null;
}
