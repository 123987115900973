import { DraggableLocation, DropResult } from 'react-beautiful-dnd';
import { SplitDTO, SplitLoadVehicleDTO } from './SplitLoadsDTO';

export function findVehicleAndLoad(
  loads: SplitDTO[],
  dropResult: DropResult,
): [SplitLoadVehicleDTO?, SplitDTO?] {
  const sourceLoad = loads.find(
    (load) => load.uniqueId === dropResult.source.droppableId,
  );

  const draggedVehicle = sourceLoad?.vehicles?.[dropResult.source.index];

  return [draggedVehicle, sourceLoad];
}

export function findDestinationLoad(
  loads: SplitDTO[] | undefined = [],
  destination: DraggableLocation,
) {
  return loads.find((load) => load.uniqueId === destination.droppableId);
}

export function replaceLoad(loads: SplitDTO[], load: SplitDTO) {
  const newLoads = Array.from(loads);

  const changedIndex = newLoads.findIndex(
    (currentLoad) => currentLoad.uniqueId === load.uniqueId,
  );

  newLoads.splice(changedIndex, 1, load);

  return newLoads;
}

export function removeVehicle(load: SplitDTO, index: number) {
  if (!load.vehicles) {
    return load;
  }

  const vehicles = Array.from(load.vehicles);
  vehicles.splice(index, 1);
  return { ...load, vehicles };
}

export function addVehicle(
  load: SplitDTO,
  vehicle: SplitLoadVehicleDTO,
  index: number,
) {
  if (!load.vehicles) {
    return load;
  }

  const vehicles = Array.from(load.vehicles);
  vehicles.splice(index, 0, vehicle);
  return { ...load, vehicles };
}

export function removeLoadIfEmpty(loads: SplitDTO[], uniqueId: string) {
  const newLoads = Array.from(loads);
  const index = newLoads.findIndex((load) => load.uniqueId === uniqueId);
  const vehiclesCount = newLoads[index]?.vehicles?.length ?? 0;

  if (index >= 0 && vehiclesCount === 0) {
    newLoads.splice(index, 1);
    return newLoads;
  }

  return newLoads;
}

export function vehicleCanBeDropped(
  sourceLoad: SplitDTO,
  dropResult: DropResult,
  isDroppedToEmptylist: boolean,
) {
  const hasOneVehicleInSource = sourceLoad.vehicles?.length === 1;

  if (sourceLoad.isBase && hasOneVehicleInSource) {
    return false;
  }

  if (isDroppedToEmptylist && hasOneVehicleInSource) {
    return false;
  }

  if (
    sourceLoad.isBase &&
    dropResult.source.droppableId === dropResult.destination?.droppableId
  ) {
    return false;
  }

  return true;
}
