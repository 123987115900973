import { Route, Switch } from 'react-router-dom';
import { SuperPayVerificationStatus } from 'shared/settings/CarrierSettingsAPI';
import { AccountDetailsPage } from './superpay/AccountDetailsPage';
import { BankAccountPage } from './superpay/BankAccountPage';
import { BusinessDetailsPage } from './superpay/BusinessDetailsPage';
import { PromoPage } from './superpay/PromoPage';
import {
  SuperPayRoute,
  SUPERPAY_PROFILE_ROUTES,
} from './superpay/SuperPayRoute';

const getPath = (status: SuperPayVerificationStatus | null | undefined) => {
  switch (status) {
    case 'restricted':
      return [SUPERPAY_PROFILE_ROUTES.promo];
    case 'bank_account':
      return [SUPERPAY_PROFILE_ROUTES.bankDetails];
    case 'bank_account_verification':
    case 'verified':
    case 'bank_account_failed':
    case 'bank_account_errored':
      return [SUPERPAY_PROFILE_ROUTES.accountDetails];
    default:
      return [
        SUPERPAY_PROFILE_ROUTES.promo,
        SUPERPAY_PROFILE_ROUTES.businessDetails,
      ];
  }
};

export function ProfileSuperPayPage() {
  return (
    <Switch>
      <Route path={SUPERPAY_PROFILE_ROUTES.businessDetails}>
        {/* The 'path' prop was introduced as a replacement for 'useRouteMatch' in order to facilitate the migration to React Router v6. */}
        <SuperPayRoute
          path={SUPERPAY_PROFILE_ROUTES.businessDetails}
          getPathByStatus={getPath}
        >
          <BusinessDetailsPage />
        </SuperPayRoute>
      </Route>
      <Route path={SUPERPAY_PROFILE_ROUTES.bankDetails}>
        {/* The 'path' prop was introduced as a replacement for 'useRouteMatch' in order to facilitate the migration to React Router v6. */}
        <SuperPayRoute
          path={SUPERPAY_PROFILE_ROUTES.bankDetails}
          getPathByStatus={getPath}
        >
          <BankAccountPage />
        </SuperPayRoute>
      </Route>
      <Route path={SUPERPAY_PROFILE_ROUTES.accountDetails}>
        {/* The 'path' prop was introduced as a replacement for 'useRouteMatch' in order to facilitate the migration to React Router v6. */}
        <SuperPayRoute
          path={SUPERPAY_PROFILE_ROUTES.accountDetails}
          getPathByStatus={getPath}
        >
          <AccountDetailsPage path={SUPERPAY_PROFILE_ROUTES.accountDetails} />
        </SuperPayRoute>
      </Route>
      <Route path={SUPERPAY_PROFILE_ROUTES.promo}>
        {/* The 'path' prop was introduced as a replacement for 'useRouteMatch' in order to facilitate the migration to React Router v6. */}
        <SuperPayRoute
          path={SUPERPAY_PROFILE_ROUTES.promo}
          getPathByStatus={getPath}
        >
          <PromoPage />
        </SuperPayRoute>
      </Route>
    </Switch>
  );
}
