import { InputAdornment, Typography } from '@material-ui/core';
import { InputClearButton } from 'shared/ui/InputClearButton';
import { formatLoadsPageField, LoadFilterField } from '../data/LoadsPageParams';

export interface LoadsPageFilterFieldLabelProps {
  htmlFor: string;
  name: LoadFilterField;
}

export function LoadsPageFilterFieldLabel({
  name,
  htmlFor,
}: LoadsPageFilterFieldLabelProps) {
  return (
    <InputAdornment position="start" component="label" htmlFor={htmlFor}>
      <Typography variant="body1" color="textSecondary">
        {formatLoadsPageField(name)}
      </Typography>
    </InputAdornment>
  );
}

export interface LoadsPageFilterFieldActionsProps {
  hasValue: boolean;
  onClear: () => void;
}

export function LoadsPageFilterFieldActions({
  hasValue,
  onClear,
}: LoadsPageFilterFieldActionsProps) {
  return (
    <InputAdornment position="end">
      {hasValue && (
        <InputClearButton
          aria-label="autocomplete clear button"
          onClick={(event) => {
            event.stopPropagation();
            onClear();
          }}
        />
      )}
    </InputAdornment>
  );
}
