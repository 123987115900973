import { useMemo } from 'react';
import { requestCarrierAPI } from 'shared/api/APIClient';
import {
  APIMutationOptions,
  APIMutationResult,
  useAPIMutation,
} from 'shared/api/APIMutation';
import { uploadMedia } from 'shared/data/MediaServiceAPI';
import {
  LoadAttachmentCreateDTO,
  parseLoadAttachmentFileType,
} from './LoadDTO';
import { useLoadsCache } from './LoadsAPI';

export function useAttachmentAPI() {
  return useMemo(() => {
    function createAttachment(json: LoadAttachmentCreateDTO) {
      return requestCarrierAPI('POST /internal/web/attachments/', { json });
    }

    function deleteAttachment(guid: string) {
      return requestCarrierAPI([
        'DELETE /internal/web/attachments/{guid}/',
        { guid },
      ]);
    }

    return { createAttachment, deleteAttachment };
  }, []);
}

export interface AddAttachmentInput {
  file: File;
  loadGUID: string;
}

export function useAddAttachment(
  options?: APIMutationOptions<AddAttachmentInput>,
): APIMutationResult<AddAttachmentInput> {
  const { invalidateLoads } = useLoadsCache();
  const { createAttachment } = useAttachmentAPI();
  return useAPIMutation(
    ({ file, loadGUID }) =>
      uploadMedia(file).then((url) =>
        createAttachment({
          file_url: url,
          name: file.name,
          load_guid: loadGUID,
          file_type: parseLoadAttachmentFileType(file.name),
          is_created_by_broker: false,
        }).then((response) => {
          invalidateLoads();
          return response;
        }),
      ),
    options,
  );
}

export function useDeleteAttachment(
  options?: APIMutationOptions<string>,
): APIMutationResult<string> {
  const { invalidateLoads } = useLoadsCache();
  const { deleteAttachment } = useAttachmentAPI();
  return useAPIMutation(
    (guid) =>
      deleteAttachment(guid).then((response) => {
        invalidateLoads();
        return response;
      }),
    options,
  );
}
