import { yupObject, yupPhone, yupString } from 'shared/utils/YupUtils';
import { InferType } from 'yup';

export type TerminalDTO = InferType<typeof terminalSchema>;
export const terminalSchema = yupObject({
  guid: yupString(),
  name: yupString().required().max(100),
  street: yupString().required().max(100),
  city: yupString().required().max(50),
  state: yupString().required().max(50),
  zip: yupString().required().max(20),
  contact_name: yupString().required().max(25),
  phone_number: yupPhone().required(),
});
