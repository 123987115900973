import { THIRTY_SECONDS } from 'shared/constants/NumberConstants';
import {
  BaseResourceFieldProps,
  ResourceField,
} from 'shared/form/ResourceField';
import { DispatcherDTO } from 'shared/modules/dispatcher/DispatcherDTO';
import {
  useDispatcher,
  useSearchDispatchersList,
} from '../data/DispatchersAPI';

export function DispatcherField(props: BaseResourceFieldProps<DispatcherDTO>) {
  const useOptions = (q: string) =>
    useSearchDispatchersList(
      { q, page_size: 20 },
      { staleTime: THIRTY_SECONDS, refetchOnWindowFocus: false },
    );

  const useSelectedOption = (guid: string) =>
    useDispatcher(guid, {
      staleTime: THIRTY_SECONDS,
      refetchOnWindowFocus: false,
    });

  return (
    <ResourceField
      {...props}
      useOptions={useOptions}
      noOptionsText="No dispatchers found"
      useSelectedOption={useSelectedOption}
      getOptionKey={(option) => option.guid}
      getOptionLabel={(option) => option.name || option.email || option.phone}
    />
  );
}
