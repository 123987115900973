import { Dialog, Typography } from '@material-ui/core';
import { useSnackbarStack } from '@superdispatch/ui';
import { MutationDialog } from 'shared/form/MutationDialog';
import { useNavigate } from 'shared/routing/react-router-6';
import { ConfirmDialogContent } from 'shared/ui/ConfirmDialog';
import { useRemoveTripLoad } from '../data/TripsAPI';

interface RemoveTripLoadDialogProps {
  isOpen: boolean;
  onClose: () => void;
  loadGUID?: string;
  currentTripGUID: string;
  redirectUrl: string;
}

export function RemoveTripLoadDialog({
  isOpen,
  currentTripGUID,
  loadGUID,
  redirectUrl,
  onClose,
}: RemoveTripLoadDialogProps) {
  const { addSnackbar } = useSnackbarStack();
  const navigate = useNavigate();

  const removeLoad = useRemoveTripLoad(currentTripGUID, {
    onSuccess() {
      addSnackbar('Load was successfully removed', {
        variant: 'success',
      });
      navigate(redirectUrl, { replace: true });
    },
    onError() {
      addSnackbar('Error occurred while removing the load', {
        variant: 'error',
      });
    },
  });

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth={true} maxWidth="xs">
      <MutationDialog
        open={isOpen}
        onClose={onClose}
        fullWidth={true}
        maxWidth="xs"
      >
        <ConfirmDialogContent
          onClose={onClose}
          title="Are you sure you want to remove this load?"
          cancelButtonProps={{ disabled: removeLoad.isLoading }}
          confirmButtonProps={{
            children: 'Remove',
            variant: 'critical',
            pending: removeLoad.isLoading,
            onClick() {
              if (loadGUID) {
                removeLoad.mutate(loadGUID);
              }
            },
          }}
        >
          <Typography>It will not delete the order.</Typography>
        </ConfirmDialogContent>
      </MutationDialog>
    </Dialog>
  );
}
