import { yupArray, yupObject, yupString } from 'shared/utils/YupUtils';
import { InferType } from 'yup';

export type GetChatsDTO = InferType<typeof getChatsSchema>;
export const getChatsSchema = yupObject({
  guid: yupString().required(),
  load_status: yupObject({
    status: yupString().required(),
    title: yupString().required(),
  }).required(),
  participant: yupObject({
    guid: yupString().required(),
    name: yupString().required(),
  }).required(),
  type: yupString().oneOf(['getChat']).required(),
});

export type MessageDTO = InferType<typeof messageSchema>;
export const messageSchema = yupObject({
  type: yupString().required(),
  chat_guid: yupString().required(),
  guid: yupString().required(),
  content: yupString().required(),
  sent_at: yupString().required(),
  user_guid: yupString().required(),
  user_name: yupString().required(),
  user_role: yupObject({
    role: yupString().required(),
    title: yupString().required(),
  }).required(),
  source: yupString().required(),
});

export const getMessagesSchema = yupArray(messageSchema).required();

export type ChatFormDTO = InferType<typeof chatFormSchema>;
export const chatFormSchema = yupObject({
  message: yupString().required(),
});
