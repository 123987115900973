import { createSvgIcon } from 'shared/icons/IconUtils';

export const SLBSidebarIcon = createSvgIcon(
  'SLBSidebar',
  <g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 6C3 3.79086 4.79086 2 7 2H17C19.2091 2 21 3.79086 21 6V11.2547C19.8662 10.4638 18.4872 10 17 10C15.6803 10 14.446 10.3652 13.3924 11H8C7.4 11 7 11.4 7 12C7 12.6 7.4 13 8 13H11.2547C10.8334 13.604 10.5049 14.2776 10.2899 15H8C7.4 15 7 15.4 7 16C7 16.6 7.4 17 8 17H10C10 18.9587 10.8045 20.7295 12.101 22H7C4.79086 22 3 20.2091 3 18V6ZM16 9H8C7.4 9 7 8.6 7 8C7 7.4 7.4 7 8 7H16C16.6 7 17 7.4 17 8C17 8.6 16.6 9 16 9Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 17C20 18.6569 18.6569 20 17 20C15.3431 20 14 18.6569 14 17C14 15.3431 15.3431 14 17 14C18.6569 14 20 15.3431 20 17ZM19.7574 21.1716C18.967 21.6951 18.0191 22 17 22C14.2386 22 12 19.7614 12 17C12 14.2386 14.2386 12 17 12C19.7614 12 22 14.2386 22 17C22 18.0191 21.6951 18.967 21.1716 19.7574L23.7071 22.2929L22.2929 23.7071L19.7574 21.1716Z"
      fill="currentColor"
    />
  </g>,
);
