import { Link, Typography } from '@material-ui/core';
import { Stack, useSnackbarStack } from '@superdispatch/ui';
import { Duration } from 'luxon';
import { APIError } from 'shared/api/APIError';
import { useResendVerificationCode } from '../data/AuthAPI';
import {
  PERMISSION_DENIED,
  VERIFICATION_ACCESS_DENIED,
} from '../VerifyAccountPage';
import { useVerification } from './VerificationStorage';

interface ResendVerificationProps {
  onError: (error: APIError) => void;
  email: string;
  onResendVerificationCode: () => void;
  isElapsed: boolean;
  timeLeft: Duration | undefined;
}

export const ResendVerification = ({
  onError,
  email,
  isElapsed,
  timeLeft,
  onResendVerificationCode,
}: ResendVerificationProps) => {
  const { addSnackbar } = useSnackbarStack();
  const { verificationChannel } = useVerification();

  const { mutate: resendVerificationCode, isLoading } =
    useResendVerificationCode({
      onSuccess: (response) => {
        addSnackbar(response.user_message, { variant: 'success' });
        onResendVerificationCode();
      },
      onError: (error: APIError) => {
        if (
          error.type === PERMISSION_DENIED ||
          error.type === VERIFICATION_ACCESS_DENIED
        ) {
          onError(error);
        } else {
          addSnackbar(error.message, { variant: 'error' });
        }
      },
    });

  const handleResendVerification = () => {
    if (!isLoading) {
      resendVerificationCode({
        email: email || '',
        channel: verificationChannel || '',
      });
    }
  };

  if (!timeLeft) {
    return <Typography>&nbsp;</Typography>;
  }

  const seconds = timeLeft.toFormat('s');

  if (!isElapsed && seconds !== '0') {
    return (
      <Stack space="none">
        <Typography color="textSecondary">
          Didn&#8217;t receive a code?{' '}
          {verificationChannel !== 'sms'
            ? 'Check your spam folder.'
            : `Resend code in ${seconds} sec.`}
        </Typography>
        {verificationChannel !== 'sms' && (
          <Typography color="textSecondary">
            Resend code in {seconds} sec.
          </Typography>
        )}
      </Stack>
    );
  }

  return (
    <Stack space="none">
      <Typography color="textSecondary">
        Didn&#8217;t receive a code?{' '}
        {verificationChannel !== 'sms' && `Check your spam folder. `}
        {verificationChannel === 'sms' && (
          <Link
            href="#"
            underline="none"
            color="primary"
            onClick={(e) => {
              e.preventDefault();
              handleResendVerification();
            }}
          >
            Resend code.
          </Link>
        )}
      </Typography>
      {verificationChannel !== 'sms' && (
        <Link
          href="#"
          underline="none"
          color="primary"
          onClick={(e) => {
            e.preventDefault();
            handleResendVerification();
          }}
        >
          Resend code.
        </Link>
      )}
    </Stack>
  );
};
