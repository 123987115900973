import { Tooltip } from '@material-ui/core';
import { Inline, Tag } from '@superdispatch/ui';
import { startCase } from 'lodash-es';
import { useMemo } from 'react';
import { useFlag } from 'shared/settings/FeatureToggles';
import { formatLoadStatus, isLoadCanceled, LoadDTO } from '../data/LoadDTO';

export function LoadTags({ load }: { load: LoadDTO }) {
  const currentTerminal = useMemo(
    () => load.terminals.find((terminal) => terminal.is_current),
    [load.terminals],
  );
  const isChangedByBroker =
    load.activities?.some((item) => item.is_changed_by_broker) ||
    load.is_changed_by_broker;
  const isChangedByBrokerEnabled = useFlag('changed_by_shipper');

  return (
    <Inline space="xsmall">
      {load.inspection_type === 'aiag' && (
        <Tag color="purple" variant="bold" aria-label="AIAG">
          AIAG
        </Tag>
      )}

      {load.inspection_type === 'advanced' && (
        <Tag color="teal" variant="bold" aria-label="Advanced">
          Advanced
        </Tag>
      )}

      {load.is_dispatched_to_carrier && (
        <Tag color="purple" variant="subtle" aria-label="Dispatched To Carrier">
          Dispatched to Carrier
        </Tag>
      )}

      {currentTerminal ? (
        <Tag color="purple" variant="bold" aria-label="Terminal">
          In {currentTerminal.name}
        </Tag>
      ) : (
        <Tag
          aria-label="status tag"
          variant="subtle"
          color={
            load.status === 'new'
              ? 'teal'
              : load.status === 'picked_up'
              ? 'yellow'
              : 'green'
          }
        >
          {formatLoadStatus(load.status)}
        </Tag>
      )}

      {load.archived && load.stage !== 'deleted' && (
        <Tag color="grey" variant="subtle" aria-label="Archived">
          Archived
        </Tag>
      )}

      {load.is_canceled_by_broker ? (
        <Tag color="red" variant="bold" aria-label="Cancelled By Broker">
          Canceled by Broker
        </Tag>
      ) : isLoadCanceled(load) ? (
        <Tooltip
          title={`Canceled by ${startCase(load.is_canceled_by || '')}`}
          aria-label="Canceled"
        >
          <Tag color="red" variant="bold">
            Canceled
          </Tag>
        </Tooltip>
      ) : null}

      {!!load.trip?.name && (
        <Tag color="grey" variant="subtle">
          {load.trip.name}
        </Tag>
      )}
      {isChangedByBroker &&
        isChangedByBrokerEnabled &&
        !load.archived &&
        load.is_active && (
          <Tag color="grey" variant="subtle">
            Changed by Shipper
          </Tag>
        )}
    </Inline>
  );
}
