import { useField, useFormikContext } from 'formik';
import {
  TextAutocomplete,
  TextAutocompleteProps,
} from 'shared/form/TextAutocomplete';
import { useVehicleModels } from '../data/VehiclesAPI';

interface FormikVehicleModelFieldProps
  extends Omit<TextAutocompleteProps, 'useOptions' | 'onBlur' | 'onChange'> {
  name: string;
  vehicleMake: string;
}

export function FormikVehicleModelField({
  name,
  disabled,
  helperText,
  vehicleMake,
  ...props
}: FormikVehicleModelFieldProps) {
  const { isSubmitting } = useFormikContext();
  const [field, { error, touched }, { setValue }] = useField<string>(name);
  const errorText = touched && error;

  const useOptions = (keyword: string | undefined) => {
    const { data } = useVehicleModels(vehicleMake, keyword, {
      staleTime: Infinity,
    });
    return data;
  };

  return (
    <TextAutocomplete
      {...props}
      {...field}
      error={!!error}
      useOptions={useOptions}
      disabled={disabled || isSubmitting}
      helperText={errorText || helperText}
      onChange={(nextValue) => {
        void setValue(nextValue);
      }}
    />
  );
}
