import { SuperDispatchTheme } from '@superdispatch/ui';
import { ReactNode } from 'react';
import styled, { css } from 'styled-components';

const MouseOverArea = styled.div`
  position: relative;

  /* Support for parents with  "display: flex" */
  width: 100%;
`;

interface ButtonContainerProps {
  isVisible?: boolean;

  // Adopted from the Tooltip placement
  // @link https://material-ui.com/api/tooltip/#props
  placement?: 'right-start' | 'right';
}

const ButtonContainer = styled.div<ButtonContainerProps>`
  position: absolute;

  ${({ placement }) => {
    switch (placement) {
      case 'right':
        return css`
          top: 50%;
          right: 4px;
          transform: translateY(-50%);
        `;

      case 'right-start':
      default:
        return css`
          top: 4px;
          right: 4px;
        `;
    }
  }};

  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transition: ${({ theme }) =>
    (theme as SuperDispatchTheme).transitions.create('opacity')};

  ${MouseOverArea}:hover > & {
    opacity: 1;
  }

  @media (hover: none) {
    opacity: 1;
  }
`;

export interface FloatingButtonAreaProps {
  children?: ReactNode;

  button?: ReactNode;
  buttonVisible?: boolean;
  buttonPlacement?: ButtonContainerProps['placement'];
}

export function FloatingButtonArea({
  children,

  button,
  buttonVisible: isButtonVisible,
  buttonPlacement = 'right-start',
}: FloatingButtonAreaProps) {
  return (
    <MouseOverArea>
      {!!button && (
        <ButtonContainer
          placement={buttonPlacement}
          isVisible={isButtonVisible}
        >
          {button}
        </ButtonContainer>
      )}

      {children}
    </MouseOverArea>
  );
}
