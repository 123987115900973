import { Skeleton } from '@material-ui/lab';
import { Stack } from '@superdispatch/ui';

export function PhoneVerificationPlaceholder() {
  return (
    <Stack>
      <Skeleton width="120px" height="120px" />
      <Skeleton height="24px" />
      <Skeleton height="48px" />
    </Stack>
  );
}
