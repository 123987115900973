import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import { Button } from '@superdispatch/ui-lab';
import { showNewIntercomMessage } from 'shared/helpers/Intercom';

export interface LoginErrorDialogProps {
  title: string;
  message?: string;
  onClose: () => void;
}

export function LoginErrorDialog({
  title,
  message,
  onClose,
}: LoginErrorDialogProps) {
  return (
    <Dialog open={!!message} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{message}</DialogContent>
      <DialogActions>
        <Button
          variant="neutral"
          onClick={() => {
            onClose();
            showNewIntercomMessage();
          }}
        >
          Contact Support
        </Button>

        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}
