import { Box, Button } from '@superdispatch/ui-lab';
import { parseURITemplate } from '@superdispatch/uri';
import { getOS } from 'shared/helpers/BrowserHelpers';
import { openExternalURL } from 'shared/helpers/URLHelpers';
import { getBackURL } from 'shared/ui/BackButton';
import { refreshAuthToken } from '../AuthToken';

interface ResetPasswordActionProps {
  token?: string;
}

const ANDROID_HOST = 'carrier.superdispatch.com';
const ANDROID_SCHEME = 'superdispatch';
const APP_PACKAGE = 'com.mysuperdispatch.android';

function launchAppOnAndroidDevice() {
  const url = parseURITemplate(
    'intent://{ANDROID_HOST}/#Intent;scheme={ANDROID_SCHEME};package={APP_PACKAGE};end',
    {
      ANDROID_HOST,
      ANDROID_SCHEME,
      APP_PACKAGE,
    },
  );

  window.location.replace(url);
}

function launchAppOnAppleDevice() {
  // if the redirect fails we need to navigate users to app store to get the app
  setTimeout(() => {
    window.location.replace(
      'https://itunes.apple.com/us/app/super-dispatch-bol-app-epod/id921598152?mt=8',
    );
  }, 500);

  window.location.replace('mysuperdispatch://');
}

function launchSDApp(fallbackFn?: () => void) {
  const os = getOS();
  if (os === 'android') {
    launchAppOnAndroidDevice();
  } else if (os === 'ios') {
    launchAppOnAppleDevice();
  } else {
    fallbackFn?.();
  }
}

export function ResetPasswordAction({ token }: ResetPasswordActionProps) {
  const openApp = () => {
    launchSDApp(() => {
      refreshAuthToken(token);
      openExternalURL(getBackURL(window.location.search, '/tms/loads'));
    });
  };

  return (
    <Box backgroundColor="White" padding={['small', 'medium']}>
      <Button fullWidth={true} onClick={openApp}>
        Continue
      </Button>
    </Box>
  );
}
