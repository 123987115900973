import {
  CircularProgress,
  InputAdornment,
  MenuItem,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { Info } from '@material-ui/icons';
import {
  FormikCurrencyField,
  FormikDateField,
  FormikTextField,
} from '@superdispatch/forms';
import { LoadPaymentMethod, LoadPaymentTerm } from '@superdispatch/sdk';
import {
  Column,
  Columns,
  Inline,
  Stack,
  useSnackbarStack,
} from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import { getIn, useFormikContext } from 'formik';
import { useEffect, useMemo, useRef } from 'react';
import { ONE_SECOND } from 'shared/constants/NumberConstants';
import { formatCurrency } from 'shared/helpers/IntlHelpers';
import { formatLoadPayment } from 'shared/helpers/PaymentHelpers';
import { scheduleTimeout } from 'shared/helpers/ReactHelpers';
import { useCarrierPaidMethodVisibleOptions } from 'shared/settings/CarrierSettingsAPI';
import { AddLoadDTO } from '../data/LoadDTO';
import { LOAD_PAYMENT_TYPES } from '../data/LoadPaymentTypes';
import { useLoadsContext } from '../data/LoadsContext';

interface TooltipWrapperPorps {
  title: string;
  children: JSX.Element;
}

export function TooltipWrapper({ title, children }: TooltipWrapperPorps) {
  return title ? (
    <Tooltip title={title}>
      <Box>{children}</Box>
    </Tooltip>
  ) : (
    children
  );
}

export interface LoadPaymentFormProps {
  name?: string;
  hasInitialLoadID?: boolean;
  vehiclesTotalPrice?: number;
  canPaymentBeEdited?: boolean;
  isEnabledEditLoad?: boolean;
}

export function LoadPaymentForm({
  name,
  hasInitialLoadID,
  vehiclesTotalPrice,
  canPaymentBeEdited = true,
  isEnabledEditLoad = true,
}: LoadPaymentFormProps) {
  const prefix = !name ? '' : `${name}.`;
  const { addSnackbar } = useSnackbarStack();
  const {
    loadPriceCalculationStatus: { setIsCalculating, isCalculating },
  } = useLoadsContext();

  const { values, setFieldValue, setFormikState } =
    useFormikContext<AddLoadDTO>();

  const paidMethod = getIn(values, `${prefix}paid_method`) as number;
  const paidMethodOptions = useCarrierPaidMethodVisibleOptions(paidMethod);

  const isMarkedAsPaid = !!getIn(values, `${prefix}paid_at`);
  const paymentMethod = getIn(values, `${prefix}method`) as
    | LoadPaymentMethod
    | LoadPaymentTerm;

  const paymentTypes = useMemo(
    () =>
      LOAD_PAYMENT_TYPES.filter(
        (type) => !(paymentMethod !== 'superpay' && type === 'superpay'),
      ),
    [paymentMethod],
  );

  const priceFieldName = `${prefix}price`;
  const priceFieldValue = (getIn(values, priceFieldName) as number) || 0;
  const previousTotalPriceRef = useRef<number>();
  useEffect(() => {
    // Reset when `vehiclesTotalPrice` is not provided.
    if (vehiclesTotalPrice == null) {
      previousTotalPriceRef.current = undefined;
      return;
    }

    // Handle initial value.
    if (previousTotalPriceRef.current == null) {
      previousTotalPriceRef.current = vehiclesTotalPrice;
      return;
    }

    // Skip when `vehiclesTotalPrice` is not changed.
    if (vehiclesTotalPrice === previousTotalPriceRef.current) return;

    setIsCalculating(true);

    return scheduleTimeout(() => {
      setIsCalculating(false);
      const shouldNotify = priceFieldValue !== previousTotalPriceRef.current;

      previousTotalPriceRef.current = vehiclesTotalPrice;

      if (priceFieldValue !== vehiclesTotalPrice) {
        void setFieldValue(priceFieldName, vehiclesTotalPrice || null, false);

        if (shouldNotify) {
          addSnackbar(
            `Payment price adjusted to: ${formatCurrency(vehiclesTotalPrice)}`,
            { key: 'load_payment_price_adjust' },
          );
        }
      }
    }, ONE_SECOND);
  }, [
    addSnackbar,
    setFieldValue,
    setFormikState,
    setIsCalculating,
    priceFieldName,
    priceFieldValue,
    vehiclesTotalPrice,
  ]);

  return (
    <Stack space="medium">
      <Stack space="small">
        <Columns space="small" collapseBelow="desktop">
          <Column width="1/2">
            <TooltipWrapper
              title={
                isEnabledEditLoad
                  ? ''
                  : 'You cannot change Price for the loads coming from Shipper'
              }
            >
              <FormikCurrencyField
                fullWidth={true}
                label="Price"
                disabled={
                  isCalculating || !canPaymentBeEdited || !isEnabledEditLoad
                }
                name={`${prefix}price`}
                helperText={
                  !!vehiclesTotalPrice && (
                    <>
                      Vehicles total price is{' '}
                      {formatCurrency(vehiclesTotalPrice)}
                    </>
                  )
                }
                inputProps={{
                  disableValueParsing: true,
                }}
                InputProps={{
                  endAdornment: isCalculating ? (
                    <InputAdornment position="end">
                      <CircularProgress size={12} color="inherit" />
                    </InputAdornment>
                  ) : undefined,
                }}
              />
            </TooltipWrapper>
          </Column>

          <Column width="1/2">
            <FormikCurrencyField
              fullWidth={true}
              name={`${prefix}driver_pay`}
              label={
                <Inline noWrap={true} space="xsmall" verticalAlign="center">
                  <span>Driver Pay</span>

                  <Tooltip
                    placement="top"
                    enterTouchDelay={0}
                    title="Money that the driver will get for the load. The driver will see this amount in the mobile application. This amount will also be included in the calculations in Custom Reports."
                  >
                    <Info fontSize="small" color="action" />
                  </Tooltip>
                </Inline>
              }
            />
          </Column>
        </Columns>

        <Columns space="small" collapseBelow="desktop">
          <Column width="1/2">
            <TooltipWrapper
              title={
                isEnabledEditLoad
                  ? ''
                  : 'You cannot change Method for the loads coming from Shipper'
              }
            >
              <FormikTextField
                select={true}
                fullWidth={true}
                label="Method"
                disabled={!canPaymentBeEdited || !isEnabledEditLoad}
                name={`${prefix}method`}
              >
                {paymentTypes.map((key) => (
                  <MenuItem key={key} value={key}>
                    {formatLoadPayment(key)}
                  </MenuItem>
                ))}
              </FormikTextField>
            </TooltipWrapper>
          </Column>
        </Columns>

        <Columns space="small" collapseBelow="desktop">
          <Column width="1/2">
            <TooltipWrapper
              title={
                isEnabledEditLoad
                  ? ''
                  : 'You cannot change Broker Fee for the loads coming from Shipper'
              }
            >
              <FormikCurrencyField
                fullWidth={true}
                name={`${prefix}broker_fee`}
                disabled={!isEnabledEditLoad}
                label={
                  <Inline noWrap={true} space="xsmall" verticalAlign="center">
                    <span>Broker Fee</span>

                    <Tooltip
                      placement="top"
                      enterTouchDelay={0}
                      title="Fee paid to broker"
                    >
                      <Info fontSize="small" color="action" />
                    </Tooltip>
                  </Inline>
                }
              />
            </TooltipWrapper>
          </Column>
        </Columns>

        <FormikTextField
          label="Notes"
          multiline={true}
          fullWidth={true}
          name={`${prefix}notes`}
        />
      </Stack>

      {isMarkedAsPaid && (
        <Stack space="small">
          <Columns space="small" collapseBelow="desktop">
            <Column width="1/2">
              <FormikCurrencyField
                fullWidth={true}
                label="Paid Amount"
                disabled={!canPaymentBeEdited}
                name={`${prefix}paid_amount`}
              />
            </Column>

            <Column width="1/2">
              <FormikDateField
                fullWidth={true}
                label="Receipt Date"
                disabled={!canPaymentBeEdited}
                name={`${prefix}paid_at`}
              />
            </Column>
          </Columns>

          <FormikTextField
            label="Method"
            select={true}
            fullWidth={true}
            disabled={!canPaymentBeEdited}
            name={`${prefix}paid_method`}
          >
            {Array.from(paidMethodOptions, ([value, text]) => (
              <MenuItem key={value} value={value}>
                {text}
              </MenuItem>
            ))}
          </FormikTextField>

          <FormikTextField
            disabled={!canPaymentBeEdited}
            fullWidth={true}
            label="Reference Number"
            name={`${prefix}payment_reference_number`}
          />
        </Stack>
      )}

      <Stack space="small">
        <Typography variant="h4">Invoice Details</Typography>

        <Columns space="small" collapseBelow="desktop">
          <Column width="1/2">
            <FormikTextField
              fullWidth={true}
              label="Invoice ID"
              name={`${prefix}invoice.invoice_id`}
              helperText={
                hasInitialLoadID &&
                'Leave this field empty to assign ID automatically.'
              }
            />
          </Column>
        </Columns>

        <FormikTextField
          label="Notes"
          multiline={true}
          fullWidth={true}
          name={`${prefix}invoice.invoice_notes`}
        />
      </Stack>
    </Stack>
  );
}
