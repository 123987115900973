import { Typography } from '@material-ui/core';
import { Box } from '@superdispatch/ui-lab';
import styled from 'styled-components';

const Placeholder = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

export function SplitLoadsEmptyCard() {
  return (
    <Placeholder>
      <Typography variant="h3" color="textSecondary">
        Drag & Drop
      </Typography>
      <Typography variant="body2" color="textSecondary">
        vehicles to create a load
      </Typography>
    </Placeholder>
  );
}
