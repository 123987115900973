import { LocalStore, useLocalStore } from 'shared/helpers/Store';

export const IS_ENTERED_DISPATCHER_PROFILE = 'is_entered_dispatcher_profile';
export const IS_CLICKED_UPDATE_PASSWORD_BANNER =
  'is_clicked_update_password_banner';
export const CLICKED_UPDATE_PASSWORD_REMINDER_STORAGE_KEY =
  'clicked_update_password_reminder';

export function useClickedUpdatePasswordBanner() {
  const setClickedUpdatePasswordBanner = (value: string) => {
    LocalStore.set(IS_CLICKED_UPDATE_PASSWORD_BANNER, value);
  };
  const closeBanner = () => {
    setClickedUpdatePasswordBanner('true');
  };

  const isOpen = useLocalStore(IS_CLICKED_UPDATE_PASSWORD_BANNER);
  return {
    isOpen,
    closeBanner,
  };
}

export function useIsClickedUpdatePassword() {
  const updatePasswordReminder = useLocalStore(
    CLICKED_UPDATE_PASSWORD_REMINDER_STORAGE_KEY,
  );
  function setClickedUpdatePassword() {
    LocalStore.set(CLICKED_UPDATE_PASSWORD_REMINDER_STORAGE_KEY, 'true');
  }

  return {
    setClickedUpdatePassword,
    updatePasswordReminder,
  };
}

export function setEnteredDispatcherProfile(value: string) {
  LocalStore.set(IS_ENTERED_DISPATCHER_PROFILE, value);
}
