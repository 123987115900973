import { Skeleton } from '@material-ui/lab';
import { Column, Columns, Stack } from '@superdispatch/ui';

export function ChargebeeEmptyPlaceholder() {
  return (
    <Stack space="small">
      <Skeleton height="32px" />
      <Skeleton height="32px" />
      <Columns space="medium">
        <Column width="1/2">
          <Skeleton height="32px" />
        </Column>
        <Column width="1/2">
          <Skeleton height="32px" />
        </Column>
      </Columns>
    </Stack>
  );
}
