import { formatNumber, formatPlural } from 'shared/helpers/IntlHelpers';

interface VehicleParameters {
  curb_weight: number | null;
  height: number | null;
  length: number | null;
  width: number | null;
}

export function hasVehicleDimension(vehicle: VehicleParameters): boolean {
  const { height, width, length } = vehicle;
  return height != null || width != null || length != null;
}

export function formatVehicleDimensions(vehicle: VehicleParameters): string {
  return [
    [vehicle.length, 'L'],
    [vehicle.width, 'W'],
    [vehicle.height, 'H'],
  ]
    .filter(([value]) => value != null)
    .map(
      ([value, label]) =>
        `${formatNumber(value, { maximumFractionDigits: 0 })}” ${label}`,
    )
    .join(' x ');
}

export function formatVehicleLength(length: number) {
  return formatPlural(length, 'vehicle', 'vehicles');
}

export function formatCurbWeight(value: number, unit?: string) {
  return `~ ${formatNumber(value)} ${unit || 'lbs'}`;
}
