import { useEffect, useState } from 'react';
import { useVisibilityState } from 'shared/helpers/BrowserHelpers';
import { useSet } from 'shared/helpers/ReactHelpers';

export function useDocumentTitleHighlighter(
  message: null | string,
  duration = 2000,
) {
  useEffect(() => {
    const prevTitle = document.title;

    if (!message) {
      return;
    }

    const interval = setInterval(() => {
      document.title = document.title === message ? prevTitle : message;
    }, duration);

    return () => {
      document.title = prevTitle;
      clearInterval(interval);
    };
  }, [message, duration]);
}

export function useTabNotification() {
  const visibilityState = useVisibilityState();

  const [highlightedTitle, setHighlightedTitle] = useState<string | null>(null);
  const [unseenMessages, addMessage, deleteMessage] = useSet<string>();

  useDocumentTitleHighlighter(highlightedTitle);

  useEffect(() => {
    if (visibilityState === 'visible') {
      setHighlightedTitle(null);
    } else if (unseenMessages.size > 0) {
      setHighlightedTitle(`${unseenMessages.size} new message(s)`);
    }
  }, [unseenMessages, setHighlightedTitle, visibilityState]);

  return { addMessage, deleteMessage };
}
