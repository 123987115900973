import { yupObject, yupString } from 'shared/utils/YupUtils';
import { InferType } from 'yup';

export type ContactDTO = InferType<typeof contactSchema>;
export const contactSchema = yupObject({
  guid: yupString(),
  name: yupString().nullable(),
  address: yupString(),
  city: yupString(),
  state: yupString(),
  zip: yupString(),
  contact: yupString(),
  phone: yupString(),
  email: yupString(),
  notes: yupString(),
});

export type ContactEditDTO = InferType<typeof contactEditSchema>;
export const contactEditSchema = yupObject({
  name: yupString().required(),
  address: yupString().optional(),
  city: yupString().optional(),
  state: yupString().optional(),
  zip: yupString().optional(),
  contact: yupString().optional(),
  phone: yupString().optional(),
  email: yupString().multipleEmails().optional(),
  notes: yupString().optional(),
});
