import { useFormikEnhanced } from '@superdispatch/forms';
import { useSnackbarStack } from '@superdispatch/ui';
import { Button } from '@superdispatch/ui-lab';
import { APIResponse } from 'shared/api/APIClient';
import { APIError } from 'shared/api/APIError';
import { isWebView } from 'shared/constants/AppTypeConstants';
import { useInitialValues } from 'shared/form/AppFormik';
import { DocumentTitle } from 'shared/layout/DocumentTitle';
import { ProfileStateMotorCarrierPermitForm } from './core/ProfileStateMotorCarrierPermitForm';
import {
  useSubmitVerificationDocument,
  VerificationDialogs,
} from './core/VerificationDialogs';
import { trackCarrierProfileEvent } from './data/CarrierProfileAnalytics';
import {
  useCarrierProfileAPI,
  useStateMotorCarrierPermit,
} from './data/CarrierProfileAPI';
import {
  StateMotorCarrierPermitDTO,
  stateMotorCarrierPermitSchema,
} from './data/CarrierProfileDTO';
import { ProfilePageLayout } from './ProfilePageLayout';

export function ProfileStateMotorCarrierPermitPage() {
  const { addSnackbar } = useSnackbarStack();
  const { updateStateMotorCarrierPermit } = useCarrierProfileAPI();
  const { data, isLoading } = useStateMotorCarrierPermit({
    onError: () => {
      addSnackbar('Failed to fetch State Motor Carrier Permit', {
        variant: 'error',
      });
    },
  });

  const initialValues = useInitialValues(stateMotorCarrierPermitSchema, data);

  const formik = useFormikEnhanced<StateMotorCarrierPermitDTO, APIResponse>({
    initialValues,
    validationSchema: stateMotorCarrierPermitSchema,
    onSubmit: updateStateMotorCarrierPermit,
    onSubmitSuccess: () => {
      addSnackbar('State Motor Carrier Permit was successfully updated', {
        variant: 'success',
      });

      trackCarrierProfileEvent({
        name: 'Carrier Updated Carrier Info',
        utm_medium: 'Carrier Profile',
        utm_content: 'State Motor Carrier Permit',
      });
    },
    onSubmitFailure: ({ message }: APIError) => {
      addSnackbar(`Failed to update State Motor Carrier Permit: ${message}`, {
        variant: 'error',
      });
    },
  });

  const submitVerificationForm = useSubmitVerificationDocument(formik);

  return (
    <>
      <DocumentTitle title="State Motor Carrier Permit - Profile" />

      <VerificationDialogs formik={formik} />

      <ProfilePageLayout
        formik={formik}
        title="State Motor Carrier Permit"
        isLoading={isLoading}
        headerEndActions={
          <Button
            type="button"
            onClick={submitVerificationForm}
            pending={formik.isSubmitting}
            disabled={!formik.dirty}
            fullWidth={isWebView}
          >
            Save
          </Button>
        }
      >
        <ProfileStateMotorCarrierPermitForm />
      </ProfilePageLayout>
    </>
  );
}
