import { Stack, useSnackbarStack } from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import { useEffect } from 'react';
import { trackEvent } from 'shared/helpers/Analytics';
import { DocumentTitle } from 'shared/layout/DocumentTitle';
import { CarrierRatingsCard } from 'shared/modules/ratings/CarrierRatingsCard';
import { useLocationParams } from 'shared/routing/LocationParams';
import { ScrollToTop } from 'shared/routing/ScrollToTop';
import { useCarrierRatingsSummary } from '../../ratings/data/CarrierRatingsAPI';
import { carrierRatingsPageParamsSchema } from '../../ratings/data/CarrierRatingsDTO';
import { CarrierOverviewHeader } from './CarrierOverviewHeader';

export function CarrierOverviewPage() {
  const { addSnackbar } = useSnackbarStack();
  const { data: carrierRatingsSummary, error } = useCarrierRatingsSummary();
  const [params] = useLocationParams({
    yupSchema: carrierRatingsPageParamsSchema,
  });

  useEffect(() => {
    if (error) {
      addSnackbar('Failed to fetch carrier ratings summary', {
        variant: 'error',
      });
    }
  }, [addSnackbar, error]);

  useEffect(() => {
    if (params.tab === 'ratings_received') {
      trackEvent('CTMS: Opened Own Ratings');
    }
  }, [params]);

  return (
    <>
      <DocumentTitle title="Overview" />

      <ScrollToTop key={params.ratings_page} />

      <Box padding={['xsmall', 'none']}>
        <Box maxWidth="832px" margin="auto">
          <Stack space="small">
            <CarrierOverviewHeader
              carrierRatingsSummary={carrierRatingsSummary}
            />
            <CarrierRatingsCard carrierRatingsSummary={carrierRatingsSummary} />
          </Stack>
        </Box>
      </Box>
    </>
  );
}
