import { useDeepEqualValue } from '@superdispatch/hooks';
import { createContext, ReactNode, useCallback } from 'react';
import { useNullableContext } from 'shared/hooks/useNullableContext';
import { useMatch, useNavigate } from 'shared/routing/react-router-6';

export interface ContactsPageRouter {
  rootURL: string;
  currentContactGUID: string | undefined;
  openContact: (guid: string, replace?: boolean) => void;
  clearContact: () => void;
}

const context = createContext<null | ContactsPageRouter>(null);

export function useContactsPageRouter(): ContactsPageRouter {
  return useNullableContext('ContactsPageRouter', context);
}

export interface ContactsPageRouterProviderProps {
  children?: ReactNode;
}

const rootURL = '/contacts';

export function ContactsPageRouterProvider({
  children,
}: ContactsPageRouterProviderProps) {
  const contactsDetailsMatch = useMatch<{ contactGUID?: string }>(
    `${rootURL}/:contactGUID`,
  );
  const currentContactGUID =
    contactsDetailsMatch?.params.contactGUID || undefined;

  const navigate = useNavigate();
  const openContact = useCallback(
    (guid: string, replace?: boolean) => {
      navigate(`${rootURL}/${guid}`, { replace });
    },
    [navigate],
  );

  const clearContact = useCallback(() => {
    navigate(rootURL, { replace: true });
  }, [navigate]);

  const ctx = useDeepEqualValue({
    rootURL,
    openContact,
    clearContact,
    currentContactGUID,
  });

  return <context.Provider value={ctx}>{children}</context.Provider>;
}
