import { EMPTY_ERROR_MESSAGE } from '@superdispatch/forms';
import { passwordCheckTypes } from 'shared/utils/PasswordUtils';
import { hasOnlyDigits } from 'shared/utils/StringUtils';
import { yupObject, yupString } from 'shared/utils/YupUtils';
import { InferType } from 'yup';

export type LoginDTO = InferType<typeof loginSchema>;
export const loginSchema = yupObject({
  email: yupString()
    .trim()
    .required('Enter email')
    .email('Enter valid email. Example: name@company.com'),
  password: yupString().required('Enter password'),
});

export type ResetPasswordDTO = InferType<typeof resetPasswordSchema>;
export const resetPasswordSchema = yupObject({
  email: yupString()
    .required('Enter email')
    .email('Enter valid email. Example: name@company.com'),
});
export type ConfirmPasswordResetDTO = InferType<
  typeof confirmPasswordResetSchema
>;
export const confirmPasswordResetSchema = yupObject({
  password: yupString()
    .required(EMPTY_ERROR_MESSAGE)
    .min(8, EMPTY_ERROR_MESSAGE)
    .max(128)
    .matches(passwordCheckTypes.toLowerCaseAndUpperCase, EMPTY_ERROR_MESSAGE)
    .matches(passwordCheckTypes.toSpecialCharacter, EMPTY_ERROR_MESSAGE)
    .matches(passwordCheckTypes.toNumber, EMPTY_ERROR_MESSAGE),
});

export type ResetPasswordSuccessDTO = InferType<
  typeof resetPasswordSuccessSchema
>;
export const resetPasswordSuccessSchema = yupObject({
  token: yupString(),
  has_dispatcher_role: yupString().nullable(),
});

export type VerifyAccountDTO = InferType<typeof verifyAccountSchema>;
export const verifyAccountSchema = yupObject({
  code: yupString()
    .required('Enter Verification Code')
    .length(6, 'Your code must be composed of 6 digits')
    .test('only digits', 'Your code must contain only digits', hasOnlyDigits),
  email: yupString().notRequired(),
  channel: yupString(),
});

export type ResendVerificationCodeDTO = InferType<typeof resendCodeSchema>;
export const resendCodeSchema = yupObject({
  email: yupString().required(),
  channel: yupString().required(),
});
