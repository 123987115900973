import { chunk } from 'lodash-es';
import { useMemo } from 'react';
import { APIResponse, requestCarrierAPI } from 'shared/api/APIClient';
import {
  APIMutationOptions,
  APIMutationResult,
  useAPIMutation,
} from 'shared/api/APIMutation';
import {
  APIQueryOptions,
  APIQueryResult,
  useAPIQuery,
} from 'shared/api/APIQuery';
import { LoadVehicleCreateDTO, LoadVehicleEditDTO } from './LoadActionsDTO';

interface VehicleMutationParams {
  adjust_load_price?: boolean;
}

export function useVehiclesAPI() {
  return useMemo(() => {
    function createVehicle(
      json: LoadVehicleCreateDTO,
      { adjust_load_price = false }: VehicleMutationParams = {},
    ) {
      return requestCarrierAPI(
        [
          'POST /internal/web/vehicles/{?adjust_load_price}',
          { adjust_load_price },
        ],
        { json },
      );
    }

    function bulkCreateVehicles(
      vehicles: LoadVehicleCreateDTO[],
      { adjust_load_price = false }: VehicleMutationParams = {},
    ) {
      return Promise.all(
        chunk(vehicles, 20).map((json) =>
          requestCarrierAPI(
            [
              'POST /internal/web/vehicles/bulk-create/{?adjust_load_price}',
              { adjust_load_price },
            ],
            { json },
          ),
        ),
      ).then(([firstResponse]) => firstResponse as APIResponse);
    }

    function editVehicle(
      guid: string,
      json: LoadVehicleEditDTO,
      { adjust_load_price = false }: VehicleMutationParams = {},
    ) {
      return requestCarrierAPI(
        [
          'PUT /internal/web/vehicles/{guid}/{?adjust_load_price}',
          { guid, adjust_load_price },
        ],
        { json },
      );
    }

    function deleteVehicle(
      guid: string,
      { adjust_load_price = false }: VehicleMutationParams = {},
    ) {
      return requestCarrierAPI([
        'DELETE /internal/web/vehicles/{guid}/{?adjust_load_price}',
        { guid, adjust_load_price },
      ]);
    }

    return { createVehicle, bulkCreateVehicles, editVehicle, deleteVehicle };
  }, []);
}

export function useDeleteVehicle(
  params?: VehicleMutationParams,
  options?: APIMutationOptions<string>,
): APIMutationResult<string> {
  const { deleteVehicle } = useVehiclesAPI();
  return useAPIMutation((guid: string) => deleteVehicle(guid, params), options);
}

export function useVehicleMakes(
  keyword: string | undefined,
  options?: APIQueryOptions<string[]>,
): APIQueryResult<string[]> {
  return useAPIQuery(
    ['vehicles', 'makes', { keyword }],
    () =>
      requestCarrierAPI([
        'GET /internal/web/vehicles/makes/{?keyword}',
        { keyword },
      ]),
    {
      ...options,
      enabled: !!keyword,
      normalize(values: Array<{ name: string }>) {
        return values.map((value) => value.name);
      },
    },
  );
}

export function useVehicleModels(
  make: string,
  keyword: string | undefined,
  options?: APIQueryOptions<string[]>,
): APIQueryResult<string[]> {
  return useAPIQuery(
    ['vehicles', 'models', { make, keyword }],
    () =>
      requestCarrierAPI([
        'GET /internal/web/vehicles/models/{?make,keyword}',
        { make, keyword },
      ]),
    {
      ...options,
      enabled: !!keyword,
      normalize(values: Array<{ name: string }>) {
        return values.map((value) => value.name);
      },
    },
  );
}
