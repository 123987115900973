import { Navigate, useMatch } from 'shared/routing/react-router-6';
import { OffersPageDetails } from './OffersPageDetails';

export function OffersPage() {
  const offerRoute = useMatch<{ guid: string }>('/offers/:guid');
  const offerGUID = offerRoute?.params.guid;

  if (!offerGUID) return <Navigate to="/loads" />;

  return <OffersPageDetails guid={offerGUID} />;
}
