import { useIsMounted } from '@superdispatch/hooks';
import clipboardCopy from 'clipboard-copy';
import { useCallback, useMemo, useState } from 'react';
import { logError } from 'shared/helpers/ErrorTracker';

export function blurActiveElement() {
  // using `as` here because not all html elements has blur method
  const activeInput = document.activeElement as null | HTMLInputElement;
  if (activeInput && typeof activeInput.blur == 'function') activeInput.blur();
}

export interface UseClipboardResult {
  reset: () => void;
  copy: (text: string) => void;
  status: 'waiting' | 'copying' | 'copied';
}

export function useClipboard(): UseClipboardResult {
  const isMounted = useIsMounted();
  const [status, setStatus] = useState<UseClipboardResult['status']>('waiting');
  const reset = useCallback(() => {
    setStatus('waiting');
  }, []);
  const copy = useCallback(
    (text: string) => {
      setStatus('copying');

      clipboardCopy(text).then(
        () => {
          if (isMounted()) {
            setStatus('copied');
          }
        },
        (error) => {
          logError(error, 'useClipboard');

          if (isMounted()) {
            setStatus('waiting');
            // eslint-disable-next-line no-alert
            alert(text);
          }
        },
      );
    },
    [isMounted],
  );

  return useMemo<UseClipboardResult>(
    () => ({ copy, reset, status }),
    [reset, copy, status],
  );
}
