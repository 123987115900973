import {
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from '@material-ui/core';
import { Inline, useSnackbarStack } from '@superdispatch/ui';
import { Box, Button, TextBox } from '@superdispatch/ui-lab';
import { useState } from 'react';
import { DispatcherDTO } from 'shared/modules/dispatcher/DispatcherDTO';
import { useNavigate } from 'shared/routing/react-router-6';
import { trackDispatchersEvent } from '../data/DispatchersAnalytics';
import {
  useAcceptDispatcherRequest,
  useDeclineDispatcherRequest,
} from '../data/DispatchersAPI';

interface DispatcherJoinRequestBannerProps {
  currentDispatcher: DispatcherDTO;
}

export function DispatcherJoinRequestBanner({
  currentDispatcher,
}: DispatcherJoinRequestBannerProps) {
  const navigate = useNavigate();
  const { addSnackbar } = useSnackbarStack();
  const [isOpen, setOpen] = useState<boolean>(false);
  const { mutate: acceptJoinRequest, isLoading: isAcceptLoading } =
    useAcceptDispatcherRequest({
      onSuccess: () => {
        addSnackbar('New dispatcher added', { variant: 'success' });
        trackDispatchersEvent({
          name: 'Carrier Accepted Join Request Of Dispatcher',
        });
        handleClose();
      },
    });
  const { mutate: declineJoinRequest, isLoading: isDeclineLoading } =
    useDeclineDispatcherRequest({
      onSuccess: () => {
        trackDispatchersEvent({
          name: 'Carrier Declined Join Request Of Dispatcher',
        });
        handleClose();
        navigate('/dispatchers');
      },
    });

  function handleClose() {
    setOpen(false);
  }

  return (
    <>
      <Dialog open={isOpen} onClose={handleClose}>
        <DialogContent>
          <Typography variant="h3">
            Do you want to decline request from {currentDispatcher.name}?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Inline space="small">
            <Button
              disabled={isDeclineLoading}
              variant="neutral"
              onClick={() => {
                setOpen(false);
              }}
            >
              No
            </Button>
            <Button
              pending={isDeclineLoading}
              variant="critical"
              onClick={() => {
                declineJoinRequest(currentDispatcher.guid);
              }}
            >
              Yes, Decline
            </Button>
          </Inline>
        </DialogActions>
      </Dialog>
      <Box
        width="100%"
        borderTopWidth="small"
        borderTopColor="Silver400"
        paddingLeft="large"
        paddingRight="large"
        paddingTop="small"
        paddingBottom="small"
      >
        <Inline space="medium" verticalAlign="center" horizontalAlign="center">
          <TextBox color="primary" variant="body">
            <b>{currentDispatcher.name}</b> is requesting to join your company
          </TextBox>
          <Inline space="small" verticalAlign="center" horizontalAlign="center">
            <Button
              disabled={isAcceptLoading}
              variant="critical"
              onClick={() => {
                setOpen(true);
              }}
            >
              Decline
            </Button>
            <Button
              pending={isAcceptLoading}
              disabled={isDeclineLoading}
              onClick={() => {
                acceptJoinRequest(currentDispatcher.guid);
              }}
            >
              Accept
            </Button>
          </Inline>
        </Inline>
      </Box>
    </>
  );
}
