import { MenuItem } from '@material-ui/core';
import {
  FormikCheckboxField,
  FormikContextTypeEnhanced,
  FormikCurrencyField,
  FormikDateField,
  FormikTextField,
} from '@superdispatch/forms';
import { Column, Columns, Stack } from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import { ReactNode, useMemo } from 'react';
import { useAppFormik } from 'shared/form/AppFormik';
import {
  FormikDrawerActions,
  FormikDrawerContent,
} from 'shared/form/FormikDrawer';
import { FormikFileDropZone } from 'shared/form/FormikFileDropZone';
import { ContentProgressIndicator } from 'shared/layout/ContentProgressIndicator';
import { performNavigation } from 'shared/routing/NavigationBlock';
import { useExpensesAPI } from '../data/ExpensesAPI';
import {
  LoadExpenseEditDTO,
  loadExpenseEditSchema,
} from '../data/LoadActionsDTO';
import {
  formatLoadExpenseType,
  formatLoadStatus,
  LOAD_EXPENSE_TYPES,
} from '../data/LoadDTO';
import { trackLoadsEvent } from '../data/LoadsAnalytics';
import { useLoadDetails, useLoadsCache } from '../data/LoadsAPI';
import { useLoadStageFromBackURL } from '../view/ViewLoadHeaderActions';

interface LoadExpenseDrawerContentProps {
  title: ReactNode;
  onClose: () => void;
  formik: FormikContextTypeEnhanced<LoadExpenseEditDTO, unknown>;
}

function LoadExpenseDrawerContent({
  title,
  formik,
  onClose,
}: LoadExpenseDrawerContentProps) {
  return (
    <FormikDrawerContent
      title={title}
      formik={formik}
      onClose={onClose}
      disableAutoClose={true}
      actions={<FormikDrawerActions />}
    >
      <Stack space="large">
        <Box maxWidth={['auto', '378px']}>
          <Stack space="small">
            <Columns space="small">
              <Column>
                <FormikTextField
                  name="type"
                  label="Type"
                  fullWidth={true}
                  select={true}
                  onChange={(e) => {
                    if (e.target.value !== 'other') {
                      void formik.setFieldValue('name', '');
                    }
                  }}
                >
                  {LOAD_EXPENSE_TYPES.map((expenseType) => (
                    <MenuItem key={expenseType} value={expenseType}>
                      {formatLoadExpenseType(expenseType)}
                    </MenuItem>
                  ))}
                </FormikTextField>
              </Column>

              <Column width="content">
                <Box width="80px">
                  <FormikCurrencyField
                    name="price"
                    label="Price"
                    fullWidth={true}
                  />
                </Box>
              </Column>

              <Column width="content">
                <Box width={['168px', '144px']}>
                  <FormikDateField
                    name="date"
                    fullWidth={true}
                    label="Receipt Date"
                  />
                </Box>
              </Column>
            </Columns>

            {formik.values.type === 'other' && (
              <FormikTextField
                name="name"
                label="Specify Type"
                fullWidth={true}
                multiline={true}
                rows={2}
              />
            )}
          </Stack>
        </Box>

        <FormikFileDropZone name="receipt_url">
          {formik.values.receipt_url
            ? 'Change Receipt File'
            : 'Upload Receipt File'}
        </FormikFileDropZone>

        <Stack space="xsmall">
          <FormikCheckboxField
            name="show_on_invoice"
            label="Show expense on invoice"
          />

          <FormikCheckboxField
            name="deduct_from_driver_pay"
            label="Deduct from driver pay"
          />
        </Stack>
      </Stack>
    </FormikDrawerContent>
  );
}

export interface AddLoadExpenseDrawerContentProps {
  loadGUID: string;
  onClose: () => void;
}

export function AddLoadExpenseDrawerContent({
  onClose,
  loadGUID,
}: AddLoadExpenseDrawerContentProps) {
  const { addExpense } = useExpensesAPI();
  const { invalidateLoads } = useLoadsCache();
  const { data } = useLoadDetails(loadGUID);
  const loadStage = useLoadStageFromBackURL();

  const formik = useAppFormik({
    initialValues: { load_guid: loadGUID },
    validationSchema: loadExpenseEditSchema,
    onSubmit(values) {
      return addExpense(values);
    },
    onSubmitSuccess(_, values) {
      if (values.type === 'other') {
        trackLoadsEvent({ name: 'CTMS: Selected Other Expense Type' });
      }

      if (values.name) {
        trackLoadsEvent({ name: 'CTMS: Added Other Expense Type Description' });
      }

      trackLoadsEvent({
        name: 'Carrier Edited Load',
        utm_medium: loadStage,
        utm_content: 'View Page',
        load_status: formatLoadStatus(data?.status),
        is_created_by_broker: !!data?.is_created_by_broker,
        expenses: data?.expenses?.length ? 'Edited' : 'Added',
      });

      invalidateLoads();
      performNavigation(onClose);
    },
    getErrorMessage(error) {
      return (
        error.message ||
        'Something went wrong while adding expense, contact support.'
      );
    },
    getSuccessMessage() {
      return 'Successfully added new expense.';
    },
  });

  return (
    <LoadExpenseDrawerContent
      formik={formik}
      onClose={onClose}
      title="Add Expense"
    />
  );
}

export interface EditLoadExpenseDrawerContentProps {
  loadGUID: string;
  expenseGUID: string;
  onClose: () => void;
}

export function EditLoadExpenseDrawerContent({
  onClose,
  loadGUID,
  expenseGUID,
}: EditLoadExpenseDrawerContentProps) {
  const { editExpense } = useExpensesAPI();
  const { data } = useLoadDetails(loadGUID);
  const { invalidateLoads } = useLoadsCache();
  const expense = useMemo(
    () => data?.expenses?.find((item) => item.guid === expenseGUID),
    [data?.expenses, expenseGUID],
  );
  const loadStage = useLoadStageFromBackURL();
  const formik = useAppFormik({
    initialValues: { ...expense, load_guid: loadGUID },
    validationSchema: loadExpenseEditSchema,
    onSubmit(values) {
      return editExpense(values);
    },
    onSubmitSuccess(_, values) {
      if (values.type === 'other' && values.type !== expense?.type) {
        trackLoadsEvent({ name: 'CTMS: Selected Other Expense Type' });
      }

      if (values.name && values.name !== expense?.name) {
        trackLoadsEvent({ name: 'CTMS: Added Other Expense Type Description' });
      }

      trackLoadsEvent({
        name: 'Carrier Edited Load',
        utm_medium: loadStage,
        utm_content: 'View Page',
        load_status: formatLoadStatus(data?.status),
        is_created_by_broker: !!data?.is_created_by_broker,
        expenses: 'Edited',
      });

      invalidateLoads();
      performNavigation(onClose);
    },
    getErrorMessage(error) {
      return (
        error.message ||
        'Something went wrong while updating expense, contact support.'
      );
    },
    getSuccessMessage() {
      return 'Successfully updated expense.';
    },
  });

  if (!expense) {
    return <ContentProgressIndicator />;
  }

  return (
    <LoadExpenseDrawerContent
      formik={formik}
      onClose={onClose}
      title="Edit Expense"
    />
  );
}
