import { Typography } from '@material-ui/core';
import { Info } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import { useFormikEnhanced } from '@superdispatch/forms';
import {
  Color,
  Column,
  Columns,
  DrawerActions,
  Stack,
  useSnackbarStack,
} from '@superdispatch/ui';
import {
  Alert,
  Box,
  Button,
  DescriptionLineItem,
  TextBox,
} from '@superdispatch/ui-lab';
import { useMemo, useState } from 'react';
import { FormikDrawer, FormikDrawerContent } from 'shared/form/FormikDrawer';
import { formatCurrency } from 'shared/helpers/IntlHelpers';
import { useDebouncedValue } from 'shared/helpers/ReactHelpers';
import { useFlag } from 'shared/settings/FeatureToggles';
import { joinStrings } from 'shared/utils/StringUtils';
import { trackSubscriptionEvent } from '../data/SubscriptionAnalytics';
import {
  useBillingAddress,
  useEstimateSeatsUpgrade,
  useSubscriptionAPI,
  useSubscriptionSettings,
} from '../SubscriptionAPI';
import { PlanUpdateDrawerLoadingContent } from './PlanUpdateDrawerLoadingContent';
import { getPerDriverPrice, SeatBasedPricingBox } from './SeatBasedPricingBox';

export function useNextPlanEstimate() {
  const { data: subscription } = useSubscriptionSettings();

  return useEstimateSeatsUpgrade(Number(subscription?.plan?.seats_count) + 1, {
    enabled: false,
    refetchInterval: false,
    refetchOnWindowFocus: false,
    cacheTime: 0,
    staleTime: 0,
  });
}

export function useShowPlanUpdateDrawer() {
  const { data: subscription } = useSubscriptionSettings();
  const shouldShowSeatBasedPricing = useFlag('seats_based_pricing');
  const driversCount = subscription?.plan?.drivers_count || 1;
  const seatsCount = subscription?.plan?.seats_count || 1;
  const isTrial = subscription?.status === 'In Trial';
  const isCanceled = subscription?.status === 'Canceled';

  return {
    shouldShowPlanUpdateDrawer:
      shouldShowSeatBasedPricing &&
      driversCount >= seatsCount &&
      !isTrial &&
      !isCanceled,
  };
}

interface PlanUpdateDrawerProps {
  open: boolean;
  onClose: () => void;
  onSubmitSuccess: () => void;
}

export function PlanUpdateDrawer({
  open,
  onClose,
  onSubmitSuccess,
}: PlanUpdateDrawerProps) {
  return (
    <FormikDrawer open={open} onClose={onClose}>
      <PlanUpdateDrawerContent
        onClose={onClose}
        onSubmitSuccess={onSubmitSuccess}
      />
    </FormikDrawer>
  );
}

interface PlanUpdateDrawerContentProps {
  onClose: () => void;
  onSubmitSuccess: () => void;
}

function PlanUpdateDrawerContent({
  onClose,
  onSubmitSuccess,
}: PlanUpdateDrawerContentProps) {
  const { data: subscription, isLoading: isSubscriptionSettingsLoading } =
    useSubscriptionSettings();
  const { data: billingAddress, isLoading: isBillingAddressLoading } =
    useBillingAddress();
  const { upgradeSeats } = useSubscriptionAPI();
  const { addSnackbar } = useSnackbarStack();
  const [errorMsg, setErrorMsg] = useState<string>();

  const initialSeatsCount = useMemo(() => {
    if (subscription?.plan) {
      if (
        subscription.plan.seats_count === 1 &&
        subscription.plan.drivers_count === 0
      ) {
        return subscription.plan.seats_count;
      }
      return (subscription.plan.seats_count || 1) + 1;
    }
    return 1;
  }, [subscription]);

  const formik = useFormikEnhanced({
    initialValues: {
      seats_count: initialSeatsCount,
    },
    onSubmit(values) {
      return upgradeSeats(values.seats_count);
    },
    validate: ({ seats_count }) => {
      if (
        !seats_count ||
        seats_count < (subscription?.plan?.seats_count || 1) + 1
      ) {
        return {
          seats_count:
            'Please enter a number greater than the current number of drivers',
        };
      }
      return {};
    },
    onSubmitSuccess(_, { seats_count }) {
      trackSubscriptionEvent({ name: 'Carrier Updated Seats' });
      if (subscription?.plan) {
        const perDriverPrice = getPerDriverPrice(
          seats_count,
          subscription.plan,
        );

        addSnackbar(
          `Plan updated to ${seats_count} drivers ${formatCurrency(
            perDriverPrice * seats_count,
          )}/month`,
          {
            variant: 'success',
          },
        );
      }
      onSubmitSuccess();
    },
    onSubmitFailure: (error) => {
      setErrorMsg(error.message);
    },
  });
  const { values } = formik;
  const seatsCount = subscription?.plan?.seats_count || 1;
  const hasCreditCard = !!subscription?.card_information?.card_number;
  const cardDetails = subscription?.card_information;
  const isDisabledMinus = formik.values.seats_count <= seatsCount + 1;

  const isLoading = isSubscriptionSettingsLoading || isBillingAddressLoading;
  const debouncedSeatsCount = useDebouncedValue(values.seats_count, 500);

  return (
    <FormikDrawerContent
      onClose={onClose}
      formik={formik}
      title="Update Your Plan?"
      actions={
        !isLoading && (
          <PlanUpdateDrawerActions
            seats_count={debouncedSeatsCount}
            isSubmitting={formik.isSubmitting}
            errorMsg={errorMsg}
          />
        )
      }
    >
      {isLoading ? (
        <PlanUpdateDrawerLoadingContent />
      ) : (
        <Box maxWidth="430px">
          <Stack space="large">
            <Stack space="small">
              <Box
                padding="xsmall"
                borderRadius="small"
                backgroundColor="Blue50"
              >
                <Columns align="top" space="xsmall">
                  <Column width="content">
                    <Info fontSize="small" htmlColor={Color.Blue300} />
                  </Column>
                  <Column width="fluid">
                    <Stack space="xxsmall">
                      <Typography variant="body1">
                        Current Carrier TMS Plan: {seatsCount}{' '}
                        {seatsCount > 1 ? 'Drivers' : 'Driver'}
                      </Typography>
                      <Typography>
                        Inviting a new driver will update your plan. <br />
                        To avoid changing your plan, replace a driver by
                        deactivating any driver first and then inviting.
                      </Typography>
                    </Stack>
                  </Column>
                </Columns>
              </Box>
              <SeatBasedPricingBox
                isMinusDisabled={isDisabledMinus}
                isReadOnly={true}
              />
            </Stack>

            <Box paddingBottom="small">
              <Stack space="large">
                {hasCreditCard && (
                  <Stack space="xsmall">
                    <Typography color="textSecondary" variant="h6">
                      CARD DETAILS
                    </Typography>
                    <Typography color="textSecondary">
                      Card Number: {cardDetails?.card_number}
                    </Typography>
                    <Typography color="textSecondary">
                      Expiration Date: {cardDetails?.expiry}
                    </Typography>
                  </Stack>
                )}

                <Stack space="xsmall">
                  <Typography color="textSecondary" variant="h6">
                    Billing Address
                  </Typography>
                  <Typography color="textSecondary">
                    {joinStrings(
                      ', ',
                      billingAddress?.billing_address,
                      joinStrings(
                        ' ',
                        billingAddress?.billing_city,
                        billingAddress?.billing_state,
                        billingAddress?.billing_zip,
                      ),
                      billingAddress?.billing_country,
                    )}
                  </Typography>
                </Stack>
              </Stack>
            </Box>
          </Stack>
        </Box>
      )}
    </FormikDrawerContent>
  );
}

interface PlanUpdateDrawerActionsProps {
  isSubmitting: boolean;
  seats_count: number;
  errorMsg?: string;
}

function PlanUpdateDrawerActions({
  isSubmitting,
  seats_count,
  errorMsg,
}: PlanUpdateDrawerActionsProps) {
  const { data: taxPrice, isLoading } = useEstimateSeatsUpgrade(seats_count);

  return (
    <DrawerActions>
      <Stack space="medium">
        <Stack space="large">
          <Stack space="small">
            <Stack space="xxsmall">
              <DescriptionLineItem
                title={
                  <TextBox variant="body" color="primary">
                    Plan Update
                  </TextBox>
                }
              >
                {isLoading ? (
                  <Skeleton width="60px" height="20px" />
                ) : (
                  <TextBox align="right" color="primary">
                    {formatCurrency(taxPrice?.sub_total)}
                  </TextBox>
                )}
              </DescriptionLineItem>

              <TextBox color="secondary">Prorated</TextBox>
            </Stack>

            <Stack space="xsmall">
              <DescriptionLineItem
                title={
                  <TextBox variant="body" color="primary">
                    Subtotal
                  </TextBox>
                }
              >
                {isLoading ? (
                  <Skeleton width="60px" height="20px" />
                ) : (
                  <TextBox align="right" color="primary">
                    {formatCurrency(taxPrice?.sub_total)}
                  </TextBox>
                )}
              </DescriptionLineItem>
              {taxPrice?.taxes && taxPrice.taxes.length > 0 && (
                <Columns>
                  <Column>
                    <Box />
                  </Column>
                  <Column width="2/3">
                    <Stack align="right" space="xxsmall">
                      {taxPrice.taxes.map((tax) => {
                        return (
                          <DescriptionLineItem
                            key={tax.name}
                            title={
                              <TextBox color="secondary">
                                {tax.description}
                              </TextBox>
                            }
                          >
                            <TextBox align="right" color="secondary">
                              {formatCurrency(tax.amount)}
                            </TextBox>
                          </DescriptionLineItem>
                        );
                      })}
                    </Stack>
                  </Column>
                </Columns>
              )}
            </Stack>

            <DescriptionLineItem
              title={<TextBox variant="heading-4">Total Due Today</TextBox>}
            >
              {isLoading ? (
                <Skeleton width="64px" height="24px" />
              ) : (
                <TextBox variant="heading-4" align="right">
                  {formatCurrency(taxPrice?.total)}
                </TextBox>
              )}
            </DescriptionLineItem>
          </Stack>

          <Button
            size="large"
            fullWidth={true}
            type="submit"
            pending={isSubmitting}
          >
            Update Now
          </Button>
        </Stack>
        {errorMsg && (
          <Box maxWidth="430px">
            <Alert severity="critical">{errorMsg}</Alert>
          </Box>
        )}
      </Stack>
    </DrawerActions>
  );
}
