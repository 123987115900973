import {
  yupArray,
  yupBoolean,
  yupEnum,
  yupNumber,
  yupObject,
  yupString,
} from 'shared/utils/YupUtils';
import { InferType } from 'yup';
import { VERIFICATION_STATUS } from './data/VerificationStatusDTO';

export type VerifiedCarrierApplicationStatusDTO = InferType<
  typeof verifiedCarrierApplicationStatusSchema
>;
export const verifiedCarrierApplicationStatusSchema = yupObject({
  verified_carrier_application: yupObject({
    status: yupEnum(VERIFICATION_STATUS).required(),
    progress: yupObject({
      carrier_info_step_is_complete: yupBoolean().required(),
      cargo_insurance_step_is_complete: yupBoolean().required(),
      w9_step_is_complete: yupBoolean().required(),
      usdot_step_is_complete: yupBoolean().required(),
      fmcsa_phone_verification_is_complete: yupBoolean().required(),
      transporter_plate_registration_is_complete: yupBoolean().required(),
      state_motor_carrier_permit_is_complete: yupBoolean().required(),
      business_license_is_complete: yupBoolean().required(),
      is_completed: yupBoolean().required(),
      percentage: yupNumber().required(),
    }),
    declining_reason: yupArray(yupString()),
    other_declining_reason: yupString(),
    declining_reasons: yupObject({
      cargo_insurance_file_url: yupArray(yupString()).optional(),
      w9_form_url: yupArray(yupString()).optional(),
      usdot_certificate_url: yupArray(yupString()).optional(),
      reliability_score: yupArray(yupString()).optional(),
      transporter_plate_registration: yupArray(yupString()).optional(),
      state_motor_carrier_permit: yupArray(yupString()).optional(),
      business_license: yupArray(yupString()).optional(),
    }).nullable(),
    external_notes: yupObject({
      w9: yupString().optional(),
      usdot_certificate: yupString().optional(),
      cargo_insurance: yupString().optional(),
      low_reliability: yupString().optional(),
      terms_and_conditions: yupString().optional(),
      transporter_plate_registration: yupString().optional(),
      state_motor_carrier_permit: yupString().optional(),
      business_license: yupString().optional(),
    }).nullable(),
    revoking_reasons: yupObject({
      cargo_insurance_file_url: yupArray(yupString()).optional(),
      usdot_certificate_url: yupArray(yupString()).optional(),
      terms_and_conditions: yupArray(yupString()).optional(),
      fmcsa_phone: yupArray(yupString()).optional(),
    }).nullable(),
    non_verifiable_reasons: yupObject({
      business_location_is_invalid: yupArray(yupString()).optional(),
      company_type_is_invalid: yupArray(yupString()).optional(),
    }).nullable(),
  }),
});
