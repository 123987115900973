import { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { LocalStore } from 'shared/helpers/Store';
import { ONBOARDING_FIRST_LOAD_TOUR_STORAGE_KEY } from 'shared/modules/onboarding/data/OnboardingConstants';
import { AddLoadPage } from './add/AddLoadPage';
import { listenToLoadEvents } from './data/LoadEvents';
import { LoadsContextProvider } from './data/LoadsContext';
import { EditLoadPage } from './edit-page/EditLoadPage';
import { LoadsPageContextProvider } from './loads-page/data/LoadsPageParamsContext';
import { LoadsPage } from './loads-page/LoadsPage';
import { ViewLoadPage } from './view/ViewLoadPage';
import { ViewLoadPagePrintPage } from './view/ViewLoadPagePrintPage';

export function LoadsContainerPage() {
  useEffect(() => {
    listenToLoadEvents((event) => {
      if (event === 'created' || event === 'imported') {
        LocalStore.set(ONBOARDING_FIRST_LOAD_TOUR_STORAGE_KEY, 'pending');
      }
    });
  }, []);

  return (
    <LoadsContextProvider>
      <Switch>
        <Route path="/loads/add">
          <AddLoadPage />
        </Route>

        <Route path="/loads/:loadGUID/edit">
          <EditLoadPage />
        </Route>

        <Route path="/loads/:loadGUID/print">
          <ViewLoadPagePrintPage />
        </Route>

        <Route path="/loads/:loadGUID">
          <ViewLoadPage />
        </Route>

        <Route path="/loads">
          <LoadsPageContextProvider>
            <LoadsPage />
          </LoadsPageContextProvider>
        </Route>
      </Switch>
    </LoadsContextProvider>
  );
}
