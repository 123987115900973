import {
  IconButton,
  InputAdornment,
  MenuItem,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { InfoRounded, Refresh } from '@material-ui/icons';
import {
  FormikCheckboxField,
  FormikTextField,
  useFormikEnhanced,
} from '@superdispatch/forms';
import { Color, Inline, Stack, useSnackbarStack } from '@superdispatch/ui';
import { Box, Button } from '@superdispatch/ui-lab';
import { Form, FormikProvider } from 'formik';
import { useClipboard } from 'shared/helpers/DomHelpers';
import { ContentCopyIcon } from 'shared/icons';
import {
  useCheckServiceItemsListMutation,
  useImportQBDContactsMutation,
  useQBCompanyMutation,
  useQuickbookDesktopConnectionDetails,
  useQuickbookDesktopConnectionInfo,
  useUpdateServiceItem,
} from '../data/SettingsAPI';
import {
  downloadQwcUrl,
  findServiceId,
  updateDateToString,
} from '../data/SettingsUtils';

export function SettingsQuickbooksDesktopForm() {
  const { addSnackbar } = useSnackbarStack();
  const { copy } = useClipboard();
  const { data: desktop } = useQuickbookDesktopConnectionDetails();
  const { data: info } = useQuickbookDesktopConnectionInfo();
  const { mutate: updateServiceItem } = useUpdateServiceItem({}, 'desktop');
  const { mutate: updateCompany } = useQBCompanyMutation({}, 'desktop');
  const { mutate: checkServiceItemsList, isLoading: isChecking } =
    useCheckServiceItemsListMutation({
      onSuccess: (data) => {
        addSnackbar(data.user_message, { variant: 'success' });
      },
    });
  const { mutate: importContacts, isLoading } = useImportQBDContactsMutation({
    onSuccess: (data) => {
      addSnackbar(data.user_message, { variant: 'success' });
    },
  });

  const formik = useFormikEnhanced({
    enableReinitialize: true,
    initialValues: {
      service_qbd: findServiceId(desktop?.service_items, info?.invoice_item),
      create_customers_in_qbd: desktop?.is_allowed_to_create_customer,
      qbd_password: desktop?.password,
    },
    onSubmit: () => {
      importContacts();
    },
  });

  const { qbd_password } = formik.values;

  function downloadQWC() {
    if (desktop?.qwc_url) {
      downloadQwcUrl(desktop.qwc_url);
    }
  }

  const lastUpdated = updateDateToString(
    desktop?.service_items_last_updated_at,
  );
  const lastImport = updateDateToString(desktop?.customers_last_updated_at);

  return (
    <FormikProvider value={formik}>
      <Form>
        <Stack space="large">
          <Stack space="xsmall">
            <Typography variant="h5">
              How to send invoice to QuickBooks?
            </Typography>
            <Typography>
              Make sure QuickBooks Web Connector is turned on. Choose the
              service item you want below. Go to the Loads page, click Options →
              Send Invoice. Select the QuickBooks Desktop option. After filling
              all required information click Send Invoice.
            </Typography>
          </Stack>
          <Stack space="small">
            <Inline space="small" verticalAlign="bottom">
              <FormikTextField
                label="Password for Web Connector"
                aria-label="qbd-password"
                disabled={true}
                name="qbd_password"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => {
                          if (qbd_password) {
                            copy(qbd_password);
                            addSnackbar('Copied!');
                          }
                        }}
                      >
                        <ContentCopyIcon htmlColor={Color.Dark100} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              <Inline verticalAlign="center" space="xsmall">
                <Button onClick={downloadQWC} variant="neutral">
                  Download QWC File
                </Button>
                <Tooltip
                  interactive={true}
                  title="QuickBooks Web Connector (QWC) file. QWC file allows us to connect your Super Dispatch account with QuickBooks Desktop account."
                >
                  <InfoRounded htmlColor={Color.Dark100} />
                </Tooltip>
              </Inline>
            </Inline>
            <Inline space="small" verticalAlign="bottom">
              <FormikTextField
                label="Service Item"
                aria-label="service-list-input"
                fullWidth={true}
                name="service_qbd"
                helperText={
                  <Typography color="textSecondary" variant="caption">
                    For invoice items and syncing them to QuickBooks
                  </Typography>
                }
                select={true}
                onChange={(event) => {
                  updateServiceItem(Number(event.target.value));
                }}
              >
                {desktop?.service_items?.map((service) => (
                  <MenuItem key={service.id} value={Number(service.id)}>
                    {service.name}
                  </MenuItem>
                ))}
              </FormikTextField>
              <Stack space="xxsmall">
                <Button
                  onClick={() => {
                    checkServiceItemsList();
                  }}
                  startIcon={<Refresh />}
                  pending={isChecking}
                  variant="neutral"
                >
                  Update Service Items List
                </Button>
                <Typography color="textSecondary" variant="caption">
                  Last update was {lastUpdated}
                </Typography>
              </Stack>
            </Inline>
          </Stack>

          <Stack space="small">
            <Stack space="xxsmall">
              <Button pending={isLoading} type="submit" variant="neutral">
                Import Contacts from QuickBooks
              </Button>
              <Typography color="textSecondary" variant="caption">
                Last import was {lastImport}
              </Typography>
            </Stack>
            <Stack space="none">
              <FormikCheckboxField
                name="create_customers_in_qbd"
                label="Automatically create customers inside QuickBooks Desktop"
                onChange={(event) => {
                  updateCompany(event.target.checked);
                }}
              />
              <Box paddingLeft="large">
                <Typography variant="caption" color="textSecondary">
                  A new contact will be created if there is no the same customer
                  name in QuickBooks Desktop
                </Typography>
              </Box>
            </Stack>
          </Stack>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
