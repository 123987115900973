import { CircularProgress } from '@material-ui/core';
import styled from 'styled-components';

interface ContainerProps {
  minHeight?: number | string;
}

const Container = styled.div<ContainerProps>`
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: ${({ minHeight }) =>
    typeof minHeight == 'string'
      ? minHeight
      : typeof minHeight == 'number'
      ? `${minHeight}px`
      : 'auto'};
`;

export interface ContentProgressIndicatorProps extends ContainerProps {
  progressSize?: number;
}

export function ContentProgressIndicator({
  minHeight,
  progressSize = 50,
}: ContentProgressIndicatorProps) {
  return (
    <Container minHeight={minHeight}>
      <CircularProgress color="primary" size={progressSize} />
    </Container>
  );
}
