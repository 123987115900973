import { useEffect } from 'react';
import { LocalStore, useLocalStore } from 'shared/helpers/Store';
import { openExternalURL } from 'shared/helpers/URLHelpers';
import { useExperiments, useExperimentState } from '../ExperimentsAPI';

const REDIRECT_TO_SLB_EXPERIMENT_STATUS_KEY =
  'redirect_to_slb_experiment_status';

export function useRedirectToSLBExperiment() {
  const { isInExperiment, markAsViewed } =
    useExperimentState('Redirect To SLB');
  const { refetch } = useExperiments();
  const redirectToSLBExperimentStatus = useLocalStore<string>(
    REDIRECT_TO_SLB_EXPERIMENT_STATUS_KEY,
  );

  useEffect(() => {
    if (isInExperiment && redirectToSLBExperimentStatus !== 'redirected') {
      openExternalURL('/tms/loads');
      LocalStore.set(REDIRECT_TO_SLB_EXPERIMENT_STATUS_KEY, 'redirected');
    }
  }, [isInExperiment, refetch, markAsViewed, redirectToSLBExperimentStatus]);
}
