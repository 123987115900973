import { QueryBuilder } from '@material-ui/icons';
import { Color } from '@superdispatch/ui';
import { Box, SidebarMenuItemAvatar } from '@superdispatch/ui-lab';
import { PersonAddAlt } from 'shared/icons/PersonAddAlt';
import { PersonDeactivatedIcon } from 'shared/icons/PersonDeactivatedIcon';
import styled from 'styled-components';
import { DriverDTO } from '../data/DriverDTO';
import { getDriverLabel } from '../data/DriverPageUtils';

interface DriverAvatarProps {
  driver: DriverDTO;
}

const StyledBox = styled(Box)`
  border-radius: 50%;
`;

export function DriverAvatar({ driver }: DriverAvatarProps) {
  const inviteDirection = driver.connection_type;
  const inviteStatus = driver.connection_status;
  const driverLabel = getDriverLabel(driver);

  if (inviteDirection === 'join_request' && inviteStatus === 'pending') {
    return (
      <StyledBox backgroundColor="Blue300" padding="xsmall">
        <PersonAddAlt htmlColor={Color.White} fontSize="small" />
      </StyledBox>
    );
  }

  if (inviteDirection === 'invite' && inviteStatus === 'pending') {
    return (
      <StyledBox
        borderWidth="small"
        borderColor="Yellow300"
        backgroundColor="Yellow50"
        padding="xsmall"
      >
        <QueryBuilder fontSize="small" htmlColor={Color.Yellow500} />
      </StyledBox>
    );
  }

  if (inviteStatus === 'declined' || inviteStatus === 'deactivated') {
    return (
      <StyledBox
        borderWidth="small"
        borderColor="Silver400"
        backgroundColor="Silver200"
        padding="xsmall"
      >
        <PersonDeactivatedIcon />
      </StyledBox>
    );
  }

  return <SidebarMenuItemAvatar>{driverLabel}</SidebarMenuItemAvatar>;
}
