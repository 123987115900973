import {
  FormattedDate,
  parseDate,
  toPrimitiveDateInput,
} from '@superdispatch/dates';
import { TextBox } from '@superdispatch/ui-lab';
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { LoadSuggestionDTO } from '../data/LoadSuggestionDTO';

export function LoadSuggestionsAvailableDate({
  scheduledAt,
}: {
  scheduledAt: LoadSuggestionDTO['pickup']['scheduled_at'];
}) {
  const primitiveInput = toPrimitiveDateInput(scheduledAt);
  const date = useMemo(() => parseDate(primitiveInput), [primitiveInput]);
  const endOfDay = useMemo(() => DateTime.local().endOf('day'), []);

  if (!date.isValid) {
    return <TextBox color="inherit">No Date</TextBox>;
  }

  if (date <= endOfDay) {
    return <TextBox color="inherit">Available Now</TextBox>;
  }

  return (
    <TextBox color="inherit">
      Available <FormattedDate date={date} variant="ShortDate" />
    </TextBox>
  );
}
