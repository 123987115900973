import { CircularProgress, Typography } from '@material-ui/core';
import { Error } from '@material-ui/icons';
import { Column, Columns, Inline, Stack } from '@superdispatch/ui';
import { Box, Button } from '@superdispatch/ui-lab';
import { APIError, isAPIError } from 'shared/api/APIError';
import { CheckIcon } from 'shared/icons/CheckIcon';

interface LoadSendInvoiceStatusItemProps {
  isLoading: boolean;
  error: Error | null;
  loadingText: string;
  successText: string;
  handleRetry?: () => void;
}

export function LoadSendInvoiceStatusItem({
  isLoading,
  loadingText,
  successText,
  error,
  handleRetry,
}: LoadSendInvoiceStatusItemProps) {
  const errorText = error?.message;
  const errorDescription = isAPIError(error)
    ? formatQbErrorDescription(error)
    : '';

  return (
    <Columns space="small">
      <Column width="content">
        <Box width="25px" height="25px">
          <Inline verticalAlign="center" horizontalAlign="center">
            {isLoading ? (
              <CircularProgress size={18} />
            ) : error ? (
              <Error color="error" />
            ) : (
              <CheckIcon />
            )}
          </Inline>
        </Box>
      </Column>
      <Column width="fluid">
        <Stack space="small">
          <Box>
            {isLoading ? (
              <Typography variant="body1">{loadingText}</Typography>
            ) : error ? (
              <Typography variant="body1">{errorText}</Typography>
            ) : (
              <Typography variant="body1">{successText}</Typography>
            )}
            {errorText !== errorDescription && errorDescription && error && (
              <Box>
                <Typography>{errorDescription}</Typography>
              </Box>
            )}
          </Box>
          <Box>
            {error && (
              <Inline>
                <Button onClick={handleRetry} variant="neutral">
                  Try Again
                </Button>
              </Inline>
            )}
          </Box>
        </Stack>
      </Column>
    </Columns>
  );
}

export function formatQbErrorDescription(qbError: APIError): string {
  if (!!qbError.context && Array.isArray(qbError.context)) {
    return (
      qbError.context
        .filter((item) => typeof item === 'string')
        .map(String)
        .slice(0, 1)
        .pop() || ''
    );
  }
  return '';
}
