import { chooseUTMSource } from 'shared/helpers/Analytics';
import {
  BankAccountFailedBanner,
  BankAccountVerificationBanner,
  getOnboardingLink,
  SuperPayNudgingBannerProps,
} from 'shared/modules/superpay/SuperPayNudgingBanner';
import { usePublicOfferPageContext } from './PublicOfferPageContext';

export function PublicOfferSuperPayNudgingBanner({
  source,
}: SuperPayNudgingBannerProps) {
  const mainLink = getOnboardingLink();
  const link = `${mainLink}account-details/verify-bank-account?utm_medium=${source}&utm_source=${chooseUTMSource()}`;
  const {
    publicOffer: { data },
  } = usePublicOfferPageContext();

  const verificationStatus = data?.superpay.verification;
  const isStatusBankAccountVerification =
    verificationStatus === 'bank_account_verification' &&
    data?.superpay.is_verification_available;
  const isBankAccountFailed =
    verificationStatus === 'bank_account_failed' ||
    verificationStatus === 'bank_account_errored';

  if (isStatusBankAccountVerification) {
    return <BankAccountVerificationBanner link={link} />;
  }

  if (isBankAccountFailed) {
    return <BankAccountFailedBanner />;
  }

  return null;
}
