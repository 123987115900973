import { normalizeURL } from '@superdispatch/uri';
import { parse, stringify } from 'qs';

export type URLSearchQuery = Record<string, string | undefined>;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type URLSearchQueryInit = Record<string, any>;

export function parseSearchQuery(search: string): URLSearchQuery {
  return parse(search, {
    ignoreQueryPrefix: true,
    arrayLimit: 100,
  }) as URLSearchQuery;
}

export function stringifySearchQuery(query: URLSearchQueryInit): string {
  return stringify(query, {
    skipNulls: true,
    sort: (a: string, b: string) =>
      a.toLowerCase().localeCompare(b.toLowerCase()),
  });
}

export function updateSearchQuery(
  search: string,
  updater: (query: URLSearchQuery) => URLSearchQueryInit,
): string {
  const query = parseSearchQuery(search);
  const updated = updater(query);

  return stringifySearchQuery(updated);
}

export function parseURLQuery(input: unknown): URLSearchQuery {
  const normalized = normalizeURL(input);

  if (normalized) {
    try {
      const url = new URL(normalized);

      return Object.fromEntries(url.searchParams);
    } catch {}
  }

  return {};
}
