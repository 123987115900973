import { Typography } from '@material-ui/core';
import { InfoCard, Stack } from '@superdispatch/ui';
import { Box, Button, TextBox } from '@superdispatch/ui-lab';
import { useFormikContext } from 'formik';
import { useQuery } from 'shared/helpers/RoutingHelpers';
import { useSuperPaySettings } from '../data/SuperPayAPI';
import { ProfilePageLayout } from '../ProfilePageLayout';
import { BankAccountForm, useBankAccountForm } from './BankAccountForm';

function BankAccountContent() {
  const { submitForm, isSubmitting, dirty } = useFormikContext();
  return (
    <Stack space="small">
      <TextBox color="secondary">
        To enable payments, we are legally required to collect information
        below. This information is not stored by Super Dispatch and only used to
        verify the business.
      </TextBox>
      <InfoCard>
        <Stack>
          <Typography variant="h3">Bank Account</Typography>
          <Stack space="small">
            <BankAccountForm />
            <Button
              onClick={submitForm}
              pending={isSubmitting}
              disabled={!dirty}
            >
              Submit
            </Button>
          </Stack>
        </Stack>
      </InfoCard>
    </Stack>
  );
}

export function BankAccountPage() {
  const [_, setQuery] = useQuery();
  const { refetch: refetchSuperPaySetting, isFetching } = useSuperPaySettings();

  const formik = useBankAccountForm({
    onComplete: () => {
      setQuery({ open_microdeposit_dialog: true, utm_medium: 'Bank Details' });
    },
    onSettled: () => {
      void refetchSuperPaySetting();
    },
  });

  return (
    <ProfilePageLayout
      title="Setup SuperPay"
      headerEndActions={false}
      isLoading={formik.isSubmitting || isFetching}
      formik={formik}
    >
      <Box
        paddingTop="small"
        maxWidth={['initial', 'initial', '528px']}
        margin="auto"
      >
        <BankAccountContent />
      </Box>
    </ProfilePageLayout>
  );
}
