import { PageHeaderContent, PageLayout } from 'shared/layout/PageLayout';
import { useFlag } from 'shared/settings/FeatureToggles';
import { DispatcherInfoCard } from '../core/DispatcherInforCard';
import { DispatcherJoinRequestBanner } from '../core/DispatcherJoinRequestBanner';
import { DispatcherPageLoader } from '../core/DispatcherPageLoader';
import { DispatchersPageDetailsHeader } from '../core/DispatchersPageDetailsHeader';
import { InvitedDispatcher } from '../core/InvitedDispatcher';
import { useDispatcherPageContext } from '../data/DispatchersProvider';

export function DispatcherPageDetails() {
  const shouldShowJoinRequestBanner = useFlag(
    'dispatcher_management_join_request_banner',
  );
  const { dispatcher } = useDispatcherPageContext();

  const { isFetching, data: currentDispatcher } = dispatcher;
  const hasJoinRequest =
    currentDispatcher?.connection_status === 'pending' &&
    currentDispatcher.connection_type === 'join_request';

  return (
    <PageLayout
      loading={isFetching}
      header={
        <>
          <PageHeaderContent>
            <DispatchersPageDetailsHeader />
          </PageHeaderContent>
          {currentDispatcher &&
            hasJoinRequest &&
            shouldShowJoinRequestBanner && (
              <DispatcherJoinRequestBanner
                currentDispatcher={currentDispatcher}
              />
            )}
        </>
      }
    >
      {currentDispatcher?.connection_status === 'pending' &&
      currentDispatcher.connection_type === 'invite' ? (
        <InvitedDispatcher dispatcher={currentDispatcher} />
      ) : currentDispatcher ? (
        <DispatcherInfoCard currentDispatcher={currentDispatcher} />
      ) : (
        <DispatcherPageLoader />
      )}
    </PageLayout>
  );
}
