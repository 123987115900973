import { CARRIER_LOGISTICS } from 'shared/modules/carrier-profile/CarrierInfoDTO';
import { yupObject, yupString } from 'shared/utils/YupUtils';
import { StringSchema } from 'yup';

export const identityStepEditSchema = yupObject({
  w9_form_url: yupString().required(),
  usdot_certificate_url: yupString().when(
    '$isCarrierWithoutUSDOT',
    (isCarrierWithoutUSDOT: boolean, schema: StringSchema) => {
      return isCarrierWithoutUSDOT ? schema : schema.required();
    },
  ),
  transportation_plate_registration: yupString().when(
    '$logisticsType',
    (
      logisticsType: (typeof CARRIER_LOGISTICS)[number],
      schema: StringSchema,
    ) => {
      return logisticsType === 'driveaway' ? schema.required() : schema;
    },
  ),
  state_motor_carrier_permit: yupString().when(
    ['$logisticsType', '$isCarrierWithoutUSDOT'],
    (
      logisticsType: (typeof CARRIER_LOGISTICS)[number],
      isCarrierWithoutUSDOT: boolean,
      schema: StringSchema,
    ) => {
      return logisticsType !== 'driveaway' && isCarrierWithoutUSDOT
        ? schema.required()
        : schema;
    },
  ),
  business_license: yupString().when(
    ['$logisticsType', '$isCarrierWithoutUSDOT'],
    (
      logisticsType: (typeof CARRIER_LOGISTICS)[number],
      isCarrierWithoutUSDOT: boolean,
      schema: StringSchema,
    ) => {
      return logisticsType === 'driveaway' && isCarrierWithoutUSDOT
        ? schema.required()
        : schema;
    },
  ),
});
