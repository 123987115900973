import { useEffect, useState } from 'react';

const EXTENSION_PACKAGE_ID = 'npciikilfcggljgdkebakdcccmkdmpik';
export const CHROME_EXTENSION_URL = `https://chrome.google.com/webstore/detail/super-dispatch-importer/${EXTENSION_PACKAGE_ID}`;

const checkExtension = (
  id: string,
  src: string,
  callback: (isExisted: boolean) => void,
) => {
  let image = new Image();
  image.src = 'chrome-extension://' + id + '/' + src;
  image.onload = () => {
    callback(true);
  };
  image.onerror = () => {
    callback(false);
  };
};

export function useCheckChromeExtension() {
  const [isInstalled, setInstalled] = useState<boolean>(false);

  useEffect(() => {
    checkExtension(EXTENSION_PACKAGE_ID, 'icons/logo.png', (isExisted) => {
      setInstalled(isExisted);
    });
  }, []);

  return isInstalled;
}
