import { QueryClient, useQuery } from 'react-query';
import { APIPageResponse } from 'shared/api/APIClient';
import {
  APIQueryInput,
  APIQueryOptions,
  APIQueryResult,
} from 'shared/api/APIQuery';
import { THIRTY_SECONDS } from 'shared/constants/NumberConstants';
import { Schema } from 'yup';

export interface APIPageQueryOptions<TData>
  extends APIQueryOptions<APIPageResponse<TData>> {
  schema?: Schema<TData>;
  normalize?: (response: unknown) => TData;
}

export type APIPageQueryResult<TData> = APIQueryResult<APIPageResponse<TData>>;

export function useAPIPageQuery<TData>(
  input: APIQueryInput,
  query: () => Promise<APIPageResponse<TData>>,
  {
    schema,
    normalize,
    keepPreviousData = true,
    staleTime = THIRTY_SECONDS,
    ...options
  }: APIPageQueryOptions<TData> = {},
): APIPageQueryResult<TData> {
  return useQuery(
    input,
    () =>
      query().then((response) => {
        if (schema || normalize) {
          response.data = response.data.map((data) => {
            if (schema) {
              data = schema.cast(data);
            }

            if (normalize) {
              data = normalize(data);
            }

            return data;
          });
        }

        return response;
      }),
    { ...options, staleTime, keepPreviousData },
  );
}

export function findAPIPageQueryItem<TData>(
  input: APIQueryInput,
  queryClient: QueryClient,
  fn: (item: TData) => boolean,
): undefined | TData {
  for (const { state } of queryClient.getQueryCache().findAll(input)) {
    if (state.data) {
      const { data } = state.data as APIPageResponse<TData>;

      for (const item of data) {
        if (fn(item)) {
          return item;
        }
      }
    }
  }

  return undefined;
}
