import { Color } from '@superdispatch/ui';
import { createSvgIcon } from 'shared/icons/IconUtils';

export const LoadOrganizationIcon = createSvgIcon(
  'LoadOrganizationIcon',
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M16.0009 2.66675C20.7388 2.66675 24.611 6.3736 24.8754 11.0454C23.5314 10.4106 22.0293 10.0557 20.4443 10.0557C18.8541 10.0557 17.3474 10.4129 16 11.0516C14.6526 10.4129 13.1459 10.0557 11.5557 10.0557C9.97142 10.0557 8.46995 10.4103 7.12637 11.0445C7.39123 6.37315 11.2632 2.66675 16.0009 2.66675ZM20.4443 11.5557C22.0336 11.5557 23.5254 11.9728 24.8164 12.7035C24.5255 14.9602 23.3883 16.9517 21.7333 18.3495C21.2015 15.7525 19.7007 13.5086 17.6251 12.012C18.5109 11.716 19.4589 11.5557 20.4443 11.5557ZM26.1782 13.6521C25.635 16.3032 24.0819 18.5863 21.9384 20.0818C21.9426 20.2022 21.9447 20.3232 21.9447 20.4446C21.9447 23.9165 20.2416 26.9907 17.6252 28.8771C18.511 29.1731 19.4589 29.3335 20.4443 29.3335C25.0466 29.3335 28.8321 25.8357 29.2873 21.3535C29.3177 21.0547 29.3332 20.7515 29.3332 20.4446C29.3332 20.2744 29.3284 20.1052 29.319 19.9373C29.3077 19.7373 29.2899 19.539 29.2656 19.3427C28.9843 17.0678 27.8435 15.0593 26.1782 13.6521ZM16.0001 28.1445C18.5185 26.6877 20.2559 24.0308 20.4302 20.9558C19.0866 21.59 17.5852 21.9447 16.0009 21.9447C14.4159 21.9447 12.9138 21.5897 11.5697 20.955C11.7438 24.0303 13.4812 26.6875 15.9999 28.1445C15.9999 28.1444 16 28.1444 16 28.1444C16 28.1444 16.0001 28.1444 16.0001 28.1445ZM14.3748 28.8771C11.7584 26.9907 10.0553 23.9165 10.0553 20.4446C10.0553 20.3228 10.0574 20.2014 10.0616 20.0806C7.91868 18.5849 6.3661 16.3018 5.82326 13.6508C3.89278 15.2814 2.66676 17.7197 2.66675 20.4445C2.66675 25.3537 6.64647 29.3335 11.5557 29.3335C12.5411 29.3335 13.489 29.1731 14.3748 28.8771ZM16 12.7447C18.3432 14.1001 20.0103 16.4944 20.3714 19.2978C19.0808 20.0279 17.5895 20.4447 16.0009 20.4447C14.4116 20.4447 12.9197 20.0276 11.6287 19.2969C11.9901 16.4939 13.657 14.1 16 12.7447ZM14.3749 12.012C12.2996 13.5083 10.7989 15.7518 10.2669 18.3483C8.61257 16.9503 7.47586 14.9589 7.18521 12.7025C8.4758 11.9724 9.96709 11.5557 11.5557 11.5557C12.5411 11.5557 13.489 11.716 14.3749 12.012Z"
    fill={Color.Silver500}
  />,
  {
    viewBox: '0 0 32 32',
    style: {
      width: '32px',
      height: '32px',
    },
  },
);
