import { Link, LinkProps } from '@material-ui/core';
import { AnchorButton, LinkedText } from '@superdispatch/ui-lab';
import { ComponentType, forwardRef, MouseEvent } from 'react';
import { MobileAppBridge } from 'shared/data/MobileAppBridge';
import { getOS } from 'shared/helpers/BrowserHelpers';

function makeWebViewLink<
  TProps extends {
    onClick?: (event: MouseEvent<HTMLAnchorElement>) => void;
  },
  TRef,
>(LinkComponent: ComponentType<TProps>) {
  const Component = forwardRef<TRef, TProps>((props: TProps, ref) => (
    <LinkComponent
      {...props}
      ref={ref}
      onClick={(event: MouseEvent<HTMLAnchorElement>) => {
        props.onClick?.(event);
        if (!event.isDefaultPrevented()) {
          MobileAppBridge.interceptLinkClick(event);
        }
      }}
    />
  ));

  Component.displayName = `WebView${LinkComponent.displayName}`;

  return Component;
}

export const WebViewLink = makeWebViewLink(Link);
export const WebViewLinkedText = makeWebViewLink(LinkedText);
export const WebViewAnchorButton = makeWebViewLink(AnchorButton);

interface WebViewSMSLinkProps extends LinkProps {
  phone: string;
}
export function WebViewSMSLink({ phone, ...props }: WebViewSMSLinkProps) {
  const os = getOS();
  const tel = phone.replace(/[^+\d]/g, '');

  return os === 'ios' ? (
    <Link {...props} href={`sms:${tel}&body=START`}>
      {phone}
    </Link>
  ) : (
    <WebViewLink {...props} href={`smsto:${tel}`}>
      {phone}
    </WebViewLink>
  );
}

interface WebViewPhoneLinkProps extends LinkProps {
  tel: string;
}
export function WebViewPhoneLink({ tel, ...props }: WebViewPhoneLinkProps) {
  const os = getOS();
  const Component = os === 'ios' ? Link : WebViewLink;

  return (
    <Component href={`tel:${tel}`} {...props}>
      {tel}
    </Component>
  );
}
