import { Link, Typography } from '@material-ui/core';

interface SuperPayExpeditedPayNudgingProps {
  onClick: () => void;
}

export function SuperPayExpeditedPayNudging({
  onClick,
}: SuperPayExpeditedPayNudgingProps) {
  return (
    <Link
      data-intercom-target="expedite payment link"
      component="button"
      onClick={(e) => {
        e.preventDefault();
        onClick();
      }}
    >
      <Typography color="textSecondary">Expedite Payment</Typography>
    </Link>
  );
}
