import {
  FormikTextField,
  SuspendedFormikPhoneField,
  useFormikEnhanced,
} from '@superdispatch/forms';
import { Stack } from '@superdispatch/ui';
import {
  useFormikStatusSnackbar,
  useInitialValues,
} from 'shared/form/AppFormik';
import { FormikDrawerActions } from 'shared/form/FormikDrawer';
import { SlowFormikDrawer } from 'shared/form/SlowFormikDrawer';
import { terminalSchema } from '../terminals/data/TerminalDTO';
import { useTerminalsAPI } from '../terminals/data/TerminalsAPI';

interface SettingsTerminalAddDrawerProps {
  open?: boolean;
  onClose?: () => void;
  onSubmitSuccess?: () => void;
}

export function SettingsTerminalAddDrawer({
  open,
  onClose,
  onSubmitSuccess,
}: SettingsTerminalAddDrawerProps) {
  const { createTerminal } = useTerminalsAPI();
  const initialValues = useInitialValues(terminalSchema);
  const formik = useFormikEnhanced({
    key: open,
    initialValues,
    validationSchema: terminalSchema,
    onSubmit: (values) => createTerminal(values),
    onSubmitSuccess: () => {
      onClose?.();
      onSubmitSuccess?.();
    },
  });
  useFormikStatusSnackbar(
    formik,
    'New terminal was successfully added',
    ({ message }) => `Failed to create new terminal: ${message}`,
  );

  return (
    <SlowFormikDrawer
      formik={formik}
      title="New Terminal"
      open={open}
      onClose={onClose}
      actions={<FormikDrawerActions />}
    >
      <Stack space="small">
        <FormikTextField
          name="name"
          label="Terminal Name"
          fullWidth={true}
          required={true}
        />
        <FormikTextField
          name="street"
          label="Street"
          fullWidth={true}
          required={true}
        />
        <FormikTextField
          name="city"
          label="City"
          fullWidth={true}
          required={true}
        />
        <FormikTextField
          name="state"
          label="State"
          fullWidth={true}
          required={true}
        />
        <FormikTextField
          name="zip"
          label="Zip"
          fullWidth={true}
          required={true}
        />
        <FormikTextField
          name="contact_name"
          label="Contact Name"
          fullWidth={true}
          required={true}
        />
        <SuspendedFormikPhoneField
          name="phone_number"
          label="Phone Number"
          fullWidth={true}
          required={true}
        />
      </Stack>
    </SlowFormikDrawer>
  );
}
