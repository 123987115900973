import { requestCarrierAPI } from 'shared/api/APIClient';
import {
  APIQueryResult,
  useAPIQuery,
  UseAPIQueryOptions,
} from 'shared/api/APIQuery';
import { CarrierInfoDTO, carrierInfoSchema } from './CarrierInfoDTO';

export function useCarrierInfo(
  options?: UseAPIQueryOptions<CarrierInfoDTO>,
): APIQueryResult<CarrierInfoDTO> {
  return useAPIQuery(
    ['profile', 'carrier-info'],
    () => requestCarrierAPI('GET /internal/web/carrier/profile/carrier-info/'),
    { ...options, schema: carrierInfoSchema },
  );
}
