import { BankAccountStatusType } from '@moovio/moov-js';
import { Tag } from '@superdispatch/ui';
import { BankAccountVerificationStatusType } from 'shared/modules/superpay/SuperPayDTO';

interface Props {
  status: BankAccountVerificationStatusType | BankAccountStatusType;
}

export function BankVerificationStatusTag({ status }: Props) {
  switch (status) {
    case 'new':
      return (
        <Tag color="blue" variant="subtle">
          New
        </Tag>
      );
    case 'pending':
      return (
        <Tag color="yellow" variant="subtle">
          Pending
        </Tag>
      );
    case 'verified':
      return (
        <Tag color="green" variant="subtle">
          Bank Account Verified
        </Tag>
      );
    case 'failed':
      return (
        <Tag color="red" variant="subtle">
          Failed
        </Tag>
      );
    case 'errored':
      return (
        <Tag color="red" variant="subtle">
          Errored
        </Tag>
      );
    case 'locked':
      return (
        <Tag color="red" variant="subtle">
          Verification Locked
        </Tag>
      );
    default:
      return null;
  }
}
