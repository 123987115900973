import { Avatar, Link, Typography } from '@material-ui/core';
import { AccountBalance, Help } from '@material-ui/icons';
import { Color, Column, Columns, Inline, Stack } from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import { BankErrorBanner } from 'shared/errors/SuperPayBankErrors';
import { showNewIntercomMessage } from 'shared/helpers/Intercom';
import { SuperPayBankAccountDTO } from 'shared/modules/superpay/SuperPayDTO';
import { useFlag } from 'shared/settings/FeatureToggles';
import styled from 'styled-components';
import { BankVerificationStatusTag } from '../core/BankVerificationStatusTag';
import { BankValidationErrorBanner } from '../core/SuperPayVerificationError';
import { useMoovAccountInfo } from '../data/useMoovAccountInfo';
import { SuperPayVerification } from './SuperPayVerification';

const BankAvatar = styled(Avatar)`
  width: 56px;
  height: 56px;
  background: ${Color.Silver200};
`;

const ContactSupportLink = styled(Link)`
  cursor: pointer;
`;

interface Props {
  bankAccount?: SuperPayBankAccountDTO;
  hasAccountFeature?: boolean;
  path: string;
}

export function SuperPayBankAccountDetails({
  bankAccount,
  hasAccountFeature,
  path,
}: Props) {
  const isSuperPayErrorNotificationsEnabled = useFlag(
    'superpay_bank_error_notifications',
  );
  const moovAccountInfo = useMoovAccountInfo();
  const moovBankAccount = moovAccountInfo.getBankAccount();

  if (
    !bankAccount &&
    !(moovBankAccount?.bankName && moovBankAccount.lastFourAccountNumber)
  ) {
    return (
      <Stack>
        <Typography variant="h3">Bank Account</Typography>
        <Typography color="textSecondary">No data</Typography>
      </Stack>
    );
  }

  const shouldShowVerification =
    moovBankAccount?.status !== 'validated' &&
    (bankAccount?.verification_status === 'new' ||
      bankAccount?.verification_status === 'pending');

  const bankName = bankAccount?.bank_name || moovBankAccount?.bankName;
  const bankAccountDisplayNumber =
    bankAccount?.display_number || moovBankAccount?.lastFourAccountNumber;
  const verificationStatus =
    bankAccount?.verification_status || moovBankAccount?.status;

  const hasValidationError =
    verificationStatus === 'errored' ||
    verificationStatus === 'failed' ||
    verificationStatus === 'locked';

  return (
    <Stack space="large">
      {shouldShowVerification && (
        <Box margin="auto" maxWidth="500px">
          {/* The 'path' prop was introduced as a replacement for 'useRouteMatch' in order to facilitate the migration to React Router v6. */}
          <SuperPayVerification
            path={path}
            bankName={bankName}
            hasAccountFeature={hasAccountFeature}
            bankAccountDisplayNumber={bankAccountDisplayNumber}
            bankAccountDateSent={bankAccount.microdeposit_sent_at}
            bankAccountAvailableDate={bankAccount.verification_available_date}
            isVerificationAvailable={bankAccount.is_verification_available}
          />
        </Box>
      )}

      <Stack space="medium">
        {bankAccount?.payment_error && isSuperPayErrorNotificationsEnabled ? (
          <BankErrorBanner
            paymentError={bankAccount.payment_error}
            source="SuperPay Settings"
          />
        ) : (
          hasValidationError && (
            <BankValidationErrorBanner
              verificationStatus={verificationStatus}
            />
          )
        )}

        <Box aria-label="bank account details" margin="auto" maxWidth="496px">
          <Stack space="small">
            <Columns
              reverse={[true, false]}
              align={['top', 'center']}
              collapseBelow="tablet"
              space={['large', 'small']}
            >
              <Column>
                <Typography variant="h3">Bank Account</Typography>
              </Column>

              <Column width="content">
                <Inline verticalAlign="center" space="xxsmall">
                  <Box fontSize="16px">
                    <Help fontSize="inherit" color="action" />
                  </Box>
                  <Box fontSize="14px">
                    <Link
                      target="_blank"
                      rel="noreferrer"
                      href="https://support.superdispatch.com/en/collections/3768773-superpay-for-carriers"
                    >
                      How SuperPay works
                    </Link>
                  </Box>
                </Inline>
              </Column>
            </Columns>

            <Columns
              collapseBelow="tablet"
              space="small"
              align={['top', 'center']}
            >
              <Column>
                <Inline verticalAlign="center" space="small">
                  <BankAvatar>
                    <AccountBalance color="action" />
                  </BankAvatar>

                  <Stack space="none">
                    <Typography variant="h3">
                      &bull;&bull;&bull;&bull;{bankAccountDisplayNumber}
                    </Typography>

                    <Typography color="textSecondary">{bankName}</Typography>
                  </Stack>
                </Inline>
              </Column>

              <Column width="content">
                {verificationStatus && (
                  <BankVerificationStatusTag status={verificationStatus} />
                )}
              </Column>
            </Columns>
            {bankAccount?.verification_status === 'verified' && (
              <Inline space="xxsmall">
                <Typography variant="caption" color="textSecondary">
                  To change bank account,
                </Typography>
                <ContactSupportLink
                  aria-label="contact support link"
                  variant="caption"
                  color="textSecondary"
                  onClick={() => {
                    showNewIntercomMessage('I want to change my bank account');
                  }}
                >
                  contact support.
                </ContactSupportLink>
              </Inline>
            )}
          </Stack>
        </Box>
      </Stack>
    </Stack>
  );
}
