import { DateTime } from 'luxon';
import { QuickbookOnlineServiceDTO } from './SettingsDTO';

export function downloadQwcUrl(qwc_url: string) {
  const link = document.createElement('a');
  link.href = qwc_url;
  link.setAttribute('download', `QWCFile`);

  document.body.appendChild(link);
  link.click();
  link.parentNode?.removeChild(link);
}

export function findServiceId(
  services?: QuickbookOnlineServiceDTO[],
  invoiceItem?: string,
) {
  return services?.find((service) => service.name === invoiceItem)?.id;
}

export function updateDateToString(date: string | null | undefined) {
  if (date) {
    const dateTime = DateTime.fromISO(date, { zone: 'UTC' });
    const now = DateTime.utc();

    return dateTime.toRelative({ base: now });
  }
  return 'Never';
}
