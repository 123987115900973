import { useMemo } from 'react';
import { LocalStore, useLocalStore } from 'shared/helpers/Store';
import { tryParseJSON } from 'shared/utils/DataUtils';

const SUBSCRIPTION_CANCELLED_DIALOG = 'subscription_cancelled_dialog';
interface SubscriptionCancelledDialogProps {
  open: boolean;
  seen: boolean;
  type: 'cancelled' | 'expired';
}

export const PERMITTED_PAGES = [
  '/tms/drivers',
  '/tms/settings/factoring',
  '/tms/settings/quickbooks',
  '/tms/settings/subscription',
  '/tms/dispatchers',
  '/tms/profile',
  '/tms/dispatchers/profile',
];

export function useShowSubscriptionCancelledDialog() {
  const showSubscriptionCancelledDialog = useLocalStore(
    SUBSCRIPTION_CANCELLED_DIALOG,
    JSON.stringify({
      open: false,
      seen: false,
      type: 'cancelled',
    }),
  );

  const isOpenSubscriptionCancelledDialog = useMemo(() => {
    if (showSubscriptionCancelledDialog) {
      return tryParseJSON<SubscriptionCancelledDialogProps>(
        showSubscriptionCancelledDialog,
      )?.open;
    }
    return false;
  }, [showSubscriptionCancelledDialog]);

  const hasSeenSubscriptionCancelledDialog = useMemo(() => {
    if (showSubscriptionCancelledDialog) {
      return tryParseJSON<SubscriptionCancelledDialogProps>(
        showSubscriptionCancelledDialog,
      )?.seen;
    }
    return false;
  }, [showSubscriptionCancelledDialog]);

  const trialType = useMemo(() => {
    if (showSubscriptionCancelledDialog) {
      return tryParseJSON<SubscriptionCancelledDialogProps>(
        showSubscriptionCancelledDialog,
      )?.type;
    }
    return 'cancelled';
  }, [showSubscriptionCancelledDialog]);

  function setOpenSubscriptionCancelledDialog(type?: 'cancelled' | 'expired') {
    LocalStore.set(
      SUBSCRIPTION_CANCELLED_DIALOG,
      JSON.stringify({ open: true, seen: true, type }),
    );
  }

  function closeSubscriptionCancelledDialog() {
    LocalStore.set(
      SUBSCRIPTION_CANCELLED_DIALOG,
      JSON.stringify({ open: false, seen: true }),
    );
  }

  function removeSubscriptionCancelledDialog() {
    LocalStore.delete(SUBSCRIPTION_CANCELLED_DIALOG);
  }

  return {
    hasSeenSubscriptionCancelledDialog,
    isOpenSubscriptionCancelledDialog,
    showSubscriptionCancelledDialog,
    setOpenSubscriptionCancelledDialog,
    closeSubscriptionCancelledDialog,
    removeSubscriptionCancelledDialog,
    trialType,
  };
}
