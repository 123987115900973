import { FormikTextField, useFormikEnhanced } from '@superdispatch/forms';
import { Inline, Stack } from '@superdispatch/ui';
import { Button, Chat, ChatMessage, TextBox } from '@superdispatch/ui-lab';
import { Form, FormikProvider } from 'formik';
import { validateString } from 'shared/helpers/ValidationHelpers';
import { useCarrierSettings } from 'shared/settings/CarrierSettingsAPI';
import styled from 'styled-components';
import { LoadDTO } from '../data/LoadDTO';
import { ChatFormDTO, chatFormSchema } from '../messaging/MessagingDTO';
import { useMessaging } from '../messaging/useMessaging';

interface ViewLoadMessagesProps {
  load: LoadDTO;
}

const StyledChat = styled(Chat)`
  max-height: 368px;
`;

/**
 * ViewLoadChat component is used to display the chat messages between the carrier and the shipper
 * @param load - LoadDTO object
 */
export function ViewLoadChat({ load }: ViewLoadMessagesProps) {
  const { data: settings } = useCarrierSettings(); // used to get user guid
  const { messages, sendMessage, isReady } = useMessaging({
    loadGuid: load.guid,
  });

  const formik = useFormikEnhanced<ChatFormDTO, void>({
    key: 'chat-form',
    validationSchema: chatFormSchema,
    initialValues: { message: '' },
    onSubmit: (values: { message: string }) => {
      sendMessage(values.message);
      formik.resetForm();
    },
  });

  return (
    <>
      <StyledChat
        emptyText={`No new messages from ${load.customer.venue.name}. 
        We will let you know when they send you a message.`}
      >
        {messages.map((msg) => (
          <ChatMessage
            key={`message-${msg.guid}`}
            author={msg.user_name}
            role={msg.user_role.title}
            dateTime={msg.sent_at}
            text={msg.content}
            variant={
              settings?.user.guid === msg.user_guid ? 'outgoing' : 'incoming'
            }
          />
        ))}
      </StyledChat>

      <FormikProvider value={formik}>
        <Form>
          <Stack space="xsmall">
            <TextBox>
              Message to <b>{load.customer.venue.name}</b>
            </TextBox>
            <FormikTextField
              name="message"
              validate={(value) => validateString(value, { maxLength: 5000 })}
              fullWidth={true}
              multiline={true}
            />
            <Inline horizontalAlign="right">
              <Button
                disabled={!formik.dirty || !isReady}
                pending={formik.isSubmitting}
                type="submit"
              >
                Send
              </Button>
            </Inline>
          </Stack>
        </Form>
      </FormikProvider>
    </>
  );
}
