import { FormikContextTypeEnhanced } from '@superdispatch/forms';
import { useNavigationBlock } from 'shared/routing/NavigationBlock';

export function useBlockFormChanges<T, TResponse>(
  formik: FormikContextTypeEnhanced<T, TResponse>,
) {
  useNavigationBlock(
    formik.status.type !== 'submitted' &&
      (formik.dirty || formik.isSubmitting) &&
      'Changes have not been saved. Leaving will result in unsaved changes being lost.',
  );
}
