import { useCallback } from 'react';
import { LocalStore, useLocalStore } from 'shared/helpers/Store';
import { useDispatcherProfile } from 'shared/modules/dispatcher/DispatcherAPI';

const BANNER_KEY = 'subscription-celebration';
const CONFETTI_KEY = 'subscription-celebration-confetti';

export function useSubscriptionCelebrationState() {
  const { data: dispatcher } = useDispatcherProfile();
  const bannerKey = `${dispatcher?.guid}_${BANNER_KEY}`;
  const confettiKey = `${dispatcher?.guid}_${CONFETTI_KEY}`;
  const bannerStatus = useLocalStore(bannerKey);
  const confettiStatus = useLocalStore(confettiKey);

  const setBannerStatus = useCallback(
    (status: 'seen' | 'unseen') => {
      LocalStore.set(bannerKey, status);
    },
    [bannerKey],
  );

  const setConfettiStatus = useCallback(
    (status: 'seen') => {
      LocalStore.set(confettiKey, status);
    },
    [confettiKey],
  );

  const removeStatuses = useCallback(() => {
    LocalStore.delete(bannerKey);
    LocalStore.delete(confettiKey);
  }, [bannerKey, confettiKey]);

  return {
    bannerStatus,
    confettiStatus,
    setBannerStatus,
    setConfettiStatus,
    removeStatuses,
  };
}
