import { Dialog, Typography } from '@material-ui/core';
import { MonetizationOn } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import { formatDate } from '@superdispatch/dates';
import { InfoCard, Inline, Stack, useSnackbarStack } from '@superdispatch/ui';
import { Alert, Box, Button } from '@superdispatch/ui-lab';
import { trackEvent } from 'shared/helpers/Analytics';
import { formatCurrency } from 'shared/helpers/IntlHelpers';
import {
  useConfirmExpeditedPay,
  useExpeditedPay,
} from 'shared/modules/superpay/SuperPayAPI';
import { InlineBulletItems } from 'shared/ui/InlineBulletItems';
import styled from 'styled-components';

const PercentInformation = styled(Box)`
  display: flex;
  justify-content: space-between;
`;

type Source = 'Load Details' | 'Load List';

export interface SuperPayExpeditedPayLoadInfo {
  guid: string;
  customer: {
    shipper_profile_url: string;
    venue: {
      name: string;
    };
  };
}

interface SuperPayExpeditedPayContentProps {
  load?: SuperPayExpeditedPayLoadInfo;
  onClose: () => void;
  source: Source;
}

function SuperPayExpeditedPayContent({
  load,
  onClose,
  source,
}: SuperPayExpeditedPayContentProps) {
  const {
    data: expeditedPayData,
    isLoading: isLoadingExpeditedPay,
    error: expeditedPayError,
  } = useExpeditedPay(load?.guid);
  const { addSnackbar } = useSnackbarStack();

  const { isLoading, mutateAsync } = useConfirmExpeditedPay(load?.guid, {
    onSuccess() {
      trackEvent('Carrier Expedited Payment', {
        utm_medium: source,
        shipper: load?.customer.venue.name,
        payment_terms: expeditedPayData?.current_terms,
        order_price: expeditedPayData?.current_price,
      });
      addSnackbar('Payment Expedited Successfully.', {
        variant: 'success',
      });
      onClose();
    },
    onError(error) {
      addSnackbar(error.message || 'An unexpected error happened. Try again.', {
        variant: 'error',
      });
    },
  });

  return (
    <Box minWidth={['auto', '400px']} padding="large">
      <Stack space="medium">
        <Stack space="small">
          <Typography variant="h3">Expedite Payment</Typography>
          {expeditedPayError ? (
            <Alert severity="critical">
              <Typography color="textPrimary">
                {expeditedPayError.message ||
                  'An unexpected error happened. Try again.'}
              </Typography>
            </Alert>
          ) : (
            (expeditedPayData || isLoadingExpeditedPay) && (
              <>
                <Box
                  backgroundColor="Silver200"
                  padding="small"
                  borderRadius="small"
                >
                  <Stack>
                    <Typography color="textSecondary">Booked Rate</Typography>
                    {isLoadingExpeditedPay ? (
                      <Skeleton width="100%" />
                    ) : (
                      <InlineBulletItems>
                        <Typography>
                          {formatDate(expeditedPayData.current_expected_date, {
                            variant: 'Date',
                          })}
                        </Typography>
                        <Typography>
                          {expeditedPayData.current_terms}
                        </Typography>
                        <Typography>
                          {formatCurrency(expeditedPayData.current_price)}
                        </Typography>
                      </InlineBulletItems>
                    )}
                  </Stack>
                </Box>
                <InfoCard>
                  {isLoadingExpeditedPay ? (
                    <Skeleton width="100%" />
                  ) : (
                    <Stack>
                      <PercentInformation>
                        <Typography variant="h4">
                          {formatDate(expeditedPayData.new_expected_date, {
                            variant: 'Date',
                          })}
                        </Typography>
                        <Typography color="textSecondary" variant="h4">
                          {expeditedPayData.fee_percent}% fee
                        </Typography>
                      </PercentInformation>
                      <Inline verticalAlign="center" space="xxsmall">
                        <MonetizationOn fontSize="small" color="action" />
                        <Typography color="textSecondary">
                          {formatCurrency(expeditedPayData.new_price)}
                        </Typography>
                      </Inline>
                    </Stack>
                  )}
                </InfoCard>
              </>
            )
          )}
        </Stack>
        <Stack align="center">
          <Button
            disabled={!expeditedPayData || !!expeditedPayError}
            pending={isLoading || isLoadingExpeditedPay}
            fullWidth={true}
            size="large"
            onClick={() => mutateAsync()}
          >
            Confirm
          </Button>
          <Button
            variant="text"
            disabled={isLoading || isLoadingExpeditedPay}
            fullWidth={true}
            size="large"
            onClick={() => {
              trackEvent('Carrier Canceled Expedite Payment Dialog', {
                utm_medium: source,
                shipper: load?.customer.venue.name,
                payment_terms: expeditedPayData?.current_terms,
                order_price: expeditedPayData?.current_price,
              });
              onClose();
            }}
          >
            Cancel
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
}

interface SuperPayExpeditedPayDialogProps {
  load?: SuperPayExpeditedPayLoadInfo;
  onClose: () => void;
  source: Source;
}

export function SuperPayExpeditedPayDialog({
  load,
  onClose,
  source,
}: SuperPayExpeditedPayDialogProps) {
  return (
    <Dialog open={!!load} onClose={onClose}>
      <SuperPayExpeditedPayContent
        onClose={onClose}
        load={load}
        source={source}
      />
    </Dialog>
  );
}
