import { Inline, Snackbar, useSnackbarStack } from '@superdispatch/ui';
import { Button } from '@superdispatch/ui-lab';
import { formatPlural } from 'shared/helpers/IntlHelpers';
import { useContactsPageRouter } from './ContactsPageRouter';
import { useBatchDeleteContacts } from './data/ContactsAPI';

export interface ContactsDeleteSnackbarProps {
  onClose: () => void;
  contacts: Set<string>;
}

export function ContactsDeleteSnackbar({
  onClose,
  contacts,
}: ContactsDeleteSnackbarProps) {
  const { addSnackbar } = useSnackbarStack();
  const { openContact } = useContactsPageRouter();
  const batchDeleteContacts = useBatchDeleteContacts({
    onSuccess(response, _, context) {
      onClose();
      if (context) openContact(context, true);
      addSnackbar(
        response.user_message ||
          formatPlural(
            contacts.size,
            'Contact deleted',
            `${contacts.size} contacts deleted`,
          ),
      );
    },
    onError(error) {
      addSnackbar(error.message, { variant: 'error' });
    },
  });

  return (
    <Snackbar
      open={contacts.size > 0}
      action={
        <Inline noWrap={true}>
          <Button
            variant="inverted"
            pending={batchDeleteContacts.isLoading}
            onClick={() => {
              batchDeleteContacts.mutate(contacts);
            }}
          >
            Delete
          </Button>
          <Button
            variant="inverted"
            onClick={onClose}
            disabled={batchDeleteContacts.isLoading}
          >
            Clear selection
          </Button>
        </Inline>
      }
    >
      {contacts.size} {formatPlural(contacts.size, 'contact', 'contacts')}{' '}
      selected
    </Snackbar>
  );
}
