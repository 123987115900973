import { trackEvent } from 'shared/helpers/Analytics';

type SuperPayAnalyticsProps =
  | {
      name:
        | 'Carrier Opened SuperPay Promo Page'
        | 'Carrier Submitted Bank Account'
        | 'Carrier Submitted Business Details'
        | 'Carrier Opened SuperPay Settings Page'
        | 'Carrier Started Bank Account Verification';
    }
  | {
      name: 'Carrier Clicked Set Up SuperPay';
      utm_content: 'Top Button' | 'Footer Button';
    }
  | {
      name: 'Carrier Submitted Bank Account Verification';
      verification_response: string | undefined;
    };

export function trackSuperPayEvent(event: SuperPayAnalyticsProps) {
  const { name, ...props } = event;
  trackEvent(name, {
    ...props,
  });
}
