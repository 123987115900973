import mitt from 'mitt';

export type LoadEventType = 'created' | 'imported' | 'accepted' | 'edited';

const emitter = mitt<{ 'load-event': LoadEventType }>();

export function listenToLoadEvents(
  handler: (eventType: LoadEventType) => void,
): () => void {
  emitter.on('load-event', handler);
  return () => {
    emitter.off('load-event', handler);
  };
}

export function emitLoadEvent(eventType: LoadEventType): void {
  emitter.emit('load-event', eventType);
}
