import { Card, CardContent } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';

export interface PaginationCardProps {
  page: number;
  count: number;
  pageSize: number;
  onChange: (nextPage: number) => void;
}

export function PaginationCard({
  page,
  count,
  onChange,
  pageSize,
}: PaginationCardProps) {
  const maxPage = Math.ceil(count / pageSize);

  return (
    <Card>
      <CardContent>
        <Pagination
          page={page}
          count={maxPage}
          siblingCount={0}
          onChange={(_, nextPage) => {
            onChange(nextPage);
          }}
        />
      </CardContent>
    </Card>
  );
}
