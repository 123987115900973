import { FormikErrors } from 'formik';
import { APIError, isAPIError } from 'shared/api/APIError';
import { defaultFormErrorsGetter } from 'shared/helpers/FormHelpers';

export function billingAddressFormErrorsGetter<TValues>(
  err: Error,
): FormikErrors<TValues> {
  if (isAPIError(err)) {
    const errorDetails = (err as APIError<{ details: string[] }>).context
      ?.details;

    if (Array.isArray(errorDetails)) {
      err.message = errorDetails.join('\n');
    }
  }

  return defaultFormErrorsGetter(err);
}
