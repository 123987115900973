import { APIResponse, requestAPIGateway } from 'shared/api/APIClient';
import { useAPIQuery } from 'shared/api/APIQuery';

export interface LoadboardLoadsCount {
  available: number;
  booked: number;
  requested: number;
  suggested: number;
}

export function useLoadboardLoadsCount() {
  return useAPIQuery(
    ['loadboard', 'loads-count'],
    () =>
      requestAPIGateway<APIResponse<LoadboardLoadsCount>>(
        'GET /loadboard/loads/count',
      ),
    { staleTime: Infinity },
  );
}
