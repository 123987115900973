import { useEffect, useRef, useState } from 'react';
import { useGoogleMaps } from 'shared/geo/GoogleMaps';
import styled from 'styled-components';
import { TrackingDriverDTO } from './data/TrackingDTO';
import { TrackingDriversMarker } from './TrackingDriversMarker';

const MapContainer = styled.div`
  flex-grow: 1;
`;

interface TrackingPageProps {
  drivers?: TrackingDriverDTO[];
  hoveredDriverId?: number;
  checkedDriversIds: Set<number>;
  onFocusChange: (id?: number) => void;
}

export function TrackingMap({
  drivers,
  hoveredDriverId,
  checkedDriversIds,
  onFocusChange,
}: TrackingPageProps) {
  const maps = useGoogleMaps();
  const [map, setMap] = useState<google.maps.Map>();
  const mapRef = useRef<HTMLDivElement>(null);
  const isMapCenteredRef = useRef(false);

  useEffect(() => {
    if (!maps || !mapRef.current) {
      return;
    }

    const nextMap = new maps.Map(mapRef.current, {
      //SuperDispatch office in Kansas City
      center: { lat: 39.0938064, lng: -94.5831797 },
      zoom: 12,
      mapTypeControl: false,
      zoomControlOptions: {
        position: google.maps.ControlPosition.RIGHT_CENTER,
      },
      streetViewControl: false,
    });

    setMap(nextMap);

    return () => {
      maps.event.clearInstanceListeners(nextMap);
    };
  }, [maps]);

  useEffect(() => {
    if (!map || !drivers || drivers.length === 0 || isMapCenteredRef.current) {
      return;
    }

    isMapCenteredRef.current = true;

    const bounds = new google.maps.LatLngBounds();

    drivers.forEach((driver) => {
      if (driver.last_location) {
        bounds.extend({
          lat: driver.last_location.latitude,
          lng: driver.last_location.longitude,
        });
      }
    });

    if (drivers.length > 1) {
      map.fitBounds(bounds);
    } else {
      map.setCenter(bounds.getCenter());
    }
  }, [map, drivers]);

  return (
    <>
      <MapContainer ref={mapRef} />

      {checkedDriversIds.size > 0 &&
        drivers?.map(
          (driver) =>
            driver.last_location &&
            !driver.disabled && (
              <TrackingDriversMarker
                map={map}
                key={driver.id}
                driver={driver}
                isFocused={hoveredDriverId === driver.id}
                onFocusChange={(isFocused) => {
                  if (isFocused) {
                    onFocusChange(driver.id);
                  } else {
                    onFocusChange(undefined);
                  }
                }}
              />
            ),
        )}
    </>
  );
}
