import { createSvgIcon } from 'shared/icons/IconUtils';

export const SidebarLogoIcon = createSvgIcon(
  'SidebarLogo',
  <path
    fill="currentColor"
    fillRule="evenodd"
    clipRule="evenodd"
    d="M17.523 141.998C53.222 57.9 141.02 0 232.02 0c92.198 0 178.597 57.2 214.497 141.998 40.3 94.998 5.8 189.997-51.3 269.395-43.4 60.5-94.498 112.3-151.398 160.098-.8.8-2.1.6-2.8-.2-5-5.555-9.77-11.377-14.548-17.207l-8.952-10.793c-.8-.8-.6-2.1.2-2.8 49.9-41.9 95.598-87.398 135.598-138.897 37.5-48.4 70.7-106 70.7-168.998 0-103.498-88.4-191.897-192.197-191.897-104.5 0-192.198 87.6-192.198 191.897 0 31.8 13.5 85.3 78.1 159.398 26 30 52.4 53.7 67.4 66.6 15.7-14.2 44-40.9 72.1-73 53.3-61.1 81.5-113.998 81.5-152.998 0-59-48-106.998-107.098-106.998-59 0-107.1 48-107.1 106.998 0 52.777 46.5 94.78 83.128 127.865l4.57 4.133c.8.7.8 2 .1 2.8l-24.6 27.1c-.7.8-2 .8-2.8.1-46.7-42.3-100.998-94-100.998-161.998 0-78.598 69.4-147.697 147.898-147.697 79.9 0 147.898 67.9 147.898 147.697 0 49.7-30.9 110.398-91.8 180.097-30.9 35.3-64.7 67.6-100.7 97.7-.7.6-1.8.6-2.5 0-34.7-28.2-68-58.2-97.398-91.9-25.1-28.9-45.3-57.5-59.8-84.898-32-60.6-37.1-127.898-10-191.597zm214.496 27.5c34.8 0 63.2 28.2 63.2 63.1s-28.3 63.1-63.2 63.1-63.2-28.2-63.2-63.1 28.4-63.1 63.2-63.1zm-27.7 63.1c0 15.2 12.4 27.6 27.7 27.6s27.7-12.4 27.7-27.6-12.4-27.6-27.7-27.6-27.7 12.4-27.7 27.6z"
  />,
  { viewBox: '0 0 464 572' },
);
