import { SidebarContainer } from '@superdispatch/ui-lab';
import { ReactElement } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Navigate } from 'shared/routing/react-router-6';
import { LoadsContextProvider } from '../loads/data/LoadsContext';
import { LoadsPageContextProvider } from '../loads/loads-page/data/LoadsPageParamsContext';
import { ReportsSidebar } from './core/ReportsSidebar';
import { ReportsContextProvider } from './data/ReportsContext';
import { ReportsAccountsPayablePage } from './ReportsAccountsPayable';
import { ReportsAccountsReceivablePage } from './ReportsAccountsReceivablePage';
import { ReportsCompanyRevenuePage } from './ReportsCompanyRevenuePage';
import { ReportsCustomPage } from './ReportsCustomPage';
import { ReportsDriverPayPage } from './ReportsDriverPayPage';
import { ReportsLoadsPage } from './ReportsLoadsPage';

interface ReportsPageRouteProps {
  children: ReactElement;
}

function ReportsPageRoute({ children }: ReportsPageRouteProps) {
  return (
    <SidebarContainer sidebar={<ReportsSidebar />}>{children}</SidebarContainer>
  );
}

export function ReportsContainerPage() {
  return (
    <ReportsContextProvider>
      <Switch>
        <Route path="/reports/loads/">
          <LoadsContextProvider>
            <LoadsPageContextProvider>
              <ReportsLoadsPage />
            </LoadsPageContextProvider>
          </LoadsContextProvider>
        </Route>

        <Route path="/reports/driver-pay/">
          <ReportsPageRoute>
            <ReportsDriverPayPage />
          </ReportsPageRoute>
        </Route>
        <Route path="/reports/accounts-receivable/">
          <ReportsPageRoute>
            <ReportsAccountsReceivablePage />
          </ReportsPageRoute>
        </Route>
        <Route path="/reports/company-revenue/">
          <ReportsPageRoute>
            <ReportsCompanyRevenuePage />
          </ReportsPageRoute>
        </Route>
        <Route path="/reports/accounts-payable/">
          <ReportsPageRoute>
            <ReportsAccountsPayablePage />
          </ReportsPageRoute>
        </Route>
        <Route path="/reports/custom-report/:reportGuid/">
          <ReportsPageRoute>
            <ReportsCustomPage />
          </ReportsPageRoute>
        </Route>
        <Route path="/reports/custom-report/">
          <ReportsPageRoute>
            <ReportsCustomPage />
          </ReportsPageRoute>
        </Route>

        <Route path="*">
          <Navigate to="/reports/accounts-receivable/" />
        </Route>
      </Switch>
    </ReportsContextProvider>
  );
}
