import { useMemo } from 'react';
import { LocalStore, useLocalStore } from 'shared/helpers/Store';
import { tryParseJSON } from 'shared/utils/DataUtils';

const TRIAL_EXPIRED_DIALOG = 'trial_expired_dialog';
interface TrialExpiredDialogProps {
  open: boolean;
  seen: boolean;
}

export function useShowTrialExpiredDialog() {
  const showTrialExpiredDialog = useLocalStore(
    TRIAL_EXPIRED_DIALOG,
    JSON.stringify({
      open: false,
      seen: false,
    }),
  );

  const isOpenTrialExpiredDialog = useMemo(() => {
    if (showTrialExpiredDialog) {
      return tryParseJSON<TrialExpiredDialogProps>(showTrialExpiredDialog)
        ?.open;
    }
    return false;
  }, [showTrialExpiredDialog]);

  const hasSeenTrialExpiredDialog = useMemo(() => {
    if (showTrialExpiredDialog) {
      return tryParseJSON<TrialExpiredDialogProps>(showTrialExpiredDialog)
        ?.seen;
    }
    return false;
  }, [showTrialExpiredDialog]);

  function setOpenTrialExpiredDialog() {
    LocalStore.set(
      TRIAL_EXPIRED_DIALOG,
      JSON.stringify({ open: true, seen: true }),
    );
  }

  function closeTrialExpiredDialog() {
    LocalStore.set(
      TRIAL_EXPIRED_DIALOG,
      JSON.stringify({ open: false, seen: true }),
    );
  }

  function removeTrialExpiredDialog() {
    LocalStore.delete(TRIAL_EXPIRED_DIALOG);
  }

  return {
    hasSeenTrialExpiredDialog,
    isOpenTrialExpiredDialog,
    showTrialExpiredDialog,
    setOpenTrialExpiredDialog,
    closeTrialExpiredDialog,
    removeTrialExpiredDialog,
  };
}
